import React, { useState } from "react";
import EditDomainModal from "../modal/EditDomainModal";
import { Link } from "react-router-dom";
import { Popover } from "reactstrap";
import { ACTIVATE_DOMAIN_GQL } from "../../../Apollo/AdminServices/MutationRequest";
import graphHandler from "../../../Apollo/graphHandler";
import { toast } from "react-toastify";
import { getDescription } from "../../../utils/Functions/filter";
import ReadMore from "../../../components/ReadMore";

export default function DomainListItem({
  access = {},
  item = {},
  refresh,
  index,
}) {
  const [isEdit, setEdit] = useState(false);
  // const [showPop, setShowPop] = useState(false);
  // const toggle = () => setShowPop((x) => !x);
  // const activateItem = async (active) => {
  //   if (!item.domain_id) return;
  //   toggle();
  //   try {
  //     const bae = await graphHandler().invoke(
  //       ACTIVATE_DOMAIN_GQL,
  //       { active_request: { active, domain_id: item.domain_id } },
  //       1,
  //       false,
  //       true
  //     );
  //     toast.success(
  //       `${item.domain_name} ${
  //         active ? "Activated" : "Deactivated"
  //       } Successfully`
  //     );
  //     refresh();
  //   } catch (error) {
  //     console.log("activateDomain error", error);
  //   }
  // };
  return (
    <tr>
      <td className="col-sm-2">{item.domain_name}</td>
      <td>
        <ReadMore>
          {getDescription(item.domain_description, "Web")
            ? getDescription(item.domain_description, "Web").description
            : ""}
        </ReadMore>
      </td>
      <td>
        <span
          className={`${item.active ? "good" : "bad"} text-center btn btn-sm`}
        >
          {item.active ? "Active" : "Disabled"}
        </span>
      </td>
      {access.canEdit && (
       <td>
       <button
         type="button"
         className="btn btn-outline-success btn-sm "
         onClick={() => setEdit(true)}
       >
         Edit
       </button>
     </td>
      )}
      {/* <td className="accordion-menu" id={`domainData${index}`} onClick={toggle}>
        <i className="fas fa-ellipsis-v" />
      </td>
      <Popover
        isOpen={showPop}
        toggle={toggle}
        placement="left"
        target={`domainData${index}`}
        trigger="legacy"
      >
        <div className="accordion-menu-header">Options</div>

        <div
          className="accordion-menu-item"
          onClick={() => activateItem(!item.active)}
        >
          {!item.active ? "Activate" : "Deactivate"}
        </div>
      </Popover> */}
      <EditDomainModal
        isOpen={isEdit}
        setOpen={() => setEdit(false)}
        initialValue={item}
        refresh={refresh}
      />
    </tr>
  );
}
