import { useEffect, useState } from "react";
import "./screener.css";
import PaginationComponent from "../../components/PaginationComponent";
import ListItem from "./Components/ListItem";
import withRoleBased from "../../utils/Functions/withRoleBased";
import useOrganizationList from "../../utils/CustomHooks/useOrganizationList";
import { sortArray } from "../../utils/Functions/filter";
import OrganizationModal from "./modal/OrganizationModal";

function Index({ access = {} }) {
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize] = useState(10);
  const [count, setCount] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const { organizationList, loading, getOrganizationList } =
    useOrganizationList("");
  const { canCreate } = access;
  const [search, setSearch] = useState("");
  const [isSearching, setIsSearch] = useState(false);

  useEffect(() => {
    let req = {};
    if (search) req.organization_name = search;
    getOrganizationList(req);
  }, [count]);

  const handleClick = (event, index) => {
    event.preventDefault();
    setCurrentPage(index);
    // console.log(`Viewing Page ${index + 1} Data`);
  };
  const pageCount = Math.ceil(organizationList.length / pageSize);

  const onSearch = () => {
    setCurrentPage(0)
    setCount((x) => x + 1);
    setIsSearch(search ? true : false);
  };
  // console.log("organizationList",organizationList)
  return (
    <main className="page-content">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6 mb-3">
            <h5 className="page-title">Organization</h5>
          </div>
          <div className="col-md-6  mb-3 d-flex justify-content-end">
            {canCreate && (
              <button
                type="button"
                className="btn btn-outline-success "
                onClick={() => setIsOpen(true)}
              >
                Add Organization
              </button>
            )}
          </div>
          <div className="col-md-4">
            <div className="search" style={{ margin: "16px 0px 32px" }}>
              <input
                type="text"
                className="form-control"
                placeholder="Search"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                onKeyDown={(e) => e.key === "Enter" && onSearch()}
              />
              {!isSearching ? (
                <button onClick={onSearch}>
                  <i className="fa fa-search" />
                </button>
              ) : (
                <button
                  onClick={() => {
                    setSearch("");
                    setIsSearch(false);
                    setCount((x) => x + 1);
                  }}
                >
                  <i className="fa fa-times" />
                </button>
              )}
            </div>
          </div>
          <div className="col-md-12">
            <div className="table-responsive">
              <table className="table domain-table table-borderless">
                <thead>
                  <tr className="">
                    <td className="">Name</td>
                    <td className="">Email</td>
                    <td className="">Domain Name</td>
                    <td className="">Email Domains</td>
                    <td className="">Status</td>
                    {access.canEdit && <td className="">Action</td>}
                  </tr>
                </thead>
                <tbody>
                  {!loading &&
                  organizationList &&
                  organizationList.length > 0 ? (
                    sortArray(organizationList)
                      .slice(
                        currentPage * pageSize,
                        (currentPage + 1) * pageSize
                      )
                      .map((item, i) => (
                        <ListItem
                          item={item}
                          i={i}
                          key={i}
                          refresh={() => {
                            setCount((x) => x + 1);
                          }}
                          access={access}
                        />
                      ))
                  ) : (
                    <tr>
                      <td colSpan="6" className="text-center">
                        <span className="font-weight-bold">
                          {loading ? "Loading..." : "Oops! No Data Found"}
                        </span>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {organizationList.length > 0 && pageCount != 1 && (
          <PaginationComponent
            handleClick={handleClick}
            currentPage={currentPage}
            pageCount={pageCount}
          />
        )}
        <OrganizationModal
          isOpen={isOpen}
          setOpen={setIsOpen}
          refresh={() => {
            setCount((x) => x + 1);
          }}
          isEditable={access.canEdit}
        />
      </div>
    </main>
  );
}

export default withRoleBased(Index, "subDomain");
