import { Modal, ModalBody } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import graphHandler from "../../../Apollo/graphHandler";
import CustomButton from "../../../components/Button/CustomButton";
import { UPDATE_ORGANIZATION_GQL } from "../../../Apollo/ApplicationService/OrganizationResponse";
import {
  OrgImageFormat,
  organizationEmployeeStatus,
  organizationOnboardStatus,
} from "../../../constants";
import SingleMediaFileUpload from "../../../components/MediaFileUpload/SingleMediaFileUpload";
import Description from "../../../components/Input/Description";
import { getDescription } from "../../../utils/Functions/filter";
import { useEffect } from "react";
import { useState } from "react";
import useCountryList from "../../../utils/CustomHooks/useCountryList";
import useCityList from "../../../utils/CustomHooks/useCityList";

export default function EditOrganization({
  values = {},
  isEditable = false,
  refresh = () => {},
  onClose = () => {},
  countryOption = [],
  setLoading = () => {},
}) {
  const formik = useFormik({
    initialValues: {},
    validationSchema: validationSchema,
    onSubmit: (values) => {
      onSubmit(values);
    },
  });
  // console.log("formik", formik.values);

  const [cityOption, setCityOption] = useState([]);
  const { cityList, getCityList } = useCityList();
  useEffect(() => {
    if (values.organization_id) {
      // getCountryList();
      getCityList();
    }
  }, [values]);
  useEffect(() => {
    if (formik?.values?.organization_country_id)
      getCityList({ country_id: formik?.values?.organization_country_id });
  }, [formik?.values?.organization_country_id]);

  useEffect(() => {
    setCityOption(
      cityList
        .filter((x) => x.active === true)
        .sort((a, b) => a.sort_order - b.sort_order)
        .map((x) => ({
          label: x.city_name,
          value: x.city_id,
        }))
    );
  }, [cityList]);

  useEffect(() => {
    if (values.organization_id) {
      const value = {
        organization_id: values.organization_id,
        organization_name: values.organization_name,
        organization_email: values.organization_email,
        domain_name: values.domain_name,
        // organization_mobile_number: values.organization_mobile_number,
      };
      if (values.organization_mobile_number) {
        value.organization_mobile_number = values.organization_mobile_number;
      }
      if (values.organization_onboard_status) {
        value.organization_onboard_status = values.organization_onboard_status;
      }
      if (values.organization_status) {
        value.organization_status = values.organization_status;
      }
      if (values.email_domains) {
        value.email_domains = values.email_domains;
      }
      if (values?.organization_address?.line_1) {
        value.line_1 = values?.organization_address?.line_1;
      }
      if (values?.organization_address?.line_2) {
        value.line_2 = values?.organization_address?.line_2;
      }
      if (values?.organization_address?.postcode) {
        value.postcode = values?.organization_address?.postcode;
      }
      if (values?.organization_address?.region) {
        value.region = values?.organization_address?.region;
      }
      if (values?.organization_city_id) {
        value.organization_city_id = values?.organization_city_id;
      }
      if (values?.organization_country_id) {
        value.organization_country_id = values?.organization_country_id;
      }
      if (
        values?.organization_image_urls &&
        values?.organization_image_urls?.length > 0
      ) {
        value.media_datas = values.organization_image_urls.map((x) => {
          return {
            ...x,
            mediaUrl: {
              url: x.url,
              type: "Image",
            },
            media_format: x.image_type,
            media_device: x.is_mobile.toString(),
          };
        });
      }
      if (
        values.organization_description &&
        values.organization_description.length > 0
      ) {
        if (getDescription(values.organization_description, "Web")) {
          value.organization_description = getDescription(
            values.organization_description,
            "Web"
          ).description;
        }
        if (getDescription(values.organization_description, "Mobile")) {
          value.mobile_description = getDescription(
            values.organization_description,
            "Mobile"
          ).description;
        }
      }
      formik.setValues(value);
    }
  }, [values]);

  const onClosed = () => {
    onClose();
    formik.resetForm();
    formik.setSubmitting(false);
  };

  const onSubmit = async (value) => {
    formik.setSubmitting(true);
    const {
      organization_name,
      organization_email,
      organization_mobile_number,
      domain_name,
      organization_id,
      // media_datas,
      // mobile_description,
      // organization_description,
      organization_city_id,
      email_domains,
      organization_country_id,
      line_1,
      line_2,
      region,
      postcode,
      organization_onboard_status,
      organization_status,
    } = value;
    let update_request = {
      organization_id,
      email_domains
    };
    if (values.organization_onboard_status !== organization_onboard_status) {
      update_request.organization_onboard_status = organization_onboard_status;
    }
    if (values.organization_status !== organization_status) {
      update_request.organization_status = organization_status;
    }
    if (values.organization_name !== organization_name) {
      update_request.organization_name = organization_name;
    }
    if (values.organization_email !== organization_email) {
      update_request.organization_email = organization_email;
    }
    if (values.organization_mobile_number !== organization_mobile_number) {
      update_request.organization_mobile_number = organization_mobile_number;
    }
    if (values.domain_name !== domain_name) {
      update_request.domain_name = domain_name;
    }
    // let organization_image_urls = [];
    // if (media_datas && media_datas.length > 0) {
    //   media_datas.map((x) => {
    //     organization_image_urls.push({
    //       image_type: x.media_format,
    //       url: x.mediaUrl.url,
    //       is_mobile: x.media_device === "false" ? false : true,
    //     });
    //   });
    // }
    // if (organization_image_urls.length > 0) {
    //   update_request.organization_image_urls = organization_image_urls;
    // } else {
    //   update_request.organization_image_urls = [];
    // }

    // let description = [];
    // if (organization_description) {
    //   description.push({
    //     client_type: "Web",
    //     description: organization_description,
    //   });
    // }
    // if (mobile_description) {
    //   description.push({
    //     client_type: "Mobile",
    //     description: mobile_description,
    //   });
    // }
    // if (description.length > 0) {
    //   update_request.organization_description = description;
    // } else {
    //   update_request.organization_description = [];
    // }
    if (values.organization_country_id !== organization_country_id) {
      update_request.organization_country_id = organization_country_id;
    }
    if (values.organization_city_id !== organization_city_id) {
      update_request.organization_city_id = organization_city_id;
    }

    let organization_address = {};
    if (line_1) {
      organization_address.line_1 = line_1;
    }
    if (line_2) {
      organization_address.line_2 = line_2;
    }
    if (region) {
      organization_address.region = region;
    }
    if (postcode) {
      organization_address.postcode = postcode;
    }
    if (Object.keys(organization_address).length > 0) {
      update_request.organization_address = organization_address;
    }
    // console.log("update_request", update_request);
    try {
      await graphHandler().invoke(
        UPDATE_ORGANIZATION_GQL,
        { update_request },
        2,
        true
      );
      refresh();
      onClosed();
      setLoading(false);
      formik.setSubmitting(false);
    } catch (error) {
      console.error("createOrganization", error);
      formik.setSubmitting(false);
      setLoading(false);
    }
  };

  const handleError = () => {
    if (Object.keys(formik.errors).length > 0) {
      toast.error(formik.errors[Object.keys(formik.errors)[0]]);
    }
  };

  const handleFilter = (val) => {
    let data = formik.values?.email_domains.filter((x) => x !== val);
    formik.setFieldValue("email_domains", data);
  };

  return (
    // <Modal toggle={onClose} isOpen={isOpen} centered scrollable size="lg">
    //   <div className="modal-header">
    //     <button type="button" className="close" onClick={onClose}>
    //       <span aria-hidden="true">&times;</span>
    //     </button>
    //     <h5 className="modal-title">Edit Organization</h5>
    //   </div>
    //   <ModalBody className="pt-0">
    <div className="container">
      <div className="row justify-content-start">
      <div className="col-md-6 form-group">
          <label
            htmlFor="organization_name"
            className="col-form-label w-100 mb-2"
          >
            Name
          </label>
          <input
            type="text"
            className="form-control"
            id="organization_name"
            name="organization_name"
            // maxLength="30"
            value={
              formik.values && formik.values.organization_name
                ? formik.values.organization_name
                : ""
            }
            onChange={(e) =>
              formik.setFieldValue(
                "organization_name",
                e.target.value.trimStart()
              )
            }
            onBlur={(e) => {
              formik.setFieldTouched("organization_name", true);
              formik.setFieldValue(
                "organization_name",
                e.target.value.replace(/\s+/g, " ").trim()
              );
            }}
          />
          <small className="text-danger">
            {formik.touched.organization_name &&
            formik.errors &&
            formik.errors.organization_name
              ? formik.errors.organization_name
              : ""}
          </small>
        </div>
        <div className="col-md-6 form-group">
          <label
            htmlFor="organization_email"
            className="col-form-label mb-2 w-100"
          >
            Email
          </label>
          <input
            type="text"
            className="form-control"
            name="organization_email"
            maxLength="50"
            value={
              formik.values && formik.values.organization_email
                ? formik.values.organization_email
                : ""
            }
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <small className="text-danger">
            {formik.touched.organization_email &&
            formik.errors &&
            formik.errors.organization_email
              ? formik.errors.organization_email
              : ""}
          </small>
        </div>
        <div className="col-md-6 form-group">
          <label
            htmlFor="organization_mobile_number"
            className="col-form-label w-100 mb-2"
          >
            Mobile
          </label>
          <input
            type="text"
            className="form-control"
            name="organization_mobile_number"
            maxLength="10"
            value={
              formik.values && formik.values.organization_mobile_number
                ? formik.values.organization_mobile_number
                : ""
            }
            onChange={(e) =>
              formik.setFieldValue(
                "organization_mobile_number",
                e.target.value.replace(/[^0-9+]/gi, "")
              )
            }
            onBlur={formik.handleBlur}
          />
          <small className="text-danger">
            {formik.touched.organization_mobile_number &&
            formik.errors &&
            formik.errors.organization_mobile_number
              ? formik.errors.organization_mobile_number
              : ""}
          </small>
        </div>

        <div className="col-md-6 form-group">
          <label htmlFor="domain_name" className="col-form-label w-100 mb-2">
            Domain Name
          </label>
          <input
            type="text"
            className="form-control"
            name="domain_name"
            value={
              formik.values && formik.values.domain_name
                ? formik.values.domain_name
                : ""
            }
            onChange={(e) =>
              formik.setFieldValue("domain_name", e.target.value.trimStart())
            }
            onBlur={(e) => {
              formik.setFieldTouched("domain_name", true);
              formik.setFieldValue(
                "domain_name",
                e.target.value.replace(/\s+/g, " ").trim()
              );
            }}
          />
          <small className="text-danger">
            {formik.touched.domain_name &&
            formik.errors &&
            formik.errors.domain_name
              ? formik.errors.domain_name
              : ""}
          </small>
        </div>
        <div className="col-md-10 form-group">
          <label htmlFor="org_domain" className="col-form-label w-100 mb-2">
            Email Domains
          </label>
          <input
            type="text"
            className="form-control"
            name="org_domain"
            value={
              formik.values && formik.values.org_domain
                ? formik.values.org_domain
                : ""
            }
            onChange={(e) =>
              formik.setFieldValue("org_domain", e.target.value.trimStart())
            }
            onBlur={(e) => {
              formik.setFieldTouched("org_domain", true);
              formik.setFieldValue(
                "org_domain",
                e.target.value.replace(/\s+/g, " ").trim()
              );
            }}
          />
        </div>
        <div className="col-md-2 d-flex align-items-center justify-content-center mt-1">
          <CustomButton
            className="btn-sm btn btn-primary btn-outline-success"
            disabled={!formik.values.org_domain}
            onClick={async () => {
              let data = [
                ...formik.values.email_domains,
                formik.values.org_domain,
              ];
              await formik.setFieldValue("email_domains", data);
              formik.setFieldValue("org_domain", "");
            }}
          >
            {" "}
            Add{" "}
          </CustomButton>
        </div>
        <div
          className="col-md-12 form-group d-flex flex-wrap"
          style={{ gap: "0.5rem" }}
        >
          {formik.values?.email_domains &&
            formik.values?.email_domains?.length > 0 && (
              <div className="d-flex flex-wrap w-100" style={{ gap: "1rem" }}>
                {formik.values?.email_domains.map((x, i) => (
                  <span className="good text-center btn btn-sm" key={i}>
                  {x}{" "}
                  <span
                    className="badge border border-dark border-5"
                    onClick={() => handleFilter(x)}
                  >
                    X
                  </span>
                </span>
                ))}
              </div>
            )}
        </div>
        {/* <div > */}

        <div className="col-md-6 form-group">
          <label
            htmlFor="organization_onboard_status"
            className="col-form-label w-100 mb-2"
          >
            Organization Onboarding Status
          </label>
          <select
            id="organization_onboard_status"
            name="organization_onboard_status"
            className="form-control"
            onChange={formik.handleChange}
            value={formik.values.organization_onboard_status}
            onBlur={formik.handleBlur}
          >
            <option key="Select" hidden>
              Select
            </option>
            {organizationOnboardStatus.map((x, i) => (
              <option value={x.value} key={i}>
                {x.label}
              </option>
            ))}
          </select>
          <small className="text-danger">
            {formik.touched.organization_onboard_status &&
            formik.errors &&
            formik.errors.organization_onboard_status
              ? formik.errors.organization_onboard_status
              : ""}
          </small>
        </div>

        <div className="col-md-6 form-group">
          <label
            htmlFor="organization_status"
            className="col-form-label w-100 mb-2"
          >
            Organization Status
          </label>
          <select
            id="organization_status"
            name="organization_status"
            className="form-control"
            onChange={formik.handleChange}
            value={formik.values.organization_status}
            onBlur={formik.handleBlur}
          >
            <option key="Select" hidden>
              Select
            </option>
            {organizationEmployeeStatus.map((x, i) => (
              <option value={x.value} key={i}>
                {x.label}
              </option>
            ))}
          </select>
          <small className="text-danger">
            {formik.touched.organization_status &&
            formik.errors &&
            formik.errors.organization_status
              ? formik.errors.organization_status
              : ""}
          </small>
        </div>

        {/* <Description
          col="col-md-6"
          formik={formik}
          webName="organization_description"
        />
        <div className="col-md-12">
          <SingleMediaFileUpload
            formik={formik}
            heading="Image File"
            contentId={values.organization_id}
            contentType="ORGANIZATION"
            mediaType="Image"
            parentArray="media_datas"
            mediaFormat={OrgImageFormat}
          />
        </div> */}
        <label
          htmlFor="organization_city_id"
          className="col-form-label w-100 mb-2  ml-3"
        >
          Address:
        </label>
        <div className="col-md-6 form-group">
          <label htmlFor="line_1" className="col-form-label w-100 mb-2">
            Address Line 1
          </label>
          <input
            type="text"
            className="form-control"
            name="line_1"
            value={
              formik.values && formik.values.line_1 ? formik.values.line_1 : ""
            }
            onChange={(e) =>
              formik.setFieldValue("line_1", e.target.value.trimStart())
            }
            onBlur={(e) => {
              formik.setFieldTouched("line_1", true);
              formik.setFieldValue(
                "line_1",
                e.target.value.replace(/\s+/g, " ").trim()
              );
            }}
          />
          <small className="text-danger">
            {formik.touched.line_1 && formik.errors && formik.errors.line_1
              ? formik.errors.line_1
              : ""}
          </small>
        </div>
        <div className="col-md-6 form-group">
          <label htmlFor="line_2" className="col-form-label w-100 mb-2">
            Address Line 2
          </label>
          <input
            type="text"
            className="form-control"
            name="line_2"
            value={
              formik.values && formik.values.line_2 ? formik.values.line_2 : ""
            }
            onChange={(e) =>
              formik.setFieldValue("line_2", e.target.value.trimStart())
            }
            onBlur={(e) => {
              formik.setFieldTouched("line_2", true);
              formik.setFieldValue(
                "line_2",
                e.target.value.replace(/\s+/g, " ").trim()
              );
            }}
          />
          <small className="text-danger">
            {formik.touched.line_2 && formik.errors && formik.errors.line_2
              ? formik.errors.line_2
              : ""}
          </small>
        </div>
        <div className="col-md-6 form-group">
          <label htmlFor="region" className="col-form-label w-100 mb-2">
            Region
          </label>
          <input
            type="text"
            className="form-control"
            name="region"
            value={
              formik.values && formik.values.region ? formik.values.region : ""
            }
            onChange={(e) =>
              formik.setFieldValue("region", e.target.value.trimStart())
            }
            onBlur={(e) => {
              formik.setFieldTouched("region", true);
              formik.setFieldValue(
                "region",
                e.target.value.replace(/\s+/g, " ").trim()
              );
            }}
          />
          <small className="text-danger">
            {formik.touched.region && formik.errors && formik.errors.region
              ? formik.errors.region
              : ""}
          </small>
        </div>
        <div className="col-md-6 form-group">
          <label htmlFor="postcode" className="col-form-label w-100 mb-2">
            Postcode
          </label>
          <input
            type="text"
            className="form-control"
            name="postcode"
            value={
              formik.values && formik.values.postcode
                ? formik.values.postcode
                : ""
            }
            onChange={(e) =>
              formik.setFieldValue("postcode", e.target.value.trimStart())
            }
            onBlur={(e) => {
              formik.setFieldTouched("postcode", true);
              formik.setFieldValue(
                "postcode",
                e.target.value.replace(/\s+/g, " ").trim()
              );
            }}
          />
          <small className="text-danger">
            {formik.touched.postcode && formik.errors && formik.errors.postcode
              ? formik.errors.postcode
              : ""}
          </small>
        </div>
        <div className="col-md-6 form-group">
          <label
            htmlFor="organization_country_id"
            className="col-form-label w-100 mb-2"
          >
            Organization Country
          </label>
          <select
            id="organization_country_id"
            name="organization_country_id"
            className="form-control"
            onChange={formik.handleChange}
            value={formik.values.organization_country_id}
            onBlur={formik.handleBlur}
          >
            <option key="Select" hidden>
              Select
            </option>
            {countryOption.map((x, i) => (
              <option value={x.value} key={i}>
                {x.label}
              </option>
            ))}
          </select>
          <small className="text-danger">
            {formik.touched.organization_country_id &&
            formik.errors &&
            formik.errors.organization_country_id
              ? formik.errors.organization_country_id
              : ""}
          </small>
        </div>
        <div className="col-md-6 form-group">
          <label
            htmlFor="organization_city_id"
            className="col-form-label w-100 mb-2"
          >
            Organization City
          </label>
          <select
            id="organization_city_id"
            name="organization_city_id"
            className="form-control"
            onChange={formik.handleChange}
            value={formik.values.organization_city_id}
            onBlur={formik.handleBlur}
          >
            <option key="Select" hidden>
              Select
            </option>
            {cityOption.map((x, i) => (
              <option value={x.value} key={i}>
                {x.label}
              </option>
            ))}
          </select>
          <small className="text-danger">
            {formik.touched.organization_city_id &&
            formik.errors &&
            formik.errors.organization_city_id
              ? formik.errors.organization_city_id
              : ""}
          </small>
        </div>

        <div className="d-flex align-items-end justify-content-end col-md-12 mt-3">
          <button
            onClick={onClosed}
            type="button"
            className="btn btn-danger btn-sm float-right btn-add-domain mr-2"
          >
            Close
          </button>
          <CustomButton
            type="button"
            className="btn btn-primary btn-outline-success btn-sm float-right btn-add-domain"
            disabled={
              Object.keys(formik.errors).length !== 0 ||
              !formik.isValid ||
              formik.isSubmitting
            }
            onClick={
              formik.isValid && Object.keys(formik.values).length !== 0
                ? formik.handleSubmit
                : handleError
            }
          >
            {formik.values.organization_id ? "Update" : "Create"}
          </CustomButton>
        </div>
      </div>
    </div>
    //   </ModalBody>
    // </Modal>
  );
}

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const validationSchema = Yup.object().shape({
  organization_name: Yup.string().required("Enter name"),
  organization_email: Yup.string()
    .email("Invalid email")
    .required("Enter a email"),
  organization_mobile_number: Yup.string()
    // .required("Enter mobile number")
    .matches(phoneRegExp, "Mobile number is not valid"),
  domain_name: Yup.string().required("Domain is required"),
  line_1: Yup.string(),
  // .required("Address is required"),
  postcode: Yup.string(),
  // .required("Postcode is required"),
  organization_city_id: Yup.string().required("City is required"),
  organization_country_id: Yup.string().required("Country is required"),
  organization_onboard_status: Yup.string().required(
    "Onboard status is required"
  ),
  email_domains: Yup.array().test({
    message: "Enter Email Domains",
    test: (arr) => {
      return arr && arr.length > 0;
    },
  }),
  organization_status: Yup.string().required("Status is required"),
  // organization_description: Yup.string().trim().required("Enter description"),
  // mobile_description: Yup.string().trim().required("Enter mobile description"),
});
