import { useEffect } from "react";
import { Modal, ModalBody } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import graphHandler from "../../../Apollo/graphHandler";
import {
  CREATE_AUTHOR_GQL,
  UPDATE_AUTHOR_GQL,
} from "../../../Apollo/AdminServices/MutationRequest";
import {
  getDescription,
  removeEmptyObjValue,
} from "../../../utils/Functions/filter";
import { SocialProvider } from "../../../constants";
import Description from "../../../components/Input/Description";
import CustomButton from "../../../components/Button/CustomButton";
import SingleImageUpload from "../../../components/MediaFileUpload/SingleImageUpload";

export default function AuthorModal({
  isOpen,
  setOpen,
  refresh = () => {},
  defaultValues = {},
}) {
  useEffect(() => {
    if (defaultValues.author_id) {
      onEdit(defaultValues);
    }
  }, [defaultValues, isOpen]);

  const formik = useFormik({
    initialValues: {},
    validationSchema: validation,
    onSubmit: (values) => {
      onSubmit(values);
    },
  });

  const onClose = () => {
    formik.resetForm();
    setOpen();
  };

  const onSubmit = async (values) => {
    const {
      author_name,
      author_id,
      description,
      author_code,
      mobile_description,
      author_social_urls,
      author_image_urls,
    } = values;
    let request = {
      author_name,
      author_social_urls:
        author_social_urls && author_social_urls.length > 0
          ? author_social_urls
          : [],
    };
    if (author_code && !author_id) {
      request.author_code = author_code;
    }
    if (author_name === defaultValues.author_name && author_id) {
      delete request.author_name;
    }
    let author_description = [];
    if (description) {
      author_description.push({ client_type: "Web", description });
    }
    if (mobile_description) {
      author_description.push({
        client_type: "Mobile",
        description: mobile_description,
      });
    }
    if (author_description.length > 0) {
      request.author_description = author_description;
    } else {
      request.author_description = [];
    }
    if (author_image_urls && author_image_urls.length > 0) {
      request.author_image_urls = author_image_urls.map((x) => {
        delete x.index;
        return removeEmptyObjValue(x);
      });
    } else {
      request.author_image_urls = [];
    }
    if (author_id) {
      onUpdate({ author_id, ...request });
    } else {
      onCreate({ ...request });
    }
  };

  const onCreate = async (addReq = {}) => {
    try {
      await graphHandler().invoke(CREATE_AUTHOR_GQL, { addReq }, 1, true);
      refresh();
      onClose();
      formik.setSubmitting(false);
    } catch (error) {
      console.error("create User", error);
      formik.setSubmitting(false);
    }
  };

  const onUpdate = async (update_request = {}) => {
    try {
      await graphHandler().invoke(
        UPDATE_AUTHOR_GQL,
        { update_request },
        1,
        true
      );
      refresh();
      onClose();
      formik.setSubmitting(false);
    } catch (error) {
      console.error("update Author", error);
      formik.setSubmitting(false);
    }
  };

  const onEdit = (item) => {
    if (item.author_id) {
      let model = {
        author_id: item.author_id,
        author_name: item.author_name,
        author_code: item.author_code,
        author_social_urls:
          item.author_social_urls && item.author_social_urls.length > 0
            ? item.author_social_urls
            : [],
      };
      if (item.author_description && item.author_description.length > 0) {
        if (getDescription(item.author_description, "Web")) {
          model.description = getDescription(
            item.author_description,
            "Web"
          ).description;
        }
        if (getDescription(item.author_description, "Mobile")) {
          model.mobile_description = getDescription(
            item.author_description,
            "Mobile"
          ).description;
        }
      }
      if (item.author_image_urls && item.author_image_urls.length > 0) {
        model.author_image_urls = item.author_image_urls;
      }
      formik.setValues(model);
    }
  };

  const handleError = () => {
    if (Object.keys(formik.errors).length > 0) {
      toast.error(formik.errors[Object.keys(formik.errors)[0]]);
    }
  };

  const AddLinks = () => {
    if (
      !formik.values ||
      !formik.values.provider ||
      !formik.values.link ||
      formik.errors.provider ||
      formik.errors.link
    )
      return;
    let author_social_urls = [
      ...(formik.values.author_social_urls &&
      formik.values.author_social_urls.length > 0
        ? formik.values.author_social_urls
        : []
      ).map((x) => x),
    ];
    author_social_urls.push({
      provider: formik.values.provider,
      link: formik.values.link,
    });
    formik.setFieldValue("author_social_urls", author_social_urls);
    formik.setFieldValue("provider", "");
    formik.setFieldValue("link", "");
  };

  const editLinks = (data, i, edit = true) => {
    if (!data.provider || !data.link) return;
    let author_social_urls = [
      ...(formik.values.author_social_urls &&
      formik.values.author_social_urls.length > 0
        ? formik.values.author_social_urls
        : []
      ).map((x) => x),
    ];
    author_social_urls.splice(i, 1);
    formik.setFieldValue("author_social_urls", author_social_urls);
    if (edit) {
      formik.setFieldValue("provider", data.provider);
      formik.setFieldValue("link", data.link);
    }
  };

  return (
    <Modal toggle={onClose} isOpen={isOpen} centered scrollable size="xl">
      <div className="modal-header">
        <button type="button" className="close" onClick={onClose}>
          <span aria-hidden="true">&times;</span>
        </button>
        <h5 className="modal-title">
          {formik.values.author_id ? "Update " : "Add "}Author
        </h5>
      </div>
      <ModalBody className="pt-0">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12 form-group">
              <label
                htmlFor="author_name"
                className="col-form-label w-100 mb-2"
              >
                Name
              </label>
              <input
                type="text"
                className="form-control"
                id="author_name"
                name="author_name"
                // maxLength="30"
                value={
                  formik.values && formik.values.author_name
                    ? formik.values.author_name
                    : ""
                }
                onChange={(e) =>
                  formik.setFieldValue(
                    "author_name",
                    e.target.value.trimStart()
                  )
                }
                onBlur={(e) => {
                  formik.setFieldTouched("author_name", true);
                  formik.setFieldValue(
                    "author_name",
                    e.target.value.replace(/\s+/g, " ").trim()
                  );
                }}
              />
              <small className="text-danger">
                {formik.touched.author_name &&
                formik.errors &&
                formik.errors.author_name
                  ? formik.errors.author_name
                  : ""}
              </small>
            </div>
            <div className="form-group col-md-12">
              <label
                htmlFor="author_code"
                className="col-form-label w-100 mb-2"
              >
                Code
              </label>
              <input
                type="text"
                className="form-control"
                id="author_code"
                name="author_code"
                // maxLength="30"
                value={formik.values.author_code || ""}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                disabled={defaultValues.author_id}
              />
              <small className="text-danger">
                {formik.touched.author_code &&
                formik.errors &&
                formik.errors.author_code
                  ? formik.errors.author_code
                  : ""}
              </small>
            </div>
            <Description formik={formik} />

            {defaultValues.author_id && defaultValues.author_code ? (
              <>
                <div className="col-md-12">
                  <SingleImageUpload
                    formik={formik}
                    heading="Image File"
                    contentId={defaultValues.author_id}
                    contentType="AUTHOR"
                    mediaType="Image"
                    parentArray="author_image_urls"
                  />
                </div>
              </>
            ) : null}

            <div className="col-md-12 form-group mb-0">
              <label className="col-form-label col-md-12 w-100 mb-2">
                Social Links
              </label>
              <div className="row mb-3">
                <div className="col-md-4 pr-1">
                  <select
                    className="custom-select"
                    name="provider"
                    id="provider"
                    onChange={formik.handleChange}
                    value={formik.values.provider ? formik.values.provider : ""}
                    onBlur={formik.handleBlur}
                  >
                    <option key="Select" hidden>
                      Select
                    </option>
                    {SocialProvider.filter(
                      (x) =>
                        [
                          ...(formik.values.author_social_urls &&
                          formik.values.author_social_urls.length > 0
                            ? formik.values.author_social_urls
                            : []),
                        ].filter((y) => y.provider === x).length === 0
                    ).map((x, i) => (
                      <option value={x} key={i}>
                        {x}
                      </option>
                    ))}
                  </select>
                  <small className="text-danger col-md-12">
                    {formik.touched.provider && formik.errors.provider
                      ? formik.errors.provider
                      : ""}
                  </small>
                </div>
                <div className="col-md-4 pr-1">
                  <input
                    type="text"
                    className="form-control"
                    name="link"
                    id="link"
                    onChange={formik.handleChange}
                    placeholder=""
                    value={formik.values.link ? formik.values.link : ""}
                    onBlur={formik.handleBlur}
                  />
                  <small className="text-danger">
                    {formik.touched.link && formik.errors.link
                      ? formik.errors.link
                      : ""}
                  </small>
                </div>

                <div className=" col-md-4 ">
                  <button
                    className="btn btn-dark"
                    disabled={
                      !formik.values ||
                      !formik.values.provider ||
                      !formik.values.link ||
                      formik.errors.provider ||
                      formik.errors.link
                    }
                    onClick={AddLinks}
                  >
                    Add Link <i className="fas fa-plus" />
                  </button>
                </div>
              </div>

              {formik.values &&
              formik.values.author_social_urls &&
              formik.values.author_social_urls.length > 0 ? (
                <div className="table-responsive border mb-3">
                  <table
                    className="table table-borderless overflow-auto questionnaire-table"
                    style={{ maxHeight: "200px" }}
                  >
                    <thead>
                      <tr className=" ">
                        <th className="col-sm-3">Provider</th>
                        <th className="col-sm-6">Link</th>
                        <th className="col-sm-3">Link</th>
                      </tr>
                    </thead>
                    <tbody>
                      {formik.values.author_social_urls.slice().map((x, i) => (
                        <tr key={i}>
                          <td className="">{x.provider}</td>
                          <td className="">{x.link}</td>
                          <td className="d-flex flex-row justify-content-around align-items-center">
                            <i
                              className="fas fa-edit cursor-pointer"
                              onClick={() => editLinks(x, i)}
                            ></i>
                            <i
                              className="fas fa-times cursor-pointer"
                              onClick={() => editLinks(x, i, false)}
                            ></i>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ) : null}
            </div>
            <CustomButton
              type="button"
              className="btn btn-primary btn-outline-success float-right btn-add-domain"
              disabled={
                Object.keys(formik.errors).length !== 0 ||
                !formik.isValid ||
                formik.isSubmitting
              }
              onClick={
                formik.isValid && Object.keys(formik.values).length !== 0
                  ? formik.handleSubmit
                  : handleError
              }
            >
              {formik.values.author_id ? "Update" : "Create"}
            </CustomButton>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
}

const validation = Yup.object().shape({
  description: Yup.string(),
  mobile_description: Yup.string(),
  author_name: Yup.string().required("Enter a name"),
  // .min(4, "Name should be min of 4 characters")
  // .max(30, "Name should be maximum of 30 characters")
  author_code: Yup.string().required("Enter a code"),
  provider: Yup.string(),
  link: Yup.string().matches(
    /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i,
    // /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/,
    "Enter a valid url!"
  ),
  author_social_urls: Yup.array().of(
    Yup.object().shape({
      provider: Yup.string(),
      link: Yup.string().matches(
        /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i,
        // /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/,
        "Enter a valid url!"
      ),
    })
  ),
});
