import { Modal, ModalBody } from "reactstrap";
import ReactJsonViewCompare from "react-json-view-compare";

export default function JsonCompareModal({
  isOpen,
  setOpen,
  actualDate = {},
  newDate = {},
  title = "Compare",
}) {
  const onClose = () => {
    setOpen(false);
  };
  return (
    <Modal toggle={ onClose } isOpen={ isOpen } centered scrollable size="lg">
      <div className="modal-header pb-0">
        <button type="button" className="close" onClick={ onClose }>
          <span aria-hidden="true">&times;</span>
        </button>
        <h5 className="modal-title">{title}</h5>
      </div>
      <ModalBody>
        <ReactJsonViewCompare oldData={ actualDate } newData={ newDate } />
        <button
          type="button"
          className="btn btn-primary btn-outline-success btn-sm float-right btn-add-domain"
          onClick={ onClose }
        >
                  Close
        </button>
      </ModalBody>
    </Modal>
  );
}
