import { useState } from "react";
import {
  ACTIVE_SCREENER_GQL,
  APPROVE_SCREENER_GQL,
  REMOVE_QUESTION_GQL,
  REMOVE_SCREENER_RESULT_RANGE_GQL,
  REMOVE_SCREENER_THRESHOLD_GQL,
  REMOVE_TOPIC_GQL,
  REVIEW_SCREENER_GQL,
} from "../../Apollo/AdminServices/MutationRequest";
import graphHandler from "../../Apollo/graphHandler";
function useScreenerAPI() {
  const [loading, setLoading] = useState(false);
  const approveScreener = async (req = {}, callback = () => {}) => {
    setLoading(true);
    try {
      const { data } = await graphHandler().invoke(
        APPROVE_SCREENER_GQL,
        req,
        1,
        false,
        true
      );
      // console.log("approveScreener Success", data.approveScreenerQuestion);
      callback();
      setLoading(false);
    } catch (error) {
      console.log("approveScreener Error", error);
      setLoading(false);
    }
  };
  const reviewScreener = async (req = {}, callback = () => {}) => {
    setLoading(true);
    try {
      const { data } = await graphHandler().invoke(
        REVIEW_SCREENER_GQL,
        req,
        1,
        false,
        true
      );

      // console.log("reviewScreener Success", data.reviewScreenerQuestion);
      callback();
      setLoading(false);
    } catch (error) {
      console.log("reviewScreener Error", error);
      setLoading(false);
    }
  };
  const activateScreener = async (req = {}, callback = () => {}) => {
    setLoading(true);
    try {
      const { data } = await graphHandler().invoke(
        ACTIVE_SCREENER_GQL,
        req,
        1,
        false,
        true
      );
      // console.log("activateScreener Success", data.activateScreener);
      callback();
      setLoading(false);
    } catch (error) {
      console.log("activateScreener Error", error);
      setLoading(false);
    }
  };

  const removeTopic = async (
    req = {},
    callback = () => {},
    eCallBack = () => {}
  ) => {
    setLoading(true);
    try {
      const { data } = await graphHandler().invoke(
        REMOVE_TOPIC_GQL,
        req,
        1,
        false,
        true
      );
      // console.log("removeTopic Success", data.removeScreenerTopic);
      callback();
      setLoading(false);
    } catch (error) {
      eCallBack(error);
      console.log("removeTopic Error", error);
      setLoading(false);
    }
  };

  const removeQuestion = async (
    req = {},
    callback = () => {},
    eCallBack = () => {}
  ) => {
    setLoading(true);
    try {
      const { data } = await graphHandler().invoke(
        REMOVE_QUESTION_GQL,
        req,
        1,
        false,
        true
      );
      // console.log("removeQuestion Success", data.removeScreenerQuestion);
      callback();
      setLoading(false);
    } catch (error) {
      eCallBack(error);
      console.log("removeQuestion Error", error);
      setLoading(false);
    }
  };
  const removeResultRange = async (
    req = {},
    callback = () => {},
    eCallBack = () => {}
  ) => {
    setLoading(true);
    try {
      const { data } = await graphHandler().invoke(
        REMOVE_SCREENER_RESULT_RANGE_GQL,
        req,
        1,
        false,
        true
      );
      // console.log("removeResultRange Success", data.removeScreenerResultRange);
      callback();
      setLoading(false);
    } catch (error) {
      eCallBack(error);
      console.log("removeResultRange Error", error);
      setLoading(false);
    }
  };
  const removeThreshold = async (
    req = {},
    callback = () => {},
    eCallBack = () => {}
  ) => {
    setLoading(true);
    try {
      const { data } = await graphHandler().invoke(
        REMOVE_SCREENER_THRESHOLD_GQL,
        req,
        1,
        false,
        true
      );
      // console.log("removeThreshold Success", data.removeScreenerThreshold);
      callback();
      setLoading(false);
    } catch (error) {
      eCallBack(error);
      console.log("removeThreshold Error", error);
      setLoading(false);
    }
  };
  return {
    approveScreener,
    loading,
    reviewScreener,
    activateScreener,
    removeQuestion,
    removeTopic,
    removeResultRange,
    removeThreshold,
  };
}
export default useScreenerAPI;
