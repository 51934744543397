import { useState } from "react";
import {
  ACTIVE_BLOG_GQL,
  APPROVE_BLOG_GQL,
  REVIEW_BLOG_GQL,
} from "../../Apollo/AdminServices/MutationRequest";
import graphHandler from "../../Apollo/graphHandler";
function useBlogAPI() {
  const [loading, setLoading] = useState(false);
  const approveBlog = async (req = {}, callback = () => {}) => {
    setLoading(true);
    try {
      const { data } = await graphHandler().invoke(
        APPROVE_BLOG_GQL,
        req,
        1,
        false,
        true
      );
      // console.log("approveBlog Success", data.approveBlog);
      callback();
      setLoading(false);
    } catch (error) {
      console.log("approveBlog Error", error);
      setLoading(false);
    }
  };
  const reviewBlog = async (req = {}, callback = () => {}) => {
    setLoading(true);
    try {
      const { data } = await graphHandler().invoke(
        REVIEW_BLOG_GQL,
        req,
        1,
        false,
        true
      );

      // console.log("reviewBlog Success", data.reviewBlog);
      callback();
      setLoading(false);
    } catch (error) {
      console.log("reviewBlog Error", error);
      setLoading(false);
    }
  };
  const activateBlog = async (req = {}, callback = () => {}) => {
    setLoading(true);
    try {
      const { data } = await graphHandler().invoke(
        ACTIVE_BLOG_GQL,
        req,
        1,
        false,
        true
      );
      // console.log("activateBlog Success", data.activateBlog);
      callback();
      setLoading(false);
    } catch (error) {
      console.log("activateBlog Error", error);
      setLoading(false);
    }
  };
  return {
    approveBlog,
    loading,
    reviewBlog,
    activateBlog,
  };
}
export default useBlogAPI;
