import React from "react";
import MdEditor from "react-markdown-editor-lite";
import MarkdownIt from "markdown-it";
import "react-markdown-editor-lite/lib/index.css";
import "./mdEditor.css";
const mdParser = new MarkdownIt(/* Markdown-it options */);

export default function MDEditorFormik({
  formik,
  id = "id",
  label = "label",
  props = {},
  className = "col-md-6",
}) {
  return (
    <div className={` ${className}`}>
      <label htmlFor={id} className="col-form-label w-100 mb-2">
        {label}
      </label>
      {/* <textarea
	className="form-control"
	placeholder=""
	id={id}
	name={id}
	value={formik.values && formik.values[id] ? formik.values[id] : ""}
	onBlur={formik.handleBlur}
	onChange={formik.handleChange}
	{...props}
  /> */}
      <MdEditor
        style={{ height: "50vh" }}
        // className="form-control"
        id={id}
        name={id}
        renderHTML={(text) => mdParser.render(text)}
        value={formik.values && formik.values[id] ? formik.values[id] : ""}
        onBlur={formik.handleBlur}
        onChange={({ text }) => formik.setFieldValue(id, text)}
      />
      {/* {formik.values && formik.values[id] ? (
        <div
          dangerouslySetInnerHTML={{
            __html: mdParser.render(formik.values[id]),
          }}
        ></div>
      ) : null} */}
      <small className="text-danger">
        {formik.touched[id] && formik.errors && formik.errors[id]
          ? formik.errors[id]
          : ""}
      </small>
    </div>
  );
}
{
  /* <MdEditor
+                style={{ height: "50vh" }}
+                renderHTML={(text) => mdParser.render(text)}
+                onChange={({ text }) =>
+                  formik.setFieldValue("blog_content", text)
+                }
+                onBlur={formik.handleBlur}
+                name="blog_content"
+              /> */
}
