import { useState } from "react";
import { REMOVE_RELATED_CONTENT_GQL } from "../../Apollo/AdminServices/RelatedContentMutationRequest";
import { GET_RELATED_CONTENT_LIST_GQL } from "../../Apollo/AdminServices/RelatedContentQueryRequest";
import graphHandler from "../../Apollo/graphHandler";

function useRelatedContentList(preload = false) {
  const [relatedContents, setRelatedContent] = useState([]);
  const [loading, setLoading] = useState(preload);

  const getRelatedContents = async (req = {}) => {
    setLoading(true);
    try {
      const { data } = await graphHandler().invoke(
        GET_RELATED_CONTENT_LIST_GQL,
        { ...req },
        1,
        false,
        true
      );
      // console.log("getRelatedContents Success", data);
      if (
        data &&
        data.getRelatedContentList &&
        data.getRelatedContentList.status === "Success" &&
        data.getRelatedContentList.related_contents &&
        data.getRelatedContentList.related_contents.length > 0
      ) {
        setRelatedContent(data.getRelatedContentList.related_contents);
        setLoading(false);
      } else {
        setRelatedContent([]);
        setLoading(false);
      }
    } catch (error) {
      console.log("getRelatedContents Error", error);
      setRelatedContent({});
      setLoading(false);
    }
  };

  const deleteRelatedContent = async (req = {}) => {
    setLoading(true);
    try {
      const { data } = await graphHandler().invoke(
        REMOVE_RELATED_CONTENT_GQL,
        { ...req },
        1,
        false,
        true
      );
      // console.log("delete Success", data);
      setLoading(false);
      return data
    } catch (error) {
      console.log("delete Error", error);
      setLoading(false);
    }
  };
  return {
    relatedContents,
    loading,
    deleteRelatedContent,
    getRelatedContents,
  };
}

export default useRelatedContentList;