import { useState } from "react";
import {
  ACTIVATE_INTERVENTION_GQL,
  APPROVE_INTERVENTION_GQL,
  REVIEW_INTERVENTION_GQL,
} from "../../Apollo/AdminServices/MutationRequest";
import graphHandler from "../../Apollo/graphHandler";
function useInterventionAPI() {
  const [loading, setLoading] = useState(false);
  const approveIntervention = async (req = {}, callback = () => {}) => {
    setLoading(true);
    try {
      const { data } = await graphHandler().invoke(
        APPROVE_INTERVENTION_GQL,
        req,
        1,
        false,
        true
      );
      // console.log("approveIntervention Success", data.approveIntervention);
      callback();
      setLoading(false);
    } catch (error) {
      console.log("approveIntervention Error", error);
      setLoading(false);
    }
  };
  const reviewIntervention = async (req = {}, callback = () => {}) => {
    setLoading(true);
    try {
      const { data } = await graphHandler().invoke(
        REVIEW_INTERVENTION_GQL,
        req,
        1,
        false,
        true
      );

      callback();
      setLoading(false);
    } catch (error) {
      console.log("reviewIntervention Error", error);
      setLoading(false);
    }
  };
  const activateIntervention = async (req = {}, callback = () => {}) => {
    setLoading(true);
    try {
      const { data } = await graphHandler().invoke(
        ACTIVATE_INTERVENTION_GQL,
        req,
        1,
        false,
        true
      );
      // console.log("activateIntervention Success", data.activateIntervention);
      callback();
      setLoading(false);
    } catch (error) {
      console.log("activateIntervention Error", error);
      setLoading(false);
    }
  };
  return {
    approveIntervention,
    loading,
    reviewIntervention,
    activateIntervention,
  };
}
export default useInterventionAPI;
