import { useState } from "react";
import { GET_TRANSACTION_LIST } from "../../Apollo/ApplicationService/OrganizationResponse";
import graphHandler from "../../Apollo/graphHandler";

function useTransactionList(preload = false) {
  const [transactionList, setTransactionList] = useState([]);
  const [loading, setLoading] = useState(preload);

  const getTransactionList = async (req = {}) => {
    setTransactionList([]);
    setLoading(true);
    try {
      const { data } = await graphHandler().invoke(
        GET_TRANSACTION_LIST,
        { ...req },
        2,
        false,
        true
      );
      if (
        data &&
        data.getTransactionList &&
        data.getTransactionList.Transactions &&
        data.getTransactionList.Transactions.length > 0
      ) {
         setTransactionList(
          data.getTransactionList.Transactions
            .slice()
            .sort(
              (a, b) =>
                new Date(b.updated_at ? b.updated_at : b.created_at) -
                new Date(a.updated_at ? a.updated_at : a.created_at)
            )
        );
        setLoading(false);
      } else {
        setTransactionList([]);
        setLoading(false);
      }
    } catch (error) {
      console.log("getTransactionList Error", error);
      setTransactionList([]);
      setLoading(false);
    }
  };

  return {
    transactionList,
    loading,
    getTransactionList,
  };
}

export default useTransactionList;
