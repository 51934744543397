import { useFormik } from "formik";
import { useState, useMemo } from "react";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { UPDATE_MODULE_ACTION_GQL } from "../../../Apollo/AdminServices/MutationRequest";
import graphHandler from "../../../Apollo/graphHandler";
import CustomButton from "../../../components/Button/CustomButton";
import DescriptionInputField from "../../../components/HistoryEditInput/DescriptionInputField";
import DocumentHistoryInput from "../../../components/HistoryEditInput/DocumentHistoryInput";
import ImageHistoryInput from "../../../components/HistoryEditInput/ImageHistoryInput";
import NewReactSelectInputField from "../../../components/HistoryEditInput/NewReactSelectInputField";
import SelectInputField from "../../../components/HistoryEditInput/SelectInputField";
import TextInputField from "../../../components/HistoryEditInput/TextInputField";
import VideoHistoryInput from "../../../components/HistoryEditInput/VideoHistoryInput";
import { mergeMultiSelectArray, objectHasKey, removeEmptyObjValue } from "../../../utils/Functions/filter";
import { ActionType } from "../../../constants";
import { useEffect } from "react";

export default function UpdateTherapyAction({
  refresh = () => {},
  subDomainList = [],
  setLoading = () => {},
  therapyAction = {},
  reset = () => {},
  moduleList = {},
  interventions = [],
  screeners = [],
}) {
  const [selectedSubDomainList, setSelectedSubDomainList] = useState([]);
  const pending_history = therapyAction.pending_history
    ? therapyAction.pending_history
    : {};
  let { therapy_module_action_id, sub_domain_ids } = therapyAction;
  const hasUpdated = Boolean(pending_history && pending_history.updated_data);
  const updated_data = hasUpdated ? pending_history.updated_data : {};
  const formik = useFormik({
    initialValues: {},
    onSubmit: (values) => {
      onSubmit(values);
    },
  });
//   console.log(therapyAction, formik.values, formik.errors);

  const action_type = formik.values.therapy_action_type
    ? formik.values.therapy_action_type
    : therapyAction.therapy_action_type;

  useEffect(() => {
    //Reset on edit
    formik.resetForm();
    formik.setValues({});
    formik.setSubmitting(false);
    if (therapy_module_action_id) {
      let publish = hasUpdated ? updated_data : {};
      if (subDomainList&&subDomainList.length > 0) {
        let subArray = [],
          sub_domains =
            sub_domain_ids && sub_domain_ids.length > 0 ? sub_domain_ids : [],
          publishedSub =
            publish.sub_domain_ids &&
            publish.sub_domain_ids.update_items &&
            publish.sub_domain_ids.update_items.length > 0
              ? publish.sub_domain_ids.update_items
              : [];
        let value = mergeMultiSelectArray(sub_domains, publishedSub);
        let i = 0;
        subArray = value.map((x) => {
          let ret = {
            ...subDomainList.find((y) => y.value === x.data),
            ...x,
            index: i,
          };
          if (x.isRemoved) {
            ret.value =
              subDomainList.find((y) => y.value === x.data).label || "";
          } else {
            i++;
          }
          return ret;
        });
        setSelectedSubDomainList(subArray);
      }
    }
  }, [therapy_module_action_id,subDomainList]);

  useMemo(() => {
    let values = { ...formik.values };
    delete values.intervention_id;
    delete values.screener_id;
    delete values.therapy_module_action_image_urls;
    delete values.therapy_module_action_document_urls;
    delete values.therapy_module_action_video_urls;
    formik.setValues({ ...values });
  }, [formik.values.therapy_action_type]);

  const onSubmit = async (formikValues) => {
    formik.setSubmitting(false);
    let {
      sort_order,
      sub_domain_ids,
      therapy_action_type,
      intervention_id,
      screener_id,
      therapy_module_action_name,
      therapy_module_action_code,
      therapy_module_action_description,
      therapy_module_action_image_urls,
      therapy_module_action_video_urls,
      therapy_module_action_document_urls,
    } = formikValues;
    let update_request = {
      therapy_module_action_id,
    };
    if (therapy_action_type === "Intervention" && !intervention_id)
      return toast.warn("Intervention is required field");
    if (therapy_action_type === "Screener" && !screener_id)
      return toast.warn("Screener is required field");
    if (objectHasKey(formikValues, "therapy_module_action_name")) {
      update_request.therapy_module_action_name = therapy_module_action_name;
    }
    if (objectHasKey(formikValues, "therapy_module_action_code")) {
      update_request.therapy_module_action_code = therapy_module_action_code;
    }
    if (objectHasKey(formikValues, "therapy_action_type")) {
      update_request.therapy_action_type = therapy_action_type;
    }
    if (objectHasKey(formikValues, "sort_order")) {
      update_request.sort_order = Number(sort_order);
    }

    if (objectHasKey(formikValues, "sub_domain_ids")) {
      update_request.sub_domain_ids = { update_items: sub_domain_ids };
    }
    if (objectHasKey(formikValues, "therapy_module_action_description")) {
      update_request.therapy_module_action_description = {
        update_items: therapy_module_action_description,
      };
    }
    if (
      action_type === "Screener" &&
      objectHasKey(formikValues, "screener_id")
    ) {
      update_request.screener_id = screener_id;
    } else if (
      action_type === "Intervention" &&
      objectHasKey(formikValues, "intervention_id")
    ) {
      update_request.intervention_id = intervention_id;
    } else if (
      action_type === "Video" &&
      objectHasKey(formikValues, "therapy_module_action_video_urls")
    ) {
      update_request.therapy_module_action_video_urls = {
        update_items: therapy_module_action_video_urls.map((x) =>
          removeEmptyObjValue(x)
        ),
      };
    } else if (
      action_type === "Image" &&
      objectHasKey(formikValues, "therapy_module_action_image_urls")
    ) {
      update_request.therapy_module_action_image_urls = {
        update_items: therapy_module_action_image_urls.map((x) =>
          removeEmptyObjValue(x)
        ),
      };
    } else if (
      action_type === "Document" &&
      objectHasKey(formikValues, "therapy_module_action_document_urls")
    ) {
      update_request.therapy_module_action_document_urls = {
        update_items: therapy_module_action_document_urls.map((x) =>
          removeEmptyObjValue(x)
        ),
      };
    }
    formik.setSubmitting(true);
    try {
      await graphHandler().invoke(
        UPDATE_MODULE_ACTION_GQL,
        { update_request },
        1,
        false
      );
      toast.success("Updated Successfully");
      refresh();
      setLoading(false);
      formik.setSubmitting(false);
      resetModal();
    } catch (error) {
      console.log("updatePlanAction error", error);
      formik.setSubmitting(false);
    }
  };

  const handleError = () => {
    if (Object.keys(formik.errors).length > 0) {
      toast.error(formik.errors[Object.keys(formik.errors)[0]]);
    }
  };

  const resetModal = () => {
    formik.resetForm();
    formik.setValues({});
    formik.setSubmitting(false);
    reset();
  };

  return (
    <>
      <div className="col-md-12 modal-header" id="CreateTopic">
        <h5 className="modal-title text-center mb-3">Update Action</h5>
      </div>
      <div className="col-md-12">
        <div className="  border">
          <div className="row p-3">
            <div className="col-md-6">
              <div className="row">
                <NewReactSelectInputField
                  parentForm={formik}
                  id="sub_domain_ids"
                  title="Sub Domain"
                  placeholder="Select Sub Domain"
                  options={subDomainList}
                  isCreatable={false}
                  isMulti={true}
                  isPublished={therapyAction.published}
                  defaultValue={selectedSubDomainList}
                  updateValue={
                    updated_data.sub_domain_ids &&
                    updated_data.sub_domain_ids.update_items &&
                    updated_data.sub_domain_ids.update_items.length > 0
                      ? updated_data.sub_domain_ids.update_items
                      : false
                  }
                  validation={{
                    sub_domain_ids: Yup.array()
                      .of(
                        Yup.object().shape({
                          label: Yup.string(),
                          value: Yup.string(),
                        })
                      )
                      // .test({
                      //   message: "Select sub domain",
                      //   test: (arr) => {
                      //     return arr && arr.length > 0;
                      //   },
                      // }),
                  }}
                />
                <SelectInputField
                  parentForm={formik}
                  className="col-md-12"
                  updateValue={updated_data.therapy_module_id}
                  actualValue={therapyAction.therapy_module_id}
                  id="therapy_module_id"
                  title="Therapy Group"
                  validation={{
                    therapy_module_id: Yup.string().required("Select group"),
                  }}
                  options={[
                    { label: "Select", value: "", props: { hidden: true } },
                    ...moduleList,
                  ]}
                />
                <TextInputField
                  parentForm={formik}
                  className="col-md-12"
                  updateValue={updated_data.therapy_module_action_name}
                  actualValue={therapyAction.therapy_module_action_name}
                  id="therapy_module_action_name"
                  title="Action Name"
                  validation={{
                    therapy_module_action_name: Yup.string()
                      .trim()
                      .required("Enter action name"),
                  }}
                />
                <div className="col-md-12">
                  <div className="row">
                    <TextInputField
                      parentForm={formik}
                      className="col-md-6"
                      updateValue={updated_data.therapy_module_action_code}
                      actualValue={therapyAction.therapy_module_action_code}
                      id="therapy_module_action_code"
                      title="Code"
                      disabled={true}
                      validation={{
                        therapy_module_action_code:
                          Yup.string().required("Enter action code"),
                      }}
                    />
                    <TextInputField
                      parentForm={formik}
                      className="col-md-6"
                      updateValue={updated_data.sort_order}
                      actualValue={therapyAction.sort_order}
                      id="sort_order"
                      title="Sort Order"
                      validation={{
                        sort_order: Yup.string().required("Enter sort order"),
                      }}
                      isNumber={true}
                      inputProps={{
                        maxLength: 10,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="row">
                <DescriptionInputField
                  parentForm={formik}
                  parentKey="therapy_module_action_description"
                  className="col-md-12"
                  updateValue={
                    updated_data.therapy_module_action_description &&
                    updated_data.therapy_module_action_description.update_items
                      ? updated_data.therapy_module_action_description
                          .update_items
                      : false
                  }
                  actualValue={therapyAction.therapy_module_action_description}
                  validation={{
                    description: Yup.string()
                      .trim()
                      .required("Description is required"),
                    mobile_description: Yup.string()
                      .trim()
                      .required("Mobile description is required"),
                  }}
                  showEditor={true}
                  refreshId={therapy_module_action_id}
                />
              </div>
            </div>
            <SelectInputField
              parentForm={formik}
              className="col-md-6"
              updateValue={updated_data.therapy_action_type}
              actualValue={therapyAction.therapy_action_type}
              id="therapy_action_type"
              title="Action Type"
              disabled={therapyAction.therapy_module_action_id}
              validation={{
                therapy_action_type: Yup.string().required("Select type"),
              }}
              options={[
                { label: "Select", value: "", props: { hidden: true } },
                ...ActionType,
              ]}
            />
              <RenderActionInput
                type={action_type}
                formik={formik}
                updated_data={updated_data}
                therapyAction={therapyAction}
                interventions={interventions}
                screeners={screeners}
              />
            <div
              className={"d-flex align-items-end justify-content-end col-md-12"}
            >
              <button
                onClick={resetModal}
                type="button"
                className="btn btn-light btn-sm float-right btn-add-domain mr-2"
              >
                Reset
              </button>
              <CustomButton
                type="button"
                className="btn btn-primary btn-outline-success btn-sm float-right btn-add-domain"
                disabled={!formik.isValid || formik.isSubmitting}
                onClick={
                  formik.isValid && Object.keys(formik.values).length !== 0
                    ? formik.handleSubmit
                    : handleError
                }
              >
                Update
              </CustomButton>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const RenderActionInput = ({
  type,
  formik,
  updated_data,
  therapyAction,
  interventions,
  screeners,
}) => {
  const fieldName = type === "Intervention" ? "intervention_id" : "screener_id";
  if (type === "Intervention" || type === "Screener") {
    return (
      <SelectInputField
        parentForm={formik}
        className="col-md-6"
        updateValue={updated_data[fieldName]}
        actualValue={therapyAction[fieldName] ? therapyAction[fieldName] : ""}
        id={fieldName}
        title={type}
        validation={{
          [fieldName]: Yup.string().required(`Select a ${type}`),
        }}
        oldOptions={[
          { label: "Select", value: "", props: { hidden: true } },
          ...(therapyAction.therapy_action_type === "Intervention"
            ? interventions
            : screeners),
        ]}
        options={[
          { label: "Select", value: "", props: { hidden: true } },
          ...(type === "Intervention" ? interventions : screeners),
        ]}
      />
    );
  } else if (type === "Image") {
    return (
      <div className="col-md-12">
        <ImageHistoryInput
          parentForm={formik}
          contentId={therapyAction.therapy_module_action_id}
          contentType="THERAPYMODULEACTION"
          heading="Image File"
          parentArray="therapy_module_action_image_urls"
          actualValue={therapyAction.therapy_module_action_image_urls}
          updateValue={
            updated_data.therapy_module_action_image_urls &&
            updated_data.therapy_module_action_image_urls.update_items
              ? updated_data.therapy_module_action_image_urls.update_items
              : false
          }
          isPublished={therapyAction.published}
          validation={{
            therapy_module_action_image_urls: Yup.array().of(
              Yup.object().shape({
                is_mobile: Yup.boolean().required("Select a Image device"),
                image_type: Yup.string().required("Select a Image format"),
                url: Yup.string(),
              })
            ),
          }}
        />
      </div>
    );
  } else if (type === "Video") {
    return (
      <div className="col-md-12">
        <VideoHistoryInput
          parentForm={formik}
          contentId={therapyAction.therapy_module_action_id}
          contentType="THERAPYMODULEACTION"
          heading="Video File"
          parentArray="therapy_module_action_video_urls"
          actualValue={therapyAction.therapy_module_action_video_urls}
          isPublished={therapyAction.published}
          updateValue={
            updated_data.therapy_module_action_video_urls &&
            updated_data.therapy_module_action_video_urls.update_items
              ? updated_data.therapy_module_action_video_urls.update_items
              : false
          }
          validation={{
            therapy_module_action_video_urls: Yup.array().of(
              Yup.object().shape({
                is_mobile: Yup.boolean().required("Select a video device"),
                video_type: Yup.string().required("Select a video format"),
                video_url: Yup.string(),
              })
            ),
          }}
        />
      </div>
    );
  } else if (type === "Document") {
    return (
      <div className="col-md-12">
        <DocumentHistoryInput
          parentForm={formik}
          contentId={therapyAction.therapy_module_action_id}
          contentType="THERAPYMODULEACTION"
          parentArray="therapy_module_action_document_urls"
          actualValue={therapyAction.therapy_module_action_document_urls}
          updateValue={
            updated_data.therapy_module_action_document_urls &&
            updated_data.therapy_module_action_document_urls.update_items
              ? updated_data.therapy_module_action_document_urls.update_items
              : false
          }
          isPublished={therapyAction.published}
          validation={{
            therapy_module_action_document_urls: Yup.array().of(
              Yup.object().shape({
                is_mobile: Yup.boolean().required("Select a video device"),
                document_type: Yup.string().required("Select a video format"),
                url: Yup.string(),
                name: Yup.string(),
              })
            ),
          }}
        />
      </div>
    );
  } else return null;
};
