import React, { useEffect, useState } from "react";
import {
  Collapse,
  Card,
  CardBody,
  Tooltip,
  UncontrolledPopover,
} from "reactstrap";
import { getDescription } from "../../../utils/Functions/filter";
import ActionListItem from "./ActionListItem";
import useModuleActions from "../../../utils/CustomHooks/useModuleActions";
import ActionModal from "./ActionModal";
import { toast } from "react-toastify";

export default function ModuleListItem({
  item,
  onEdit,
  interventionList = [],
  screenerList = [],
  removeModules,
  removeActions,
  refresh,
}) {
  const [toggle, setToggle] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const [count, setCount] = useState(0);
  const { actionList, loading, getActionList } = useModuleActions();
  useEffect(() => {
    if (item.therapy_id && item.therapy_module_id) {
      getActionList({
        therapy_id: item.therapy_id,
        therapy_module_id: item.therapy_module_id,
        get_pending_update: true,
      });
    }
  }, [item, count]);
  const remove = () => {
    if (actionList.length > 0)
      return toast.warn("Remove all actions before removing modules");
    removeModules(item.therapy_module_id, () => {
      toast.success("Module removed");
      refresh();
    });
  };
  return (
    <>
      <tr onClick={() => setToggle((x) => !x)}>
        <td className="">{item.therapy_module_name}</td>
        <td className="">
          {item.therapy_module_description &&
          getDescription(item.therapy_module_description, "Web")
            ? getDescription(item.therapy_module_description, "Web").description
            : "-"}
        </td>
        <td className="">{item.min_completion_days}</td>
        <td className="">{item.max_completion_days}</td>
        <td className="">{Number(item.start_interval_seconds) / 86400}</td>
        {/* <td className="">
          {item.therapy_module_sessions
            ? item.therapy_module_sessions.length
            : 0}
        </td> */}
        <td className="">{item.sort_order}</td>
        <td className="d-flex flex-row">
          <i
            className="fas fa-edit cursor-pointer"
            onClick={() => {
              setToggle(false);
              onEdit(item);
            }}
          ></i>
          <i
            id="addActionButton"
            className="ml-2 fas fa-plus cursor-pointer"
            onClick={() => {
              setToggle(false);
              setOpen(true);
            }}
          ></i>
          <i className="ml-2 fas fa-trash cursor-pointer" onClick={remove}></i>
        </td>
        <UncontrolledPopover target="addActionButton" trigger="hover">
          Add actions for this module
        </UncontrolledPopover>
      </tr>
      <tr>
        <td colSpan="7" className={toggle ? "p-1" : "p-0"}>
          <Collapse isOpen={toggle}>
            <Card>
              <CardBody>
                <div className="table-responsive">
                  <table
                    className="table table-borderless mb-0"
                    style={{ maxHeight: "200px" }}
                  >
                    <thead>
                      <tr className=" ">
                        <td className="text-center">Action Name</td>
                        <td className="text-center">Action Type</td>
                        <td className="text-center">Data</td>
                        <td className="text-center">Order</td>
                        <td className="text-center">Action</td>
                      </tr>
                    </thead>
                    <tbody>
                      {!loading && item && actionList.length > 0 ? (
                        actionList
                          .slice()
                          .sort((a, b) => a.sort_order - b.sort_order)
                          .map((x, i) => (
                            <ActionListItem
                              key={i}
                              item={x}
                              refresh={() => setCount((x) => x + 1)}
                              {...{
                                screenerList,
                                actionList,
                                interventionList,
                                removeActions,
                              }}
                            />
                          ))
                      ) : (
                        <tr className="border-bottom">
                          <td className="text-center" colSpan="6">
                            {loading ? "Loading..." : "No Actions Found."}
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </CardBody>
            </Card>
          </Collapse>
        </td>
      </tr>
      <ActionModal
        isOpen={isOpen}
        setOpen={setOpen}
        refresh={() => setCount((x) => x + 1)}
        therapy_module_id={item.therapy_module_id}
        therapy_id={item.therapy_id}
        interventionList={interventionList}
        screenerList={screenerList}
        actionList={actionList}
      />
    </>
  );
}
