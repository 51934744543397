import React, { useEffect, useState } from "react";
import useQuestionaireList from "../../../utils/CustomHooks/useQuestionaireList";
import CreateQuestionaireModal from "../../Screener/modal/CreateQuestionaireModal";
import TopicQuestion from "./TopicQuestion";
import PaginationComponent from "../../../components/PaginationComponent";

export default function QuestionComponent({ screener_id, setReqCount }) {
  const [isOpen, setIsOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const { questionaireList, loading, getQuestionaireList, resetQuestions } =
    useQuestionaireList("");
  const [topicsArr, setTopicsArr] = useState([]);
  const pageCount = Math.ceil(questionaireList.length / pageSize);

  useEffect(() => {
    if (screener_id) getQuestionaireList({ screener_id });
  }, [screener_id]);

  const addTopics = (e) => {
    e.preventDefault();
    setIsOpen(true);
  };

  const handleClick = (event, index) => {
    event.preventDefault();
    setCurrentPage(index);
    // console.log(`Viewing Page ${index + 1} Data`);
  };

  return (
    <>
      <h5 className="mb-4">
        Questions{" "}
        <span className="cursor-pointer">
          <button
            type="button"
            className="btn btn-primary btn-outline-success btn-sm float-right btn-add-domain"
            onClick={addTopics}
          >
            Add
          </button>
        </span>
      </h5>

      {questionaireList && questionaireList.length > 0 ? (
        questionaireList
          .slice()
          .sort((a, b) => a.sort_order - b.sort_order)
          .slice(currentPage * pageSize, (currentPage + 1) * pageSize)
          .map((item, i) => (
            <TopicQuestion
              item={item}
              key={i}
              i={currentPage * pageSize + i}
              refresh={() => getQuestionaireList({ screener_id })}
            />
          ))
      ) : (
        <span className="font-weight-bold w-100 text-center">
          Oops! No Data Found
        </span>
      )}
      {questionaireList.length > 0 && pageCount > 1 && (
        <div className="mt-3">
          <PaginationComponent
            handleClick={handleClick}
            currentPage={currentPage}
            pageCount={pageCount}
          />
        </div>
      )}
      <CreateQuestionaireModal
        isOpen={isOpen}
        setOpen={() => setIsOpen(false)}
        refresh={() => {
          if (screener_id) getQuestionaireList({ screener_id });
        }}
        screener_id={screener_id}
      />
    </>
  );
}
