import { useEffect, useState } from "react";
import { Modal, ModalBody } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import graphHandler from "../../../Apollo/graphHandler";
// import { UPDATE_CITY_GQL } from "../../../Apollo/AdminServices/MutationRequest";
import { UPDATE_TIMEZONE_GQL } from "../../../Apollo/AdminServices/MutationRequest";
import CustomButton from "../../../components/Button/CustomButton";
import MultiSelect from "../../../components/MultiSelect/MultiSelect";

export default function UpdateTimeZone({
  isOpen,
  setOpen,
  refresh,
  initialValue = {},
  timeZoneList = [],
}) {

  // useEffect(() => {
  //   setCountry(
  //     timeZoneList.map((x) => ({
  //       label: x.country_name,
  //       value: x.country_id,
  //     }))
  //   );
  // }, [timeZoneList]);
  const formik = useFormik({
    initialValues: {},
    validationSchema: validation,
    onSubmit: (values) => {
      onSubmit(values);
    },
  });

  useEffect(() => {
    if (initialValue.time_zone_id) {
      const value = {
        // city_id: initialValue.city_id,
        // city_name: initialValue.city_name,
      };
      // if (initialValue.city_short_code) {
      //   value.city_short_code = initialValue.city_short_code;
      // }
      // if (initialValue.city_name) {
      //   value.city_name = initialValue.city_name;
      // }
      if (initialValue.time_zone_name) {
        value.time_zone_name = initialValue.time_zone_name;
      }
      if (initialValue.time_zone_utc_offset) {
        value.time_zone_utc_offset = initialValue.time_zone_utc_offset;
      }
      if (initialValue.time_zone_short_code) {
        value.time_zone_short_code = initialValue.time_zone_short_code;
      }
      // if (
      //   initialValue.country_id &&
      //   countries.find((x) => x.value === initialValue.country_id)
      // ) {
      //   value.country_id = countries.find(
      //     (x) => x.value === initialValue.country_id
      //   );
      // }

      formik.setValues(value);
    }
  }, [initialValue, isOpen]);

  const onClose = () => {
    formik.resetForm();
    setOpen();
  };

  const onSubmit = async (values) => {
    const {
      // city_name,
      // city_short_code,
      time_zone_name,
      time_zone_utc_offset,
      time_zone_short_code,
      // country_id,
      // city_id,
    } = values;
    const update_request = {
      // city_id,
      // city_name,
      // city_short_code,
      // time_zone_name:time_zone_name.trim(),
      time_zone_utc_offset:time_zone_utc_offset.trim(),
      // time_zone_short_code:time_zone_short_code.trim(),
      time_zone_id:initialValue.time_zone_id,
      // country_id: country_id.value,
    };
    if(initialValue.time_zone_short_code !== time_zone_short_code.trim()){
      update_request.time_zone_short_code=time_zone_short_code.trim()
    }
    if(initialValue.time_zone_name !== time_zone_name.trim()){
      update_request.time_zone_name=time_zone_name.trim()
    }

    try {
      await graphHandler().invoke(UPDATE_TIMEZONE_GQL, { update_request }, 1, true);
      refresh();
      onClose();
      formik.setSubmitting(false);
    } catch (error) {
      // console.log("UpdateCategory error", error);
      formik.setSubmitting(false);
    }
  };

  const handleError = () => {
    if (Object.keys(formik.errors).length > 0) {
      toast.error(formik.errors[Object.keys(formik.errors)[0]]);
    }
  };

  return (
    <Modal toggle={onClose} isOpen={isOpen} centered scrollable size="xl">
      <div className="modal-header">
        <button type="button" className="close" onClick={onClose}>
          <span aria-hidden="true">&times;</span>
        </button>
        <h5 className="modal-title">Update TimeZone</h5>
      </div>
      <ModalBody className="pt-0">
        <div className="container">
          <div className="row justify-content-center">
            {/* <div className="col-md-12 form-group  ">
              <label
                htmlFor="category_id"
                className="col-form-label w-100 mb-2"
              >
                Country
              </label>
              <MultiSelect
                option={countries}
                onChange={(e) => formik.setFieldValue("country_id", e)}
                placeholder="Select a category"
                isCreatable={false}
                isMulti={false}
                type="country_id"
                name="country_id"
                onBlur={formik.handleBlur}
                defaultValue={formik.values.country_id}
              />
              <small className="text-danger align-self-end">
                {formik.touched["react-select-3-input"] &&
                formik.errors &&
                formik.errors.country_id
                  ? formik.errors.country_id
                  : ""}
              </small>
              <small className="text-danger">
                {formik.touched.country_id &&
                formik.errors &&
                formik.errors.country_id
                  ? formik.errors.country_id
                  : ""}
              </small>
            </div>
            <div className="col-md-12 form-group  ">
              <label htmlFor="city_name" className="col-form-label w-100 mb-2">
                TimeZone
              </label>
              <input
                type="text"
                className="form-control"
                id="city_name"
                name="city_name"
                maxLength="30"
                value={
                  formik.values && formik.values.city_name
                    ? formik.values.city_name
                    : ""
                }
                onChange={(e) =>
                  formik.setFieldValue(
                    "city_name",
                    e.target.value.replace(/[^a-z ]/gi, "")
                  )
                }
                onBlur={formik.handleBlur}
              />
              <small className="text-danger">
                {formik.touched.city_name &&
                formik.errors &&
                formik.errors.city_name
                  ? formik.errors.city_name
                  : ""}
              </small>
            </div>
            <div className="col-md-12 form-group">
              <label
                htmlFor="city_short_code"
                className="col-form-label w-100 mb-2"
              >
                Short Code
              </label>
              <input
                type="text"
                className="form-control"
                id="city_short_code"
                name="city_short_code"
                maxLength="20"
                value={
                  formik.values && formik.values.city_short_code
                    ? formik.values.city_short_code
                    : ""
                }
                onChange={(e) =>
                  formik.setFieldValue(
                    "city_short_code",
                    e.target.value.replace(/[^a-z]/gi, "")
                  )
                }
                onBlur={formik.handleBlur}
              />
              <small className="text-danger">
                {formik.touched.city_short_code &&
                formik.errors &&
                formik.errors.city_short_code
                  ? formik.errors.city_short_code
                  : ""}
              </small>
            </div> */}
            <div className="col-md-12 form-group ">
              <label
                htmlFor="time_zone_name"
                className="col-form-label w-100 mb-2"
              >
                TimeZone
              </label>
              <input
                type="text"
                className="form-control"
                id="time_zone_name"
                name="time_zone_name"
                maxLength="40"
                value={
                  formik.values && formik.values.time_zone_name
                    ? formik.values.time_zone_name
                    : ""
                }
                onChange={(e) =>
                  formik.setFieldValue(
                    "time_zone_name",
                    e.target.value.trimStart()
                  )
                }
                onBlur={formik.handleBlur}
              />
              <small className="text-danger">
                {formik.touched.time_zone_name &&
                formik.errors &&
                formik.errors.time_zone_name
                  ? formik.errors.time_zone_name
                  : ""}
              </small>
            </div>
            <div className="col-md-12 form-group ">
              <label
                htmlFor="time_zone_utc_offset"
                className="col-form-label w-100 mb-2"
              >
                Offset
              </label>
              <input
                type="text"
                className="form-control"
                id="time_zone_utc_offset"
                name="time_zone_utc_offset"
                maxLength="20"
                value={
                  formik.values && formik.values.time_zone_utc_offset
                    ? formik.values.time_zone_utc_offset
                    : ""
                }
                onChange={(e) =>
                  formik.setFieldValue(
                    "time_zone_utc_offset",
                    e.target.value.trimStart()
                  )
                }
                onBlur={formik.handleBlur}
              />
              <small className="text-danger">
                {formik.touched.time_zone_utc_offset &&
                formik.errors &&
                formik.errors.time_zone_utc_offset
                  ? formik.errors.time_zone_utc_offset
                  : ""}
              </small>
            </div>
            <div className="col-md-12 form-group ">
              <label
                htmlFor="time_zone_short_code"
                className="col-form-label w-100 mb-2"
              >
                TimeZone Short Code
              </label>
              <input
                type="text"
                className="form-control"
                id="time_zone_short_code"
                name="time_zone_short_code"
                maxLength="10"
                value={
                  formik.values && formik.values.time_zone_short_code
                    ? formik.values.time_zone_short_code
                    : ""
                }
                onChange={(e) =>
                  formik.setFieldValue(
                    "time_zone_short_code",
                    e.target.value.trimStart()
                  )
                }
                onBlur={formik.handleBlur}
              />
              <small className="text-danger">
                {formik.touched.time_zone_short_code &&
                formik.errors &&
                formik.errors.time_zone_short_code
                  ? formik.errors.time_zone_short_code
                  : ""}
              </small>
            </div>

            <CustomButton
              type="button"
              className="btn btn-primary btn-outline-success float-right btn-add-domain"
              disabled={
                Object.keys(formik.errors).length !== 0 ||
                !formik.isValid ||
                formik.isSubmitting
              }
              onClick={
                formik.isValid && Object.keys(formik.values).length !== 0
                  ? formik.handleSubmit
                  : handleError
              }
            >
              Update
            </CustomButton>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
}

const validation = Yup.object().shape({
  // country_id: Yup.object().required("Select Country"),
  // city_name: Yup.string().required("Enter TimeZone Name"),
  // city_short_code: Yup.string().required("Enter TimeZone ShortCode"),
  time_zone_name: Yup.string().required("Enter TimeZone"),
  time_zone_utc_offset: Yup.string().required("Enter TimeZone Offset"),
  time_zone_short_code: Yup.string().required("Enter TimeZone ShortCode"),
});
