import { useState } from "react";
import { GET_PLAN_GQL } from "../../Apollo/AdminServices/NewQueryRequest";
import {
  GET_BLOG_GQL,
  GET_CATEGORY_GQL,
  GET_DOMAIN_GQL,
  GET_INTERVENTION_GQL,
  GET_SCREENER,
  GET_THERAPY_GQL,
} from "../../Apollo/AdminServices/QueryRequest";
import { GET_SUB_DOMAIN } from "../../Apollo/ApplicationService/applicationQueryRequest";
import graphHandler from "../../Apollo/graphHandler";

function useGetContent(type = "") {
  const [content, setContent] = useState({});
  const [loading, setLoading] = useState(true);

  const getCategory = async (category_id) => {
    setLoading(true);
    try {
      const { data } = await graphHandler().invoke(
        GET_CATEGORY_GQL,
        { category_id },
        1,
        false,
        true
      );
      if (data && data.getCategory && data.getCategory.category) {
        // console.log("getCategory Success", data.getCategory);
        setContent({});
        setContent(data.getCategory.category);
        setLoading(false);
      } else {
        setContent({});
        setLoading(false);
      }
    } catch (error) {
      console.log("getCategory Error", error);
      setContent({});
      setLoading(false);
    }
  };

  const getDomain = async (domain_id) => {
    setLoading(true);
    try {
      const { data } = await graphHandler().invoke(
        GET_DOMAIN_GQL,
        { domain_id },
        1,
        false,
        true
      );
      if (data && data.getDomain && data.getDomain.domain) {
        // console.log("getDomain Success", data.getDomain);
        setContent({});
        setContent(data.getDomain.domain);
        setLoading(false);
      } else {
        setContent({});
        setLoading(false);
      }
    } catch (error) {
      console.log("getDomain Error", error);
      setContent({});
      setLoading(false);
    }
  };

  const getSubDomain = async (sub_domain_id) => {
    if (!sub_domain_id) return;
    setLoading(true);
    try {
      const { data } = await graphHandler().invoke(
        GET_SUB_DOMAIN,
        { sub_domain_id },
        1
      );
      if (data && data.getSubDomain && data.getSubDomain.sub_domain) {
        // console.log("getSubDomain Success", data.getSubDomain.sub_domain);
        setContent(data.getSubDomain.sub_domain);
        setLoading(false);
      } else {
        setContent({});
        setLoading(false);
      }
    } catch (error) {
      console.log("getSubDomain Error", error);
      setContent({});
      setLoading(false);
    }
  };

  const getScreener = async (screener_id) => {
    setLoading(true);
    try {
      const { data } = await graphHandler().invoke(
        GET_SCREENER,
        { screener_id },
        1,
        false,
        true
      );
      if (data && data.getScreener && data.getScreener.screener) {
        // console.log("getScreener Success", data.getScreener);
        setContent({});
        setContent(data.getScreener.screener);
        setLoading(false);
      } else {
        setContent({});
        setLoading(false);
      }
    } catch (error) {
      console.log("getScreener Error", error);
      setContent({});
      setLoading(false);
    }
  };
  const getIntervention = async (intervention_id) => {
    setLoading(true);
    try {
      const { data } = await graphHandler().invoke(
        GET_INTERVENTION_GQL,
        { intervention_id },
        1,
        false,
        true
      );
      if (data && data.getIntervention && data.getIntervention.intervention) {
        // console.log("getIntervention Success", data.getIntervention);
        setContent({});
        setContent(data.getIntervention.intervention);
        setLoading(false);
      } else {
        setContent({});
        setLoading(false);
      }
    } catch (error) {
      console.log("getIntervention Error", error);
      setContent({});
      setLoading(false);
    }
  };

  const getTherapy = async (therapy_id) => {
    setLoading(true);
    try {
      const { data } = await graphHandler().invoke(
        GET_THERAPY_GQL,
        { therapy_id },
        1,
        false,
        true
      );
      if (data && data.getTherapy && data.getTherapy.therapy) {
        // console.log("getTherapy Success", data.getTherapy);
        setContent({});
        setContent(data.getTherapy.therapy);
        setLoading(false);
      } else {
        setContent({});
        setLoading(false);
      }
    } catch (error) {
      console.log("getTherapy Error", error);
      setContent({});
      setLoading(false);
    }
  };

  const getBlog = async (blog_id) => {
    setLoading(true);
    try {
      const { data } = await graphHandler().invoke(
        GET_BLOG_GQL,
        { blog_id },
        1,
        false,
        true
      );
      if (data && data.getBlog && data.getBlog.blog) {
        // console.log("getBlog Success", data.getBlog);
        setContent({});
        setContent(data.getBlog.blog);
        setLoading(false);
      } else {
        setContent({});
        setLoading(false);
      }
    } catch (error) {
      console.log("getBlog Error", error);
      setContent({});
      setLoading(false);
    }
  };

  const getPlan = async (plan_id) => {
    setLoading(true);
    try {
      const { data } = await graphHandler().invoke(
        GET_PLAN_GQL,
        { plan_id },
        1,
        false,
        true
      );
      if (data && data.getPlan && data.getPlan.plan) {
        // console.log("getPlan Success", data.getPlan);
        setContent({});
        setContent(data.getPlan.plan);
        setLoading(false);
      } else {
        setContent({});
        setLoading(false);
      }
    } catch (error) {
      console.log("getPlan Error", error);
      setContent({});
      setLoading(false);
    }
  };
  const getContent = (id = "") => {
    if (type === "SCREENER") getScreener(id);
    else if (type === "INTERVENTION") getIntervention(id);
    else if (type === "THERAPY") getTherapy(id);
    else if (type === "BLOG") getBlog(id);
    else if (type === "PLAN") getPlan(id);
    else if (type === "SUBDOMAIN") getSubDomain(id);
    else if (type === "DOMAIN") getDomain(id);
    else if (type === "CATEGORY") getCategory(id);
    //Need get therapy module api
    // else if (type === "THERAPY_MODULE") getModule(id);
    //Need get master class api
    // else if (type === "MASTERCLASS") getMasterClass(id);
  };
  return {
    content,
    loading,
    getContent,
  };
}

export default useGetContent;
