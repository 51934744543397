import { useEffect, useState } from "react";
import { GET_THRESHOLD_LIST_GQL } from "../../Apollo/AdminServices/QueryRequest";
import graphHandler from "../../Apollo/graphHandler";
import { sortArray } from "../Functions/filter";
function useThresholdList(id) {
  const [thresholdList, setThresholdList] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (id) {
      getThresholdList(id);
    } else {
      setThresholdList([]);
    }
  }, [id]);
  const getThresholdList = async (req = {}) => {
    setLoading(true);
    try {
      const { data } = await graphHandler().invoke(
        GET_THRESHOLD_LIST_GQL,
        req,
        1,
        false,
        true
      );
      if (
        data &&
        data.getScreenerThresholdList &&
        data.getScreenerThresholdList.screener_thresholds &&
        data.getScreenerThresholdList.screener_thresholds.length > 0
      ) {
        // console.log(
        //   "getThresholdList Success",
        //   data.getScreenerThresholdList.screener_thresholds
        // );
        const struct = data.getScreenerThresholdList.screener_thresholds
          .slice()
          .map((x) => ({
            ...x.screener_threshold,
            pending_history: x.pending_screener_threshold_history,
          }));
        setThresholdList([]);
        setThresholdList(sortArray(struct));
        setLoading(false);
      } else {
        setThresholdList([]);
        setLoading(false);
      }
    } catch (error) {
      console.log("getThresholdList Error", error);
      setThresholdList([]);
      setLoading(false);
    }
  };
  return {
    thresholdList,
    loading,
    getThresholdList,
    reset: () => setThresholdList([]),
  };
}
export default useThresholdList;
