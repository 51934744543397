import { toast } from "react-toastify";

export default function UpdateOptionCard({
  formik = {},
  titleKey = "data",
  valueKey = "value",
  disabled,
  setVisible = () => {},
  reset = () => {},
}) {
  const handleError = () => {
    if (Object.keys(formik.errors).length > 0) {
      toast.error(formik.errors[Object.keys(formik.errors)[0]]);
    }
  };

  const handleNumber = (e) => {
    const { name, value } = e.target;
    const re = /^[0-9\b]+$/;
    let temp = !value
      ? value
      : re.test(value)
      ? value
      : formik.values[name]
      ? formik.values[name]
      : "";
    return temp ? temp.substring(0, 10) : temp;
  };

  const resetIn = () => {
    setVisible(false);
    reset();
  };
  return (
    <div className="row px-3 pt-3 h-100  position-relative rounded-4 shadow-common">
      <div className="col-md-8 form-group mb-0">
        <label htmlFor={titleKey} className="col-form-label w-100 mb-2">
          Data
        </label>
        <input
          type="text"
          className="form-control"
          name={titleKey}
          id={titleKey}
          onChange={formik.handleChange}
          placeholder=""
          value={formik.values[titleKey] ? formik.values[titleKey] : ""}
          onBlur={formik.handleBlur}
          disabled={disabled}
        />
        <small className="text-danger col-md-12">
          {formik.touched[titleKey] && formik.errors[titleKey]
            ? formik.errors[titleKey]
            : ""}
        </small>
      </div>
      <div className="col-md-8 form-group mb-0">
        <label htmlFor={valueKey} className="col-form-label w-100 mb-2">
          Value
        </label>
        <input
          type="text"
          className="form-control"
          name={valueKey}
          id={valueKey}
          onChange={(e) => formik.setFieldValue(valueKey, handleNumber(e))}
          placeholder=""
          value={formik.values[valueKey] ? formik.values[valueKey] : ""}
          onBlur={formik.handleBlur}
          disabled={disabled}
        />
        <small className="text-danger col-md-12">
          {formik.touched[valueKey] && formik.errors[valueKey]
            ? formik.errors[valueKey]
            : ""}
        </small>
      </div>
      <div className="col-md-4 form-group">
        <label
          htmlFor={valueKey}
          className="col-form-label w-100 mb-2"
          style={{ opacity: 0 }}
        >
          buttons
        </label>
        <div className="d-flex align-items-start justify-content-end">
          {Number.isInteger(formik.values.index) ? (
            <button className="btn btn-light btn-sm mr-3" onClick={reset}>
              Reset
            </button>
          ) : null}
          <button
            className="btn btn-primary btn-outline-success btn-sm"
            onClick={
              formik.isValid && Object.keys(formik.values).length !== 0
                ? formik.handleSubmit
                : handleError
            }
            disabled={Object.keys(formik.errors).length !== 0}
          >
            Update
          </button>
        </div>
      </div>
      <button
        type="button"
        className="btn btn-link position-absolute"
        style={{ top: "0px", right: "0px" }}
        onClick={resetIn}
      >
        <i className="fas fa-times"></i>
      </button>
    </div>
  );
}
