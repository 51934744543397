import { useState } from "react";
import { REMOVE_COUNTRY_GQL, REMOVE_LANGUAGE_GQL, REMOVE_INDUSTRY_GQL, REMOVE_FUNCTION_GQL,REMOVE_EDUCATION_GQL, REMOVE_TIMEZONE_GQL, REMOVE_CITY_GQL, REMOVE_GAME_CONFIG_GQL } from "../../Apollo/ApplicationService/applicationMutationRequest";
import graphHandler from "../../Apollo/graphHandler";
function useRemoveAPI() {
    const [loading,setLoading] = useState(false);

    const removeCountry = async (
        req = {},
        callback = () => { },
        eCallBack = () => { }
    ) => {
        setLoading(true);
        try {
            const { data } = await graphHandler().invoke(
                REMOVE_COUNTRY_GQL,
                req,
                1,
                false,
                true
            );
            // console.log("removeCountry Success", data.removeScreenerTopic);
            callback();
            setLoading(false);
        } catch (error) {
            eCallBack(error);
            console.log("removeCountry Error", error);
            setLoading(false);
        }
    };

    const removeLanguage = async (
        req = {},
        callback = () => { },
        eCallBack = () => { }
    ) => {
        setLoading(true);
        try {
            const { data } = await graphHandler().invoke(
                REMOVE_LANGUAGE_GQL,
                req,
                1,
                false,
                true
            );
            // console.log("removeLanguage Success");
            callback();
            setLoading(false);
        } catch (error) {
            eCallBack(error);
            console.log("removeLanguage Error", error);
            setLoading(false);
        }
    };

    const removeIndustry = async (
        req = {},
        callback = () => { },
        eCallBack = () => { }
    ) => {
        setLoading(true);
        try {
            const { data } = await graphHandler().invoke(
                REMOVE_INDUSTRY_GQL,
                req,
                1,
                false,
                true
            );
            // console.log("removeIndustry Success");
            callback();
            setLoading(false);
        } catch (error) {
            eCallBack(error);
            console.log("removeIndustry Error", error);
            setLoading(false);
        }
    };
    const removeFunction = async (
        req = {},
        callback = () => { },
        eCallBack = () => { }
    ) => {
        setLoading(true);
        try {
            const { data } = await graphHandler().invoke(
                REMOVE_FUNCTION_GQL,
                req,
                1,
                false,
                true
            );
            // console.log("removeFunction Success");
            callback();
            setLoading(false);
        } catch (error) {
            eCallBack(error);
            console.log("removeFunction Error", error);
            setLoading(false);
        }
    };
    const removeEducation = async (
        req = {},
        callback = () => { },
        eCallBack = () => { }
    ) => {
        setLoading(true);
        try {
            const { data } = await graphHandler().invoke(
                REMOVE_EDUCATION_GQL,
                req,
                1,
                false,
                true
            );
            // console.log("removeEducation Success");
            callback();
            setLoading(false);
        } catch (error) {
            eCallBack(error);
            console.log("removeEducation Error", error);
            setLoading(false);
        }
    };
    const removeTimeZone = async (
        req = {},
        callback = () => {},
        eCallBack = () => {}
      ) => {
        setLoading(true);
        try {
          const { data } = await graphHandler().invoke(
            REMOVE_TIMEZONE_GQL,
            req,
            1,
            false,
            true
          );
        //   console.log("removeTimeZone Success", data.removeScreenerTopic);
          callback();
          setLoading(false);
        } catch (error) {
          eCallBack(error);
          console.log("removeTimeZone Error", error);
          setLoading(false);
        }
      };
      const removeCity = async (
        req = {},
        callback = () => { },
        eCallBack = () => { }
    ) => {
        setLoading(true);
        try {
            const { data } = await graphHandler().invoke(
                REMOVE_CITY_GQL,
                req,
                1,
                false,
                true
            );
            // console.log("removeCity Success");
            callback();
            setLoading(false);
        } catch (error) {
            eCallBack(error);
            console.log("removeCity Error", error);
            setLoading(false);
        }
    };
    const removeGameConfig = async (
        req = {},
        callback = () => { },
        eCallBack = () => { }
    ) => {
        setLoading(true);
        try {
            const { data } = await graphHandler().invoke(
                REMOVE_GAME_CONFIG_GQL,
                req,
                1,
                false,
                true
            );
            // console.log("removeGameConfig Success");
            callback();
            setLoading(false);
        } catch (error) {
            eCallBack(error);
            console.log("removeGameConfig Error", error);
            setLoading(false);
        }
    };

    return {
        loading,
        removeCountry,
        removeLanguage,
        removeIndustry,
        removeFunction,
        removeEducation,
        removeTimeZone,
        removeCity,
        removeGameConfig,
    };
}
export default useRemoveAPI;
