import { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { CREATE_SCREENER_QUESTIONAIRE_GQL } from "../../../Apollo/AdminServices/MutationRequest";
import graphHandler from "../../../Apollo/graphHandler";
import CustomButton from "../../../components/Button/CustomButton";
import UploadQuestion from "../modal/UploadQuestion";
import EditorModal from "../../../components/Modal/EditorModal";

export default function CreateQuestionSection({
  values = {},
  topicList = [],
  questionaireList = [],
  setLoading = () => {},
  refresh = () => {},
  question = {},
  subDomains = [],
}) {
  const { screener_id } = values;
  const [isOpen, setOpen] = useState(false);
  const [isOpenWeb, setOpenWeb] = useState(false);

  let allQuestions = questionaireList
    .slice()
    .sort((a, b) => b.sort_order - a.sort_order);
  const formik = useFormik({
    initialValues: { ...initialState, options: [{ ...initialValues }] },
    validationSchema: validation,
    onSubmit: (values) => {
      onSubmit(values);
    },
  });
  useEffect(() => {
    if (question.duplicate) onDuplicate(question);
  }, [question]);

  const canShowOptions =
    formik.values.screener_question_type &&
    formik.values.screener_question_type != "TextField" &&
    formik.values.screener_question_type != "DocumentUpload" &&
    formik.values.screener_question_type != "ImageCapture" &&
    formik.values.screener_question_type != "Date" &&
    formik.values.screener_question_type != "Rating" &&
    formik.values.screener_question_type != "TextArea";

  const handleError = () => {
    if (Object.keys(formik.errors).length > 0) {
      toast.error(formik.errors[Object.keys(formik.errors)[0]]);
    }
  };

  const onSubmit = async (value) => {
    const {
      sub_domain_id,
      screener_topic_id,
      screener_question_title,
      ignore_for_scoring,
      result_weightage,
      options,
    } = value;
    if (
      canShowOptions &&
      options.filter(
        (x) => x.data && x.value !== "" && Number.isInteger(Number(x.value))
      ).length <= 1
    )
      return toast.error("Please create multiple options");
    const request = {
      sub_domain_id,
      screener_id,
      screener_topic_id,
      screener_question_title,
      result_weightage: Number(result_weightage),
      screener_question_type: "RadioButton",
      mandatory: true,
      option_type: "Text",
      ignore_for_scoring:
        typeof ignore_for_scoring === "boolean"
          ? ignore_for_scoring
          : ignore_for_scoring === "true",
    };
    request.sort_order = Number(
      allQuestions.length === 0 ? 1 : allQuestions[0].sort_order + 1
    );
    if (options) {
      request.options = {
        option_type: "Text",
        options: options
          .filter(
            (x) => x.data && x.value !== "" && Number.isInteger(Number(x.value))
          )
          .map(({ data, value }) => ({ data, value: Number(value) })),
      };
    }
    create(request);
  };

  const create = async (create_request) => {
    setLoading(true);
    formik.setSubmitting(true);
    try {
      const { data } = await graphHandler().invoke(
        CREATE_SCREENER_QUESTIONAIRE_GQL,
        { create_request },
        1,
        false,
        true
      );
      if (
        data &&
        data.createScreenerQuestion &&
        data.createScreenerQuestion.screener_question
      ) {
        // console.log(
        //   "createScreenerQuestion Success",
        //   data.createScreenerQuestion.screener_question
        // );
        setLoading(false);
        refresh();
        resetModal();
      }
    } catch (error) {
      console.error("createScreenerQuestion Error", error);
      setLoading(false);
      formik.setSubmitting(false);
    }
  };

  const handleOptionChange = (value, i, name = "data") => {
    const options = [...formik.values.options.map((x) => x)];
    options[i][name] = value;
    formik.setFieldValue("options", options);
  };

  const addOption = (i) => {
    const options = [...formik.values.options.map((x) => x)];
    if (!options[i].data || !options[i].value) return;
    formik.setFieldValue("options", [...options, { ...initialValues }]);
  };

  const removeOption = (i, image = false) => {
    if (!image && formik.values.options.length == 1) return;
    const options = [...formik.values.options.map((x) => x)];
    options.splice(i, 1);
    formik.setFieldValue("options", options);
  };

  const handleNumber = (e) => {
    const { name, value } = e.target;
    const re = /^[0-9\b]+$/;
    let temp = !value
      ? value
      : re.test(value)
      ? value
      : formik.values[name]
      ? formik.values[name]
      : "";
    formik.setFieldValue(name, temp ? temp.substring(0, 10) : temp);
  };
  const onDuplicate = (data) => {
    const {
      mandatory,
      screener_question_type,
      option_type,
      options,
      ignore_for_scoring,
      result_weightage,
      screener_topic_id,
      sub_domain_id,
    } = data;
    const model = {};
    if (mandatory) {
      model.mandatory = mandatory.toString();
    }
    if (screener_question_type) {
      model.screener_question_type = screener_question_type;
    }
    if (option_type) {
      model.option_type = option_type;
    }
    if (ignore_for_scoring) {
      model.ignore_for_scoring = ignore_for_scoring;
    }
    if (result_weightage) {
      model.result_weightage = result_weightage;
    }
    if (screener_topic_id) {
      model.screener_topic_id = screener_topic_id;
    }
    if (sub_domain_id) {
      model.sub_domain_id = sub_domain_id;
    }
    let choose =
      options.options && options.options.length > 0
        ? options.options
        : [{ ...initialValues }];
    if (choose) {
      model.options = [
        ...choose.map(({ data, value }) => ({
          data,
          value,
        })),
      ];
    }
    formik.setValues(model);
    setLoading(false);
  };
  const resetModal = () => {
    formik.resetForm();
    formik.setValues({ ...initialState });
    formik.setFieldValue("options", [{ ...initialValues }]);
  };
  return (
    <>
      <div
        className="col-md-12 modal-header justify-content-between align-items-center"
        id="updateQuestion"
      >
        <h5 className="modal-title text-center">Create Question</h5>
        <button
          type="button"
          className="btn btn-success"
          onClick={() => setOpen(true)}
        >
          Upload Questions <i className="fas fa-file-excel" />
        </button>
        <UploadQuestion
          {...{
            isOpen,
            setOpen,
            values,
            refresh,
            subDomains,
            topicList,
            questionaireList,
          }}
        />
      </div>
      <div className="col-md-12">
        <div className="  border">
          <div className="questionnaire-form row p-3">
            <div className="col-md-6 form-group">
            <EditorModal
            isOpen={isOpenWeb}
            defaultValue={formik.values.screener_question_title ? formik.values.screener_question_title : ""}
            close={() => setOpenWeb(false)}
            save={(e) => {
              formik.setFieldValue("screener_question_title", e);
            }}
          />
              <label
                htmlFor="screener_question_title"
                className="col-form-label w-100 mb-2"
              >
                Question
                <button
              className="btn-link-common float-right"
              onClick={() => setOpenWeb(true)}
            >
              Use Editor
            </button>
              </label>
              <textarea
                className="form-control"
                id="screener_question_title"
                name="screener_question_title"
                // maxLength="150"
                value={
                  formik.values && formik.values.screener_question_title
                    ? formik.values.screener_question_title
                    : ""
                }
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <small className="text-danger align-self-end">
                {formik.touched.screener_question_title &&
                formik.errors &&
                formik.errors.screener_question_title
                  ? formik.errors.screener_question_title
                  : ""}
              </small>
            </div>
            <div className="col-md-6">
              <div className="row">
                <div className="col-md-12 form-group ">
                  <label
                    htmlFor="screener_topic_id"
                    className="col-form-label w-100 mb-2"
                  >
                    Topic
                  </label>
                  <select
                    id="screener_topic_id"
                    name="screener_topic_id"
                    className="custom-select"
                    onChange={formik.handleChange}
                    value={
                      formik.values.screener_topic_id
                        ? formik.values.screener_topic_id
                        : ""
                    }
                    onBlur={formik.handleBlur}
                    // disabled={formik.values.screener_question_id}
                  >
                    <option key="Select" hidden value="">
                      Select
                    </option>
                    {topicList.map((x, i) => (
                      <option key={i} value={x.screener_topic_id}>
                        {x.screener_topic_title}
                      </option>
                    ))}
                  </select>
                  <small className="text-danger align-self-end">
                    {formik.touched.screener_topic_id &&
                    formik.errors &&
                    formik.errors.screener_topic_id
                      ? formik.errors.screener_topic_id
                      : ""}
                  </small>
                </div>
                <div className="col-md-12 form-group ">
                  <label
                    htmlFor="sub_domain_id"
                    className="col-form-label w-100 mb-2"
                  >
                    Sub Domain
                  </label>
                  <select
                    id="sub_domain_id"
                    name="sub_domain_id"
                    className="custom-select"
                    onChange={formik.handleChange}
                    value={
                      formik.values.sub_domain_id
                        ? formik.values.sub_domain_id
                        : ""
                    }
                    onBlur={formik.handleBlur}
                    // disabled={formik.values.screener_question_id}
                  >
                    <option key="Select" hidden>
                      Select
                    </option>
                    {subDomains.map((x, i) => (
                      <option key={i} value={x.sub_domain_id}>
                        {x.sub_domain_name}
                      </option>
                    ))}
                  </select>
                  <small className="text-danger align-self-end">
                    {formik.touched.sub_domain_id &&
                    formik.errors &&
                    formik.errors.sub_domain_id
                      ? formik.errors.sub_domain_id
                      : ""}
                  </small>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="row">
                <div className="col-md-6 form-group">
                  <label
                    htmlFor="result_weightage"
                    className="col-form-label w-100 mb-2"
                  >
                    Result Weightage
                  </label>
                  <div className="d-flex flex-column p-0">
                    <input
                      type="text"
                      className="form-control h-100 w-100"
                      //   placeholder={questionaireList.length + 1}
                      placeholder=""
                      id="result_weightage"
                      name="result_weightage"
                      max={100}
                      value={
                        formik.values && formik.values.result_weightage
                          ? formik.values.result_weightage
                          : ""
                      }
                      onBlur={formik.handleBlur}
                      onChange={handleNumber}
                    />
                    <small className="text-danger ">
                      {formik.touched.result_weightage &&
                      formik.errors &&
                      formik.errors.result_weightage
                        ? formik.errors.result_weightage
                        : ""}
                    </small>
                  </div>
                </div>
                <div className="col-md-6 form-group">
                  <div className="d-flex flex-row">
                    <input
                      className="mr-2"
                      type="checkbox"
                      id="ignore_for_scoring"
                      name="ignore_for_scoring"
                      onChange={(e) =>
                        formik.setFieldValue(
                          "ignore_for_scoring",
                          e.target.checked
                        )
                      }
                      checked={formik.values.ignore_for_scoring}
                      onBlur={formik.handleBlur}
                      //   disabled={formik.values.screener_question_id}
                    />
                    <label
                      htmlFor="ignore_for_scoring"
                      className="col-form-label w-100 mb-2"
                    >
                      Ignore Scoring
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 form-group">
              <div className="col-md-12 pl-0 pr-md-2 pr-sm-0 pr-xl-0 d-flex justify-content-start flex-row mb-2">
                <label className="col-form-label w-100">Options</label>
                {(!formik.values.options ||
                  formik.values.options.length === 0) && (
                  <button
                    className="btn btn-primary btn-outline-success btn-sm"
                    onClick={() =>
                      formik.setFieldValue("options", [{ ...initialValues }])
                    }
                  >
                    Add
                  </button>
                )}
              </div>
              {/* {formik.values.option_type == "Image" ? ( */}
              {formik.values.options &&
                formik.values.options.map((x, i) => (
                  <div key={i} className="col-md-12 px-0 mb-3">
                    <div className="d-flex align-items-center row">
                      <div className="col-md-6">
                        <input
                          key={i}
                          type="text"
                          className="form-control"
                          placeholder="Option"
                          onChange={(e) =>
                            handleOptionChange(e.target.value, i, "data")
                          }
                          onBlur={formik.handleBlur}
                          value={x.data}
                        />
                      </div>
                      <div className="col-md-4">
                        <input
                          key={i}
                          type="text"
                          className="form-control"
                          placeholder="Value"
                          onChange={(e) =>
                            handleOptionChange(
                              e.target.value.replace(/[^0-9\b]/g, ""),
                              i,
                              "value"
                            )
                          }
                          onBlur={formik.handleBlur}
                          value={x.value}
                        />
                      </div>
                      <>
                        {i === formik.values.options.length - 1 && (
                          <i
                            className="fas fa-plus ml-2"
                            onClick={() => addOption(i)}
                            style={{ color: "#5e6c84" }}
                          />
                        )}
                        {formik.values.options.length != 1 && (
                          <i
                            className="fa fa-minus text-danger ml-2"
                            onClick={() => removeOption(i)}
                            style={{ color: "#5e6c84" }}
                          />
                        )}
                      </>
                    </div>
                  </div>
                ))}
            </div>
            <div
              className={`d-flex align-items-end justify-content-end col-md-12`}
            >
              <CustomButton
                type="button"
                className="btn btn-primary btn-outline-success btn-sm float-right btn-add-domain"
                disabled={
                  Object.keys(formik.errors).length !== 0 ||
                  !formik.isValid ||
                  formik.isSubmitting
                }
                onClick={
                  formik.isValid && Object.keys(formik.values).length !== 0
                    ? formik.handleSubmit
                    : handleError
                }
              >
                Create
              </CustomButton>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
const validation = Yup.object().shape({
  sub_domain_id: Yup.string().required("Select a sub domain"),
  screener_topic_id: Yup.string().required("Select a topic"),
  screener_question_title: Yup.string().trim().required("Enter a question"),
  // .min(4, "Question should be min of 4 characters")
  // .max(150, "Question should be maximum of 150 characters")
  ignore_for_scoring: Yup.string(),
  result_weightage: Yup.string().trim().required("Enter result weightage"),
  options: Yup.array()
    .of(
      Yup.object().shape({
        data: Yup.string(),
        value: Yup.string(),
        flag: Yup.string(),
      })
    )
    .test({
      message: "Add at least a multiple option",
      test: (arr) => {
        return arr && arr.length > 0;
      },
    }),
});

var initialValues = {
  data: "",
  value: "",
};
var initialState = {
  option_type: "Text",
  ignore_for_scoring: false,
  // screener_question_type:"RadioButton"
};
