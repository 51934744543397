import defaultImg from "../../../assets/Images/Blog/quotes-img.png";
import { getImage } from "../../../utils/Functions/filter";

export default function Quote({
  value = {},
  isPreview = false,
  onDelete = () => {},
  onEdit = () => {},
}) {
  const canShowImage = isPreview
    ? value.image_urls && value.image_urls[0].url
    : value.image_urls;
  return (
    <section className="wit-section-top">
      <div className="row">
        <div className="col-12">
          <div className="quotes-card col-12">
            <div
              className={`quotes-content text-center ${
                canShowImage ? "" : "w-100"
              }`}
            >
              <blockquote>
                <p>{value.content.map((x) => x).join(", ")}</p>
              </blockquote>
            </div>
            {canShowImage ? (
              <div className="quotes-img">
                <img
                  src={
                    value.image_urls &&
                    getImage(value.image_urls) &&
                    getImage(value.image_urls).url
                      ? getImage(value.image_urls).url
                      : defaultImg
                  }
                  alt="quotes"
                />
              </div>
            ) : null}
          </div>
        </div>
      </div>
      <div className="tool-tip">
        <button onClick={() => onEdit(value)}>
          <i className="fas fa-edit" />
        </button>
        <button onClick={() => onDelete(value)}>
          <i className="fas fa-trash" />
        </button>
      </div>
    </section>
  );
}
