import { useEffect, useState } from "react";
import { GET_BANNER_LIST_GQL } from "../../Apollo/AdminServices/ObjectiveRequest";
import { GET_LOCALIZE_LIST_GQL } from "../../Apollo/AdminServices/QueryRequest";
import graphHandler from "../../Apollo/graphHandler";
function useBannerList(preLoad = false) {
  const [bannerList, setBanner] = useState([]);
  const [loading, setLoading] = useState(preLoad);

  useEffect(() => {
    if (preLoad) {
      getBannerList({});
    }
  }, []);

  const getBannerList = async (req = {}) => {
    setLoading(true);
    try {
      const { data } = await graphHandler().invoke(
        GET_BANNER_LIST_GQL,
        req,
        1,
        false,
        true
      );
      if (
        data &&
        data.getBannerList &&
        data.getBannerList.banners &&
        data.getBannerList.banners.length > 0
      ) {
        // console.log(
        //   "getLocalizeList Success",
        //   data.getBannerList.banners
        // );
        setBanner([]);
        setBanner(data.getBannerList.banners);
        setLoading(false);
      } else {
        setBanner([]);
        setLoading(false);
      }
    } catch (error) {
      console.log("getBannerList Error", error);
      setBanner([]);
      setLoading(false);
    }
  };
  return {
    bannerList,
    loading,
    getBannerList,
  };
}
export default useBannerList;
