import { Modal, ModalBody } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import graphHandler from "../../../Apollo/graphHandler";
import { ADD_INDUSTRY_GQL } from "../../../Apollo/AdminServices/MutationRequest";
import CustomButton from "../../../components/Button/CustomButton";

export default function CreateIndustry({ isOpen, setOpen, refresh }) {
  const formik = useFormik({
    initialValues: {},
    validationSchema: validation,
    onSubmit: (values) => {
      onSubmit(values);
    },
  });
  const onClose = () => {
    formik.resetForm();
    setOpen();
  };
  const onSubmit = async (values) => {
    const { industry_name } = values;
    const addReq = {
      industry_name:industry_name.trim(),
    };

    try {
      await graphHandler().invoke(ADD_INDUSTRY_GQL, { addReq }, 1, true);
      refresh();
      onClose();
      formik.setSubmitting(false);
    } catch (error) {
      console.log("CreateIndustry error", error);
      formik.setSubmitting(false);
    }
  };

  const handleError = () => {
    if (Object.keys(formik.errors).length > 0) {
      toast.error(formik.errors[Object.keys(formik.errors)[0]]);
    }
  };

  return (
    <Modal toggle={onClose} isOpen={isOpen} centered scrollable size="xl">
      <div className="modal-header">
        <button type="button" className="close" onClick={onClose}>
          <span aria-hidden="true">&times;</span>
        </button>
        <h5 className="modal-title">Create Industry</h5>
      </div>
      <ModalBody className="pt-0">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12 form-group  ">
              <label
                htmlFor="industry_name"
                className="col-form-label w-100 mb-2"
              >
                Industry
              </label>
              <input
                type="text"
                className="form-control"
                id="industry_name"
                name="industry_name"
                maxLength="40"
                value={
                  formik.values && formik.values.industry_name
                    ? formik.values.industry_name
                    : ""
                }
                onChange={(e) =>
                  formik.setFieldValue(
                    "industry_name",
                    e.target.value.trimStart()
                  )
                }
                onBlur={formik.handleBlur}
              />
              <small className="text-danger">
                {formik.touched.industry_name &&
                formik.errors &&
                formik.errors.industry_name
                  ? formik.errors.industry_name
                  : ""}
              </small>
            </div>

            <CustomButton
              type="button"
              className="btn btn-primary btn-outline-success float-right btn-add-domain"
              disabled={
                Object.keys(formik.errors).length !== 0 ||
                !formik.isValid ||
                formik.isSubmitting
              }
              onClick={
                formik.isValid && Object.keys(formik.values).length !== 0
                  ? formik.handleSubmit
                  : handleError
              }
            >
              Create
            </CustomButton>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
}

const validation = Yup.object().shape({
  industry_name: Yup.string().required("Enter industry"),
});
