import { useFormik } from "formik";
import { useMemo } from "react";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { CREATE_PLAN_ACTION_GQL } from "../../../Apollo/AdminServices/NewMutationRequest";
import graphHandler from "../../../Apollo/graphHandler";
import CustomButton from "../../../components/Button/CustomButton";
import Description from "../../../components/Input/Description";
import MultiSelect from "../../../components/MultiSelect/MultiSelect";
import { PlanActionType } from "../../../constants";
import moment from "moment";

export default function CreatePlanAction({
  refresh = () => {},
  subDomainList = [],
  setLoading = () => {},
  groupList = {},
  plan = {},
  interventions = [],
  reportOption = [],
  mailOption = [],
  screeners = [],
  therapyOption = [],
  planOption = [],
  setAction,
}) {
  const { plan_id } = plan;
  const formik = useFormik({
    initialValues: {
      mandatory: false,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      onSubmit(values);
    },
  });
  //Clear options by type
  useMemo(() => {
    let values = { ...formik.values };
    delete values.intervention_id;
    delete values.screener_id;
    delete values.template_id;
    delete values.plan_action_image_urls;
    delete values.plan_action_document_urls;
    delete values.plan_action_video_urls;
    formik.setValues({ ...values });
  }, [formik.values.plan_action_type]);

  const onSubmit = async (value) => {
    formik.setSubmitting(false);
    const {
      sub_domain_ids,
      plan_group_id,
      plan_action_type,
      plan_action_name,
      description,
      mobile_description,
      plan_action_code,
      sort_order,
      min_completion_days,
      max_completion_days,
      start_interval_seconds,
      mandatory,
      action_time_seconds,
      intervention_id,
      screener_id,
      template_id,
      therapy_id,
      action_plan_id,
    } = value;

    if (
      min_completion_days &&
      max_completion_days &&(
      Number(max_completion_days) <= Number(min_completion_days))
    ) {
      formik.setSubmitting(false);
      return toast.warn("Max days should be > min days");
    }
    if (
      plan_action_type.value === "Intervention" &&
      (!intervention_id || !intervention_id.value)
    )
      return toast.warn("Intervention is required field");
    if (
      plan_action_type.value === "Screener" &&
      (!screener_id || !screener_id.value)
    )
      return toast.warn("Screener is required field");
    if (plan_action_type === "Therapy" && (!therapy_id || !therapy_id.value))
      return toast.warn("Therapy is required field");
    if (
      plan_action_type === "Plan" &&
      (!action_plan_id || !action_plan_id.value)
    )
      return toast.warn("Plan is required field");
    if (
      (plan_action_type.value === "MailTemplate" ||
        plan_action_type.value === "Report") &&
      (!template_id || !template_id.value)
    )
      return toast.warn("Template is required field");
    let create_request = {
      plan_id,
      plan_group_id: plan_group_id.value,
      plan_action_type: plan_action_type.value,
      // sub_domain_ids: [...sub_domain_ids.map((x) => x.value)],
      plan_action_name,
      plan_action_code,
      sort_order: Number(sort_order),
      // min_completion_days: Number(min_completion_days),
      // max_completion_days: Number(max_completion_days),
      // start_interval_seconds: Number(start_interval_seconds) * 86400,
      mandatory: Boolean(mandatory),
    };
    if (sub_domain_ids && sub_domain_ids.length > 0) {
      create_request.sub_domain_ids = [...sub_domain_ids.map((x) => x.value)];
    }
    // if (sort_order) {
    //   create_request.sort_order = Number(sort_order);
    // }
    if(action_time_seconds){
     create_request.action_time_seconds = moment(action_time_seconds, 'HH:mm').diff(moment().startOf('day'), 'seconds')
    }
    if (start_interval_seconds) {
      create_request.start_interval_seconds =
        Number(start_interval_seconds) * 86400;
    }
    if (max_completion_days) {
      create_request.max_completion_days = Number(max_completion_days);
    }
    if (min_completion_days) {
      create_request.min_completion_days = Number(min_completion_days);
    }
    let descriptions = [];
    if (description) {
      descriptions.push({
        client_type: "Web",
        description: description,
      });
    }
    if (mobile_description) {
      descriptions.push({
        client_type: "Mobile",
        description: mobile_description,
      });
    }
    if (descriptions.length > 0)
      create_request.plan_action_descriptions = descriptions;

    if (plan_action_type.value === "Intervention" && intervention_id.value) {
      create_request.intervention_id = intervention_id.value;
    } else if (plan_action_type.value === "Screener" && screener_id.value) {
      create_request.screener_id = screener_id.value;
    } else if (plan_action_type.value === "Therapy" && therapy_id.value) {
      create_request.therapy_id = therapy_id.value;
    } else if (plan_action_type.value === "Plan" && action_plan_id.value) {
      create_request.action_plan_id = action_plan_id.value;
    } else if (
      (plan_action_type.value === "Report" ||
        plan_action_type.value === "MailTemplate") &&
      template_id.value
    ) {
      create_request.template_id = template_id.value;
    }

    // formik.setSubmitting(true);
    // setLoading(true);
    try {
      const { data } = await graphHandler().invoke(
        CREATE_PLAN_ACTION_GQL,
        { create_request },
        1,
        false
      );
      if (data && data.createPlanAction && data.createPlanAction.plan_action) {
        setAction({
          ...data.createPlanAction.plan_action,
          pending_history: data.createPlanAction.plan_action_history,
        });
      }
      refresh();
      setLoading(false);
      toast.success("Created Successfully");
      formik.setSubmitting(false);
      resetModal();
    } catch (error) {
      console.log("createPlanAction error", error);
      setLoading(false);
      formik.setSubmitting(false);
    }
  };
  const handleError = () => {
    if (Object.keys(formik.errors).length > 0) {
      toast.error(formik.errors[Object.keys(formik.errors)[0]]);
    }
  };

  const handleNumber = (e) => {
    const { name, value } = e.target;
    const re = /^[0-9\b]+$/;
    let temp = !value ? value : re.test(value) ? value : formik.values[name];
    formik.setFieldValue(name, temp ? temp.substring(0, 10) : temp);
  };

  const resetModal = () => {
    formik.resetForm();
    formik.setValues({
      mandatory: false,
    });
    formik.setSubmitting(false);
  };
  const renderActionInput = (type) => {
    switch (type) {
      case "Intervention":
        return (
          <div className="col-md-6 form-group">
            <label
              htmlFor="intervention_id"
              className="col-form-label w-100 mb-2"
            >
              Intervention
            </label>
            <MultiSelect
              option={interventions}
              onChange={(e) => formik.setFieldValue("intervention_id", e)}
              placeholder="Select intervention"
              isCreatable={false}
              isMulti={false}
              type="Sub Domain"
              name="intervention_id"
              id="intervention_id"
              onBlur={formik.handleBlur}
              defaultValue={formik.values.intervention_id || "Reset"}
            />
            <small className="text-danger align-self-end">
              {formik.touched.intervention_id &&
              formik.errors &&
              formik.errors.intervention_id
                ? formik.errors.intervention_id
                : ""}
            </small>
          </div>
        );
      case "Plan":
        return (
          <div className="col-md-6 form-group">
            <label
              htmlFor="action_plan_id"
              className="col-form-label w-100 mb-2"
            >
              Plan
            </label>
            <MultiSelect
              option={planOption}
              onChange={(e) => formik.setFieldValue("action_plan_id", e)}
              placeholder="Select Plan"
              isCreatable={false}
              isMulti={false}
              type="Sub Domain"
              name="action_plan_id"
              id="action_plan_id"
              onBlur={formik.handleBlur}
              defaultValue={formik.values.action_plan_id || "Reset"}
            />
            <small className="text-danger align-self-end">
              {formik.touched.action_plan_id &&
              formik.errors &&
              formik.errors.action_plan_id
                ? formik.errors.action_plan_id
                : ""}
            </small>
          </div>
        );
      case "Therapy":
        return (
          <div className="col-md-6 form-group">
            <label htmlFor="therapy_id" className="col-form-label w-100 mb-2">
              Therapy
            </label>
            <MultiSelect
              option={therapyOption}
              onChange={(e) => formik.setFieldValue("therapy_id", e)}
              placeholder="Select Therapy"
              isCreatable={false}
              isMulti={false}
              type="Sub Domain"
              name="therapy_id"
              id="therapy_id"
              onBlur={formik.handleBlur}
              defaultValue={formik.values.therapy_id || "Reset"}
            />
            <small className="text-danger align-self-end">
              {formik.touched.therapy_id &&
              formik.errors &&
              formik.errors.therapy_id
                ? formik.errors.therapy_id
                : ""}
            </small>
          </div>
        );
      case "Screener":
        return (
          <div className="col-md-6 form-group ">
            <label htmlFor="screener_id" className="col-form-label w-100 mb-2">
              Screener
            </label>
            <MultiSelect
              option={screeners}
              onChange={(e) => formik.setFieldValue("screener_id", e)}
              placeholder="Select screener"
              isCreatable={false}
              isMulti={false}
              type="Sub Domain"
              name="screener_id"
              id="screener_id"
              onBlur={formik.handleBlur}
              defaultValue={formik.values.screener_id || "Reset"}
            />
            <small className="text-danger align-self-end">
              {formik.touched.screener_id &&
              formik.errors &&
              formik.errors.screener_id
                ? formik.errors.screener_id
                : ""}
            </small>
          </div>
        );
      case "Report":
        return (
          <div className="col-md-6 form-group ">
            <label htmlFor="template_id" className="col-form-label w-100 mb-2">
              Report
            </label>
            <MultiSelect
              option={reportOption}
              onChange={(e) => formik.setFieldValue("template_id", e)}
              placeholder="Select Report"
              isCreatable={false}
              isMulti={false}
              type="Report"
              name="template_id"
              id="template_id"
              onBlur={formik.handleBlur}
              defaultValue={formik.values.template_id || "Reset"}
            />
            <small className="text-danger align-self-end">
              {formik.touched.template_id &&
              formik.errors &&
              formik.errors.template_id
                ? formik.errors.template_id
                : ""}
            </small>
          </div>
        );
      case "MailTemplate":
        return (
          <div className="col-md-6 form-group ">
            <label htmlFor="template_id" className="col-form-label w-100 mb-2">
              Mail Template
            </label>
            <MultiSelect
              option={mailOption}
              onChange={(e) => formik.setFieldValue("template_id", e)}
              placeholder="Select Mail Template"
              isCreatable={false}
              isMulti={false}
              type=" Mail Template"
              name="template_id"
              id="template_id"
              onBlur={formik.handleBlur}
              defaultValue={formik.values.template_id || "Reset"}
            />
            <small className="text-danger align-self-end">
              {formik.touched.template_id &&
              formik.errors &&
              formik.errors.template_id
                ? formik.errors.template_id
                : ""}
            </small>
          </div>
        );

      default:
        return !type ? null : (
          <div className="col-md-6 form-group ">
            <label className="col-form-label w-100 mb-2">{type}</label>
            <input
              type="text"
              className="form-control"
              value="You can upload after create"
              disabled
            />
          </div>
        );
    }
  };
  // console.log("hgu",moment(formik.values.action_time_seconds, 'HH:mm').diff(moment().startOf('day'), 'seconds'))
  // console.log("hguss",formik.values.action_time_seconds)
  return (
    <>
      <div className="col-md-12 modal-header" id="CreateTopic">
        <h5 className="modal-title text-center mb-3">Create Action</h5>
      </div>
      <div className="col-md-12">
        <div className="  border">
          <div className="row p-3">
            <div className="col-md-6">
              <div className="row">
                <div className="col-md-12 form-group">
                  <label
                    htmlFor="sub_domain_ids"
                    className="col-form-label w-100 mb-2"
                  >
                    Sub Domains
                  </label>
                  <MultiSelect
                    option={subDomainList}
                    onChange={(e) => formik.setFieldValue("sub_domain_ids", e)}
                    placeholder="Select Sub Domain"
                    isCreatable={false}
                    type="Sub Domain"
                    name="sub_domain_ids"
                    id="sub_domain_ids"
                    onBlur={formik.handleBlur}
                    defaultValue={formik.values.sub_domain_ids || "Reset"}
                  />
                  <small className="text-danger">
                    {formik.touched.sub_domain_ids &&
                    formik.errors &&
                    formik.errors.sub_domain_ids
                      ? formik.errors.sub_domain_ids
                      : ""}
                  </small>
                </div>
                <div className="col-md-12 form-group ">
                  <label
                    htmlFor="plan_group_id"
                    className="col-form-label w-100 mb-2"
                  >
                    Plan Group
                  </label>
                  <MultiSelect
                    option={groupList}
                    onChange={(e) => formik.setFieldValue("plan_group_id", e)}
                    placeholder="Select group"
                    isCreatable={false}
                    isMulti={false}
                    type="Sub Domain"
                    name="plan_group_id"
                    id="plan_group_id"
                    onBlur={formik.handleBlur}
                    defaultValue={formik.values.plan_group_id || "Reset"}
                  />
                  <small className="text-danger align-self-end">
                    {formik.touched.plan_group_id &&
                    formik.errors &&
                    formik.errors.plan_group_id
                      ? formik.errors.plan_group_id
                      : ""}
                  </small>
                </div>
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-md-6 form-group">
                      <label
                        htmlFor="plan_action_code"
                        className="col-form-label w-100 mb-2"
                      >
                        Code
                      </label>
                      <input
                        {...formik.getFieldProps("plan_action_code")}
                        type="text"
                        className="form-control "
                        id="plan_action_code"
                        value={
                          formik.values.plan_action_code
                            ? formik.values.plan_action_code
                            : ""
                        }
                      />
                      <small className="text-danger align-self-end">
                        {formik.touched.plan_action_code &&
                        formik.errors &&
                        formik.errors.plan_action_code
                          ? formik.errors.plan_action_code
                          : ""}
                      </small>
                    </div>
                    <div className="col-md-6 form-group">
                      <label
                        htmlFor="sort_order"
                        className="col-form-label w-100 mb-2"
                      >
                        Sort Order
                      </label>
                      <input
                        {...formik.getFieldProps("sort_order")}
                        type="text"
                        className="form-control "
                        id="sort_order"
                        onChange={handleNumber}
                        maxLength={10}
                        value={
                          formik.values.sort_order
                            ? formik.values.sort_order
                            : ""
                        }
                      />
                      <small className="text-danger align-self-end">
                        {formik.touched.sort_order &&
                        formik.errors &&
                        formik.errors.sort_order
                          ? formik.errors.sort_order
                          : ""}
                      </small>
                    </div>
                    <div className="col-md-6 form-group">
                      <label
                        htmlFor="start_interval_seconds"
                        className="col-form-label w-100 mb-2"
                      >
                        Interval (in days)
                      </label>
                      <input
                        {...formik.getFieldProps("start_interval_seconds")}
                        type="text"
                        className="form-control "
                        id="start_interval_seconds"
                        onChange={handleNumber}
                        maxLength={10}
                        value={
                          formik.values.start_interval_seconds
                            ? formik.values.start_interval_seconds
                            : ""
                        }
                      />
                      <small className="text-danger align-self-end">
                        {formik.touched.start_interval_seconds &&
                        formik.errors &&
                        formik.errors.start_interval_seconds
                          ? formik.errors.start_interval_seconds
                          : ""}
                      </small>
                    </div>
                    <div className="col-md-4 form-group">
                      <label
                        htmlFor="action_time_seconds"
                        className="col-form-label w-100 mb-2"
                      >
                        Action Time
                      </label>
                      <input
                        {...formik.getFieldProps("action_time_seconds")}
                        type="time"
                        className="form-control "
                        id="action_time_seconds"
                        min="12:00"
                        value={
                          formik.values.action_time_seconds
                            ? formik.values.action_time_seconds
                            : ""
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-12 form-group mb-0">
                  <label
                    htmlFor="min_completion_days"
                    className="col-form-label w-100 mb-2"
                  >
                    <b>Completion Days</b>
                  </label>
                  <div className="row">
                    <div className="col-md-6 form-group">
                      <label
                        htmlFor="min_completion_days"
                        className="col-form-label w-100 mb-2"
                      >
                        Minimum
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="min_completion_days"
                        name="min_completion_days"
                        onChange={handleNumber}
                        maxLength={10}
                        value={
                          formik.values.min_completion_days
                            ? formik.values.min_completion_days
                            : ""
                        }
                        onBlur={formik.handleBlur}
                      />
                      <small className="text-danger align-self-end">
                        {formik.touched.min_completion_days &&
                        formik.errors &&
                        formik.errors.min_completion_days
                          ? formik.errors.min_completion_days
                          : ""}
                      </small>
                    </div>
                    <div className="col-md-6 form-group">
                      <label
                        htmlFor="max_completion_days"
                        className="col-form-label w-100 mb-2"
                      >
                        Maximum
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="max_completion_days"
                        name="max_completion_days"
                        onChange={handleNumber}
                        maxLength={10}
                        value={
                          formik.values.max_completion_days
                            ? formik.values.max_completion_days
                            : ""
                        }
                        onBlur={formik.handleBlur}
                      />
                      <small className="text-danger align-self-end">
                        {formik.touched.max_completion_days &&
                        formik.errors &&
                        formik.errors.max_completion_days
                          ? formik.errors.max_completion_days
                          : ""}
                      </small>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 form-group">
                  <div className="d-flex flex-row">
                    <input
                      className="mr-2"
                      type="checkbox"
                      id="mandatory"
                      name="mandatory"
                      onChange={(e) =>
                        formik.setFieldValue("mandatory", e.target.checked)
                      }
                      checked={formik.values.mandatory}
                      onBlur={formik.handleBlur}
                      //   disabled={formik.values.screener_question_id}
                    />
                    <label className="col-form-label w-100">Mandatory</label>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="row">
                <div className="col-md-12 form-group">
                  <label
                    htmlFor="plan_action_name"
                    className="col-form-label w-100 mb-2"
                  >
                    Action Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="plan_action_name"
                    name="plan_action_name"
                    onChange={formik.handleChange}
                    // maxLength={30}
                    value={
                      formik.values.plan_action_name
                        ? formik.values.plan_action_name
                        : ""
                    }
                    onBlur={formik.handleBlur}
                  />
                  <small className="text-danger align-self-end">
                    {formik.touched.plan_action_name &&
                    formik.errors &&
                    formik.errors.plan_action_name
                      ? formik.errors.plan_action_name
                      : ""}
                  </small>
                </div>
                <Description
                  formik={formik}
                  webName="description"
                  showEditor={true}
                />
              </div>
            </div>
            <div className="col-md-6 form-group ">
              <label
                htmlFor="plan_action_type"
                className="col-form-label w-100 mb-2"
              >
                Action Type
              </label>
              <MultiSelect
                option={PlanActionType}
                onChange={(e) => formik.setFieldValue("plan_action_type", e)}
                placeholder="Select group"
                isCreatable={false}
                isMulti={false}
                type="Sub Domain"
                name="plan_action_type"
                id="plan_action_type"
                onBlur={formik.handleBlur}
                defaultValue={formik.values.plan_action_type || "Reset"}
              />
              <small className="text-danger align-self-end">
                {formik.touched.plan_action_type &&
                formik.errors &&
                formik.errors.plan_action_type
                  ? formik.errors.plan_action_type
                  : ""}
              </small>
            </div>
            {renderActionInput(formik.values.plan_action_type?.value)}
            <div
              className={"d-flex align-items-end justify-content-end col-md-12"}
            >
              <button
                onClick={resetModal}
                type="button"
                className="btn btn-light btn-sm float-right btn-add-domain mr-2"
              >
                Reset
              </button>
              <CustomButton
                type="button"
                className="btn btn-primary btn-outline-success btn-sm float-right btn-add-domain"
                disabled={
                  Object.keys(formik.errors).length !== 0 ||
                  !formik.isValid ||
                  formik.isSubmitting
                }
                onClick={
                  formik.isValid && Object.keys(formik.values).length !== 0
                    ? formik.handleSubmit
                    : handleError
                }
              >
                Create
              </CustomButton>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const validationSchema = Yup.object().shape({
  sub_domain_ids: Yup.array().of(
    Yup.object().shape({
      label: Yup.string(),
      value: Yup.string(),
    })
  ),
  // .test({
  //   message: "Select sub domain",
  //   test: (arr) => {
  //     return arr && arr.length > 0;
  //   },
  // }),
  plan_group_id: Yup.object().required("Select group"),
  plan_action_type: Yup.object().required("Select type"),
  sort_order: Yup.string().required("Enter sort order"),
  // .required("Enter sort order"),
  plan_action_name: Yup.string().required("Enter action name"),
  // .min(4, "Name should be min of 4 characters")
  // .max(30, "Name should be maximum of 30 characters")
  description: Yup.string().required("Enter description"),
  mobile_description: Yup.string().required("Enter mobile description"),
  plan_action_code: Yup.string().required("Enter action code"),
  min_completion_days: Yup.string(),
  action_time_seconds: Yup.string(),
  // .required("Enter minimum days"),
  max_completion_days: Yup.string(),
  // .required("Enter maximum days"),
  start_interval_seconds: Yup.string(),
  // .required("Enter start interval"),
  mandatory: Yup.boolean(),
});
