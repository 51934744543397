import { useState } from "react";
import { GET_TIMEZONE_LIST_GQL } from "../../Apollo/AdminServices/QueryRequest";
import graphHandler from "../../Apollo/graphHandler";
function useTimeZoneList(){
    const [timeZoneList, setTimeZoneList] = useState([]);
    const [loading, setLoading] = useState(false);
    
  const getTimeZoneList = async (req = {}) => {
    setLoading(true);
    try {
      let { data } = await graphHandler().invoke(
        GET_TIMEZONE_LIST_GQL,
        req,
        1,
        false,
        true
      );
      if (
        data &&
        data.getTimeZoneList &&
        data.getTimeZoneList.time_zones &&
        data.getTimeZoneList.time_zones.length > 0
      ) {
        // console.log(
        //   "getTimeZoneList Success",
        //   data.getTimeZoneList.time_zones
        // );
        setTimeZoneList([]);
        setTimeZoneList(data.getTimeZoneList.time_zones.slice());
        setLoading(false);
      } else {
        setTimeZoneList([]);
        setLoading(false);
      }
    } catch (error) {
      console.log("getTimeZoneList Error", error);
      setTimeZoneList([]);
      setLoading(false);
    }
  };
  return {
    timeZoneList,
    loading,
    getTimeZoneList,
  };
  
}
export default useTimeZoneList;