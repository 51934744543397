import { useEffect, useState } from "react";
import PaginationComponent from "../../components/PaginationComponent";
import "./questionaire.css";
import withRoleBased from "../../utils/Functions/withRoleBased";
import usePlatformList from "../../utils/CustomHooks/usePlatformList";
import CreatePlatformContent from "./modal/CreatePlatformContent";
import ContentListItem from "./components/ContentListItem";
import { ContentTag } from "../../constants";

function PlatformContent({ access = {} }) {
  const { platformContentList, loading, getPlatformList } = usePlatformList(
    true,
    true
  );

  const [count, setCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [selectTag, setSelectTag] = useState("");
  const [pageSize] = useState(10);
  const pageCount = Math.ceil(platformContentList.length / pageSize);
  const [search, setSearch] = useState("");
  const [isSearching, setIsSearch] = useState(false);

  const handleClick = (event, index) => {
    event.preventDefault();
    setCurrentPage(index);
  };

  useEffect(() => {
    let req = {};
    if (search) req.platform_content_mode_name = search;
    if (selectTag) req.platform_content_mode_tag = selectTag;
    getPlatformList(req);
  }, [count]);
  useEffect(() => {}, []);
  const onSearch = () => {
    setCurrentPage(0)
    setCount((x) => x + 1);
    setIsSearch(search ? true : false);
  };

  return (
    <main className="page-content">
      <div className="container-fluid">
        <div className="row ">
          <div className="col-md-6 mb-3">
            <h5 className="page-title">Platform Content Mode</h5>
          </div>
          <div className="col-md-6  mb-3 d-flex justify-content-end">
            {access.canCreate && (
              <button
                type="button"
                className="btn btn-outline-success "
                onClick={() => setIsOpen(true)}
              >
                Create
              </button>
            )}
          </div>
          <div className="col-md-4">
            <div className="search" style={{ margin: "16px 0px 32px" }}>
              <input
                type="text"
                className="form-control"
                placeholder="Search"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                onKeyDown={(e) => e.key === "Enter" && onSearch()}
              />
              {!isSearching ? (
                <button onClick={onSearch}>
                  <i className="fa fa-search" />
                </button>
              ) : (
                <button
                  onClick={() => {
                    setSearch("");
                    setIsSearch(false);
                    setCount((x) => x + 1);
                  }}
                >
                  <i className="fa fa-times" />
                </button>
              )}
            </div>
          </div>
          <div className="col-md-4 form-group d-flex">
            <select
              style={{ margin: "16px 0px 32px" }}
              className="custom-select"
              name="platform_content_mode_tag"
              id="platform_content_mode_tag"
              onChange={(e) => {
                setCount((x) => x + 1);
                setSelectTag(e.target.value);
              }}
              value={selectTag ?? ""}
            >
              {ContentTag.map((data, i) => (
                <option key={i} value={data.value} hidden={!data.value}>
                  {data.name}
                </option>
              ))}
            </select>
            {selectTag && (
              <button
                type="button"
                style={{ margin: "16px 0px 32px 10px" }}
                className="btn btn-outline-success "
                onClick={() => {
                  setSelectTag("");
                  setSearch("");
                  setIsSearch(false);
                  setCount((x) => x + 1);
                }}
              >
                Clear
              </button>
            )}
          </div>
          <div className="col-md-12">
            <div className="table-responsive ">
              <table className="table domain-table  table-borderless ">
                <thead>
                  <tr className=" ">
                    <td className="">Name</td>
                    <td className="">Content Type</td>
                    <td className="">Content</td>
                    <td className="">Tag</td>
                    <td className="">Is Featured</td>
                    <td className="">Is Pilot</td>
                    <td className="">Is Live</td>
                    {/* <td className="">Status</td> */}
                    {access.canCreate && <td className="">Action</td>}
                  </tr>
                </thead>
                <tbody>
                  {!loading && platformContentList.length > 0 ? (
                    platformContentList
                      .slice(
                        currentPage * pageSize,
                        (currentPage + 1) * pageSize
                      )
                      .map((x, i) => (
                        <ContentListItem
                          item={x}
                          key={i}
                          index={i}
                          access={access}
                          refresh={() => setCount((x) => x + 1)}
                        />
                      ))
                  ) : (
                    <tr>
                      <td className="text-center font-weight-bold" colSpan="7">
                        {loading ? "Loading..." : "No Data Found"}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {platformContentList.length > 0 && pageCount != 1 && (
          <PaginationComponent
            handleClick={handleClick}
            currentPage={currentPage}
            pageCount={pageCount}
          />
        )}
        {access.canCreate && (
          <CreatePlatformContent
            isOpen={isOpen}
            setOpen={() => setIsOpen(false)}
            refresh={() => setCount((x) => x + 1)}
          />
        )}
      </div>
    </main>
  );
}
export default withRoleBased(PlatformContent, "tag");
