import { useState } from "react";
import { toast } from "react-toastify";
import { MetaDataType } from "../../../constants";
import EditorModal from "../../Modal/EditorModal";

export default function UpdateMetaCard({
  formik = {},
  valueKey = "meta_data_value",
  titleKey = "meta_data_title",
  constType = "Instruction",
  typeKey = "meta_data_type",
  disabled,
  setVisible = () => {},
  reset = () => {},
}) {
  const [isOpen, setOpen] = useState(false);

  const handleError = () => {
    if (Object.keys(formik.errors).length > 0) {
      toast.error(formik.errors[Object.keys(formik.errors)[0]]);
    }
  };

  const resetIn = () => {
    setVisible(false);
    reset();
  };
  return (
    <div className="row px-3 pt-3 position-relative rounded-4 shadow-common">
      <EditorModal
        isOpen={isOpen}
        defaultValue={formik.values[valueKey] ? formik.values[valueKey] : ""}
        close={() => setOpen(false)}
        save={(e) => {
          formik.setFieldValue(valueKey, e);
        }}
        disabled={disabled}
      />
      <div className="col-md-4">
        <div className="row">
          {!constType && (
            <div className="col-md-12 form-group">
              <label htmlFor={typeKey} className="col-form-label w-100 mb-2">
                Type
              </label>
              <select
                className="custom-select"
                name={typeKey}
                id={typeKey}
                onChange={formik.handleChange}
                value={formik.values[typeKey] ? formik.values[typeKey] : ""}
                onBlur={formik.handleBlur}
                disabled={disabled}
              >
                <option key="Select" hidden>
                  Select
                </option>
                {MetaDataType.map((x, i) => (
                  <option value={x} key={i}>
                    {x}
                  </option>
                ))}
              </select>
              <small className="text-danger col-md-12">
                {formik.touched[typeKey] && formik.errors[typeKey]
                  ? formik.errors[typeKey]
                  : ""}
              </small>
            </div>
          )}
          <div className="col-md-12 form-group">
            <label htmlFor={titleKey} className="col-form-label w-100 mb-2">
              Key
            </label>
            <input
              type="text"
              className="form-control"
              name={titleKey}
              id={titleKey}
              onChange={formik.handleChange}
              placeholder=""
              value={formik.values[titleKey] ? formik.values[titleKey] : ""}
              onBlur={formik.handleBlur}
              disabled={disabled}
            />
            <small className="text-danger col-md-12">
              {formik.touched[titleKey] && formik.errors[titleKey]
                ? formik.errors[titleKey]
                : ""}
            </small>
          </div>
        </div>
      </div>
      <div className="col-md-8 ">
        <div className="row">
          <div className="col-md-8 form-group">
            <label htmlFor={valueKey} className="col-form-label w-100 mb-2">
              Value
              <button
                className="btn-link-common float-right"
                onClick={() => setOpen(true)}
              >
                Use Editor
              </button>
            </label>
            <textarea
              type="text"
              className="form-control"
              name={valueKey}
              id={valueKey}
              onChange={formik.handleChange}
              placeholder=""
              value={formik.values[valueKey] ? formik.values[valueKey] : ""}
              onBlur={formik.handleBlur}
              disabled={disabled}
            />
            <small className="text-danger col-md-12">
              {formik.touched[valueKey] && formik.errors[valueKey]
                ? formik.errors[valueKey]
                : ""}
            </small>
          </div>
          <div className="col-md-4 d-flex align-items-end justify-content-end mb-5">
            {Number.isInteger(formik.values.index) ? (
              <button className="btn btn-light btn-sm mr-3" onClick={reset}>
                Reset
              </button>
            ) : null}
            <button
              className="btn btn-primary btn-outline-success btn-sm"
              onClick={
                formik.isValid && Object.keys(formik.values).length !== 0
                  ? formik.handleSubmit
                  : handleError
              }
              disabled={Object.keys(formik.errors).length !== 0}
            >
              {!Number.isInteger(formik.values.index) ? "Save" : "Update"}
            </button>
          </div>
        </div>
      </div>
      <button
        type="button"
        className="btn btn-link position-absolute"
        style={{ top: "0px", right: "0px" }}
        onClick={resetIn}
      >
        <i className="fas fa-times"></i>
      </button>
    </div>
  );
}
