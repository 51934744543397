import React, { useState } from "react";
import useDomain from "../../../utils/CustomHooks/useDomain";
import UpdateSubDomain from "../modal/UpdateSubDomain";
import { Link } from "react-router-dom";
import graphHandler from "../../../Apollo/graphHandler";
import { Popover } from "reactstrap";
import { ACTIVATE_SUBDOMAIN_GQL } from "../../../Apollo/AdminServices/MutationRequest";
import { toast } from "react-toastify";
import { getDescription } from "../../../utils/Functions/filter";
import ReadMore from "../../../components/ReadMore";
import { VisibilityStatus } from "../../../constants";
import SubDomainModal from "../modal/SubDomainModal";

export default function SubDomainListItem({
  item,
  refresh,
  domainList = [],
  index,
  access = {},
}) {
  const [isEdit, setEdit] = useState(false);
  const { domain, loading } = useDomain(item.domain_id);
  const [showPop, setShowPop] = useState(false);
  const toggle = () => setShowPop((x) => !x);
  const activateItem = async (active) => {
    if (!item.sub_domain_id) return;
    toggle();
    try {
      const bae = await graphHandler().invoke(
        ACTIVATE_SUBDOMAIN_GQL,
        { active_request: { active, sub_domain_id: item.sub_domain_id } },
        1,
        false,
        true
      );
      toast.success(
        `${item.sub_domain_name} ${
          active ? "Activated" : "Deactivated"
        } Successfully`
      );
      refresh();
    } catch (error) {
      console.log("activateSubDomain error", error);
    }
  };
  return (
    <tr>
      <td className="col-sm-2">{item.sub_domain_name}</td>
      <td>
        <ReadMore>
          {getDescription(item.sub_domain_description, "Web")
            ? getDescription(item.sub_domain_description, "Web").description
            : ""}
        </ReadMore>
      </td>
      <td>{!loading ? domain.domain_name || "-" : "loading..."}</td>
      <td>
        <span
          className={`${
            item.visibility_status === "Active" ? "good" : "bad"
          } text-center btn btn-sm`}
        >
          {
            VisibilityStatus.find((x) => x.value === item.visibility_status)
              .name
          }
        </span>
      </td>
      {access.canEdit && (
        <td>
          <button
            type="button"
            className="btn btn-outline-success btn-sm "
            onClick={() => setEdit(true)}
          >
            Edit
          </button>
        </td>
      )}
      {/* <td
        className="accordion-menu"
        id={`subDomainData${index}`}
        onClick={toggle}
      >
        <i className="fas fa-ellipsis-v" />
      </td>
      <Popover
        isOpen={showPop}
        toggle={toggle}
        placement="left"
        target={`subDomainData${index}`}
        trigger="legacy"
      >
        <div className="accordion-menu-header">Options</div>

        <div
          className="accordion-menu-item"
          onClick={() => activateItem(!item.active)}
        >
          {!item.active ? "Activate" : "Deactivate"}
        </div>
      </Popover> */}
      <SubDomainModal
        domainList={domainList}
        isOpen={isEdit}
        setOpen={() => setEdit(false)}
        initialValues={item}
        refresh={refresh}
      />
    </tr>
  );
}
