/* eslint-disable react/display-name */
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import CustomLoader from "../../components/Loader";
const withRoleBased =
  (Component, type = "user") =>
  (props) => {
    const history = useHistory();
    const isLogin = useSelector(({ Auth }) => Auth.isLogin);
    const isLoading = useSelector(({ Auth }) => Auth.loading);
    const isDone = useSelector(({ Role }) => Role.isDone);
    const Role = useSelector(({ Role }) => Role);
    const [access, setAccess] = useState({ ...defaultValue });
    useEffect(() => {
      if (!isLoading) {
        if (!isLogin) {
          history.push("/login");
        }
      }
      if (!isLoading && isLogin && type && isDone && Role[type]) {
        const current = Role[type];
        let temp = { ...defaultValue };
        temp.canView = current.find((x) => x === "View") ? true : false;
        temp.canCreate = current.find((x) => x === "Create") ? true : false;
        temp.canEdit = current.find((x) => x === "Edit") ? true : false;
        temp.canApprove = current.find((x) => x === "Approve") ? true : false;
        temp.canReview = current.find((x) => x === "Review") ? true : false;
        temp.isAdmin = Role.label === "Admin" ? true : false;
        temp.isSuper = Role.label === "Super Admin" ? true : false;
        temp.isContent =
          Role.label && Role.label.includes("Content") ? true : false;
        setAccess(temp);
      }
    }, [history, isLoading, isLogin, type, isDone, Role]);

    if (isLoading) {
      return <CustomLoader />;
    } else {
      return <Component {...props} access={access} />;
    }
  };
export default withRoleBased;

const defaultValue = {
  canView: true,
  canEdit: false,
  canApprove: false,
  canCreate: false,
  canReview: false,
  isAdmin: false,
  isContent: false,
};
