import { useEffect, useState } from "react";
import { Menu, MenuItem, ProSidebar } from "react-pro-sidebar";
import { Link } from "react-router-dom";
import { Collapse } from "reactstrap";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import "./SideBar.scss";
import {
  contentMenuList,
  gameMenuList,
  masterMenuList,
  userMenuList,
} from "../../constants";
import { useSelector } from "react-redux";
// import "react-pro-sidebar/dist/css/styles.css";
const SideBar = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [active, setActive] = useState(0);
  const { isLogin } = useSelector(({ Auth }) => Auth);
  const history = useHistory();
  const location = useLocation();
  const onClickMenuIcon = () => {
    setCollapsed((x) => !x);
  };
  useEffect(() => {
    if (isLogin && location.pathname) {
      if (userMenuList.find((x) => location.pathname.includes(x.url))) {
        setActive(1);
      } else if (
        contentMenuList.find((x) => location.pathname.includes(x.url))
      ) {
        setActive(2);
      } else if (
        masterMenuList.find((x) => location.pathname.includes(x.url))
      ) {
        setActive(3);
      } else if (gameMenuList.find((x) => location.pathname.includes(x.url))) {
        setActive(4);
      }
    }
  }, [isLogin]);
  return (
    <ProSidebar
      // className="sidebar-content"
      collapsed={collapsed}
      breakPoint="sm"
    >
      <div
        className={`menu-chevron ${collapsed ? "toggle" : ""}`}
        onClick={onClickMenuIcon}
      >
        {/* <i className="fas fa-bars"/> */}
        <i className="icon fas fa-chevron-left" />
      </div>
      <Menu iconShape="circle">
        <div
          onClick={() => {
            setActive((x) => (x === 1 ? 0 : 1));
            history.push("/home");
          }}
          className={`topic ${active === 1 && "active"}`}
        >
          User Management
          <i className="fa fa-chevron-down float-right" />
        </div>

        <Collapse isOpen={active === 1}>
          {userMenuList.map((x, i) => (
            <MenuItem
              key={i}
              icon={x.icon}
              active={location.pathname.includes(x.url)}
            >
              {x.name}
              <Link to={x.url} />
            </MenuItem>
          ))}
        </Collapse>
        <div
          onClick={() => {
            setActive((x) => (x === 2 ? 0 : 2));
            history.push("/domain");
          }}
          className={`topic ${active === 2 && "active"}`}
        >
          Content Management
          <i className="fa fa-chevron-down float-right" />
        </div>
        <Collapse isOpen={active === 2}>
          {contentMenuList.map((x, i) => (
            <MenuItem
              key={i}
              icon={x.icon}
              active={
                location.pathname.includes("sub-domain")
                  ? location.pathname === "/" + x.url
                  : location.pathname.includes(x.url)
              }
            >
              {x.name}
              <Link to={x.url} />
            </MenuItem>
          ))}
        </Collapse>
        <div
          onClick={() => {
            setActive((x) => (x === 3 ? 0 : 3));
            // history.push("/country");
          }}
          className={`topic ${active === 3 && "active"}`}
        >
          Master Management
          <i className="fa fa-chevron-down float-right" />
        </div>
        <Collapse isOpen={active === 3}>
          {masterMenuList.map((x, i) => (
            <MenuItem
              key={i}
              icon={x.icon}
              active={location.pathname.includes(x.url)}
            >
              {x.name}
              <Link to={x.url} />
            </MenuItem>
          ))}
        </Collapse>
        <div
          onClick={() => {
            setActive((x) => (x === 4 ? 0 : 4));
            // history.push("/domain");
          }}
          className={`topic ${active === 4 && "active"}`}
        >
          Game Management
          <i className="fa fa-chevron-down float-right" />
        </div>
        <Collapse isOpen={active === 4}>
          {gameMenuList.map((x, i) => (
            <MenuItem
              key={i}
              icon={x.icon}
              active={location.pathname.includes(x.url)}
            >
              {x.name}
              <Link to={x.url} />
            </MenuItem>
          ))}
        </Collapse>
      </Menu>
    </ProSidebar>
  );
};

export default SideBar;
