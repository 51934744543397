import { useEffect, useState } from "react";
import { GET_POLICY_LIST_GQL } from "../../Apollo/AdminServices/QueryRequest";
import graphHandler from "../../Apollo/graphHandler";

function usePolicyList(id) {
  const [policyList, setPolicyList] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (id) {
      getPolicyList(id);
    } else {
      setPolicyList([]);
    }
  }, [id]);

  const getPolicyList = async (req = {}) => {
    setLoading(true);
    try {
      const { data } = await graphHandler().invoke(
        GET_POLICY_LIST_GQL,
        { ...req },
        1,
        false,
        true
      );
      if (
        data &&
        data.getPolicyList &&
        data.getPolicyList.policies &&
        data.getPolicyList.policies.length > 0
      ) {
        // console.log("getPolicyList Success", data.getPolicyList);
        setPolicyList([]);
        setPolicyList(data.getPolicyList.policies);
        setLoading(false);
      } else {
        setPolicyList([]);
        setLoading(false);
      }
    } catch (error) {
      console.log("getPolicyList Error", error);
      setPolicyList([]);
      setLoading(false);
    }
  };

  return {
    policyList,
    loading,
    getPolicyList,
  };
}

export default usePolicyList;
