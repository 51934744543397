import { useState } from "react";
// import useGetContent from "../../../utils/CustomHooks/useGetContent";
import UpdateRule from "../modal/UpdateRule";
import { Switch } from "antd";
import { toast } from "react-toastify";
import { Popconfirm } from "antd";
import useRuleEngineAPI from "../../../utils/CustomHooks/useRuleEngineAPI";

export default function RuleListItem({
  item = {},
  access,
  refresh = () => {},
}) {
 const [isEdit, setEdit] = useState(false);
  const { canEdit, isAdmin } = access;
  const { activateRule, loading, removeRule } = useRuleEngineAPI();


  const onDelete = () => {
    if (loading) return;
    removeRule({ rule_engine_id: item.rule_engine_id }, refresh);
  };

  const activateItem = async (active) => {
    if (!item.rule_engine_id) return;
    const request = {
      active_request: {
        active,
        rule_engine_id: item.rule_engine_id,
      },
    };
    activateRule(request, onActivate(active));
  };

  const onActivate = (active) => {
    setTimeout(() => {
      toast.success(
        `${"This rule"} ${active ? "Activated" : "Deactivated"} Successfully`
      );

      refresh();
    }, [1000]);
  };

  return (
    <tr className="option-table">
      {isAdmin && (
        <td>
          <Switch checked={item.active} onChange={activateItem} />
        </td>
      )}
      <td>{item.platform_type || "-"}</td>
      <td>{item.rule_input_type || "-"}</td>
      <td>{item.rule_output_type || "-"}</td>
      <td>
        <span
          className={`${item.active ? "good" : "bad"} text-center btn btn-sm`}
        >
          {item.active ? "Active" : "Disabled"}
        </span>
      </td>

      <td>
        {canEdit && (
          <button
            type="button"
            className="ml-2 btn btn-outline-success btn-sm "
            onClick={() => setEdit(true)}
          >
            Edit
          </button>
        )}
        {isAdmin && (
          <Popconfirm
            title="Are you sure to delete this rule?"
            onConfirm={onDelete}
            okText="Yes"
            cancelText="No"
          >
            <i className="ml-2 fas fa-trash cursor-pointer"></i>
          </Popconfirm>
        )}
      </td>
      {canEdit && (
        <UpdateRule
          isOpen={isEdit}
          setOpen={() => setEdit(false)}
          refresh={refresh}
          values={item}
        />
      )}
    </tr>
  );
}
