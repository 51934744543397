import { useEffect, useState } from "react";
import { GET_QUESTIONAIRE_HISTORIES } from "../../Apollo/ApplicationService/applicationQueryRequest";
import graphHandler from "../../Apollo/graphHandler";
function useQuestionaireHistories(id = "") {
  const [screenerHistories, setQuestionHistories] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (id) {
      getQuestionHistory(id);
    } else {
      setQuestionHistories([]);
    }
  }, [id]);

  const getQuestionHistory = async (screener_question_id) => {
    if (!screener_question_id) return;
    setLoading(true);
    try {
      const { data } = await graphHandler().invoke(
        GET_QUESTIONAIRE_HISTORIES,
        { screener_question_id },
        1
      );
      if (
        data &&
        data.getAllScreenerQuestionHistories &&
        data.getAllScreenerQuestionHistories.screener_question_history_list &&
        data.getAllScreenerQuestionHistories.screener_question_history_list
          .length > 0
      ) {
        // console.log(
        //   "getAllScreenerQuestionHistories Success",
        //   data.getAllScreenerQuestionHistories.screener_question_history_list
        // );
        setQuestionHistories(
          data.getAllScreenerQuestionHistories.screener_question_history_list
        );
        setLoading(false);
      } else {
        setQuestionHistories([]);
        setLoading(false);
      }
    } catch (error) {
      console.log("getAllScreenerQuestionHistories Error", error);
      setQuestionHistories([]);
      setLoading(false);
    }
  };
  return {
    screenerHistories,
    loading,
    getQuestionHistory,
  };
}
export default useQuestionaireHistories;
