import { useEffect, useState } from "react";
import useDomainList from "../../../utils/CustomHooks/useDomainList";
import { isEqual } from "../../../utils/Functions/filter";
import TopicSectionItem from "../Components/TopicSectionItem";
import CreateTopicSection from "./CreateTopicSection";
import UpdateTopicSection from "./UpdateTopicSection";
import { Popconfirm } from "antd";

export default function TopicSection(props) {
  let {
    values = {},
    topicList = [],
    refresh = () => {},
    refreshQues = () => {},
    onClose = () => {},
    onDone = () => {},
    onBack = () => {},
    handleDiscord = () => {},
    isEditable,
    isGame,
  } = props;
  const { domainList, getDomainList } = useDomainList("");
  const [topic, setTopic] = useState({});
  const screener_history = topic.pending_history ? topic.pending_history : {};
  const hasUpdated = Boolean(screener_history && screener_history.updated_data);
  const [history, setHistory] = useState(false);
  
  // console.log("topic",topic)
  useEffect(() => {
    getDomainList();
    refresh();
    refreshQues();
  }, [topic]);
  const ActionComponent = topic.screener_topic_id
    ? UpdateTopicSection
    : CreateTopicSection;
  return (
    <div className="row justify-content-center ">
      <div className="col-md-12">
        <div className="table-responsive border p-2">
          <table className="table questionnaire-table table-borderless mb-0">
            <thead>
              <tr className=" ">
                <th className="text-center">Topic</th>
                <th className="text-center">Aggregate Type</th>
                <th className="text-center">Calculation Type</th>
                <th className="text-center">
                  Domain
                </th>
                <th className="text-center">Action</th>
              </tr>
            </thead>
            <tbody>
              {topicList&&topicList.length > 0 ? (
                topicList
                  .slice()
                  .filter((x)=>x?.active === true)
                  .sort((a, b) => a.sort_order - b.sort_order)
                  .map((item, i) => (
                    <TopicSectionItem
                      item={item}
                      key={i}
                      isEditable={isEditable}
                      onEdit={setTopic}
                      canDelete={!values.published}
                      refresh={refresh}
                      domainList={domainList}
                      setHistory={setHistory}
                    />
                  ))
              ) : (
                <tr>
                  <td className="text-center" colSpan="5">
                    No Topics Found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      {isEditable && (
        <ActionComponent
          {...props}
          topic={topic}
          reset={() => setTopic({})}
          domainList={domainList}
        />
      )}
      <div className="d-flex align-items-end justify-content-center col-md-12 p-5">
        {isEditable && (hasUpdated || history) && (
          <Popconfirm
            title="Are you sure to discard and close?"
            onConfirm={handleDiscord}
            //  onCancel={cancel}
            okText="Yes"
            cancelText="No"
          >
            <button
             type="button"
              className="btn btn-danger btn-sm float-right btn-add-domain mr-2"
            >
              Discard All & Close
            </button>
          </Popconfirm>
        )}
        <button
          onClick={onClose}
          type="button"
          className="btn btn-danger btn-sm float-right btn-add-domain mr-2"
        >
          Close
        </button>{" "}
        <button
          onClick={onBack}
          type="button"
          className="btn btn-error btn-sm float-right btn-add-domain mr-2"
        >
          Back
        </button>
        {(topicList.length > 0 || isGame) && (
          <button
            onClick={onDone}
            type="button"
            className="btn btn-secondary btn-sm float-right btn-add-domain mr-2"
          >
            Next
          </button>
        )}
      </div>
    </div>
  );
}
