import { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import graphHandler from "../../../Apollo/graphHandler";
import {
  IsNullOrEmpty,
  mergeMultiSelectArray,
  objectHasKey,
  removeEmptyObjValue,
} from "../../../utils/Functions/filter";
import { UPDATE_BLOG_GQL } from "../../../Apollo/AdminServices/MutationRequest";
import useInterventionList from "../../../utils/CustomHooks/useInterventionList";
import CustomButton from "../../../components/Button/CustomButton";
import ContentDesignModal from "../../../components/Content";
import TextInputField from "../../../components/HistoryEditInput/TextInputField";
import NewReactSelectInputField from "../../../components/HistoryEditInput/NewReactSelectInputField";
import SelectInputField from "../../../components/HistoryEditInput/SelectInputField";
import DescriptionInputField from "../../../components/HistoryEditInput/DescriptionInputField";
import MetaHistoryInput from "../../../components/HistoryEditInput/MetaHistoryInput";
import ImageHistoryInput from "../../../components/HistoryEditInput/ImageHistoryInput";
import VideoHistoryInput from "../../../components/HistoryEditInput/VideoHistoryInput";
import useBlogAPI from "../../../utils/CustomHooks/useBlogAPI";
import { Popconfirm } from "antd";

export default function EditBlogModal({
  initialValues,
  isOpen,
  isEditable = false,
  onDone = () => {},
  refresh = () => {},
  setBlog,
  setLoading = () => {},
  onClose = () => {},
  subDomainList = [],
  categories = [],
  authors = [],
}) {
  // const subDomains = useSelector(({ DataSet }) => DataSet.subDomains);
  const [selectedList, setSelectedList] = useState([]);
  const [selectedCategoryList, setSelectedCategoryList] = useState([]);
  const [selectedAuthorList, setSelectedAuthorList] = useState([]);
  const [selectedInterventionList, setInterventionList] = useState([]);

  const [interventions, setInterventions] = useState([]);
  const [isContentOpen, setContentOpen] = useState(false);
  const [preview, setPreview] = useState(false);
  const [canEdit, setEdit] = useState(false);

  const { interventionList, getInterventionList, resetIntervention } =
    useInterventionList("");
  const [type, setIsVideo] = useState(false);
  const { reviewBlog } = useBlogAPI();
  // const {
  // interventionList: firstIntervention,
  // getInterventionList: getFirstInter,
  // } = useInterventionList("");

  const blog_history = initialValues.pending_history
    ? initialValues.pending_history
    : {};
  const hasUpdated = Boolean(blog_history && blog_history.updated_data);
  const updated_data = hasUpdated ? blog_history.updated_data : {};
  // console.log("initialValues",initialValues,initialValues.pending_history)
  const formik = useFormik({
    initialValues: {
      // sub_domain_ids: [],
      // blog_type: "Default",
    },
    // validationSchema: type ? videoValidation : validationSchema,
    onSubmit: (values) => {
      onSubmit(values);
    },
  });
  useEffect(() => {
    if (initialValues?.blog_id) {
      getInterventionList({ published: true, active: true });
    }
  }, [initialValues]);
  useEffect(() => {
    setIsVideo(
      (formik.values.blog_type || initialValues.blog_type) === "Video"
    );
  }, [initialValues.blog_type, formik.values.blog_type]);

  useEffect(() => {
    if (interventionList.length > 0) {
      let list = interventionList.map((x) => ({
        label: x.intervention_name,
        value: x.intervention_id,
      }));
      setInterventions(list);
    }
  }, [interventionList.length]);

  // useEffect(() => {
  //   if (subDomains.length > 0) {
  //     getFirstInter({ published: true, active: true });
  //   }
  // }, [subDomains]);

  // useEffect(() => {
  //   if (
  //     initialValues &&
  //     initialValues?.sub_domain_ids &&
  //     initialValues?.sub_domain_ids.length > 0
  //   ) {
  //     let sub_domain_ids = initialValues?.sub_domain_ids.map((x) => x);
  //     getInterventionList({ published: true, active: true, sub_domain_ids });
  //   }
  // }, [initialValues?.sub_domain_ids]);

  // useEffect(() => {
  //   if (
  //     formik.values.sub_domain_ids &&
  //     formik.values.sub_domain_ids.length > 0 &&
  //     !type
  //   ) {
  //     let sub_domain_ids = formik.values.sub_domain_ids.map(
  //       (x) => x.value || x.update_data
  //     );
  //     getInterventionList({ published: true, active: true, sub_domain_ids });
  //   } else {
  //     formik.setFieldValue("intervention_ids", []);
  //     resetIntervention([]);
  //   }
  // }, [formik.values.sub_domain_ids, type]);
  // console.log("initial",initialValues)
  useEffect(() => {
    if (initialValues?.blog_id) {
      onSetDefault(initialValues, hasUpdated ? updated_data : {});
    }
  }, [initialValues, interventions, categories, subDomainList, authors]);

  const onSetDefault = async (data = {}, publish = {}) => {
    const { sub_domain_ids, category_ids, author_ids, intervention_ids } = data;
    if (subDomainList && subDomainList.length > 0) {
      let subArray = [],
        sub_domains =
          sub_domain_ids && sub_domain_ids.length > 0 ? sub_domain_ids : [],
        publishedSub =
          publish.sub_domain_ids &&
          publish.sub_domain_ids.update_items &&
          publish.sub_domain_ids.update_items.length > 0
            ? publish.sub_domain_ids.update_items
            : [];
      let value = mergeMultiSelectArray(sub_domains, publishedSub);
      let i = 0;
      subArray = value.map((x) => {
        let ret = {
          ...subDomainList.find((y) => y.value === x.data),
          ...x,
          index: i,
        };
        if (x.isRemoved) {
          ret.value = subDomainList.find((y) => y.value === x.data).label || "";
        } else {
          i++;
        }
        return ret;
      });
      setSelectedList(subArray);
    }
    if (categories && categories.length > 0) {
      let subArray = [],
        categoryArray =
          (await category_ids) && category_ids.length > 0 ? category_ids : [],
        publishedSub =
          (await publish.category_ids) &&
          publish.category_ids.update_items &&
          publish.category_ids.update_items.length > 0
            ? publish.category_ids.update_items
            : [];
      let value = await mergeMultiSelectArray(categoryArray, publishedSub);
      let i = 0;
      subArray = value.map((x) => {
        let ret = {
          ...categories.find((y) => y.value === x.data),
          ...x,
          index: i,
        };
        if (x.isRemoved) {
          ret.value = categories.find((y) => y.value === x.data).label || "";
        } else {
          i++;
        }
        return ret;
      });
      setSelectedCategoryList(subArray);
    }
    if (authors && authors.length > 0) {
      let subArray = [],
        authorArray =
          (await author_ids) && author_ids.length > 0 ? author_ids : [],
        publishedSub =
          (await publish.author_ids) &&
          publish.author_ids.update_items &&
          publish.author_ids.update_items.length > 0
            ? publish.author_ids.update_items
            : [];
      let value = await mergeMultiSelectArray(authorArray, publishedSub);
      let i = 0;
      subArray = value.map((x) => {
        let ret = {
          ...authors.find((y) => y.value === x.data),
          ...x,
          index: i,
        };
        if (x.isRemoved) {
          ret.value = authors.find((y) => y.value === x.data).label || "";
        } else {
          i++;
        }
        return ret;
      });
      setSelectedAuthorList(subArray);
    }
    if (interventions && interventions.length > 0) {
      let subArray = [],
        interventionArray =
          (await intervention_ids) && intervention_ids.length > 0
            ? intervention_ids
            : [],
        publishedSub =
          (await publish.intervention_ids) &&
          publish.intervention_ids.update_items &&
          publish.intervention_ids.update_items.length > 0
            ? publish.intervention_ids.update_items
            : [];
      let value = await mergeMultiSelectArray(interventionArray, publishedSub);
      let i = 0;
      subArray = value.map((x) => {
        let ret = {
          ...interventions.find((y) => y.value === x.data),
          ...x,
          index: i,
        };
        if (x.isRemoved) {
          ret.value = interventions.find((y) => y.value === x.data).label || "";
        } else {
          i++;
        }
        return ret;
      });
      setInterventionList(subArray);
    }
  };

  const onClosed = () => {
    onClose();
    formik.resetForm();
    formik.setValues({});
    refresh();
  };

  const onSubmit = async (value) => {
    const {
      // blog_id,
      blog_contents,
      blog_name,
      blog_type,
      blog_descriptions,
      blog_image_urls,
      blog_video_urls,
      intervention_ids,
      blog_meta,
      category_ids,
      sub_domain_ids,
      read_duration,
      sort_order,
      author_ids,
      alias_name,
    } = value;
    const update_request = {
      blog_id: initialValues.blog_id,
    };
    if (objectHasKey(value, "blog_name")) {
      update_request.blog_name = blog_name;
    }
    if (objectHasKey(value, "alias_name")) {
      update_request.alias_name = alias_name;
    }
    if (objectHasKey(value, "blog_type")) {
      update_request.blog_type = blog_type;
    }
    if (
      sub_domain_ids &&
      sub_domain_ids.length > 0 &&
      objectHasKey(value, "sub_domain_ids")
    ) {
      update_request.sub_domain_ids = { update_items: sub_domain_ids };
    }
    if (
      category_ids &&
      category_ids.length > 0 &&
      objectHasKey(value, "category_ids")
    ) {
      update_request.category_ids = { update_items: category_ids };
    }
    if (
      author_ids &&
      author_ids.length > 0 &&
      objectHasKey(value, "author_ids")
    ) {
      update_request.author_ids = { update_items: author_ids };
    }
    if (
      !type &&
      intervention_ids &&
      intervention_ids.length > 0 &&
      objectHasKey(value, "intervention_ids")
    ) {
      update_request.intervention_ids = { update_items: intervention_ids };
    }
    // if (category_id) update_request.category_id = category_id.value;
    // if (read_duration)
    //   update_request.read_duration = Number(read_duration) * 60;
    if (objectHasKey(value, "read_duration")) {
      update_request.read_duration = Number(read_duration) * 60;
    }
    if (objectHasKey(value, "sort_order")) {
      update_request.sort_order = Number(sort_order);
    }
    if (!type && blog_contents && blog_contents.length > 0) {
      update_request.blog_contents = [
        ...blog_contents.map((x) => {
          let obj = {
            sort_order: Number(x.sort_order),
            blog_content_type: x.blog_content_type,
          };
          if (x.title) obj.title = x.title;
          if (x.content) obj.content = x.content;
          if (x.image_urls) obj.image_urls = x.image_urls;
          return obj;
        }),
      ];
    }
    if (objectHasKey(value, "blog_descriptions")) {
      update_request.blog_descriptions = {
        update_items: blog_descriptions,
      };
    }
    if (!type && objectHasKey(value, "blog_image_urls")) {
      update_request.blog_image_urls = {
        update_items: blog_image_urls.map((x) => removeEmptyObjValue(x)),
      };
    }
    if (objectHasKey(value, "blog_meta")) {
      update_request.blog_meta = {
        update_items: blog_meta.map((x) => removeEmptyObjValue(x)),
      };
    }
    if (type && objectHasKey(value, "blog_video_urls")) {
      update_request.blog_video_urls = {
        update_items: blog_video_urls.map((x) => removeEmptyObjValue(x)),
      };
    }
    if (Object.keys(update_request).length === 1) {
      refresh();
      formik.setSubmitting(false);
      setLoading(false);
      return;
    }
    // return
    try {
      const { data } = await graphHandler().invoke(
        UPDATE_BLOG_GQL,
        { update_request },
        1,
        true
      );

      if (data && data.updateBlog && data.updateBlog.blog) {
        setBlog({
          ...data.updateBlog.blog,
          pending_history: data.updateBlog.blog_history,
        });
      }
      onDone();
      refresh();
      setLoading(false);
      formik.setSubmitting(false);
    } catch (error) {
      console.log("updateBlog error", error);
      formik.setSubmitting(false);
    }
  };

  const handleError = () => {
    if (Object.keys(formik.errors).length > 0) {
      toast.error(formik.errors[Object.keys(formik.errors)[0]]);
    }
  };
  const handleDiscord = () => {
    const request = {
      review_request: {
        comment: "Discord Values",
        approve: false,
      },
      blog_history_id: blog_history.blog_history_id,
    };
    reviewBlog(request, onClosed);
  };

  return (
    <div className="container py-5 position-relative">
      {isEditable && (
        <button
          type="button"
          className="btn btn-link position-absolute"
          style={{ top: "0px", right: "60px" }}
          onClick={() => setEdit((x) => !x)}
        >
          {canEdit ? (
            <>
              <i className="fas fa-times"></i> Cancel
            </>
          ) : (
            <>
              <i className="fas fa-pen"></i> Edit
            </>
          )}
        </button>
      )}
      <div className="intervention-form row justify-content-center ">
        <div className="col-md-6">
          <div className="row">
            <NewReactSelectInputField
              parentForm={formik}
              id="sub_domain_ids"
              className="col-md-12"
              title="Sub Domain"
              placeholder="Select Sub Domain"
              options={subDomainList}
              isCreatable={false}
              isMulti={true}
              disabled={!canEdit}
              isPublished={initialValues.published}
              defaultValue={selectedList}
              updateValue={
                updated_data.sub_domain_ids &&
                updated_data.sub_domain_ids.update_items &&
                updated_data.sub_domain_ids.update_items.length > 0
                  ? updated_data.sub_domain_ids.update_items
                  : false
              }
              validation={{
                sub_domain_ids: Yup.array()
                  .of(
                    Yup.object().shape({
                      label: Yup.string(),
                      value: Yup.string(),
                    })
                  )
                  .test({
                    message: "Select a sub domain",
                    test: (arr) => {
                      return arr && arr.length > 0;
                    },
                  }),
              }}
            />
            <TextInputField
              parentForm={formik}
              updateValue={updated_data.blog_code}
              actualValue={initialValues.blog_code}
              id="blog_code"
              title="Code"
              disabled={initialValues.blog_id}
              validation={{
                blog_code: Yup.string().trim().required("Enter a code"),
              }}
            />
            <NewReactSelectInputField
              parentForm={formik}
              id="category_ids"
              title="Category"
              placeholder="Select a category"
              options={categories}
              isCreatable={false}
              isPublished={initialValues.published}
              isMulti={true}
              disabled={!canEdit}
              defaultValue={selectedCategoryList}
              updateValue={
                updated_data.category_ids &&
                updated_data.category_ids.update_items &&
                updated_data.category_ids.update_items.length > 0
                  ? updated_data.category_ids.update_items
                  : false
              }
              validation={{
                category_ids: Yup.array()
                  .of(
                    Yup.object().shape({
                      label: Yup.string(),
                      value: Yup.string(),
                    })
                  )
                  .test({
                    message: "Select a category",
                    test: (arr) => {
                      return arr && arr.length > 0;
                    },
                  }),
              }}
            />
            {((initialValues.sub_domain_ids &&
              initialValues.sub_domain_ids.length > 0) ||
              (formik.values.sub_domain_ids &&
                formik.values.sub_domain_ids.length > 0)) &&
              !type && (
                <NewReactSelectInputField
                  parentForm={formik}
                  className="col-md-12 form-group"
                  id="intervention_ids"
                  title="Intervention"
                  isPublished={initialValues.published}
                  placeholder="Select a intervention"
                  options={interventions}
                  isCreatable={false}
                  isMulti={true}
                  disabled={!canEdit}
                  defaultValue={
                    selectedInterventionList
                    // &&selectedInterventionList.length > 0
                    //   ? selectedInterventionList
                    //   : []
                  }
                  updateValue={
                    updated_data.intervention_ids &&
                    updated_data.intervention_ids.update_items &&
                    updated_data.intervention_ids.update_items.length > 0
                      ? updated_data.intervention_ids.update_items
                      : false
                  }
                  validation={{
                    intervention_ids: Yup.array().of(
                      Yup.object().shape({
                        label: Yup.string(),
                        value: Yup.string(),
                      })
                    ),
                    // .test({
                    //   message: "Select a Intervention",
                    //   test: (arr) => {
                    //     return arr && arr.length > 0;
                    //   },
                    // }),
                  }}
                />
              )}
            <NewReactSelectInputField
              parentForm={formik}
              className="col-md-12 form-group"
              id="author_ids"
              title="Author"
              placeholder="Select a author"
              options={authors}
              isCreatable={false}
              isMulti={true}
              isPublished={initialValues.published}
              disabled={!canEdit}
              defaultValue={selectedAuthorList}
              updateValue={
                updated_data.author_ids &&
                updated_data.author_ids.update_items &&
                updated_data.author_ids.update_items.length > 0
                  ? updated_data.author_ids.update_items
                  : false
              }
              validation={{
                author_ids: Yup.array().of(
                  Yup.object().shape({
                    label: Yup.string(),
                    value: Yup.string(),
                  })
                ),
                // .test({
                //   message: "Select a author",
                //   test: (arr) => {
                //     return arr && arr.length > 0;
                //   },
                // }),
              }}
            />
            <div className="col-md-12">
              <div className="row">
                <TextInputField
                  parentForm={formik}
                  className={`${type ? "col-md-4" : "col-md-6"} form-group`}
                  updateValue={
                    !IsNullOrEmpty(updated_data.read_duration)
                      ? updated_data.read_duration / 60
                      : ""
                  }
                  actualValue={
                    !IsNullOrEmpty(initialValues.read_duration)
                      ? initialValues.read_duration / 60
                      : ""
                  }
                  disabled={!canEdit}
                  id="read_duration"
                  title="Read Duration(in Mins)"
                  validation={{
                    read_duration: Yup.string()
                      .trim()
                      .required("Enter a read duration"),
                  }}
                  isNumber={true}
                  inputProps={{
                    maxLength: 10,
                  }}
                />
                {type ? (
                  <TextInputField
                    parentForm={formik}
                    className="col-md-4 form-group"
                    updateValue={
                      !IsNullOrEmpty(updated_data.sort_order)
                        ? updated_data.sort_order
                        : ""
                    }
                    actualValue={
                      !IsNullOrEmpty(initialValues.sort_order)
                        ? initialValues.sort_order
                        : ""
                    }
                    disabled={!canEdit}
                    id="sort_order"
                    title="Sort Order"
                    validation={{
                      sort_order: Yup.string()
                        .trim()
                        .required("Enter a sort order"),
                    }}
                    isNumber={true}
                    inputProps={{
                      maxLength: 10,
                    }}
                  />
                ) : null}
                <SelectInputField
                  parentForm={formik}
                  className={`${type ? "col-md-4" : "col-md-6"} form-group`}
                  updateValue={updated_data.blog_type}
                  actualValue={initialValues.blog_type || ""}
                  id="blog_type"
                  title="Type"
                  disabled={!canEdit}
                  validation={{
                    blog_type: Yup.string().required("Select a type"),
                  }}
                  options={[
                    {
                      label: "Default",
                      value: "Default",
                    },
                    {
                      label: "Video",
                      value: "Video",
                    },
                  ]}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="row">
            <TextInputField
              parentForm={formik}
              updateValue={updated_data.blog_name}
              actualValue={initialValues.blog_name}
              id="blog_name"
              title="Name"
              disabled={!canEdit}
              validation={{
                blog_name: Yup.string().trim().required("Enter a name"),
              }}
            />
            <TextInputField
              parentForm={formik}
              updateValue={updated_data.alias_name ?? ""}
              actualValue={initialValues.alias_name ?? ""}
              id="alias_name"
              title="Alias Name"
              disabled={!canEdit}
              customBlur={true}
              validation={{
                alias_name: Yup.string().trim(),
              }}
            />
            <DescriptionInputField
              parentForm={formik}
              parentKey="blog_descriptions"
              className="col-12"
              updateValue={
                updated_data.blog_descriptions &&
                updated_data.blog_descriptions.update_items
                  ? updated_data.blog_descriptions.update_items
                  : false
              }
              disabled={!canEdit}
              actualValue={initialValues.blog_descriptions}
              validation={{
                blog_descriptions: Yup.string()
                  .trim()
                  .required("Enter a description"),
                mobile_description: Yup.string()
                  .trim()
                  .required("Enter a mobile description"),
              }}
              showEditor={true}
            />
          </div>
        </div>
        <div className="col-md-12">
          <MetaHistoryInput
            parentForm={formik}
            parentArray="blog_meta"
            typeKey="type"
            titleKey="key"
            valueKey="value"
            isPublished={initialValues.published}
            updateValue={
              updated_data.blog_meta && updated_data.blog_meta.update_items
                ? updated_data.blog_meta.update_items
                : false
            }
            disabled={!canEdit}
            validation={{
              blog_meta: Yup.array().of(
                Yup.object().shape({
                  typeKey: Yup.string().trim().required("Select a data type"),
                  titleKey: Yup.string().trim().required("Enter a title"),
                  valueKey: Yup.string().trim().required("Enter a value"),
                })
              ),
            }}
            actualValue={initialValues.blog_meta}
          />
        </div>
        {!type ? (
          <div className="col-md-12">
            <ImageHistoryInput
              parentForm={formik}
              contentId={initialValues.blog_id}
              contentType="BLOG"
              isPublished={initialValues.published}
              parentArray="blog_image_urls"
              actualValue={initialValues.blog_image_urls}
              updateValue={
                updated_data.blog_image_urls &&
                updated_data.blog_image_urls.update_items
                  ? updated_data.blog_image_urls.update_items
                  : false
              }
              disabled={!canEdit}
              validation={{
                blog_image_urls: Yup.array().of(
                  Yup.object().shape({
                    is_mobile: Yup.boolean().required("Select a Image device"),
                    image_type: Yup.string().required("Select a Image format"),
                    url: Yup.string(),
                  })
                ),
              }}
            />
          </div>
        ) : null}
        {type ? (
          <VideoHistoryInput
            disabled={!canEdit}
            parentForm={formik}
            contentId={initialValues.blog_id}
            contentType="BLOG"
            isPublished={initialValues.published}
            heading="Video File"
            parentArray="blog_video_urls"
            actualValue={initialValues.blog_image_urls}
            updateValue={
              updated_data.blog_video_urls &&
              updated_data.blog_video_urls.update_items
                ? updated_data.blog_video_urls.update_items
                : false
            }
            validation={{
              blog_video_urls: Yup.array().of(
                Yup.object().shape({
                  is_mobile: Yup.boolean().required("Select a video device"),
                  video_type: Yup.string().required("Select a video format"),
                  video_url: Yup.string(),
                })
              ),
            }}
          />
        ) : null}
        {!type ? (
          <div className="col-md-12 mb-3">
            <label
              htmlFor="sub_domain_id"
              className="blog-label w-100 d-flex mb-2"
            >
              Blog Content{" "}
              <div>
                {((initialValues.blog_contents &&
                  initialValues.blog_contents.length > 0) ||
                  (formik.values &&
                    formik.values.blog_contents &&
                    formik.values.blog_contents.length > 0)) && (
                  <button
                    type="button"
                    className="btn btn-outline-success ml-3 btn-sm"
                    disabled={!canEdit}
                    onClick={() => {
                      setContentOpen(true);
                      setPreview(true);
                    }}
                  >
                    Preview
                  </button>
                )}
                <button
                  type="button"
                  className="btn btn-outline-success ml-3 btn-sm"
                  disabled={!canEdit}
                  onClick={() => setContentOpen(true)}
                >
                  Design
                </button>
              </div>
            </label>
            <small className="text-danger">
              {formik.errors && formik.errors.blog_contents
                ? formik.errors.blog_contents
                : ""}
            </small>
          </div>
        ) : null}
        <div className="d-flex align-items-end justify-content-end col-md-12">
          {isEditable && canEdit && hasUpdated && (
            <Popconfirm
              title="Are you sure to discard and close?"
              onConfirm={handleDiscord}
              //  onCancel={cancel}
              okText="Yes"
              cancelText="No"
            >
              <button
                type="button"
                className="btn btn-danger btn-sm float-right btn-add-domain mr-2"
              >
                Discard All & Close
              </button>
            </Popconfirm>
          )}
          <button
            onClick={onClosed}
            type="button"
            className="btn btn-danger btn-sm float-right btn-add-domain mr-2"
          >
            Close
          </button>
          {canEdit ? (
            <CustomButton
              type="button"
              className="btn btn-primary btn-outline-success btn-sm float-right btn-add-domain"
              disabled={Object.keys(formik.errors).length !== 0}
              onClick={
                formik.isValid && Object.keys(formik.values).length !== 0
                  ? formik.handleSubmit
                  : handleError
              }
            >
              Update
            </CustomButton>
          ) : (
            <button
              onClick={onDone}
              type="button"
              className="btn btn-secondary btn-sm float-right btn-add-domain mr-2"
            >
              Next
            </button>
          )}
        </div>
      </div>
      <ContentDesignModal
        isOpen={isContentOpen}
        onSave={(e) => {
          formik.setFieldValue("blog_contents", e);
          setContentOpen(false);
          setPreview(false);
        }}
        onCancel={() => {
          setContentOpen(false);
          setPreview(false);
        }}
        preview={preview}
        setPreview={setPreview}
        reset={isOpen}
        contentId={initialValues.blog_id}
        contentType="BLOG"
        updateValue={
          updated_data.blog_contents && updated_data.blog_contents.length > 0
            ? updated_data.blog_contents
            : false
        }
        values={
          formik.values &&
          formik.values.blog_contents &&
          formik.values.blog_contents.length > 0
            ? formik.values.blog_contents
            : []
        }
        actualValue={
          initialValues.blog_contents && initialValues.blog_contents.length > 0
            ? initialValues.blog_contents
            : []
        }
      />
    </div>
  );
}
