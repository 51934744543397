import { toast } from "react-toastify";
import { DocumentFormat, ImageFormat, MediaDevice, VideoFormat } from "../../../constants";
import CreateMediaFileUpload from "../../MediaFileUpload/CreateMediaFileUpload";

export default function UpdateImageCard(props) {
  let {
    formik = {},
    disabled,
    contentId,
    contentType = "",
    setVisible = () => {},
    reset = () => {},
  } = props;
  const handleError = () => {
    if (Object.keys(formik.errors).length > 0) {
      toast.error(formik.errors[Object.keys(formik.errors)[0]]);
    }
  };

  const resetIn = () => {
    setVisible(false);
    reset();
    formik.setValues({});
    formik.setTouched({});
  };
  // console.log("formik",formik.values.is_mobile)
  return (
    <div className="row px-3 pt-3 position-relative rounded-4 shadow-common">
      <div className="col-md-12 ">
        <div className="row">
          <div className="col-md-6">
            <div htmlFor="is_mobile" className="col-form-label w-100 mb-2">
              <select
                className="custom-select"
                id="is_mobile"
                name="is_mobile"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={
                  formik.values.is_mobile
                }
              >
                <option key="Select" hidden>
                  Select a device
                </option>
                {MediaDevice.map((x, i) => (
                  <option value={x.value} key={i}>
                    {x.label}
                  </option>
                ))}
              </select>
            </div>
          </div>
            <div className="col-md-6">
              <div htmlFor="image_type" className="col-form-label w-100 mb-2">
                <select
                  className="custom-select"
                  id="image_type"
                  name="image_type"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={
                    formik?.values?.image_type
                  }
                >
                  <option key="Select" hidden>
                    Select a format
                  </option>
                  {ImageFormat.map((x, i) => (
                    <option value={x.value} key={i}>
                      {x.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          {formik.values.image_type ? (
            <div className="col-md-6">
              <div htmlFor="media_upload" className="col-form-label w-100 mb-2">
                <CreateMediaFileUpload
                  id="imageUrl"
                  name="mediaUrl"
                  formik={formik}
                  contentId={contentId}
                  mediaType="Image"
                  contentType={contentType}
                  format={formik.values.image_type}
                />
              </div>
            </div>
          ) : null}
          <div className="col-md-12 d-flex align-items-end justify-content-end mb-5">
            {Number.isInteger(formik.values.index) ? (
              <button className="btn btn-light btn-sm mr-3" onClick={reset}>
                Reset
              </button>
            ) : null}
            <button
              className="btn btn-primary btn-outline-success btn-sm"
              onClick={
                formik.isValid && Object.keys(formik.values).length !== 0
                  ? formik.handleSubmit
                  : handleError
              }
              disabled={Object.keys(formik.errors).length !== 0 || !formik?.values?.mediaUrl?.url}
            >
              {!Number.isInteger(formik.values.index) ? "Save" : "Update"}
            </button>
          </div>
        </div>
      </div>
      <button
        type="button"
        className="btn btn-link position-absolute"
        style={{ top: "0px", right: "0px" }}
        onClick={resetIn}
      >
        <i className="fas fa-times"></i>
      </button>
    </div>
  );
}
