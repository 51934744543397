import { useEffect, useState } from "react";
import bannerImg2 from "../../../assets/Images/Blog/banner2.png";
import PlayIcon from "../../../assets/Images/Blog/play-button.png";
import { getImage } from "../../../utils/Functions/filter";
import MarkdownIt from "markdown-it";
const mdParser = new MarkdownIt(/* Markdown-it options */);

export default function MediaLeft({
  value = {},
  isPreview = false,
  onDelete = () => {},
  onEdit = () => {},
}) {
  const [isPlaying, setPlaying] = useState(false);
  useEffect(() => {
    const bannerVideo = document.getElementById(value.id);
    if (!bannerVideo) return;
    if (isPlaying) {
      bannerVideo.play();
    } else {
      bannerVideo.pause();
    }
  }, [isPlaying]);
  const image_urls = value.image_urls;
  const video = image_urls ? getImage(image_urls, "Video") : false;
  const image = !video ? getImage(image_urls) : false;

  const parseContent = (e, i) => {
    return (
      <div
        key={i}
        className="blog-own-description custom-html-style"
        dangerouslySetInnerHTML={{
          __html: mdParser.render(e),
        }}
      ></div>
    );
  };
  return (
    <section className="wit-section-top">
      <div className="container">
        {value.title && (
          <div className="row">
            <div className="col-12 col-sm-12">
              <h1 className="main-section-title mb-3">{value.title}</h1>
            </div>
          </div>
        )}
        <div className="row">
          <div className="col-12 col-sm-6">
            <div className="blog-template-img">
              {video && video.url ? (
                <>
                  <video
                    src={video.url}
                    onClick={() => setPlaying((x) => !x)}
                    id={value.id}
                    loop
                    muted
                  />
                </>
              ) : (
                <img
                  src={image && image.url ? image.url : bannerImg2}
                  alt="Privcybanner"
                />
              )}
            </div>
            {video && video.url && (
              <div
                className="video-play-btn"
                onClick={() => setPlaying((x) => !x)}
              >
                {!isPlaying && <img src={PlayIcon} alt="play" />}
              </div>
            )}
          </div>
          <div className="col-12 col-sm-6">
            {value.content &&
              value.content.map(
                (x, i) => parseContent(x, i)
                // <p key={i} className="blog-own-description text-align-justify">
                //   {x}
                // </p>
              )}
          </div>
        </div>
      </div>
      <div className="tool-tip">
        <button onClick={() => onEdit(value)}>
          <i className="fas fa-edit" />
        </button>
        <button onClick={() => onDelete(value)}>
          <i className="fas fa-trash" />
        </button>
      </div>
    </section>
  );
}
