import { toast } from "react-toastify";
import graphHandler from "../../../Apollo/graphHandler";
import { ACTIVATE_USER_GQL } from "../../../Apollo/ProfileService/profileMutateRequest";
import { Popover } from "reactstrap";
import { useState } from "react";
import { useSelector } from "react-redux";

export default function UserTableItems({ data, refresh, index, access }) {
  const user = useSelector(({ Auth }) => Auth.user);
  const [showPop, setShowPop] = useState(false);
  const toggle = () => setShowPop((x) => !x);
  const activateUser = async (activate) => {
    if (!data.user_id || user.user_id === data.user_id) return;
    toggle();
    try {
      const bae = await graphHandler().invoke(
        ACTIVATE_USER_GQL,
        { activate, user_id: data.user_id },
        0,
        false,
        true
      );
      toast.success(
        `${data.name} ${activate ? "Activated" : "Deactivated"} Successfully`
      );
      refresh();
    } catch (error) {
      console.log("activateUser error", error);
    }
  };
  return (
    <tr>
      <td className="col-sm-2">{data.name}</td>
      <td style={{ textTransform: "none" }}>{data.email}</td>
      <td>
        <span
          className={`${data.active ? "good" : "bad"} text-center btn btn-sm`}
        >
          {data.active ? "Active" : "Disabled"}
        </span>
      </td>
      <td className="col-sm-2">
        <a href={"/#/user-details?id=" + data.user_id}>Show Details</a>
      </td>
      {access.canEdit && (
        <>
          {user.user_id !== data.user_id && (
            <td
              className="accordion-menu"
              id={`userData${index}`}
              onClick={toggle}
            >
              <i className="fas fa-ellipsis-v" />
            </td>
          )}
          {user.user_id !== data.user_id && (
            <Popover
              isOpen={showPop}
              toggle={toggle}
              placement="left"
              target={`userData${index}`}
              trigger="legacy"
            >
              <div className="accordion-menu-header">Options</div>

              <div
                className="accordion-menu-item"
                onClick={() => activateUser(!data.active)}
              >
                {!data.active ? "Activate" : "Deactivate"}
              </div>
            </Popover>
          )}
        </>
      )}
    </tr>
  );
}
