import React, { useState, useEffect } from "react";
import PaginationComponent from "../../../components/PaginationComponent";
import useTherapyHistory from "../../../utils/CustomHooks/useTherapyHistory";
import { sortArray } from "../../../utils/Functions/filter";
import QuestionTableItem from "../components/QuestionTableItem";

export default function TherapyHistoryTable({ id = "" }) {
  const { therapyHistory, loading, getTherapyHistory } = useTherapyHistory("");
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const pageCount = Math.ceil(therapyHistory.length / pageSize);

  useEffect(() => {
    if (id) getTherapyHistory(id);
  }, [id]);

  const handleClick = (event, index) => {
    event.preventDefault();
    setCurrentPage(index);
  };

  return (
    <>
      <table className="table table-borderless ">
        <thead>
          <tr className=" ">
            <td className="">Action</td>
            <td className="">Time </td>
            <td className="">By</td>
          </tr>
        </thead>
        <tbody>
          {therapyHistory.length > 0 ? (
            sortArray(therapyHistory)
              .slice(currentPage * pageSize, (currentPage + 1) * pageSize)
              .map((x, i) => <QuestionTableItem data={x} key={i} />)
          ) : (
            <tr>
              <td className="text-center font-weight-bold" colSpan="7">
                {loading ? "loading..." : "No Hisory Found..."}
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {therapyHistory.length > 0 && (
        <PaginationComponent
          handleClick={handleClick}
          currentPage={currentPage}
          pageCount={pageCount}
        />
      )}
    </>
  );
}
