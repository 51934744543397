import { useEffect, useState } from "react";
import { GET_TOPIC_LIST_GQL } from "../../Apollo/AdminServices/QueryRequest";
import graphHandler from "../../Apollo/graphHandler";
import { sortArray } from "../Functions/filter";
function useTopicList(id) {
  const [topicList, setTopics] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (id) {
      getTopicsList(id);
    } else {
      setTopics([]);
    }
  }, [id]);
  const getTopicsList = async (req = {}) => {
    // if (!screener_id) return;
    setLoading(true);
    try {
      const { data } = await graphHandler().invoke(
        GET_TOPIC_LIST_GQL,
        req,
        1,
        false,
        true
      );
      if (
        data &&
        data.getScreenerTopicList &&
        data.getScreenerTopicList.screener_topics &&
        data.getScreenerTopicList.screener_topics.length > 0
      ) {
        // console.log(
        //   "getScreenerTopicList Success",
        //   data.getScreenerTopicList.screener_topics
        // );
        setTopics([]);
        const struct = data.getScreenerTopicList.screener_topics
          .slice()
          .map((x) => ({
            ...x.screener_topic,
            pending_history: x.pending_screener_topic_history,
          }));
        setTopics(sortArray(struct));
        setLoading(false);
      } else {
        setTopics([]);
        setLoading(false);
      }
    } catch (error) {
      console.log("getScreenerTopicList Error", error);
      setTopics([]);
      setLoading(false);
    }
  };
  return {
    topicList,
    loading,
    getTopicsList,
  };
}
export default useTopicList;
