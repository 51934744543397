import { useState } from "react";
import { GET_MODULE_ACTION_LIST_GQL } from "../../Apollo/AdminServices/QueryRequest";
import graphHandler from "../../Apollo/graphHandler";
function useModuleActions() {
  const [actionList, setActions] = useState([]);
  const [loading, setLoading] = useState(false);

  const getActionList = async (req = {}) => {
    setLoading(true);
    try {
      let { data } = await graphHandler().invoke(
        GET_MODULE_ACTION_LIST_GQL,
        req,
        1,
        false,
        true
      );
      if (
        data &&
        data.getTherapyModuleActionList &&
        data.getTherapyModuleActionList.therapy_module_actions &&
        data.getTherapyModuleActionList.therapy_module_actions.length > 0
      ) {
        const struct = data.getTherapyModuleActionList.therapy_module_actions
          .map((x) => ({
            ...x.therapy_module_action,
            pending_history: x.pending_therapy_module_action_history,
          }))
          .slice()
          .sort(
            (a, b) =>
              new Date(b.updated_at ? b.updated_at : b.created_at) -
              new Date(a.updated_at ? a.updated_at : a.created_at)
          );
        // console.log(
        //   "getActionList Success",
        //   data.getTherapyModuleActionList.therapy_module_actions
        // );
        setActions([]);
        setActions(struct);
        setLoading(false);
      } else {
        setActions([]);
        setLoading(false);
      }
    } catch (error) {
      console.log("getActionList Error", error);
      setActions([]);
      setLoading(false);
    }
  };
  return {
    actionList,
    loading,
    getActionList,
    resetActionList: () => setActions([]),
  };
}
export default useModuleActions;
