import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import graphHandler from "../../../Apollo/graphHandler";
import CustomButton from "../../../components/Button/CustomButton";
import { FontSize } from "../../../constants";
import ColorPicker from "../../../components/Input/ColorPicker";
import Description from "../../../components/Input/Description";
import { ADD_BANNER_GQL } from "../../../Apollo/AdminServices/ObjectiveRequest";
import { useSelector } from "react-redux";

export default function CreateBanner({
  setBanner = () => {},
  refresh = () => {},
}) {
  const initialValues = {
    title_font_color: "#ffffff",
    description_font_color: "#ffffff",
    description_font_size: "1vw",
    title_font_size: "3vw",
  };
  const subDomains = useSelector(({ DataSet }) => DataSet.subDomains);

  const formik = useFormik({
    initialValues: { ...initialValues },
    validationSchema: validation,
    onSubmit: (values) => {
      onSubmit(values);
    },
  });

  const onSubmit = async (values) => {
    const {
      banner_code,
      banner_name,
      sort_order,
      description,
      mobile_description,
      description_font_color,
      description_font_size,
      title,
      sub_domain_id,
      title_font_color,
      title_font_size,
    } = values;
    const addReq = {
      banner_name,
      sort_order: Number(sort_order),
      banner_code,
      banner_location: "HomePage",
    };
    if (sub_domain_id) {
      addReq.sub_domain_id = sub_domain_id;
    }
    let banner_content = {},
      description_value = [];
    if (title) {
      banner_content.title = title;
    }
    if (title_font_color) {
      banner_content.title_font_color = title_font_color;
    }
    if (title_font_size) {
      banner_content.title_font_size = title_font_size;
    }
    if (description_font_color) {
      banner_content.description_font_color = description_font_color;
    }
    if (description_font_size) {
      banner_content.description_font_size = description_font_size;
    }
    if (Object.keys(banner_content).length > 0)
      addReq.banner_content = { ...banner_content };
    if (description) {
      description_value.push({
        client_type: "Web",
        description: description,
      });
    }
    if (mobile_description) {
      description_value.push({
        client_type: "Mobile",
        description: mobile_description,
      });
    }
    if (description_value.length > 0) {
      addReq.banner_descriptions = description_value;
    }
    try {
      const { data } = await graphHandler().invoke(
        ADD_BANNER_GQL,
        { addReq },
        1,
        true
      );
      refresh();
      if (data && data.AddBanner && data.AddBanner.banner) {
        setBanner(data.AddBanner.banner);
      }
      formik.setSubmitting(false);
    } catch (error) {
      console.log("CreateBanner error", error);
      formik.setSubmitting(false);
    }
  };
  const handleError = () => {
    if (Object.keys(formik.errors).length > 0) {
      toast.error(formik.errors[Object.keys(formik.errors)[0]]);
    }
  };

  const handleNumber = (e) => {
    const { name, value } = e.target;
    const re = /^[0-9\b]+$/;
    let temp = !value ? value : re.test(value) ? value : formik.values[name];
    formik.setFieldValue(name, temp ? temp.substring(0, 10) : temp);
  };

  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-md-6 form-group  ">
          <label htmlFor="banner_name" className="col-form-label w-100 mb-2">
            Name
          </label>
          <input
            type="text"
            className="form-control"
            id="banner_name"
            name="banner_name"
            value={
              formik.values && formik.values.banner_name
                ? formik.values.banner_name
                : ""
            }
            onChange={(e) =>
              formik.setFieldValue(
                "banner_name",
                e.target.value.trimStart()
              )
            }
            onBlur={(e) => {
              formik.setFieldTouched("banner_name", true);
              formik.setFieldValue(
                "banner_name",
                e.target.value.replace(/\s+/g, " ").trim()
              );
            }}
          />
          <small className="text-danger">
            {formik.touched.banner_name &&
            formik.errors &&
            formik.errors.banner_name
              ? formik.errors.banner_name
              : ""}
          </small>
        </div>
        {/* <div className="col-md-6">
          <div className="row"> */}
            <div className="col-md-6 form-group">
              <label
                htmlFor="banner_code"
                className="col-form-label w-100 mb-2"
              >
                Code
              </label>
              <input
                type="text"
                className="form-control"
                id="banner_code"
                name="banner_code"
                onChange={formik.handleChange}
                value={
                  formik.values.banner_code ? formik.values.banner_code : ""
                }
                onBlur={formik.handleBlur}
              />
              <small className="text-danger align-self-end">
                {formik.touched.banner_code &&
                formik.errors &&
                formik.errors.banner_code
                  ? formik.errors.banner_code
                  : ""}
              </small>
            </div>
            <div className="col-md-6 form-group ">
              <label
                htmlFor="sub_domain_id"
                className="col-form-label w-100 mb-2"
              >
                Sub Domain
              </label>
              <select
                id="sub_domain_id"
                name="sub_domain_id"
                className="custom-select"
                onChange={formik.handleChange}
                value={
                  formik.values.sub_domain_id ? formik.values.sub_domain_id : ""
                }
                onBlur={formik.handleBlur}
              >
                <option key="Select" hidden>
                  Select
                </option>
                {subDomains.map((x, i) => (
                  <option key={i} value={x.sub_domain_id}>
                    {x.sub_domain_name}
                  </option>
                ))}
              </select>
              <small className="text-danger align-self-end">
                {formik.touched.sub_domain_id &&
                formik.errors &&
                formik.errors.sub_domain_id
                  ? formik.errors.sub_domain_id
                  : ""}
              </small>
            </div>
            <div className="col-md-6 form-group">
              <label htmlFor="sort_order" className="col-form-label w-100 mb-2">
                Sort Order
              </label>
              <input
                {...formik.getFieldProps("sort_order")}
                type="text"
                className="form-control "
                id="sort_order"
                onChange={handleNumber}
                maxLength={10}
                value={formik.values.sort_order ? formik.values.sort_order : ""}
              />
              <small className="text-danger align-self-end">
                {formik.touched.sort_order &&
                formik.errors &&
                formik.errors.sort_order
                  ? formik.errors.sort_order
                  : ""}
              </small>
            </div>
          {/* </div>
        </div> */}
        <div className="col-md-12">
          <div className="col-md-12 section-border  form-group">
            <label htmlFor="title" className="section-label">
              Title
            </label>
            <div className="row">
              <div className="col-md-4">
                <input
                  type="text"
                  className="form-control"
                  name="title"
                  id="title"
                  placeholder="Your title"
                  onChange={formik.handleChange}
                  value={formik.values.title ? formik.values.title : ""}
                  onBlur={formik.handleBlur}
                />
                <small className="text-danger col-md-12">
                  {formik.touched.title && formik.errors.title
                    ? formik.errors.title
                    : ""}
                </small>
              </div>
              <div className="col-md-4">
                <select
                  className="custom-select"
                  name="title_font_size"
                  id="title_font_size"
                  onChange={formik.handleChange}
                  value={
                    formik.values.title_font_size
                      ? formik.values.title_font_size
                      : ""
                  }
                  onBlur={formik.handleBlur}
                >
                  <option key="Select" hidden>
                    Font Size
                  </option>
                  {FontSize.map((x, i) => (
                    <option value={x.value} key={i}>
                      {x.label}
                    </option>
                  ))}
                </select>
                <small className="text-danger col-md-12">
                  {formik.touched.title_font_size &&
                  formik.errors.title_font_size
                    ? formik.errors.title_font_size
                    : ""}
                </small>
              </div>
              <div className="col-md-4">
                <ColorPicker
                  id="title_font_color"
                  name="title_font_color"
                  onBlur={() =>
                    formik.setFieldTouched("title_font_color", true)
                  }
                  value={
                    formik.values.title_font_color
                      ? formik.values.title_font_color
                      : ""
                  }
                  onChange={(e) => formik.setFieldValue("title_font_color", e)}
                />
                <small className="text-danger col-md-12">
                  {formik.touched.title_font_color &&
                  formik.errors.title_font_color
                    ? formik.errors.title_font_color
                    : ""}
                </small>
              </div>
            </div>
          </div>
          <div className="col-md-12 section-border  form-group">
            <label htmlFor="title" className="section-label">
              Description
            </label>
            <div className="row">
              <Description
                formik={formik}
                className="col-md-12"
                col="col-6"
                webTitle="Web Description"
                showEditor={true}
              />
              <div className="col-md-6 form-group">
                <label
                  htmlFor="description_font_size"
                  className="col-form-label w-100 mb-2"
                >
                  Font size
                </label>
                <select
                  className="custom-select"
                  name="description_font_size"
                  id="description_font_size"
                  onChange={formik.handleChange}
                  value={
                    formik.values.description_font_size
                      ? formik.values.description_font_size
                      : ""
                  }
                  onBlur={formik.handleBlur}
                >
                  <option key="Select" hidden>
                    Font Size
                  </option>
                  {FontSize.map((x, i) => (
                    <option value={x.value} key={i}>
                      {x.label}
                    </option>
                  ))}
                </select>
                <small className="text-danger col-md-12">
                  {formik.touched.description_font_size &&
                  formik.errors.description_font_size
                    ? formik.errors.description_font_size
                    : ""}
                </small>
              </div>
              <div className="col-md-6 form-group">
                <label
                  htmlFor="description_font_color"
                  className="col-form-label w-100 mb-2"
                >
                  Color
                </label>
                <ColorPicker
                  id="description_font_color"
                  name="description_font_color"
                  onBlur={() =>
                    formik.setFieldTouched("description_font_color", true)
                  }
                  value={
                    formik.values.description_font_color
                      ? formik.values.description_font_color
                      : ""
                  }
                  onChange={(e) =>
                    formik.setFieldValue("description_font_color", e)
                  }
                />
                <small className="text-danger col-md-12">
                  {formik.touched.description_font_color &&
                  formik.errors.description_font_color
                    ? formik.errors.description_font_color
                    : ""}
                </small>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex align-items-end justify-content-center col-md-12 p-5">
          <CustomButton
            type="button"
            className="btn btn-primary btn-outline-success float-right btn-add-domain"
            disabled={
              Object.keys(formik.errors).length !== 0 ||
              !formik.isValid ||
              formik.isSubmitting
            }
            onClick={
              formik.isValid && Object.keys(formik.values).length !== 0
                ? formik.handleSubmit
                : handleError
            }
          >
            Create
          </CustomButton>
        </div>
      </div>
    </div>
  );
}

const validation = Yup.object().shape({
  //   description: Yup.string(),
  //   mobile_description: Yup.string(),
  banner_code: Yup.string().required("Enter a code"),
  banner_name: Yup.string().required("Enter a name"),
  sort_order: Yup.string().required("Enter a sort order"),
  //   description_font_color:Yup.string().required("Select a color"),
  //   description_font_size:Yup.string().required("Enter a font size"),
  //   title_font_color:Yup.string().required("Select a color"),
  //   title_font_size:Yup.string().required("Enter a font size"),
});
