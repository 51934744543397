import React, { useState, useEffect } from "react";
import PaginationComponent from "../../../components/PaginationComponent";
import useInterventionHistory from "../../../utils/CustomHooks/useInterventionHistory";
import QuestionTableItem from "../components/QuestionTableItem";

export default function InterventionHistoryTable({ id = "" }) {
  const { interventionHistory, loading, getIntervetionHistory } =
    useInterventionHistory("");
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const pageCount = Math.ceil(interventionHistory.length / pageSize);

  useEffect(() => {
    if (id) getIntervetionHistory(id);
  }, [id]);

  const handleClick = (event, index) => {
    event.preventDefault();
    setCurrentPage(index);
  };

  return (
    <>
      <table className="table table-borderless ">
        <thead>
          <tr className=" ">
            <td className="">Action</td>
            <td className="">Time </td>
            <td className="">By</td>
          </tr>
        </thead>
        <tbody>
          {interventionHistory.length > 0 ? (
            interventionHistory
              .slice()
              .sort(
                (a, b) =>
                  new Date(b.updated_at ? b.updated_at : b.created_at) -
                  new Date(a.updated_at ? a.updated_at : a.created_at)
              )
              .slice(currentPage * pageSize, (currentPage + 1) * pageSize)
              .map((x, i) => <QuestionTableItem data={x} key={i} />)
          ) : (
            <tr>
              <td className="text-center font-weight-bold" colSpan="7">
                {loading ? "loading..." : "No Hisory Found..."}
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {interventionHistory.length > 0 && (
        <PaginationComponent
          handleClick={handleClick}
          currentPage={currentPage}
          pageCount={pageCount}
        />
      )}
    </>
  );
}
