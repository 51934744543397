import { Modal, ModalBody } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import graphHandler from "../../../Apollo/graphHandler";
import { CREATE_DOMAIN_GQL } from "../../../Apollo/AdminServices/MutationRequest";
import Description from "../../../components/Input/Description";
import CustomButton from "../../../components/Button/CustomButton";
const validation = Yup.object().shape({
  domain_name: Yup.string().trim().required("Domain name is required"),
  alias_name: Yup.string(),
  // .min(4, "Domain name should be min of 4 characters")
  // .max(30, "Domain name should be maximum of 30 characters")
  description: Yup.string(),
  mobile_description: Yup.string(),
  sort_order: Yup.string().required("Enter sort order"),
});

export default function AddDomainModal({ isOpen, setOpen, refresh }) {
  const formik = useFormik({
    initialValues: {},
    initialStatus: false,
    validationSchema: validation,
    onSubmit: (values) => {
      onSubmit(values);
    },
  });
  const onClose = () => {
    formik.resetForm();
    setOpen();
  };
  const onSubmit = async (values) => {
    // console.log("Submit", values);
    const {
      alias_name,
      domain_name,
      description,
      mobile_description,
      sort_order,
    } = values;
    const create_request = {
      alias_name,
      domain_name,
      sort_order: Number(sort_order),
    };
    let domain_description = [];
    if (description) {
      domain_description.push({ client_type: "Web", description });
    }
    if (mobile_description) {
      domain_description.push({
        client_type: "Mobile",
        description: mobile_description,
      });
    }
    if (domain_description.length > 0)
      create_request.domain_description = domain_description;
    // console.log("create_request",create_request)
    try {
      const { data } = await graphHandler().invoke(
        CREATE_DOMAIN_GQL,
        { create_request },
        1,
        false
      );
      toast.success("Added Successfully!");
      refresh();
      onClose();
      formik.setSubmitting(false);
    } catch (error) {
      console.log("createDomain error", error);
      formik.setSubmitting(false);
    }
  };
  const handleError = () => {
    if (Object.keys(formik.errors).length > 0) {
      toast.error(formik.errors[Object.keys(formik.errors)[0]]);
    }
  };
  const removeRules = (i) => {
    const value = [...formik.values.allowed_roles];
    formik.setFieldValue("allowed_roles", value.splice(0, i));
  };
  return (
    <Modal toggle={onClose} isOpen={isOpen} centered scrollable size="xl">
      <div className="modal-header">
        <button type="button" className="close" onClick={onClose}>
          <span aria-hidden="true">&times;</span>
        </button>
        <h5 className="modal-title">Domain</h5>
      </div>
      <ModalBody>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12 form-group">
              <label
                htmlFor="domain_name"
                className="col-form-label w-100 mb-2"
              >
                Name
              </label>
              <input
                type="text"
                className="form-control"
                id="domain_name"
                name="domain_name"
                // maxLength="30"
                value={
                  formik.values && formik.values.domain_name
                    ? formik.values.domain_name
                    : ""
                }
                onChange={(e) =>
                  formik.setFieldValue(
                    "domain_name",
                    e.target.value.trimStart()
                  )
                }
                onBlur={(e) => {
                  formik.setFieldTouched("domain_name", true);
                  formik.setFieldValue(
                    "domain_name",
                    e.target.value.replace(/\s+/g, " ").trim()
                  );
                }}
              />
              <small className="text-danger">
                {formik.touched.domain_name &&
                formik.errors &&
                formik.errors.domain_name
                  ? formik.errors.domain_name
                  : ""}
              </small>
            </div>
            <div className="col-md-12 form-group">
              <label htmlFor="alias_name" className="col-form-label w-100 mb-2">
                Alias Name
              </label>
              <input
                type="text"
                className="form-control"
                id="alias_name"
                name="alias_name"
                // maxLength="30"
                value={
                  formik.values && formik.values.alias_name
                    ? formik.values.alias_name
                    : ""
                }
                // onChange={formik.handleChange}
                // onBlur={formik.handleBlur}
                onChange={(e) =>
                  formik.setFieldValue("alias_name", e.target.value.trimStart())
                }
                onBlur={(e) => {
                  formik.setFieldTouched("alias_name", true);
                  formik.setFieldValue(
                    "alias_name",
                    e.target.value.replace(/\s+/g, " ").trim()
                  );
                }}
              />
              <small className="text-danger">
                {formik.touched.alias_name &&
                formik.errors &&
                formik.errors.alias_name
                  ? formik.errors.alias_name
                  : ""}
              </small>
            </div>
            <div className="col-md-12 form-group">
              <label htmlFor="sort_order" className="col-form-label w-100 mb-2">
                Sort Order
              </label>
              <div className="d-flex flex-column p-0">
                <input
                  type="number"
                  className="form-control h-100 w-100"
                  //   placeholder={questionaireList.length + 1}
                  placeholder=""
                  id="sort_order"
                  name="sort_order"
                  max={100}
                  value={
                    formik.values && formik.values.sort_order
                      ? formik.values.sort_order
                      : ""
                  }
                  onBlur={formik.handleBlur}
                  onChange={(e) =>
                    formik.setFieldValue(
                      "sort_order",
                      e.target.value.replace(/[^0-9\.]/g, "")
                    )
                  }
                />
                <small className="text-danger">
                  {formik.touched.sort_order && formik.errors.sort_order
                    ? formik.errors.sort_order
                    : ""}
                </small>
              </div>
            </div>
            <Description formik={formik} />
            <CustomButton
              type="button"
              className="btn btn-primary btn-outline-success float-right btn-add-domain"
              disabled={
                Object.keys(formik.errors).length !== 0 ||
                !formik.isValid ||
                formik.isSubmitting
              }
              onClick={
                formik.isValid && Object.keys(formik.values).length !== 0
                  ? formik.handleSubmit
                  : handleError
              }
            >
              Create
            </CustomButton>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
}
