import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import useExplainerList from "../../utils/CustomHooks/useExplainerList";
import PaginationComponent from "../../components/PaginationComponent";
import ExplainerListItem from "./components/ExplainerListItem";
import { rGetSubDomainList } from "../../redux/DataSets/action";
import ExplainerModal from "./modal/Explainer";
import withRoleBased from "../../utils/Functions/withRoleBased";
import { sortArray } from "../../utils/Functions/filter";

function Explainer({ access = {} }) {
  const { explainerList, loading, getExplainerList } = useExplainerList("");
  const [isOpen, setIsOpen] = useState(false);
  const [count, setCount] = useState(0);
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize] = useState(10);
  const pageCount = Math.ceil(explainerList.length / pageSize);
  const { canCreate } = access;
  const [search, setSearch] = useState("");
  const [isSearching, setIsSearch] = useState(false);
  useEffect(() => {
    dispatch(rGetSubDomainList());
  }, []);

  const handleClick = (event, index) => {
    event.preventDefault();
    setCurrentPage(index);
  };

  useEffect(() => {
    let req = {};
    if (search) req.explainer_name = search;
    getExplainerList(req);
  }, [count]);

  const onSearch = () => {
    setCurrentPage(0)
    setCount((x) => x + 1);
    setIsSearch(search ? true : false);
  };

  return (
    <main className="page-content">
      <div className="container-fluid">
        <div className="row m-0 w-100">
          <div className="col-md-6 mb-3">
            <h5 className="page-title"> Explainer Video </h5>
          </div>
          <div className="col-md-6  mb-3 d-flex justify-content-md-end">
            {canCreate && (
              <button
                type="button"
                className="btn btn-outline-success "
                onClick={() => setIsOpen(true)}
              >
                Create Explainer
              </button>
            )}
          </div>

          <div className="col-md-4">
            <div className="search" style={{ margin: "16px 0px 32px" }}>
              <input
                type="text"
                className="form-control"
                placeholder="Search"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                onKeyDown={(e) => e.key === "Enter" && onSearch()}
              />
              {!isSearching ? (
                <button onClick={onSearch}>
                  <i className="fa fa-search" />
                </button>
              ) : (
                <button
                  onClick={() => {
                    setSearch("");
                    setIsSearch(false);
                    setCount((x) => x + 1);
                  }}
                >
                  <i className="fa fa-times" />
                </button>
              )}
            </div>
          </div>
          <div className="col-md-12">
            <div className="table-responsive">
              <table className="table table-borderless ">
                <thead>
                  <tr>
                    <td>Name</td>
                    <td>Description </td>
                    <td>Status</td>
                    {access.canEdit ? <td>Action</td> : ""}
                  </tr>
                </thead>
                <tbody>
                  {!loading && explainerList && explainerList.length > 0 ? (
                    sortArray(explainerList)
                      .slice(
                        currentPage * pageSize,
                        (currentPage + 1) * pageSize
                      )
                      .map((x, i) => (
                        <ExplainerListItem
                          item={x}
                          key={i}
                          index={i}
                          access={access}
                          refresh={() => setCount((x) => x + 1)}
                        />
                      ))
                  ) : (
                    <tr>
                      <td colSpan="4" className="text-center">
                        <span className="font-weight-bold">
                          {loading ? "Loading..." : "Oops! No Data Found"}
                        </span>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {explainerList.length > 0 && pageCount != 1 && (
          <PaginationComponent
            handleClick={handleClick}
            currentPage={currentPage}
            pageCount={pageCount}
          />
        )}
        <ExplainerModal
          isOpen={isOpen}
          setOpen={() => setIsOpen(false)}
          refresh={() => setCount((x) => x + 1)}
        />
      </div>
    </main>
  );
}

export default withRoleBased(Explainer, "explainer");
