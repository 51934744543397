import { Popconfirm } from "antd";
import { secondsToDuration } from "../../../utils/Functions/filter";
// import usePlanAPI from "../../../utils/CustomHooks/usePlanAPI";
import graphHandler from "../../../Apollo/graphHandler";
import { UPDATE_PLAN_ACTION_GQL } from "../../../Apollo/AdminServices/NewMutationRequest";
import { useEffect } from "react";

export default function PlanActionListItem({
  canDelete = false,
  refresh,
  item = {},
  groupList = [],
  isEditable,
  onEdit,
  reset,
  setHistory = () => {},
}) {
  const { pending_history } = item;
  // const hasPublished =
  //   pending_history &&
  //   pending_history.publish_data &&
  //   pending_history.publish_data.created_by;
  // const { loading, removeAction } = usePlanAPI();
  useEffect(() => {
    if (isEditable && pending_history?.updated_data?.active === false) {
      setHistory(Boolean(pending_history && pending_history.updated_data));
    }
  }, [pending_history]);

  const onDelete = async () => {
    const { plan_action_id } = item;
    // if (loading) return;
    onEdit({});
    //   if (!canDelete) return toast.warn("Sorry plan was published");
    // removeAction({ plan_action_id: item.plan_action_id }, refresh);
    try {
      await graphHandler().invoke(
        UPDATE_PLAN_ACTION_GQL,
        {
          update_request: {
            active: false,
            plan_action_id,
          },
        },
        1,
        false
      );
      refresh();
    } catch (error) {
      console.log("Plan error", error);
    }
  };

  let planGroup = groupList.find((x) => x.value === item.plan_group_id);

  return (
    <tr
      className={`${
        pending_history?.updated_data?.active === false ? "bad" : ""
      } option-table`}
    >
      <td className="text-center"> {item.plan_action_name || "-"}</td>
      <td className="text-center">{planGroup?.label || "-"}</td>
      <td className="text-center">{item.plan_action_type || "-"}</td>
      <td className="text-center">{item.sort_order || "0"}</td>
      <td className="text-center">
        {item.start_interval_seconds
          ? secondsToDuration(item.start_interval_seconds)
          : "0"}
      </td>
      <td className="text-center">{item.min_completion_days || "0"}</td>
      <td className="text-center"> {item.max_completion_days || "0"}</td>
      <td className="d-flex align-items-center justify-content-center">
        {/* {hasPublished && (
          <span className="badge badge-error float-right">Changes</span>
        )} */}
        {isEditable && pending_history?.updated_data?.active !== false ? (
          <>
            <button
              type="button"
              className="ml-2 btn btn-outline-success btn-sm "
              onClick={async () => {
                await reset();
                onEdit(item);
              }}
            >
              Edit
            </button>
            <Popconfirm
              title="Are you sure to delete this action?"
              onConfirm={onDelete}
              okText="Yes"
              cancelText="No"
            >
              <i
                className="ml-2 fas fa-trash cursor-pointer"
                // onClick={onDelete}
              ></i>
            </Popconfirm>
          </>
        ) : (
          "-"
        )}
      </td>
    </tr>
  );
}
