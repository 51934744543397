import { useEffect, useState } from "react";
import { GET_THERAPY_LIST_GQL } from "../../Apollo/AdminServices/QueryRequest";
import graphHandler from "../../Apollo/graphHandler";
import { sortArray } from "../Functions/filter";

function useTherapyList(id) {
  const [therapyList, setTherapyList] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (id) {
      getTherapyList(id);
    } else {
      setTherapyList([]);
    }
  }, [id]);

  const getTherapyList = async (req = {}, get_pending_update = true) => {
    setLoading(true);
    try {
      const { data } = await graphHandler().invoke(
        GET_THERAPY_LIST_GQL,
        { get_pending_update, ...req },
        1,
        false,
        true
      );
      if (
        data &&
        data.getTherapyList &&
        data.getTherapyList.therapies &&
        data.getTherapyList.therapies.length > 0
      ) {
        // console.log("getTherapyList Success", data.getTherapyList);
        const struct = data.getTherapyList.therapies.map((x) => ({
          ...x.therapy,
          label: x.therapy.therapy_name,
          value: x.therapy.therapy_id,
          pending_therapy_history: x.pending_therapy_history,
        }));
        setTherapyList([]);
        setTherapyList(sortArray(struct));
        setLoading(false);
      } else {
        setTherapyList([]);
        setLoading(false);
      }
    } catch (error) {
      console.log("getTherapyList Error", error);
      setTherapyList([]);
      setLoading(false);
    }
  };

  return {
    therapyList,
    loading,
    getTherapyList,
  };
}

export default useTherapyList;
