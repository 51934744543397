import { useEffect, useState } from "react";
import { Modal, ModalBody } from "reactstrap";
import MdEditor from "react-markdown-editor-lite";
import MarkdownIt from "markdown-it";
const mdParser = new MarkdownIt(/* Markdown-it options */);
import "react-markdown-editor-lite/lib/index.css";
import "../FormikInput/mdEditor.css";

export default function EditorModal({
  isOpen,
  close = () => {},
  save = () => {},
  title = "Editor",
  defaultValue = "",
}) {
  const [value, setValue] = useState("");
  const onCancel = () => {
    close();
    setValue(defaultValue);
  };

  const onSave = () => {
    close();
    save(value);
  };

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue, isOpen]);

  return (
    <Modal
      toggle={onCancel}
      isOpen={isOpen}
      keyboard={false}
      modalClassName="modal-fullscreen modal-dialog-scrollable"
    >
      <div className="modal-header pb-0">
        <button type="button" className="close" onClick={onCancel}>
          <span aria-hidden="true">&times;</span>
        </button>
        <h5 className="modal-title">{title}</h5>
      </div>
      <ModalBody>
        <MdEditor
          style={{ height: "50vh" }}
          renderHTML={(text) => mdParser.render(text)}
          value={value ? value : ""}
          onChange={({ text }) => setValue(text)}
        />
        <div className="d-flex align-items-end justify-content-end col-md-12 mt-3">
          <button
            type="button"
            className="btn btn-primary btn-outline-success float-right btn-add-domain"
            disabled={!value}
            onClick={onSave}
          >
            Save
          </button>
        </div>
      </ModalBody>
    </Modal>
  );
}
