import { useEffect, useState } from "react";
import { Modal, ModalBody } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { DataOptionType } from "../../../constants";
import { useSelector } from "react-redux";
import { CREATE_SCREENER_QUESTIONAIRE_GQL } from "../../../Apollo/AdminServices/MutationRequest";
import graphHandler from "../../../Apollo/graphHandler";
import { formatBytes, GenerateID } from "../../../utils/Functions/filter";
import { isImage } from "../../../constants";
import { S3_BUCKET, REGION, myBucket } from "../../../constants/uploadCreds";

export default function CreateQuestionaireModal({
  isOpen,
  setOpen,
  refresh,
  screener_id,
}) {
  const defaultChoices = useSelector(({ DataSet }) => DataSet.defaultChoices);
  const subDomains = useSelector(({ DataSet }) => DataSet.subDomains);
  const [type, setType] = useState(false);
  const formik = useFormik({
    initialValues: {
      options: [{ ...initialValues }],
      option_type: "Text",
      choice_type: "Custom",
      screener_id,
      ignore_for_scoring: false,
    },
    validationSchema: type ? validationOptions : validation,
    onSubmit: (values) => {
      onSubmit(values);
    },
  });

  const canShowOptions =
    formik.values.screener_question_type &&
    formik.values.screener_question_type != "TextField" &&
    formik.values.screener_question_type != "DocumentUpload" &&
    formik.values.screener_question_type != "ImageCapture" &&
    formik.values.screener_question_type != "Date" &&
    formik.values.screener_question_type != "Rating" &&
    formik.values.screener_question_type != "TextArea";

  const canShowOptionType =
    formik.values.screener_question_type &&
    formik.values.screener_question_type == "RadioButton";

  const onClose = () => {
    formik.resetForm();
    formik.setValues({
      options: [{ ...initialValues }],
      option_type: "Text",
      choice_type: "Custom",
      screener_id,
      ignore_for_scoring: false,
    });
    setOpen();
  };

  useEffect(() => {
    formik.values.screener_question_type && formik.validateForm();
  }, [type]);

  useEffect(() => {
    setType(canShowOptions);
  }, [formik.values.screener_question_type]);

  const handleError = () => {
    if (Object.keys(formik.errors).length > 0) {
      toast.error(formik.errors[Object.keys(formik.errors)[0]]);
    }
  };

  const onSubmit = async (value) => {
    const {
      screener_question_title,
      mandatory,
      sort_order,
      screener_question_type,
      //   option_type,
      options,
      sub_domain_id,
      result_weightage,
      ignore_for_scoring,
    } = value;
    // if (canShowOptions )
    //   return toast.error("Please select a option type");
    if (canShowOptions && options.filter((x) => x.data && x.value).length <= 1)
      return toast.error("Please create multiple options");
    const create_request = {
      screener_question_title,
      screener_question_type,
      mandatory: mandatory === "true",
      sort_order: Number(sort_order),
      option_type: "Text",
      sub_domain_id,
      result_weightage: Number(result_weightage),
      screener_id,
      ignore_for_scoring: ignore_for_scoring === "true",
    };
    if (canShowOptions) {
      create_request.options = {
        option_type: "Text",
        options: options
          .filter((x) => x.data && x.value)
          .map(({ data, value }) => ({ data, value: Number(value) })),
      };
    } else {
      delete create_request.options;
      delete create_request.option_type;
    }
    // return console.log({ create_request });
    try {
      const { data } = await graphHandler().invoke(
        CREATE_SCREENER_QUESTIONAIRE_GQL,
        { create_request },
        1,
        true
      );
      refresh();
      onClose();
    } catch (error) {
      console.log("createScreenerQuestion error", error);
    }
  };

  const handleOptionChange = (value, i, name = "data") => {
    const options = [...formik.values.options.map((x) => x)];
    options[i][name] = value;
    formik.setFieldValue("options", options);
  };

  const addOption = (i) => {
    const options = [...formik.values.options.map((x) => x)];
    if (!options[i].data || !options[i].value) return;
    formik.setFieldValue("options", [...options, { ...initialValues }]);
  };

  const removeOption = (i, image = false) => {
    if (!image && formik.values.options.length == 1) return;
    const options = [...formik.values.options.map((x) => x)];
    options.splice(i, 1);
    formik.setFieldValue("options", options);
  };

  const handleFileChange = async (event, i) => {
    const options = [...formik.values.options.map((x) => x)];
    if (!event.target.files[0]) return;
    options[i] = { isUpload: true };
    options[i].size = formatBytes(event.target.files[0].size);
    options[i].name = event.target.files[0].name;
    formik.setFieldValue("options", options);
    const file = new File(
      [event.target.files[0]],
      event.target.files[0].name.replace(/\s/g, ""),
      {
        type: event.target.files[0].type,
      }
    );
    if (isImage(file)) {
      uploadToCloud(file, i, options);
    } else {
      toast.error("Please select a valid Image");
      options.splice(i, 1);
      formik.setFieldValue("options", options);
    }
  };

  function uploadToCloud(file, i, obj) {
    const options = [...obj.map((x) => x)];
    const params = {
      ACL: "public-read",
      Body: file,
      Bucket: S3_BUCKET + "/Image",
      Key: GenerateID() + file.name,
    };
    myBucket
      .putObject(params)
      .on("httpUploadProgress", (event) => {
        options[i].size = `${formatBytes(event.loaded)}/${formatBytes(
          event.total
        )}`;
        options[i].progress = parseInt(
          Math.round((100 * event.loaded) / event.total)
        );
        formik.setFieldValue("options", options);
      })
      .promise()
      .then((res) => {
        options[i].name = file.name;
        options[
          i
        ].data = `http://s3.${REGION}.amazonaws.com/${params.Bucket}/${params.Key}`;
        options[i].isUpload = false;
        // console.log("Uploading then", options);
        formik.setFieldValue("options", options);
      })
      .catch((err) => {
        console.log("Upload error", err);
        toast.error(err.toString());
        options.splice(i, 1);
        formik.setFieldValue("options", options);
      });
  }

  const onChoiceChange = (e) => {
    const { name, value } = e.target;
    if (value === "Custom")
      formik.setFieldValue("options", [{ ...initialValues }]);
    else {
      let defaultData = defaultChoices.find(
        (x) => x.default_option_name === value
      );
      let options = [...defaultData.options.options.map((x) => x)];
      formik.setFieldValue("options", options);
      formik.setFieldValue(
        "screener_question_type",
        defaultData.screener_question_type
      );
    }
    formik.setFieldValue(name, value);
  };

  return (
    <Modal
      toggle={onClose}
      isOpen={isOpen}
      modalClassName="modal-fullscreen modal-dialog-scrollable"
    >
      <div className="modal-header">
        <button type="button" className="close" onClick={onClose}>
          <span aria-hidden="true">&times;</span>
        </button>
        <h5 className="modal-title">Create questionnaire</h5>
      </div>
      <ModalBody>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12">
              <div className="questionnaire-form row p-0">
                <div className="col-md-6 form-group">
                  <label
                    htmlFor="screener_question_title"
                    className="col-form-label w-100 mb-2"
                  >
                    Question
                  </label>
                  <textarea
                    className="form-control"
                    id="screener_question_title"
                    name="screener_question_title"
                    // maxLength="150"
                    value={
                      formik.values && formik.values.screener_question_title
                        ? formik.values.screener_question_title
                        : ""
                    }
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  <small className="text-danger align-self-end">
                    {formik.touched.screener_question_title &&
                    formik.errors &&
                    formik.errors.screener_question_title
                      ? formik.errors.screener_question_title
                      : ""}
                  </small>
                </div>
                <div className="col-md-6">
                  <div className="row">
                    <div className="col-md-12 form-group ">
                      <label
                        htmlFor="sub_domain_id"
                        className="col-form-label w-100 mb-2"
                      >
                        Sub Domain
                      </label>
                      <select
                        id="sub_domain_id"
                        name="sub_domain_id"
                        className="custom-select"
                        onChange={formik.handleChange}
                        value={
                          formik.values.sub_domain_id
                            ? formik.values.sub_domain_id
                            : ""
                        }
                        onBlur={formik.handleBlur}
                      >
                        <option key="Select" hidden>
                          Select
                        </option>
                        {subDomains.map((x, i) => (
                          <option key={i} value={x.sub_domain_id}>
                            {x.sub_domain_name}
                          </option>
                        ))}
                      </select>
                      <small className="text-danger align-self-end">
                        {formik.touched.sub_domain_id &&
                        formik.errors &&
                        formik.errors.sub_domain_id
                          ? formik.errors.sub_domain_id
                          : ""}
                      </small>
                    </div>

                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-6 form-group">
                          <label
                            htmlFor="sort_order"
                            className="col-form-label w-100 mb-2"
                          >
                            Sort Order
                          </label>
                          <div className="d-flex flex-column p-0">
                            <input
                              type="number"
                              className="form-control h-100 w-100"
                              //   placeholder={questionaireList.length + 1}
                              placeholder=""
                              id="sort_order"
                              name="sort_order"
                              max={100}
                              value={
                                formik.values && formik.values.sort_order
                                  ? formik.values.sort_order
                                  : ""
                              }
                              onBlur={formik.handleBlur}
                              onChange={(e) =>
                                formik.setFieldValue(
                                  "sort_order",
                                  e.target.value.replace(/[^0-9\.]/g, "")
                                )
                              }
                            />
                            <small className="text-danger align-self-end">
                              {formik.touched.sort_order &&
                              formik.errors &&
                              formik.errors.sort_order
                                ? formik.errors.sort_order
                                : ""}
                            </small>
                          </div>
                        </div>
                        <div className="col-md-6 form-group">
                          <label
                            htmlFor="result_weightage"
                            className="col-form-label w-100 mb-2"
                          >
                            Result Weightage
                          </label>
                          <div className="d-flex flex-column p-0">
                            <input
                              type="number"
                              className="form-control h-100 w-100"
                              //   placeholder={questionaireList.length + 1}
                              placeholder=""
                              id="result_weightage"
                              name="result_weightage"
                              max={100}
                              value={
                                formik.values && formik.values.result_weightage
                                  ? formik.values.result_weightage
                                  : ""
                              }
                              onBlur={formik.handleBlur}
                              onChange={(e) =>
                                formik.setFieldValue(
                                  "result_weightage",
                                  e.target.value.replace(/[^0-9\.]/g, "")
                                )
                              }
                            />
                            <small className="text-danger align-self-end">
                              {formik.touched.result_weightage &&
                              formik.errors &&
                              formik.errors.result_weightage
                                ? formik.errors.result_weightage
                                : ""}
                            </small>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="row">
                    <div className="col-md-6 form-group">
                      <label
                        htmlFor="screener_question_type"
                        className="col-form-label w-100 mb-2"
                      >
                        Data Type
                      </label>
                      <select
                        className="form-control"
                        id="screener_question_type"
                        name="screener_question_type"
                        value={
                          formik.values && formik.values.screener_question_type
                            ? formik.values.screener_question_type
                            : ""
                        }
                        onChange={formik.handleChange}
                      >
                        <option key="Select" hidden>
                          Select Type
                        </option>
                        {DataOptionType.map((x, i) => (
                          <option value={x} key={i}>
                            {x}
                          </option>
                        ))}
                      </select>
                      <small className="text-danger align-self-end">
                        {formik.touched.screener_question_type &&
                        formik.errors &&
                        formik.errors.screener_question_type
                          ? formik.errors.screener_question_type
                          : ""}
                      </small>
                    </div>
                    <div className="col-md-6 form-group">
                      <label
                        htmlFor="mandatory"
                        className="col-form-label w-100 mb-2"
                      >
                        Mandatory
                      </label>
                      <select
                        className="form-control"
                        id="mandatory"
                        name="mandatory"
                        value={
                          formik.values && formik.values.mandatory
                            ? formik.values.mandatory
                            : ""
                        }
                        onChange={formik.handleChange}
                      >
                        <option key="Select" hidden>
                          Select Type
                        </option>
                        {[
                          { name: "Yes", value: true },
                          { name: "No", value: false },
                        ].map((x, i) => (
                          <option value={x.value} key={i}>
                            {x.name}
                          </option>
                        ))}
                      </select>
                      <small className="text-danger align-self-end">
                        {formik.touched.mandatory &&
                        formik.errors &&
                        formik.errors.mandatory
                          ? formik.errors.mandatory
                          : ""}
                      </small>
                    </div>

                    <div className="col-md-6 form-group">
                      <div className="d-flex flex-row">
                        <input
                          className="mr-2"
                          type="checkbox"
                          id="ignore_for_scoring"
                          name="ignore_for_scoring"
                          onChange={(e) =>
                            formik.setFieldValue(
                              "ignore_for_scoring",
                              e.target.checked
                            )
                          }
                          checked={formik.values.ignore_for_scoring}
                          onBlur={formik.handleBlur}
                        />
                        <label
                          htmlFor="ignore_for_scoring"
                          className="col-form-label w-100 mb-2"
                        >
                          Ignore Scoring
                        </label>
                      </div>
                    </div>
                  </div>
                  {/*<div className="row">
             {canShowOptionType && (
              <div className="col-md-6 form-group">
                <label
                  htmlFor="question-text"
                  className="col-form-label w-100 mb-2"
                >
                  Option Type
                </label>
                <select
                  className="form-control"
                  id="choice_type"
                  name="choice_type"
                  value={
                    formik.values && formik.values.choice_type
                      ? formik.values.choice_type
                      : ""
                  }
                  onChange={onChoiceChange}
                >
                  <option key="Custom" value="Custom">
                    Custom
                  </option>
                  {defaultChoices.map((x, i) => (
                    <option value={x.default_option_name} key={i}>
                      {x.default_option_name}
                    </option>
                  ))}
                </select>
                <small className="text-danger align-self-end">
                  {formik.touched.choice_type &&
                  formik.errors &&
                  formik.errors.choice_type
                    ? formik.errors.choice_type
                    : ""}
                </small>
              </div>
            )}  {canShowOptions && (
                      <div className="col-md-6 form-group">
                        <label
                          htmlFor="question-text"
                          className="col-form-label w-100 mb-2"
                        >
                          Option Type
                        </label>
                        <div className="d-flex flex-row pl-3 pr-0 row d-flex">
                          {QuestionOptionType.map((x, i) => (
                            <div
                              className="form-check form-check-inline mb-2"
                              key={i}
                            >
                              <input
                                className="form-check-input"
                                type="radio"
                                value={x}
                                name="option_type"
                                id={x}
                                onChange={(e) => {
                                  formik.setFieldValue(
                                    "option_type",
                                    e.target.value
                                  );
                                  formik.setFieldValue(
                                    "options",
                                    e.target.value === "Image"
                                      ? []
                                      : [{ ...initialValues }]
                                  );
                                }}
                                checked={x === formik.values.option_type}
                                onBlur={formik.handleBlur}
                              />
                              <label className="form-check-label" htmlFor={x}>
                                {x}
                              </label>
                            </div>
                          ))}
                        </div>
                        <small className="text-danger align-self-end">
                          {formik.touched.option_type &&
                          formik.errors &&
                          formik.errors.option_type
                            ? formik.errors.option_type
                            : ""}
                        </small>
                      </div>
                    )} 
          </div>*/}
                </div>
                {canShowOptions ? (
                  <div className="col-md-6 form-group">
                    <label
                      htmlFor="questionnaire-option"
                      className="col-form-label w-100 mb-2"
                    >
                      Options
                    </label>
                    {/* {formik.values.option_type == "Image" ? ( */}

                    {formik.values.options.map((x, i) => (
                      <div key={i} className="col-md-12 px-0 mb-3">
                        <div className="d-flex align-items-center row">
                          <div className="col-md-6">
                            <input
                              key={i}
                              type="text"
                              className="form-control"
                              placeholder="Option"
                              onChange={(e) =>
                                handleOptionChange(e.target.value, i, "data")
                              }
                              disabled={
                                formik.values &&
                                formik.values.choice_type != "Custom"
                              }
                              onBlur={formik.handleBlur}
                              value={x.data}
                            />
                          </div>
                          <div className="col-md-4">
                            <input
                              key={i}
                              type="text"
                              className="form-control"
                              placeholder="Value"
                              onChange={(e) =>
                                handleOptionChange(
                                  e.target.value.replace(/[^0-9\.]/g, ""),
                                  i,
                                  "value"
                                )
                              }
                              onBlur={formik.handleBlur}
                              value={x.value}
                            />
                          </div>
                          {formik.values &&
                            formik.values.choice_type === "Custom" && (
                              <>
                                {i === formik.values.options.length - 1 && (
                                  <i
                                    className="fas fa-plus ml-2"
                                    onClick={() => addOption(i)}
                                    style={{ color: "#5e6c84" }}
                                  />
                                )}
                                {formik.values.options.length != 1 && (
                                  <i
                                    className="fa fa-minus text-danger ml-2"
                                    onClick={() => removeOption(i)}
                                    style={{ color: "#5e6c84" }}
                                  />
                                )}
                              </>
                            )}
                        </div>
                      </div>
                    ))}
                    {/* ) : (
                      <div className="upload d-flex flex-column col-md-12 p-0">
                        <div className="upload__drop">
                          <input
                            className="upload__input"
                            type="file"
                            disabled={
                              formik.values.options[
                                formik.values.options.length
                              ]
                                ? formik.values.options[
                                    formik.values.options.length
                                  ].isUpload
                                : false
                            }
                            onChange={(e) =>
                              handleFileChange(e, formik.values.options.length)
                            }
                          />
                          <a className="upload__btn">Select Files</a>
                        </div>
                        {formik.values.options.map((x, i) => (
                          <div className="upload__list mb-2" key={i}>
                            <div className="upload__info flex">
                              <img src={x.data} className="upload__img" />
                              <div className="flex w-100 wrap">
                                <p className="upload__name">
                                  {x.name || "Uploaded" + (i + 1)}{" "}
                                </p>
                                {x.size != 0 && (
                                  <p className="upload__size">( {x.size} )</p>
                                )}
                                {x.progress != 0 && (
                                  <p className="upload__long"> {x.progress}%</p>
                                )}
                                {x.progress == 100 && (
                                  <p className="upload__long text-success">
                                    Done
                                  </p>
                                )}
                                <a
                                  className="upload__delete upload__btn"
                                  onClick={() => removeOption(i, true)}
                                >
                                  <i className="fas fa-minus-circle" />
                                </a>
                                {x.progress != 0 && x.progress != 100 && (
                                  <div className="upload__progress">
                                    <div
                                      className="upload__bar"
                                      style={{
                                        width: x.progress + "%",
                                      }}
                                    />
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    )} */}
                  </div>
                ) : null}

                <div
                  className={`d-flex align-items-end justify-content-end col-md-12`}
                >
                  <button
                    type="button"
                    className="btn btn-primary btn-outline-success btn-sm float-right btn-add-domain"
                    disabled={Object.keys(formik.errors).length !== 0}
                    onClick={
                      formik.isValid && Object.keys(formik.values).length !== 0
                        ? formik.handleSubmit
                        : handleError
                    }
                  >
                    Create
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
}

const validation = Yup.object().shape({
  sub_domain_id: Yup.string().required("Select a Sub Domain"),
  ignore_for_scoring: Yup.string(),
  result_weightage: Yup.string().trim().required("Enter result weightage"),
  //   screener_topic_id: Yup.object().required("Select a topic"),
  screener_question_title: Yup.string().trim().required("Enter a Question"),
  // .min(4, "Question should be min of 4 characters")
  // .max(150, "Question should be maximum of 150 characters")
  mandatory: Yup.boolean().required("Select a Mandatory"),
  sort_order: Yup.string().required("Enter sort order"),
  screener_question_type: Yup.string().required("Select Question Type"),
});

const validationOptions = Yup.object().shape({
  sub_domain_id: Yup.string().required("Select a Sub Domain"),
  ignore_for_scoring: Yup.string(),
  result_weightage: Yup.string().trim().required("Enter result weightage"),
  //   screener_topic_id: Yup.object().required("Select a topic"),
  screener_question_title: Yup.string().trim().required("Enter a Question"),
  // .min(4, "Question should be min of 4 characters")
  // .max(150, "Question should be maximum of 150 characters")
  mandatory: Yup.boolean().required("Select a Mandatory"),
  sort_order: Yup.string().required("Enter sort order"),
  screener_question_type: Yup.string().required("Select Question Type"),
  //   option_type: Yup.string().required("Select Option Type"),
  options: Yup.array()
    .of(
      Yup.object().shape({
        data: Yup.string(),
        value: Yup.string(),
        flag: Yup.string(),
      })
    )
    .test({
      message: "Add at least a multiple option",
      test: (arr) => {
        return arr.length > 0;
      },
    }),
  // .compact((v) => !v.checked)
});

const initialValues = {
  data: "",
  value: "",
};
