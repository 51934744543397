import MarkdownIt from "markdown-it";
const mdParser = new MarkdownIt(/* Markdown-it options */);

export default function Paragraph({
  value = {},
  isPreview = false,
  onDelete = () => {},
  onEdit = () => {},
}) {
  const parseContent = (e, i) => {
    return (
      <div
        key={i}
        className="blog-own-description custom-html-style"
        dangerouslySetInnerHTML={{
          __html: mdParser.render(e),
        }}
      ></div>
    );
    // return parse(mdParser.render(e));
  };
  return (
    <section className="container mt-5">
      <div className="row">
        <div className="col-12">
          {/* <p className="blog-own-description text-align-justify"> */}
          {value.content && value.content.map((x, i) => parseContent(x, i))}
          {/* </p> */}
        </div>
      </div>
      <div className="tool-tip">
        <button onClick={() => onEdit(value)}>
          <i className="fas fa-edit" />
        </button>
        <button onClick={() => onDelete(value)}>
          <i className="fas fa-trash" />
        </button>
      </div>
    </section>
  );
}
