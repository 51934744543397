import { useEffect, useState } from "react";
import graphHandler from "../../Apollo/graphHandler";
import { GET_USER_ACTIVITY_CONTENT_LIST_GQL } from "../../Apollo/ProfileService/profileQueryRequest";

function UseUserContentHistory(id) {
  const [userContentHistory, setUserContentHistory] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (id) {
      getUserContentHistory(id);
    } else {
      setUserContentHistory([]);
    }
  }, [id]);

  const getUserContentHistory = async (user_id) => {
    setLoading(true);
    try {
      const { data } = await graphHandler().invoke(
        GET_USER_ACTIVITY_CONTENT_LIST_GQL,
        { user_id },
        0,
        false,
        true
      );
      if (
        data &&
        data.getUserContentActivity &&
        data.getUserContentActivity.content_history_list
      ) {
        // console.log(
        //   "getUserContentActivity Success",
        //   data.getUserContentActivity.content_history_list
        // );
        let history = [
          ...data.getUserContentActivity.content_history_list.map((x) => x),
        ];
        setUserContentHistory(history);
        setLoading(false);
      } else {
        setUserContentHistory([]);
        setLoading(false);
      }
    } catch (error) {
      console.log("getUserContentActivity Error", error);
      setUserContentHistory([]);
      setLoading(false);
    }
  };
  return {
    userContentHistory,
    loading,
    getUserContentHistory,
    clear: () => setUserContentHistory([]),
  };
}

export default UseUserContentHistory;
