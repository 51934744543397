import React, { useEffect } from "react";
import useSubDomainList from "../../../utils/CustomHooks/useSubDomainList";
import { getDescription } from "../../../utils/Functions/filter";

export default function EditScreenerLeft({ editable, screener, formik }) {
  const { subDomains, loading, getSubDomains } = useSubDomainList("");
  useEffect(() => {
    if (screener.sub_domain_ids && screener.sub_domain_ids.length) {
      getSubDomains({ sub_domain_ids: screener.sub_domain_ids });
    }
  }, [screener]);

  return (
    <div className="col-md-6 mb-3">
      {!editable ? (
        <p className="float-start mb-0">
          <strong>
            {" "}
            {screener.screener_name ? screener.screener_name : ""}{" "}
          </strong>{" "}
        </p>
      ) : (
        <>
          <input
            type="text"
            name="screener_name"
            id="screener_name"
            onChange={formik.handleChange}
            placeholder="Screener Name"
            value={
              formik.values.hasOwnProperty("screener_name")
                ? formik.values.screener_name
                : screener.screener_name
            }
          />
        </>
      )}
      {!editable ? (
        <p className="float-start mb-0">
          {screener.screener_description &&
          getDescription(screener.screener_description, "Web")
            ? getDescription(screener.screener_description, "Web").description
            : ""}
        </p>
      ) : (
        <textarea
          type="text"
          className="form-control"
          id="screener_description"
          name="screener_description"
          onChange={formik.handleChange}
          value={
            formik.values.hasOwnProperty("screener_description")
              ? formik.values.screener_description
              : getDescription(screener.screener_description, "Web")
              ? getDescription(screener.screener_description, "Web").description
              : ""
          }
          placeholder="Screener Description"
          //   maxLength="300"
        />
      )}
      {!editable ? (
        <p className="float-start mb-0">
          {screener.screener_description &&
          getDescription(screener.screener_description, "Mobile")
            ? getDescription(screener.screener_description, "Mobile")
                .description
            : ""}
        </p>
      ) : (
        <textarea
          type="text"
          className="form-control"
          id="mobile_description"
          name="mobile_description"
          onChange={formik.handleChange}
          value={
            formik.values.hasOwnProperty("mobile_description")
              ? formik.values.mobile_description
              : getDescription(screener.screener_description, "Mobile")
              ? getDescription(screener.screener_description, "Mobile")
                  .description
              : ""
          }
          placeholder="Mobile Description"
          //   maxLength="300"
        />
      )}
      {screener.screener_type != "MultiDimensionalScreener" && (
        <label className="float-start mb-0">
          <strong>Sub Domain : </strong>
          {subDomains.map(
            (x, i) =>
              x.sub_domain_name + (i + 1 != subDomains.length ? ", " : "")
          )}
          {screener.screener_type ? screener.screener_type : ""}
        </label>
      )}
      <label className="float-start mb-0">
        <b>Status: </b>Approved
      </label>
    </div>
  );
}
