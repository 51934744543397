import { useEffect } from "react";
import { toast } from "react-toastify";
import { Popconfirm } from "antd";
import { secondsToDuration } from "../../../utils/Functions/filter";
// import usePlanAPI from "../../../utils/CustomHooks/usePlanAPI";
import usePlanActionList from "../../../utils/CustomHooks/usePlanActionList";
import graphHandler from "../../../Apollo/graphHandler";
import { UPDATE_PLAN_GROUP_GQL } from "../../../Apollo/AdminServices/NewMutationRequest";

export default function PlanGroupListItem({
  canDelete = false,
  refresh,
  item = {},
  isEditable,
  onEdit,
  reset,
  setHistory = () => {},
}) {
  const { pending_history } = item;
  // const hasPublished =
  //   pending_history &&
  //   pending_history.publish_data &&
  //   pending_history.publish_data.created_by;
  const { actionList, loading, getActionList } = usePlanActionList("");
  // const { loading: loading1, removeGroup } = usePlanAPI();
  useEffect(() => {
    if (item.plan_group_id) {
      getActionList({ plan_group_id: item.plan_group_id ,get_pending_update:true,active:true});
    }
  }, [item.plan_group_id]);

  useEffect(() => {
    if (isEditable && pending_history?.updated_data?.active === false) {
      setHistory(Boolean(pending_history && pending_history.updated_data));
    }
  }, [pending_history]);

  const onDelete = async () => {
    const { plan_group_id } = item;
    if (loading) return;
    let data = await actionList.filter((x)=> x.pending_history?.updated_data?.active !== false)
     // console.log("data",data)
     if (data&&data.length > 0){
      return toast.error("Please delete all the actions inside this group");
    }
    onEdit({});
    // removeGroup({ plan_group_id: item.plan_group_id }, refresh);
    try {
      await graphHandler().invoke(
        UPDATE_PLAN_GROUP_GQL,
        {
          update_request: {
            active: false,
            plan_group_id,
          },
        },
        1,
        false
      );
      refresh();
    } catch (error) {
      console.log("Plan error", error);
    }
  };

  return (
    <tr
      className={`${
        pending_history?.updated_data?.active === false ? "bad" : ""
      } option-table`}
    >
      <td className="text-center"> {item.plan_group_name || "-"}</td>
      <td className="text-center">{item.sort_order || "0"}</td>
      <td className="text-center">{item.min_completion_days || "0"}</td>
      <td className="text-center"> {item.max_completion_days || "0"}</td>
      <td className="text-center">
        {item.start_interval_seconds
          ? secondsToDuration(item.start_interval_seconds)
          : "0"}
      </td>
      <td className="d-flex align-items-center justify-content-center">
        {/* {hasPublished && (
          <span className="badge badge-error float-right">Changes</span>
        )} */}
        {isEditable && pending_history?.updated_data?.active !== false ? (
          <>
            <button
              type="button"
              className="ml-2 btn btn-outline-success btn-sm "
              onClick={async () => {
                await reset();
                onEdit(item);
              }}
            >
              Edit
            </button>
            <Popconfirm
              title="Are you sure to delete this group?"
              onConfirm={onDelete}
              okText="Yes"
              cancelText="No"
            >
              <i className="ml-2 fas fa-trash cursor-pointer"></i>
            </Popconfirm>
          </>
        ) : (
          "-"
        )}
      </td>
    </tr>
  );
}
