import { useEffect } from "react";
import { useState } from "react";
import ContentTableItem from "./ContentTableItem";
import { RelatedContentTypes } from "../../../constants";
import useBlogList from "../../../utils/CustomHooks/useBlogList";
import useCategoryList from "../../../utils/CustomHooks/useCategoryList";
import useInterventionList from "../../../utils/CustomHooks/useInterventionList";
import usePlanList from "../../../utils/CustomHooks/usePlanList";
import useRelatedContentList from "../../../utils/CustomHooks/useRelatedContentList";
import useSubDomainList from "../../../utils/CustomHooks/useSubDomainList";
import useTherapyList from "../../../utils/CustomHooks/useTherapyList";
import RelatedContentAction from "./RelatedContentAction";

export default function RelatedContentsGroup(props) {
  let {
    contentId,
    relatedContentList = [],
    refresh = () => {},
    onClose = () => {},
    onBack = () => {},
    isEditable,
    contentType,
    types = RelatedContentTypes.map((x) => x.value),
    showDesc = false,
  } = props;
  const [relatedcontent, setRelatedContent] = useState({});
  const { deleteRelatedContent } = useRelatedContentList();
  const { subDomains, getSubDomains } = useSubDomainList();
  const { categoryList, getCategoryList } = useCategoryList();
  const { interventionList, getInterventionList } = useInterventionList();
  const { therapyList, getTherapyList } = useTherapyList();
  const { blogList, getBlogList } = useBlogList();
  const { planList, getPlanList } = usePlanList("");

  useEffect(() => {
    if (types.find((x) => x === "SUBDOMAIN")) getSubDomains({ active: true });
    if (types.find((x) => x === "CATEGORY")) getCategoryList({ active: true });
    if (types.find((x) => x === "INTERVENTION"))
      getInterventionList({ active: true, published: true }, false);
    if (types.find((x) => x === "THERAPY"))
      getTherapyList({ active: true, published: true }, false);
    if (types.find((x) => x === "BLOG"))
      getBlogList(
        { active: true, published: true, blog_type: "Default" },
        false
      );
    if (types.find((x) => x === "PLAN"))
      getPlanList({ active: true, published: true }, false);
  }, []);

  useEffect(() => {
    refresh();
  }, []);

  const remove = async(i) => {
    await deleteRelatedContent({ related_content_row_id: i });
    refresh();
  };
  const onEdit = (values = {}) => {
    setRelatedContent(values);
  };

  return (
    <div className="row justify-content-center ">
      <div className="col-md-12">
        <div className="table-responsive border p-2">
          <table className="table questionnaire-table table-borderless mb-0">
            <thead>
              <tr className=" ">
                <th className="col-sm-8">Content</th>
                <th className="col-sm-2">Type</th>
                <th className="col-sm-2">Action</th>
              </tr>
            </thead>
            <tbody>
              {relatedContentList && relatedContentList.length > 0 ? (
                relatedContentList
                  .slice()
                  .sort((a, b) => a.sort_order - b.sort_order)
                  .map((item, index) => (
                    <ContentTableItem
                      key={index}
                      {...{
                        item,
                        index,
                        subDomains,
                        categoryList,
                        interventionList,
                        therapyList,
                        blogList,
                        planList,
                        onEdit,
                        remove,
                      }}
                    />
                  ))
              ) : (
                <tr>
                  <td className="text-center" colSpan="5">
                    No Realated Content Found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      <RelatedContentAction
        {...props}
        contentId={contentId}
        contentType={contentType}
        relatedcontent={relatedcontent}
        reset={() => setRelatedContent({})}
        subDomains={subDomains}
        categoryList={categoryList}
        interventionList={interventionList}
        therapyList={therapyList}
        blogList={blogList}
        planList={planList}
        showDesc={showDesc}
        types={types}
      />
      <div className="d-flex align-items-end justify-content-center col-md-12 p-5">
        <button
          onClick={onClose}
          type="button"
          className="btn btn-danger btn-sm float-right btn-add-domain mr-2"
        >
          Close
        </button>{" "}
        <button
          onClick={onBack}
          type="button"
          className="btn btn-error btn-sm float-right btn-add-domain mr-2"
        >
          Back
        </button>
      </div>
    </div>
  );
}
