import { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import MultiSelect from "../../../components/MultiSelect/MultiSelect";
import graphHandler from "../../../Apollo/graphHandler";
import { CREATE_SCREENER } from "../../../Apollo/AdminServices/MutationRequest";
import { toast } from "react-toastify";
import Description from "../../../components/Input/Description";
import CustomButton from "../../../components/Button/CustomButton";
import MetaInput from "../../../components/MetaInput";

export default function CreateScreenerSection({
  refresh = () => {},
  values = {},
  onDone = () => {},
  onClose = () => {},
  setScreener = () => {},
  setLoading = () => {},
  templateOption = [],
}) {
  const subDomains = useSelector(({ DataSet }) => DataSet.subDomains);
  const [type, setType] = useState(false);
  const [subDomainList, setSubDomainList] = useState([]);
  const formik = useFormik({
    initialValues: {
      ...defaultValue,
    },
    validationSchema: type ? validation : otherValidation,
    onSubmit: (values) => {
      onSubmit(values);
    },
  });

  useEffect(() => {
    formik.values.screener_type && formik.validateForm();
  }, [type]);

  useEffect(() => {
    setType(formik.values.screener_type === "MultiDimensionalScreener");
  }, [formik.values.screener_type]);

  useEffect(() => {
    if (values.sub_domain_ids && values.sub_domain_ids.length) {
      setSubDomainList(
        subDomains.map((x) => ({
          label: x.sub_domain_name,
          value: x.sub_domain_id,
          isRemoved: true,
          //   isFixed:
          //     values.sub_domain_ids.filter((y) => y === x.sub_domain_id).length >
          //     0,
        }))
      );
    } else {
      setSubDomainList(
        subDomains.map((x) => ({
          label: x.sub_domain_name,
          value: x.sub_domain_id,
        }))
      );
    }
  }, [subDomains, values]);

  const handleError = () => {
    if (Object.keys(formik.errors).length > 0) {
      toast.error(formik.errors[Object.keys(formik.errors)[0]]);
    }
  };

  const onSubmit = (formikValues) => {
    formik.setSubmitting(true);
    setLoading(true);
    const {
      screener_name,
      screener_description,
      mobile_description,
      screener_time,
      screener_format,
      screener_type,
      sort_type,
      screener_meta,
      screener_code,
      sub_domain_ids,
      template_id,
      alias_name
    } = formikValues;
    let request = {
      screener_name,
      screener_code,
      screener_time: (Number(screener_time) * 60).toString(),
      screener_format,
      screener_type,
      sort_type,
      alias_name,
      template_id,
    };
    if (formik.values.screener_type !== "MultiDimensionalScreener") {
      request.sub_domain_ids = [...sub_domain_ids.map((x) => x.value)];
    }
    let description = [];
    if (screener_description) {
      description.push({
        client_type: "Web",
        description: screener_description,
      });
    }
    if (mobile_description) {
      description.push({
        client_type: "Mobile",
        description: mobile_description,
      });
    }
    if (description.length > 0) request.screener_description = description;
    if (screener_meta && screener_meta.length > 0) {
      request.screener_meta = screener_meta;
    } else {
      request.screener_meta = [];
    }
    createScreener(request);
  };
  const createScreener = async (create_request) => {
    // console.log("create_request", create_request);
    formik.setSubmitting(true);
    try {
      const { data } = await graphHandler().invoke(
        CREATE_SCREENER,
        { create_request },
        1,
        false,
        true
      );
      if (data && data.createScreener && data.createScreener.screener) {
        // console.log("createScreener Success", data.createScreener.screener);
        setScreener(data.createScreener.screener);
        onDone();
        refresh();
        setLoading(false);
        formik.setSubmitting(false);
      }
    } catch (error) {
      console.error("createScreener Error", error);
      setLoading(false);
      formik.setSubmitting(false);
    }
  };

  const handleNumber = (e) => {
    const { name, value } = e.target;
    const re = /^[0-9\b]+$/;
    let temp = !value
      ? value
      : re.test(value)
      ? value
      : formik.values[name]
      ? formik.values[name]
      : "";
    formik.setFieldValue(name, temp ? temp.substring(0, 10) : temp);
  };
  return (
    <div className="p-5 position-relative">
      <div className="row justify-content-center ">
        <div className="col-md-6">
          <div className="row">
            <div className="col-md-12 form-group">
              <label
                htmlFor="question-text"
                className="col-form-label w-100 mb-2"
              >
                Screener Type
              </label>
              <select
                name="screener_type"
                className="custom-select"
                id="screener_type"
                onChange={formik.handleChange}
                value={
                  formik.values && formik.values.screener_type
                    ? formik.values.screener_type
                    : ""
                }
                onBlur={formik.handleBlur}
              >
                <option value="" hidden>
                  Select Type
                </option>
                <option value="MultiDimensionalScreener">
                  Multidimensional
                </option>
                <option value="DomainScreener">Other</option>
              </select>

              <small className="text-danger">
                {formik.touched.screener_type &&
                formik.errors &&
                formik.errors.screener_type
                  ? formik.errors.screener_type
                  : ""}
              </small>
            </div>

            {formik.values.screener_type &&
              formik.values.screener_type !== "MultiDimensionalScreener" && (
                <div className="col-md-12 form-group">
                  <label
                    htmlFor="questionnaire-topic"
                    className="col-form-label w-100 mb-2"
                  >
                    Select Sub Domain
                  </label>
                  <MultiSelect
                    option={subDomainList}
                    onChange={(e) => formik.setFieldValue("sub_domain_ids", e)}
                    placeholder="Select Sub Domain"
                    isCreatable={false}
                    isMulti={true}
                    type="Sub Domain"
                    name="sub_domain_ids"
                    id="sub_domain_ids"
                    onBlur={formik.handleBlur}
                    defaultValue={formik.values.sub_domain_ids}
                  />
                  <small className="text-danger">
                    {formik.touched.sub_domain_ids &&
                    formik.errors &&
                    formik.errors.sub_domain_ids
                      ? formik.errors.sub_domain_ids
                      : ""}
                  </small>
                </div>
              )}
            <div className="col-md-12 form-group">
              <label
                htmlFor="screener_code"
                className="col-form-label w-100 mb-2"
              >
                Code
              </label>
              <input
                type="text"
                className="form-control"
                id="screener_code"
                name="screener_code"
                value={
                  formik.values && formik.values.screener_code
                    ? formik.values.screener_code
                    : ""
                }
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <small className="text-danger">
                {formik.touched.screener_code &&
                formik.errors &&
                formik.errors.screener_code
                  ? formik.errors.screener_code
                  : ""}
              </small>
            </div>
            <div className="col-md-12 form-group">
              <label
                htmlFor="template_id"
                className="col-form-label w-100 mb-2"
              >
                Template
              </label>
              <select
                id="template_id"
                name="template_id"
                className="custom-select"
                onChange={formik.handleChange}
                value={
                  formik.values.template_id ? formik.values.template_id : ""
                }
                onBlur={formik.handleBlur}
              >
                <option key="Select" value="" hidden>
                  Select
                </option>
                {templateOption.map((x, i) => (
                  <option value={x.value} key={i}>
                    {x.label}
                  </option>
                ))}
              </select>
              <small className="text-danger">
                {formik.touched.template_id &&
                formik.errors &&
                formik.errors.template_id
                  ? formik.errors.template_id
                  : ""}
              </small>
            </div>
            <div className="col-md-12 form-group">
              <label
                htmlFor="screener_format"
                className="col-form-label w-100 mb-2"
              >
                Format
              </label>
              <select
                id="screener_format"
                name="screener_format"
                className="custom-select"
                onChange={formik.handleChange}
                value={
                  formik.values.screener_format
                    ? formik.values.screener_format
                    : ""
                }
                onBlur={formik.handleBlur}
              >
                <option key="Select" hidden>
                  Select
                </option>
                <option value="Screener">Screener</option>
                <option value="Game">Game</option>
              </select>
              <small className="text-danger">
                {formik.touched.screener_format &&
                formik.errors &&
                formik.errors.screener_format
                  ? formik.errors.screener_format
                  : ""}
              </small>
            </div>
            <div className="col-md-12 form-group">
              <label htmlFor="sort_type" className="col-form-label w-100 mb-2">
                Sort Type{" "}
              </label>
              <select
                id="sort_type"
                name="sort_type"
                className="custom-select"
                onChange={formik.handleChange}
                value={formik.values.sort_type ? formik.values.sort_type : ""}
                onBlur={formik.handleBlur}
              >
                <option key="Select" hidden>
                  Select
                </option>
                <option value="Topic">Topic</option>
                <option value="Question">Question</option>
              </select>
              <small className="text-danger">
                {formik.touched.sort_type &&
                formik.errors &&
                formik.errors.sort_type
                  ? formik.errors.sort_type
                  : ""}
              </small>
            </div>
            <div className="col-md-12 form-group">
              <label
                htmlFor="screener_time"
                className="col-form-label w-100 mb-2"
              >
                Duration (in minutes)
              </label>
              <input
                type="text"
                className="form-control"
                id="screener_time"
                name="screener_time"
                maxLength={10}
                onChange={handleNumber}
                value={
                  formik.values.screener_time ? formik.values.screener_time : ""
                }
                onBlur={formik.handleBlur}
              />
              <small className="text-danger align-self-end">
                {formik.touched.screener_time &&
                formik.errors &&
                formik.errors.screener_time
                  ? formik.errors.screener_time
                  : ""}
              </small>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="row">
            <div className="col-md-12 form-group">
              <label
                htmlFor="screener_name"
                className="col-form-label w-100 mb-2"
              >
                Name
              </label>
              <input
                type="text"
                className="form-control"
                id="screener_name"
                name="screener_name"
                value={
                  formik.values && formik.values.screener_name
                    ? formik.values.screener_name
                    : ""
                }
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <small className="text-danger">
                {formik.touched.screener_name &&
                formik.errors &&
                formik.errors.screener_name
                  ? formik.errors.screener_name
                  : ""}
              </small>
            </div>
            <div className="col-md-12 form-group">
              <label htmlFor="alias_name" className="col-form-label w-100 mb-2">
                Alias Name
              </label>
              <input
                type="text"
                className="form-control"
                id="alias_name"
                name="alias_name"
                value={
                  formik.values && formik.values.alias_name
                    ? formik.values.alias_name
                    : ""
                }
                onChange={(e)=>formik.setFieldValue("alias_name", e.target.value.trimStart())}
                // onBlur={formik.handleBlur}
                onBlur={(e)=>{
                  formik.setFieldTouched("alias_name", true)
                  formik.setFieldValue("alias_name", e.target.value.replace(/\s+/g,' ').trim())
                }}
              />
              <small className="text-danger">
                {formik.touched.alias_name &&
                formik.errors &&
                formik.errors.alias_name
                  ? formik.errors.alias_name
                  : ""}
              </small>
            </div>
            <Description
              formik={formik}
              webName="screener_description"
              webTitle={<>Description</>}
              mobileTitle={<>Mobile Description</>}
            />
          </div>
        </div>

        <div className="col-md-12">
          <MetaInput
            formik={formik}
            parentArray="screener_meta"
            typeKey="type"
            titleKey="key"
            valueKey="value"
          />
        </div>

        <div className={"d-flex align-items-end justify-content-end col-md-12"}>
          <button
            onClick={onClose}
            type="button"
            className="btn btn-danger btn-sm float-right btn-add-domain mr-2"
          >
            Close
          </button>
          <CustomButton
            type="button"
            className="btn btn-primary btn-outline-success btn-sm float-right btn-add-domain"
            disabled={
              Object.keys(formik.errors).length !== 0 ||
              !formik.isValid ||
              formik.isSubmitting
            }
            onClick={
              formik.isValid && Object.keys(formik.values).length !== 0
                ? formik.handleSubmit
                : handleError
            }
          >
            Create
          </CustomButton>
        </div>
      </div>
    </div>
  );
}

const validation = Yup.object().shape({
  screener_type: Yup.string().required("Screener type is required"),
  screener_description: Yup.string()
    .trim()
    .required("Screener description is required"),
  mobile_description: Yup.string()
    .trim()
    .required("Screener mobile description is required"),
  // .min(4, "Description should be min of 4 characters")
  // .max(150, "Description should be maximum of 150 characters")
  screener_name: Yup.string().trim().required("Enter a screener name"),
  alias_name: Yup.string().trim(),
  screener_code: Yup.string().trim().required("Enter a screener code"),
  // .min(4, "Name should be min of 4 characters")
  // .max(30, "Name should be maximum of 30 characters")
  screener_time: Yup.string().trim().required("Enter screener duration"),
  screener_format: Yup.string().required("Select a screener format"),
  template_id: Yup.string().required("Select a template"),
  sort_type: Yup.string().required("Select a sort type"),
  screener_meta: Yup.array().of(
    Yup.object().shape({
      type: Yup.string().required("Select a data type"),
      key: Yup.string().trim().required("Enter a title"),
      value: Yup.string().trim().required("Enter a value"),
    })
  ),
});

const otherValidation = Yup.object().shape({
  screener_type: Yup.string().required("Screener type is required"),
  screener_description: Yup.string()
    .trim()
    .required("Screener description is required"),
  mobile_description: Yup.string()
    .trim()
    .required("Screener mobile description is required"),
  // .min(4, "Description should be min of 4 characters")
  // .max(150, "Description should be maximum of 150 characters")
  screener_name: Yup.string().trim().required("Enter a screener name"),
  alias_name: Yup.string().trim(),
  screener_code: Yup.string().trim().required("Enter a screener code"),
  // .min(4, "Name should be min of 4 characters")
  // .max(30, "Name should be maximum of 30 characters")
  screener_time: Yup.string().trim().required("Enter Screener duration"),
  template_id: Yup.string(),
  screener_format: Yup.string().required("Select a screener format"),
  sort_type: Yup.string().required("Select a screener format"),
  screener_meta: Yup.array().of(
    Yup.object().shape({
      type: Yup.string().required("Select a data type"),
      key: Yup.string().trim().required("Enter a title"),
      value: Yup.string().trim().required("Enter a value"),
    })
  ),
  sub_domain_ids: Yup.array()
    .of(
      Yup.object().shape({
        label: Yup.string(),
        value: Yup.string(),
      })
    )
    .test({
      message: "Select a sub domain",
      test: (arr) => {
        return arr && arr.length > 0;
      },
    }),
});

var defaultValue = {
  sub_domain_ids: [],
  screener_meta: [],
};
