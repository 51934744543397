import { useEffect, useState } from "react";
import { Modal, ModalBody } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import graphHandler from "../../../Apollo/graphHandler";
import {
  CREATE_EXPLAINER_GQL,
  UPDATE_EXPLAINER_GQL,
} from "../../../Apollo/AdminServices/MutationRequest";
import {
  getDescription,
  removeEmptyObjValue,
} from "../../../utils/Functions/filter";
import Description from "../../../components/Input/Description";
import SingleVideoUpload from "../../../components/MediaFileUpload/SingleVideoUpload";
import SingleImageUpload from "../../../components/MediaFileUpload/SingleImageUpload";

export default function Explainer({
  isOpen,
  setOpen = () => {},
  refresh = () => {},
  initialValue = {},
}) {
  // const subDomains = useSelector(({ DataSet }) => DataSet.subDomains);
  const formik = useFormik({
    initialValues: {},
    validationSchema: validation,
    onSubmit: (values) => {
      onSubmit(values);
    },
  });
  const [explainerData, setExplainerData] = useState({});

  // console.log("initialValue",initialValue)
  useEffect(() => {
    if (initialValue.explainer_id) {
      let value = {
        explainer_name: initialValue.explainer_name,
        explainer_id: initialValue.explainer_id,
        explainer_code: initialValue.explainer_code,
      };
      if (
        initialValue.explainer_description &&
        initialValue.explainer_description.length > 0
      ) {
        if (getDescription(initialValue.explainer_description, "Web")) {
          value.explainer_description = getDescription(
            initialValue.explainer_description,
            "Web"
          ).description;
        }
        if (getDescription(initialValue.explainer_description, "Mobile")) {
          value.mobile_description = getDescription(
            initialValue.explainer_description,
            "Mobile"
          ).description;
        }
      }
      if (
        initialValue.explainer_image_urls &&
        initialValue.explainer_image_urls.length > 0
      ) {
        value.explainer_image_urls = initialValue.explainer_image_urls;
      }
      if (
        initialValue.explainer_video_urls &&
        initialValue.explainer_video_urls.length > 0
      ) {
        value.explainer_video_urls = initialValue.explainer_video_urls;
      }
      formik.setValues(value);
    }
  }, [initialValue, isOpen]);

  const onClose = () => {
    formik.resetForm();
    setOpen();
  };

  const onSubmit = async (values) => {
    // console.log("Submit", values);
    const {
      explainer_id,
      explainer_name,
      explainer_description,
      mobile_description,
      // media,
      explainer_image_urls,
      explainer_video_urls,
      explainer_code,
      // mobilemedia,
    } = values;
    const request = { explainer_name, explainer_code };

    let description = [];
    if (explainer_description) {
      description.push({
        client_type: "Web",
        description: explainer_description,
      });
    }
    if (mobile_description) {
      description.push({
        client_type: "Mobile",
        description: mobile_description,
      });
    }
    if (description.length > 0) {
      request.explainer_description = description;
    }else{
      request.explainer_description =[]
    }
    if (explainer_image_urls && explainer_image_urls.length > 0) {
      request.explainer_image_urls = explainer_image_urls.map((x) => {
        delete x.index;
        return removeEmptyObjValue(x);
      });
    }else{
      request.explainer_image_urls =[]
    }
    if (explainer_video_urls && explainer_video_urls.length > 0) {
      request.explainer_video_urls = explainer_video_urls.map((x) => {
        delete x.index;
        return removeEmptyObjValue(x);
      });
    }else{
      request.explainer_video_urls =[]
    }
    if (explainer_id) {
      update({ explainer_id, ...request });
    } else {
      create({ ...request });
    }
  };

  const create = async (create_request = {}) => {
    try {
      const { data } = await graphHandler().invoke(
        CREATE_EXPLAINER_GQL,
        { create_request },
        1,
        true
      );
      if (
        data &&
        data.createExplainer &&
        data.createExplainer.explainer &&
        data.createExplainer.explainer.explainer_id
      ) {
        //   setLocalizeId(data.createExplainer.explainer.explainer_id);
        formik.setFieldValue(
          "explainer_id",
          data.createExplainer.explainer.explainer_id
        );
        setExplainerData(data.createExplainer.explainer);
      }
      refresh();
      // onClose();
    } catch (error) {
      console.log("CreateExplainer error", error);
    }
  };
  const update = async (update_request = {}) => {
    delete update_request.explainer_code;
    if (
      update_request.explainer_name ===
      (initialValue.explainer_name || explainerData.explainer_name)
      ) {
        delete update_request.explainer_name;
    }
    // let ImageData = initialValue.explainer_image_urls ?? explainerData.explainer_image_urls;
    // if (ImageData&&ImageData.length > 0) {
    //   let Image = update_request?.explainer_image_urls&&update_request?.explainer_image_urls.filter(
    //     (x) =>!ImageData.some((y) => y.image_type === x.image_type && y.is_mobile === x.is_mobile && y.url === x.url));
    //     if (Image && Image?.length === 0) {
    //       delete update_request.explainer_image_urls;
    //   }
    // }else{
    //   update_request.explainer_image_urls=[];
    // }
    // let VideoData = initialValue.explainer_video_urls ?? explainerData.explainer_video_urls;
    // if (VideoData&&VideoData?.length>0) {
    //   let Video = update_request?.explainer_video_urls&&update_request?.explainer_video_urls.filter(
    //     (x) =>!VideoData.some((y) => y.video_type === x.video_type && y.is_mobile === x.is_mobile && y.video_url === x.video_url));
    //     if (Video && Video?.length === 0) {
    //       delete update_request.explainer_video_urls;
    //     }
    //   }else{
    //     update_request.explainer_video_urls=[];
    //   }
      
    //   let descriptionData = initialValue.explainer_description ?? explainerData.explainer_description;
    //   if (descriptionData&&descriptionData?.length>0){
    //     let description = update_request&&update_request?.explainer_description.filter((x) =>!descriptionData.some((y) =>y?.client_type === x?.client_type && y?.description === x?.description));
    //     if (description && description?.length === 0) {
    //       delete update_request.explainer_description;
    //     }
    // }
    
    if (Object.keys(update_request).length === 1) {
      refresh();
      onClose();
      return
    }
    
    try {
      console.log("update_request",update_request)
      await graphHandler().invoke(
        UPDATE_EXPLAINER_GQL,
        { update_request },
        1,
        true
      );
      refresh();
      onClose();
    } catch (error) {
      console.log("CreateExplainer error", error);
    }
  };

  const handleError = () => {
    if (Object.keys(formik.errors).length > 0) {
      toast.error(formik.errors[Object.keys(formik.errors)[0]]);
    }
  };

  return (
    <Modal toggle={onClose} isOpen={isOpen} centered scrollable size="xl">
      <div className="modal-header">
        <button type="button" className="close" onClick={onClose}>
          <span aria-hidden="true">&times;</span>
        </button>
        <h5 className="modal-title">
          {formik.values.explainer_id ? "Update" : "Create"} Explainer
        </h5>
      </div>
      <ModalBody className="pt-0">
        <div className="container">
          <div className="row justify-content-center">
            <div className="form-group col-md-12">
              <label htmlFor="explainer_name" className="col-md-4 ">
                Name
              </label>
              <input
                type="text"
                className="form-control"
                id="explainer_name"
                name="explainer_name"
                // maxLength="30"
                value={formik.values.explainer_name}
                onChange={(e) =>
                  formik.setFieldValue(
                    "explainer_name",
                    e.target.value.trimStart()
                  )
                }
                onBlur={(e) => {
                  formik.setFieldTouched("explainer_name", true);
                  formik.setFieldValue(
                    "explainer_name",
                    e.target.value.replace(/\s+/g, " ").trim()
                  );
                }}
              />
              <small className="text-danger">
                {formik.touched.explainer_name &&
                formik.errors &&
                formik.errors.explainer_name
                  ? formik.errors.explainer_name
                  : ""}
              </small>
            </div>
            <div className="form-group col-md-12">
              <label htmlFor="explainer_code" className="col-md-4 ">
                Code
              </label>
              <input
                type="text"
                className="form-control"
                id="explainer_code"
                name="explainer_code"
                // maxLength="30"
                value={formik.values.explainer_code}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                disabled={initialValue.explainer_id}
              />
              <small className="text-danger">
                {formik.touched.explainer_code &&
                formik.errors &&
                formik.errors.explainer_code
                  ? formik.errors.explainer_code
                  : ""}
              </small>
            </div>
            <Description formik={formik} webName="explainer_description" showEditor={true} />

            {formik.values.explainer_id ? (
              <>
                <div className="col-md-12">
                  <SingleImageUpload
                    formik={formik}
                    heading="Image File"
                    contentId={formik.values.explainer_id}
                    contentType="EXPLAINER"
                    mediaType="Image"
                    parentArray="explainer_image_urls"
                  />
                </div>
                <div className="col-md-12">
                  <SingleVideoUpload
                    formik={formik}
                    heading="Video File"
                    contentId={formik.values.explainer_id}
                    contentType="EXPLAINER"
                    mediaType="Video"
                    parentArray="explainer_video_urls"
                  />
                </div>
              </>
            ) : null}
            <button
              type="button"
              className="btn btn-primary btn-outline-success float-right btn-add-domain"
              disabled={Object.keys(formik.errors).length !== 0}
              onClick={
                formik.isValid && Object.keys(formik.values).length !== 0
                  ? formik.handleSubmit
                  : handleError
              }
            >
              {formik.values.explainer_id ? "Update" : "Add"}
            </button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
}

const validation = Yup.object().shape({
  mobile_description: Yup.string().required("Enter a Mobile Description"),
  explainer_description: Yup.string().required("Enter a Description"),
  // .min(4, "Description should be min of 4 characters")
  // .max(50, "Description should be maximum of 50 characters")
  explainer_name: Yup.string().required("Enter a name"),
  explainer_code: Yup.string().required("Enter a code"),
  // .min(4, "Name should be min of 4 characters")
  // .max(30, "Name should be maximum of 30 characters")
  // media: Yup.object().required("Select a media"),
  // mobilemedia: Yup.object().required("Select a mobile media"),
});
