import { useEffect, useState } from "react";
import graphHandler from "../../Apollo/graphHandler";
import { GET_USER } from "../../Apollo/ProfileService/profileQueryRequest";

function useUserInfo(id) {
  const [userInfo, setUserInfo] = useState({});
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (id) {
      getUser(id);
    } else {
      setUserInfo({});
    }
  }, [id]);

  const getUser = async (user_id) => {
    setLoading(true);
    try {
      const { data } = await graphHandler().invoke(
        GET_USER,
        { user_id },
        0,
        false,
        true
      );
      if (data && data.getUser) {
        // console.log("getUser Success", data.getUser);
        setUserInfo(data.getUser);
        setLoading(false);
      } else {
        setUserInfo({});
        setLoading(false);
      }
    } catch (error) {
      console.log("getPost Error", error);
      setUserInfo({});
      setLoading(false);
    }
  };

  return { userInfo, loading, getUser };
}

export default useUserInfo;
