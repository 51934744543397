import { useState } from "react";
import { GET_SCREENER_LIST_GQL } from "../../Apollo/AdminServices/QueryRequest";
import graphHandler from "../../Apollo/graphHandler";
import { sortArray } from "../Functions/filter";

function useScreenerList() {
  const [screenerList, setScreenerList] = useState([]);
  const [loading, setLoading] = useState(false);

  const getScreenerList = async (req = {}, get_pending_update = true) => {
    setLoading(true);
    try {
      const { data } = await graphHandler().invoke(
        GET_SCREENER_LIST_GQL,
        { get_pending_update, ...req },
        1,
        false,
        true
      );
      if (
        data &&
        data.getScreenerList &&
        data.getScreenerList.screeners &&
        data.getScreenerList.screeners.length > 0
      ) {
        // console.log("getScreenerList Success", data.getScreenerList);
        const struct = data.getScreenerList.screeners.map((x) => ({
          ...x.screener,
          label: x.screener.screener_name,
          value: x.screener.screener_id,
          pending_screener_history: x.pending_screener_history,
        }));
        setScreenerList([]);
        setScreenerList(sortArray(struct));
        setLoading(false);
      } else {
        setScreenerList([]);
        setLoading(false);
      }
    } catch (error) {
      console.log("getScreenerList Error", error);
      setScreenerList([]);
      setLoading(false);
    }
  };

  return {
    screenerList,
    loading,
    getScreenerList,
  };
}

export default useScreenerList;
