import { useEffect, useState } from "react";
import { Modal, ModalBody } from "reactstrap";
import { useSelector } from "react-redux";
import UpdatePlan from "../Section/UpdatePlan";
import CreatePlan from "../Section/CreatePlan";
import CustomLoader from "../../../components/Loader";
import { Steps } from "antd";
import usePlanGroupList from "../../../utils/CustomHooks/usePlanGroupList";
import PlanGroup from "../components/PlanGroup";
import PlanAction from "../components/PlanAction";
import usePlanActionList from "../../../utils/CustomHooks/usePlanActionList";
import RelatedContentsGroup from "../../../components/RelatedContents/component/RelatedContentsGroup";
import useRelatedContentList from "../../../utils/CustomHooks/useRelatedContentList";
import useTemplateList from "../../../utils/CustomHooks/useTemplateList";
import useTherapyList from "../../../utils/CustomHooks/useTherapyList";
import usePlanList from "../../../utils/CustomHooks/usePlanList";
import usePlanAPI from "../../../utils/CustomHooks/usePlanAPI";
const { Step } = Steps;

export default function PlanModal({
  initialValues = {},
  isOpen,
  setOpen,
  refresh,
  categoryList = [],
  interventionList = [],
  screenerList = [],
  isEditable = false,
  isFirst = false,
}) {
  const { groupList, loading: groupLoading, getGroupList } = usePlanGroupList();
  const {
    actionList,
    loading: actionLoading,
    getActionList,
  } = usePlanActionList();
  const { planList, getPlanList } = usePlanList("");
  const { mailList, getTemplateList } = useTemplateList();
  const { therapyList,getTherapyList } = useTherapyList("");
  const {
    relatedContents,
    loading: relatedContentLoading,
    getRelatedContents,
  } = useRelatedContentList();
  const subDomains = useSelector(({ DataSet }) => DataSet.subDomains);
  
  const [activeKey, setActive] = useState(0);
  const [plan, setPlan] = useState({});
  const [loading, setLoading] = useState(false);
  const [reqCount, setReqCount] = useState(0);
  const [relateCount, setRelateCount] = useState(0);
  const [reqActionCount, setReqActionCount] = useState(0);
  const [subDomainList, setSubDomainList] = useState([]);
  const [categories, setCategories] = useState([]);
  const [interventions, setInterVentions] = useState([]);
  const [screeners, setScreeners] = useState([]);
  const [planGroups, setPlanGroups] = useState([]);
  const [mailOption, setMailOption] = useState([]);
  const [reportOption, setReportOption] = useState([]);
  const [therapyOption, setTherapyOption] = useState([]);
  const [planOption, setPlanOption] = useState([]);
  const { reviewPlan } = usePlanAPI("");
  const { planList:PlanDetails, getPlanList:getPlanDetails } = usePlanList("");
  
  useEffect(() => {
    initialValues.plan_id && setPlan(initialValues);
  }, [initialValues]);
  useEffect(() => {
    if (plan.plan_id && isOpen) {
      getGroupList({
        plan_id: plan.plan_id,
        get_pending_update: true,
      });
      getPlanDetails({
        plan_id: plan.plan_id,
        get_pending_update: true,
      })
    }
  }, [plan, reqCount, isOpen]);

  useEffect(() => {
    if (plan.plan_id && isOpen) {
      getTemplateList({ active: true });
      getTherapyList({ published: true})
      getPlanList({published: true})
      getActionList({
        plan_id: plan.plan_id,
        get_pending_update: true,
      });
      getPlanDetails({
        plan_id: plan.plan_id,
        get_pending_update: true,
      })
    }
  }, [plan, reqActionCount, isOpen]);

  useEffect(() => {
    if (plan.plan_id && isOpen) {
      getRelatedContents({
        content_id: plan.plan_id,
        content_type: "PLAN",
      });
    }
  }, [plan, relateCount, isOpen]);

  const handleDiscord = () => {
    const request = {
      review_request: {
        comment: "Discord Values",
        approve: false,
      },
      plan_history_id: PlanDetails[0]?.pending_history?.plan_history_id,
    };
    reviewPlan(request, onClose);
  }; 

  const PlanSection = plan.plan_id ? UpdatePlan : CreatePlan;
  const renderSteps = (key) => {
    switch (key) {
      case 0:
        return (
          <PlanSection
            refresh={refresh}
            onDone={() => setActive(1)}
            onClose={onClose}
            plan={plan}
            handleDiscord={handleDiscord}
            isEditable={isEditable}
            isFirst={isFirst}
            setPlan={setPlan}
            setLoading={setLoading}
            categories={categories}
            subDomainList={subDomainList}
          />
        );
      case 1:
        return (
          <PlanGroup
            refresh={() => setReqCount((x) => x + 1)}
            onClose={onClose}
            onDone={() => setActive(2)}
            onBack={() => setActive(0)}
            plan={plan}
            isEditable={isFirst || isEditable}
            setPlan={setPlan}
            setLoading={setLoading}
            handleDiscord={handleDiscord}
            subDomainList={subDomainList}
            groupList={groupList}
          />
        );
      case 2:
        return (
          <PlanAction
            refresh={() => setReqActionCount((x) => x + 1)}
            onClose={onClose}
            onDone={() => setActive(3)}
            onBack={() => setActive(1)}
            plan={plan}
            isEditable={isFirst || isEditable}
            setPlan={setPlan}
            setLoading={setLoading}
            handleDiscord={handleDiscord}
            subDomainList={subDomainList}
            interventions={interventions}
            groupList={planGroups}
            actionList={actionList}
            screeners={screeners}
            reportOption={reportOption}
            mailOption={mailOption}
            therapyOption={therapyOption}
            planOption={planOption}
          />
        );
      case 3:
        return (
          <RelatedContentsGroup
            refresh={() => setRelateCount((x) => x + 1)}
            onClose={onClose}
            onBack={() => setActive(2)}
            contentId={plan.plan_id}
            isEditable={isFirst || isEditable}
            setLoading={setLoading}
            contentType="PLAN"
            relatedContentList={relatedContents}
            showDesc={true}
          />
        );
      default:
        return <h5>No step found</h5>;
    }
  };

  useEffect(() => {
    setSubDomainList(
      subDomains.map((x) => ({
        label: x.sub_domain_name,
        value: x.sub_domain_id,
      }))
    );
  }, [subDomains]);

  useEffect(() => {
    setCategories(
      categoryList.map((x) => ({
        label: x.category_name,
        value: x.category_id,
      }))
    );
  }, [categoryList]);

  useEffect(() => {
    setInterVentions(
      interventionList.map((x) => ({
        label: x.intervention_name,
        value: x.intervention_id,
      }))
    );
  }, [interventionList]);

  useEffect(() => {
    setPlanGroups(
      groupList.filter((x) => x.active === true).sort((a, b) => a.sort_order - b.sort_order).map((x) => ({
        label: x.plan_group_name,
        value: x.plan_group_id,
      }))
    );
  }, [groupList]);

  useEffect(() => {
    setTherapyOption(
      therapyList.map((x) => ({
        label: x.therapy_name,
        value: x.therapy_id,
      }))
    );
  }, [therapyList]);
  useEffect(() => {
    setPlanOption(
      planList.map((x) => ({
        label: x.plan_name,
        value: x.plan_id,
      }))
    );
  }, [planList]);
  useEffect(() => {
    setScreeners(
      screenerList.map((x) => ({
        label: x.screener_name,
        value: x.screener_id,
      }))
    );
  }, [screenerList]);
  useEffect(() => {
    if (mailList.length > 0) {
      setReportOption(
        mailList
          .filter((x) => x.template_type === "Report")
          .map((x) => ({
            label: x.template_name,
            value: x.template_id,
          }))
      );

      setMailOption(
        mailList
          .filter((x) => x.template_type === "Mail")
          .map((x) => ({
            label: x.template_name,
            value: x.template_id,
          }))
      );
    }
  }, [mailList]);

  const onClose = () => {
    setOpen(false);
    setActive(0);
    setPlan({});
    refresh();
  };

  const onChangeStep = (e) => {
    if (e === 1 && !plan.plan_id) return;
    if (e === 2 && groupList.length === 0) return;
    if (e === 3 && !plan.plan_id) return;
    setActive(e);
  };

  return (
    <Modal
      toggle={onClose}
      isOpen={isOpen}
      size="lg"
      keyboard={false}
      modalClassName="modal-fullscreen modal-dialog-scrollable"
    >
      <div className="modal-header">
        <Steps
          type="navigation"
          current={activeKey}
          onChange={onChangeStep}
          className="site-navigation-steps"
        >
          <Step
            status={plan.plan_id ? "finish" : "process"}
            title={plan.plan_id ? "Plan" : "Create Plan"}
          />
          <Step
            status={
              groupList.length > 0
                ? "finish"
                : plan.plan_id
                ? "process"
                : "wait"
            }
            disabled={!plan.plan_id}
            title="Groups"
          />
          <Step
            status={
              actionList.length > 0
                ? "finish"
                : groupList.length
                ? "process"
                : "wait"
            }
            disabled={groupList.length === 0}
            title="Actions"
          />
          <Step
            status={
              relatedContents.length > 0
                ? "finish"
                : plan.plan_id
                ? "process"
                : "wait"
            }
            disabled={!plan.plan_id}
            title="Related Content"
          />
        </Steps>
      </div>
      <ModalBody>
        {(loading ||
          groupLoading ||
          actionLoading ||
          relatedContentLoading) && <CustomLoader />}
        {renderSteps(activeKey)}
      </ModalBody>
    </Modal>
  );
}
