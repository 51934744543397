import { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
// import { toast } from "react-toastify";
import InputFormik from "../../FormikInput/InputFormik";
import TextAreaFormik from "../../FormikInput/TextAreaFormik";
import SingleMediaFormik from "../../FormikInput/SingleMediaFormik";
import { getObjectArray, suffix } from "../../../utils/Functions/filter";
import MDEditorFormik from "../../FormikInput/MDEditorFormik";
// import ImageHistoryInput from "../../HistoryEditInput/ImageHistoryInput";

export default function BlogForm({
  edit = {},
  setEdit = () => {},
  dataSet = [],
  setDataSet = () => {},
  setCurrentEditor = () => {},
  contentId,
  contentType
}) {
  const getValidation = () => {
    let obj = {};
    if (!edit || !edit.blog_content_type) return {};
    let arr = getObjectArray(edit);
    arr.map((x) => {
      if (x === "content") {
        obj[x] = Yup.string("This is a required field").required(
          "This is a required field"
        );
      }
      //   else if (x === "title") {
      //     obj[x] = Yup.string("This is a required field").required(
      //       "This is a required field"
      //     );
      //   }
      //    else if (x === "image_urls") {
      //     obj[x] = Yup.object();
      //     obj["mobile" + x] = Yup.object();
      //   }
    });
    return Yup.object().shape(obj);
  };
  const formik = useFormik({
    initialValues: {},
    validationSchema: getValidation(),
    onSubmit: (values) => {
      onSubmit(values);
    },
  });
  
  useEffect(() => {
    formik.validateForm();
    if (edit.id) onSet(edit);
  }, [edit]);

  const onSet = async (value = {}) => {
    let modal = {};
    let arr = getObjectArray(value);
    if (
      edit.blog_content_type === "QUOTE" &&
      !arr.find((x) => x === "image_urls")
    ) {
      modal.mobileimage_urls = {
        image_type: "Default",
        url: "",
        is_mobile: true,
      };
      modal.image_urls = {
        image_type: "Default",
        url: "",
        is_mobile: false,
      };
    }
    arr.map((x) => {
      if (x === "image_urls") {
        modal.mobileimage_urls = value.image_urls.find((y) => y.is_mobile);
        modal.image_urls = value.image_urls.find((y) => !y.is_mobile);
      } else if (x === "content") {
        modal.content =
          value.content && value.content.length > 0 ? value.content[0] : "";
      } else if (x === "title") {
        modal.title = value.title ? value.title : "";
      }
    });
    formik.setValues(modal);
    formik.validateForm();
  };

  const onSubmit = async (value) => {
    let final = { ...edit };
    let arr = getObjectArray(edit);
    if (
      edit.blog_content_type === "QUOTE" &&
      !arr.find((x) => x === "image_urls")
    ) {
      arr.push("image_urls");
    }
    arr.map((x) => {
      if (x === "image_urls") {
        if (
          edit.blog_content_type === "QUOTE" &&
          (!value.image_urls || !value.image_urls.url)
        ) {
          return (final.image_urls = null);
        }
        final.image_urls = [];
        if (value.image_urls) {
          final.image_urls.push({
            image_type: value.image_urls.image_type,
            url: value.image_urls.url,
            is_mobile: false,
          });
        }
        if (value.mobileimage_urls) {
          final.image_urls.push({
            image_type: value.mobileimage_urls.image_type,
            url: value.mobileimage_urls.url,
            is_mobile: true,
          });
        }
      } else if (x === "content") {
        final.content = [value.content ? value.content : ""];
      } else if (x === "title") {
        final.title = value.title ? value.title : "";
      }
    });
    // console.log("onSubmit", final, value);
    let set = [...dataSet.sort((a, b) => a.sort_order - b.sort_order)];
    let index = set.findIndex((x) => x.id === edit.id);
    if (index < 0) return;
    set[index] = final;
    setDataSet(set.sort((a, b) => a.sort_order - b.sort_order));
    setCurrentEditor(0);
    setEdit({});
  };
  const renderFields = (value) => {
    if (!value || !value.blog_content_type) return null;
    let arr = getObjectArray(value);
    if (
      edit.blog_content_type === "QUOTE" &&
      !arr.find((x) => x === "image_urls")
    ) {
      arr.push("image_urls");
    }
    // console.log(arr);
    return arr.map((x) => Component(x));
  };
  const Component = (type = "") => {
    if (edit.blog_content_type === "QUOTE" && type === "content") {
      return (
        <TextAreaFormik
          formik={formik}
          id={type}
          key={type}
          label="Paragraph"
          className="col-md-12"
        />
      );
    }
    switch (type) {
      case "content":
        return (
          <MDEditorFormik
            formik={formik}
            id={type}
            key={type}
            label="Paragraph"
            className="col-md-12"
          />
        );
      case "title":
        return (
          <InputFormik
            formik={formik}
            id={type}
            key={type}
            label="Title"
            className="col-md-12"
          />
        );
      case "image_urls":
        return (
          <SingleMediaFormik
            formik={formik}
            id={type}
            key={type}
            mobileKey={"mobile" + type}
            label="Media"
            className="col-md-12"
            contentId={contentId}
            contentType={contentType}
          />
        //   <ImageHistoryInput
        //   parentForm={formik}
        //   contentId={contentId}
        //   contentType={contentType}
        //   parentArray="image_urls"
        //   actualValue={initialValues.image_urls}
        //   updateValue={
        //     updated_data.image_urls &&
        //     updated_data.image_urls.update_items
        //       ? updated_data.image_urls.update_items
        //       : false
        //   }
        // />
        );
      default:
        return null;
    }
  };

  return (
    <div className="col-md-12">
      <div className="col-md-12 py-4">
        <h6 className="text-center">
          {edit.sort_order + 1 + suffix(edit.sort_order + 1) + " item"}
        </h6>
        {renderFields(edit)}
        <div
          className={`d-flex align-items-center justify-content-center col-md-12 mt-4`}
        >
          <button
            type="button"
            className="btn  btn-add-domain mr-3"
            onClick={() => {
              setCurrentEditor(0);
              setEdit({});
            }}
          >
            Cancel
          </button>
          <button
            type="button"
            className="btn btn-primary btn-outline-success btn-add-domain"
            disabled={
              !formik.isValid ||
              Object.keys(formik.errors).length !== 0 ||
              Object.keys(formik.values).length === 0
            }
            onClick={formik.handleSubmit}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
}
