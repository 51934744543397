import { useEffect } from "react";
import { useState } from "react";
import CreateTherapyGroup from "../section/CreateTherapyGroup";
import UpdateTherapyGroup from "../section/UpdateTherapyGroup";
import TherapyGroupListItem from "./TherapyGroupListItem";
import { Popconfirm } from "antd";

export default function TherapyGroup(props) {
  let {
    therapy = {},
    moduleList = [],
    refresh = () => {},
    onClose = () => {},
    onDone = () => {},
    onBack = () => {},
    handleDiscord=()=>{},
    isEditable,
  } = props;
  const [therapyGroup, setGroup] = useState({});
  const pending_history = therapyGroup.pending_history ? therapyGroup.pending_history : {};
  const hasUpdated = Boolean(pending_history && pending_history.updated_data);
  const [history, setHistory] = useState(false);
  
  useEffect(() => {
    refresh();
  }, []);

  const ActionComponent = therapyGroup.therapy_module_id ?UpdateTherapyGroup:CreateTherapyGroup;
  return (
    <div className="row justify-content-center ">
      <div className="col-md-12">
        <div className="table-responsive border p-2">
          <table className="table questionnaire-table table-borderless mb-0">
            <thead>
              <tr className=" ">
                <th className="col-sm-3">Name</th>
                <th className="col-sm-4">Description</th>
                <th className="col-sm-1">Min Days</th>
                <th className="col-sm-1">Max Days</th>
                <th className="col-sm-1">Interval</th>
                <th className="col-sm-1">Order</th>
                <th className="col-sm-3" colSpan={2}>Action</th>
              </tr>
            </thead>
            <tbody>
              {moduleList.length > 0 ? (
                moduleList
                  .slice()
                  //  .filter((x) =>
                  //   x.published && x?.pending_history?.updated_data?.active
                  //     ? !x?.pending_history?.updated_data?.active
                  //     : x?.active === true
                  // )
                  .filter((x)=>x?.active === true)
                  .sort((a, b) => a.sort_order - b.sort_order)
                  .map((item, i) => (
                    <TherapyGroupListItem
                    {...props}
                      item={item}
                      key={i}
                      isEditable={isEditable}
                      onEdit={setGroup}
                      canDelete={!therapy.published}
                      refresh={refresh}
                      reset={() => setGroup({})}
                      setHistory={setHistory}
                    />
                  ))
              ) : (
                <tr>
                  <td className="text-center" colSpan="7">
                    No Therapy Group Found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      {isEditable && (
        <ActionComponent
          {...props}
          therapyGroup={therapyGroup}
          setGroup={setGroup}
          reset={() => setGroup({})}
        />
      )}
      <div className="d-flex align-items-end justify-content-center col-md-12 p-5">
      {isEditable && (hasUpdated || history) && (
          <Popconfirm
            title="Are you sure to discard and close?"
            onConfirm={handleDiscord}
            //  onCancel={cancel}
            okText="Yes"
            cancelText="No"
          >
            <button
             type="button"
              className="btn btn-danger btn-sm float-right btn-add-domain mr-2"
            >
              Discard All & Close
            </button>
          </Popconfirm>
        )}
        <button
          onClick={onClose}
          type="button"
          className="btn btn-danger btn-sm float-right btn-add-domain mr-2"
        >
          Close
        </button>{" "}
        <button
          onClick={onBack}
          type="button"
          className="btn btn-error btn-sm float-right btn-add-domain mr-2"
        >
          Back
        </button>
        {moduleList.length > 0 && (
          <button
            onClick={onDone}
            type="button"
            className="btn btn-secondary btn-sm float-right btn-add-domain mr-2"
          >
            Next
          </button>
        )}
      </div>
    </div>
  );
}
