import { useEffect, useState } from "react";
import SortByQuestionItem from "../Components/SortByQuestionItem";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Popconfirm } from "antd";

export default function QuestionSortSection(props) {
  const [questions, setQuestions] = useState([]);
  const [prevQuestions, setPrevQuestions] = useState([]);
  const [isUpdated, setIsUpdated] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const { questionaireList = [], isEditable,handleDiscord=()=>{}} = props;

  useEffect(() => {
    if (questionaireList) {
      let data =
        questionaireList &&
        questionaireList.length > 0 &&
        questionaireList.filter((x)=>x?.active === true).map((x) => {
          const hasUpdated = Boolean(
            x && x?.pending_history && x?.pending_history?.updated_data
          );
          setIsUpdated(hasUpdated);
          // setIsUpdated(true);
          return {
            ...x,
            sort_order: hasUpdated
              ? x?.pending_history?.updated_data?.sort_order
              : x.sort_order,
          };
        });
      setQuestions([
        ...data.slice().sort((a, b) => a.sort_order - b.sort_order),
      ]);
        setPrevQuestions([
          ...questionaireList
            .slice().filter((x)=>x?.active === true)
            .sort((a, b) => a.sort_order - b.sort_order),
        ]);
      }
  }, [questionaireList]);

  const reorder = (list, startIndex, endIndex) => {
    // console.log("index", startIndex, endIndex);
    let newData = [
      ...list.map((x, i) => ({
        ...x,
        sort_order:
          i === startIndex
            ? startIndex + 1
            : i === endIndex
            ? endIndex + 1
            : x.sort_order,
      })),
    ];
    const result = Array.from(newData);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result.filter((x) => x);
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;
    const items = reorder(
      questions,
      result.source.index,
      result.destination.index
    );
    setQuestions(items);
    props.refreshQues()
  };
  return (
    <>
      {isUpdated && (
        <div className="d-flex align-items-end justify-content-center p-0 col-md-12">
          <Popconfirm
            title="Are you sure to discard and close?"
            onConfirm={handleDiscord}
            //  onCancel={cancel}
            okText="Yes"
            cancelText="No"
          >
            <button
             type="button"
              className="btn btn-danger btn-sm float-right btn-add-domain mr-2"
            >
              Discard All & Close
            </button>
          </Popconfirm>
          <button
            type="button"
            className="btn btn-primary btn-outline-success btn-sm float-right btn-add-domain"
            onClick={() => setIsOpen(!isOpen)}
          >
            Compare
          </button>
        </div>
      )}
      <div className="pl-5 pr-5 pb-5 pt-3 position-relative row justify-content-center">
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided) => (
              <>
                <div
                  {...provided.droppableProps}
                  className={isOpen?`col-md-6`:`col-md-12`}
                  ref={provided.innerRef}
                >
                  {isOpen && (
                    <label className="badge row badge-error">New</label>
                  )}
                  {questions.map((item, i) => (
                    <Draggable
                      key={item.screener_question_id}
                      draggableId={item.screener_question_id}
                      index={i}
                      isDragDisabled={!isEditable}
                    >
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className="row"
                        >
                          <SortByQuestionItem
                            {...props}
                            item={item}
                            key={i}
                            i={i}
                          />
                        </div>
                      )}
                    </Draggable>
                  ))}
                </div>
              </>
            )}
          </Droppable>
        </DragDropContext>
        {isOpen && (
          <div className="col-md-6">
            <label className="badge row badge-good">Existing</label>
            {prevQuestions.map((item, i) => (
              <div className="row" key={i}>
                <SortByQuestionItem {...props} item={item} key={i} i={i} />
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
}
