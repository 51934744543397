import { Draggable, Droppable } from "react-beautiful-dnd";
import Banner from "../components/Banner";
import Paragraph from "../components/Paragraph";
import MediaLeft from "../components/MediaLeft";
import Quote from "../components/Quote";
import MediaRight from "../components/MediaRight";

export default function Editor(props = {}) {
  let { dataSet = [], edit, setEdit, setCurrentEditor, setDataSet } = props;

  const onEdit = (e) => {
    setEdit(e);
    setCurrentEditor(1);
  };
  const onDelete = (e = {}) => {
    let set = [...dataSet.sort((a, b) => a.sort_order - b.sort_order)];
    let index = set.findIndex((x) => x.id === e.id);
    if (edit.id === e.id) {
      setEdit({});
      setCurrentEditor(0);
    }
    if (index < 0) return;
    set.splice(index, 1);
    setDataSet(
      set
        .sort((a, b) => a.sort_order - b.sort_order)
        .map((x, i) => ({ ...x, sort_order: i }))
    );
  };

  const renderComponent = (value) => {
    switch (value.blog_content_type) {
      case "BANNER":
        return (
          <Banner
            value={value}
            {...props}
            onEdit={onEdit}
            onDelete={onDelete}
          />
        );
      case "PARAGRAPH":
        return (
          <Paragraph
            value={value}
            {...props}
            onEdit={onEdit}
            onDelete={onDelete}
          />
        );
      case "LEFTMEDIA":
        return (
          <MediaLeft
            value={value}
            {...props}
            onEdit={onEdit}
            onDelete={onDelete}
          />
        );
      case "RIGHTMEDIA":
        return (
          <MediaRight
            value={value}
            {...props}
            onEdit={onEdit}
            onDelete={onDelete}
          />
        );
      case "QUOTE":
        return (
          <Quote value={value} {...props} onEdit={onEdit} onDelete={onDelete} />
        );
      default:
        return null;
    }
  };

  return (
    <Droppable droppableId="editor">
      {(provided, snapshot) => (
        <div
          className={`editor-view pb-60 border-dashed ${
            snapshot.isDraggingOver ? "isDragged" : ""
          }`}
          {...provided.droppableProps}
          ref={provided.innerRef}
        >
          {dataSet.map((item, i) => (
            <Draggable key={item.id} draggableId={item.id.toString()} index={i}>
              {(provided) => (
                <div
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  className="editor-component"
                >
                  {renderComponent(item)}
                </div>
              )}
            </Draggable>
          ))}
        </div>
      )}
    </Droppable>
  );
}
