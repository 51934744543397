import { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import graphHandler from "../../../Apollo/graphHandler";
import { UPDATE_SUB_DOMAIN_GQL } from "../../../Apollo/AdminServices/MutationRequest";
import {
  getDescription,
  removeEmptyObjValue,
} from "../../../utils/Functions/filter";
import { VisibilityStatus } from "../../../constants";
import Description from "../../../components/Input/Description";
import CustomButton from "../../../components/Button/CustomButton";
import SingleImageUpload from "../../../components/MediaFileUpload/SingleImageUpload";
import MetaInput from "../../../components/MetaInput";

export default function UpdateSubDomain({
  domainList,
  isOpen,
  setOpen= () => {},
  isEditable = false,
  refresh = () => {},
  onDone = () => {},
  onClose = () => {},
  setLoading = () => {},
  initialValue = {},
}) {
  // const [progress, setProgress] = useState(0);
  // const [size, setSize] = useState("0");
  // const [isUpload, setUpload] = useState(false);
  // const [image, setImage] = useState({});
  const [canEdit, setEdit] = useState(false);
  const formik = useFormik({
    initialValues: {
      description: "",
      mobile_description: "",
      sub_domain_id: initialValue.sub_domain_id,
      alias_name: initialValue.alias_name??"",
      sub_domain_name: initialValue.sub_domain_name ?? "",
      sub_domain_code: initialValue.sub_domain_code,
      sort_order: initialValue.sort_order,
      sub_domain_description: initialValue.sub_domain_description
        ? initialValue.sub_domain_description
        : "",
      domain_id: initialValue.domain_id,
      visibility_status: initialValue.visibility_status,
      // sub_domain_meta:initialValue.sub_domain_meta??[],
    },
    validationSchema: validation,
    onSubmit: (values) => {
      onSubmit(values);
    },
  });
  // console.log(initialValue,"initialValue");
  // console.log(formik.values,"formik");
  useEffect(() => {
    if (initialValue.sub_domain_id) {
      let value = {
        sub_domain_id: initialValue.sub_domain_id,
        sub_domain_name: initialValue.sub_domain_name,
        // alias_name: initialValue.alias_name,
        sub_domain_code: initialValue.sub_domain_code,
        domain_id: initialValue.domain_id,
        sort_order: initialValue.sort_order,
        visibility_status: initialValue.visibility_status,
      };
      if(initialValue.alias_name){
        value.alias_name= initialValue.alias_name;
      }
      if (
        initialValue.sub_domain_image_urls &&
        initialValue.sub_domain_image_urls.length > 0
      ) {
        value.sub_domain_image_urls = initialValue.sub_domain_image_urls;
      }
      if (
        initialValue.sub_domain_meta &&
        initialValue.sub_domain_meta.length > 0
      ) {
        value.sub_domain_meta = initialValue.sub_domain_meta;
      }
      if (
        initialValue.sub_domain_description &&
        initialValue.sub_domain_description.length > 0
      ) {
        if (getDescription(initialValue.sub_domain_description, "Web")) {
          value.description = getDescription(
            initialValue.sub_domain_description,
            "Web"
          ).description;
        }
        if (getDescription(initialValue.sub_domain_description, "Mobile")) {
          value.mobile_description = getDescription(
            initialValue.sub_domain_description,
            "Mobile"
          ).description;
        }
      }
      formik.setValues(value);
    }
  }, [initialValue, isOpen]);

  const onClosed = () => {
    onClose();
    formik.resetForm();
    formik.setSubmitting(false);
    setOpen(false);
    // setImage({});
  };
  const onSubmit = async (values) => {
    const {
      sub_domain_id,
      sub_domain_name,
      domain_id,
      description,
      mobile_description,
      visibility_status,
      sort_order,
      sub_domain_image_urls,
      alias_name,
      sub_domain_meta,
    } = values;
    const update_request = {
      sub_domain_id,
    };
    if (sub_domain_name !== initialValue.sub_domain_name) {
      update_request.sub_domain_name = sub_domain_name;
    }
    if (initialValue.alias_name !== alias_name) {
      update_request.alias_name = alias_name;
    }
    if (initialValue.domain_id !== domain_id) {
      update_request.domain_id = domain_id;
    }
    if (initialValue.sort_order != sort_order) {
      update_request.sort_order = Number(sort_order);
    }
    if (initialValue.visibility_status !== visibility_status) {
      update_request.visibility_status = visibility_status;
    }
    let sub_domain_description = [];
     if (description) {
      sub_domain_description.push({
        client_type: "Web",
        description: description !== "" ? description:"",
      });
      }
      if (mobile_description) {
      sub_domain_description.push({
        client_type: "Mobile",
        description: mobile_description!==""?mobile_description:"",
      });
      }
    if (sub_domain_description.length > 0)
      update_request.sub_domain_description = sub_domain_description;

      if (sub_domain_meta&&sub_domain_meta.length > 0){
        update_request.sub_domain_meta = sub_domain_meta;
      }

    if (sub_domain_image_urls && sub_domain_image_urls.length > 0) {
      update_request.sub_domain_image_urls = sub_domain_image_urls.map((x) => {
        delete x.index;
        return removeEmptyObjValue(x);
      });
    }else{
      update_request.sub_domain_image_urls = [];
    }
    //Temp
    // delete update_request.domain_id;
    // console.log("update_request", update_request);

    try {
      const { data } = await graphHandler().invoke(
        UPDATE_SUB_DOMAIN_GQL,
        { update_request },
        1,
        true
      );
      onDone();
      refresh();
      // onClosed();
      setLoading(false);
      formik.setSubmitting(false);
      // console.log("update data", data);
    } catch (error) {
      console.log("updateSubDomain error", error);
      formik.setSubmitting(false);
      setLoading(false);
    }
  };

  const handleError = () => {
    if (Object.keys(formik.errors).length > 0) {
      toast.error(formik.errors[Object.keys(formik.errors)[0]]);
    }
  };

  return (
    <>
      <div className="container p-5 position-relative">
        {isEditable && (
          <button
            type="button"
            className="btn btn-link position-absolute"
            style={{ top: "0px", right: "60px" }}
            onClick={() => setEdit((x) => !x)}
          >
            {canEdit ? (
              <>
                <i className="fas fa-times"></i> Cancel
              </>
            ) : (
              <>
                <i className="fas fa-pen"></i> Edit
              </>
            )}
          </button>
        )}
        <div className="row  justify-content-center">
          <div className="col-md-6">
            <div className="row">
              <div className="col-md-12 form-group">
                <label
                  htmlFor="sub_domain_name"
                  className="col-form-label w-100 mb-2"
                >
                  Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="sub_domain_name"
                  name="sub_domain_name"
                  // maxLength="30"
                  disabled={!canEdit}
                  value={
                    formik.values && formik.values.sub_domain_name
                      ? formik.values.sub_domain_name
                      : ""
                  }
                  onChange={(e) =>
                    formik.setFieldValue(
                      "sub_domain_name",
                      e.target.value.trimStart()
                    )
                  }
                  onBlur={(e)=>{
                    formik.setFieldTouched("sub_domain_name", true)
                    formik.setFieldValue("sub_domain_name", e.target.value.replace(/\s+/g,' ').trim())
                  }}
                />
                <small className="text-danger">
                  {formik.touched.sub_domain_name &&
                  formik.errors &&
                  formik.errors.sub_domain_name
                    ? formik.errors.sub_domain_name
                    : ""}
                </small>
              </div>
              <div className="col-md-12 form-group">
                <label
                  htmlFor="alias_name"
                  className="col-form-label w-100 mb-2"
                >
                  Alias Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="alias_name"
                  name="alias_name"
                  disabled={!canEdit}
                  value={
                    formik.values && formik.values.alias_name
                      ? formik.values.alias_name
                      : ""
                  }
                  // onChange={formik.handleChange}
                  onChange={(e)=>formik.setFieldValue("alias_name", e.target.value.trimStart())}
                  // onBlur={formik.handleBlur}
                  onBlur={(e)=>{
                    formik.setFieldTouched("alias_name", true)
                    formik.setFieldValue("alias_name", e.target.value.replace(/\s+/g,' ').trim())
                  }}
                />
                <small className="text-danger">
                  {formik.touched.alias_name &&
                  formik.errors &&
                  formik.errors.alias_name
                    ? formik.errors.alias_name
                    : ""}
                </small>
              </div>
              <div className="col-md-12 form-group">
                <label
                  htmlFor="sub_domain_code"
                  className="col-form-label w-100 mb-2"
                >
                  Code
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="sub_domain_code"
                  name="sub_domain_code"
                  onChange={formik.handleChange}
                  value={
                    formik.values.sub_domain_code
                      ? formik.values.sub_domain_code
                      : ""
                  }
                  disabled={initialValue.sub_domain_id}
                  onBlur={formik.handleBlur}
                />
                <small className="text-danger align-self-end">
                  {formik.touched.sub_domain_code &&
                  formik.errors &&
                  formik.errors.sub_domain_code
                    ? formik.errors.sub_domain_code
                    : ""}
                </small>
              </div>
              <div className="col-md-12 form-group">
                <label
                  htmlFor="domain_id"
                  className="col-form-label w-100 mb-2"
                >
                  Domains
                </label>
                <select
                  name="domain_id"
                  disabled={!canEdit}
                  className="form-control"
                  id="assign-roles"
                  onChange={formik.handleChange}
                  value={formik.values.domain_id ? formik.values.domain_id : ""}
                  onBlur={formik.handleBlur}
                >
                  <option key="Select" hidden>
                    Select
                  </option>
                  {domainList.map((x, i) => (
                    <option value={x.domain_id} key={i}>
                      {x.domain_name}
                    </option>
                  ))}
                </select>
                <small className="text-danger">
                  {formik.touched.domain_id &&
                  formik.errors &&
                  formik.errors.domain_id
                    ? formik.errors.domain_id
                    : ""}
                </small>
              </div>
              <div className="col-md-6 form-group">
                <label
                  htmlFor="visibility_status"
                  className="col-form-label w-100 mb-2"
                >
                  Visibility
                </label>
                <select
                  className="form-control"
                  name="visibility_status"
                  id="visibility_status"
                  onChange={formik.handleChange}
                  disabled={!canEdit}
                  value={
                    formik.values.visibility_status
                      ? formik.values.visibility_status
                      : ""
                  }
                  onBlur={formik.handleBlur}
                >
                  <option hidden value="">
                    Select
                  </option>
                  {VisibilityStatus.map((x, i) => (
                    <option value={x.value} key={i}>
                      {x.name}
                    </option>
                  ))}
                </select>
                <small className="text-danger">
                  {formik.touched.visibility_status &&
                  formik.errors &&
                  formik.errors.visibility_status
                    ? formik.errors.visibility_status
                    : ""}
                </small>
              </div>
              <div className="col-md-6 form-group">
                <label
                  htmlFor="sort_order"
                  className="col-form-label w-100 mb-2"
                >
                  Sort Order
                </label>
                <div className="d-flex flex-column p-0">
                  <input
                    type="number"
                    className="form-control h-100 w-100"
                    //   placeholder={questionaireList.length + 1}
                    placeholder=""
                    id="sort_order"
                    name="sort_order"
                    max={100}
                    disabled={!canEdit}
                    value={
                      formik.values && formik.values.sort_order
                        ? formik.values.sort_order
                        : ""
                    }
                    onBlur={formik.handleBlur}
                    onChange={(e) =>
                      formik.setFieldValue(
                        "sort_order",
                        e.target.value.replace(/[^0-9\.]/g, "")
                      )
                    }
                  />
                  <small className="text-danger">
                    {formik.touched.sort_order && formik.errors.sort_order
                      ? formik.errors.sort_order
                      : ""}
                  </small>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 ">
            <div className="row">
              <Description formik={formik} disabled={!canEdit} showEditor={true}/>
            </div>
          </div>
          <div className="col-md-12">
            <SingleImageUpload
              formik={formik}
              disabled={!canEdit}
              heading="Image File"
              contentId={initialValue.sub_domain_id}
              contentType="SUBDOMAIN"
              parentArray="sub_domain_image_urls"
              mediaType="Image"
            />
          </div>
          <div className="col-md-12">
          <MetaInput
            formik={formik}
            parentArray="sub_domain_meta"
            typeKey="type"
            titleKey="key"
            valueKey="value"
            metaType={"Subdomain"}
            canEdit={canEdit}
            constType="ReportDescription"
          />
          </div>
          <div className={"d-flex justify-content-end col-md-12"}>
            <button
              onClick={onClosed}
              type="button"
              className="btn btn-danger btn-sm float-right btn-add-domain mr-2"
            >
              Close
            </button>
            {canEdit ? (
              <CustomButton
                type="button"
                className="btn btn-primary btn-outline-success float-right btn-add-domain"
                disabled={
                  Object.keys(formik.errors).length !== 0 ||
                  !formik.isValid ||
                  formik.isSubmitting
                }
                onClick={() => {
                  formik.isValid && Object.keys(formik.values).length !== 0
                    ? formik.handleSubmit()
                    : handleError();
                }}
              >
                Update
              </CustomButton>
            ) : (
              <button
                onClick={onDone}
                type="button"
                className="btn btn-secondary btn-sm float-right btn-add-domain mr-2"
              >
                Next
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

const validation = Yup.object().shape({
  alias_name: Yup.string().trim(),
  sub_domain_code: Yup.string().required("Enter a code"),
  description: Yup.string().required("Enter a Description"),
  mobile_description: Yup.string().required("Enter a Mobile Description"),
  sub_domain_name: Yup.string().trim().required("Enter a name"),
  visibility_status: Yup.string().required("Select visibility"),
  // .min(4, "Name should be min of 4 characters")
  // .max(30, "Name should be maximum of 30 characters")
  sort_order: Yup.string().required("Enter sort order"),
  domain_id: Yup.string().required("Select a domain"),
});
