import { toast } from "react-toastify";
import { isDocument, isImage, isPDF, isVideo } from "../../constants";
import { uploadHandler } from "../../utils/CustomHooks/useUploadImage";
import { formatBytes } from "../../utils/Functions/filter";

const CreateMediaFileUpload = (props) => {
  let { formik, name, id, contentId, contentType, type, mediaType } = props;
  const handleFileChange = async (event) => {
    const { name, files } = event.target;
    if (!files) return;
    let options = { ...formik.values[name] };
    if (!files[0]) return;
    const file = new File([files[0]], files[0].name.replace(/\s/g, ""), {
      type: files[0].type,
    });
    if (
      (mediaType === "Image" && !isImage(file)) ||
      (mediaType === "Video" && !isVideo(file)) ||
      (mediaType === "Document"&&formik?.values?.document_type === "Document" && !isDocument(file)) ||
      (mediaType === "Document"&&formik?.values?.document_type === "PDF" && !isPDF(file))
    ) {
      return toast.warn(`Invalid ${mediaType}`);
    }
    options = { isUpload: true };
    options.size = formatBytes(files[0].size);
    options.name = files[0].name;
    formik.setFieldValue(name, options);
    uploadToCloud(file, options, name);
  };
  async function uploadToCloud(file, obj, name) {
    let options = { ...obj };
    let fileType =
      mediaType === "Image" && isImage(file)
        ? "Image"
        : mediaType === "Video" && isVideo(file)
        ? "Video"
        : "Document";
    let content = contentType.toLowerCase();
    try {
      let data = await uploadHandler(content).upload(
        file,
        fileType,
        contentId,
        contentType
      );
      options.name = file.name;
      options.url = data.url;
      options.isUpload = false;
      options.progress = 100;
      options.type = fileType;
      // console.log("Uploading then", options);
      formik.setFieldValue(name, options);
    } catch (error) {
      console.log("Upload error", error);
      // options.splice(i, 1);
      toast.error(error);
      formik.setFieldValue(name, undefined);
    }
  }
  const removeMedia = (key = "media") => {
    // let options = [
    //   ...(formik.values[key] ? formik.values[key] : []).map((x) => x),
    // ];
    // options.splice(i, 1);
    formik.setFieldValue(key, undefined);
  };
  return (
    <div className="input-group  form-group col-md-12 d-flex align-items-start justify-content-start">
      <div className="d-flex w-100 flex-row justify-content-between">
        <button
          id="custom-file"
          onClick={() => document.getElementById(id).click()}
        >
          Upload File
        </button>
      </div>
      <input
        type="file"
        className="custom-file-input d-none"
        id={id}
        name={name}
        onBlur={formik.handleBlur}
        onClick={(e)=>e.target.value =null}
        onChange={(e) => handleFileChange(e)}
      />
      {formik.values && formik.values[name] ? (
        <div className="upload__list col-md-12 mt-2">
          <div className="upload__info flex">
            {formik.values[name].type === "Video" || mediaType === "Video" ? (
              <video
                src={formik.values[name].url}
                className="upload__img"
              ></video>
            ) : formik.values.media_format === "Image" ||
              mediaType === "Image" ||
              formik.values[name].type === "Image" ? (
              <img src={formik.values[name].url} className="upload__img" />
            ) : (
              <i className="fas fa-file mr-5" style={{ fontSize: "60px" }} />
            )}
            <div className="flex w-100 wrap">
              <p className="upload__name">
                {formik.values[name].name || "Uploaded " + mediaType}
              </p>
              {formik.values[name].name && formik.values[name].size != 0 && (
                <p className="upload__size">
                  ( {formik.values[name].size || formik.values.size} )
                </p>
              )}
              {formik.values[name].name &&
                formik.values[name].isUpload &&
                formik.values[name].progress != 0 && (
                  <p className="upload__long">
                    {" "}
                    {formik.values[name].progress || formik.values.progress}%
                  </p>
                )}
              {formik.values[name].name &&
                formik.values[name].progress == 100 && (
                  <p className="upload__long text-success">Done</p>
                )}
              <a className="upload__delete upload__btn">
                <i
                  className="fas fa-minus-circle"
                  onClick={() => removeMedia(name)}
                />
              </a>
              {formik.values[name].name &&
                formik.values[name].isUpload &&
                formik.values[name].progress != 0 &&
                formik.values[name].progress != 100 && (
                  <div className="upload__progress">
                    <div
                      className="upload__bar"
                      style={{
                        width:
                          (formik.values[name].progress ||
                            formik.values.progress) + "%",
                      }}
                    />
                  </div>
                )}
            </div>
          </div>
        </div>
      ) : null}
      <small className="text-danger col-md-12 mt-1">
        {formik.errors && formik.errors[name] ? typeof formik.errors[name]==="string"?
        formik.errors[name]:formik.errors[name]?.url
         : ""}
      </small>
    </div>
  );
};

export default CreateMediaFileUpload;
