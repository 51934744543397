import { Popconfirm } from "antd";
// import React from "react";
// import { useEffect } from "react";
// import useTherapyAPI from "../../../utils/CustomHooks/useTherapyAPI";
import graphHandler from "../../../Apollo/graphHandler";
import { UPDATE_MODULE_ACTION_GQL } from "../../../Apollo/AdminServices/MutationRequest";
import { useEffect } from "react";
// import { getDescription } from "../../../utils/Functions/filter";

const TherapyActionListItem = ({
  refresh,
  item = {},
  isEditable,
  onEdit,
  interventionList = [],
  screenerList = [],
  moduleList = [],
  setHistory = () => {},
}) => {
  const { pending_history } = item;
  // const hasPublished =
  //   pending_history &&
  //   pending_history.publish_data &&
  //   pending_history.publish_data.created_by;
  // const { removeActions, loading } = useTherapyAPI("");

  // console.log("interventionList",interventionList)
  const getData = (type) => {
    if (type === "Intervention") {
      return interventionList.length > 0 &&
        interventionList.find((x) => x.intervention_id === item.intervention_id)
        ? interventionList.find(
            (x) => x.intervention_id === item.intervention_id
          ).intervention_name
        : type;
    } else if (type === "Document") {
      return item.therapy_module_document_url
        ? item.therapy_module_document_url.name
        : type;
    } else if (type === "Video") {
      return item.therapy_module_video_url
        ? item.therapy_module_video_url.video_type
        : type;
    } else if (type === "Screener") {
      return screenerList.length > 0 &&
        screenerList.find((x) => x.screener_id === item.screener_id)
        ? screenerList.find((x) => x.screener_id === item.screener_id)
            .screener_name
        : type;
    } else {
      return type;
    }
  };

  useEffect(() => {
    if (isEditable && pending_history?.updated_data?.active === false) {
      setHistory(Boolean(pending_history && pending_history.updated_data));
    }
  }, [pending_history]);
  
  const onDelete = async () => {
    const { therapy_module_action_id } = item;
    // if (loading) return;
    onEdit({});
    try {
      // removeActions(therapy_module_action_id , refresh);
      await graphHandler().invoke(
        UPDATE_MODULE_ACTION_GQL,
        {
          update_request: {
            active: false,
            therapy_module_action_id,
          },
        },
        1,
        true
      );
      refresh();
    } catch (error) {
      console.log("therapyGroup error", error);
    }
  };
  // console.log("item",item)
  const getModuleName = (id) => {
    let data = moduleList.find((x) => x.value === id);
    return data && data?.label ? data?.label : "-";
  };
  return (
    <tr
      className={`${
        pending_history?.updated_data?.active === false ? "bad" : ""
      } option-table`}
    >
      <td className="text-center">{item.therapy_module_action_name || "-"}</td>
      <td className="text-center">
        {getModuleName(item?.therapy_module_id) || "-"}
      </td>
      <td className="text-center">{item.therapy_action_type || "-"}</td>
      <td className="text-center">{getData(item.therapy_action_type)}</td>
      <td className="text-center">{item.sort_order}</td>

      <td className="d-flex align-items-center justify-content-center">
        {/* { hasPublished && (
          <span className="badge badge-error float-right">Changes</span>
        )} */}
        {isEditable && pending_history?.updated_data?.active !== false ? (
          <>
            <button
              type="button"
              className="ml-2 btn btn-outline-success btn-sm "
              onClick={() => onEdit(item)}
            >
              Edit
            </button>
            <Popconfirm
              title="Are you sure to delete this action?"
              onConfirm={onDelete}
              okText="Yes"
              cancelText="No"
            >
              <i className="ml-2 fas fa-trash cursor-pointer"></i>
            </Popconfirm>
          </>
        ) : (
          "-"
        )}
      </td>
    </tr>
  );
};

export default TherapyActionListItem;
