import { useEffect, useState } from "react";
import { getImage } from "../../../utils/Functions/filter";
import defaultImg from "../../../assets/Images/Blog/banner2.png";
import PlayIcon from "../../../assets/Images/Blog/play-button.png";

export default function Banner({
  value = {},
  isPreview = false,
  onDelete = () => {},
  onEdit = () => {},
}) {
  //   console.log(value);
  const [isPlaying, setPlaying] = useState(false);
  useEffect(() => {
    const bannerVideo = document.getElementById(value.id);
    if (!bannerVideo) return;
    if (isPlaying) {
      bannerVideo.play();
    } else {
      bannerVideo.pause();
    }
  }, [isPlaying]);
  const image_urls = value.image_urls;
  const video = image_urls ? getImage(image_urls, "Video") : false;
  const image = !video ? getImage(image_urls) : false;
  return (
    <section className="wit-section-top">
      <div className="container">
        <div className="privacy-banner">
          {video && video.url ? (
            <>
              <video
                src={video.url}
                onClick={() => setPlaying((x) => !x)}
                id={value.id}
                loop
                muted
              />
              {!isPlaying && (
                <img
                  className="play-button"
                  src={PlayIcon}
                  onClick={() => setPlaying((x) => !x)}
                />
              )}
            </>
          ) : (
            <img
              src={image && image.url ? image.url : defaultImg}
              alt="Privcybanner"
            />
          )}
        </div>
      </div>
      <div className="tool-tip">
        <button onClick={() => onEdit(value)}>
          <i className="fas fa-edit" />
        </button>
        <button onClick={() => onDelete(value)}>
          <i className="fas fa-trash" />
        </button>
      </div>
    </section>
  );
}
