import React, { useEffect, useState } from "react";
import { Collapse, Card, CardBody } from "reactstrap";
import useModuleActions from "../../../utils/CustomHooks/useModuleActions";
import { getDescription } from "../../../utils/Functions/filter";
import ActionListItem from "./ActionListItem";
export default function ModuleTableItem({
  item,
  refresh = () => {},
  interventionList = [],
  screenerList = [],
}) {
  const [toggle, setToggle] = useState(false);
  const { actionList, loading, getActionList } = useModuleActions();
  useEffect(() => {
    if (item.therapy_id && item.therapy_module_id) {
      getActionList({
        therapy_id: item.therapy_id,
        therapy_module_id: item.therapy_module_id,
      });
    }
  }, [item]);

  return (
    <>
      <tr onClick={() => setToggle((x) => !x)}>
        <td className="">{item.therapy_module_name}</td>
        <td className="">
          {item.therapy_module_description &&
          getDescription(item.therapy_module_description, "Web")
            ? getDescription(item.therapy_module_description, "Web").description
            : "-"}
        </td>
        <td className="">
          {item.therapy_module_sessions
            ? item.therapy_module_sessions.length
            : 0}
        </td>
        <td className="">{item.sort_order}</td>
        {/* <td className="" onClick={() => setIsOpen(true)}>
          <i className="fas fa-edit" />
        </td> */}
      </tr>
      <tr>
        <td colSpan="5" className={toggle ? "p-1" : "p-0"}>
          <Collapse isOpen={toggle}>
            <Card>
              <CardBody>
                <div className="table-responsive">
                  <table
                    className="table table-borderless mb-0"
                    style={{ maxHeight: "200px" }}
                  >
                    <thead>
                      <tr className=" ">
                        <td className="text-center">Action Name</td>
                        <td className="text-center">Action Type</td>
                        <td className="text-center">Data</td>
                        <td className="text-center">Order</td>
                      </tr>
                    </thead>
                    <tbody>
                      {!loading && item && actionList.length > 0 ? (
                        actionList
                          .slice()
                          .sort((a, b) => a.sort_order - b.sort_order)
                          .map((x, i) => (
                            <ActionListItem
                              key={i}
                              item={x}
                              interventionList={interventionList}
                              screenerList={screenerList}
                              actionList={actionList}
                            />
                          ))
                      ) : (
                        <tr className="border-bottom">
                          <td className="text-center" colSpan="6">
                            {loading ? "Loading..." : "No Actions Found."}
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </CardBody>
            </Card>
          </Collapse>
        </td>
      </tr>
    </>
  );
}
