import { useFormik } from "formik";
import { useEffect } from "react";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { CREATE_TOPIC_GQL } from "../../../Apollo/AdminServices/MutationRequest";
import graphHandler from "../../../Apollo/graphHandler";
import CustomButton from "../../../components/Button/CustomButton";
import MultiSelect from "../../../components/MultiSelect/MultiSelect";

export default function CreateTopicSection({
  values = {},
  topicList = [],
  setLoading = () => {},
  refresh = () => {},
  refreshQues = () => {},
  domainList = [],
}) {
  const formik = useFormik({
    initialValues: {},
    validationSchema: validation,
    onSubmit: (values) => {
      onSubmit(values);
    },
  });
  useEffect(() => {
    refresh();
    refreshQues();
  }, []);

  const onSubmit = (formikValues) => {
    formik.setSubmitting(true);
    setLoading(true);
    const {
      screener_topic_title,
      domain_id,
      screener_result_calculation_type,
      screener_topic_aggregate_type,
    } = formikValues;
    let request = {
      screener_id: values.screener_id,
      sort_order: topicList.length,
      render_topic: values.sort_type === "Topic",
      screener_topic_title,
    };
    if (domain_id) {
      request.domain_id = domain_id;
    }
    if (screener_result_calculation_type) {
      request.screener_result_calculation_type =
        screener_result_calculation_type;
    }
    if (
      screener_topic_aggregate_type &&
      screener_topic_aggregate_type.length > 0
    ) {
      request.screener_topic_aggregate_type = screener_topic_aggregate_type.map(
        (x) => x.value
      );
    }
    create(request);
  };

  const create = async (create_request) => {
    formik.setSubmitting(true);
    try {
      const { data } = await graphHandler().invoke(
        CREATE_TOPIC_GQL,
        { create_request },
        1,
        false,
        true
      );
      if (
        data &&
        data.createScreenerTopic &&
        data.createScreenerTopic.screener_topic
      ) {
        // console.log(
        //   "createScreenerTopic Success",
        //   data.createScreenerTopic.screener_topic
        // );
        refresh();
        setLoading(false);
        toast.success("Created Successfully");
        formik.setSubmitting(false);
        resetModal();
      }
    } catch (error) {
      console.error("createScreenerTopic Error", error);
      setLoading(false);
      formik.setSubmitting(false);
    }
  };

  const handleError = () => {
    if (Object.keys(formik.errors).length > 0) {
      toast.error(formik.errors[Object.keys(formik.errors)[0]]);
    }
  };
  const resetModal = () => {
    formik.resetForm();
    formik.setValues({});
    formik.setSubmitting(false);
  };

  return (
    <>
      <div className="col-md-12 modal-header" id="CreateTopic">
        <h5 className="modal-title text-center mb-3">Create Topics</h5>
      </div>
      <div className="col-md-12">
        <div className="  border">
          <div className="row p-3">
            <div className="col-md-6 form-group">
              <label
                htmlFor="screener_topic_title"
                className="col-form-label w-100 mb-2"
              >
                Title
              </label>
              <input
                type="text"
                className="form-control"
                id="screener_topic_title"
                name="screener_topic_title"
                // maxLength="30"
                value={
                  formik.values && formik.values.screener_topic_title
                    ? formik.values.screener_topic_title
                    : ""
                }
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <small className="text-danger">
                {formik.touched.screener_topic_title &&
                formik.errors &&
                formik.errors.screener_topic_title
                  ? formik.errors.screener_topic_title
                  : ""}
              </small>
            </div>
            <div className="col-md-6 form-group">
              <label
                htmlFor="screener_topic_aggregate_type"
                className="col-form-label w-100 mb-2"
              >
                Aggregation Type
              </label>
              <MultiSelect
                option={[
                  { label: "Domain", value: "Domain" },
                  { label: "Topic", value: "Topic" },
                  //   { label: "Screener", value: "Screener" },
                ]}
                onChange={(e) =>
                  formik.setFieldValue("screener_topic_aggregate_type", e)
                }
                placeholder="Select Aggregation Type"
                isCreatable={false}
                isMulti={true}
                type="Type"
                name="screener_topic_aggregate_type"
                id="screener_topic_aggregate_type"
                onBlur={formik.handleBlur}
                defaultValue={
                  formik.values.screener_topic_aggregate_type
                    ? formik.values.screener_topic_aggregate_type
                    : "Reset"
                }
              />
              <small className="text-danger">
                {formik.touched.screener_topic_aggregate_type &&
                formik.errors &&
                formik.errors.screener_topic_aggregate_type
                  ? formik.errors.screener_topic_aggregate_type
                  : ""}
              </small>
            </div>

            <div className="col-md-6 form-group">
              <label
                htmlFor="screener_result_calculation_type"
                className="col-form-label w-100 mb-2"
              >
                Calculation Type
              </label>
              <select
                id="screener_result_calculation_type"
                name="screener_result_calculation_type"
                className="custom-select"
                onChange={formik.handleChange}
                value={
                  formik.values.screener_result_calculation_type
                    ? formik.values.screener_result_calculation_type
                    : ""
                }
                onBlur={formik.handleBlur}
              >
                <option key="Select" hidden>
                  Select
                </option>
                <option value="Average">Average</option>
                <option value="Total">Total</option>
              </select>
              <small className="text-danger">
                {formik.touched.screener_result_calculation_type &&
                formik.errors &&
                formik.errors.screener_result_calculation_type
                  ? formik.errors.screener_result_calculation_type
                  : ""}
              </small>
            </div>

            <div className="col-md-6 form-group">
              <label htmlFor="domain_id" className="col-form-label w-100 mb-2">
                Domain
              </label>
              <select
                name="domain_id"
                className="form-control"
                id="domain_id"
                onChange={formik.handleChange}
                value={formik.values.domain_id ? formik.values.domain_id : ""}
                onBlur={formik.handleBlur}
              >
                <option key="Select" hidden>
                  Select
                </option>
                {domainList.map((x, i) => (
                  <option value={x.domain_id} key={i}>
                    {x.domain_name}
                  </option>
                ))}
              </select>
              <small className="text-danger">
                {formik.touched.domain_id &&
                formik.errors &&
                formik.errors.domain_id
                  ? formik.errors.domain_id
                  : ""}
              </small>
            </div>

            <div
              className={"d-flex align-items-end justify-content-end col-md-12"}
            >
              <CustomButton
                type="button"
                className="btn btn-primary btn-outline-success btn-sm float-right btn-add-domain"
                disabled={
                  Object.keys(formik.errors).length !== 0 ||
                  !formik.isValid ||
                  formik.isSubmitting
                }
                onClick={
                  formik.isValid && Object.keys(formik.values).length !== 0
                    ? formik.handleSubmit
                    : handleError
                }
              >
                Create
              </CustomButton>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const validation = Yup.object().shape({
  //   screener_topic_descriptions: Yup.string().required(
  //     "Screener description is required"
  //   ),
  //   mobile_description: Yup.string().required(
  //     "Screener mobile description is required"
  //   ),
  // .min(4, "Description should be min of 4 characters")
  // .max(150, "Description should be maximum of 150 characters")
  screener_topic_title: Yup.string().trim().required("Enter title"),
  // .min(4, "Name should be min of 4 characters")
  // .max(30, "Name should be maximum of 30 characters")
  domain_id: Yup.string(),
  screener_result_calculation_type: Yup.string(),
  //   .required(
  //     "Select calculation type"
  //   ),
  screener_topic_aggregate_type: Yup.array().of(
    Yup.object().shape({
      label: Yup.string(),
      value: Yup.string(),
    })
  ),
  // .test({
  //   message: "Select aggregate type",
  //   test: (arr) => {
  //     return arr && arr.length > 0;
  //   },
  // }),
});
