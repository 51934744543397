import { useState } from "react";
import UpdateFunction from "../modal/UpdateFunction";
// import { Link } from "react-router-dom";
import { Popconfirm } from "antd";
import useRemoveAPI from "../../../utils/CustomHooks/useRemoveAPI";

export default function ListItem({
  item,
  refresh,
  access = {},
  industryList = [],
}) {
  const [isEdit, setEdit] = useState(false);
  const { removeFunction } = useRemoveAPI();
  const onDelete = () => {
    removeFunction({ function_id: item.function_id }, refresh);
  };

  // const getIndustryName =()=>{
  //   if(industryList&& industryList.length>0){
  //    return industryList.find((x)=>x.industry_id === item.industry_id)
  //   }
  // }

  return (
    <tr>
      <td className="col-sm-2">{item.function_name??"-"}</td>
      {/* <td className="col-sm-2">{getIndustryName()?.industry_name??"-"}</td> */}
      <td>
        <span
          className={`${item.active ? "good" : "bad"} text-center btn btn-sm`}
        >
          {item.active ? "Active" : "Disabled"}
        </span>
      </td>
      {access.canEdit && (
        <td>
        <button
          type="button"
          className="btn btn-outline-success btn-sm "
          onClick={() => setEdit(true)}
        >
          Edit
        </button>
      </td>
      )}
      <td>
        <Popconfirm
          title="Are you sure to delete this Function?"
          onConfirm={onDelete}
          //  onCancel={cancel}
          okText="Yes"
          cancelText="No"
        >
          <i
            className="ml-2 fas fa-trash cursor-pointer"
          // onClick={onDelete}
          ></i>
        </Popconfirm>
      </td>
      <UpdateFunction
        isOpen={isEdit}
        setOpen={() => setEdit(false)}
        initialValue={item}
        refresh={refresh}
        industryList={industryList}
      />
    </tr>
  );
}
