import { useFormik } from "formik";
import { useEffect } from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import * as Yup from "yup";
import {
  CREATE_RELATED_CONTENT_GQL,
  UPDATE_RELATED_CONTENT_GQL,
} from "../../../Apollo/AdminServices/RelatedContentMutationRequest";
import graphHandler from "../../../Apollo/graphHandler";
import CustomButton from "../../Button/CustomButton";
import Description from "../../Input/Description";
import { RelatedContentTypes } from "../../../constants";
import { getDescription } from "../../../utils/Functions/filter";

export default function RelatedContentAction({
  refresh = () => {},
  setLoading = () => {},
  contentType,
  contentId,
  relatedcontent,
  subDomains = [],
  categoryList = [],
  interventionList = [],
  therapyList = [],
  blogList = [],
  planList = [],
  reset = () => {},
  types = RelatedContentTypes.map((x) => x.value),
  showDesc = false,
}) {
  const [options, setOptions] = useState([]);

  const formik = useFormik({
    initialValues: {},
    validationSchema: Yup.object().shape({
      related_content_id: Yup.string().required("Select a Content"),
      related_content_type: Yup.string().required("Select a Content type"),
      description: showDesc
        ? Yup.string().required("Description is mandatory")
        : Yup.string(),
      mobile_description: showDesc
        ? Yup.string().required("Mobile description is mandatory")
        : Yup.string(),
    }),
    onSubmit: (values) => {
      onSubmit(values);
    },
  });
  useEffect(() => {
    if (relatedcontent && relatedcontent.related_content_row_id) {
      let value = {
        ...relatedcontent,
        related_content_id: relatedcontent.related_content_id,
        related_content_type: relatedcontent.related_content_type,
      };
      if (
        relatedcontent.related_content_descriptions &&
        relatedcontent.related_content_descriptions.length > 0
      ) {
        if (
          getDescription(relatedcontent.related_content_descriptions, "Web")
        ) {
          value.description = getDescription(
            relatedcontent.related_content_descriptions,
            "Web"
          ).description;
        }
        if (
          getDescription(relatedcontent.related_content_descriptions, "Mobile")
        ) {
          value.mobile_description = getDescription(
            relatedcontent.related_content_descriptions,
            "Mobile"
          ).description;
        }
      }
      formik.setValues(value);
    }
  }, [relatedcontent]);

  let contentTypes = types
    .map((x) => RelatedContentTypes.find((y) => y.value === x))
    .filter((x) => x.value);

  useEffect(() => {
    if (formik.values.related_content_type) {
      let arr = [];
      switch (formik.values.related_content_type) {
        case "SUBDOMAIN":
          arr = subDomains.map((x) => ({
            label: x.sub_domain_name,
            value: x.sub_domain_id,
          }));
          break;
        case "CATEGORY":
          arr = categoryList.map((x) => ({
            label: x.category_name,
            value: x.category_id,
          }));
          break;
        case "INTERVENTION":
          arr = interventionList.map((x) => ({
            label: x.intervention_name,
            value: x.intervention_id,
          }));
          break;
        case "THERAPY":
          arr = therapyList.map((x) => ({
            label: x.therapy_name,
            value: x.therapy_id,
          }));
          break;
        case "BLOG":
          arr = blogList.map((x) => ({
            label: x.blog_name,
            value: x.blog_id,
          }));
          break;
        case "PLAN":
          arr = planList.map((x) => ({
            label: x.plan_name,
            value: x.plan_id,
          }));
          break;
        default:
          break;
      }
      setOptions(arr);
    }
  }, [formik.values.related_content_type]);

  const onSubmit = async (value) => {
    setLoading(true);
    formik.setSubmitting(true);
    let createReq = {
      content_id: contentId,
      content_type: contentType,
      related_content_id: value.related_content_id,
      related_content_type: value.related_content_type,
    };
    let content_description = [];
    if (value.description) {
      content_description.push({
        client_type: "Web",
        description: value.description,
      });
    }
    if (mobile_description) {
      content_description.push({
        client_type: "Mobile",
        description: value.mobile_description,
      });
    }
    if (content_description.length > 0) {
      createReq.related_content_descriptions = content_description;
    }
    let request = value.related_content_row_id
      ?{update_request:{...createReq,related_content_row_id:value.related_content_row_id}}
      : {createReq};
      // console.log("request",request)
    try {
      await graphHandler().invoke(
        value.related_content_row_id
          ? UPDATE_RELATED_CONTENT_GQL
          : CREATE_RELATED_CONTENT_GQL,
        request,
        1,
        true
      );
      // toast.success("Created Successfully");
      refresh();
      setLoading(false);
      formik.setSubmitting(false);
      resetModal();
    } catch (error) {
      console.log("create error", error);
      setLoading(false);
      formik.setSubmitting(false);
    }
  };
  const handleError = () => {
    if (Object.keys(formik.errors).length > 0) {
      toast.error(formik.errors[Object.keys(formik.errors)[0]]);
    }
  };
  const resetModal = () => {
    formik.resetForm();
    formik.setValues({});
    formik.setSubmitting(false);
    reset();
  };

  return (
    <>
      <div className="col-md-12 modal-header" id="CreateTopic">
        <h5 className="modal-title text-center mb-3">
          {formik.values.related_content_row_id ? "Update" : "Create"} Related
          Content
        </h5>
      </div>
      <div className="col-md-12">
        <div className="  border">
          <div className="row p-3">
            <div className="col-md-6">
              <div className="row">
                <div className="col-md-12 form-group ">
                  <label
                    htmlFor="related_content_type"
                    className="col-form-label w-100 mb-2"
                  >
                    Content Type
                  </label>
                  <select
                    id="related_content_type"
                    name="related_content_type"
                    className="custom-select"
                    onChange={(e) => {
                      formik.setFieldValue("related_content_id", "");
                      formik.setFieldValue(
                        "related_content_type",
                        e.target.value
                      );
                    }}
                    value={
                      formik.values.related_content_type
                        ? formik.values.related_content_type
                        : ""
                    }
                    onBlur={formik.handleBlur}
                  >
                    <option key="Select Type" hidden value="">
                      Select
                    </option>
                    {contentTypes.map((x, i) => (
                      <option key={i} value={x.value}>
                        {x.label}
                      </option>
                    ))}
                  </select>
                  <small className="text-danger align-self-end">
                    {formik.touched.related_content_type &&
                    formik.errors.related_content_type
                      ? formik.errors.related_content_type
                      : ""}
                  </small>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="row">
                {formik.values.related_content_type && (
                  <div className="col-md-12 form-group">
                    <label
                      htmlFor="related_content_id"
                      className="col-form-label w-100 mb-2"
                    >
                      {
                        RelatedContentTypes.find(
                          (x) => x.value === formik.values.related_content_type
                        ).label
                      }
                    </label>
                    <select
                      id="related_content_id"
                      name="related_content_id"
                      className="custom-select"
                      onChange={(e) => {
                        formik.setFieldValue(
                          "related_content_id",
                          e.target.value
                        );
                      }}
                      value={
                        formik.values.related_content_id
                          ? formik.values.related_content_id
                          : ""
                      }
                      onBlur={formik.handleBlur}
                    >
                      <option key="Select content" hidden>
                        Select
                      </option>
                      {options.map((x, i) => (
                        <option key={i} value={x.value}>
                          {x.label}
                        </option>
                      ))}
                    </select>
                    <small className="text-danger align-self-end">
                      {formik.touched.related_content_id &&
                      formik.errors.related_content_id
                        ? formik.errors.related_content_id
                        : ""}
                    </small>
                  </div>
                )}
              </div>
            </div>
            {showDesc && <Description formik={formik} col="col-md-6" />}

            <div
              className={"d-flex align-items-end justify-content-end col-md-12"}
            >
              {formik.values.related_content_row_id && (
                <button
                  onClick={resetModal}
                  type="button"
                  className="btn btn-light btn-sm float-right btn-add-domain mr-2"
                >
                  Reset
                </button>
              )}
              <CustomButton
                type="button"
                className="btn btn-primary btn-outline-success btn-sm float-right btn-add-domain"
                disabled={
                  Object.keys(formik.errors).length !== 0 ||
                  !formik.isValid ||
                  formik.isSubmitting
                }
                onClick={
                  formik.isValid && Object.keys(formik.values).length !== 0
                    ? formik.handleSubmit
                    : handleError
                }
              >
                {formik.values.related_content_row_id ? "Update" : "Create"}
              </CustomButton>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
