import React, { useState } from "react";

export default function index({
  children,
  maxLength = 120,
  useChild = true,
  text = "",
  moreComponent,
  canShow = true,
}) {
  const [show, setShow] = useState(false);
  const string = (useChild ? children : text ? text : "").toString();
  const canDo = string.length > maxLength;
  const toggle = () => setShow((x) => !x);
  const getClampedText = (string) => {
    return (
      <>
        {string
          .toString()
          .slice(0, show ? string.toString().length : maxLength)
          .concat(!show && canDo ? "..." : "")}
        {canDo && canShow ? (
          moreComponent ? (
            <moreComponent onClick={toggle}>
              {show ? "Show Less" : "Show More"}
            </moreComponent>
          ) : (
            <label
              className="cursor-pointer font-weight-bold float-right"
              onClick={toggle}
              style={{ color: "#263a5b" }}
            >
              {show ? "Show Less" : "Show More"}
            </label>
          )
        ) : (
          ""
        )}
      </>
    );
  };
  return <>{getClampedText(string)}</>;
}
