import { useEffect, useState } from "react";
import ListItem from "./components/ListItem";
import AddLicense from "./modal/AddLicense";
import PaginationComponent from "../../components/PaginationComponent";
import "../Category/subdomain.css";
import withRoleBased from "../../utils/Functions/withRoleBased";
// import useCountryList from "../../utils/CustomHooks/useCountryList";
import { sortArray } from "../../utils/Functions/filter";
import useLicenseList from "../../utils/CustomHooks/useLicenseList";
import useOrganizationList from "../../utils/CustomHooks/useOrganizationList";

function License({ access }) {
  const [isOpen, setIsOpen] = useState(false);
  const [count, setCount] = useState(0);
  const { organizationList, getOrganizationList } = useOrganizationList("");
  const { licenseList, loading, getLicenseList } = useLicenseList("");
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize] = useState(10);
  const pageCount = Math.ceil(licenseList.length / pageSize);
  const [search, setSearch] = useState("");
  const [isSearching, setIsSearch] = useState(false);
  const [organizationOption, setOrganizationOption] = useState([]);

  useEffect(() => {
    let req = {};
    if (search) req.organization_license_number = search;
    getLicenseList(req);
  }, [count]);

  useEffect(() => {
    getOrganizationList();
  }, []);

  useEffect(() => {
    setOrganizationOption(
      organizationList.map((x) => ({
        label: x.organization_name,
        value: x.organization_id,
      }))
    );
  }, [organizationList]);

  const handleClick = (event, index) => {
    event.preventDefault();
    setCurrentPage(index);
  };

  const onSearch = () => {
    setCurrentPage(0)
    setCount((x) => x + 1);
    setIsSearch(search ? true : false);
  };
  return (
    <main className="page-content">
      <div className="container-fluid">
        <div className="row m-0 w-100">
          <div className="col-md-6 mb-3">
            <h5 className="page-title"> License </h5>
          </div>
          <div className="col-md-6  mb-3 d-flex justify-content-md-end">
            {access.canCreate && (
              <button
                type="button"
                className="btn btn-outline-success "
                onClick={() => setIsOpen(true)}
              >
                Add License
              </button>
            )}
          </div>

          <div className="col-md-4">
            <div className="search" style={{ margin: "16px 0px 32px" }}>
              <input
                type="text"
                className="form-control"
                placeholder="Search"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                onKeyDown={(e) => e.key === "Enter" && onSearch()}
              />
              {!isSearching ? (
                <button onClick={onSearch}>
                  <i className="fa fa-search" />
                </button>
              ) : (
                <button
                  onClick={() => {
                    setSearch("");
                    setIsSearch(false);
                    setCount((x) => x + 1);
                  }}
                >
                  <i className="fa fa-times" />
                </button>
              )}
            </div>
          </div>
          <div className="col-md-12">
            <div className="table-responsive">
              <table className="table domain-table table-borderless ">
                <thead>
                  <tr>
                    <td className="">Organization</td>
                    {/* <td className="">Organization License Id</td> */}
                    <td className="">Organization License No</td>
                    <td className="">Total Seat Count</td>
                    <td className="">license Duration </td>
                    {/* <td className="">Amount</td> */}
                    <td className="">StartDate</td>
                    <td className="">EndDate </td>
                    <td className="">Status </td>
                    {access.canEdit && <td className="">Action</td>}
                  </tr>
                </thead>
                <tbody>
                  {!loading && licenseList && licenseList.length > 0 ? (
                    sortArray(licenseList)
                      .slice(
                        currentPage * pageSize,
                        (currentPage + 1) * pageSize
                      )
                      .map((x, i) => (
                        <ListItem
                          item={x}
                          key={i}
                          index={i}
                          refresh={() => setCount((x) => x + 1)}
                          access={access}
                          organizationOption={organizationOption}
                        />
                      ))
                  ) : (
                    <tr>
                      <td colSpan="7" className="text-center">
                        <span className="font-weight-bold">
                          {loading ? "Loading..." : "Oops! No Data Found"}
                        </span>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {licenseList.length > 0 && pageCount != 1 && (
          <PaginationComponent
            handleClick={handleClick}
            currentPage={currentPage}
            pageCount={pageCount}
          />
        )}
        <AddLicense
          isOpen={isOpen}
          setOpen={() => setIsOpen(false)}
          refresh={() => setCount((x) => x + 1)}
          organizationOption={organizationOption}
        />
      </div>
    </main>
  );
}
export default withRoleBased(License, "subDomain");
