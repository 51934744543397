import { useFormik } from "formik";
import { useMemo } from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { UPDATE_MODULE_GQL } from "../../../Apollo/AdminServices/MutationRequest";
import graphHandler from "../../../Apollo/graphHandler";
import CustomButton from "../../../components/Button/CustomButton";
import DescriptionInputField from "../../../components/HistoryEditInput/DescriptionInputField";
import ImageHistoryInput from "../../../components/HistoryEditInput/ImageHistoryInput";
import MetaHistoryInput from "../../../components/HistoryEditInput/MetaHistoryInput";
import NewReactSelectInputField from "../../../components/HistoryEditInput/NewReactSelectInputField";
import TextInputField from "../../../components/HistoryEditInput/TextInputField";
import VideoHistoryInput from "../../../components/HistoryEditInput/VideoHistoryInput";
import {
  mergeMultiSelectArray,
  objectHasKey,
  removeEmptyObjValue,
} from "../../../utils/Functions/filter";
import { useEffect } from "react";

const UpdateTherapyGroup = ({
  refresh = () => {},
  subDomainList = [],
  setLoading = () => {},
  therapyGroup = {},
  reset = () => {},
}) => {
  const [selectedSubDomainList, setSelectedSubDomainList] = useState([]);
  const pending_history = therapyGroup.pending_history
    ? therapyGroup.pending_history
    : {};
  let { therapy_module_id, sub_domain_ids } = therapyGroup;
  const hasUpdated = Boolean(pending_history && pending_history.updated_data);
  const updated_data = hasUpdated ? pending_history.updated_data : {};
  const formik = useFormik({
    initialValues: {},
    onSubmit: (values) => {
      onSubmit(values);
    },
  });
  useEffect(() => {
    //Reset onEdit
    formik.resetForm();
    formik.setValues({});
    formik.setSubmitting(false);
    if (therapy_module_id) {
      let publish = hasUpdated ? updated_data : {};
      if (subDomainList&&subDomainList.length > 0) {
        let subArray = [],
          sub_domains =
            sub_domain_ids && sub_domain_ids.length > 0 ? sub_domain_ids : [],
          publishedSub =
            publish.sub_domain_ids &&
            publish.sub_domain_ids.update_items &&
            publish.sub_domain_ids.update_items.length > 0
              ? publish.sub_domain_ids.update_items
              : [];
        let value = mergeMultiSelectArray(sub_domains, publishedSub);
        let i = 0;
        subArray = value.map((x) => {
          let ret = {
            ...subDomainList.find((y) => y.value === x.data),
            ...x,
            index: i,
          };
          if (x.isRemoved) {
            ret.value =
              subDomainList.find((y) => y.value === x.data).label || "";
          } else {
            i++;
          }
          return ret;
        });
        setSelectedSubDomainList(subArray);
      }
    }
  }, [therapy_module_id,subDomainList]);

  const onSubmit = async (formikValues) => {
    formik.setSubmitting(true);
    const {
      sub_domain_ids,
      therapy_module_name,
      therapy_module_description,
      sort_order,
      therapy_module_meta,
      therapy_module_code,
      min_completion_days,
      max_completion_days,
      start_interval_seconds,
      therapy_module_video_urls,
      therapy_module_image_urls,
    } = formikValues;
    let update_request = {
      therapy_module_id,
    };
    if (objectHasKey(formikValues, "min_completion_days")) {
      update_request.min_completion_days = Number(min_completion_days);
    }
    if (objectHasKey(formikValues, "start_interval_seconds")) {
      update_request.start_interval_seconds =
        Number(start_interval_seconds) * 86400;
    }
    if (objectHasKey(formikValues, "max_completion_days")) {
      update_request.max_completion_days = Number(max_completion_days);
    }
    if (objectHasKey(formikValues, "sub_domain_ids")) {
      update_request.sub_domain_ids = { update_items: sub_domain_ids };
    }
    if (objectHasKey(formikValues, "therapy_module_name")) {
      update_request.therapy_module_name = therapy_module_name;
    }
    if (objectHasKey(formikValues, "therapy_module_code")) {
      update_request.therapy_module_code = therapy_module_code;
    }
    if (objectHasKey(formikValues, "sort_order")) {
      update_request.sort_order = Number(sort_order);
    }
    if (objectHasKey(formikValues, "therapy_module_description")) {
      update_request.therapy_module_description = {
        update_items: therapy_module_description,
      };
    }
    if (objectHasKey(formikValues, "therapy_module_meta")) {
      update_request.therapy_module_meta = {
        update_items: therapy_module_meta.map((x) => removeEmptyObjValue(x)),
      };
    }
    if (objectHasKey(formikValues, "therapy_module_image_urls")) {
      update_request.therapy_module_image_urls = {
        update_items: therapy_module_image_urls.map((x) =>
          removeEmptyObjValue(x)
        ),
      };
    }
    if (objectHasKey(formikValues, "therapy_module_video_urls")) {
      update_request.therapy_module_video_urls = {
        update_items: therapy_module_video_urls.map((x) =>
          removeEmptyObjValue(x)
        ),
      };
    }
    formik.setSubmitting(true);
    setLoading(true);
    // console.log("update_request", update_request);
    try {
      await graphHandler().invoke(
        UPDATE_MODULE_GQL,
        { update_request },
        1,
        true
      );
      toast.success("Updated Successfully");
      refresh();
      setLoading(false);
      formik.setSubmitting(false);
      resetModal();
    } catch (error) {
      console.log("therapyGroup error", error);
      setLoading(false);
      formik.setSubmitting(false);
    }
  };
  const handleError = () => {
    if (Object.keys(formik.errors).length > 0) {
      toast.error(formik.errors[Object.keys(formik.errors)[0]]);
    }
  };

  const resetModal = () => {
    formik.resetForm();
    formik.setValues({});
    formik.setSubmitting(false);
    reset();
  };

  return (
    <>
      <div className="col-md-12 modal-header" id="CreateTopic">
        <h5 className="modal-title text-center mb-3">Update Modules</h5>
      </div>
      <div className="col-md-12">
        <div className="  border">
          <div className="row p-3">
            <div className="col-md-6">
              <div className="row">
                <NewReactSelectInputField
                  parentForm={formik}
                  id="sub_domain_ids"
                  title="Sub Domain"
                  placeholder="Select Sub Domain"
                  options={subDomainList}
                  isPublished={therapyGroup.published}
                  isCreatable={false}
                  isMulti={true}
                  defaultValue={selectedSubDomainList}
                  updateValue={
                    updated_data.sub_domain_ids &&
                    updated_data.sub_domain_ids.update_items &&
                    updated_data.sub_domain_ids.update_items.length > 0
                      ? updated_data.sub_domain_ids.update_items
                      : false
                  }
                  validation={{
                    sub_domain_ids: Yup.array()
                      .of(
                        Yup.object().shape({
                          label: Yup.string(),
                          value: Yup.string(),
                        })
                      )
                      .test({
                        message: "Select sub domain",
                        test: (arr) => {
                          return arr && arr.length > 0;
                        },
                      }),
                  }}
                />
                <TextInputField
                  parentForm={formik}
                  className="col-md-6"
                  updateValue={updated_data.therapy_module_name}
                  actualValue={therapyGroup.therapy_module_name}
                  id="therapy_module_name"
                  title="Module Name"
                  validation={{
                    therapy_module_name:
                      Yup.string().required("Enter group code"),
                  }}
                />
                <TextInputField
                  parentForm={formik}
                  className="col-md-6"
                  updateValue={updated_data.therapy_module_code}
                  actualValue={therapyGroup.therapy_module_code}
                  id="therapy_module_code"
                  title="Code"
                  disabled={true}
                  validation={{
                    therapy_module_code:
                      Yup.string().required("Enter group code"),
                  }}
                />
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-md-12 form-group mb-0">
                      <label
                        htmlFor="min_completion_days"
                        className="col-form-label w-100 mb-2"
                      >
                        Completion Days
                      </label>
                      <div className="row">
                        <TextInputField
                          parentForm={formik}
                          className="col-md-6"
                          updateValue={updated_data.min_completion_days}
                          actualValue={therapyGroup.min_completion_days}
                          id="min_completion_days"
                          title="Minimum"
                          validation={{
                            min_completion_days: Yup.string().required("Enter minimum completion days")
                          }}
                          isNumber={true}
                          inputProps={{
                            maxLength: 10,
                          }}
                        />
                        <TextInputField
                          parentForm={formik}
                          className="col-md-6"
                          updateValue={updated_data.max_completion_days}
                          actualValue={therapyGroup.max_completion_days}
                          id="max_completion_days"
                          title="Maximum"
                          validation={{
                            max_completion_days:
                              Yup.string().required("Enter max completion days")
                              .when('min_completion_days', (min_completion_days, schema) => {
                                return schema.test({
                                  test: max_completion_days => {
                                    // return Number(max_completion_days??therapyGroup.max_completion_days) > Number(min_completion_days??therapyGroup.min_completion_days);
                                    return Number(max_completion_days??therapyGroup.max_completion_days) > Number(min_completion_days??therapyGroup.min_completion_days);
                                  },
                                  message: "Max days should be > min days"
                                })
                              }),
                          }}
                          isNumber={true}
                          inputProps={{
                            maxLength: 10,
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="row">
                        <TextInputField
                          parentForm={formik}
                          className="col-md-4"
                          updateValue={
                            updated_data.start_interval_seconds &&
                            updated_data.start_interval_seconds / 86400
                          }
                          actualValue={
                            therapyGroup.start_interval_seconds &&
                            therapyGroup.start_interval_seconds / 86400
                          }
                          id="start_interval_seconds"
                          title="Interval (in days)"
                          validation={{
                            start_interval_seconds: Yup.string().required(
                              "Enter start interval"
                            ),
                          }}
                          isNumber={true}
                          inputProps={{
                            maxLength: 10,
                          }}
                        />
                        <TextInputField
                          parentForm={formik}
                          className="col-md-6"
                          updateValue={updated_data.sort_order}
                          actualValue={therapyGroup.sort_order}
                          id="sort_order"
                          title="Sort Order"
                          validation={{
                            sort_order:
                              Yup.string().required("Enter sort order"),
                          }}
                          isNumber={true}
                          inputProps={{
                            maxLength: 10,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="row">
                <DescriptionInputField
                  parentForm={formik}
                  parentKey="therapy_module_description"
                  className="col-md-12"
                  updateValue={
                    updated_data.therapy_module_description &&
                    updated_data.therapy_module_description.update_items
                      ? updated_data.therapy_module_description.update_items
                      : false
                  }
                  actualValue={therapyGroup.therapy_module_description}
                  validation={{
                    therapy_module_description: Yup.string()
                      .trim()
                      .required("Description is required"),
                    mobile_description: Yup.string()
                      .trim()
                      .required("Mobile description is required"),
                  }}
                  showEditor={true}
                  refreshId={therapy_module_id}
                />
              </div>
            </div>

            <div className="col-md-12">
              <MetaHistoryInput
                parentForm={formik}
                parentArray="therapy_module_meta"
                typeKey="type"
                titleKey="key"
                valueKey="value"
                isPublished={therapyGroup.published}
                updateValue={
                  updated_data.therapy_module_meta &&
                  updated_data.therapy_module_meta.update_items
                    ? updated_data.therapy_module_meta.update_items
                    : false
                }
                validation={{
                  therapy_module_meta: Yup.array().of(
                    Yup.object().shape({
                      type: Yup.string().required("Select a data type"),
                      key: Yup.string().trim().required("Enter a title"),
                      value: Yup.string().trim().required("Enter a value"),
                    })
                  ),
                }}
                actualValue={therapyGroup.therapy_module_meta}
              />
              <ImageHistoryInput
                parentForm={formik}
                contentId={therapyGroup.therapy_module_id}
                contentType="THERAPYMODULE"
                heading="Image File"
                isPublished={therapyGroup.published}
                parentArray="therapy_module_image_urls"
                actualValue={therapyGroup.therapy_module_image_urls}
                updateValue={
                  updated_data.therapy_module_image_urls &&
                  updated_data.therapy_module_image_urls.update_items
                    ? updated_data.therapy_module_image_urls.update_items
                    : false
                }
                validation={{
                  therapy_module_image_urls: Yup.array().of(
                    Yup.object().shape({
                      is_mobile: Yup.boolean().required(
                        "Select a Image device"
                      ),
                      image_type: Yup.string().required(
                        "Select a Image format"
                      ),
                      url: Yup.string(),
                    })
                  ),
                }}
              />
              <VideoHistoryInput
                parentForm={formik}
                contentId={therapyGroup.therapy_module_id}
                contentType="THERAPYMODULE"
                heading="Video File"
                isPublished={therapyGroup.published}
                parentArray="therapy_module_video_urls"
                actualValue={therapyGroup.therapy_module_video_urls}
                updateValue={
                  updated_data.therapy_module_video_urls &&
                  updated_data.therapy_module_video_urls.update_items
                    ? updated_data.therapy_module_video_urls.update_items
                    : false
                }
                validation={{
                  therapy_module_video_urls: Yup.array().of(
                    Yup.object().shape({
                      is_mobile: Yup.boolean().required(
                        "Select a video device"
                      ),
                      video_type: Yup.string().required(
                        "Select a video format"
                      ),
                      video_url: Yup.string(),
                    })
                  ),
                }}
              />
            </div>
            <div
              className={"d-flex align-items-end justify-content-end col-md-12"}
            >
              <button
                onClick={resetModal}
                type="button"
                className="btn btn-light btn-sm float-right btn-add-domain mr-2"
              >
                Reset
              </button>
              <CustomButton
                type="button"
                className="btn btn-primary btn-outline-success btn-sm float-right btn-add-domain"
                disabled={!formik.isValid || formik.isSubmitting}
                onClick={
                  formik.isValid && Object.keys(formik.values).length !== 0
                    ? formik.handleSubmit
                    : handleError
                }
              >
                Update
              </CustomButton>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default UpdateTherapyGroup;
