import { useEffect, useState } from "react";
import { GET_ALL_SCREENER_HISTORIES } from "../../Apollo/AdminServices/QueryRequest";
import graphHandler from "../../Apollo/graphHandler";
function useScreenerHistory(id) {
  const [screenerHistory, setScreenerHistory] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (id) {
      getScreenerHistory(id);
    } else {
      setScreenerHistory([]);
    }
  }, [id]);
  const getScreenerHistory = async (screener_id) => {
    if (!screener_id) return;
    setLoading(true);
    try {
      const { data } = await graphHandler().invoke(
        GET_ALL_SCREENER_HISTORIES,
        { screener_id },
        1,
        false,
        true
      );
      if (
        data &&
        data.getAllScreenerHistories &&
        data.getAllScreenerHistories.screener_history_list
      ) {
        // console.log(
        //   "getAllScreenerHistories Success",
        //   data.getAllScreenerHistories.screener_history_list
        // );
        setScreenerHistory(
          data.getAllScreenerHistories.screener_history_list
            .slice()
            .sort(
              (a, b) =>
                new Date(b.updated_at ? b.updated_at : b.created_at) -
                new Date(a.updated_at ? a.updated_at : a.created_at)
            )
        );
        setLoading(false);
      } else {
        setScreenerHistory([]);
        setLoading(false);
      }
    } catch (error) {
      console.log("getAllScreenerHistories Error", error);
      setScreenerHistory([]);
      setLoading(false);
    }
  };
  return {
    screenerHistory,
    loading,
    getScreenerHistory,
  };
}
export default useScreenerHistory;
