import React from "react";

const HistoryMediaTableItem = ({
  item,
  arrayIndex = "",
  remove = () => {},
  onEdit = () => {},
  disabled,
  isPublished = false,
}) => {
  // console.log("item",item,mediaType)
  return (
    <tr
      className={`${
        item.isRemoved
          ? "bad"
          : item.isAdded
          ? "good"
          : item.isUpdated
          ? "warn"
          : ""
      }`}
    >
      <td className="text-none">{arrayIndex + 1}</td>
      <td className="text-none">
        { item?.mediaUrl?.type === "Image" ||
          item.media_format === "Image" ||
          item.document_type === "Image" ? (
            <img
            src={item?.url}
            className="upload__img"
            alt="upload__img"
          />
        ) : (
          <i className="fas fa-file mr-5" style={{ fontSize: "60px" }} />
        )}
      </td>
      <td className="text-none">
        <p className="upload__name">
          {item?.name ?? "Upload file"}
        </p>
      </td>
      <td className="text-none">
      {`${item.is_mobile}` === "false" ? "Web" : "Mobile"}
      </td>
      <td className="text-none">
        {item.document_type}
      </td>
      {!disabled && (
        <td className="d-flex flex-row">
          {!isPublished &&!item.isAdded && !item.isRemoved ? (
            <>
              {/* <i
                className="fas fa-edit cursor-pointer"
                onClick={() => {
                  !disabled && onEdit({ ...item,i:arrayIndex });
                }}
              ></i> */}
              <i
                className="ml-2 fas fa-trash cursor-pointer"
                onClick={() =>
                  !disabled && remove(item, item.index, arrayIndex)
                }
              ></i>
            </>
          ) : isPublished && !item.isAdded &&
            !item.isRemoved &&
            item.update_type !== "Update" &&
            item.update_type !== "Add" ? (
            <>
              <i
                className="ml-2 fas fa-trash cursor-pointer"
                onClick={() =>
                  !disabled && remove(item, item.index, arrayIndex)
                }
              ></i>
            </>
          ) : (
            "-"
          )}
        </td>
      )}
    </tr>
  );
};

export default HistoryMediaTableItem;
