import { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { UPDATE_SCREENER_QUESTIONAIRE_GQL } from "../../../Apollo/AdminServices/MutationRequest";
import graphHandler from "../../../Apollo/graphHandler";
import CustomButton from "../../../components/Button/CustomButton";
import { objectHasKey } from "../../../utils/Functions/filter";
import UploadQuestion from "../modal/UploadQuestion";
import TextInputField from "../../../components/HistoryEditInput/TextInputField";
import TextAreaField from "../../../components/HistoryEditInput/TextAreaField";
import SelectInputField from "../../../components/HistoryEditInput/SelectInputField";
import CheckInputField from "../../../components/HistoryEditInput/CheckInputField";
import OptionHistoryInput from "../../../components/HistoryEditInput/OptionHistoryInput";

export default function UpdateQuestionSection({
  values = {},
  topicList = [],
  questionaireList = [],
  setLoading = () => {},
  refresh = () => {},
  question = {},
  reset = () => {},
}) {
  // console.log("UpdateQuestionSection", question);
  const { screener_question_id } = question;
  const pending_history = question.pending_history
    ? question.pending_history
    : {};
  const hasUpdated = Boolean(pending_history && pending_history.updated_data);
  const updated_data = hasUpdated ? pending_history.updated_data : {};
  const [isOpen, setOpen] = useState(false);

  const subDomains = useSelector(({ DataSet }) => DataSet.subDomains);
  const formik = useFormik({
    initialValues: {},
    validationSchema: Yup.object().shape({}),
    onSubmit: (values) => {
      onSubmit(values);
    },
  });

  const handleError = () => {
    if (Object.keys(formik.errors).length > 0) {
      toast.error(formik.errors[Object.keys(formik.errors)[0]]);
    }
  };

  const onSubmit = async (value) => {
    const {
      sub_domain_id,
      screener_topic_id,
      screener_question_title,
      ignore_for_scoring,
      result_weightage,
      options,
    } = value;
    let update_request = { screener_question_id };
    if (objectHasKey(value, "screener_question_title")) {
      update_request.screener_question_title = screener_question_title;
    }
    if (objectHasKey(value, "result_weightage")) {
      update_request.result_weightage = Number(result_weightage);
    }
    if (objectHasKey(value, "screener_topic_id")) {
      update_request.screener_topic_id = screener_topic_id;
    }
    if (objectHasKey(value, "ignore_for_scoring")) {
      update_request.ignore_for_scoring =
        typeof ignore_for_scoring === "boolean"
          ? ignore_for_scoring
          : ignore_for_scoring === "true";
    }
    if (objectHasKey(value, "sub_domain_id")) {
      update_request.sub_domain_id = sub_domain_id;
    }
    if (objectHasKey(value, "options")) {
      update_request.options = { options_update: { update_items: options } };
    }
    try {
      const { data } = await graphHandler().invoke(
        UPDATE_SCREENER_QUESTIONAIRE_GQL,
        { update_request },
        1,
        false,
        true
      );
      if (
        data &&
        data.updateScreenerQuestion &&
        data.updateScreenerQuestion.screener_question
      ) {
        setLoading(false);
        toast.success("Updated Successfully");
        refresh();
        reset();
      }
    } catch (error) {
      console.error("updateScreenerQuestion Error", error);
      setLoading(false);
      formik.setSubmitting(false);
    }
  };

  //   console.log(formik.values, formik.errors);
  return (
    <>
      <div
        className="col-md-12 modal-header justify-content-between align-items-center"
        id="updateQuestion"
      >
        <h5 className="modal-title text-center">Update Question</h5>
        <button
          type="button"
          className="btn btn-success"
          onClick={() => setOpen(true)}
        >
          Upload Questions <i className="fas fa-file-excel" />
        </button>
        <UploadQuestion
          {...{
            isOpen,
            setOpen,
            values,
            refresh,
            subDomains,
            topicList,
            questionaireList,
          }}
        />
      </div>
      <div className="col-md-12">
        <div className="  border">
          <div className="questionnaire-form row p-3">
            <TextAreaField
              parentForm={formik}
              className="col-md-12"
              updateValue={updated_data.screener_question_title}
              actualValue={question.screener_question_title}
              id="screener_question_title"
              title="Question"
              validation={{
                screener_question_title:
                  Yup.string().trim().required("Enter a question"),
              }}
              showEditor={true}
            />
            <SelectInputField
              parentForm={formik}
              className="col-md-4"
              updateValue={updated_data.screener_topic_id}
              actualValue={question.screener_topic_id}
              id="screener_topic_id"
              title="Topic"
              validation={{
                screener_topic_id: Yup.string().required("Select a topic"),
              }}
              options={[
                { label: "Select", value: "", props: { hidden: true } },
                ...topicList.map((x) => ({
                  label: x.screener_topic_title,
                  value: x.screener_topic_id,
                })),
              ]}
            />
            <SelectInputField
              parentForm={formik}
              className="col-md-4"
              updateValue={updated_data.sub_domain_id}
              actualValue={question.sub_domain_id}
              id="sub_domain_id"
              title="Sub Domain"
              validation={{
                sub_domain_id: Yup.string().required("Select a sub domain"),
              }}
              options={[
                { label: "Select", value: "", props: { hidden: true } },
                ...subDomains.map((x) => ({
                  label: x.sub_domain_name,
                  value: x.sub_domain_id,
                })),
              ]}
            />
            <TextInputField
              parentForm={formik}
              className="col-md-4"
              updateValue={updated_data.result_weightage}
              actualValue={question.result_weightage}
              id="result_weightage"
              title="Result Weightage"
              validation={{
                result_weightage: Yup.string().trim().required(
                  "Enter result weightage"
                ),
              }}
              isNumber={true}
              inputProps={{
                maxLength: 10,
              }}
            />

            <div className="col-md-12">
              <OptionHistoryInput
                className="col-md-12"
                parentForm={formik}
                updateValue={
                  updated_data.options &&
                  updated_data.options.options_update &&
                  updated_data.options.options_update.update_items
                    ? updated_data.options.options_update.update_items
                    : false
                }
                validation={{
                  options: Yup.array()
                    .of(
                      Yup.object().shape({
                        data: Yup.string(),
                        value: Yup.string(),
                      })
                    )
                    .test({
                      message: "Add at least a multiple option",
                      test: (arr) => {
                        return arr && arr.length > 0;
                      },
                    }),
                }}
                actualValue={
                  question.options && question.options.options
                    ? question.options.options
                    : []
                }
              />
            </div>
            <CheckInputField
              parentForm={formik}
              col="col-md-6"
              className="col-md-6"
              updateValue={updated_data.ignore_for_scoring}
              // eslint-disable-next-line no-prototype-builtins
              hasUpdated={typeof updated_data.ignore_for_scoring === "boolean"}
              actualValue={question.ignore_for_scoring}
              id="ignore_for_scoring"
              title="Ignore Scoring"
              validation={{
                ignore_for_scoring: Yup.string(),
              }}
            />
            <div
              className={`d-flex align-items-end justify-content-end col-md-12`}
            >
              <button
                onClick={reset}
                type="button"
                className="btn btn-light btn-sm float-right btn-add-domain mr-2"
              >
                Reset
              </button>
              <CustomButton
                type="button"
                className="btn btn-primary btn-outline-success btn-sm float-right btn-add-domain"
                disabled={
                  Object.keys(formik.errors).length !== 0 ||
                  !formik.isValid ||
                  formik.isSubmitting
                }
                onClick={
                  formik.isValid && Object.keys(formik.values).length !== 0
                    ? formik.handleSubmit
                    : handleError
                }
              >
                Update
              </CustomButton>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
