export const videoRatioCheck = (
  file,
  limit = 600.1,
  limitError = "10 Minutes"
) => {
  return new Promise(async (resolve, reject) => {
    let value = {};
    let video = document.createElement("video");

    video.preload = "metadata";
    video.onloadedmetadata = async () => {
      let position = await calculateRatio(video.videoWidth, video.videoHeight);
      window.URL.revokeObjectURL(video.src);
      var duration = video.duration;
      if (parseFloat(duration) > limit) {
        value = {
          error: "Maximum Video Size Should Not be Greater Than " + limitError,
          position,
          success: false,
          file,
        };
        reject(value);
        video.remove();
        return;
      }
      if (position) {
        value = { error: "", position, success: true, file };
        // console.log(
        //   "resolve",
        //   position,
        //   video,
        //   video.videoWidth,
        //   video.videoHeight
        // );
        resolve(value);
        video.remove();
      } else {
        value = {
          error: "Invalid Video Ratio Use 16:9 or 9:16",
          position,
          success: false,
          file,
        };
        // console.log(
        //   "reject",
        //   position,
        //   video,
        //   video.videoWidth,
        //   video.videoHeight
        // );
        reject(value);
        video.remove();
      }
    };
    video.onerror = () => {
      value = {
        error: "Error occur while loading video, please try again",
        position: "",
        success: false,
        file,
      };
      console.log(value.error);
      reject(value);
      video.remove();
    };
    video.src = URL.createObjectURL(file);
  });
};

export const videoDurationCheck = (
  file,
  limit = 600.1,
  limitError = "10 Minutes"
) => {
  return new Promise(async (resolve, reject) => {
    let value = {};
    let video = document.createElement("video");

    video.preload = "metadata";
    video.onloadedmetadata = async () => {
      window.URL.revokeObjectURL(video.src);
      var duration = video.duration;
      if (parseFloat(duration) > limit) {
        value = {
          error: "Maximum Video Size Should Not be Greater Than " + limitError,
          duration,
          success: false,
          file,
        };
        reject(value);
        video.remove();
        return;
      } else {
        value = { error: "", duration, success: true, file };
        resolve(value);
        video.remove();
      }
    };
    video.onerror = () => {
      value = {
        error: "Error occur while loading video, please try again",
        success: false,
        file,
      };
      console.log(value.error);
      reject(value);
      video.remove();
    };
    video.src = URL.createObjectURL(file);
  });
};

async function calculateRatio(w, h) {
  const rw = await (w / gcd(w, h));
  const rh = await (h / gcd(w, h));
  //   console.log(w, h, rw, rh);
  if (rw === 16 && rh === 9) {
    return "Horizontal";
  } else if (rw === 9 && rh === 16) {
    return "Vertical";
  } else {
    return "";
  }
}
function gcd(w, h) {
  return h == 0 ? w : gcd(h, w % h);
}

export const imageCheck = (file) => {
  return new Promise(async (resolve, reject) => {
    let value = {};
    var img = new Image();
    img.onload = function () {
      var height = img.height;
      var width = img.width;
      // console.log(img, height, width);
      if (height === width) {
        value = { error: "", success: true, file };
        resolve(value);
      } else {
        value = {
          error: "Invalid Image Ratio try 1:1 Images",
          success: false,
          file,
        };
        reject(value);
      }
    };
    img.src = URL.createObjectURL(file);
  });
};

export const audioCheck = (file, limit = 600.1, limitError = "10 Minutes") => {
  return new Promise(async (resolve, reject) => {
    let value = {};
    let video = document.createElement("audio");
    video.preload = "metadata";
    video.onloadedmetadata = async () => {
      window.URL.revokeObjectURL(video.src);
      var duration = video.duration;
      if (parseFloat(duration) > limit) {
        value = {
          error: "Maximum Audio Size Should Not be Greater Than " + limitError,
          success: false,
          file,
        };
        reject(value);
        video.remove();
        return;
      } else {
        value = {
          error: "",
          success: true,
          file,
        };
        resolve(value);
        video.remove();
      }
    };
    video.onerror = () => {
      value = {
        error: "Error occur while loading audio, please try again",
        success: false,
        file,
      };
      console.log(value.error);
      reject(value);
      video.remove();
    };
    video.src = URL.createObjectURL(file);
  });
};
