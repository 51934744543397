import { useEffect, useState } from "react";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import defaultStyles from "./DefaultStyle";
import "./MultiSelect.scss";

const MultiSelect = (props) => {
  let {
    type,
    option,
    displayName = "label",
    uniqueKey = "value",
    menuPlacement = "auto",
    defaultValue,
    onChange = () => {},
    onCreate = () => {},
    isMulti = true,
    className = "",
    placeholder = "",
    style,
    isCreatable = true,
    isClearable = false,
    disabled = false,
    onBlur = () => {},
    id,
    inputId,
    autoClose,
  } = props;
  const [options, setOptions] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [value, setValue] = useState([]);
  useEffect(() => {
    if (defaultValue === "Reset") setValue([]);
    else if (defaultValue) setValue(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    if (option && Array.isArray(option)) {
      setOptions(
        option.map((item) => createOption(item[displayName], item[uniqueKey]))
      );
    }
  }, [option]);
  const handleChange = (newValue, option) => {
    if (option.removedValue && option.removedValue.isFixed) return;
    // console.log(newValue);
    if (onChange) onChange(newValue);
    // console.log(newValue);
    setValue(newValue);
  };

  const onCreateSuccess = async (obj) => {
    setLoading(false);
    const newOption = createOption(
      obj[displayName] ? obj[displayName] : obj,
      obj[uniqueKey] ? obj[uniqueKey] : obj
    );
    setValue(value && value.length ? [...value, newOption] : [newOption]);
    if (isMulti) {
      onChange(value && value.length ? [...value, newOption] : [newOption]);
    } else {
      onChange(newOption);
    }
  };

  const onCreateError = async () => {
    setLoading(false);
  };
  const handleCreate = async (inputValue) => {
    try {
      setLoading(true);
      onCreate(inputValue, onCreateSuccess, onCreateError);
    } catch (error) {
      setLoading(false);
      console.log(error.response ? error.response : error);
    }
  };
  return isCreatable ? (
    <CreatableSelect
      className={className ? className : ""}
      styles={style ? style : defaultStyles}
      isMulti={isMulti}
      isClearable={isClearable}
      placeholder={placeholder ? placeholder : "Add more"}
      isDisabled={isLoading || disabled}
      isLoading={isLoading}
      onChange={handleChange}
      formatCreateLabel={(option) => `Add New ${type} "${option}"`}
      onCreateOption={handleCreate}
      options={options}
      value={value}
      onBlur={onBlur}
      inputId={id ? id : inputId}
      closeMenuOnSelect={autoClose}
      menuPlacement={menuPlacement}
    />
  ) : (
    <Select
      className={className ? className : ""}
      styles={style ? style : defaultStyles}
      isMulti={isMulti}
      isClearable={isClearable}
      placeholder={placeholder ? placeholder : "Add more"}
      isDisabled={isLoading || disabled}
      isLoading={isLoading}
      onChange={handleChange}
      options={options}
      value={value}
      onBlur={onBlur}
      inputId={id ? id : inputId}
      closeMenuOnSelect={autoClose}
      menuPlacement={menuPlacement}
    />
  );
};

export default MultiSelect;
const createOption = (label, value) => ({
  label,
  value,
});
