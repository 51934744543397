import { useState } from "react";
import { GET_CONFIG_LIST_GQL } from "../../Apollo/AdminServices/QueryRequest";
import graphHandler from "../../Apollo/graphHandler";

function useGameConfigList(preload = false) {
  const [configList, setConfigList] = useState([]);
  const [loading, setLoading] = useState(preload);

  const getConfigList = async (req = {}) => {
    setConfigList([]);
    setLoading(true);
    try {
      const { data } = await graphHandler().invoke(
        GET_CONFIG_LIST_GQL,
        { ...req },
        1,
        false,
        true
      );
      if (
        data &&
        data.getGameConfigList &&
        data.getGameConfigList.game_configs &&
        data.getGameConfigList.game_configs.length > 0
      ) {
        // console.log("getGameConfigList Success", data.getGameConfigList);
        setConfigList(
          data.getGameConfigList.game_configs
            .slice()
            .sort(
              (a, b) =>
                new Date(b.updated_at ? b.updated_at : b.created_at) -
                new Date(a.updated_at ? a.updated_at : a.created_at)
            )
        );
        setLoading(false);
      } else {
        setConfigList([]);
        setLoading(false);
      }
    } catch (error) {
      console.log("getGameConfigList Error", error);
      setConfigList([]);
      setLoading(false);
    }
  };

  return {
    configList,
    loading,
    getConfigList,
  };
}

export default useGameConfigList;
