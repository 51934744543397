import React, { useEffect, useState } from "react";
import CustomLoader from "../../components/Loader";
import BlogHistoryTable from "./sections/BlogHistoryTable";
import InterventionHistoryTable from "./sections/InterventionHistoryTable";
import PackageHistoryTable from "./sections/PackageHistoryTable";
import QuestionaireHistoryTable from "./sections/QuestionaireHistoryTable";
import ScreenerHistoryTable from "./sections/ScreenerHistoryTable";
import TagHistoryTable from "./sections/TagHistoryTable";
import TherapyHistoryTable from "./sections/TherapyHistoryTable";
import TopicHistoryTable from "./sections/TopicHistoryTable";

const ViewHistory = ({ location, history }) => {
  const [loading, setLoading] = useState(true);
  const [histotyType, setHistoryType] = useState("Loading");
  const [id, setId] = useState("");
  useEffect(() => {
    const query = new URLSearchParams(location.search);
    if (
      loading &&
      query.has("type") &&
      query.get("type")
      //   &&
      //   query.has("id") &&
      //   query.get("id")
    ) {
      setHistoryType(query.get("type"));
      setId(query.get("id"));
      setLoading(false);
    } else if (!query.has("type")) {
      if (history.previous) {
        history.goBack();
      } else {
        history.push("/");
      }
    }
  }, [loading, location]);
  const renderHistory = (status) => {
    switch (status.toLowerCase()) {
      case "questionaire":
        return <QuestionaireHistoryTable id={id} />;
      case "topic":
        return <TopicHistoryTable id={id} />;
      case "tag":
        return <TagHistoryTable id={id} />;
      case "intervention":
        return <InterventionHistoryTable id={id} />;
      case "screener":
        return <ScreenerHistoryTable id={id} />;
      case "blog":
        return <BlogHistoryTable id={id} />;
      case "therapy":
        return <TherapyHistoryTable id={id} />;
      case "package":
        return <PackageHistoryTable id={id} />;
      default:
        return <h5>Development in progress</h5>;
    }
  };

  if (loading) return <CustomLoader />;
  return (
    <main className="page-content">
      <div className="container-fluid">
        <div className="row ">
          <div className="col-md-6 mb-3">
            <h5 className="page-title"> {histotyType} History</h5>
          </div>
          <div className="col-md-12">{renderHistory(histotyType)}</div>
        </div>
      </div>
    </main>
  );
};
export default ViewHistory;
