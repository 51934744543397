import { useEffect, useState } from "react";
import { Modal, ModalBody } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import graphHandler from "../../../Apollo/graphHandler";
import {
  CREATE_POLICY_GQL,
  UPDATE_POLICY_GQL,
} from "../../../Apollo/AdminServices/MutationRequest";
import { isInputNumber } from "../../../utils/Functions/filter";
import { PolicyType } from "../../../constants";
import CustomButton from "../../../components/Button/CustomButton";
import EditorModal from "../../../components/Modal/EditorModal";

export default function PolicyModal({
  isOpen,
  setOpen,
  refresh,
  initialValues = {},
}) {
  const [isOpenContent, setOpenContent] = useState(false);
  const formik = useFormik({
    initialValues: {},
    validationSchema: validation,
    onSubmit: (values) => {
      onSubmit(values);
    },
  });

  useEffect(() => {
    if (initialValues.policy_id) onEdit(initialValues);
  }, [initialValues, isOpen]);
  const onClose = () => {
    formik.resetForm();
    setOpen();
  };

  const onSubmit = async (values) => {
    const {
      policy_title,
      policy_type,
      policy_url,
      policy_version,
      policy_id,
      policy_body,
      policy_user_type,
    } = values;
    const request = {
      policy_title,
      policy_type,
      policy_user_type,
      policy_url,
      policy_version: Number(policy_version),
    };
    if (policy_body) {
      request.policy_body = policy_body;
    }
    if (policy_id) {
      update({ ...request, policy_id });
    } else {
      create(request);
    }
  };

  const create = async (addReq = {}) => {
    try {
      await graphHandler().invoke(CREATE_POLICY_GQL, { addReq }, 1, false);
      toast.success("Added Successfully!");
      refresh();
      onClose();
      formik.setSubmitting(false);
    } catch (error) {
      console.log("CreatePolicy error", error);
      formik.setSubmitting(false);
    }
  };
  const update = async (update_request = {}) => {
    try {
      await graphHandler().invoke(
        UPDATE_POLICY_GQL,
        { update_request },
        1,
        false
      );
      toast.success("Update Successfully!");
      refresh();
      onClose();
      formik.setSubmitting(false);
    } catch (error) {
      console.log("updatePolicy error", error);
      formik.setSubmitting(false);
    }
  };

  const handleError = () => {
    if (Object.keys(formik.errors).length > 0) {
      toast.error(formik.errors[Object.keys(formik.errors)[0]]);
    }
  };

  const onEdit = (values = {}) => {
    if (values.policy_id) {
      let model = {
        policy_id: values.policy_id,
        policy_title: values.policy_title,
        policy_url: values.policy_url,
        policy_version: values.policy_version,
        policy_user_type: values.policy_user_type,
        policy_type: values.policy_type,
      };
      if (values.policy_body) {
        model.policy_body = values.policy_body;
      }
      formik.setValues(model);
    }
  };
  return (
    <Modal
      toggle={onClose}
      isOpen={isOpen}
      size="lg"
      modalClassName="modal-fullscreen modal-dialog-scrollable"
    >
      <div className="modal-header">
        <button type="button" className="close" onClick={onClose}>
          <span aria-hidden="true">&times;</span>
        </button>
        <h5 className="modal-title">
          {formik.values.policy_id ? "Update" : "Create"} Policy
        </h5>
      </div>
      <EditorModal
        isOpen={isOpenContent}
        defaultValue={
          formik.values.policy_body ? formik.values.policy_body : ""
        }
        close={() => setOpenContent(false)}
        save={(e) => {
          formik.setFieldValue("policy_body", e);
        }}
      />
      <ModalBody className="pt-0">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-6">
              <div className="row">
                <div className="col-md-12 form-group">
                  <label
                    htmlFor="policy_title"
                    className="col-form-label w-100 mb-2"
                  >
                    Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="policy_title"
                    name="policy_title"
                    // maxLength="30"
                    value={
                      formik.values && formik.values.policy_title
                        ? formik.values.policy_title
                        : ""
                    }
                    onChange={(e) =>
                      formik.setFieldValue(
                        "policy_title",
                        e.target.value.trimStart()
                      )
                    }
                    onBlur={(e) => {
                      formik.setFieldTouched("policy_title", true);
                      formik.setFieldValue(
                        "policy_title",
                        e.target.value.replace(/\s+/g, " ").trim()
                      );
                    }}
                  />
                  <small className="text-danger">
                    {formik.touched.policy_title &&
                    formik.errors &&
                    formik.errors.policy_title
                      ? formik.errors.policy_title
                      : ""}
                  </small>
                </div>
                <div className="form-group col-md-12">
                  <label
                    htmlFor="policy_body"
                    className="col-form-label w-100 mb-2"
                  >
                    Content
                    <button
                      className="btn-link-common float-right"
                      onClick={() => setOpenContent(true)}
                    >
                      Use Editor
                    </button>
                  </label>
                  <textarea
                    className="form-control"
                    id="policy_body"
                    name="policy_body"
                    value={
                      formik.values && formik.values.policy_body
                        ? formik.values.policy_body
                        : ""
                    }
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  <small className="text-danger">
                    {formik.touched.policy_body &&
                    formik.errors &&
                    formik.errors.policy_body
                      ? formik.errors.policy_body
                      : ""}
                  </small>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="row">
                <div className="col-md-12 form-group">
                  <label
                    htmlFor="policy_url"
                    className="col-form-label w-100 mb-2"
                  >
                    URL
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="policy_url"
                    name="policy_url"
                    // maxLength="30"
                    value={
                      formik.values && formik.values.policy_url
                        ? formik.values.policy_url
                        : ""
                    }
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  <small className="text-danger">
                    {formik.touched.policy_url &&
                    formik.errors &&
                    formik.errors.policy_url
                      ? formik.errors.policy_url
                      : ""}
                  </small>
                </div>
                <div className="col-md-12 form-group">
                  <label
                    htmlFor="policy_version"
                    className="col-form-label w-100 mb-2"
                  >
                    Version
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="policy_version"
                    name="policy_version"
                    // maxLength="30"
                    value={
                      formik.values && formik.values.policy_version
                        ? formik.values.policy_version
                        : ""
                    }
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    onKeyPress={isInputNumber}
                  />
                  <small className="text-danger">
                    {formik.touched.policy_version &&
                    formik.errors &&
                    formik.errors.policy_version
                      ? formik.errors.policy_version
                      : ""}
                  </small>
                </div>
                <div className="col-md-12 form-group">
                  <label
                    htmlFor="policy_type"
                    className="col-form-label w-100 mb-2"
                  >
                    Policy Type
                  </label>
                  <select
                    name="policy_type"
                    className="form-control"
                    id="assign-roles"
                    onChange={formik.handleChange}
                    value={
                      formik.values.policy_type ? formik.values.policy_type : ""
                    }
                    onBlur={formik.handleBlur}
                  >
                    <option key="Select" hidden>
                      Select
                    </option>
                    {PolicyType.map((x, i) => (
                      <option value={x.value} key={i}>
                        {x.label}
                      </option>
                    ))}
                  </select>
                  <small className="text-danger">
                    {formik.touched.policy_type &&
                    formik.errors &&
                    formik.errors.policy_type
                      ? formik.errors.policy_type
                      : ""}
                  </small>
                </div>
                <div className="col-md-12 form-group">
                  <label
                    htmlFor="policy_user_type"
                    className="col-form-label w-100 mb-2"
                  >
                    Policy User
                  </label>
                  <select
                    name="policy_user_type"
                    className="form-control"
                    id="policy_user_type"
                    onChange={formik.handleChange}
                    value={
                      formik.values.policy_user_type
                        ? formik.values.policy_user_type
                        : ""
                    }
                    onBlur={formik.handleBlur}
                  >
                    <option key="Select" hidden>
                      Select
                    </option>
                    {["Customer", "OrgEmployee"].map((x, i) => (
                      <option value={x} key={i}>
                        {x}
                      </option>
                    ))}
                  </select>
                  <small className="text-danger">
                    {formik.touched.policy_user_type &&
                    formik.errors &&
                    formik.errors.policy_user_type
                      ? formik.errors.policy_user_type
                      : ""}
                  </small>
                </div>
              </div>
            </div>
            <CustomButton
              type="button"
              className="btn btn-primary btn-outline-success float-right btn-add-domain"
              disabled={
                Object.keys(formik.errors).length !== 0 ||
                !formik.isValid ||
                formik.isSubmitting
              }
              onClick={
                formik.isValid && Object.keys(formik.values).length !== 0
                  ? formik.handleSubmit
                  : handleError
              }
            >
              {formik.values.policy_id ? "Update" : "Create"}
            </CustomButton>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
}

const validation = Yup.object().shape({
  policy_url: Yup.string()
    .matches(
      /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i,
      // /^((http|https):\/\/)?(www.)?(?!.*(http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+(\/)?.([\w\?[a-zA-Z-_%\/@?]+)*([^\/\w\?[a-zA-Z0-9_-]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/,
      "Enter a valid URL!"
    )
    .required("Enter url"),
  policy_version: Yup.string().required("Enter version"),
  policy_title: Yup.string().required("Enter  name"),
  policy_body: Yup.string().required("Enter policy content"),
  // .min(4, "Name should be min of 4 characters")
  // .max(30, "Name should be maximum of 30 characters")
  policy_type: Yup.string().required("Select a type"),
  policy_user_type: Yup.string().required("Select a user type"),
});
