import { useEffect, useState } from "react";
import "./screener.css";
import useScreenerList from "../../utils/CustomHooks/useScreenerList";
import PaginationComponent from "../../components/PaginationComponent";
import GameItem from "./Components/GameItem";
import GameModal from "./modal/GameModal";
import withRoleBased from "../../utils/Functions/withRoleBased";
import useGameConfigList from "../../utils/CustomHooks/useGameConfigList";
import { Tabs } from "antd";
import useInterventionList from "../../utils/CustomHooks/useInterventionList";
import { sortArray } from "../../utils/Functions/filter";
const { TabPane } = Tabs;

function Index({ access = {} }) {
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize] = useState(10);
  const [count, setCount] = useState(0);
  const [game_type, setType] = useState("Intervention");
  const [isOpen, setIsOpen] = useState(false);
  // const [screenerList, setScreeners] = useState([]);
  // const [interventionList, setInterVentions] = useState([]);
  const {
    configList,
    loading: cLoading,
    getConfigList,
  } = useGameConfigList("");
  // const { configList: configAllList, getConfigList: getConfigAllList } =
  //   useGameConfigList("");
  const {
    screenerList: screeners,
    loading: sLoading,
    getScreenerList,
  } = useScreenerList("");
  const {
    interventionList: interventions,
    loading: iLoading,
    getInterventionList,
  } = useInterventionList("");
  const pageCount = Math.ceil(configList.length / pageSize);
  const [search, setSearch] = useState("");
  const [isSearching, setIsSearch] = useState(false);

  const { canCreate } = access;
  const loading = cLoading || sLoading || iLoading;
  useEffect(() => {
    getScreenerList({ screener_format: "Game", published: true }, false);
    getInterventionList({ activity_type: "Game" }, false);
  }, []);

  useEffect(() => {
    fetch();
  }, [count, game_type]);

  // useEffect(() => {
  //   setInterVentions(
  //     interventions
  //       .slice()
  //       .filter(
  //         (x) =>
  //           !configAllList.find((y) => y.intervention_id === x.intervention_id)
  //       )
  //   );
  //   setScreeners(
  //     screeners
  //       .slice()
  //       .filter(
  //         (x) => !configAllList.find((y) => y.screener_id === x.screener_id)
  //       )
  //   );
  // }, [configAllList, screeners, interventions]);
  const fetch = () => {
    let request = { game_type };
    // if (search) request.game_name_key = search;
    getConfigList(request);
    // getConfigAllList({});
  };
  const handleClick = (event, index) => {
    event.preventDefault();
    setCurrentPage(index);
  };

  const onSearch = () => {
    setCurrentPage(0)
    setCount((x) => x + 1);
    setIsSearch(search ? true : false);
  };
  return (
    <main className="page-content">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6 mb-3">
            <h5 className="page-title"> Game Config</h5>
          </div>
          <div className="col-md-6  mb-3 d-flex justify-content-end">
            {canCreate && (
              <button
                type="button"
                className="btn btn-outline-success "
                onClick={() => setIsOpen(true)}
              >
                Add Config
              </button>
            )}
          </div>
          <div className="col-md-4">
            <div className="search" style={{ margin: "16px 0px 32px" }}>
              <input
                type="text"
                className="form-control"
                placeholder="Search"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                onKeyDown={(e) => e.key === "Enter" && onSearch()}
              />
              {!isSearching ? (
                <button onClick={onSearch}>
                  <i className="fa fa-search" />
                </button>
              ) : (
                <button
                  onClick={() => {
                    setSearch("");
                    setIsSearch(false);
                    setCount((x) => x + 1);
                  }}
                >
                  <i className="fa fa-times" />
                </button>
              )}
            </div>
          </div>
          <div className="col-md-12">
            <Tabs centered onTabClick={setType} activeKey={game_type}>
              <TabPane tab="Intervention" key="Intervention"></TabPane>
              <TabPane tab="Screener" key="Screener"></TabPane>
            </Tabs>
          </div>

          <div className="col-md-12">
            <div className="table-responsive">
              <table className="table domain-table table-borderless">
                <thead>
                  <tr className="">
                    <td className="">Content</td>
                    <td className="">Key Name</td>
                    <td className="">Type</td>
                    <td className="">View ID</td>
                    {/* <td className="">Status</td> */}
                    {canCreate && <td className="">Action</td>}
                    {canCreate && <td className="">Activity</td>}
                  </tr>
                </thead>
                <tbody>
                  {!loading && configList && configList.length > 0 ? (
                    sortArray(configList)
                      .slice(
                        currentPage * pageSize,
                        (currentPage + 1) * pageSize
                      )
                      .map((item, i) => (
                        <GameItem
                          item={item}
                          i={i}
                          key={i}
                          refresh={() => {
                            setCount((x) => x + 1);
                          }}
                          access={access}
                          {...{
                            screenerList: screeners,
                            interventionList: interventions,
                          }}
                        />
                      ))
                  ) : (
                    <tr>
                      <td colSpan="6" className="text-center">
                        <span className="font-weight-bold">
                          {loading ? "Loading..." : "Oops! No Data Found"}
                        </span>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {configList.length > 0 && pageCount != 1 && (
          <PaginationComponent
            handleClick={handleClick}
            currentPage={currentPage}
            pageCount={pageCount}
          />
        )}
        {canCreate && (
        <GameModal
          isOpen={isOpen}
          setOpen={setIsOpen}
          refresh={() => {
            setCount((x) => x + 1);
          }}
          isEditable={access.canEdit}
          {...{ screenerList:screeners, interventionList:interventions }}
        />
        )}
      </div>
    </main>
  );
}

export default withRoleBased(Index, "tag");
