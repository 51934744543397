import { useState } from "react";
import { Link } from "react-router-dom";
import { getDescription } from "../../../utils/Functions/filter";
import ReadMore from "../../../components/ReadMore";
import AuthorModal from "../modal/AuthorModal";

export default function AuthorListItem({ item, refresh, access = {} }) {
  const [isEdit, setEdit] = useState(false);

  return (
    <tr>
      <td className="col-sm-2">{item.author_name}</td>
      <td>
        <ReadMore>
          {item &&
          item?.author_description &&
          getDescription(item?.author_description, "Web")
            ? getDescription(item?.author_description, "Web").description
            : "-"}
        </ReadMore>
      </td>
      <td>
        <span
          className={`${item.active ? "good" : "bad"} text-center btn btn-sm`}
        >
          {item.active ? "Active" : "Disabled"}
        </span>
      </td>
      {access.canEdit && (
        <td>
          <button
            type="button"
            className="btn btn-outline-success btn-sm "
            onClick={() => setEdit(true)}
          >
            Edit
          </button>
        </td>
      )}
      <AuthorModal
        isOpen={isEdit}
        setOpen={() => setEdit(false)}
        defaultValues={item}
        refresh={refresh}
      />
    </tr>
  );
}
