import { useEffect, useState } from "react";
import { GET_SCREENER } from "../../Apollo/AdminServices/QueryRequest";
import graphHandler from "../../Apollo/graphHandler";
function useScreener(id) {
  const [screener, setScreener] = useState({});
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (id) {
      getScreener(id);
    } else {
      setScreener({});
    }
  }, [id]);
  const getScreener = async (screener_id) => {
    if (!screener_id) return;
    setLoading(true);
    try {
      const { data } = await graphHandler().invoke(
        GET_SCREENER,
        { screener_id },
        1,
        false,
        true
      );
      if (data && data.getScreener && data.getScreener.screener) {
        // console.log("getScreener Success", data.getScreener.screener);
        setScreener(data.getScreener.screener);
        setLoading(false);
      } else {
        setScreener({});
        setLoading(false);
      }
    } catch (error) {
      console.log("getScreener Error", error);
      setScreener({});
      setLoading(false);
    }
  };
  return {
    screener,
    loading,
    getScreener,
  };
}
export default useScreener;
