import React, { useEffect, useState } from "react";
import { UPDATE_THERAPY_GQL } from "../../../Apollo/AdminServices/MutationRequest";
import graphHandler from "../../../Apollo/graphHandler";
import bg from "../../../assets/Images/bg1.jpg";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { videoDurationCheck } from "../../../components/Media";
import {
  formatBytes,
  GenerateID,
  getDescription,
} from "../../../utils/Functions/filter";
import { isImage, isVideo } from "../../../constants";
import { uploadHandler } from "../../../utils/CustomHooks/useUploadImage";

export default function TherapyData({
  canUpdate,
  setCanUpdate,
  data,
  refresh,
}) {
  const { therapy_id } = data;
  const [editable, setEditable] = useState(false);
  const formik = useFormik({
    initialValues: {},
    validationSchema,
    onSubmit: (value) => onSubmit(value),
  });

  useEffect(() => {
    formik.resetForm();
    setFormikValues(data);
  }, [editable, data]);

  const setFormikValues = (initial) => {
    if (initial) {
      let model = {
        therapy_name: initial.therapy_name,
        screener_result_range_min: initial.screener_result_range_min,
        screener_result_range_max: initial.screener_result_range_max,
      };
      if (
        initial.therapy_description &&
        initial.therapy_description.length > 0
      ) {
        if (getDescription(initial.therapy_description, "Web")) {
          model.therapy_description = getDescription(
            initial.therapy_description,
            "Web"
          ).description;
        }
        if (getDescription(initial.therapy_description, "Mobile")) {
          model.mobile_description = getDescription(
            initial.therapy_description,
            "Mobile"
          ).description;
        }
      }
      if (initial.therapy_image_urls) {
        let url = initial.therapy_image_urls;
        if (url.find((x) => x.image_type == "Default" && !x.is_mobile)) {
          model.image = {
            url: url.find((x) => x.image_type == "Default").url,
            is_mobile: false,
          };
        }
        if (url.find((x) => x.image_type == "Default" && x.is_mobile)) {
          model.mobileImage = {
            url: url.find((x) => x.image_type == "Default").url,
            is_mobile: true,
          };
        }
        if (
          url.find(
            (x) =>
              (x.image_type == "Banner" || x.image_type == "Video") &&
              !x.is_mobile
          )
        ) {
          model.banner = {
            url: url.find(
              (x) =>
                (x.image_type == "Banner" || x.image_type == "Video") &&
                !x.is_mobile
            ).url,
            type: url.find(
              (x) =>
                (x.image_type == "Banner" || x.image_type == "Video") &&
                !x.is_mobile
            ).image_type,
            is_mobile: false,
          };
        }
        if (
          url.find(
            (x) =>
              (x.image_type == "Banner" || x.image_type == "Video") &&
              x.is_mobile
          )
        ) {
          model.mobilebanner = {
            url: url.find(
              (x) =>
                (x.image_type == "Banner" || x.image_type == "Video") &&
                x.is_mobile
            ).url,
            type: url.find(
              (x) =>
                (x.image_type == "Banner" || x.image_type == "Video") &&
                x.is_mobile
            ).image_type,
            is_mobile: true,
          };
        }
      }
      if (
        initial.therapy_document_urls &&
        initial.therapy_document_urls.length > 0
      ) {
        model.document = {
          name: initial.therapy_document_urls[0].name,
          type: initial.therapy_document_urls[0].document_type,
          url: initial.therapy_document_urls[0].url,
        };
      }
      formik.setValues(model);
    }
  };

  const onSubmit = async (value = {}) => {
    // console.log(value);
    const {
      therapy_name,
      therapy_description,
      image,
      mobileImage,
      banner,
      mobilebanner,
      document,
      screener_result_range_min,
      screener_result_range_max,
      mobile_description,
    } = value;
    const update_request = {
      therapy_id,
      therapy_name,
      screener_result_range_min: Number(screener_result_range_min),
      screener_result_range_max: Number(screener_result_range_max),
    };
    let description = [];
    if (therapy_description) {
      description.push({
        client_type: "Web",
        description: therapy_description,
      });
    }
    if (mobile_description) {
      description.push({
        client_type: "Mobile",
        description: mobile_description,
      });
    }
    if (description.length > 0)
      update_request.therapy_description = description;
    let therapy_image_urls = [];
    if (image && image.url)
      therapy_image_urls.push({
        image_type: "Default",
        url: image.url,
        is_mobile: false,
      });
    if (mobileImage && mobileImage.url)
      therapy_image_urls.push({
        image_type: "Default",
        url: mobileImage.url,
        is_mobile: true,
      });
    if (banner && banner.url)
      therapy_image_urls.push({
        image_type: banner.type,
        url: banner.url,
        is_mobile: false,
      });
    if (mobilebanner && mobilebanner.url)
      therapy_image_urls.push({
        image_type: mobilebanner.type,
        url: mobilebanner.url,
        is_mobile: true,
      });
    if (therapy_image_urls.length > 0)
      update_request.therapy_image_urls = therapy_image_urls;

    if (document && document.url)
      update_request.therapy_document_urls = [
        {
          document_type: document.type,
          url: document.url,
          name: document.name,
        },
      ];
    try {
      const { data } = await graphHandler().invoke(
        UPDATE_THERAPY_GQL,
        { update_request },
        1,
        false
      );
      refresh();
      setEditable(false);
      toast.success("Updated Successfully. Approve before updating again.");
      if (data && data.updateTherapy && data.updateTherapy.therapy_history) {
        setCanUpdate(false);
      }
      //   onClose();
    } catch (error) {
      console.log("updateTherapy error", error);
    }
  };

  const handleFileChange = async (event, type = "Image") => {
    const { name, files } = event.target;
    if (!files) return;
    let options = { ...formik.values[name] };
    if (!files[0]) return;
    options = { isUpload: true };
    options.size = formatBytes(files[0].size);
    options.name = files[0].name;
    formik.setFieldValue(name, options);
    const file = new File([files[0]], files[0].name.replace(/\s/g, ""), {
      type: files[0].type,
    });
    if (type === "Image" && isImage(file)) {
      uploadToCloud(file, name, options, type);
    } else if (type === "Video" && isVideo(file)) {
      videoDurationCheck(file)
        .then((res) => {
          uploadToCloud(res.file, name, options, type, res.duration);
        })
        .catch((e) => {
          toast.error(e.error);
          formik.setFieldValue(name, undefined);
        });
    } else if (type === "Document") {
      uploadToCloud(file, name, options, type);
    } else {
      toast.error("Please select a valid " + type.toLowerCase());
      formik.setFieldValue(name, undefined);
    }
  };

  async function uploadToCloud(file, name, obj, type, duration = 0.0) {
    const options = { ...obj };
    try {
      let data = await uploadHandler("intervention").upload(file);
      options.name = file.name;
      options.url = data.url;
      options.isUpload = false;
      options.progress = 100;
      options.type = isImage(file) ? "Banner" : "Video";
      if (type == "Video") {
        options.duration = duration;
      }
      if (type == "Document") {
        options.type = isImage(file)
          ? "Image"
          : file.type === "application/pdf"
          ? "PDF"
          : "Document";
      }
      console.log("Uploading then", options, data);
      formik.setFieldValue(name, options);
    } catch (error) {
      console.log("Upload error", error);
      formik.setFieldValue(name, undefined);
    }
  }

  const handleMinMax = (e, type) => {
    const { name, value } = e.target;
    const re = /^[0-9\b]+$/;
    let temp = !value ? value : re.test(value) ? value : formik.values[name];
    console.log(temp);
    formik.setFieldValue(name, temp ? temp.substring(0, 10) : temp);
  };

  return (
    <>
      <div className="row m-0 w-100">
        <div className="col-md-12 mb-3 file-upload  ">
          {formik.values && formik.values.banner ? (
            formik.values.banner.type === "Video" ? (
              <video
                src={formik.values.banner.url}
                className="opacity-50"
                width="100%"
              />
            ) : (
              <img
                src={formik.values.banner.url}
                className="opacity-50"
                width="100%"
              />
            )
          ) : (
            <img src={bg} className="opacity-50" width="100%" />
          )}
          {canUpdate && (
            <>
              <input
                type="file"
                id="banner"
                name="banner"
                onBlur={formik.handleBlur}
                onChange={(e) => handleFileChange(e, "Image")}
              />
              <span>
                <i
                  className="fas fa-edit ml-5"
                  onClick={() => document.getElementById("banner").click()}
                />
              </span>
            </>
          )}
        </div>

        <div className="col-md-12 mb-3 d-flex">
          <div className="col-md-6 mb-3">
            {!editable ? (
              <b className="page-title"> {data.therapy_name}</b>
            ) : (
              <>
                <input
                  type="text"
                  name="therapy_name"
                  id="therapy_name"
                  onChange={formik.handleChange}
                  placeholder="Screener Name"
                  value={
                    formik.values.hasOwnProperty("therapy_name")
                      ? formik.values.therapy_name
                      : data.therapy_name
                  }
                />
              </>
            )}

            {!editable ? (
              <p>
                {" "}
                {getDescription(data.therapy_description, "Web")
                  ? getDescription(data.therapy_description, "Web").description
                  : ""}{" "}
              </p>
            ) : (
              <textarea
                type="text"
                className="form-control"
                id="therapy_description"
                name="therapy_description"
                onChange={formik.handleChange}
                value={
                  formik.values.hasOwnProperty("therapy_description")
                    ? formik.values.therapy_description
                    : data.therapy_description &&
                      getDescription(data.therapy_description, "Web")
                    ? getDescription(data.therapy_description, "Web")
                        .description
                    : ""
                }
                placeholder="Description"
                // maxLength="150"
              />
            )}

            {!editable ? (
              <p>
                {getDescription(data.therapy_description, "Mobile")
                  ? getDescription(data.therapy_description, "Mobile")
                      .description
                  : ""}{" "}
              </p>
            ) : (
              <textarea
                type="text"
                className="form-control"
                id="mobile_description"
                name="mobile_description"
                onChange={formik.handleChange}
                value={
                  formik.values.hasOwnProperty("mobile_description")
                    ? formik.values.mobile_description
                    : data.therapy_description &&
                      getDescription(data.therapy_description, "Mobile")
                    ? getDescription(data.therapy_description, "Mobile")
                        .description
                    : ""
                }
                placeholder="Description"
                // maxLength="150"
              />
            )}
          </div>
          <div className="col-md-6  mb-3 text-right">
            {canUpdate && (
              <button id="edit_content" className="btn-edit-screener">
                <i
                  className="fas fa-edit"
                  onClick={() => setEditable(!editable)}
                />
              </button>
            )}
            <span className="content2">
              <b>Status: </b>Approved
            </span>
            <div className="content2">
              <div className="average m-0 d-flex justify-content-end row">
                {!editable ? (
                  <>
                    <span>
                      {data.screener_result_range_min
                        ? data.screener_result_range_min
                        : "0"}
                      -
                      {data.screener_result_range_max
                        ? data.screener_result_range_max
                        : "0"}{" "}
                      Mark
                    </span>
                  </>
                ) : (
                  <div className="row d-flex justify-content-end ">
                    <div className="col-md-3 p-0 pr-1 form-group">
                      <label
                        htmlFor="screener_result_range_min"
                        className="col-form-label w-100 mb-2"
                      >
                        Result Min
                      </label>
                      <input
                        type="text"
                        className="form-control "
                        id="screener_result_range_min"
                        {...formik.getFieldProps("screener_result_range_min")}
                        onChange={(e) => handleMinMax(e, "min")}
                      />
                      <small className="text-danger align-self-end">
                        {formik.touched.screener_result_range_min &&
                        formik.errors &&
                        formik.errors.screener_result_range_min
                          ? formik.errors.screener_result_range_min
                          : ""}
                      </small>
                    </div>
                    <div className="col-md-3 p-0 form-group">
                      <label
                        htmlFor="screener_result_range_max"
                        className="col-form-label w-100 mb-2"
                      >
                        Result Max
                      </label>
                      <input
                        type="text"
                        className="form-control "
                        id="screener_result_range_max"
                        {...formik.getFieldProps("screener_result_range_max")}
                        onChange={(e) => handleMinMax(e, "max")}
                      />
                      <small className="text-danger align-self-end">
                        {formik.touched.screener_result_range_max &&
                        formik.errors &&
                        formik.errors.screener_result_range_max
                          ? formik.errors.screener_result_range_max
                          : ""}
                      </small>
                    </div>
                  </div>
                )}
                {editable && (
                  <div className="col-12">
                    <button
                      className="btn btn-primary btn-outline-success btn-sm btn-add-domain mt-2"
                      disabled={Object.keys(formik.errors).length !== 0}
                      onClick={formik.handleSubmit}
                    >
                      Save
                    </button>
                    <button
                      className="btn  btn-sm mt-2 ml-2"
                      onClick={() => {
                        setEditable(false);
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                )}{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="row m-0 w-100">
        <div className="col-md-6  mb-3">
          <div className="imgMask">
            <div className="imgMask-content">+</div>
          </div>
          <input type="file" className="imgg" id="imgInp" name="files" />
          <img id="sub-domain-img" src="#" alt="." />
          <b>Game Doc</b>
        </div>
      </div> */}
    </>
  );
}

const validationSchema = Yup.object().shape({
  therapy_name: Yup.string().required("Enter name"),
  // .min(4, "Name should be min of 4 characters")
  // .max(30, "Name should be maximum of 30 characters")
  therapy_description: Yup.string().required("Enter description"),
  image: Yup.object(),
  mobileImage: Yup.object(),
  banner: Yup.object(),
  mobilebanner: Yup.object(),
  document: Yup.object(),
  screener_result_range_min: Yup.string().required("Enter min result mark"),
  screener_result_range_max: Yup.string().required("Enter max result mark"),
});
