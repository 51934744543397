import { useEffect, useState } from "react";
import { GET_EXPLAINER_LIST } from "../../Apollo/AdminServices/QueryRequest";
import graphHandler from "../../Apollo/graphHandler";
function useExplainerList(preLoad = false) {
  const [explainerList, setExplainers] = useState([]);
  const [loading, setLoading] = useState(preLoad);

  const getExplainerList = async (req = {}) => {
    setLoading(true);
    try {
      const { data } = await graphHandler().invoke(
        GET_EXPLAINER_LIST,
        req,
        1,
        false,
        true
      );
      if (
        data &&
        data.getExplainerList &&
        data.getExplainerList.explainers &&
        data.getExplainerList.explainers.length > 0
      ) {
        // console.log(
        //   "getExplainerList Success",
        //   data.getExplainerList.explainers
        // );

        setExplainers([]);
        setExplainers(data.getExplainerList.explainers);
        setLoading(false);
      } else {
        setExplainers([]);
        setLoading(false);
      }
    } catch (error) {
      console.log("getExplainerList Error", error);
      setExplainers([]);
      setLoading(false);
    }
  };
  return {
    explainerList,
    loading,
    getExplainerList,
    resetStories: () => setExplainers([]),
  };
}
export default useExplainerList;
