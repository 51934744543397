import { useFormik } from "formik";
import { useEffect, useState, useMemo } from "react";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { UPDATE_TOPIC_GQL } from "../../../Apollo/AdminServices/MutationRequest";
import graphHandler from "../../../Apollo/graphHandler";
import CustomButton from "../../../components/Button/CustomButton";
import NewReactSelectInputField from "../../../components/HistoryEditInput/NewReactSelectInputField";
import SelectInputField from "../../../components/HistoryEditInput/SelectInputField";
import TextInputField from "../../../components/HistoryEditInput/TextInputField";
import { AggregationTypes } from "../../../constants";
import {
  mergeMultiSelectArray,
  objectHasKey,
} from "../../../utils/Functions/filter";

export default function UpdateTopicSection({
  topic = {},
  setLoading = () => {},
  refresh = () => {},
  refreshQues = () => {},
  reset = () => {},
  domainList = [],
}) {
  const pending_history = topic.pending_history ? topic.pending_history : {};
  const hasUpdated = Boolean(pending_history && pending_history.updated_data);
  const updated_data = hasUpdated ? pending_history.updated_data : {};
  const { screener_topic_id } = topic;
  const [selectedList, setSelectedList] = useState([]);
  const formik = useFormik({
    initialValues: {},
    validationSchema: validation,
    onSubmit: (values) => {
      onSubmit(values);
    },
  });
  useEffect(() => {
    if (topic?.screener_topic_id) {
      onSetDefault(topic, hasUpdated ? updated_data : {});
    }
  }, [topic,AggregationTypes]);

  const onSetDefault = async (data = {}, publish = {}) => {
    const { screener_topic_aggregate_type } = data;
    if (AggregationTypes.length > 0) {
      let subArray = [],
        formatArray =
          (await screener_topic_aggregate_type) && screener_topic_aggregate_type.length > 0
            ? screener_topic_aggregate_type
            : [],
        publishedSub =
          (await publish.screener_topic_aggregate_type) &&
          publish.screener_topic_aggregate_type.update_items &&
          publish.screener_topic_aggregate_type.update_items.length > 0
            ? publish.screener_topic_aggregate_type.update_items
            : [];
      let value = await mergeMultiSelectArray(formatArray, publishedSub);
      let i = 0;
      subArray = value.map((x) => {
        let ret = {
          ...AggregationTypes.find((y) => y.value === x.data),
          ...x,
          index: i,
        };
        if (x.isRemoved) {
          ret.value =
          AggregationTypes.find((y) => y.value === x.data).label +
              Date.now() || "";
        } else {
          i++;
        }
        return ret;
      });
      setSelectedList(subArray);
    }
  };

  useEffect(() => {
    refresh();
    refreshQues();
  }, []);

  useEffect(() => {}, [topic]);

  const onSubmit = async (formikValues) => {
    formik.setSubmitting(true);
    setLoading(true);
    let {
      screener_topic_title,
      domain_id,
      screener_result_calculation_type,
      screener_topic_aggregate_type,
    } = formikValues;
    let update_request = {
      screener_topic_id,
    };
    if (objectHasKey(formikValues, "screener_topic_title")) {
      update_request.screener_topic_title = screener_topic_title;
    }
    if (objectHasKey(formikValues, "screener_result_calculation_type")) {
      update_request.screener_result_calculation_type =
        screener_result_calculation_type;
    }
    if (objectHasKey(formikValues, "domain_id")) {
      update_request.domain_id = domain_id;
    }

    if (objectHasKey(formikValues, "screener_topic_aggregate_type")) {
      update_request.screener_topic_aggregate_type = {
        update_items: screener_topic_aggregate_type,
      };
    }

    try {
      const { data } = await graphHandler().invoke(
        UPDATE_TOPIC_GQL,
        { update_request },
        1,
        false,
        true
      );
      if (
        data &&
        data.updateScreenerTopic &&
        data.updateScreenerTopic.screener_topic
      ) {
        toast.success("Updated Successfully");
        refresh();
        setLoading(false);
        formik.setSubmitting(false);
        resetModal();
      }
    } catch (error) {
      console.error("updateScreenerTopic Error", error);
      setLoading(false);
      formik.setSubmitting(false);
    }
  };

  const handleError = () => {
    if (Object.keys(formik.errors).length > 0) {
      toast.error(formik.errors[Object.keys(formik.errors)[0]]);
    }
  };

  const resetModal = () => {
    formik.resetForm();
    formik.setValues({});
    formik.setSubmitting(false);
    reset();
  };

  return (
    <>
      <div className="col-md-12 modal-header" id="CreateTopic">
        <h5 className="modal-title text-center mb-3">Update Topic</h5>
      </div>
      <div className="col-md-12">
        <div className="border">
          <div className="row p-3">
            <TextInputField
              parentForm={formik}
              className="col-md-6"
              updateValue={updated_data.screener_topic_title}
              actualValue={topic.screener_topic_title}
              id="screener_topic_title"
              title="Title"
              validation={{
                screener_topic_title: Yup.string()
                  .trim()
                  .required("Enter title"),
              }}
            />
            <NewReactSelectInputField
              parentForm={formik}
              className="col-md-6"
              id="screener_topic_aggregate_type"
              title="Aggregation Type"
              placeholder="Select Aggregation Type"
              options={AggregationTypes}
              isPublished={topic.published}
              isCreatable={false}
              isMulti={true}
              defaultValue={selectedList}
              updateValue={
                updated_data.screener_topic_aggregate_type &&
                updated_data.screener_topic_aggregate_type.update_items &&
                updated_data.screener_topic_aggregate_type.update_items.length >
                  0
                  ? updated_data.screener_topic_aggregate_type.update_items
                  : false
              }
              validation={{
                screener_topic_aggregate_type: Yup.array().of(
                  Yup.object().shape({
                    label: Yup.string(),
                    value: Yup.string(),
                  })
                ),
                //   .test({
                //     message: "Select aggregate type",
                //     test: (arr) => {
                //       return arr && arr.length > 0;
                //     },
                //   }),
              }}
            />
            <SelectInputField
              parentForm={formik}
              className="col-md-6"
              updateValue={updated_data.screener_result_calculation_type || ""}
              actualValue={topic.screener_result_calculation_type || ""}
              id="screener_result_calculation_type"
              title="Calculation Type"
              //   validation={{
              //     screener_result_calculation_type: Yup.string().required(
              //       "Select calculation type"
              //     ),
              //   }}
              options={[
                { label: "Select", value: "", props: { hidden: true } },
                {
                  label: "Average",
                  value: "Average",
                },
                {
                  label: "Total",
                  value: "Total",
                },
              ]}
            />

            <SelectInputField
              parentForm={formik}
              className="col-md-6"
              updateValue={updated_data.domain_id}
              actualValue={topic.domain_id}
              id="domain_id"
              title="Domain"
              // validation={{
              //   domain_id: Yup.string(),
              // }}
              options={[
                { label: "Select", value: "", props: { hidden: true } },
                ...domainList.map((x) => ({
                  label: x.domain_name,
                  value: x.domain_id,
                })),
              ]}
            />
            <div
              className={"d-flex align-items-end justify-content-end col-md-12"}
            >
              <button
                onClick={resetModal}
                type="button"
                className="btn btn-light btn-sm float-right btn-add-domain mr-2"
              >
                Reset
              </button>
              <CustomButton
                type="button"
                className="btn btn-primary btn-outline-success btn-sm float-right btn-add-domain"
                disabled={
                  Object.keys(formik.errors).length !== 0 ||
                  !formik.isValid ||
                  formik.isSubmitting
                }
                onClick={
                  formik.isValid && Object.keys(formik.values).length !== 0
                    ? formik.handleSubmit
                    : handleError
                }
              >
                Update
              </CustomButton>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const validation = Yup.object().shape({});
