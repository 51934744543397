import CustomLoader from "../../../components/Loader";
import { useCSVDownloader } from "react-papaparse";

export default function UploadSection({
  onClose = () => {},
  onNext = () => {},
  onBack = () => {},
  setOpen = () => {},
  isProcessing,
  errorData = [],
  successData = [],
}) {
  const { CSVDownloader, Type } = useCSVDownloader();
  return isProcessing ? (
    <CustomLoader />
  ) : (
    <div className="row">
      {successData.length === 0 && errorData.length > 0 ? (
        <div className="container final-container">
          <div className="w-100 text-danger text">
            Upload failed for all {errorData.length} questions
            <CSVDownloader
              type={Type.Button}
              filename={"Questions"}
              className="ml-2 btn btn-success float-right"
              data={errorData}
            >
              Download File
              <i className="fas fa-file-download ml-2" />
            </CSVDownloader>
          </div>
        </div>
      ) : successData.length > 0 && errorData.length > 0 ? (
        <div className="container final-container">
          <div className="w-100 text-success text">
            {successData.length} questions uploaded successfully
          </div>
          <div className="w-100 text-danger text">
            Failed to upload {errorData.length} questions
            <CSVDownloader
              type={Type.Button}
              filename={"Questions"}
              className="ml-2 btn btn-success float-right"
              data={errorData}
            >
              Download File
              <i className="fas fa-file-download ml-2" />
            </CSVDownloader>
          </div>
        </div>
      ) : (
        <div className="container final-container">
          <div className="w-100 text-success text">
            {successData.length} questions uploaded successfully
          </div>
        </div>
      )}
      <div className="col-12 d-flex align-items-end justify-content-end">
        <button
          onClick={onClose}
          type="button"
          className="btn btn-danger btn-sm float-right btn-add-domain mr-2"
        >
          Close
        </button>
        <button
          onClick={onBack}
          type="button"
          className="btn btn-secondary btn-sm float-right btn-add-domain mr-2"
        >
          Back
        </button>
      </div>
    </div>
  );
}
