import { useState } from "react";
import UpdateCategory from "../modal/UpdateCategory";
import { Link } from "react-router-dom";
import { getDescription } from "../../../utils/Functions/filter";
import ReadMore from "../../../components/ReadMore";

export default function ListItem({ item, refresh, access = {} }) {
  const [isEdit, setEdit] = useState(false);

  return (
    <tr>
      <td className="col-sm-2">{item.category_name}</td>
      <td>
        <ReadMore>
          {item.category_description &&
          getDescription(item.category_description, "Web")
            ? getDescription(item.category_description, "Web").description
            : "-"}
        </ReadMore>
      </td>
      <td>
        {item.supported_content_types
          ? item.supported_content_types.join(", ")
          : "-"}
      </td>
      <td>
        <span
          className={`${item.active ? "good" : "bad"} text-center btn btn-sm`}
        >
          {item.active ? "Active" : "Disabled"}
        </span>
      </td>
      {access.canEdit && (
        <td>
          <button
            type="button"
            className="btn btn-outline-success btn-sm "
            onClick={() => setEdit(true)}
          >
            Edit
          </button>
        </td>
      )}
      <UpdateCategory
        isOpen={isEdit}
        setOpen={() => setEdit(false)}
        initialValue={item}
        refresh={refresh}
      />
    </tr>
  );
}
