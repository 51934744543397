import { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  createArrayByUpdate,
  mergeMetaArray,
} from "../../utils/Functions/filter";
import HistoryImageTableItem from "./Components/HistoryImageTableItem";
import UpdateImageCard from "./Components/UpdateImageCard";

const ImageHistoryInput = (props) => {
  const [isView, setVisible] = useState(false);
  let {
    parentForm = {},
    validation = {},
    className = "col-md-12",
    actualValue = [],
    updateValue = [],
    contentId,
    contentType,
    parentArray = "therapy_image_urls",
    heading = "Image File",
  } = props;

  useEffect(() => {
    if (updateValue && updateValue.length > 0 && parentForm) {
      parentForm.setFieldValue(parentArray, updateValue);
    }
  }, [updateValue]);

  const formik = useFormik({
    initialValues: {},
    validationSchema: Yup.object().shape(validation),
    onSubmit: () => {},
  });

  const formik1 = useFormik({
    initialValues: {},
    validationSchema: Yup.object().shape({
      is_mobile: Yup.boolean().required("Select a device"),
      image_type: Yup.string().required("Select a format"),
      mediaUrl: Yup.object().required("upload a file"),
    }),
    onSubmit: (values) => onSubmit(values),
  });
  const isUpdate = Number.isInteger(formik1.values.index);

  useEffect(() => {
    if (actualValue && actualValue.length > 0) {
      let array = [];
      if (!updateValue || updateValue.length === 0) {
        array = actualValue.map((x, index) => {
          return { ...x, index };
        });
      } else {
        let value = mergeMetaArray(actualValue, updateValue);
        value.map((x, index) => {
          let returnData = { ...x, index };
          if (x.isRemoved) returnData.index = index;
          array.push(returnData);
        });
      }
      formik.setValues({ [parentArray]: array });
    } else if (updateValue && updateValue.length > 0) {
      let array = [],
        value = createArrayByUpdate(updateValue);
      value.map((x, index) => {
        let returnData = { ...x, index };
        if (x.isRemoved) returnData.index = index;
        array.push(returnData);
      });
      formik.setValues({ [parentArray]: array });
    }
  }, [actualValue, updateValue]);

  const onSubmit = (values = {}) => {
    let modal = { ...values, ...values.mediaUrl };
    let media_data = [
        ...(formik.values[parentArray] ? formik.values[parentArray] : []).map(
          (x) => x
        ),
      ],
      parentData = [
        ...(parentForm.values[parentArray]
          ? parentForm.values[parentArray]
          : []
        ).map((x) => x),
      ];
    if (!values.image_type || !values.is_mobile.toString()) {
      return console.error("Data Not found", values);
    }

    if (Number.isInteger(modal.index) && media_data.length > modal.index) {
      delete modal.index;
      parentData.push({
        update_type: "Update",
        index: values.index,
        update_data: {
          image_type: values.image_type,
          url: values.mediaUrl.url,
          is_mobile: `${values.is_mobile}` === "true" ? true : false,
        },
      });
      media_data[values.index] = { ...modal, index: values.index,update_type: "Update" };
      formik.setFieldValue(parentArray, [...media_data]);
      parentForm.setFieldValue(parentArray, [...parentData]);
      reset();
    } else {
      formik.setFieldValue(parentArray, [
        ...media_data,
        { ...modal, index: media_data.length, update_type: "Add" },
      ]);
      parentData.push({
        update_type: "Add",
        update_data: {
          image_type: values.image_type,
          url: values.mediaUrl.url,
          is_mobile: `${values.is_mobile}` === "true" ? true : false,
        },
      });
      parentForm.setFieldValue(parentArray, [...parentData]);
    }
    reset();
    setVisible(false);
  };

  const remove = (values, i, arrayIndex) => {
    const parentData = [
      ...(parentForm.values[parentArray]
        ? parentForm.values[parentArray]
        : []
      ).map((x) => x),
    ];
    const media_data = [
      ...(formik.values[parentArray] ? formik.values[parentArray] : []).map(
        (x) => x
      ),
    ];

    if (isUpdate && formik1.values.index === media_data[arrayIndex].index) {
      reset();
    }
    media_data.splice(arrayIndex, 1);
    parentData.push({
      update_type: "Remove",
      index: i,
      update_data: {
        image_type: values.image_type,
        url: values.url,
        is_mobile: `${values.is_mobile}` === "true" ? true : false,
      },
    });
    parentForm.setFieldValue(parentArray, [...parentData]);
    formik.setFieldValue(parentArray, media_data);
  };

  const reset = () => {
    formik1.resetForm();
    formik1.setTouched({});
    formik1.setValues({});
  };
  const onEdit = (values = {}) => {
    setVisible(true);
    formik1.setValues({
      ...values,
      mediaUrl: {
        url: values.url,
      },
    });
    const container = document.getElementById("mediaSection" + parentArray);
    if (container) container.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div
      className={`form-group ${className}`}
      id={"mediaSection" + parentArray}
    >
      <div className="row mb-2">
        <div style={{ display: "flex", alignItems: "center" }}>
          <label className="col-form-label w-100">{heading} </label>
          {!isView && (
            <button
              className="btn btn-outline-success ml-3 btn-sm"
              disabled={props.disabled}
              onClick={() => setVisible(true)}
            >
              Add Image
            </button>
          )}
        </div>
      </div>
      {formik.values[parentArray] && formik.values[parentArray].length > 0 ? (
        <div className="row ">
          <div
            className="table-responsive mb-3 table-borderless  questionnaire-table"
            style={{ maxHeight: "200px" }}
          >
            <table className="table  table-striped mb-0">
              <thead>
                <tr>
                  <th className="col-sm-1">#</th>
                  <th className="col-sm-2">File</th>
                  <th className="col-sm-3">name</th>
                  <th className="col-sm-2">Device</th>
                  <th className="col-sm-2">Format</th>
                  {!props.disabled && <th className="col-sm-2">Action</th>}
                </tr>
              </thead>
              <tbody>
                {formik.values[parentArray]
                  .slice()
                  .sort((a, b) => a.sort_order - b.sort_order)
                  .map((x, i) => (
                    <HistoryImageTableItem
                      key={i}
                      arrayIndex={i}
                      item={x}
                      {...props}
                      remove={remove}
                      onEdit={onEdit}
                    />
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      ) : null}
      {isView && !props.disabled && (
        <>
          <UpdateImageCard
            formik={formik1}
            reset={reset}
            setVisible={setVisible}
            {...props}
            contentType={contentType}
            contentId={contentId}
          />
        </>
      )}
    </div>
  );
};

export default ImageHistoryInput;
