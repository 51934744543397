import { gql } from "@apollo/client";
import { serviceResponse } from "./DefaultResponse";

export const GET_USER_ADMIN_ROLES_GQL = gql`
query getAllAdminRoles ($user_id: String!) {
    getAllAdminRoles (user_id: $user_id) {
        ${serviceResponse}
        roles {
            created_at
            created_by
            updated_at
            updated_by
            row_crc
            admin_role_id
            user_id
            sub_domain_id
            role_type
            active
        }
    }
}`;

export const GET_SUBDOMAIN_LIST_GQL = gql`
  query getSubDomainList(
    $pagination: PaginationFilter
    $dateFilter: DateFilter
    $active: Boolean
    $domain_id: String
    $sub_domain_ids: [String!]
    $sub_domain_name: String
    $sort_order: Float
    $visibility_status: visibilityStatus
    $sub_domain_code: String
    $alias_name: String
  ) {
    getSubDomainList(
      pagination: $pagination
      dateFilter: $dateFilter
      active: $active
      domain_id: $domain_id
      sub_domain_ids: $sub_domain_ids
      sub_domain_name: $sub_domain_name
      sort_order: $sort_order
      visibility_status: $visibility_status
      sub_domain_code: $sub_domain_code
      alias_name: $alias_name
    ) {
      status
      error
      message
      version
      service
      pagination {
        page_number
        item_count
        total_count
      }
      sub_domains {
        created_at
        created_by
        updated_at
        updated_by
        row_crc
        sub_domain_id
        domain_id
        sub_domain_name
        alias_name
        sort_order
        sub_domain_description {
          client_type
          description
        }
        sub_domain_image_urls {
          image_url_id
          image_type
          url
          is_mobile
          video_stream {
            provider_u_id
            provider_thumbnail_url
            provider_preview_url
            provider_ready_to_stream
            provider_file_size
            provider_stream_duration
            provider_playback_hls_url
            provider_playback_dash_url
          }
        }
        visibility_status
        sub_domain_code
        sub_domain_meta {
          type
          key
          value
          image_urls {
            image_url_id
            image_type
            url
            is_mobile
            video_stream {
              provider_u_id
              provider_thumbnail_url
              provider_preview_url
              provider_ready_to_stream
              provider_file_size
              provider_stream_duration
              provider_playback_hls_url
              provider_playback_dash_url
            }
          }
        }
        active
      }
    }
  }
`;

export const GET_DEFAULT_CHOICES_GQL = gql`
  query getDefaultScreenerQuestionChoices {
    getDefaultScreenerQuestionChoices {
      status
      error
      message
      version
      service
      default_choices {
        default_option_name
        screener_question_type
        option_type
        options {
          option_type
          options {
            data
            value
            flag
          }
          start_value
          end_value
          step
        }
      }
    }
  }
`;

export const GET_DOMAIN_GQL = gql`
  query getDomain($domain_id: String!) {
    getDomain(domain_id: $domain_id) {
      status
      error
      message
      version
      service
      domain {
        created_at
        created_by
        updated_at
        updated_by
        row_crc
        domain_id
        domain_name
        alias_name
        sort_order
        domain_description {
          client_type
          description
        }
        domain_image_urls {
          image_url_id
          image_type
          url
          is_mobile
        }
        active
      }
    }
  }
`;

export const GET_DOMAIN_LIST_GQL = gql`
  query getDomainList(
    $pagination: PaginationFilter
    $dateFilter: DateFilter
    $active: Boolean
    $domain_name: String
    $sort_order: Float
    $alias_name: String
  ) {
    getDomainList(
      pagination: $pagination
      dateFilter: $dateFilter
      active: $active
      domain_name: $domain_name
      sort_order: $sort_order
      alias_name: $alias_name
    ) {
      status
      error
      message
      version
      service
      pagination {
        page_number
        item_count
        total_count
      }
      domains {
        created_at
        created_by
        updated_at
        updated_by
        row_crc
        domain_id
        domain_name
        alias_name
        sort_order
        domain_description {
          client_type
          description
        }
        domain_image_urls {
          image_url_id
          image_type
          url
          is_mobile
          video_stream {
            provider_u_id
            provider_thumbnail_url
            provider_preview_url
            provider_ready_to_stream
            provider_file_size
            provider_stream_duration
            provider_playback_hls_url
            provider_playback_dash_url
          }
        }
        active
      }
    }
  }
`;

export const GET_AUTHOR_LIST_GQL = gql`
  query getAuthorList(
    $pagination: PaginationFilter
    $dateFilter: DateFilter
    $active: Boolean
    $author_id: String
    $author_ids: [String!]
    $author_name: String
    $author_code: String
  ) {
    getAuthorList(
      pagination: $pagination
      dateFilter: $dateFilter
      active: $active
      author_id: $author_id
      author_ids: $author_ids
      author_name: $author_name
      author_code: $author_code
    ) {
      status
      error
      message
      version
      service
      pagination {
        page_number
        item_count
        total_count
      }
      authors {
        created_at
        created_by
        updated_at
        updated_by
        row_crc
        author_id
        author_name
        author_code
        author_description {
          client_type
          description
        }
        author_image_urls {
          image_url_id
          image_type
          url
          is_mobile
          video_stream {
            provider_u_id
            provider_thumbnail_url
            provider_preview_url
            provider_ready_to_stream
            provider_file_size
            provider_stream_duration
            provider_playback_hls_url
            provider_playback_dash_url
          }
        }
        author_social_urls {
          provider
          link
        }
        active
      }
    }
  }
`;

export const GET_QUESTIONAIRE_LIST_GQL = gql`
  query getScreenerQuestionList(
    $get_pending_update: Boolean
    $published: Boolean
    $is_featured: Boolean
    $is_pilot: Boolean
    $is_live: Boolean
    $platform_content_mode_tag: PlatformContentModeTag
    $pagination: PaginationFilter
    $dateFilter: DateFilter
    $active: Boolean
    $sub_domain_id: String
    $screener_question_ids: [String!]
    $screener_topic_id: String
    $screener_id: String
    $screener_question_id: String
    $screener_question_title: String
  ) {
    getScreenerQuestionList(
      get_pending_update: $get_pending_update
      published: $published
      is_featured: $is_featured
      is_pilot: $is_pilot
      is_live: $is_live
      platform_content_mode_tag: $platform_content_mode_tag
      pagination: $pagination
      dateFilter: $dateFilter
      active: $active
      sub_domain_id: $sub_domain_id
      screener_question_ids: $screener_question_ids
      screener_topic_id: $screener_topic_id
      screener_id: $screener_id
      screener_question_id: $screener_question_id
      screener_question_title: $screener_question_title
    ) {
      status
      error
      message
      version
      service
      pagination {
        page_number
        item_count
        total_count
      }
      screener_questions {
        screener_question {
          created_at
          created_by
          updated_at
          updated_by
          row_crc
          sub_domain_ids
          content_version
          update_counter
          published
          active
          screener_question_id
          sub_domain_id
          screener_topic_id
          screener_question_title
          screener_question_descriptions {
            client_type
            description
          }
          screener_question_image_urls {
            image_url_id
            image_type
            url
            is_mobile
            video_stream {
              provider_u_id
              provider_thumbnail_url
              provider_preview_url
              provider_ready_to_stream
              provider_file_size
              provider_stream_duration
              provider_playback_hls_url
              provider_playback_dash_url
            }
          }
          screener_question_type
          mandatory
          sort_order
          screener_id
          default_option_name
          option_type
          options {
            option_type
            options {
              data
              value
              flag
            }
            start_value
            end_value
            step
          }
          result_weightage
          ignore_for_scoring
        }
        pending_screener_question_history {
          created_at
          created_by
          updated_at
          updated_by
          row_crc
          content_version
          update_counter
          content_type
          update_status
          update_type
          child_content_histories {
            child_content_history_id
            child_content_type
          }
          publish_data {
            created_by
            updated_by
            row_crc
            sub_domain_ids
            content_version
            update_counter
            published
            active
            screener_question_id
            sub_domain_id
            screener_topic_id
            screener_question_title
            screener_question_descriptions {
              client_type
              description
            }
            screener_question_image_urls {
              image_url_id
              image_type
              url
              is_mobile
              video_stream {
                provider_u_id
                provider_thumbnail_url
                provider_preview_url
                provider_ready_to_stream
                provider_file_size
                provider_stream_duration
                provider_playback_hls_url
                provider_playback_dash_url
              }
            }
            screener_question_type
            mandatory
            sort_order
            screener_id
            default_option_name
            option_type
            options {
              option_type
              options {
                data
                value
                flag
              }
              start_value
              end_value
              step
            }
            result_weightage
            ignore_for_scoring
          }
          publish_at
          reviewer_comment
          reviewed_by
          reviewed_at
          approver_comment
          approved_by
          approved_at
          data {
            created_by
            updated_by
            row_crc
            sub_domain_ids
            content_version
            update_counter
            published
            active
            screener_question_id
            sub_domain_id
            screener_topic_id
            screener_question_title
            screener_question_descriptions {
              client_type
              description
            }
            screener_question_image_urls {
              image_url_id
              image_type
              url
              is_mobile
              video_stream {
                provider_u_id
                provider_thumbnail_url
                provider_preview_url
                provider_ready_to_stream
                provider_file_size
                provider_stream_duration
                provider_playback_hls_url
                provider_playback_dash_url
              }
            }
            screener_question_type
            mandatory
            sort_order
            screener_id
            default_option_name
            option_type
            options {
              option_type
              options {
                data
                value
                flag
              }
              start_value
              end_value
              step
            }
            result_weightage
            ignore_for_scoring
          }
          updated_data {
            screener_topic_id
            screener_id
            sub_domain_id
            sub_domain_ids {
              update_items {
                update_type
                index
                update_data
              }
              IsValid
              MergeData
            }
            screener_question_title
            screener_question_descriptions {
              update_items {
                update_type
                index
                update_data {
                  client_type
                  description
                }
              }
            }
            screener_question_image_urls {
              update_items {
                update_type
                index
                update_data {
                  image_url_id
                  image_type
                  url
                  is_mobile
                  video_stream {
                    provider_u_id
                    provider_thumbnail_url
                    provider_preview_url
                    provider_ready_to_stream
                    provider_file_size
                    provider_stream_duration
                    provider_playback_hls_url
                    provider_playback_dash_url
                  }
                }
              }
            }
            screener_question_type
            option_type
            options {
              option_type
              options {
                data
                value
                flag
              }
              start_value
              end_value
              step
              options_update {
                update_items {
                  update_type
                  index
                  update_data {
                    data
                    value
                    flag
                  }
                }
              }
            }
            default_option_name
            result_weightage
            mandatory
            sort_order
            ignore_for_scoring
            published
            content_version
            update_counter
            active
          }
          screener_question_id
          screener_question_history_id
          screener_topic_id
        }
      }
    }
  }
`;

export const GET_TOPIC_LIST_GQL = gql`
  query getScreenerTopicList(
    $get_pending_update: Boolean
    $published: Boolean
    $is_featured: Boolean
    $is_pilot: Boolean
    $is_live: Boolean
    $platform_content_mode_tag: PlatformContentModeTag
    $pagination: PaginationFilter
    $dateFilter: DateFilter
    $active: Boolean
    $screener_id: String
    $domain_id: String
    $screener_topic_aggregate_type: [ScreenerTopicAggregationType!]
    $screener_topic_ids: [String!]
    $screener_topic_title: String
    $screener_result_calculation_type: ScreenerResultCalculationType
  ) {
    getScreenerTopicList(
      get_pending_update: $get_pending_update
      published: $published
      is_featured: $is_featured
      is_pilot: $is_pilot
      is_live: $is_live
      platform_content_mode_tag: $platform_content_mode_tag
      pagination: $pagination
      dateFilter: $dateFilter
      active: $active
      screener_id: $screener_id
      domain_id: $domain_id
      screener_topic_aggregate_type: $screener_topic_aggregate_type
      screener_topic_ids: $screener_topic_ids
      screener_topic_title: $screener_topic_title
      screener_result_calculation_type: $screener_result_calculation_type
    ) {
      status
      error
      message
      version
      service
      pagination {
        page_number
        item_count
        total_count
      }
      screener_topics {
        screener_topic {
          created_at
          created_by
          updated_at
          updated_by
          row_crc
          sub_domain_ids
          content_version
          update_counter
          published
          active
          screener_topic_id
          screener_id
          domain_id
          screener_topic_aggregate_type
          screener_result_calculation_type
          screener_topic_title
          screener_topic_descriptions {
            client_type
            description
          }
          screener_topic_image_urls {
            image_url_id
            image_type
            url
            is_mobile
            video_stream {
              provider_u_id
              provider_thumbnail_url
              provider_preview_url
              provider_ready_to_stream
              provider_file_size
              provider_stream_duration
              provider_playback_hls_url
              provider_playback_dash_url
            }
          }
          sort_order
          render_topic
        }
        pending_screener_topic_history {
          created_at
          created_by
          updated_at
          updated_by
          row_crc
          content_version
          update_counter
          content_type
          update_status
          update_type
          child_content_histories {
            child_content_history_id
            child_content_type
          }
          publish_data {
            created_by
            updated_by
            row_crc
            sub_domain_ids
            content_version
            update_counter
            published
            active
            screener_topic_id
            screener_id
            domain_id
            screener_topic_aggregate_type
            screener_result_calculation_type
            screener_topic_title
            screener_topic_descriptions {
              client_type
              description
            }
            screener_topic_image_urls {
              image_url_id
              image_type
              url
              is_mobile
              video_stream {
                provider_u_id
                provider_thumbnail_url
                provider_preview_url
                provider_ready_to_stream
                provider_file_size
                provider_stream_duration
                provider_playback_hls_url
                provider_playback_dash_url
              }
            }
            sort_order
            render_topic
          }
          publish_at
          reviewer_comment
          reviewed_by
          reviewed_at
          approver_comment
          approved_by
          approved_at
          data {
            created_by
            updated_by
            row_crc
            sub_domain_ids
            content_version
            update_counter
            published
            active
            screener_topic_id
            screener_id
            domain_id
            screener_topic_aggregate_type
            screener_result_calculation_type
            screener_topic_title
            screener_topic_descriptions {
              client_type
              description
            }
            screener_topic_image_urls {
              image_url_id
              image_type
              url
              is_mobile
              video_stream {
                provider_u_id
                provider_thumbnail_url
                provider_preview_url
                provider_ready_to_stream
                provider_file_size
                provider_stream_duration
                provider_playback_hls_url
                provider_playback_dash_url
              }
            }
            sort_order
            render_topic
          }
          updated_data {
            screener_id
            domain_id
            sub_domain_ids {
              update_items {
                update_type
                index
                update_data
              }
              IsValid
              MergeData
            }
            screener_result_calculation_type
            screener_topic_aggregate_type {
              update_items {
                update_type
                index
                update_data
              }
              IsValid
              MergeData
            }
            screener_topic_title
            screener_topic_descriptions {
              update_items {
                update_type
                index
                update_data {
                  client_type
                  description
                }
              }
            }
            screener_topic_image_urls {
              update_items {
                update_type
                index
                update_data {
                  image_url_id
                  image_type
                  url
                  is_mobile
                  video_stream {
                    provider_u_id
                    provider_thumbnail_url
                    provider_preview_url
                    provider_ready_to_stream
                    provider_file_size
                    provider_stream_duration
                    provider_playback_hls_url
                    provider_playback_dash_url
                  }
                }
              }
            }
            sort_order
            render_topic
            content_version
            update_counter
            published
            active
          }
          screener_topic_id
          screener_topic_history_id
          screener_id
        }
      }
    }
  }
`;

export const GET_RESULT_RANGE_LIST_GQL = gql`
  query getScreenerResultRangeList(
    $get_pending_update: Boolean
    $published: Boolean
    $is_featured: Boolean
    $is_pilot: Boolean
    $is_live: Boolean
    $platform_content_mode_tag: PlatformContentModeTag
    $pagination: PaginationFilter
    $dateFilter: DateFilter
    $active: Boolean
    $sub_domain_ids: [String!]
    $screener_id: String
    $screener_result_range_ids: [String!]
    $screener_result_range_name: String
    $screener_result_range_type_name: String
    $game_name_key: GameNameKey
  ) {
    getScreenerResultRangeList(
      get_pending_update: $get_pending_update
      published: $published
      is_featured: $is_featured
      is_pilot: $is_pilot
      is_live: $is_live
      platform_content_mode_tag: $platform_content_mode_tag
      pagination: $pagination
      dateFilter: $dateFilter
      active: $active
      sub_domain_ids: $sub_domain_ids
      screener_id: $screener_id
      screener_result_range_ids: $screener_result_range_ids
      screener_result_range_name: $screener_result_range_name
      screener_result_range_type_name: $screener_result_range_type_name
      game_name_key: $game_name_key
    ) {
      status
      error
      message
      version
      service
      screener_result_ranges {
        screener_result_range {
          created_at
          created_by
          updated_at
          updated_by
          row_crc
          sub_domain_ids
          content_version
          update_counter
          published
          active
          screener_result_range_id
          screener_id
          screener_result_range_entity_type
          game_name_key
          entity_ref_id
          screener_result_range_name
          screener_result_range_type_name
          screener_result_range_min
          screener_result_range_max
          screener_result_range_color
          screener_result_range_meta {
            type
            key
            value
            image_urls {
              image_url_id
              image_type
              url
              is_mobile
              video_stream {
                provider_u_id
                provider_thumbnail_url
                provider_preview_url
                provider_ready_to_stream
                provider_file_size
                provider_stream_duration
                provider_playback_hls_url
                provider_playback_dash_url
              }
            }
          }
          screener_result_range_descriptions {
            client_type
            description
          }
          screener_result_range_image_urls {
            image_url_id
            image_type
            url
            is_mobile
            video_stream {
              provider_u_id
              provider_thumbnail_url
              provider_preview_url
              provider_ready_to_stream
              provider_file_size
              provider_stream_duration
              provider_playback_hls_url
              provider_playback_dash_url
            }
          }
          screener_result_range_report_description
        }
        pending_screener_result_range_history {
          created_at
          created_by
          updated_at
          updated_by
          row_crc
          content_version
          update_counter
          content_type
          update_status
          update_type
          child_content_histories {
            child_content_history_id
            child_content_type
          }
          publish_data {
            created_by
            updated_by
            row_crc
            sub_domain_ids
            content_version
            update_counter
            published
            active
            screener_result_range_id
            screener_id
            screener_result_range_entity_type
            game_name_key
            entity_ref_id
            screener_result_range_name
            screener_result_range_type_name
            screener_result_range_min
            screener_result_range_max
            screener_result_range_color
            screener_result_range_meta {
              type
              key
              value
              image_urls {
                image_url_id
                image_type
                url
                is_mobile
                video_stream {
                  provider_u_id
                  provider_thumbnail_url
                  provider_preview_url
                  provider_ready_to_stream
                  provider_file_size
                  provider_stream_duration
                  provider_playback_hls_url
                  provider_playback_dash_url
                }
              }
            }
            screener_result_range_descriptions {
              client_type
              description
            }
            screener_result_range_image_urls {
              image_url_id
              image_type
              url
              is_mobile
              video_stream {
                provider_u_id
                provider_thumbnail_url
                provider_preview_url
                provider_ready_to_stream
                provider_file_size
                provider_stream_duration
                provider_playback_hls_url
                provider_playback_dash_url
              }
            }
            screener_result_range_report_description
          }
          publish_at
          reviewer_comment
          reviewed_by
          reviewed_at
          approver_comment
          approved_by
          approved_at
          data {
            created_by
            updated_by
            row_crc
            sub_domain_ids
            content_version
            update_counter
            published
            active
            screener_result_range_id
            screener_id
            screener_result_range_entity_type
            game_name_key
            entity_ref_id
            screener_result_range_name
            screener_result_range_type_name
            screener_result_range_min
            screener_result_range_max
            screener_result_range_color
            screener_result_range_meta {
              type
              key
              value
              image_urls {
                image_url_id
                image_type
                url
                is_mobile
                video_stream {
                  provider_u_id
                  provider_thumbnail_url
                  provider_preview_url
                  provider_ready_to_stream
                  provider_file_size
                  provider_stream_duration
                  provider_playback_hls_url
                  provider_playback_dash_url
                }
              }
            }
            screener_result_range_descriptions {
              client_type
              description
            }
            screener_result_range_image_urls {
              image_url_id
              image_type
              url
              is_mobile
              video_stream {
                provider_u_id
                provider_thumbnail_url
                provider_preview_url
                provider_ready_to_stream
                provider_file_size
                provider_stream_duration
                provider_playback_hls_url
                provider_playback_dash_url
              }
            }
            screener_result_range_report_description
          }
          updated_data {
            screener_id
            sub_domain_ids {
              update_items {
                update_type
                index
                update_data
              }
              IsValid
              MergeData
            }
            screener_result_range_entity_type
            game_name_key
            entity_ref_id
            screener_result_range_type_name
            screener_result_range_name
            screener_result_range_min
            screener_result_range_max
            screener_result_range_color
            screener_result_range_meta {
              update_items {
                update_type
                index
                update_data {
                  type
                  key
                  value
                  image_urls {
                    image_url_id
                    image_type
                    url
                    is_mobile
                    video_stream {
                      provider_u_id
                      provider_thumbnail_url
                      provider_preview_url
                      provider_ready_to_stream
                      provider_file_size
                      provider_stream_duration
                      provider_playback_hls_url
                      provider_playback_dash_url
                    }
                  }
                  image_urls_update {
                    update_items {
                      update_type
                      index
                    }
                  }
                }
              }
            }
            screener_result_range_descriptions {
              update_items {
                update_type
                index
                update_data {
                  client_type
                  description
                }
              }
            }
            screener_result_range_report_description
            screener_result_range_image_urls {
              update_items {
                update_type
                index
                update_data {
                  image_url_id
                  image_type
                  url
                  is_mobile
                  video_stream {
                    provider_u_id
                    provider_thumbnail_url
                    provider_preview_url
                    provider_ready_to_stream
                    provider_file_size
                    provider_stream_duration
                    provider_playback_hls_url
                    provider_playback_dash_url
                  }
                }
              }
            }
            content_version
            update_counter
            published
            active
          }
          screener_result_range_id
          screener_result_range_history_id
          screener_id
        }
      }
    }
  }
`;

export const GET_THRESHOLD_LIST_GQL = gql`
  query getScreenerThresholdList(
    $get_pending_update: Boolean
    $published: Boolean
    $is_featured: Boolean
    $pagination: PaginationFilter
    $dateFilter: DateFilter
    $active: Boolean
    $screener_threshold_ids: [String!]
    $screener_id: String
    $sub_domain_ids: [String!]
  ) {
    getScreenerThresholdList(
      get_pending_update: $get_pending_update
      published: $published
      is_featured: $is_featured
      pagination: $pagination
      dateFilter: $dateFilter
      active: $active
      screener_threshold_ids: $screener_threshold_ids
      screener_id: $screener_id
      sub_domain_ids: $sub_domain_ids
    ) {
      status
      error
      message
      version
      service
      screener_thresholds {
        screener_threshold {
          created_at
          created_by
          updated_at
          updated_by
          sub_domain_ids
          content_version
          update_counter
          published
          active
          screener_threshold_id
          screener_id
          screener_threshold_entity_type
          entity_ref_id
          min_threshold
          screener_threshold_meta {
            type
            key
            value
            image_urls {
              image_url_id
              image_type
              url
              is_mobile
            }
          }
          screener_threshold_descriptions {
            client_type
            description
          }
        }
        pending_screener_threshold_history {
          created_at
          created_by
          updated_at
          updated_by
          content_version
          update_counter
          content_type
          update_status
          update_type
          child_content_histories {
            child_content_history_id
            child_content_type
          }
          publish_data {
            created_by
            updated_by
            sub_domain_ids
            content_version
            update_counter
            published
            active
            screener_threshold_id
            screener_id
            screener_threshold_entity_type
            entity_ref_id
            min_threshold
            screener_threshold_meta {
              type
              key
              value
            }
            screener_threshold_descriptions {
              client_type
              description
            }
          }
          publish_at
          reviewer_comment
          reviewed_by
          reviewed_at
          approver_comment
          approved_by
          approved_at
          data {
            created_by
            updated_by
            sub_domain_ids
            content_version
            update_counter
            published
            active
            screener_threshold_id
            screener_id
            screener_threshold_entity_type
            entity_ref_id
            min_threshold
            screener_threshold_meta {
              type
              key
              value
            }
            screener_threshold_descriptions {
              client_type
              description
            }
          }
          updated_data {
            screener_id
            sub_domain_ids {
              IsValid
              MergeData
            }
            screener_threshold_entity_type
            entity_ref_id
            min_threshold
            content_version
            update_counter
            published
            active
          }
          screener_threshold_id
          screener_threshold_history_id
          screener_id
        }
      }
    }
  }
`;

export const GET_TOPIC_GQL = gql`
  query getScreenerTopic($screener_topic_id: String!) {
    getScreenerTopic(screener_topic_id: $screener_topic_id) {
      status
      error
      message
      version
      service
      screener_topic {
        created_at
        created_by
        updated_at
        updated_by
        sub_domain_ids
        content_version
        update_counter
        published
        active
        screener_topic_id
        screener_id
        domain_id
        screener_topic_aggregate_type
        screener_result_calculation_type
        screener_topic_title
        screener_topic_descriptions {
          client_type
          description
        }
        screener_topic_image_urls {
          image_url_id
          image_type
          url
          is_mobile
          video_stream {
            provider_u_id
            provider_thumbnail_url
            provider_preview_url
            provider_ready_to_stream
            provider_file_size
            provider_stream_duration
            provider_playback_hls_url
            provider_playback_dash_url
          }
        }
        sort_order
        render_topic
      }
    }
  }
`;

export const GET_TOPIC_HISTORIES_GQL = gql`
  query getAllScreenerTopicHistories($screener_topic_id: String!) {
    getAllScreenerTopicHistories(screener_topic_id: $screener_topic_id) {
      status
      error
      message
      version
      service
      screener_topic_history_list {
        created_at
        created_by
        updated_at
        updated_by
        content_version
        content_type
        update_status
        update_type
        data {
          sub_domain_ids
          content_version
          published
          active
          screener_topic_id
          screener_topic_title
          screener_topic_description
          screener_topic_image_urls {
            image_type
            url
          }
        }
        reviewer_comments
        reviewed_by
        reviewed_at
        approver_comments
        approved_by
        approved_at
        screener_topic_id
        screener_topic_history_id
      }
    }
  }
`;

export const GET_INTERVENTION_LIST_GQL = gql`
  query getInterventionList(
    $get_pending_update: Boolean
    $published: Boolean
    $is_featured: Boolean
    $is_pilot: Boolean
    $is_live: Boolean
    $pagination: PaginationFilter
    $dateFilter: DateFilter
    $active: Boolean
    $intervention_ids: [String!]
    $sub_domain_ids: [String!]
    $intervention_name: String
    $intervention_code: String
    $category_ids: [String!]
    $activity_type: ActivityType
    $intervention_formats: [InterventionFormat!]
    $alias_name: String
  ) {
    getInterventionList(
      get_pending_update: $get_pending_update
      published: $published
      is_featured: $is_featured
      is_pilot: $is_pilot
      is_live: $is_live
      pagination: $pagination
      dateFilter: $dateFilter
      active: $active
      intervention_ids: $intervention_ids
      sub_domain_ids: $sub_domain_ids
      intervention_name: $intervention_name
      intervention_code: $intervention_code
      category_ids: $category_ids
      activity_type: $activity_type
      intervention_formats: $intervention_formats
      alias_name: $alias_name
    ) {
      status
      error
      message
      version
      service
      pagination {
        page_number
        item_count
        total_count
      }
      interventions {
        intervention {
          created_at
          created_by
          updated_at
          updated_by
          row_crc
          sub_domain_ids
          content_version
          update_counter
          published
          active
          intervention_id
          category_ids
          intervention_name
          alias_name
          intervention_code
          intervention_description {
            client_type
            description
          }
          intervention_image_urls {
            image_url_id
            image_type
            url
            is_mobile
            video_stream {
              provider_u_id
              provider_thumbnail_url
              provider_preview_url
              provider_ready_to_stream
              provider_file_size
              provider_stream_duration
              provider_playback_hls_url
              provider_playback_dash_url
            }
          }
          intervention_video_urls {
            video_url_id
            video_type
            video_url
            is_mobile
            video_stream {
              provider_u_id
              provider_thumbnail_url
              provider_preview_url
              provider_ready_to_stream
              provider_file_size
              provider_stream_duration
              provider_playback_hls_url
              provider_playback_dash_url
            }
          }
          intervention_document_urls {
            document_url_id
            name
            document_type
            url
            is_mobile
          }
          intervention_formats
          activity_type
          intervention_duration
          days_per_week
          intervention_data_url
          intervention_meta_datas {
            meta_data_type
            meta_data_title
            meta_data_value
            meta_data_image_urls {
              image_url_id
              image_type
              url
              is_mobile
              video_stream {
                provider_u_id
                provider_thumbnail_url
                provider_preview_url
                provider_ready_to_stream
                provider_file_size
                provider_stream_duration
                provider_playback_hls_url
                provider_playback_dash_url
              }
            }
          }
        }
        pending_intervention_history {
          created_at
          created_by
          updated_at
          updated_by
          row_crc
          content_version
          update_counter
          content_type
          update_status
          update_type
          child_content_histories {
            child_content_history_id
            child_content_type
          }
          publish_data {
            created_by
            updated_by
            row_crc
            sub_domain_ids
            content_version
            update_counter
            published
            active
            intervention_id
            category_ids
            intervention_name
            alias_name
            intervention_code
            intervention_description {
              client_type
              description
            }
            intervention_image_urls {
              image_url_id
              image_type
              url
              is_mobile
              video_stream {
                provider_u_id
                provider_thumbnail_url
                provider_preview_url
                provider_ready_to_stream
                provider_file_size
                provider_stream_duration
                provider_playback_hls_url
                provider_playback_dash_url
              }
            }
            intervention_video_urls {
              video_url_id
              video_type
              video_url
              is_mobile
              video_stream {
                provider_u_id
                provider_thumbnail_url
                provider_preview_url
                provider_ready_to_stream
                provider_file_size
                provider_stream_duration
                provider_playback_hls_url
                provider_playback_dash_url
              }
            }
            intervention_document_urls {
              document_url_id
              name
              document_type
              url
              is_mobile
            }
            intervention_formats
            activity_type
            intervention_duration
            days_per_week
            intervention_data_url
            intervention_meta_datas {
              meta_data_type
              meta_data_title
              meta_data_value
              meta_data_image_urls {
                image_url_id
                image_type
                url
                is_mobile
                video_stream {
                  provider_u_id
                  provider_thumbnail_url
                  provider_preview_url
                  provider_ready_to_stream
                  provider_file_size
                  provider_stream_duration
                  provider_playback_hls_url
                  provider_playback_dash_url
                }
              }
            }
          }
          reviewer_comment
          reviewed_by
          approver_comment
          approved_by
          data {
            created_by
            updated_by
            row_crc
            sub_domain_ids
            content_version
            update_counter
            published
            active
            intervention_id
            category_ids
            intervention_name
            alias_name
            intervention_code
            intervention_description {
              client_type
              description
            }
            intervention_image_urls {
              image_url_id
              image_type
              url
              is_mobile
              video_stream {
                provider_u_id
                provider_thumbnail_url
                provider_preview_url
                provider_ready_to_stream
                provider_file_size
                provider_stream_duration
                provider_playback_hls_url
                provider_playback_dash_url
              }
            }
            intervention_video_urls {
              video_url_id
              video_type
              video_url
              is_mobile
              video_stream {
                provider_u_id
                provider_thumbnail_url
                provider_preview_url
                provider_ready_to_stream
                provider_file_size
                provider_stream_duration
                provider_playback_hls_url
                provider_playback_dash_url
              }
            }
            intervention_document_urls {
              document_url_id
              name
              document_type
              url
              is_mobile
            }
            intervention_formats
            activity_type
            intervention_duration
            days_per_week
            intervention_data_url
            intervention_meta_datas {
              meta_data_type
              meta_data_title
              meta_data_value
              meta_data_image_urls {
                image_url_id
                image_type
                url
                is_mobile
                video_stream {
                  provider_u_id
                  provider_thumbnail_url
                  provider_preview_url
                  provider_ready_to_stream
                  provider_file_size
                  provider_stream_duration
                  provider_playback_hls_url
                  provider_playback_dash_url
                }
              }
            }
          }
          updated_data {
            category_ids {
              update_items {
                update_type
                index
                update_data
              }
              IsValid
              MergeData
            }
            sub_domain_ids {
              update_items {
                update_type
                index
                update_data
              }
              IsValid
              MergeData
            }
            intervention_name
            intervention_code
            alias_name
            intervention_description {
              update_items {
                update_type
                index
                update_data {
                  client_type
                  description
                }
              }
            }
            intervention_document_urls {
              update_items {
                update_type
                index
                update_data {
                  document_url_id
                  name
                  document_type
                  url
                  is_mobile
                }
              }
            }
            intervention_image_urls {
              update_items {
                update_type
                index
                update_data {
                  image_url_id
                  image_type
                  url
                  is_mobile
                  video_stream {
                    provider_u_id
                    provider_thumbnail_url
                    provider_preview_url
                    provider_ready_to_stream
                    provider_file_size
                    provider_stream_duration
                    provider_playback_hls_url
                    provider_playback_dash_url
                  }
                }
              }
            }
            intervention_video_urls {
              update_items {
                update_type
                index
                update_data {
                  video_url_id
                  video_type
                  video_url
                  is_mobile
                  video_stream {
                    provider_u_id
                    provider_thumbnail_url
                    provider_preview_url
                    provider_ready_to_stream
                    provider_file_size
                    provider_stream_duration
                    provider_playback_hls_url
                    provider_playback_dash_url
                  }
                }
              }
            }
            intervention_meta_datas {
              update_items {
                update_type
                index
                update_data {
                  meta_data_type
                  meta_data_title
                  meta_data_value
                  meta_data_image_urls {
                    image_url_id
                    image_type
                    url
                    is_mobile
                    video_stream {
                      provider_u_id
                      provider_thumbnail_url
                      provider_preview_url
                      provider_ready_to_stream
                      provider_file_size
                      provider_stream_duration
                      provider_playback_hls_url
                      provider_playback_dash_url
                    }
                  }
                  meta_data_image_urls_update {
                    update_items {
                      update_type
                      index
                    }
                  }
                }
              }
            }
            intervention_formats {
              update_items {
                update_type
                index
                update_data
              }
              IsValid
              MergeData
            }
            activity_type
            intervention_duration
            days_per_week
            intervention_data_url
            game_view_id
            is_featured
          }
          intervention_history_id
          intervention_id
        }
      }
    }
  }
`;

export const GET_INTERVENTION_HISTORIES_GQL = gql`
  query getAllInterventionHistories($intervention_id: String!) {
    getAllInterventionHistories(intervention_id: $intervention_id) {
      status
      error
      message
      version
      service
      intervention_history_list {
        created_by
        updated_by
        row_crc
        content_version
        update_counter
        content_type
        update_status
        update_type
        child_content_histories {
          child_content_history_id
          child_content_type
        }
        publish_data {
          created_by
          updated_by
          row_crc
          sub_domain_ids
          content_version
          update_counter
          published
          active
          intervention_id
          category_ids
          intervention_name
          alias_name
          intervention_code
          intervention_description {
            client_type
            description
          }
          intervention_image_urls {
            image_url_id
            image_type
            url
            is_mobile
            video_stream {
              provider_u_id
              provider_thumbnail_url
              provider_preview_url
              provider_ready_to_stream
              provider_file_size
              provider_stream_duration
              provider_playback_hls_url
              provider_playback_dash_url
            }
          }
          intervention_video_urls {
            video_url_id
            video_type
            video_url
            is_mobile
            video_stream {
              provider_u_id
              provider_thumbnail_url
              provider_preview_url
              provider_ready_to_stream
              provider_file_size
              provider_stream_duration
              provider_playback_hls_url
              provider_playback_dash_url
            }
          }
          intervention_document_urls {
            document_url_id
            name
            document_type
            url
            is_mobile
          }
          intervention_formats
          activity_type
          intervention_duration
          days_per_week
          intervention_data_url
          intervention_meta_datas {
            meta_data_type
            meta_data_title
            meta_data_value
            meta_data_image_urls {
              image_url_id
              image_type
              url
              is_mobile
              video_stream {
                provider_u_id
                provider_thumbnail_url
                provider_preview_url
                provider_ready_to_stream
                provider_file_size
                provider_stream_duration
                provider_playback_hls_url
                provider_playback_dash_url
              }
            }
          }
        }
        reviewer_comment
        reviewed_by
        approver_comment
        approved_by
        data {
          created_by
          updated_by
          row_crc
          sub_domain_ids
          content_version
          update_counter
          published
          active
          intervention_id
          category_ids
          intervention_name
          alias_name
          intervention_code
          intervention_description {
            client_type
            description
          }
          intervention_image_urls {
            image_url_id
            image_type
            url
            is_mobile
            video_stream {
              provider_u_id
              provider_thumbnail_url
              provider_preview_url
              provider_ready_to_stream
              provider_file_size
              provider_stream_duration
              provider_playback_hls_url
              provider_playback_dash_url
            }
          }
          intervention_video_urls {
            video_url_id
            video_type
            video_url
            is_mobile
            video_stream {
              provider_u_id
              provider_thumbnail_url
              provider_preview_url
              provider_ready_to_stream
              provider_file_size
              provider_stream_duration
              provider_playback_hls_url
              provider_playback_dash_url
            }
          }
          intervention_document_urls {
            document_url_id
            name
            document_type
            url
            is_mobile
          }
          intervention_formats
          activity_type
          intervention_duration
          days_per_week
          intervention_data_url
          intervention_meta_datas {
            meta_data_type
            meta_data_title
            meta_data_value
            meta_data_image_urls {
              image_url_id
              image_type
              url
              is_mobile
              video_stream {
                provider_u_id
                provider_thumbnail_url
                provider_preview_url
                provider_ready_to_stream
                provider_file_size
                provider_stream_duration
                provider_playback_hls_url
                provider_playback_dash_url
              }
            }
          }
        }
        updated_data {
          category_ids {
            update_items {
              update_type
              index
              update_data
            }
            IsValid
            MergeData
          }
          sub_domain_ids {
            update_items {
              update_type
              index
              update_data
            }
            IsValid
            MergeData
          }
          intervention_name
          intervention_code
          alias_name
          intervention_description {
            update_items {
              update_type
              index
              update_data {
                client_type
                description
              }
            }
          }
          intervention_document_urls {
            update_items {
              update_type
              index
              update_data {
                document_url_id
                name
                document_type
                url
                is_mobile
              }
            }
          }
          intervention_image_urls {
            update_items {
              update_type
              index
              update_data {
                image_url_id
                image_type
                url
                is_mobile
                video_stream {
                  provider_u_id
                  provider_thumbnail_url
                  provider_preview_url
                  provider_ready_to_stream
                  provider_file_size
                  provider_stream_duration
                  provider_playback_hls_url
                  provider_playback_dash_url
                }
              }
            }
          }
          intervention_video_urls {
            update_items {
              update_type
              index
              update_data {
                video_url_id
                video_type
                video_url
                is_mobile
                video_stream {
                  provider_u_id
                  provider_thumbnail_url
                  provider_preview_url
                  provider_ready_to_stream
                  provider_file_size
                  provider_stream_duration
                  provider_playback_hls_url
                  provider_playback_dash_url
                }
              }
            }
          }
          intervention_meta_datas {
            update_items {
              update_type
              index
              update_data {
                meta_data_type
                meta_data_title
                meta_data_value
                meta_data_image_urls {
                  image_url_id
                  image_type
                  url
                  is_mobile
                  video_stream {
                    provider_u_id
                    provider_thumbnail_url
                    provider_preview_url
                    provider_ready_to_stream
                    provider_file_size
                    provider_stream_duration
                    provider_playback_hls_url
                    provider_playback_dash_url
                  }
                }
                meta_data_image_urls_update {
                  update_items {
                    update_type
                    index
                  }
                }
              }
            }
          }
          intervention_formats {
            update_items {
              update_type
              index
              update_data
            }
            IsValid
            MergeData
          }
          activity_type
          intervention_duration
          days_per_week
          intervention_data_url
          game_view_id
          is_featured
        }
        intervention_history_id
        intervention_id
      }
    }
  }
`;

export const GET_TAG_LIST_GQL = gql`
  query getInterventionTagList(
    $pagination: PaginationFilter
    $dateFilter: DateFilter
    $active: Boolean
    $intervention_id: String
    $intervention_tag_id: String
    $intervention_tag_title: String
  ) {
    getInterventionTagList(
      pagination: $pagination
      dateFilter: $dateFilter
      active: $active
      intervention_id: $intervention_id
      intervention_tag_id: $intervention_tag_id
      intervention_tag_title: $intervention_tag_title
    ) {
      status
      error
      message
      version
      service
      pagination {
        page_number
        item_count
        total_count
      }
      intervention_tags {
        created_at
        created_by
        updated_at
        updated_by
        intervention_tag_id
        sub_domain_id
        sort_order
        intervention_tag_title
        intervention_tag_description {
          client_type
          description
        }
        intervention_tag_image_urls {
          image_type
          url
          is_mobile
        }
        published
        active
      }
    }
  }
`;

export const GET_TAG_GQL = gql`
  query getInterventionTag($intervention_tag_id: String!) {
    getInterventionTag(intervention_tag_id: $intervention_tag_id) {
      status
      error
      message
      version
      service
      intervention_tag {
        created_at
        created_by
        updated_at
        updated_by
        intervention_tag_id
        sub_domain_id
        sort_order
        intervention_tag_title
        intervention_tag_description {
          client_type
          description
        }
        intervention_tag_image_urls {
          image_type
          url
          is_mobile
        }
        published
        active
      }
    }
  }
`;

export const GET_TAG_HISTORIES_GQL = gql`
  query getAllInterventionTagHistories($intervention_tag_id: String!) {
    getAllInterventionTagHistories(intervention_tag_id: $intervention_tag_id) {
      status
      error
      message
      version
      service
      intervention_tag_history_list {
        created_at
        created_by
        updated_at
        updated_by
        content_version
        content_type
        update_status
        update_type
        data {
          content_version
          published
          active
          intervention_tag_id
          intervention_tag_title
          intervention_tag_description
          intervention_tag_image_urls {
            image_type
            url
          }
        }
        reviewer_comments
        reviewed_by
        reviewed_at
        approver_comments
        approved_by
        approved_at
        intervention_tag_history_id
        intervention_tag_id
      }
    }
  }
`;

export const GET_THERAPY_LIST_GQL = gql`
  query getTherapyList(
    $get_pending_update: Boolean
    $published: Boolean
    $is_featured: Boolean
    $is_pilot: Boolean
    $is_live: Boolean
    $pagination: PaginationFilter
    $dateFilter: DateFilter
    $active: Boolean
    $therapy_id: String
    $therapy_ids: [String!]
    $therapy_name: String
    $therapy_code: String
    $sub_domain_ids: [String!]
    $screener_id: String
    $alias_name: String
  ) {
    getTherapyList(
      get_pending_update: $get_pending_update
      published: $published
      is_featured: $is_featured
      is_pilot: $is_pilot
      is_live: $is_live
      pagination: $pagination
      dateFilter: $dateFilter
      active: $active
      therapy_id: $therapy_id
      therapy_ids: $therapy_ids
      therapy_name: $therapy_name
      therapy_code: $therapy_code
      sub_domain_ids: $sub_domain_ids
      screener_id: $screener_id
      alias_name: $alias_name
    ) {
      status
      error
      message
      version
      service
      pagination {
        page_number
        item_count
        total_count
      }
      therapies {
        therapy {
          created_at
          created_by
          updated_at
          updated_by
          row_crc
          sub_domain_ids
          content_version
          update_counter
          published
          active
          therapy_id
          screener_id
          alias_name
          therapy_code
          screener_result_range_min
          screener_result_range_max
          therapy_name
          therapy_description {
            client_type
            description
          }
          therapy_image_urls {
            image_url_id
            image_type
            url
            is_mobile
            video_stream {
              provider_u_id
              provider_thumbnail_url
              provider_preview_url
              provider_ready_to_stream
              provider_file_size
              provider_stream_duration
              provider_playback_hls_url
              provider_playback_dash_url
            }
          }
          therapy_video_urls {
            video_url_id
            video_type
            video_url
            is_mobile
            video_stream {
              provider_u_id
              provider_thumbnail_url
              provider_preview_url
              provider_ready_to_stream
              provider_file_size
              provider_stream_duration
              provider_playback_hls_url
              provider_playback_dash_url
            }
          }
          therapy_document_urls {
            document_url_id
            name
            document_type
            url
            is_mobile
          }
          therapy_meta {
            type
            key
            value
            image_urls {
              image_url_id
              image_type
              url
              is_mobile
              video_stream {
                provider_u_id
                provider_thumbnail_url
                provider_preview_url
                provider_ready_to_stream
                provider_file_size
                provider_stream_duration
                provider_playback_hls_url
                provider_playback_dash_url
              }
            }
          }
          total_duration
          time_per_module
          days_per_week
        }
        pending_therapy_history {
          created_at
          created_by
          updated_at
          updated_by
          row_crc
          content_version
          update_counter
          content_type
          update_status
          update_type
          child_content_histories {
            child_content_history_id
            child_content_type
          }
          publish_data {
            created_by
            updated_by
            row_crc
            sub_domain_ids
            content_version
            update_counter
            published
            active
            therapy_id
            screener_id
            alias_name
            therapy_code
            screener_result_range_min
            screener_result_range_max
            therapy_name
            therapy_description {
              client_type
              description
            }
            therapy_image_urls {
              image_url_id
              image_type
              url
              is_mobile
              video_stream {
                provider_u_id
                provider_thumbnail_url
                provider_preview_url
                provider_ready_to_stream
                provider_file_size
                provider_stream_duration
                provider_playback_hls_url
                provider_playback_dash_url
              }
            }
            therapy_video_urls {
              video_url_id
              video_type
              video_url
              is_mobile
              video_stream {
                provider_u_id
                provider_thumbnail_url
                provider_preview_url
                provider_ready_to_stream
                provider_file_size
                provider_stream_duration
                provider_playback_hls_url
                provider_playback_dash_url
              }
            }
            therapy_document_urls {
              document_url_id
              name
              document_type
              url
              is_mobile
            }
            therapy_meta {
              type
              key
              value
              image_urls {
                image_url_id
                image_type
                url
                is_mobile
                video_stream {
                  provider_u_id
                  provider_thumbnail_url
                  provider_preview_url
                  provider_ready_to_stream
                  provider_file_size
                  provider_stream_duration
                  provider_playback_hls_url
                  provider_playback_dash_url
                }
              }
            }
            total_duration
            time_per_module
            days_per_week
          }
          publish_at
          reviewer_comment
          reviewed_by
          reviewed_at
          approver_comment
          approved_by
          approved_at
          data {
            created_by
            updated_by
            row_crc
            sub_domain_ids
            content_version
            update_counter
            published
            active
            therapy_id
            screener_id
            alias_name
            therapy_code
            screener_result_range_min
            screener_result_range_max
            therapy_name
            therapy_description {
              client_type
              description
            }
            therapy_image_urls {
              image_url_id
              image_type
              url
              is_mobile
              video_stream {
                provider_u_id
                provider_thumbnail_url
                provider_preview_url
                provider_ready_to_stream
                provider_file_size
                provider_stream_duration
                provider_playback_hls_url
                provider_playback_dash_url
              }
            }
            therapy_video_urls {
              video_url_id
              video_type
              video_url
              is_mobile
              video_stream {
                provider_u_id
                provider_thumbnail_url
                provider_preview_url
                provider_ready_to_stream
                provider_file_size
                provider_stream_duration
                provider_playback_hls_url
                provider_playback_dash_url
              }
            }
            therapy_document_urls {
              document_url_id
              name
              document_type
              url
              is_mobile
            }
            therapy_meta {
              type
              key
              value
              image_urls {
                image_url_id
                image_type
                url
                is_mobile
                video_stream {
                  provider_u_id
                  provider_thumbnail_url
                  provider_preview_url
                  provider_ready_to_stream
                  provider_file_size
                  provider_stream_duration
                  provider_playback_hls_url
                  provider_playback_dash_url
                }
              }
            }
            total_duration
            time_per_module
            days_per_week
          }
          updated_data {
            sub_domain_ids {
              update_items {
                update_type
                index
                update_data
              }
              IsValid
              MergeData
            }
            therapy_name
            therapy_code
            screener_id
            alias_name
            screener_result_range_min
            screener_result_range_max
            therapy_description {
              update_items {
                update_type
                index
                update_data {
                  client_type
                  description
                }
              }
            }
            therapy_image_urls {
              update_items {
                update_type
                index
                update_data {
                  image_url_id
                  image_type
                  url
                  is_mobile
                  video_stream {
                    provider_u_id
                    provider_thumbnail_url
                    provider_preview_url
                    provider_ready_to_stream
                    provider_file_size
                    provider_stream_duration
                    provider_playback_hls_url
                    provider_playback_dash_url
                  }
                }
              }
            }
            therapy_video_urls {
              update_items {
                update_type
                index
                update_data {
                  video_url_id
                  video_type
                  video_url
                  is_mobile
                  video_stream {
                    provider_u_id
                    provider_thumbnail_url
                    provider_preview_url
                    provider_ready_to_stream
                    provider_file_size
                    provider_stream_duration
                    provider_playback_hls_url
                    provider_playback_dash_url
                  }
                }
              }
            }
            therapy_document_urls {
              update_items {
                update_type
                index
                update_data {
                  document_url_id
                  name
                  document_type
                  url
                  is_mobile
                }
              }
            }
            therapy_meta {
              update_items {
                update_type
                index
                update_data {
                  type
                  key
                  value
                  image_urls {
                    image_url_id
                    image_type
                    url
                    is_mobile
                    video_stream {
                      provider_u_id
                      provider_thumbnail_url
                      provider_preview_url
                      provider_ready_to_stream
                      provider_file_size
                      provider_stream_duration
                      provider_playback_hls_url
                      provider_playback_dash_url
                    }
                  }
                  image_urls_update {
                    update_items {
                      update_type
                      index
                    }
                  }
                }
              }
            }
            total_duration
            time_per_module
            days_per_week
            is_featured
            published
            content_version
            update_counter
            active
          }
          therapy_history_id
          therapy_id
        }
      }
    }
  }
`;

export const GET_THERAPY_GQL = gql`
  query getTherapy($therapy_id: String!) {
    getTherapy(therapy_id: $therapy_id) {
      status
      error
      message
      version
      service
      therapy {
        created_at
        created_by
        updated_by
        row_crc
        sub_domain_ids
        content_version
        update_counter
        published
        active
        therapy_id
        screener_id
        alias_name
        therapy_code
        screener_result_range_min
        screener_result_range_max
        therapy_name
        therapy_description {
          client_type
          description
        }
        therapy_image_urls {
          image_url_id
          image_type
          url
          is_mobile
          video_stream {
            provider_u_id
            provider_thumbnail_url
            provider_preview_url
            provider_ready_to_stream
            provider_file_size
            provider_stream_duration
            provider_playback_hls_url
            provider_playback_dash_url
          }
        }
        therapy_video_urls {
          video_url_id
          video_type
          video_url
          is_mobile
          video_stream {
            provider_u_id
            provider_thumbnail_url
            provider_preview_url
            provider_ready_to_stream
            provider_file_size
            provider_stream_duration
            provider_playback_hls_url
            provider_playback_dash_url
          }
        }
        therapy_document_urls {
          document_url_id
          name
          document_type
          url
          is_mobile
        }
        therapy_meta {
          type
          key
          value
          image_urls {
            image_url_id
            image_type
            url
            is_mobile
            video_stream {
              provider_u_id
              provider_thumbnail_url
              provider_preview_url
              provider_ready_to_stream
              provider_file_size
              provider_stream_duration
              provider_playback_hls_url
              provider_playback_dash_url
            }
          }
        }
        total_duration
        time_per_module
        days_per_week
      }
    }
  }
`;

export const GET_FUNCTION_GQL = gql`
  query getFunction($function_id: String!) {
    getFunction(function_id: $function_id) {
      status
      error
      message
      version
      service
      function {
        created_at
        created_by
        updated_at
        updated_by
        row_crc
        function_id
        industry_id
        function_name
        function_description
        function_image_urls {
          image_url_id
          image_type
          url
          is_mobile
          video_stream {
            provider_u_id
            provider_thumbnail_url
            provider_preview_url
            provider_ready_to_stream
            provider_file_size
            provider_stream_duration
            provider_playback_hls_url
            provider_playback_dash_url
          }
        }
        active
      }
    }
  }
`;

export const GET_SCREENER_LIST_GQL = gql`
  query getScreenerList(
    $get_pending_update: Boolean
    $published: Boolean
    $is_featured: Boolean
    $is_pilot: Boolean
    $is_live: Boolean
    $pagination: PaginationFilter
    $dateFilter: DateFilter
    $active: Boolean
    $sub_domain_id: String
    $screener_id: String
    $screener_ids: [String!]
    $screener_name: String
    $screener_code: String
    $screener_type: ScreenerType
    $screener_format: ScreenerFormatType
    $alias_name: String
  ) {
    getScreenerList(
      get_pending_update: $get_pending_update
      published: $published
      is_featured: $is_featured
      is_pilot: $is_pilot
      is_live: $is_live
      pagination: $pagination
      dateFilter: $dateFilter
      active: $active
      sub_domain_id: $sub_domain_id
      screener_id: $screener_id
      screener_ids: $screener_ids
      screener_name: $screener_name
      screener_code: $screener_code
      screener_type: $screener_type
      screener_format: $screener_format
      alias_name: $alias_name
    ) {
      status
      error
      message
      version
      service
      pagination {
        page_number
        item_count
        total_count
      }
      screeners {
        screener {
          created_at
          created_by
          updated_at
          updated_by
          row_crc
          sub_domain_ids
          content_version
          update_counter
          published
          active
          screener_id
          screener_code
          intervention_id
          template_id
          screener_type
          alias_name
          screener_name
          screener_description {
            client_type
            description
          }
          screener_image_urls {
            image_url_id
            image_type
            url
            is_mobile
            video_stream {
              provider_u_id
              provider_thumbnail_url
              provider_preview_url
              provider_ready_to_stream
              provider_file_size
              provider_stream_duration
              provider_playback_hls_url
              provider_playback_dash_url
            }
          }
          screener_video_urls {
            video_url_id
            video_type
            video_url
            is_mobile
            video_stream {
              provider_u_id
              provider_thumbnail_url
              provider_preview_url
              provider_ready_to_stream
              provider_file_size
              provider_stream_duration
              provider_playback_hls_url
              provider_playback_dash_url
            }
          }
          screener_meta {
            type
            key
            value
            image_urls {
              image_url_id
              image_type
              url
              is_mobile
              video_stream {
                provider_u_id
                provider_thumbnail_url
                provider_preview_url
                provider_ready_to_stream
                provider_file_size
                provider_stream_duration
                provider_playback_hls_url
                provider_playback_dash_url
              }
            }
          }
          screener_time
          sort_type
          screener_format
        }
        pending_screener_history {
          created_at
          created_by
          updated_at
          updated_by
          row_crc
          content_version
          update_counter
          content_type
          update_status
          update_type
          child_content_histories {
            child_content_history_id
            child_content_type
          }
          publish_data {
            created_by
            updated_by
            row_crc
            sub_domain_ids
            content_version
            update_counter
            published
            active
            screener_id
            screener_code
            intervention_id
            template_id
            screener_type
            alias_name
            screener_name
            screener_description {
              client_type
              description
            }
            screener_image_urls {
              image_url_id
              image_type
              url
              is_mobile
              video_stream {
                provider_u_id
                provider_thumbnail_url
                provider_preview_url
                provider_ready_to_stream
                provider_file_size
                provider_stream_duration
                provider_playback_hls_url
                provider_playback_dash_url
              }
            }
            screener_video_urls {
              video_url_id
              video_type
              video_url
              is_mobile
              video_stream {
                provider_u_id
                provider_thumbnail_url
                provider_preview_url
                provider_ready_to_stream
                provider_file_size
                provider_stream_duration
                provider_playback_hls_url
                provider_playback_dash_url
              }
            }
            screener_meta {
              type
              key
              value
              image_urls {
                image_url_id
                image_type
                url
                is_mobile
                video_stream {
                  provider_u_id
                  provider_thumbnail_url
                  provider_preview_url
                  provider_ready_to_stream
                  provider_file_size
                  provider_stream_duration
                  provider_playback_hls_url
                  provider_playback_dash_url
                }
              }
            }
            screener_time
            sort_type
            screener_format
          }
          publish_at
          reviewer_comment
          reviewed_by
          reviewed_at
          approver_comment
          approved_by
          approved_at
          data {
            created_by
            updated_by
            row_crc
            sub_domain_ids
            content_version
            update_counter
            published
            active
            screener_id
            screener_code
            intervention_id
            template_id
            screener_type
            alias_name
            screener_name
            screener_description {
              client_type
              description
            }
            screener_image_urls {
              image_url_id
              image_type
              url
              is_mobile
              video_stream {
                provider_u_id
                provider_thumbnail_url
                provider_preview_url
                provider_ready_to_stream
                provider_file_size
                provider_stream_duration
                provider_playback_hls_url
                provider_playback_dash_url
              }
            }
            screener_video_urls {
              video_url_id
              video_type
              video_url
              is_mobile
              video_stream {
                provider_u_id
                provider_thumbnail_url
                provider_preview_url
                provider_ready_to_stream
                provider_file_size
                provider_stream_duration
                provider_playback_hls_url
                provider_playback_dash_url
              }
            }
            screener_meta {
              type
              key
              value
              image_urls {
                image_url_id
                image_type
                url
                is_mobile
                video_stream {
                  provider_u_id
                  provider_thumbnail_url
                  provider_preview_url
                  provider_ready_to_stream
                  provider_file_size
                  provider_stream_duration
                  provider_playback_hls_url
                  provider_playback_dash_url
                }
              }
            }
            screener_time
            sort_type
            screener_format
          }
          updated_data {
            sub_domain_ids {
              update_items {
                update_type
                index
                update_data
              }
              IsValid
              MergeData
            }
            screener_type
            screener_name
            alias_name
            screener_description {
              update_items {
                update_type
                index
                update_data {
                  client_type
                  description
                }
              }
            }
            screener_image_urls {
              update_items {
                update_type
                index
                update_data {
                  image_url_id
                  image_type
                  url
                  is_mobile
                  video_stream {
                    provider_u_id
                    provider_thumbnail_url
                    provider_preview_url
                    provider_ready_to_stream
                    provider_file_size
                    provider_stream_duration
                    provider_playback_hls_url
                    provider_playback_dash_url
                  }
                }
              }
            }
            screener_video_urls {
              update_items {
                update_type
                index
                update_data {
                  video_url_id
                  video_type
                  video_url
                  is_mobile
                  video_stream {
                    provider_u_id
                    provider_thumbnail_url
                    provider_preview_url
                    provider_ready_to_stream
                    provider_file_size
                    provider_stream_duration
                    provider_playback_hls_url
                    provider_playback_dash_url
                  }
                }
              }
            }
            screener_meta {
              update_items {
                update_type
                index
                update_data {
                  type
                  key
                  value
                  image_urls {
                    image_url_id
                    image_type
                    url
                    is_mobile
                    video_stream {
                      provider_u_id
                      provider_thumbnail_url
                      provider_preview_url
                      provider_ready_to_stream
                      provider_file_size
                      provider_stream_duration
                      provider_playback_hls_url
                      provider_playback_dash_url
                    }
                  }
                  image_urls_update {
                    update_items {
                      update_type
                      index
                    }
                  }
                }
              }
            }
            screener_time
            screener_code
            sort_type
            screener_format
            intervention_id
            template_id
          }
          screener_id
          screener_history_id
        }
      }
    }
  }
`;

export const GET_ALL_SCREENER_HISTORIES = gql`
  query getAllScreenerHistories($screener_id: String!) {
    getAllScreenerHistories(screener_id: $screener_id) {
      status
      error
      message
      version
      service
      screener_history_list {
        created_at
        created_by
        updated_at
        updated_by
        row_crc
        content_version
        update_counter
        content_type
        update_status
        update_type
        child_content_histories {
          child_content_history_id
          child_content_type
        }
        publish_data {
          created_by
          updated_by
          row_crc
          sub_domain_ids
          content_version
          update_counter
          published
          active
          screener_id
          screener_code
          intervention_id
          template_id
          screener_type
          screener_name
          screener_description {
            client_type
            description
          }
          screener_image_urls {
            image_url_id
            image_type
            url
            is_mobile
            video_stream {
              provider_u_id
              provider_thumbnail_url
              provider_preview_url
              provider_ready_to_stream
              provider_file_size
              provider_stream_duration
              provider_playback_hls_url
              provider_playback_dash_url
            }
          }
          screener_video_urls {
            video_url_id
            video_type
            video_url
            is_mobile
            video_stream {
              provider_u_id
              provider_thumbnail_url
              provider_preview_url
              provider_ready_to_stream
              provider_file_size
              provider_stream_duration
              provider_playback_hls_url
              provider_playback_dash_url
            }
          }
          screener_meta {
            type
            key
            value
            image_urls {
              image_url_id
              image_type
              url
              is_mobile
              video_stream {
                provider_u_id
                provider_thumbnail_url
                provider_preview_url
                provider_ready_to_stream
                provider_file_size
                provider_stream_duration
                provider_playback_hls_url
                provider_playback_dash_url
              }
            }
          }
          screener_time
          sort_type
          screener_format
        }
        reviewer_comment
        reviewed_by
        approver_comment
        approved_by
        data {
          created_by
          updated_by
          row_crc
          sub_domain_ids
          content_version
          update_counter
          published
          active
          screener_id
          screener_code
          intervention_id
          template_id
          screener_type
          screener_name
          screener_description {
            client_type
            description
          }
          screener_image_urls {
            image_url_id
            image_type
            url
            is_mobile
            video_stream {
              provider_u_id
              provider_thumbnail_url
              provider_preview_url
              provider_ready_to_stream
              provider_file_size
              provider_stream_duration
              provider_playback_hls_url
              provider_playback_dash_url
            }
          }
          screener_video_urls {
            video_url_id
            video_type
            video_url
            is_mobile
            video_stream {
              provider_u_id
              provider_thumbnail_url
              provider_preview_url
              provider_ready_to_stream
              provider_file_size
              provider_stream_duration
              provider_playback_hls_url
              provider_playback_dash_url
            }
          }
          screener_meta {
            type
            key
            value
            image_urls {
              image_url_id
              image_type
              url
              is_mobile
              video_stream {
                provider_u_id
                provider_thumbnail_url
                provider_preview_url
                provider_ready_to_stream
                provider_file_size
                provider_stream_duration
                provider_playback_hls_url
                provider_playback_dash_url
              }
            }
          }
          screener_time
          sort_type
          screener_format
        }
        updated_data {
          sub_domain_ids {
            update_items {
              update_type
              index
              update_data
            }
            IsValid
            MergeData
          }
          screener_type
          screener_name
          screener_description {
            update_items {
              update_type
              index
              update_data {
                client_type
                description
              }
            }
          }
          screener_image_urls {
            update_items {
              update_type
              index
              update_data {
                image_url_id
                image_type
                url
                is_mobile
                video_stream {
                  provider_u_id
                  provider_thumbnail_url
                  provider_preview_url
                  provider_ready_to_stream
                  provider_file_size
                  provider_stream_duration
                  provider_playback_hls_url
                  provider_playback_dash_url
                }
              }
            }
          }
          screener_video_urls {
            update_items {
              update_type
              index
              update_data {
                video_url_id
                video_type
                video_url
                is_mobile
                video_stream {
                  provider_u_id
                  provider_thumbnail_url
                  provider_preview_url
                  provider_ready_to_stream
                  provider_file_size
                  provider_stream_duration
                  provider_playback_hls_url
                  provider_playback_dash_url
                }
              }
            }
          }
          screener_meta {
            update_items {
              update_type
              index
              update_data {
                type
                key
                value
                image_urls {
                  image_url_id
                  image_type
                  url
                  is_mobile
                  video_stream {
                    provider_u_id
                    provider_thumbnail_url
                    provider_preview_url
                    provider_ready_to_stream
                    provider_file_size
                    provider_stream_duration
                    provider_playback_hls_url
                    provider_playback_dash_url
                  }
                }
                image_urls_update {
                  update_items {
                    update_type
                    index
                  }
                }
              }
            }
          }
          screener_time
          screener_code
          sort_type
          screener_format
          intervention_id
          template_id
        }
        screener_id
        screener_history_id
      }
    }
  }
`;

export const GET_SCREENER = gql`
  query getScreener($screener_id: String!) {
    getScreener(screener_id: $screener_id) {
      status
      error
      message
      version
      service
      screener {
        created_at
        created_by
        updated_at
        updated_by
        row_crc
        sub_domain_ids
        content_version
        update_counter
        published
        active
        screener_id
        screener_code
        intervention_id
        template_id
        screener_type
        alias_name
        screener_name
        screener_description {
          client_type
          description
        }
        screener_image_urls {
          image_url_id
          image_type
          url
          is_mobile
          video_stream {
            provider_u_id
            provider_thumbnail_url
            provider_preview_url
            provider_ready_to_stream
            provider_file_size
            provider_stream_duration
            provider_playback_hls_url
            provider_playback_dash_url
          }
        }
        screener_video_urls {
          video_url_id
          video_type
          video_url
          is_mobile
          video_stream {
            provider_u_id
            provider_thumbnail_url
            provider_preview_url
            provider_ready_to_stream
            provider_file_size
            provider_stream_duration
            provider_playback_hls_url
            provider_playback_dash_url
          }
        }
        screener_meta {
          type
          key
          value
          image_urls {
            image_url_id
            image_type
            url
            is_mobile
            video_stream {
              provider_u_id
              provider_thumbnail_url
              provider_preview_url
              provider_ready_to_stream
              provider_file_size
              provider_stream_duration
              provider_playback_hls_url
              provider_playback_dash_url
            }
          }
        }
        screener_time
        sort_type
        screener_format
      }
    }
  }
`;
export const GET_INTERVENTION_GQL = gql`
  query getIntervention($intervention_id: String!) {
    getIntervention(intervention_id: $intervention_id) {
      status
      error
      message
      version
      service
      intervention {
        created_at
        created_by
        updated_by
        row_crc
        sub_domain_ids
        content_version
        update_counter
        published
        active
        intervention_id
        category_ids
        intervention_name
        alias_name
        intervention_code
        intervention_description {
          client_type
          description
        }
        intervention_image_urls {
          image_url_id
          image_type
          url
          is_mobile
          video_stream {
            provider_u_id
            provider_thumbnail_url
            provider_preview_url
            provider_ready_to_stream
            provider_file_size
            provider_stream_duration
            provider_playback_hls_url
            provider_playback_dash_url
          }
        }
        intervention_video_urls {
          video_url_id
          video_type
          video_url
          is_mobile
          video_stream {
            provider_u_id
            provider_thumbnail_url
            provider_preview_url
            provider_ready_to_stream
            provider_file_size
            provider_stream_duration
            provider_playback_hls_url
            provider_playback_dash_url
          }
        }
        intervention_document_urls {
          document_url_id
          name
          document_type
          url
          is_mobile
        }
        intervention_formats
        activity_type
        intervention_duration
        days_per_week
        intervention_data_url
        intervention_meta_datas {
          meta_data_type
          meta_data_title
          meta_data_value
          meta_data_image_urls {
            image_url_id
            image_type
            url
            is_mobile
            video_stream {
              provider_u_id
              provider_thumbnail_url
              provider_preview_url
              provider_ready_to_stream
              provider_file_size
              provider_stream_duration
              provider_playback_hls_url
              provider_playback_dash_url
            }
          }
        }
      }
    }
  }
`;
export const GET_EXPLAINER_LIST = gql`
  query getExplainerList(
    $get_pending_update: Boolean
    $pagination: PaginationFilter
    $dateFilter: DateFilter
    $active: Boolean
    $explainer_id: String
    $explainer_name: String
    $explainer_code: String
  ) {
    getExplainerList(
      get_pending_update: $get_pending_update
      pagination: $pagination
      dateFilter: $dateFilter
      active: $active
      explainer_id: $explainer_id
      explainer_name: $explainer_name
      explainer_code: $explainer_code
    ) {
      status
      error
      message
      version
      service
      pagination {
        page_number
        item_count
        total_count
      }
      explainers {
        created_at
        created_by
        updated_at
        updated_by
        row_crc
        explainer_id
        explainer_name
        explainer_code
        explainer_description {
          client_type
          description
        }
        explainer_image_urls {
          image_url_id
          image_type
          url
          is_mobile
          video_stream {
            provider_u_id
            provider_thumbnail_url
            provider_preview_url
            provider_ready_to_stream
            provider_file_size
            provider_stream_duration
            provider_playback_hls_url
            provider_playback_dash_url
          }
        }
        explainer_video_urls {
          video_type
          video_url
          is_mobile
          video_stream {
            provider_u_id
            provider_thumbnail_url
            provider_preview_url
            provider_ready_to_stream
            provider_file_size
            provider_stream_duration
            provider_playback_hls_url
            provider_playback_dash_url
          }
        }
        published
        active
      }
    }
  }
`;

export const GET_BLOG_LIST_GQL = gql`
  query getBlogList(
    $get_pending_update: Boolean
    $published: Boolean
    $is_featured: Boolean
    $is_pilot: Boolean
    $is_live: Boolean
    $pagination: PaginationFilter
    $dateFilter: DateFilter
    $active: Boolean
    $sub_domain_ids: [String!]
    $blog_ids: [String!]
    $blog_id: String
    $blog_type: String
    $blog_code: String
    $blog_name: String
    $intervention_ids: [String!]
    $author_ids: [String!]
    $author_name: String
    $category_ids: [String!]
    $sort_order: Float
    $alias_name: String
  ) {
    getBlogList(
      get_pending_update: $get_pending_update
      published: $published
      is_featured: $is_featured
      is_pilot: $is_pilot
      is_live: $is_live
      pagination: $pagination
      dateFilter: $dateFilter
      active: $active
      sub_domain_ids: $sub_domain_ids
      blog_ids: $blog_ids
      blog_id: $blog_id
      blog_type: $blog_type
      blog_code: $blog_code
      blog_name: $blog_name
      intervention_ids: $intervention_ids
      author_ids: $author_ids
      author_name: $author_name
      category_ids: $category_ids
      sort_order: $sort_order
      alias_name: $alias_name
    ) {
      status
      error
      message
      version
      service
      pagination {
        page_number
        item_count
        total_count
      }
      blogs {
        blog {
          created_at
          created_by
          updated_at
          updated_by
          row_crc
          sub_domain_ids
          content_version
          update_counter
          published
          active
          blog_id
          blog_code
          intervention_ids
          category_ids
          author_ids
          read_duration
          blog_name
          alias_name
          blog_type
          sort_order
          blog_descriptions {
            client_type
            description
          }
          blog_contents
          blog_image_urls {
            image_url_id
            image_type
            url
            is_mobile
            video_stream {
              provider_u_id
              provider_thumbnail_url
              provider_preview_url
              provider_ready_to_stream
              provider_file_size
              provider_stream_duration
              provider_playback_hls_url
              provider_playback_dash_url
            }
          }
          blog_video_urls {
            video_url_id
            video_type
            video_url
            is_mobile
            video_stream {
              provider_u_id
              provider_thumbnail_url
              provider_preview_url
              provider_ready_to_stream
              provider_file_size
              provider_stream_duration
              provider_playback_hls_url
              provider_playback_dash_url
            }
          }
          blog_meta {
            type
            key
            value
            image_urls {
              image_url_id
              image_type
              url
              is_mobile
              video_stream {
                provider_u_id
                provider_thumbnail_url
                provider_preview_url
                provider_ready_to_stream
                provider_file_size
                provider_stream_duration
                provider_playback_hls_url
                provider_playback_dash_url
              }
            }
          }
        }
        pending_blog_history {
          created_at
          created_by
          updated_at
          updated_by
          row_crc
          content_version
          update_counter
          content_type
          update_status
          update_type
          child_content_histories {
            child_content_history_id
            child_content_type
          }
          publish_data {
            created_by
            updated_by
            row_crc
            sub_domain_ids
            content_version
            update_counter
            published
            active
            blog_id
            blog_code
            intervention_ids
            category_ids
            author_ids
            read_duration
            blog_name
            alias_name
            blog_type
            sort_order
            blog_descriptions {
              client_type
              description
            }
            blog_contents
            blog_image_urls {
              image_url_id
              image_type
              url
              is_mobile
              video_stream {
                provider_u_id
                provider_thumbnail_url
                provider_preview_url
                provider_ready_to_stream
                provider_file_size
                provider_stream_duration
                provider_playback_hls_url
                provider_playback_dash_url
              }
            }
            blog_video_urls {
              video_url_id
              video_type
              video_url
              is_mobile
              video_stream {
                provider_u_id
                provider_thumbnail_url
                provider_preview_url
                provider_ready_to_stream
                provider_file_size
                provider_stream_duration
                provider_playback_hls_url
                provider_playback_dash_url
              }
            }
            blog_meta {
              type
              key
              value
              image_urls {
                image_url_id
                image_type
                url
                is_mobile
                video_stream {
                  provider_u_id
                  provider_thumbnail_url
                  provider_preview_url
                  provider_ready_to_stream
                  provider_file_size
                  provider_stream_duration
                  provider_playback_hls_url
                  provider_playback_dash_url
                }
              }
            }
          }
          publish_at
          reviewer_comment
          reviewed_by
          reviewed_at
          approver_comment
          approved_by
          approved_at
          data {
            created_by
            updated_by
            row_crc
            sub_domain_ids
            content_version
            update_counter
            published
            active
            blog_id
            blog_code
            intervention_ids
            category_ids
            author_ids
            read_duration
            blog_name
            alias_name
            blog_type
            sort_order
            blog_descriptions {
              client_type
              description
            }
            blog_contents
            blog_image_urls {
              image_url_id
              image_type
              url
              is_mobile
              video_stream {
                provider_u_id
                provider_thumbnail_url
                provider_preview_url
                provider_ready_to_stream
                provider_file_size
                provider_stream_duration
                provider_playback_hls_url
                provider_playback_dash_url
              }
            }
            blog_video_urls {
              video_url_id
              video_type
              video_url
              is_mobile
              video_stream {
                provider_u_id
                provider_thumbnail_url
                provider_preview_url
                provider_ready_to_stream
                provider_file_size
                provider_stream_duration
                provider_playback_hls_url
                provider_playback_dash_url
              }
            }
            blog_meta {
              type
              key
              value
              image_urls {
                image_url_id
                image_type
                url
                is_mobile
                video_stream {
                  provider_u_id
                  provider_thumbnail_url
                  provider_preview_url
                  provider_ready_to_stream
                  provider_file_size
                  provider_stream_duration
                  provider_playback_hls_url
                  provider_playback_dash_url
                }
              }
            }
          }
          updated_data {
            sub_domain_ids {
              update_items {
                update_type
                index
                update_data
              }
              IsValid
              MergeData
            }
            blog_name
            blog_code
            sort_order
            alias_name
            blog_type
            blog_contents
            blog_descriptions {
              update_items {
                update_type
                index
                update_data {
                  client_type
                  description
                }
              }
            }
            blog_image_urls {
              update_items {
                update_type
                index
                update_data {
                  image_url_id
                  image_type
                  url
                  is_mobile
                  video_stream {
                    provider_u_id
                    provider_thumbnail_url
                    provider_preview_url
                    provider_ready_to_stream
                    provider_file_size
                    provider_stream_duration
                    provider_playback_hls_url
                    provider_playback_dash_url
                  }
                }
              }
            }
            blog_video_urls {
              update_items {
                update_type
                index
                update_data {
                  video_url_id
                  video_type
                  video_url
                  is_mobile
                  video_stream {
                    provider_u_id
                    provider_thumbnail_url
                    provider_preview_url
                    provider_ready_to_stream
                    provider_file_size
                    provider_stream_duration
                    provider_playback_hls_url
                    provider_playback_dash_url
                  }
                }
              }
            }
            intervention_ids {
              update_items {
                update_type
                index
                update_data
              }
              IsValid
              MergeData
            }
            category_ids {
              update_items {
                update_type
                index
                update_data
              }
              IsValid
              MergeData
            }
            author_ids {
              update_items {
                update_type
                index
                update_data
              }
              IsValid
              MergeData
            }
            blog_meta {
              update_items {
                update_type
                index
                update_data {
                  type
                  key
                  value
                  image_urls {
                    image_url_id
                    image_type
                    url
                    is_mobile
                    video_stream {
                      provider_u_id
                      provider_thumbnail_url
                      provider_preview_url
                      provider_ready_to_stream
                      provider_file_size
                      provider_stream_duration
                      provider_playback_hls_url
                      provider_playback_dash_url
                    }
                  }
                  image_urls_update {
                    update_items {
                      update_type
                      index
                    }
                  }
                }
              }
            }
            read_duration
          }
          blog_id
          blog_history_id
        }
      }
    }
  }
`;

export const GET_ALL_BLOG_HISTORIES = gql`
  query getAllBlogHistories($blog_id: String!) {
    getAllBlogHistories(blog_id: $blog_id) {
      status
      error
      message
      version
      service
      blog_history_list {
        created_by
        updated_by
        row_crc
        content_version
        update_counter
        content_type
        update_status
        update_type
        child_content_histories {
          child_content_history_id
          child_content_type
        }
        publish_data {
          created_by
          updated_by
          row_crc
          sub_domain_ids
          content_version
          update_counter
          published
          active
          blog_id
          blog_code
          intervention_ids
          category_ids
          author_ids
          read_duration
          blog_name
          blog_type
          sort_order
          blog_descriptions {
            client_type
            description
          }
          blog_contents {
            title
            content
            sort_order
            image_urls {
              image_url_id
              image_type
              url
              is_mobile
              video_stream {
                provider_u_id
                provider_thumbnail_url
                provider_preview_url
                provider_ready_to_stream
                provider_file_size
                provider_stream_duration
                provider_playback_hls_url
                provider_playback_dash_url
              }
            }
            video_urls {
              video_url_id
              video_type
              video_url
              is_mobile
              video_stream {
                provider_u_id
                provider_thumbnail_url
                provider_preview_url
                provider_ready_to_stream
                provider_file_size
                provider_stream_duration
                provider_playback_hls_url
                provider_playback_dash_url
              }
            }
            blog_content_type
          }
          blog_image_urls {
            image_url_id
            image_type
            url
            is_mobile
            video_stream {
              provider_u_id
              provider_thumbnail_url
              provider_preview_url
              provider_ready_to_stream
              provider_file_size
              provider_stream_duration
              provider_playback_hls_url
              provider_playback_dash_url
            }
          }
          blog_video_urls {
            video_url_id
            video_type
            video_url
            is_mobile
            video_stream {
              provider_u_id
              provider_thumbnail_url
              provider_preview_url
              provider_ready_to_stream
              provider_file_size
              provider_stream_duration
              provider_playback_hls_url
              provider_playback_dash_url
            }
          }
          blog_meta {
            type
            key
            value
            image_urls {
              image_url_id
              image_type
              url
              is_mobile
              video_stream {
                provider_u_id
                provider_thumbnail_url
                provider_preview_url
                provider_ready_to_stream
                provider_file_size
                provider_stream_duration
                provider_playback_hls_url
                provider_playback_dash_url
              }
            }
          }
        }
        publish_at
        reviewer_comment
        reviewed_by
        reviewed_at
        approver_comment
        approved_by
        approved_at
        data {
          created_by
          updated_by
          row_crc
          sub_domain_ids
          content_version
          update_counter
          published
          active
          blog_id
          blog_code
          intervention_ids
          category_ids
          author_ids
          read_duration
          blog_name
          blog_type
          sort_order
          blog_descriptions {
            client_type
            description
          }
          blog_contents {
            title
            content
            sort_order
            image_urls {
              image_url_id
              image_type
              url
              is_mobile
              video_stream {
                provider_u_id
                provider_thumbnail_url
                provider_preview_url
                provider_ready_to_stream
                provider_file_size
                provider_stream_duration
                provider_playback_hls_url
                provider_playback_dash_url
              }
            }
            video_urls {
              video_url_id
              video_type
              video_url
              is_mobile
              video_stream {
                provider_u_id
                provider_thumbnail_url
                provider_preview_url
                provider_ready_to_stream
                provider_file_size
                provider_stream_duration
                provider_playback_hls_url
                provider_playback_dash_url
              }
            }
            blog_content_type
          }
          blog_image_urls {
            image_url_id
            image_type
            url
            is_mobile
            video_stream {
              provider_u_id
              provider_thumbnail_url
              provider_preview_url
              provider_ready_to_stream
              provider_file_size
              provider_stream_duration
              provider_playback_hls_url
              provider_playback_dash_url
            }
          }
          blog_video_urls {
            video_url_id
            video_type
            video_url
            is_mobile
            video_stream {
              provider_u_id
              provider_thumbnail_url
              provider_preview_url
              provider_ready_to_stream
              provider_file_size
              provider_stream_duration
              provider_playback_hls_url
              provider_playback_dash_url
            }
          }
          blog_meta {
            type
            key
            value
            image_urls {
              image_url_id
              image_type
              url
              is_mobile
              video_stream {
                provider_u_id
                provider_thumbnail_url
                provider_preview_url
                provider_ready_to_stream
                provider_file_size
                provider_stream_duration
                provider_playback_hls_url
                provider_playback_dash_url
              }
            }
          }
        }
        updated_data {
          sub_domain_ids {
            update_items {
              update_type
              index
              update_data
            }
            IsValid
            MergeData
          }
          blog_name
          blog_code
          sort_order
          blog_type
          blog_contents {
            update_items {
              update_type
              index
              update_data {
                title
                content
                sort_order
                image_urls {
                  image_url_id
                  image_type
                  url
                  is_mobile
                  video_stream {
                    provider_u_id
                    provider_thumbnail_url
                    provider_preview_url
                    provider_ready_to_stream
                    provider_file_size
                    provider_stream_duration
                    provider_playback_hls_url
                    provider_playback_dash_url
                  }
                }
                video_urls {
                  video_url_id
                  video_type
                  video_url
                  is_mobile
                  video_stream {
                    provider_u_id
                    provider_thumbnail_url
                    provider_preview_url
                    provider_ready_to_stream
                    provider_file_size
                    provider_stream_duration
                    provider_playback_hls_url
                    provider_playback_dash_url
                  }
                }
                blog_content_type
                content_update {
                  update_items {
                    update_type
                    index
                    update_data
                  }
                  IsValid
                  MergeData
                }
                image_urls_update {
                  update_items {
                    update_type
                    index
                  }
                }
                video_urls_update {
                  update_items {
                    update_type
                    index
                  }
                }
              }
            }
          }
          blog_descriptions {
            update_items {
              update_type
              index
              update_data {
                client_type
                description
              }
            }
          }
          blog_image_urls {
            update_items {
              update_type
              index
              update_data {
                image_url_id
                image_type
                url
                is_mobile
                video_stream {
                  provider_u_id
                  provider_thumbnail_url
                  provider_preview_url
                  provider_ready_to_stream
                  provider_file_size
                  provider_stream_duration
                  provider_playback_hls_url
                  provider_playback_dash_url
                }
              }
            }
          }
          blog_video_urls {
            update_items {
              update_type
              index
              update_data {
                video_url_id
                video_type
                video_url
                is_mobile
                video_stream {
                  provider_u_id
                  provider_thumbnail_url
                  provider_preview_url
                  provider_ready_to_stream
                  provider_file_size
                  provider_stream_duration
                  provider_playback_hls_url
                  provider_playback_dash_url
                }
              }
            }
          }
          intervention_ids {
            update_items {
              update_type
              index
              update_data
            }
            IsValid
            MergeData
          }
          category_ids {
            update_items {
              update_type
              index
              update_data
            }
            IsValid
            MergeData
          }
          author_ids {
            update_items {
              update_type
              index
              update_data
            }
            IsValid
            MergeData
          }
          blog_meta {
            update_items {
              update_type
              index
              update_data {
                type
                key
                value
                image_urls {
                  image_url_id
                  image_type
                  url
                  is_mobile
                  video_stream {
                    provider_u_id
                    provider_thumbnail_url
                    provider_preview_url
                    provider_ready_to_stream
                    provider_file_size
                    provider_stream_duration
                    provider_playback_hls_url
                    provider_playback_dash_url
                  }
                }
                image_urls_update {
                  update_items {
                    update_type
                    index
                  }
                }
              }
            }
          }
          read_duration
        }
        blog_id
        blog_history_id
      }
    }
  }
`;

export const GET_MODULE_LIST_GQL = gql`
  query getTherapyModuleList(
    $get_pending_update: Boolean
    $published: Boolean
    $is_featured: Boolean
    $is_pilot: Boolean
    $is_live: Boolean
    $pagination: PaginationFilter
    $dateFilter: DateFilter
    $active: Boolean
    $therapy_id: String
    $therapy_module_id: String
    $therapy_module_name: String
    $therapy_module_code: String
  ) {
    getTherapyModuleList(
      get_pending_update: $get_pending_update
      published: $published
      is_featured: $is_featured
      is_pilot: $is_pilot
      is_live: $is_live
      pagination: $pagination
      dateFilter: $dateFilter
      active: $active
      therapy_id: $therapy_id
      therapy_module_id: $therapy_module_id
      therapy_module_name: $therapy_module_name
      therapy_module_code: $therapy_module_code
    ) {
      status
      error
      message
      version
      service
      pagination {
        page_number
        item_count
        total_count
      }
      therapy_modules {
        therapy_module {
          created_by
          updated_by
          row_crc
          sub_domain_ids
          content_version
          update_counter
          published
          active
          therapy_module_id
          therapy_id
          therapy_module_code
          intervention_ids
          sort_order
          min_completion_days
          max_completion_days
          start_interval_seconds
          therapy_module_name
          therapy_module_description {
            client_type
            description
          }
          therapy_module_image_urls {
            image_url_id
            image_type
            url
            is_mobile
            video_stream {
              provider_u_id
              provider_thumbnail_url
              provider_preview_url
              provider_ready_to_stream
              provider_file_size
              provider_stream_duration
              provider_playback_hls_url
              provider_playback_dash_url
            }
          }
          therapy_module_video_urls {
            video_url_id
            video_type
            video_url
            is_mobile
            video_stream {
              provider_u_id
              provider_thumbnail_url
              provider_preview_url
              provider_ready_to_stream
              provider_file_size
              provider_stream_duration
              provider_playback_hls_url
              provider_playback_dash_url
            }
          }
          therapy_module_meta {
            type
            key
            value
            image_urls {
              image_url_id
              image_type
              url
              is_mobile
              video_stream {
                provider_u_id
                provider_thumbnail_url
                provider_preview_url
                provider_ready_to_stream
                provider_file_size
                provider_stream_duration
                provider_playback_hls_url
                provider_playback_dash_url
              }
            }
          }
        }
        pending_therapy_module_history {
          created_by
          updated_by
          row_crc
          content_version
          update_counter
          content_type
          update_status
          update_type
          child_content_histories {
            child_content_history_id
            child_content_type
          }
          publish_data {
            created_by
            updated_by
            row_crc
            sub_domain_ids
            content_version
            update_counter
            published
            active
            therapy_module_id
            therapy_id
            therapy_module_code
            intervention_ids
            sort_order
            min_completion_days
            max_completion_days
            start_interval_seconds
            therapy_module_name
            therapy_module_description {
              client_type
              description
            }
            therapy_module_image_urls {
              image_url_id
              image_type
              url
              is_mobile
              video_stream {
                provider_u_id
                provider_thumbnail_url
                provider_preview_url
                provider_ready_to_stream
                provider_file_size
                provider_stream_duration
                provider_playback_hls_url
                provider_playback_dash_url
              }
            }
            therapy_module_video_urls {
              video_url_id
              video_type
              video_url
              is_mobile
              video_stream {
                provider_u_id
                provider_thumbnail_url
                provider_preview_url
                provider_ready_to_stream
                provider_file_size
                provider_stream_duration
                provider_playback_hls_url
                provider_playback_dash_url
              }
            }
            therapy_module_meta {
              type
              key
              value
              image_urls {
                image_url_id
                image_type
                url
                is_mobile
                video_stream {
                  provider_u_id
                  provider_thumbnail_url
                  provider_preview_url
                  provider_ready_to_stream
                  provider_file_size
                  provider_stream_duration
                  provider_playback_hls_url
                  provider_playback_dash_url
                }
              }
            }
          }
          reviewer_comment
          reviewed_by
          approver_comment
          approved_by
          data {
            created_by
            updated_by
            row_crc
            sub_domain_ids
            content_version
            update_counter
            published
            active
            therapy_module_id
            therapy_id
            therapy_module_code
            intervention_ids
            sort_order
            min_completion_days
            max_completion_days
            start_interval_seconds
            therapy_module_name
            therapy_module_description {
              client_type
              description
            }
            therapy_module_image_urls {
              image_url_id
              image_type
              url
              is_mobile
              video_stream {
                provider_u_id
                provider_thumbnail_url
                provider_preview_url
                provider_ready_to_stream
                provider_file_size
                provider_stream_duration
                provider_playback_hls_url
                provider_playback_dash_url
              }
            }
            therapy_module_video_urls {
              video_url_id
              video_type
              video_url
              is_mobile
              video_stream {
                provider_u_id
                provider_thumbnail_url
                provider_preview_url
                provider_ready_to_stream
                provider_file_size
                provider_stream_duration
                provider_playback_hls_url
                provider_playback_dash_url
              }
            }
            therapy_module_meta {
              type
              key
              value
              image_urls {
                image_url_id
                image_type
                url
                is_mobile
                video_stream {
                  provider_u_id
                  provider_thumbnail_url
                  provider_preview_url
                  provider_ready_to_stream
                  provider_file_size
                  provider_stream_duration
                  provider_playback_hls_url
                  provider_playback_dash_url
                }
              }
            }
          }
          updated_data {
            therapy_id
            sub_domain_ids {
              update_items {
                update_type
                index
                update_data
              }
              IsValid
              MergeData
            }
            intervention_ids {
              update_items {
                update_type
                index
                update_data
              }
              IsValid
              MergeData
            }
            therapy_module_name
            therapy_module_code
            therapy_module_description {
              update_items {
                update_type
                index
                update_data {
                  client_type
                  description
                }
              }
            }
            therapy_module_image_urls {
              update_items {
                update_type
                index
                update_data {
                  image_url_id
                  image_type
                  url
                  is_mobile
                  video_stream {
                    provider_u_id
                    provider_thumbnail_url
                    provider_preview_url
                    provider_ready_to_stream
                    provider_file_size
                    provider_stream_duration
                    provider_playback_hls_url
                    provider_playback_dash_url
                  }
                }
              }
            }
            therapy_module_video_urls {
              update_items {
                update_type
                index
                update_data {
                  video_url_id
                  video_type
                  video_url
                  is_mobile
                  video_stream {
                    provider_u_id
                    provider_thumbnail_url
                    provider_preview_url
                    provider_ready_to_stream
                    provider_file_size
                    provider_stream_duration
                    provider_playback_hls_url
                    provider_playback_dash_url
                  }
                }
              }
            }
            sort_order
            min_completion_days
            max_completion_days
            start_interval_seconds
            therapy_module_meta {
              update_items {
                update_type
                index
                update_data {
                  type
                  key
                  value
                  image_urls {
                    image_url_id
                    image_type
                    url
                    is_mobile
                    video_stream {
                      provider_u_id
                      provider_thumbnail_url
                      provider_preview_url
                      provider_ready_to_stream
                      provider_file_size
                      provider_stream_duration
                      provider_playback_hls_url
                      provider_playback_dash_url
                    }
                  }
                  image_urls_update {
                    update_items {
                      update_type
                      index
                    }
                  }
                }
              }
            }
            published
            content_version
            update_counter
            active
          }
          therapy_module_history_id
          therapy_module_id
          therapy_id
        }
      }
    }
  }
`;

export const GET_MODULE_ACTION_LIST_GQL = gql`
  query getTherapyModuleActionList(
    $get_pending_update: Boolean
    $published: Boolean
    $is_featured: Boolean
    $is_pilot: Boolean
    $is_live: Boolean
    $platform_content_mode_tag: PlatformContentModeTag
    $pagination: PaginationFilter
    $dateFilter: DateFilter
    $active: Boolean
    $therapy_id: String
    $therapy_module_action_id: String
    $therapy_module_id: String
    $therapy_action_type: TherapyActionType
    $intervention_id: String
    $screener_id: String
    $sort_order: Float
    $therapy_module_action_code: String
    $therapy_module_action_name: String
  ) {
    getTherapyModuleActionList(
      get_pending_update: $get_pending_update
      published: $published
      is_featured: $is_featured
      is_pilot: $is_pilot
      is_live: $is_live
      platform_content_mode_tag: $platform_content_mode_tag
      pagination: $pagination
      dateFilter: $dateFilter
      active: $active
      therapy_id: $therapy_id
      therapy_module_action_id: $therapy_module_action_id
      therapy_module_id: $therapy_module_id
      therapy_action_type: $therapy_action_type
      intervention_id: $intervention_id
      screener_id: $screener_id
      sort_order: $sort_order
      therapy_module_action_code: $therapy_module_action_code
      therapy_module_action_name: $therapy_module_action_name
    ) {
      status
      error
      message
      version
      service
      pagination {
        page_number
        item_count
        total_count
      }
      therapy_module_actions {
        therapy_module_action {
          created_by
          updated_by
          row_crc
          sub_domain_ids
          content_version
          update_counter
          published
          active
          therapy_module_action_id
          therapy_id
          therapy_module_id
          therapy_module_action_code
          therapy_action_type
          intervention_id
          screener_id
          sort_order
          therapy_module_action_name
          therapy_module_action_description {
            client_type
            description
          }
          therapy_module_action_image_urls {
            image_url_id
            image_type
            url
            is_mobile
            video_stream {
              provider_u_id
              provider_thumbnail_url
              provider_preview_url
              provider_ready_to_stream
              provider_file_size
              provider_stream_duration
              provider_playback_hls_url
              provider_playback_dash_url
            }
          }
          therapy_module_action_document_urls {
            document_url_id
            name
            document_type
            url
            is_mobile
          }
          therapy_module_action_video_urls {
            video_url_id
            video_type
            video_url
            is_mobile
            video_stream {
              provider_u_id
              provider_thumbnail_url
              provider_preview_url
              provider_ready_to_stream
              provider_file_size
              provider_stream_duration
              provider_playback_hls_url
              provider_playback_dash_url
            }
          }
        }
        pending_therapy_module_action_history {
          created_by
          updated_by
          row_crc
          content_version
          update_counter
          content_type
          update_status
          update_type
          child_content_histories {
            child_content_history_id
            child_content_type
          }
          publish_data {
            created_by
            updated_by
            row_crc
            sub_domain_ids
            content_version
            update_counter
            published
            active
            therapy_module_action_id
            therapy_id
            therapy_module_id
            therapy_module_action_code
            therapy_action_type
            intervention_id
            screener_id
            sort_order
            therapy_module_action_name
            therapy_module_action_description {
              client_type
              description
            }
            therapy_module_action_image_urls {
              image_url_id
              image_type
              url
              is_mobile
              video_stream {
                provider_u_id
                provider_thumbnail_url
                provider_preview_url
                provider_ready_to_stream
                provider_file_size
                provider_stream_duration
                provider_playback_hls_url
                provider_playback_dash_url
              }
            }
            therapy_module_action_document_urls {
              document_url_id
              name
              document_type
              url
              is_mobile
            }
            therapy_module_action_video_urls {
              video_url_id
              video_type
              video_url
              is_mobile
              video_stream {
                provider_u_id
                provider_thumbnail_url
                provider_preview_url
                provider_ready_to_stream
                provider_file_size
                provider_stream_duration
                provider_playback_hls_url
                provider_playback_dash_url
              }
            }
          }
          reviewer_comment
          reviewed_by
          approver_comment
          approved_by
          data {
            created_by
            updated_by
            row_crc
            sub_domain_ids
            content_version
            update_counter
            published
            active
            therapy_module_action_id
            therapy_id
            therapy_module_id
            therapy_module_action_code
            therapy_action_type
            intervention_id
            screener_id
            sort_order
            therapy_module_action_name
            therapy_module_action_description {
              client_type
              description
            }
            therapy_module_action_image_urls {
              image_url_id
              image_type
              url
              is_mobile
              video_stream {
                provider_u_id
                provider_thumbnail_url
                provider_preview_url
                provider_ready_to_stream
                provider_file_size
                provider_stream_duration
                provider_playback_hls_url
                provider_playback_dash_url
              }
            }
            therapy_module_action_document_urls {
              document_url_id
              name
              document_type
              url
              is_mobile
            }
            therapy_module_action_video_urls {
              video_url_id
              video_type
              video_url
              is_mobile
              video_stream {
                provider_u_id
                provider_thumbnail_url
                provider_preview_url
                provider_ready_to_stream
                provider_file_size
                provider_stream_duration
                provider_playback_hls_url
                provider_playback_dash_url
              }
            }
          }
          updated_data {
            therapy_id
            therapy_module_id
            screener_id
            sub_domain_ids {
              update_items {
                update_type
                index
                update_data
              }
              IsValid
              MergeData
            }
            therapy_action_type
            intervention_id
            therapy_module_action_code
            sort_order
            therapy_module_action_name
            therapy_module_action_description {
              update_items {
                update_type
                index
                update_data {
                  client_type
                  description
                }
              }
            }
            therapy_module_action_image_urls {
              update_items {
                update_type
                index
                update_data {
                  image_url_id
                  image_type
                  url
                  is_mobile
                  video_stream {
                    provider_u_id
                    provider_thumbnail_url
                    provider_preview_url
                    provider_ready_to_stream
                    provider_file_size
                    provider_stream_duration
                    provider_playback_hls_url
                    provider_playback_dash_url
                  }
                }
              }
            }
            therapy_module_action_document_urls {
              update_items {
                update_type
                index
                update_data {
                  document_url_id
                  name
                  document_type
                  url
                  is_mobile
                }
              }
            }
            therapy_module_action_video_urls {
              update_items {
                update_type
                index
                update_data {
                  video_url_id
                  video_type
                  video_url
                  is_mobile
                  video_stream {
                    provider_u_id
                    provider_thumbnail_url
                    provider_preview_url
                    provider_ready_to_stream
                    provider_file_size
                    provider_stream_duration
                    provider_playback_hls_url
                    provider_playback_dash_url
                  }
                }
              }
            }
            published
            content_version
            update_counter
            active
          }
          therapy_module_action_history_id
          therapy_module_action_id
          therapy_module_id
        }
      }
    }
  }
`;

export const GET_ALL_THERAPY_HISTORIES = gql`
  query getAllTherapyHistories($therapy_id: String!) {
    getAllTherapyHistories(therapy_id: $therapy_id) {
      status
      error
      message
      version
      service
      therapy_history_list {
        created_by
        updated_by
        row_crc
        content_version
        update_counter
        content_type
        update_status
        update_type
        child_content_histories {
          child_content_history_id
          child_content_type
        }
        publish_data {
          created_by
          updated_by
          row_crc
          sub_domain_ids
          content_version
          update_counter
          published
          active
          therapy_id
          screener_id
          therapy_code
          screener_result_range_min
          screener_result_range_max
          therapy_name
          therapy_description {
            client_type
            description
          }
          therapy_image_urls {
            image_url_id
            image_type
            url
            is_mobile
            video_stream {
              provider_u_id
              provider_thumbnail_url
              provider_preview_url
              provider_ready_to_stream
              provider_file_size
              provider_stream_duration
              provider_playback_hls_url
              provider_playback_dash_url
            }
          }
          therapy_video_urls {
            video_url_id
            video_type
            video_url
            is_mobile
            video_stream {
              provider_u_id
              provider_thumbnail_url
              provider_preview_url
              provider_ready_to_stream
              provider_file_size
              provider_stream_duration
              provider_playback_hls_url
              provider_playback_dash_url
            }
          }
          therapy_document_urls {
            document_url_id
            name
            document_type
            url
            is_mobile
          }
          therapy_meta {
            type
            key
            value
            image_urls {
              image_url_id
              image_type
              url
              is_mobile
              video_stream {
                provider_u_id
                provider_thumbnail_url
                provider_preview_url
                provider_ready_to_stream
                provider_file_size
                provider_stream_duration
                provider_playback_hls_url
                provider_playback_dash_url
              }
            }
          }
          total_duration
          time_per_module
          days_per_week
        }
        reviewer_comment
        reviewed_by
        approver_comment
        approved_by
        data {
          created_by
          updated_by
          row_crc
          sub_domain_ids
          content_version
          update_counter
          published
          active
          therapy_id
          screener_id
          therapy_code
          screener_result_range_min
          screener_result_range_max
          therapy_name
          therapy_description {
            client_type
            description
          }
          therapy_image_urls {
            image_url_id
            image_type
            url
            is_mobile
            video_stream {
              provider_u_id
              provider_thumbnail_url
              provider_preview_url
              provider_ready_to_stream
              provider_file_size
              provider_stream_duration
              provider_playback_hls_url
              provider_playback_dash_url
            }
          }
          therapy_video_urls {
            video_url_id
            video_type
            video_url
            is_mobile
            video_stream {
              provider_u_id
              provider_thumbnail_url
              provider_preview_url
              provider_ready_to_stream
              provider_file_size
              provider_stream_duration
              provider_playback_hls_url
              provider_playback_dash_url
            }
          }
          therapy_document_urls {
            document_url_id
            name
            document_type
            url
            is_mobile
          }
          therapy_meta {
            type
            key
            value
            image_urls {
              image_url_id
              image_type
              url
              is_mobile
              video_stream {
                provider_u_id
                provider_thumbnail_url
                provider_preview_url
                provider_ready_to_stream
                provider_file_size
                provider_stream_duration
                provider_playback_hls_url
                provider_playback_dash_url
              }
            }
          }
          total_duration
          time_per_module
          days_per_week
        }
        updated_data {
          sub_domain_ids {
            update_items {
              update_type
              index
              update_data
            }
            IsValid
            MergeData
          }
          therapy_name
          therapy_code
          screener_id
          screener_result_range_min
          screener_result_range_max
          therapy_description {
            update_items {
              update_type
              index
              update_data {
                client_type
                description
              }
            }
          }
          therapy_image_urls {
            update_items {
              update_type
              index
              update_data {
                image_url_id
                image_type
                url
                is_mobile
                video_stream {
                  provider_u_id
                  provider_thumbnail_url
                  provider_preview_url
                  provider_ready_to_stream
                  provider_file_size
                  provider_stream_duration
                  provider_playback_hls_url
                  provider_playback_dash_url
                }
              }
            }
          }
          therapy_video_urls {
            update_items {
              update_type
              index
              update_data {
                video_url_id
                video_type
                video_url
                is_mobile
                video_stream {
                  provider_u_id
                  provider_thumbnail_url
                  provider_preview_url
                  provider_ready_to_stream
                  provider_file_size
                  provider_stream_duration
                  provider_playback_hls_url
                  provider_playback_dash_url
                }
              }
            }
          }
          therapy_document_urls {
            update_items {
              update_type
              index
              update_data {
                document_url_id
                name
                document_type
                url
                is_mobile
              }
            }
          }
          therapy_meta {
            update_items {
              update_type
              index
              update_data {
                type
                key
                value
                image_urls {
                  image_url_id
                  image_type
                  url
                  is_mobile
                  video_stream {
                    provider_u_id
                    provider_thumbnail_url
                    provider_preview_url
                    provider_ready_to_stream
                    provider_file_size
                    provider_stream_duration
                    provider_playback_hls_url
                    provider_playback_dash_url
                  }
                }
                image_urls_update {
                  update_items {
                    update_type
                    index
                  }
                }
              }
            }
          }
          total_duration
          time_per_module
          days_per_week
          is_featured
          published
          content_version
          update_counter
          active
        }
        therapy_history_id
        therapy_id
      }
    }
  }
`;

export const GET_LOCALIZE_LIST_GQL = gql`
  query getLocalizationDataList(
    $pagination: PaginationFilter
    $dateFilter: DateFilter
    $active: Boolean
    $sub_domain_id: String
    $localization_data_code: String
    $language_id: String
    $localization_data_id: String
    $keys: [String!]
    $key: String
  ) {
    getLocalizationDataList(
      pagination: $pagination
      dateFilter: $dateFilter
      active: $active
      sub_domain_id: $sub_domain_id
      localization_data_code: $localization_data_code
      language_id: $language_id
      localization_data_id: $localization_data_id
      keys: $keys
      key: $key
    ) {
      status
      error
      message
      version
      service
      pagination {
        page_number
        item_count
        total_count
      }
      localization_datas {
        created_at
        created_by
        updated_at
        updated_by
        row_crc
        localization_data_id
        sub_domain_id
        localization_data_code
        language_id
        key
        value {
          description_value {
            client_type
            description
          }
          image_urls {
            image_url_id
            image_type
            url
            is_mobile
            video_stream {
              provider_u_id
              provider_thumbnail_url
              provider_preview_url
              provider_ready_to_stream
              provider_file_size
              provider_stream_duration
              provider_playback_hls_url
              provider_playback_dash_url
            }
          }
        }
        active
      }
    }
  }
`;

export const GET_LANGUAGE_LIST_GQL = gql`
  query getLanguageList(
    $pagination: PaginationFilter
    $dateFilter: DateFilter
    $active: Boolean
    $language_name: String
    $language_iso_short_code: String
  ) {
    getLanguageList(
      pagination: $pagination
      dateFilter: $dateFilter
      active: $active
      language_name: $language_name
      language_iso_short_code: $language_iso_short_code
    ) {
      status
      error
      message
      version
      service
      pagination {
        page_number
        item_count
        total_count
      }
      languages {
        created_at
        created_by
        updated_at
        updated_by
        language_id
        language_name
        language_description
        language_image_urls {
          image_type
          url
          is_mobile
        }
        language_iso_short_code
        active
      }
    }
  }
`;

export const GET_CATEGORY_LIST_GQL = gql`
  query getCategoryList(
    $pagination: PaginationFilter
    $dateFilter: DateFilter
    $active: Boolean
    $category_id: String
    $sub_domain_id: String
    $category_name: String
    $sort_order: Float
    $supported_content_types: [ContentType!]
    $category_code: String
  ) {
    getCategoryList(
      pagination: $pagination
      dateFilter: $dateFilter
      active: $active
      category_id: $category_id
      sub_domain_id: $sub_domain_id
      category_name: $category_name
      sort_order: $sort_order
      supported_content_types: $supported_content_types
      category_code: $category_code
    ) {
      status
      error
      message
      version
      service
      pagination {
        page_number
        item_count
        total_count
      }
      categories {
        created_at
        created_by
        updated_at
        updated_by
        category_id
        sub_domain_id
        category_name
        sort_order
        supported_content_types
        category_description {
          client_type
          description
        }
        category_image_urls {
          image_url_id
          image_type
          url
          is_mobile
          video_stream {
            provider_u_id
            provider_thumbnail_url
            provider_preview_url
            provider_ready_to_stream
            provider_file_size
            provider_stream_duration
            provider_playback_hls_url
            provider_playback_dash_url
          }
        }
        category_code
        active
      }
    }
  }
`;

export const GET_CATEGORY_GQL = gql`
  query getCategory($category_id: String!) {
    getCategory(category_id: $category_id) {
      status
      error
      message
      version
      service
      category {
        created_at
        created_by
        updated_at
        updated_by
        category_id
        sub_domain_id
        category_name
        sort_order
        supported_content_types
        category_description {
          client_type
          description
        }
        category_image_urls {
          image_url_id
          image_type
          url
          is_mobile
          video_stream {
            provider_u_id
            provider_thumbnail_url
            provider_preview_url
            provider_ready_to_stream
            provider_file_size
            provider_stream_duration
            provider_playback_hls_url
            provider_playback_dash_url
          }
        }
        category_code
        active
      }
    }
  }
`;

export const GET_FAQ_LIST_GQL = gql`
  query getFAQList(
    $pagination: PaginationFilter
    $dateFilter: DateFilter
    $active: Boolean
    $faq_id: String
    $category: String
    $faq_category_name: String
  ) {
    getFAQList(
      pagination: $pagination
      dateFilter: $dateFilter
      active: $active
      faq_id: $faq_id
      category: $category
      faq_category_name: $faq_category_name
    ) {
      status
      error
      message
      version
      service
      pagination {
        page_number
        item_count
        total_count
      }
      faqs {
        created_at
        created_by
        updated_at
        updated_by
        row_crc
        faq_id
        faq_category_name
        question {
          client_type
          description
        }
        answer {
          client_type
          description
        }
        faq_image_urls {
          image_url_id
          image_type
          url
          is_mobile
          video_stream {
            provider_u_id
            provider_thumbnail_url
            provider_preview_url
            provider_ready_to_stream
            provider_file_size
            provider_stream_duration
            provider_playback_hls_url
            provider_playback_dash_url
          }
        }
        active
      }
    }
  }
`;

export const GET_FAQ_GQL = gql`
  query getFAQ($faq_id: String!) {
    getFAQ(faq_id: $faq_id) {
      status
      error
      message
      version
      service
      faq {
        created_at
        created_by
        updated_at
        updated_by
        row_crc
        faq_id
        faq_category_name
        question {
          client_type
          description
        }
        answer {
          client_type
          description
        }
        faq_image_urls {
          image_url_id
          image_type
          url
          is_mobile
          video_stream {
            provider_u_id
            provider_thumbnail_url
            provider_preview_url
            provider_ready_to_stream
            provider_file_size
            provider_stream_duration
            provider_playback_hls_url
            provider_playback_dash_url
          }
        }
        active
      }
    }
  }
`;

export const GET_FAQ_CATEGORY_LIST_GQL = gql`
  query getFAQCategoryList(
    $pagination: PaginationFilter
    $dateFilter: DateFilter
    $active: Boolean
    $faq_category_name: String
    $faq_category_type: FAQCategoryType
  ) {
    getFAQCategoryList(
      pagination: $pagination
      dateFilter: $dateFilter
      active: $active
      faq_category_name: $faq_category_name
      faq_category_type: $faq_category_type
    ) {
      status
      error
      message
      version
      service
      pagination {
        page_number
        item_count
        total_count
      }
      faq_categories {
        created_at
        created_by
        updated_at
        updated_by
        row_crc
        faq_category_name
        faq_category_type
        faq_category_description {
          client_type
          description
        }
        faq_category_image_urls {
          image_url_id
          image_type
          url
          is_mobile
          video_stream {
            provider_u_id
            provider_thumbnail_url
            provider_preview_url
            provider_ready_to_stream
            provider_file_size
            provider_stream_duration
            provider_playback_hls_url
            provider_playback_dash_url
          }
        }
        active
      }
    }
  }
`;

export const GET_FAQ_CATEGORY_GQL = gql`
  query getFAQCategory($faq_category_name: String!) {
    getFAQCategory(faq_category_name: $faq_category_name) {
      status
      error
      message
      version
      service
      faq_category {
        created_at
        created_by
        updated_at
        updated_by
        row_crc
        faq_category_name
        faq_category_type
        faq_category_description {
          client_type
          description
        }
        faq_category_image_urls {
          image_url_id
          image_type
          url
          is_mobile
          video_stream {
            provider_u_id
            provider_thumbnail_url
            provider_preview_url
            provider_ready_to_stream
            provider_file_size
            provider_stream_duration
            provider_playback_hls_url
            provider_playback_dash_url
          }
        }
        active
      }
    }
  }
`;

export const GET_POLICY_LIST_GQL = gql`
  query getPolicyList(
    $pagination: PaginationFilter
    $dateFilter: DateFilter
    $active: Boolean
    $policy_id: String
    $policy_user_type: PolicyUserType
    $policy_type: PolicyType
    $policy_title: String
  ) {
    getPolicyList(
      pagination: $pagination
      dateFilter: $dateFilter
      active: $active
      policy_id: $policy_id
      policy_user_type: $policy_user_type
      policy_type: $policy_type
      policy_title: $policy_title
    ) {
      status
      error
      message
      version
      service
      pagination {
        page_number
        item_count
        total_count
      }
      policies {
        created_at
        created_by
        updated_at
        updated_by
        row_crc
        policy_id
        policy_title
        policy_user_type
        policy_body
        policy_url
        policy_version
        policy_type
        active
      }
    }
  }
`;
export const GET_TIMEZONE_LIST_GQL = gql`
  query getTimeZoneList(
    $pagination: PaginationFilter
    $dateFilter: DateFilter
    $active: Boolean
    $time_zone_id: String
    $time_zone_name: String
  ) {
    getTimeZoneList(
      pagination: $pagination
      dateFilter: $dateFilter
      active: $active
      time_zone_id: $time_zone_id
      time_zone_name: $time_zone_name
    ) {
      status
      error
      message
      version
      service
      pagination {
        page_number
        item_count
        total_count
      }
      time_zones {
        created_at
        created_by
        updated_at
        updated_by
        time_zone_id
        time_zone_name
        time_zone_description
        time_zone_image_urls {
          image_type
          url
          is_mobile
        }
        time_zone_short_code
        time_zone_utc_offset
        active
      }
    }
  }
`;
export const GET_COUNTRY_LIST = gql`
  query getCountryList(
    $pagination: PaginationFilter
    $dateFilter: DateFilter
    $active: Boolean
    $country_name: String
  ) {
    getCountryList(
      pagination: $pagination
      dateFilter: $dateFilter
      active: $active
      country_name: $country_name
    ) {
      status
      error
      message
      version
      service
      pagination {
        page_number
        item_count
        total_count
      }
      countries {
        created_at
        created_by
        updated_at
        updated_by
        country_id
        country_name
        country_description
        country_image_urls {
          image_type
          url
          is_mobile
        }
        country_mobile_number_prefix
        country_short_code
        active
      }
    }
  }
`;
export const GET_CITY_LIST = gql`
  query getCityList(
    $pagination: PaginationFilter
    $dateFilter: DateFilter
    $active: Boolean
    $city_id: String
    $country_id: String
    $city_name: String
  ) {
    getCityList(
      pagination: $pagination
      dateFilter: $dateFilter
      active: $active
      city_id: $city_id
      country_id: $country_id
      city_name: $city_name
    ) {
      status
      error
      message
      version
      service
      pagination {
        page_number
        item_count
        total_count
      }
      cities {
        created_at
        created_by
        updated_at
        updated_by
        city_id
        country_id
        city_name
        city_description
        city_image_urls {
          image_type
          url
          is_mobile
        }
        city_short_code
        active
      }
    }
  }
`;
export const GET_COUNTRY = gql`
  query getCountry($country_id: String!) {
    getCountry(country_id: $country_id) {
      status
      error
      message
      version
      service
      country {
        created_at
        created_by
        updated_at
        updated_by
        country_id
        country_name
        country_description
        country_image_urls {
          image_type
          url
          is_mobile
        }
        country_mobile_number_prefix
        country_short_code
        active
      }
    }
  }
`;
export const GET_EDUCATION_LIST = gql`
  query getEducationList(
    $pagination: PaginationFilter
    $dateFilter: DateFilter
    $active: Boolean
    $education_name: String
  ) {
    getEducationList(
      pagination: $pagination
      dateFilter: $dateFilter
      active: $active
      education_name: $education_name
    ) {
      status
      error
      message
      version
      service
      pagination {
        page_number
        item_count
        total_count
      }
      educations {
        created_at
        created_by
        updated_at
        updated_by
        education_id
        education_name
        education_description
        education_image_urls {
          image_type
          url
          is_mobile
        }
        active
      }
    }
  }
`;

export const GET_CONFIG_LIST_GQL = gql`
  query getGameConfigList(
    $published: Boolean
    $is_featured: Boolean
    $is_pilot: Boolean
    $is_live: Boolean
    $platform_content_mode_tag: PlatformContentModeTag
    $pagination: PaginationFilter
    $dateFilter: DateFilter
    $active: Boolean
    $game_type: GameType
    $game_config_id: String
    $screener_id: String
    $intervention_id: String
    $game_name_key: GameNameKey
    $game_view_id: Float
  ) {
    getGameConfigList(
      published: $published
      is_featured: $is_featured
      is_pilot: $is_pilot
      is_live: $is_live
      platform_content_mode_tag: $platform_content_mode_tag
      pagination: $pagination
      dateFilter: $dateFilter
      active: $active
      game_type: $game_type
      game_config_id: $game_config_id
      screener_id: $screener_id
      intervention_id: $intervention_id
      game_name_key: $game_name_key
      game_view_id: $game_view_id
    ) {
      status
      error
      message
      version
      service
      pagination {
        page_number
        item_count
        total_count
      }
      game_configs {
        created_at
        created_by
        updated_at
        updated_by
        row_crc
        game_config_id
        game_type
        intervention_id
        screener_id
        game_name_key
        game_view_id
        game_config
      }
    }
  }
`;

export const GET_LEVEL_LIST_GQL = gql`
  query getGameLevelList(
    $published: Boolean
    $is_featured: Boolean
    $is_pilot: Boolean
    $is_live: Boolean
    $pagination: PaginationFilter
    $dateFilter: DateFilter
    $active: Boolean
    $intervention_id: String
    $screener_id: String
    $level_id: Float
    $stage_id: Float
    $game_config_id: String
    $game_level_code: String
    $game_type: GameType
    $mile_stone_name: String
    $is_practice_trial: Boolean
  ) {
    getGameLevelList(
      published: $published
      is_featured: $is_featured
      is_pilot: $is_pilot
      is_live: $is_live
      pagination: $pagination
      dateFilter: $dateFilter
      active: $active
      intervention_id: $intervention_id
      screener_id: $screener_id
      level_id: $level_id
      stage_id: $stage_id
      game_config_id: $game_config_id
      game_level_code: $game_level_code
      game_type: $game_type
      mile_stone_name: $mile_stone_name
      is_practice_trial: $is_practice_trial
    ) {
      status
      error
      message
      version
      service
      pagination {
        page_number
        item_count
        total_count
      }
      game_levels {
        created_at
        created_by
        updated_at
        updated_by
        row_crc
        game_level_id
        game_type
        game_config_id
        intervention_id
        screener_id
        level_id
        stage_id
        game_level_code
        level_data
        mile_stone_name
        mile_stone_score
        mile_stone_descriptions {
          client_type
          description
        }
        mile_stone_image_urls {
          image_url_id
          image_type
          url
          is_mobile
          video_stream {
            provider_u_id
            provider_thumbnail_url
            provider_preview_url
            provider_ready_to_stream
            provider_file_size
            provider_stream_duration
            provider_playback_hls_url
            provider_playback_dash_url
          }
        }
        mile_stone_meta {
          type
          key
          value
          image_urls {
            image_url_id
            image_type
            url
            is_mobile
            video_stream {
              provider_u_id
              provider_thumbnail_url
              provider_preview_url
              provider_ready_to_stream
              provider_file_size
              provider_stream_duration
              provider_playback_hls_url
              provider_playback_dash_url
            }
          }
        }
        is_practice_trial
        active
      }
    }
  }
`;
export const GET_INDUSTRY_LIST_GQL = gql`
  query getIndustryList(
    $pagination: PaginationFilter
    $dateFilter: DateFilter
    $active: Boolean
    $industry_name: String
  ) {
    getIndustryList(
      pagination: $pagination
      dateFilter: $dateFilter
      active: $active
      industry_name: $industry_name
    ) {
      status
      error
      message
      version
      service
      pagination {
        page_number
        item_count
        total_count
      }
      industries {
        created_at
        created_by
        updated_at
        updated_by
        industry_id
        industry_name
        industry_description
        industry_image_urls {
          image_type
          url
          is_mobile
        }
        active
      }
    }
  }
`;
export const GET_FUNCTION_LIST_GQL = gql`
  query getFunctionList(
    $pagination: PaginationFilter
    $dateFilter: DateFilter
    $active: Boolean
    $function_name: String
    $industry_id: String
  ) {
    getFunctionList(
      pagination: $pagination
      dateFilter: $dateFilter
      active: $active
      function_name: $function_name
      industry_id: $industry_id
    ) {
      status
      error
      message
      version
      service
      pagination {
        page_number
        item_count
        total_count
      }
      functions {
        created_at
        created_by
        updated_at
        updated_by
        row_crc
        function_id
        industry_id
        function_name
        function_description
        function_image_urls {
          image_type
          url
          is_mobile
        }
        active
      }
    }
  }
`;
export const GET_INDUSTRY = gql`
  query getIndustry($industry_id: String!) {
    getIndustry(industry_id: $industry_id) {
      status
      error
      message
      version
      service
      industry {
        created_at
        created_by
        updated_at
        updated_by
        industry_id
        industry_name
        industry_description
        industry_image_urls {
          image_type
          url
          is_mobile
        }
        active
      }
    }
  }
`;

export const GET_PLATFORM_CONTENT_LIST_GQL = gql`
  query getPlatformContentModeList(
    $is_featured: Boolean
    $is_pilot: Boolean
    $is_live: Boolean
    $pagination: PaginationFilter
    $dateFilter: DateFilter
    $active: Boolean
    $platform_content_mode_id: String
    $category_id: String
    $platform_content_mode_name: String
    $sort_order: Float
    $content_id: String
    $content_type: ContentType
    $platform_content_mode_tag: PlatformContentModeTag
  ) {
    getPlatformContentModeList(
      is_featured: $is_featured
      is_pilot: $is_pilot
      is_live: $is_live
      pagination: $pagination
      dateFilter: $dateFilter
      active: $active
      platform_content_mode_id: $platform_content_mode_id
      category_id: $category_id
      platform_content_mode_name: $platform_content_mode_name
      sort_order: $sort_order
      content_id: $content_id
      content_type: $content_type
      platform_content_mode_tag: $platform_content_mode_tag
    ) {
      status
      error
      message
      version
      service
      pagination {
        page_number
        item_count
        total_count
      }
      platform_content_modes {
        created_at
        created_by
        updated_at
        updated_by
        row_crc
        platform_content_mode_id
        platform_content_mode_name
        platform_content_mode_tag
        category_id
        sort_order
        content_id
        content_type
        is_featured
        is_pilot
        is_live
      }
    }
  }
`;

export const GET_BLOG_GQL = gql`
  query getBlog($blog_id: String!) {
    getBlog(blog_id: $blog_id) {
      status
      error
      message
      version
      service
      blog {
        created_at
        created_by
        updated_at
        updated_by
        row_crc
        sub_domain_ids
        content_version
        update_counter
        published
        active
        blog_id
        blog_code
        intervention_ids
        category_ids
        author_ids
        read_duration
        blog_name
        alias_name
        blog_type
        sort_order
        blog_descriptions {
          client_type
          description
        }
        blog_contents
        blog_image_urls {
          image_url_id
          image_type
          url
          is_mobile
          video_stream {
            provider_u_id
            provider_thumbnail_url
            provider_preview_url
            provider_ready_to_stream
            provider_file_size
            provider_stream_duration
            provider_playback_hls_url
            provider_playback_dash_url
          }
        }
        blog_video_urls {
          video_url_id
          video_type
          video_url
          is_mobile
          video_stream {
            provider_u_id
            provider_thumbnail_url
            provider_preview_url
            provider_ready_to_stream
            provider_file_size
            provider_stream_duration
            provider_playback_hls_url
            provider_playback_dash_url
          }
        }
        blog_meta {
          type
          key
          value
          image_urls {
            image_url_id
            image_type
            url
            is_mobile
            video_stream {
              provider_u_id
              provider_thumbnail_url
              provider_preview_url
              provider_ready_to_stream
              provider_file_size
              provider_stream_duration
              provider_playback_hls_url
              provider_playback_dash_url
            }
          }
        }
      }
    }
  }
`;
