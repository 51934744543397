import { useEffect, useRef } from "react";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import SelectInputField from "../../../components/HistoryEditInput/SelectInputField";
import TextInputField from "../../../components/HistoryEditInput/TextInputField";
import graphHandler from "../../../Apollo/graphHandler";
import { UPDATE_SCREENER_THRESHOLD_GQL } from "../../../Apollo/AdminServices/MutationRequest";
import { objectHasKey } from "../../../utils/Functions/filter";
import CustomButton from "../../../components/Button/CustomButton";
import useDelay from "../../../utils/CustomHooks/useDelay";

export default function UpdateThresholdSection({
  screener = {},
  disabled,
  subDomains = [],
  topicList = [],
  domainList = [],
  refresh = () => {},
  reset = () => {},
  setLoading = () => {},
  values = {},
}) {
  const { screener_id } = screener;
  const { screener_threshold_id, pending_history } = values;
  const hasUpdated = Boolean(pending_history && pending_history.updated_data);
  const updated_data = hasUpdated ? pending_history.updated_data : {};
  const { done } = useDelay();
  const referenceRef = useRef();
  const formik = useFormik({
    initialValues: {},
    // validationSchema: validation,
    onSubmit: (values) => {
      onSubmit(values);
    },
    validateOnChange: true,
  });

  useEffect(() => {
    if (formik.values.screener_threshold_entity_type && done) {
      let cond = formik.values.screener_threshold_entity_type === "Screener";
      formik.setFieldValue("entity_ref_id", cond ? screener_id : "");
      referenceRef.current &&
        referenceRef.current.setFieldValue(
          "entity_ref_id",
          cond ? screener_id : ""
        );
    }
  }, [formik.values.screener_threshold_entity_type,referenceRef]);
  const onSubmit = async (modal) => {
    formik.setSubmitting(true);
    setLoading(true);
    let { screener_threshold_entity_type, entity_ref_id, min_threshold } =
      modal;
    let update_request = { screener_threshold_id };

    if (objectHasKey(modal, "screener_threshold_entity_type")) {
      update_request.screener_threshold_entity_type =
        screener_threshold_entity_type;
    }
    if (objectHasKey(modal, "entity_ref_id")) {
      update_request.entity_ref_id = entity_ref_id;
    }
    if (objectHasKey(modal, "min_threshold")) {
      update_request.min_threshold = Number(min_threshold);
    }
    try {
      const { data } = await graphHandler().invoke(
        UPDATE_SCREENER_THRESHOLD_GQL,
        { update_request },
        1,
        false,
        true
      );
      if (
        data &&
        data.updateScreenerThreshold &&
        data.updateScreenerThreshold.screener_threshold
      ) {
        // console.log(
        //   "createScreenerTopic Success",
        //   data.updateScreenerThreshold.screener_threshold
        // );
        refresh();
        setLoading(false);
        toast.success("Updated Successfully");
        formik.setSubmitting(false);
        resetModal();
      }
    } catch (error) {
      console.error("updateScreenerThreshold Error", error);
      setLoading(false);
      formik.setSubmitting(false);
    }
  };
  const resetModal = () => {
    formik.resetForm();
    formik.setValues({});
    formik.setSubmitting(false);
    reset();
  };

  const entityType = formik.values.screener_threshold_entity_type
    ? formik.values.screener_threshold_entity_type
    : values.screener_threshold_entity_type;

  const handleError = () => {
    if (Object.keys(formik.errors).length > 0) {
      toast.error(formik.errors[Object.keys(formik.errors)[0]]);
    }
  };


  return (
    <>
      <div className="col-md-12 modal-header text-center align-items-center">
        <h5 className="modal-title text-center">Update Threshold</h5>
      </div>
      <div className="col-md-12">
        <div className="border">
          <div className="row p-3">
            <SelectInputField
              parentForm={formik}
              className="col-md-3"
              updateValue={updated_data.screener_threshold_entity_type}
              actualValue={values.screener_threshold_entity_type}
              id="screener_threshold_entity_type"
              title="Format"
              disabled={disabled}
              validation={{
                screener_threshold_entity_type:
                  Yup.string().required("Select a type"),
              }}
              options={[
                { label: "Select", value: "", props: { hidden: true } },
                {
                  label: "Domain",
                  value: "Domain",
                },
                {
                  label: "Sub Domain",
                  value: "SubDomain",
                },
                {
                  label: "Topic",
                  value: "Topic",
                },
                {
                  label: "Screener",
                  value: "Screener",
                },
              ]}
            />
            {entityType !== "Screener" && (
              <SelectInputField
                parentForm={formik}
                className="col-md-3"
                updateValue={updated_data.entity_ref_id}
                actualValue={values.entity_ref_id}
                id="entity_ref_id"
                title={
                  entityType === "SubDomain"
                    ? "Sub Domain"
                    : entityType || "Reference"
                }
                disabled={disabled}
                validation={{
                  entity_ref_id: Yup.string().required(`Select a ${entityType}`),
                }}
                oldOptions={[
                  { label: "Select", value: "",props: { hidden: true } },
                  ...(values.screener_threshold_entity_type === "SubDomain"
                    ? subDomains.map((item) => ({
                        label: item.sub_domain_name,
                        value: item.sub_domain_id,
                      }))
                    : values.screener_threshold_entity_type === "Domain"
                    ? domainList.map((item) => ({
                        label: item.domain_name,
                        value: item.domain_id,
                      }))
                    : topicList.map((item) => ({
                        label: item.screener_topic_title,
                        value: item.screener_topic_id,
                      }))),
                ]}
                options={[
                  { label: "Select",value: "", props: { hidden: true } },
                  ...(entityType === "SubDomain"
                    ? subDomains.map((item) => ({
                        label: item.sub_domain_name,
                        value: item.sub_domain_id,
                      }))
                    : entityType === "Domain"
                    ? domainList.map((item) => ({
                        label: item.domain_name,
                        value: item.domain_id,
                      }))
                    : topicList.map((item) => ({
                        label: item.screener_topic_title,
                        value: item.screener_topic_id,
                      }))),
                ]}
                setChildRef={(e) => (referenceRef.current = e)}
              />
            )}
            <TextInputField
              parentForm={formik}
              className="col-md-3"
              updateValue={updated_data.min_threshold}
              actualValue={values.min_threshold}
              id="min_threshold"
              title="Min Threshold"
              disabled={disabled}
              validation={{
                min_threshold: Yup.string().required("Enter threshold"),
              }}
              isNumber={true}
              inputProps={{
                maxLength: 10,
              }}
            />
            <div
              className={"d-flex align-items-end justify-content-end col-md-12"}
            >
              {values.screener_threshold_id && (
                <button
                  onClick={resetModal}
                  type="button"
                  className="btn btn-light btn-sm float-right btn-add-domain mr-2"
                >
                  Reset
                </button>
              )}
              <CustomButton
                type="button"
                className="btn btn-primary btn-outline-success btn-sm float-right btn-add-domain"
                disabled={!formik.isValid || formik.isSubmitting}
                onClick={
                  formik.isValid && Object.keys(formik.values).length !== 0
                    ? formik.handleSubmit
                    : handleError
                }
              >
                {values.screener_threshold_id ? "Update" : "Create"}
              </CustomButton>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
