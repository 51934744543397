import MarkdownIt from "markdown-it";
const mdParser = new MarkdownIt(/* Markdown-it options */);

export default function HistoryMetaTableItem({
  item,
  titleKey = "meta_data_title",
  valueKey = "meta_data_value",
  arrayIndex = "",
  remove = () => {},
  onEdit = () => {},
  disabled,
  isPublished = false,
}) {
  return (
    <tr
      className={`${
        item.isRemoved
          ? "bad"
          : item.isAdded
          ? "good"
          : item.isUpdated
          ? "warn"
          : ""
      }`}
    >
      <td className="text-none">{item[titleKey]}</td>
      <td className="text-none">
        {item[valueKey] && (
          <div
            className=" custom-html-style"
            dangerouslySetInnerHTML={{
              __html: mdParser.render(item[valueKey]),
            }}
          ></div>
        )}
      </td>
      {!disabled && (
        <td className="d-flex flex-row">
          {!isPublished &&
          !item.isUpdated &&
          !item.isAdded &&
          !item.isRemoved &&
          !disabled ? (
            <>
              <i
                className="fas fa-edit cursor-pointer"
                onClick={() => {
                  !disabled && onEdit({ ...item });
                }}
              ></i>
              <i
                className="ml-2 fas fa-trash cursor-pointer"
                onClick={() =>
                  !disabled && remove(item.index, arrayIndex, item)
                }
              ></i>
            </>
          ) : isPublished &&
            !item.isUpdated &&
            !item.isAdded &&
            !item.isRemoved &&
            item.update_type !== "Update"&&item.update_type!== "Add" ? (
            <>
              <i
                className="fas fa-edit cursor-pointer"
                onClick={() => {
                  !disabled && onEdit({ ...item });
                }}
              ></i>
              <i
                className="ml-2 fas fa-trash cursor-pointer"
                onClick={() =>
                  !disabled && remove(item.index, arrayIndex, item)
                }
              ></i>
            </>
          ) : (
            "-"
          )}
        </td>
      )}
    </tr>
  );
}
