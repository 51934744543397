import { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import graphHandler from "../../../Apollo/graphHandler";
import {
  CREATE_INTERVENTION_GQL,
  UPDATE_INTERVENTION_GQL,
} from "../../../Apollo/AdminServices/MutationRequest";
import MultiSelect from "../../../components/MultiSelect/MultiSelect";
import Description from "../../../components/Input/Description";
import { ActivityType, InterventionFormat } from "../../../constants";
import CustomButton from "../../../components/Button/CustomButton";
import MetaInput from "../../../components/MetaInput";
import MediaFileUpload from "../../../components/MediaFileUpload";
import SingleImageUpload from "../../../components/MediaFileUpload/SingleImageUpload";
import SingleVideoUpload from "../../../components/MediaFileUpload/SingleVideoUpload";
import { removeEmptyObjValue } from "../../../utils/Functions/filter";

export default function AddInterventionModal({
  refresh = () => {},
  categoryList = [],
  setIntervention = () => {},
  setLoading = () => {},
  onClose = () => {},
}) {
  const [categories, setCategories] = useState([]);
  const subDomains = useSelector(({ DataSet }) => DataSet.subDomains);
  const [subDomainList, setSubDomainList] = useState([]);
  const [interversionId, setInterversionId] = useState("");

  useEffect(() => {
    setSubDomainList(
      subDomains.map((x) => ({
        label: x.sub_domain_name,
        value: x.sub_domain_id,
      }))
    );
  }, [subDomains]);

  const formik = useFormik({
    initialValues: {
      ...defaultValue,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      onSubmit(values);
    },
  });
  const formik1 = useFormik({
    initialValues: {
      ...defaultValue,
    },
    // validationSchema: validationSchemaEdit,
    onSubmit: (values) => {
      onEditSubmit(values);
    },
  });

  useEffect(() => {
    setCategories(
      categoryList.map((x) => ({
        label: x.category_name,
        value: x.category_id,
      }))
    );
  }, [categoryList]);

  const onClosed = () => {
    onClose();
    formik.resetForm();
    formik.setValues({});
    formik1.resetForm();
    formik1.setValues({});
    setInterversionId("");
  };

  const handleSubmitRequest = (value) => {
    const {
      sub_domain_ids,
      intervention_code,
      intervention_name,
      category_ids,
      intervention_description,
      activity_type,
      intervention_formats,
      intervention_duration,
      days_per_week,
      intervention_meta_datas,
      mobile_description,
      alias_name,
    } = value;
    let create_request = {
      sub_domain_ids: [...sub_domain_ids.map((x) => x.value)],
      intervention_name,
      activity_type,
      alias_name,
      intervention_formats: intervention_formats.map((x) => x.value),
      intervention_duration: Number(intervention_duration) * 60,
    };
    if (intervention_code) create_request.intervention_code = intervention_code;
    if (days_per_week) {
      create_request.days_per_week = Number(days_per_week);
    }
    let description = [];
    if (intervention_description) {
      description.push({
        client_type: "Web",
        description: intervention_description,
      });
    }
    if (mobile_description) {
      description.push({
        client_type: "Mobile",
        description: mobile_description,
      });
    }
    if (description.length > 0)
      create_request.intervention_description = description;

    if (
      intervention_meta_datas.filter(
        (x) => x.meta_data_type && x.meta_data_title && x.meta_data_value
      ).length > 0
    ) {
      create_request.intervention_meta_datas = [
        ...intervention_meta_datas.filter(
          (x) => x.meta_data_type && x.meta_data_title && x.meta_data_value
        ),
      ];
    }
    if (category_ids) {
      create_request.category_ids = [...category_ids.map((x) => x.value)];
    }
    return create_request;
  };
  const onSubmit = async (value) => {
    const create_request = handleSubmitRequest(value);
    formik.setSubmitting(true);
    setLoading(true);
    try {
      const { data } = await graphHandler().invoke(
        CREATE_INTERVENTION_GQL,
        { create_request },
        1,
        true
      );
      if (data) {
        setInterversionId(
          data?.createIntervention?.intervention?.intervention_id
        );
        setIntervention({
          ...data.createIntervention.intervention,
          pending_history: data.createIntervention.intervention_history,
        });
      }
      // formik1.setValues({ ...formik.values });
      refresh();
      setLoading(false);
      formik.setSubmitting(false);
    } catch (error) {
      console.log("Intervention error", error);
      formik.setSubmitting(false);
      setLoading(false);
    }
  };
  const onEditSubmit = async (value) => {
    // console.log("submit", value);
    const {
      intervention_image_urls,
      intervention_video_urls,
      intervention_document_urls,
    } = value;
    const update_request = {
      intervention_id: interversionId,
    };
    if (intervention_image_urls && intervention_image_urls.length > 0) {
      update_request.intervention_image_urls = intervention_image_urls.map(
        (x) => {
          delete x.index;
          return removeEmptyObjValue(x);
        }
      );
    }
    if (intervention_video_urls && intervention_video_urls.length > 0) {
      update_request.intervention_video_urls = intervention_video_urls.map(
        (x) => {
          delete x.index;
          return removeEmptyObjValue(x);
        }
      );
    }
    // if (intervention_image_urls && intervention_image_urls.length > 0) {
    //   update_request.intervention_image_urls = {
    //     update_items: intervention_image_urls.map((x) => {
    //       return {
    //         update_type: "Add",
    //         update_data: {
    //           image_type: x.media_format,
    //           url: x.mediaUrl.url,
    //           is_mobile: x.media_device === "false" ? false : true,
    //         },
    //       };
    //     }),
    //   };
    // }
    // if (intervention_video_urls && intervention_video_urls.length > 0) {
    //   update_request.intervention_video_urls = {
    //     update_items: intervention_video_urls.map((x) => {
    //       return {
    //         update_type: "Add",
    //         update_data: {
    //           video_type: x.media_format,
    //           video_url: x.mediaUrl.url,
    //           is_mobile: x.media_device === "false" ? false : true,
    //         },
    //       };
    //     }),
    //   };
    // }
    if (intervention_document_urls && intervention_document_urls.length > 0) {
      update_request.intervention_document_urls = {
        update_items: intervention_document_urls.map((x) => {
          return {
            update_type: "Add",
            update_data: {
              name: x.mediaUrl.name,
              document_type: x.media_format,
              url: x.mediaUrl.url,
              is_mobile: x.media_device === "false" ? false : true,
            },
          };
        }),
      };
    }
    // console.log("update_request", update_request);
    try {
      // formik1.setSubmitting(false);
      // return;
      const { data } = await graphHandler().invoke(
        UPDATE_INTERVENTION_GQL,
        { update_request },
        1,
        true
      );
      refresh();
      // onClose();
      // console.log("update data", data);
    } catch (error) {
      console.log("update Intervention error", error);
      formik1.setSubmitting(false);
    }
  };

  const handleError = () => {
    if (Object.keys(formik.errors).length > 0) {
      toast.error(formik.errors[Object.keys(formik.errors)[0]]);
    }
  };

  const handleEditError = () => {
    if (Object.keys(formik1.errors).length > 0) {
      toast.error(formik1.errors[Object.keys(formik1.errors)[0]]);
    }
  };

  const handleNumber = (e) => {
    const { name, value } = e.target;
    const re = /^[0-9\b]+$/;
    let temp = !value
      ? value
      : re.test(value)
      ? value
      : formik.values[name]
      ? formik.values[name]
      : "";
    formik.setFieldValue(name, temp ? temp.substring(0, 10) : temp);
  };
  return (
    <>
      <div className="container">
        <div className="intervention-form row">
          <div className="col-md-6 form-group">
            <label
              htmlFor="sub_domain_ids"
              className="col-form-label w-100 mb-2"
            >
              Sub Domain
            </label>
            <MultiSelect
              option={subDomainList}
              onChange={(e) => formik.setFieldValue("sub_domain_ids", e)}
              placeholder="Select Sub Domain"
              isCreatable={false}
              isMulti={true}
              type="Sub Domain"
              name="sub_domain_ids"
              id="sub_domain_ids"
              disabled={interversionId !== ""}
              onBlur={formik.handleBlur}
              defaultValue={formik.values.sub_domain_ids}
            />
            <small className="text-danger">
              {formik.touched.sub_domain_ids && formik.errors.sub_domain_ids
                ? formik.errors.sub_domain_ids
                : ""}
            </small>
          </div>
          <div className="col-md-6 form-group">
            <label htmlFor="category_ids" className="col-form-label w-100 mb-2">
              Category
            </label>
            <MultiSelect
              option={categories}
              onChange={(e) => formik.setFieldValue("category_ids", e)}
              placeholder="Select a Category"
              isCreatable={false}
              isMulti={true}
              type="Category"
              name="category_ids"
              id="category_ids"
              disabled={interversionId !== ""}
              onBlur={formik.handleBlur}
              defaultValue={formik.values.category_ids}
            />
            <small className="text-danger align-self-end">
              {formik.touched.category_ids && formik.errors.category_ids
                ? formik.errors.category_ids
                : ""}
            </small>
          </div>
          <div className="col-md-6 form-group">
            <label
              htmlFor="intervention_code"
              className="col-form-label w-100 mb-2"
            >
              Code
            </label>
            <input
              type="text"
              className="form-control"
              id="intervention_code"
              name="intervention_code"
              onChange={formik.handleChange}
              // maxLength={30}
              disabled={interversionId !== ""}
              value={
                formik.values.intervention_code
                  ? formik.values.intervention_code
                  : ""
              }
              onBlur={formik.handleBlur}
            />
            <small className="text-danger align-self-end">
              {formik.touched.intervention_code &&
              formik.errors &&
              formik.errors.intervention_code
                ? formik.errors.intervention_code
                : ""}
            </small>
          </div>
          <div className="col-md-6 form-group">
            <label
              htmlFor="intervention_name"
              className="col-form-label w-100 mb-2"
            >
              Name
            </label>
            <input
              type="text"
              className="form-control"
              id="intervention_name"
              name="intervention_name"
              disabled={interversionId !== ""}
              value={
                formik.values.intervention_name
                  ? formik.values.intervention_name
                  : ""
              }
              onChange={(e) =>
                formik.setFieldValue(
                  "intervention_name",
                  e.target.value.trimStart()
                )
              }
              onBlur={(e) => {
                formik.setFieldTouched("intervention_name", true);
                formik.setFieldValue(
                  "intervention_name",
                  e.target.value.replace(/\s+/g, " ").trim()
                );
              }}
            />
            <small className="text-danger align-self-end">
              {formik.touched.intervention_name &&
              formik.errors &&
              formik.errors.intervention_name
                ? formik.errors.intervention_name
                : ""}
            </small>
          </div>
          <div className="col-md-6">
            <div className="row">
              <div className="col-md-12 form-group">
                <label
                  htmlFor="alias_name"
                  className="col-form-label w-100 mb-2"
                >
                  Alias Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="alias_name"
                  name="alias_name"
                  value={
                    formik.values.alias_name ? formik.values.alias_name : ""
                  }
                  disabled={interversionId !== ""}
                  onChange={(e) =>
                    formik.setFieldValue(
                      "alias_name",
                      e.target.value.trimStart()
                    )
                  }
                  // onBlur={formik.handleBlur}
                  onBlur={(e) => {
                    formik.setFieldTouched("alias_name", true);
                    formik.setFieldValue(
                      "alias_name",
                      e.target.value.replace(/\s+/g, " ").trim()
                    );
                  }}
                />
                <small className="text-danger">
                  {formik.touched.alias_name &&
                  formik.errors &&
                  formik.errors.alias_name
                    ? formik.errors.alias_name
                    : ""}
                </small>
              </div>
              <div className="col-md-12 form-group">
                <label
                  htmlFor="activity_type"
                  className="col-form-label w-100 mb-2"
                >
                  Activity Type
                </label>
                <select
                  className="custom-select"
                  id="activity_type"
                  name="activity_type"
                  onChange={formik.handleChange}
                  value={
                    formik.values.activity_type
                      ? formik.values.activity_type
                      : ""
                  }
                  disabled={interversionId !== ""}
                  onBlur={formik.handleBlur}
                >
                  <option key="Select" hidden>
                    Select
                  </option>
                  {ActivityType.map((x, i) => (
                    <option value={x} key={i}>
                      {x}
                    </option>
                  ))}
                </select>
                <small className="text-danger align-self-end">
                  {formik.touched.activity_type &&
                  formik.errors &&
                  formik.errors.activity_type
                    ? formik.errors.activity_type
                    : ""}
                </small>
              </div>

              <div className="col-md-12 form-group">
                <label
                  htmlFor="intervention_formats"
                  className="col-form-label w-100 mb-2"
                >
                  Format
                </label>
                <MultiSelect
                  option={InterventionFormat}
                  onChange={(e) =>
                    formik.setFieldValue("intervention_formats", e)
                  }
                  placeholder="Select format"
                  isCreatable={false}
                  isMulti={true}
                  disabled={interversionId !== ""}
                  type="Format"
                  name="intervention_formats"
                  id="intervention_formats"
                  onBlur={formik.handleBlur}
                  defaultValue={formik.values.intervention_formats}
                />
                <small className="text-danger align-self-end">
                  {formik.touched.intervention_formats &&
                  formik.errors.intervention_formats
                    ? formik.errors.intervention_formats
                    : ""}
                </small>
              </div>
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-6 form-group">
                    <label
                      htmlFor="intervention_duration"
                      className="col-form-label w-100 mb-2"
                    >
                      Duration Per Day (in minutes)
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="intervention_duration"
                      name="intervention_duration"
                      maxLength={10}
                      onChange={handleNumber}
                      disabled={interversionId !== ""}
                      value={
                        formik.values.intervention_duration
                          ? formik.values.intervention_duration
                          : ""
                      }
                      onBlur={formik.handleBlur}
                    />
                    <small className="text-danger align-self-end">
                      {formik.touched.intervention_duration &&
                      formik.errors &&
                      formik.errors.intervention_duration
                        ? formik.errors.intervention_duration
                        : ""}
                    </small>
                  </div>

                  <div className="col-md-6 form-group">
                    <label
                      htmlFor="days_per_week"
                      className="col-form-label w-100 mb-2"
                    >
                      Days Per Week (in days)
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="days_per_week"
                      name="days_per_week"
                      maxLength={10}
                      disabled={interversionId !== ""}
                      onChange={handleNumber}
                      value={
                        formik.values.days_per_week
                          ? formik.values.days_per_week
                          : ""
                      }
                      onBlur={formik.handleBlur}
                    />
                    <small className="text-danger align-self-end">
                      {formik.touched.days_per_week &&
                      formik.errors &&
                      formik.errors.days_per_week
                        ? formik.errors.days_per_week
                        : ""}
                    </small>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-6 p-0">
            <Description
              formik={formik}
              disabled={interversionId !== ""}
              webName="intervention_description"
              showEditor={true}
            />
          </div>
          <div className="col-md-12">
            <MetaInput
              formik={formik}
              disabled={interversionId !== ""}
              parentArray="intervention_meta_datas"
            />
          </div>

          <div className="d-flex  justify-content-end col-md-12 mt-3">
            <button
              onClick={onClosed}
              type="button"
              className="btn btn-danger btn-sm float-right btn-add-domain mr-2"
            >
              Close
            </button>
            <CustomButton
              type="button"
              className="btn btn-primary btn-outline-success float-right btn-add-domain"
              disabled={
                Object.keys(formik.errors).length !== 0 ||
                !formik.isValid ||
                formik.isSubmitting
              }
              onClick={
                formik.isValid && Object.keys(formik.values).length !== 0
                  ? formik.handleSubmit
                  : handleError
              }
            >
              Create
            </CustomButton>
          </div>
          {interversionId !== "" ? (
            <>
              <div className="col-md-12">
                <SingleImageUpload
                  formik={formik1}
                  heading="Image File"
                  contentId={interversionId}
                  contentType="INTERVENTION"
                  mediaType="Image"
                  parentArray="intervention_image_urls"
                />
              </div>
              <div className="col-md-12">
                <SingleVideoUpload
                  formik={formik1}
                  heading="Video File"
                  contentId={interversionId}
                  contentType="INTERVENTION"
                  mediaType="Video"
                  parentArray="intervention_video_urls"
                />
              </div>
              <div className="col-md-12">
                <MediaFileUpload
                  formik={formik1}
                  heading="Document File"
                  contentId={interversionId}
                  contentType="INTERVENTION"
                  mediaType="Document"
                  parentArray="intervention_document_urls"
                />
              </div>

              <div className="d-flex align-items-end justify-content-end col-md-12">
                <CustomButton
                  type="button"
                  className="btn btn-primary btn-outline-success float-right btn-add-domain"
                  disabled={
                    Object.keys(formik1.errors).length !== 0 ||
                    !formik1.isValid ||
                    formik1.isSubmitting
                  }
                  onClick={() => {
                    formik1.isValid && Object.keys(formik1.values).length !== 0
                      ? formik1.handleSubmit()
                      : handleEditError();
                  }}
                >
                  Update
                </CustomButton>
              </div>
            </>
          ) : null}
        </div>
      </div>
    </>
  );
}

const validationSchema = Yup.object().shape({
  sub_domain_ids: Yup.array()
    .of(
      Yup.object().shape({
        label: Yup.string(),
        value: Yup.string(),
      })
    )
    .test({
      message: "Select a sub domain",
      test: (arr) => {
        return arr && arr.length > 0;
      },
    }),
  intervention_name: Yup.string().trim().required("Enter name"),
  alias_name: Yup.string().trim(),
  intervention_code: Yup.string().trim().required("Enter code"),
  category_ids: Yup.array().of(
    Yup.object().shape({
      label: Yup.string(),
      value: Yup.string(),
    })
  ),
  // .test({
  //   message: "Select a category",
  //   test: (arr) => {
  //     return arr && arr.length > 0;
  //   },
  // }),
  intervention_description: Yup.string().trim().required("Enter description"),
  mobile_description: Yup.string().trim().required("Enter mobile description"),
  activity_type: Yup.string().required("Select a type"),
  intervention_formats: Yup.array()
    .of(Yup.object())
    .test({
      message: "Select a format",
      test: (arr) => {
        return arr && arr.length > 0;
      },
    }),
  intervention_duration: Yup.string(),
  days_per_week: Yup.string(),
  intervention_meta_datas: Yup.array().of(
    Yup.object().shape({
      meta_data_type: Yup.string().trim().required("Select a data type"),
      meta_data_title: Yup.string().trim().required("Enter a title"),
      meta_data_value: Yup.string().trim().required("Enter a value"),
    })
  ),
  media_datas: Yup.array().of(
    Yup.object().shape({
      media_type: Yup.string().required("Select a media type"),
      media_device: Yup.string().required("Select a media device"),
      media_format: Yup.string().required("Select a media format"),
      mediaUrl: Yup.object(),
    })
  ),
});

var defaultValue = {
  sub_domain_ids: [],
  intervention_meta_datas: [],
  media_datas: [],
};
