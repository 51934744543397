import { useEffect } from "react";
import { toast } from "react-toastify";
import useQuestionaireList from "../../../utils/CustomHooks/useQuestionaireList";
// import useScreenerAPI from "../../../utils/CustomHooks/useScreenerAPI";
import { Popconfirm } from "antd";
import graphHandler from "../../../Apollo/graphHandler";
import { UPDATE_TOPIC_GQL } from "../../../Apollo/AdminServices/MutationRequest";

export default function TopicSectionItem({
  canDelete = false,
  refresh,
  item = {},
  isEditable,
  onEdit,
  domainList = [],
  setHistory = () => {},
}) {
  const { pending_history } = item;
  // const hasPublished =
  //   pending_history &&
  //   pending_history.publish_data &&
  //   pending_history.publish_data.created_by;
  const { questionaireList, loading, getQuestionaireList } =
    useQuestionaireList("");
  // const { loading: loading1, removeTopic } = useScreenerAPI();
  useEffect(() => {
    if (item.screener_topic_id) {
      getQuestionaireList({ screener_topic_id: item.screener_topic_id,get_pending_update:true,active:true});
    }
  }, [item.screener_topic_id]);

  useEffect(() => {
    if (isEditable && pending_history?.updated_data?.active === false) {
      setHistory(Boolean(pending_history && pending_history.updated_data));
    }
  }, [pending_history]);

  const onDelete = async() => {
    if (loading) return;
    let data = await questionaireList.filter((x)=> x.pending_history?.updated_data?.active !== false)
    // console.log("tets",data)
    // if (!canDelete) return toast.warn("Sorry screener was published");
    if (data&&data.length > 0){
      return toast.error("Please delete all the questions inside this topic");
    }
    // removeTopic({ screener_topic_id: item.screener_topic_id }, refresh);
    try {
      await graphHandler().invoke(
        UPDATE_TOPIC_GQL,
        {
          update_request: {
            active: false,
            screener_topic_id: item.screener_topic_id,
          },
        },
        1,
        false
      );
      refresh();
    } catch (error) {
      console.log("Plan error", error);
    }
  };

  const getDomain = (data) => {
    if (!data.domain_id) return "-";
    let val = domainList.find((x) => x.domain_id === data.domain_id);
    return val ? val.domain_name : "-";
  };
  return (
    <tr
      className={`${
        pending_history?.updated_data?.active === false ? "bad" : ""
      } option-table`}
    >
      <td className="text-center">{item.screener_topic_title || "-"}</td>
      <td className="text-center">
        {item.screener_topic_aggregate_type
          ? item.screener_topic_aggregate_type.join(", ")
          : "-"}
      </td>
      <td className="text-center">
        {" "}
        {item.screener_result_calculation_type || "-"}
      </td>
      <td className="text-center"> {getDomain(item)}</td>
      <td className="d-flex align-items-center justify-content-center">
        {/* {hasPublished && (
          <span className="badge badge-error float-right">Changes</span>
        )} */}
        {isEditable && pending_history?.updated_data?.active !== false ? (
          <>
            <button
              type="button"
              className="ml-2 btn btn-outline-success btn-sm "
              onClick={() => onEdit(item)}
            >
              Edit
            </button>
            <Popconfirm
              title="Are you sure to delete this topic?"
              onConfirm={onDelete}
              //  onCancel={cancel}
              okText="Yes"
              cancelText="No"
            >
              <i
                className="ml-2 fas fa-trash cursor-pointer"
                // onClick={onDelete}
              ></i>
            </Popconfirm>
          </>
        ) : (
          "-"
        )}
      </td>
    </tr>
  );
}
