import { Steps } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Modal, ModalBody } from "reactstrap";
import CustomLoader from "../../../components/Loader";
import RelatedContentsGroup from "../../../components/RelatedContents/component/RelatedContentsGroup";
import useInterventionList from "../../../utils/CustomHooks/useInterventionList";
import useModuleActions from "../../../utils/CustomHooks/useModuleActions";
import useModuleList from "../../../utils/CustomHooks/useModuleList";
import useRelatedContentList from "../../../utils/CustomHooks/useRelatedContentList";
import useScreenerList from "../../../utils/CustomHooks/useScreenerList";
import CreateModule from "../components/CreateModule";
import TherapyAction from "../components/TherapyAction";
import TherapyGroup from "../components/TherapyGroup";
import CreateTherapy from "./CreateTherapy";
import UpdateTherapy from "./UpdateTherapy";
import useTherapyAPI from "../../../utils/CustomHooks/useTherapyAPI";
import useTherapyList from "../../../utils/CustomHooks/useTherapyList";

const { Step } = Steps;

export default function TherapyModal({
  initialValues = {},
  isOpen,
  setOpen,
  refresh,
  isEditable = false,
  isFirst = false,
}) {
  const [activeKey, setActive] = useState(0);
  const [therapy, setTherapy] = useState({});
  const [loading, setLoading] = useState(false);
  const [reqCount, setReqCount] = useState(0);
  const [relateCount, setRelateCount] = useState(0);
  const [reqActionCount, setReqActionCount] = useState(0);

  const subDomains = useSelector(({ DataSet }) => DataSet.subDomains);
  const [subDomainList, setSubDomainList] = useState([]);
  const { interventionList, getInterventionList } = useInterventionList();
  const { screenerList, getScreenerList } = useScreenerList("");
  const [interventions, setInterVentions] = useState([]);
  const [screeners, setScreeners] = useState([]);
  const [therapyModules, setTherapyModules] = useState([]);
  const { reviewTherapy } = useTherapyAPI("");
  const { therapyList, getTherapyList } = useTherapyList("");

  const {
    moduleList,
    loading: moduleLoading,
    getModuleList,
  } = useModuleList("");
  const {
    relatedContents,
    loading: relatedContentLoading,
    getRelatedContents,
  } = useRelatedContentList();
  const {
    actionList,
    loading: actionLoading,
    getActionList,
  } = useModuleActions();

  useEffect(() => {
    if (initialValues.therapy_id) setTherapy(initialValues);
  }, [initialValues]);

  useEffect(() => {
    if (therapy.therapy_id && isOpen) {
      getRelatedContents({
        content_id: therapy.therapy_id,
        content_type: "THERAPY",
      });
    }
  }, [therapy, relateCount, isOpen]);

  const handleDiscord = () => {
    const request = {
      review_request: {
        comment: "Discord Values",
        approve: false,
      },
      therapy_history_id:
        therapyList[0]?.pending_therapy_history?.therapy_history_id,
    };
    reviewTherapy(request, onClose);
  };
  const TherapySection = therapy.therapy_id ? UpdateTherapy : CreateTherapy;
  const renderSteps = (key) => {
    switch (key) {
      case 0:
        return (
          <TherapySection
            refresh={refresh}
            onDone={() => setActive(1)}
            onClose={onClose}
            values={therapy}
            isFirst={isFirst}
            isEditable={isEditable}
            setTherapy={setTherapy}
            setLoading={setLoading}
            subDomainList={subDomainList}
            handleDiscord={handleDiscord}
          />
        );
      case 1:
        return (
          <TherapyGroup
            refresh={() => setReqCount((x) => x + 1)}
            onClose={onClose}
            onDone={() => setActive(2)}
            onBack={() => setActive(0)}
            therapy={therapy}
            isEditable={isFirst || isEditable}
            setTherapy={setTherapy}
            setLoading={setLoading}
            subDomainList={subDomainList}
            moduleList={moduleList}
            handleDiscord={handleDiscord}
          />
        );
      case 2:
        return (
          <TherapyAction
            refresh={() => setReqActionCount((x) => x + 1)}
            onClose={onClose}
            onDone={() => setActive(3)}
            onBack={() => setActive(1)}
            therapy={therapy}
            isEditable={isFirst || isEditable}
            setTherapy={setTherapy}
            setLoading={setLoading}
            subDomainList={subDomainList}
            interventions={interventions}
            interventionList={interventionList}
            moduleList={therapyModules}
            actionList={actionList}
            screeners={screeners}
            screenerList={screenerList}
            handleDiscord={handleDiscord}
          />
        );
      case 3:
        return (
          <RelatedContentsGroup
            refresh={() => setRelateCount((x) => x + 1)}
            onClose={onClose}
            onBack={() => setActive(2)}
            contentId={therapy.therapy_id}
            isEditable={isFirst || isEditable}
            setLoading={setLoading}
            contentType="THERAPY"
            relatedContentList={relatedContents}
            showDesc={true}
          />
        );
      default:
        return <h5>No step found</h5>;
    }
  };
  useEffect(() => {
    getInterventionList({ published: true }, false);
    getScreenerList({ published: true }, false);
  }, []);

  useEffect(() => {
    if (therapy.therapy_id && isOpen) {
      getModuleList({
        therapy_id: therapy.therapy_id,
        get_pending_update: true,
      });
      getTherapyList({
        therapy_id: therapy.therapy_id,
      });
    }
  }, [therapy, reqCount, isOpen]);
  useEffect(() => {
    if (therapy.therapy_id && isOpen) {
      getActionList({
        therapy_id: therapy.therapy_id,
        get_pending_update: true,
      });
      getTherapyList({
        therapy_id: therapy.therapy_id,
      });
    }
  }, [therapy, reqActionCount, isOpen]);
  useEffect(() => {
    setSubDomainList(
      subDomains.map((x) => ({
        label: x.sub_domain_name,
        value: x.sub_domain_id,
      }))
    );
  }, [subDomains]);

  useEffect(() => {
    setInterVentions(
      interventionList.map((x) => ({
        label: x.intervention_name,
        value: x.intervention_id,
      }))
    );
  }, [interventionList]);

  useEffect(() => {
    setScreeners(
      screenerList.map((x) => ({
        label: x.screener_name,
        value: x.screener_id,
      }))
    );
  }, [screenerList]);
  useEffect(() => {
    setTherapyModules(
      moduleList
        .filter((x) => x.active === true)
        .map((x) => ({
          label: x.therapy_module_name,
          value: x.therapy_module_id,
        }))
    );
  }, [moduleList]);

  const onClose = () => {
    setOpen(false);
    setActive(0);
    refresh();
    setTherapy({});
  };
  const onChangeStep = (e) => {
    if (e === 1 && !therapy.therapy_id) return;
    if (e === 2 && moduleList.length === 0) return;
    if (e === 3 && !therapy.therapy_id) return;
    setActive(e);
  };

  return (
    <Modal
      toggle={onClose}
      isOpen={isOpen}
      size="lg"
      keyboard={false}
      modalClassName="modal-fullscreen modal-dialog-scrollable"
    >
      <div className="modal-header">
        <Steps
          type="navigation"
          current={activeKey}
          onChange={onChangeStep}
          className="site-navigation-steps"
        >
          <Step
            status={therapy.therapy_id ? "finish" : "process"}
            title={therapy.therapy_id ? "Therapy" : "Create Therapy"}
          />
          <Step
            status={
              moduleList.length > 0
                ? "finish"
                : therapy.therapy_id
                ? "process"
                : "wait"
            }
            disabled={!therapy.therapy_id}
            title="Modules"
          />
          <Step
            status={
              actionList.length > 0
                ? "finish"
                : moduleList.length
                ? "process"
                : "wait"
            }
            disabled={moduleList.length === 0}
            title="Actions"
          />
          <Step
            status={
              relatedContents.length > 0
                ? "finish"
                : therapy.therapy_id
                ? "process"
                : "wait"
            }
            disabled={!therapy.therapy_id}
            title="Related Content"
          />
        </Steps>
      </div>
      <ModalBody>
        {(loading ||
          moduleLoading ||
          actionLoading ||
          relatedContentLoading) && <CustomLoader />}
        {renderSteps(activeKey)}
      </ModalBody>
    </Modal>
  );
}
