import { useState } from "react";
import { GET_INDUSTRY_LIST_GQL } from "../../Apollo/AdminServices/QueryRequest";
import graphHandler from "../../Apollo/graphHandler";
function useIndustryList(){
    const [industryList, setIndustryList] = useState([]);
    const [loading, setLoading] = useState(false);
    
  const getIndustryList = async (req = {}) => {
    setLoading(true);
    try {
      let { data } = await graphHandler().invoke(
        GET_INDUSTRY_LIST_GQL,
        req,
        1,
        false,
        true
      );
      if (
        data &&
        data.getIndustryList &&
        data.getIndustryList.industries &&
        data.getIndustryList.industries.length > 0
      ) {
        
        setIndustryList([]);
        setIndustryList(data.getIndustryList.industries.slice());
        setLoading(false);
      } else {
        setIndustryList([]);
        setLoading(false);
      }
    } catch (error) {
      console.log("getIndustryList Error", error);
      setIndustryList([]);
      setLoading(false);
    }
  };
  return {
    industryList,
    loading,
    getIndustryList,
  };
  
}
export default useIndustryList;