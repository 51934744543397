import { useEffect, useState } from "react";
import { GET_SUB_DOMAIN } from "../../Apollo/ApplicationService/applicationQueryRequest";
import graphHandler from "../../Apollo/graphHandler";

function useSubDomain(id) {
  const [subDomain, setSubDomain] = useState({});
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (id) {
      getSubDomain(id);
    } else {
      setSubDomain({});
    }
  }, [id]);

  const getSubDomain = async (sub_domain_id) => {
    if (!sub_domain_id) return;
    setLoading(true);
    try {
      const { data } = await graphHandler().invoke(
        GET_SUB_DOMAIN,
        { sub_domain_id },
        1
      );
      if (data && data.getSubDomain && data.getSubDomain.sub_domain) {
        // console.log("getSubDomain Success", data.getSubDomain.sub_domain);
        setSubDomain(data.getSubDomain.sub_domain);
        setLoading(false);
      } else {
        setSubDomain({});
        setLoading(false);
      }
    } catch (error) {
      console.log("getSubDomain Error", error);
      setSubDomain({});
      setLoading(false);
    }
  };

  return {
    subDomain,
    loading,
    getSubDomain,
  };
}

export default useSubDomain;
