import React, { useEffect, useState } from "react";
import { Card, CardBody } from "reactstrap";
import EditQuestionaireModal from "../../Screener/modal/EditQuestionaireModal";
import QuestionOptions from "./QuestionOptions";

export default function TopicQuestion({ item, i, refresh = () => {} }) {
  const [isEdit, setEdit] = useState(false);

  return (
    <Card>
      <CardBody>
        <h6 className="card-title position-relative">
          {`${i + 1}). ${
            item.screener_question_title ? item.screener_question_title : ""
          }`}
          <i
            className="fas fa-edit float-right"
            onClick={() => setEdit(true)}
          />
        </h6>
        <QuestionOptions item={item} />
      </CardBody>
      <EditQuestionaireModal
        isOpen={isEdit}
        setOpen={() => setEdit(false)}
        initialValue={item}
        refresh={refresh}
      />
    </Card>
  );
}
