import { Modal, ModalBody } from "reactstrap";
import { useState } from "react";
import CreateBanner from "./CreateBanner";
import UpdateBanner from "./UpdateBanner";
import { useEffect } from "react";

export default function BannerModel({
  isOpen,
  setOpen = () => {},
  initialValue = {},
  refresh = () => {},
}) {
  const [banner, setBanner] = useState({});
  const onClose = () => {
    setOpen(false);
    setBanner({});
  };

  useEffect(() => {
    initialValue.banner_id && setBanner(initialValue);
  }, [initialValue, isOpen]);

  const ActionBanner = banner.banner_id ? UpdateBanner : CreateBanner;
  return (
    <Modal
      toggle={onClose}
      isOpen={isOpen}
      size="lg"
      modalClassName="modal-fullscreen modal-dialog-scrollable"
    >
      <div className="modal-header">
        <button type="button" className="close" onClick={onClose}>
          <span aria-hidden="true">&times;</span>
        </button>
        <h5 className="modal-title">
          {banner.banner_id ? "Update" : "Create"} Banner
        </h5>
      </div>
      <ModalBody className="pt-0">
        <ActionBanner {...{ onClose, banner, setBanner, refresh }} />
      </ModalBody>
    </Modal>
  );
}
