import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import graphHandler from "../../../Apollo/graphHandler";
import CustomButton from "../../../components/Button/CustomButton";
import { CREATE_ORGANIZATION_EMPLOYEE_GQL } from "../../../Apollo/ApplicationService/OrganizationResponse";

export default function AddOrganizationEmployee({
  refresh = () => {},
  setOrganizationEmployee = () => {},
  setLoading = () => {},
  onClose = () => {},
  organizationOption = [],
}) {
  const formik = useFormik({
    initialValues: {
      email_domains: [],
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      onSubmit(values);
    },
  });

  const onClosed = () => {
    formik.resetForm();
    // setOpen();
    onClose();
    formik.setValues({});
  };

  const onSubmit = async (value) => {
    formik.setSubmitting(true);
    const {
      organization_id,
      organization_employee_name,
      organization_employee_email,
      organization_employee_mobile_number,
    } = value;
    let create_req = {
      organization_id,
      organization_employee_name,
      organization_employee_email,
      // organization_employee_mobile_number,
    };
    if (organization_employee_mobile_number) {
      create_req.organization_employee_mobile_number =
        organization_employee_mobile_number;
    }
    try {
      const { data } = await graphHandler().invoke(
        CREATE_ORGANIZATION_EMPLOYEE_GQL,
        { create_req },
        1,
        true
      );
      if (
        data &&
        data.createOrganizationEmployee &&
        data.createOrganizationEmployee.organization_employee
      ) {
        setOrganizationEmployee(
          data.createOrganizationEmployee.organization_employee
        );
      }
      refresh();
      setLoading(false);
      // onClosed();
      formik.setSubmitting(false);
    } catch (error) {
      console.error("createOrganization", error);
      formik.setSubmitting(false);
      setLoading(false);
    }
  };

  const handleError = () => {
    if (Object.keys(formik.errors).length > 0) {
      toast.error(formik.errors[Object.keys(formik.errors)[0]]);
    }
  };
  return (
    // <Modal toggle={onClosed} isOpen={isOpen} size="lg" centered>
    //   <div className="modal-header">
    //     <button type="button" className="close" onClick={onClose}>
    //       <span aria-hidden="true">&times;</span>
    //     </button>
    //     <h5 className="modal-title">Add Organization</h5>
    //   </div>
    //   <ModalBody>
    <div className="container">
      <div className="intervention-form row">
        <div className="col-md-6 form-group">
          <label
            htmlFor="organization_id"
            className="col-form-label w-100 mb-2"
          >
            Organization
          </label>
          <select
            id="organization_id"
            name="organization_id"
            className="form-control"
            onChange={formik.handleChange}
            value={formik.values.organization_id}
            onBlur={formik.handleBlur}
          >
            <option key="Select" hidden>
              Select
            </option>
            {organizationOption.map((x, i) => (
              <option value={x.value} key={i}>
                {x.label}
              </option>
            ))}
          </select>
          <small className="text-danger">
            {formik.touched.organization_id &&
            formik.errors &&
            formik.errors.organization_id
              ? formik.errors.organization_id
              : ""}
          </small>
        </div>
        <div className="col-md-6 form-group">
          <label
            htmlFor="organization_employee_name"
            className="col-form-label mb-2"
          >
            Name
          </label>
          <input
            type="text"
            className="form-control"
            name="organization_employee_name"
            maxLength="50"
            value={
              formik.values && formik.values.organization_employee_name
                ? formik.values.organization_employee_name
                : ""
            }
            onChange={(e) =>
              formik.setFieldValue(
                "organization_employee_name",
                e.target.value.trimStart()
              )
            }
            onBlur={(e) => {
              formik.setFieldTouched("organization_employee_name", true);
              formik.setFieldValue(
                "organization_employee_name",
                e.target.value.replace(/\s+/g, " ").trim()
              );
            }}
          />
          <small className="text-danger">
            {formik.touched.organization_employee_name &&
            formik.errors &&
            formik.errors.organization_employee_name
              ? formik.errors.organization_employee_name
              : ""}
          </small>
        </div>
        <div className="col-md-6 form-group">
          <label
            htmlFor="organization_employee_email"
            className="col-form-label mb-2 w-100"
          >
            Email
          </label>
          <input
            type="text"
            className="form-control"
            name="organization_employee_email"
            maxLength="50"
            value={
              formik.values && formik.values.organization_employee_email
                ? formik.values.organization_employee_email
                : ""
            }
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <small className="text-danger">
            {formik.touched.organization_employee_email &&
            formik.errors &&
            formik.errors.organization_employee_email
              ? formik.errors.organization_employee_email
              : ""}
          </small>
        </div>
        <div className="col-md-6 form-group">
          <label
            htmlFor="organization_employee_mobile_number"
            className="col-form-label mb-2"
          >
            Mobile
          </label>
          <input
            type="text"
            className="form-control"
            name="organization_employee_mobile_number"
            maxLength="10"
            value={
              formik.values && formik.values.organization_employee_mobile_number
                ? formik.values.organization_employee_mobile_number
                : ""
            }
            onChange={(e) =>
              formik.setFieldValue(
                "organization_employee_mobile_number",
                e.target.value.replace(/[^0-9+]/gi, "")
              )
            }
            onBlur={formik.handleBlur}
          />
          <small className="text-danger">
            {formik.touched.organization_employee_mobile_number &&
            formik.errors &&
            formik.errors.organization_employee_mobile_number
              ? formik.errors.organization_employee_mobile_number
              : ""}
          </small>
        </div>

        <div className="d-flex align-items-end justify-content-end col-md-12 mt-3">
          <button
            onClick={onClosed}
            type="button"
            className="btn btn-danger btn-sm float-right btn-add-domain mr-2"
          >
            Close
          </button>
          <CustomButton
            type="button"
            className="btn btn-primary btn-outline-success btn-sm float-right btn-add-domain"
            disabled={
              Object.keys(formik.errors).length !== 0 ||
              !formik.isValid ||
              formik.isSubmitting
            }
            onClick={
              formik.isValid && Object.keys(formik.values).length !== 0
                ? formik.handleSubmit
                : handleError
            }
          >
            {"Create"}
          </CustomButton>
        </div>
      </div>
    </div>
    //   </ModalBody>
    // </Modal>
  );
}

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const validationSchema = Yup.object().shape({
  organization_employee_name: Yup.string().required("Enter name"),
  organization_employee_email: Yup.string()
    .email("Invalid email")
    .required("Enter a email"),
  organization_employee_mobile_number: Yup.string()
    // .required("Enter mobile number")
    .matches(phoneRegExp, "Mobile number is not valid"),
  organization_id: Yup.string().required("Organization is required"),
});
