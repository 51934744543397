import { roleBasedAccess } from "../../constants";
import { SET_ACCESS_CONFIG, FLUSH_ACCESS_CONFIG } from "./types";

const InitialState = {
  labels: [],
  roles: [],
  access: [],
  isDone: false,
  ...roleBasedAccess(""),
};

const Reducer = (state = InitialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_ACCESS_CONFIG:
      return {
        ...state,
        ...payload,
      };
    case FLUSH_ACCESS_CONFIG:
      return {
        ...InitialState,
      };
    default:
      return state;
  }
};

export default Reducer;
