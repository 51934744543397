import { Modal, ModalBody } from "reactstrap";
import HeaderImage from "../../../assets/Images/headerImage.png";
import playIcon from "../../../assets/Images/play-button.png";
import MarkdownIt from "markdown-it";
const mdParser = new MarkdownIt(/* Markdown-it options */);

export default function PreviewBanner({
  isOpen,
  setOpen = () => {},
  values = {},
}) {
  const videoData = () => {
    return values.banner_video_url ? values.banner_video_url.url : "";
  };
  const banner = values.banner_image_url ? values.banner_image_url.url : "";
  const isVideoImg = Boolean(videoData() && banner);

  if (videoData() ? !isVideoImg : !banner) return null;
  return (
    <Modal
      toggle={() => setOpen(false)}
      isOpen={isOpen}
      size="lg"
      modalClassName="modal-fullscreen modal-dialog-scrollable banner-preview-modal"
    >
      <img src={HeaderImage} />
      <ModalBody className="pt-3">
        <div className="container">
          <div className="item position-relative banner-shadow">
            <div className="banner-text-container">
              {values.title && (
                <h1
                  style={{
                    fontSize: values.title_font_size,
                    color: values.title_font_color,
                  }}
                >
                  {values.title}
                </h1>
              )}
              {values.description && (
                <div
                  className="text-view"
                  style={{
                    fontSize: values.description_font_size,
                    color: values.description_font_color,
                  }}
                  dangerouslySetInnerHTML={{
                    __html: mdParser.render(values.description),
                  }}
                />
              )}
            </div>
            {videoData() ? (
              <>
                <div className="play-button-section z-index-1000">
                  {videoData() ? <img src={playIcon} alt="play icon"/> : ""}
                </div>
                <div className="banner-video-container">
                  {banner && (
                    <img
                      src={banner}
                      alt="img"
                      style={{ borderRadius: "20px", overFlow: "hidden" }}
                      className="banner-cover-image"
                    />
                  )}
                </div>
              </>
            ) : (
              <img src={banner} alt="img" className="only-img banner-shadow" />
            )}
          </div>
        </div>
        <div className="d-flex align-items-end justify-content-center col-md-12 pt-3">
          <button
            onClick={() => setOpen(false)}
            type="button"
            className="btn btn-danger btn-sm float-right btn-add-domain"
          >
            Close
          </button>
        </div>
      </ModalBody>
    </Modal>
  );
}
