import Routes from "../../routes/routesNew";
import { ROUTE_LIST, CLEAR_LIST } from "./types";

export const rAddRoutes = () => (dispatch) => {
  const publicRoutes = Routes.filter((x) => x.type === "PUBLIC");
  const privateRoutes = Routes.filter((x) => x.type === "PRIVATE");
  dispatch({
    type: ROUTE_LIST,
    payload: { publicRoutes, privateRoutes, all: Routes },
  });
};

export const rClearRoutes = () => (dispatch) => {
  dispatch({
    type: CLEAR_LIST,
  });
};
