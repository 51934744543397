import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Collapse } from "reactstrap";
import useQuestionaireList from "../../../utils/CustomHooks/useQuestionaireList";
// import useScreenerAPI from "../../../utils/CustomHooks/useScreenerAPI";
import QuestionTopicAccordionItem from "./QuestionTopicAccordionItem";
import { Popconfirm } from "antd";
import graphHandler from "../../../Apollo/graphHandler";
import { UPDATE_SCREENER_QUESTIONAIRE_GQL, UPDATE_TOPIC_GQL } from "../../../Apollo/AdminServices/MutationRequest";
export default function QuestionTopicAccordion({
  index,
  item,
  reqCount,
  onEdit,
  canDelete = false,
  refreshQues,
  refresh,
  setHistory=()=>{},
}) {
  const [toggle, setToggle] = useState(false);
  const { questionaireList, loading, getQuestionaireList } =
    useQuestionaireList("");
  // const { loading: loading1, removeQuestion, removeTopic } = useScreenerAPI();
  useEffect(() => {
    if (item.screener_topic_id) {
      //   if (index === 0) setToggle(true);
      getQuestionaireList({
        screener_topic_id: item.screener_topic_id,
        get_pending_update: true,
        active:true
      });
    }
  }, [reqCount, item]);

  const onDelete = async() => {
    if (loading ) return;
    // if (!canDelete) return toast.warn("Sorry screener was published");
    let data = await questionaireList.filter((x)=> x.pending_history?.updated_data?.active !== false)
    if (data&&data.length > 0){
      return toast.error("Please delete all the questions inside this topic");
    }
    // removeTopic({ screener_topic_id: item.screener_topic_id }, refresh);
    try {
      await graphHandler().invoke(
        UPDATE_TOPIC_GQL,
        {
          update_request: {
            active: false,
            screener_topic_id: item.screener_topic_id,
          },
        },
        1,
        false
      );
      refresh();
    } catch (error) {
      console.log("Plan error", error);
    }
  };

  const onDeleteQuestion = async(screener_question_id) => {
    if (loading ) return;
    // if (!canDelete) return toast.warn("Sorry screener was published");
    // removeQuestion({ screener_question_id }, () => {
    //   getQuestionaireList({ screener_topic_id: item.screener_topic_id });
    //   refresh();
    // });
    try {
      await graphHandler().invoke(
        UPDATE_SCREENER_QUESTIONAIRE_GQL,
        {
          update_request: {
            active: false,
            screener_question_id,
          },
        },
        1,
        false
      );
      refresh();
    } catch (error) {
      console.log("Plan error", error);
    }
  };
  return (
    <div className="mb-1">
      <div className={`custom-accordion ${toggle && "active"}`}>
        <div className="accordion-header" onClick={() => setToggle((x) => !x)}>
          {item.screener_topic_title + " (" + questionaireList.length + ") "}
          <i className="fa fa-chevron-down mr-2" />
        </div>
        {onEdit && (
          <Popconfirm
            title="Are you sure to delete this topic?"
            onConfirm={onDelete}
            //  onCancel={cancel}
            okText="Yes"
            cancelText="No"
          >
            <i className="fas fa-trash cursor-pointer"></i>
          </Popconfirm>
        )}
      </div>
      <Collapse isOpen={toggle}>
        <div className="table-responsive border p-2">
          <table className="table questionnaire-table table-borderless mb-0">
            <thead>
              <tr className=" ">
                <td className="text-center">Question</td>
                <td className="text-center">Options</td>
                <td className="text-center">Sub Domain</td>
                <td className="text-center">Weightage</td>
                <td className="text-center">
                  Ignore Scoring
                </td>
                <td className="text-center">Action</td>
              </tr>
            </thead>
            <tbody>
              {questionaireList.length > 0 ? (
                questionaireList
                  .slice()
                  .filter((x)=>x?.active === true)
                  .sort((a, b) => a.sort_order - b.sort_order)
                  .map((x, i) => (
                    <QuestionTopicAccordionItem
                      key={i}
                      item={x}
                      onEdit={onEdit}
                      canDelete={canDelete}
                      onDelete={onDeleteQuestion}
                      setHistory={setHistory}
                    />
                  ))
              ) : (
                <tr>
                  <td className="text-center" colSpan="5">
                    No Question Found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </Collapse>
    </div>
  );
}
