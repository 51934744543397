import { useEffect } from "react";
import { Modal, ModalBody } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import graphHandler from "../../../Apollo/graphHandler";
import { EDIT_LOCALIZE_GQL } from "../../../Apollo/AdminServices/MutationRequest";
import {
  getDescription,
  removeEmptyObjValue,
} from "../../../utils/Functions/filter";
import Description from "../../../components/Input/Description";
import { useSelector } from "react-redux";
import CustomButton from "../../../components/Button/CustomButton";
import SingleImageUpload from "../../../components/MediaFileUpload/SingleImageUpload";

export default function UpdateLocalize({
  isOpen,
  setOpen,
  refresh,
  initialValue = {},
  languageList = [],
}) {
  const subDomains = useSelector(({ DataSet }) => DataSet.subDomains);
  const formik = useFormik({
    initialValues: {
      mobile_description: "",
      web_description: "",
      image_urls: [],
    },
    validationSchema: validation,
    onSubmit: (values) => {
      onSubmit(values);
    },
  });
  useEffect(() => {
    if (initialValue.localization_data_id) {
      const value = {
        localization_data_id: initialValue.localization_data_id,
        key: initialValue.key || "",
        language_id: initialValue.language_id,
        localization_data_code: initialValue.localization_data_code,
      };
      if (initialValue.sub_domain_id) {
        value.sub_domain_id = initialValue.sub_domain_id;
      }
      if (initialValue.value && initialValue.value.length > 0) {
        let { description_value, image_urls } = initialValue.value[0];
        if (description_value && description_value.length > 0) {
          if (getDescription(description_value, "Web")) {
            value.web_description = getDescription(
              description_value,
              "Web"
            ).description;
          } else {
            value.web_description = "";
          }
          if (getDescription(description_value, "Mobile")) {
            value.mobile_description = getDescription(
              description_value,
              "Mobile"
            ).description;
          } else {
            value.mobile_description = "";
          }
        } else {
          value.web_description = "";
          value.mobile_description = "";
        }
        if (image_urls && image_urls.length > 0) {
          value.image_urls = image_urls;
        } else {
          value.image_urls = [];
        }
      }
      formik.setValues(value);
    }
  }, [initialValue, isOpen]);

  const onClose = () => {
    formik.resetForm();
    setOpen();
  };

  const onSubmit = async (values) => {
    const {
      key,
      localization_data_id,
      web_description,
      mobile_description,
      sub_domain_id,
      language_id,
      image_urls,
    } = values;
    const update_request = {
      // key,
      localization_data_id,
      // language_id,
    };
    if (key !== initialValue.key) {
      update_request.key = key;
    }
    if (language_id !== initialValue.language_id) {
      update_request.language_id = language_id;
    }
    if (sub_domain_id) {
      update_request.sub_domain_id = sub_domain_id;
    }
    let value = {};
    let description_value = [];
    if (
      mobile_description == "" &&
      web_description == "" &&
      image_urls &&
      image_urls.length == 0
    ) {
      formik.setSubmitting(false);
      return toast.error("Enter description or Select image.");
    }
    if (web_description) {
      description_value.push({
        client_type: "Web",
        description: web_description,
      });
    }
    if (mobile_description) {
      description_value.push({
        client_type: "Mobile",
        description: mobile_description,
      });
    }
    if (description_value.length > 0) {
      value.description_value = description_value;
    }
    if (image_urls && image_urls.length > 0) {
      value.image_urls = image_urls.map((x) => {
        delete x.index;
        return removeEmptyObjValue(x);
      });
    }
    if (Object.keys(value).length > 0) update_request.value = value;
    // console.log("image_urls", update_request);
    try {
      await graphHandler().invoke(
        EDIT_LOCALIZE_GQL,
        { update_request },
        1,
        true
      );
      refresh();
      onClose();
      formik.setSubmitting(false);
    } catch (error) {
      console.log("update Localize error", error);
      formik.setSubmitting(false);
    }
  };

  const handleError = () => {
    if (Object.keys(formik.errors).length > 0) {
      toast.error(formik.errors[Object.keys(formik.errors)[0]]);
    }
  };

  return (
    <Modal
      toggle={onClose}
      isOpen={isOpen}
      size="lg"
      modalClassName="modal-fullscreen modal-dialog-scrollable"
    >
      <div className="modal-header">
        <button type="button" className="close" onClick={onClose}>
          <span aria-hidden="true">&times;</span>
        </button>
        <h5 className="modal-title">Update Localized Data</h5>
      </div>
      <ModalBody className="pt-0">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-6">
              <div className="row">
                <div className="col-md-12 form-group  ">
                  <label htmlFor="key" className="col-form-label w-100 mb-2">
                    Key
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="key"
                    name="key"
                    // maxLength="30"
                    value={
                      formik.values && formik.values.key
                        ? formik.values.key
                        : ""
                    }
                    onChange={(e) =>
                      formik.setFieldValue("key", e.target.value.trimStart())
                    }
                    onBlur={(e) => {
                      formik.setFieldTouched("key", true);
                      formik.setFieldValue(
                        "key",
                        e.target.value.replace(/\s+/g, " ").trim()
                      );
                    }}
                  />
                  <small className="text-danger">
                    {formik.touched.key && formik.errors && formik.errors.key
                      ? formik.errors.key
                      : ""}
                  </small>
                </div>
                <div className="col-md-12 form-group">
                  <label
                    htmlFor="localization_data_code"
                    className="col-form-label w-100 mb-2"
                  >
                    Code
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="localization_data_code"
                    name="localization_data_code"
                    onChange={formik.handleChange}
                    disabled={initialValue.localization_data_id}
                    // maxLength={30}
                    // disabled={interversionId !== ""}
                    value={
                      formik.values.localization_data_code
                        ? formik.values.localization_data_code
                        : ""
                    }
                    onBlur={formik.handleBlur}
                  />
                  <small className="text-danger align-self-end">
                    {formik.touched.localization_data_code &&
                    formik.errors &&
                    formik.errors.localization_data_code
                      ? formik.errors.localization_data_code
                      : ""}
                  </small>
                </div>
                <div className="col-md-12 form-group ">
                  <label
                    htmlFor="sub_domain_id"
                    className="col-form-label w-100 mb-2"
                  >
                    Sub Domain
                  </label>
                  <select
                    id="sub_domain_id"
                    name="sub_domain_id"
                    className="custom-select"
                    onChange={formik.handleChange}
                    value={
                      formik.values.sub_domain_id
                        ? formik.values.sub_domain_id
                        : ""
                    }
                    onBlur={formik.handleBlur}
                  >
                     <option key="Select" hidden>
                      Select
                    </option>
                    {subDomains.map((x, i) => (
                      <option key={i} value={x.sub_domain_id}>
                        {x.sub_domain_name}
                      </option>
                    ))}
                  </select>
                  <small className="text-danger align-self-end">
                    {formik.touched.sub_domain_id &&
                    formik.errors &&
                    formik.errors.sub_domain_id
                      ? formik.errors.sub_domain_id
                      : ""}
                  </small>
                </div>
                <div className="col-md-12 form-group ">
                  <label
                    htmlFor="language_id"
                    className="col-form-label w-100 mb-2"
                  >
                    Language
                  </label>
                  <select
                    id="language_id"
                    name="language_id"
                    className="custom-select"
                    onChange={formik.handleChange}
                    value={
                      formik.values.language_id ? formik.values.language_id : ""
                    }
                    onBlur={formik.handleBlur}
                  >
                    <option key="Select" hidden>
                      Select
                    </option>
                    {languageList.map((x, i) => (
                      <option key={i} value={x.language_id}>
                        {x.language_name}
                      </option>
                    ))}
                  </select>
                  <small className="text-danger align-self-end">
                    {formik.touched.language_id &&
                    formik.errors &&
                    formik.errors.language_id
                      ? formik.errors.language_id
                      : ""}
                  </small>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="row">
                <Description
                  formik={formik}
                  webName="web_description"
                  webTitle="Value"
                  mobileTitle="Mobile Value"
                  showEditor={true}
                />
              </div>
            </div>
            <div className="col-md-12">
              <SingleImageUpload
                formik={formik}
                heading="Image File"
                contentId={initialValue.localization_data_id}
                contentType="LOCALIZATIONDATA"
                mediaType="Image"
                parentArray="image_urls"
              />
            </div>
            <CustomButton
              type="button"
              className="btn btn-primary btn-outline-success float-right btn-add-domain"
              disabled={
                Object.keys(formik.errors).length !== 0 ||
                !formik.isValid ||
                formik.isSubmitting
              }
              onClick={
                formik.isValid && Object.keys(formik.values).length !== 0
                  ? formik.handleSubmit
                  : handleError
              }
            >
              Update
            </CustomButton>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
}

const validation = Yup.object().shape({
  sub_domain_id: Yup.string(),
  language_id: Yup.string().required("Select a Language"),
  web_description: Yup.string(),
  mobile_description: Yup.string(),
  key: Yup.string().trim().required("Enter a Key"),
  localization_data_code: Yup.string().required("Enter a code"),
  image_urls: Yup.array().of(
    Yup.object().shape({
      is_mobile: Yup.boolean().required("Select a Image device"),
      image_type: Yup.string().required("Select a Image format"),
      url: Yup.string(),
    })
  ),
  // .min(4, "Name should be min of 4 characters")
  // .max(30, "Name should be maximum of 30 characters")
});
