import { useEffect, useState } from "react";
import { Modal, ModalBody } from "reactstrap";
import CustomLoader from "../../../components/Loader";
import EditOrganization from "./EditOrganization";
import AddOrganization from "./AddOrganization";
import useCountryList from "../../../utils/CustomHooks/useCountryList";

export default function OrganizationModal({
  values = {},
  isOpen,
  setOpen,
  refresh,
  isEditable = true,
  isFirst = false,
}) {
  const [organization, setOrganization] = useState({});
  const [loading, setLoading] = useState(false);
  const { countryList, getCountryList } = useCountryList("");
  const [countryOption, setCountryOption] = useState([]);
// console.log("organization",organization)
  useEffect(() => {
    values.organization_id && setOrganization(values);
  }, [values]);
  
  useEffect(() => {
    getCountryList();
  }, []);

  useEffect(() => {
    setCountryOption(
      countryList.map((x) => ({
        label: x.country_name,
        value: x.country_id,
      }))
    );
  }, [countryList]);

  const OrganizationSection = organization.organization_id
    ? EditOrganization
    : AddOrganization;
  const onClose = () => {
    setOpen(false);
    setOrganization({});
    refresh();
  };

  return (
    <Modal
      toggle={onClose}
      isOpen={isOpen}
      size="lg"
      keyboard={false}
      modalClassName="modal-fullscreen modal-dialog-scrollable"
    >
      <div className="modal-header">
        <h5 className="modal-title">
          {organization.organization_id ? "Update" : "Add"} Organization
        </h5>
      </div>
      <ModalBody>
        {loading && <CustomLoader />}
        <OrganizationSection
            refresh={refresh}
            onClose={onClose}
            countryOption={countryOption}
            organization={organization}
            isEditable={isEditable}
            isFirst={isFirst}
            values={organization}
            setOrganization={setOrganization}
            setLoading={setLoading}
          />
      </ModalBody>
    </Modal>
  );
}
