import React, { useEffect, useState } from "react";
import "./interventionDetails.css";
import useTherapy from "../../utils/CustomHooks/useTherapy";
import useTherapyHistory from "../../utils/CustomHooks/useTherapyHistory";
import TherapyData from "./components/TherapyData";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import ModuleList from "./components/ModuleList";
import ScreenerHistory from "../ScreenerDetails/Components/ScreenerHistory";
import ScreenerHistoryCommentsTable from "../ScreenerDetails/Components/ScreenerHistoryCommentsTable";
import withRoleBased from "../../utils/Functions/withRoleBased";

function Index({ location, history, access = {} }) {
  const [count, setCount] = useState(0);
  const [therapy_id, setTherapyId] = useState("");
  const [tabIndex, setTabIndex] = useState(0);
  const [allTopics, setAllTopics] = useState([]);
  const [loading, setLoading] = useState(true);
  const [canUpdate, setCanUpdate] = useState(false);
  const [historyCount, setHistoryCount] = useState(0);
  const { therapyHistory, getTherapyHistory } = useTherapyHistory("");
  const { therapy, getTherapy } = useTherapy("");

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    if (loading && query.has("id") && query.get("id")) {
      setTherapyId(query.get("id"));
      setLoading(false);
    } else if (history.previous) {
      history.goBack();
    } else {
      history.push("/therapy");
    }
    if (location.state && location.state.published) {
      setCanUpdate(location.state.published);
    }
  }, [location]);

  useEffect(() => {
    if (therapy_id) getTherapyHistory(therapy_id);
  }, [therapy_id, historyCount]);

  useEffect(() => {
    if (therapy_id) getTherapy(therapy_id);
  }, [therapy_id, count]);

  return (
    <main className="page-content">
      <div className="container-fluid">
        <div className="row">
          <TherapyData
            data={therapy}
            canUpdate={canUpdate}
            setCanUpdate={setCanUpdate}
            refresh={() => setCount((x) => x + 1)}
          />
          <div className="col-lg-12">
            <ModuleList
              therapy_id={therapy_id}
              therapy={therapy}
              access={access}
            />
            {/* <div className="col-md-6  mb-3">
            <div className="imgMask">
              <div className="imgMask-content">+</div>
            </div>
            <input type="file" className="imgg" id="imgInp" name="files" />
            <img id="sub-domain-img" src="#" alt="." />
            <b>Game Doc</b>
          </div> */}
            <Tabs
              selectedIndex={tabIndex}
              onSelect={(index) => setTabIndex(index)}
              className="w-100 mt-4"
            >
              <TabList>
                <Tab>
                  <span className="font-weight-bold">Intervention History</span>
                </Tab>
                <Tab>
                  <span className="font-weight-bold">Comments</span>
                </Tab>
              </TabList>
              <TabPanel>
                <ScreenerHistory screenerHistory={therapyHistory} />
              </TabPanel>
              <TabPanel>
                <ScreenerHistoryCommentsTable
                  screenerHistory={therapyHistory}
                />
              </TabPanel>
            </Tabs>
            {/* <div className="col-md-12 mb-3">
            <button
              type="button"
              className="btn btn-outline-success mr-3"
              //   type="button"
              //   className="btn btn-primary"
            >
              Intervention Edit History
            </button>
            <button
              type="button"
              className="btn btn-outline-success "
              //   type="button"
              //   className="btn btn-primary"
            >
              Comments
            </button>
          </div> */}
            {/* <div className="col-md-12">
            <div className="table-responsive ">
              <table className="table table-borderless">
                <thead>
                  <tr className=" ">
                    <td className="">Action</td>
                    <td className="">Time </td>
                    <td className="">By </td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="col-sm-2">Plane</td>
                    <td>Game </td>
                    <td> Game</td>
                  </tr>
                </tbody>
              </table>
            </div> 
          </div>*/}
          </div>
        </div>
      </div>
    </main>
  );
}
export default withRoleBased(Index, "therapy");
