import { useState } from "react";
import { getDescription } from "../../../utils/Functions/filter";
import { Switch } from "antd";
import { toast } from "react-toastify";
import ReadMore from "../../../components/ReadMore";
import { Popconfirm } from "antd";
import useBannerAPI from "../../../utils/CustomHooks/useBannerAPI";
import BannerModel from "../modal/BannerModel";

export default function BannerListItem({
  item = {},
  refresh = () => {},
  access = {},
}) {
  const [isEdit, setEdit] = useState(false);
  const { loading, activateBanner, removeBanner } = useBannerAPI();
  const { canEdit, isAdmin } = access;

  const onDelete = () => {
    if (loading) return;
    removeBanner({ banner_id: item.banner_id }, refresh);
  };

  const activateItem = async (active) => {
    if (!item.banner_id) return;
    const request = {
      active,
      banner_id: item.banner_id,
    };
    activateBanner(request, onActivate(active));
  };

  const onActivate = (active) => {
    setTimeout(() => {
      toast.success(
        `${"This Banner"} ${active ? "Activated" : "Deactivated"} Successfully`
      );

      refresh();
    }, [1000]);
  };

  return (
    <tr>
      {isAdmin && (
        <td>
          <Switch checked={item.active} onChange={activateItem} />
        </td>
      )}
      <td className="col-sm-2">{item.banner_name}</td>
      <td>
        <ReadMore>
          {item.banner_descriptions &&
          getDescription(item.banner_descriptions, "Web")
            ? getDescription(item.banner_descriptions, "Web").description
            : "-"}
        </ReadMore>
      </td>
      <td>{item.sort_order ? item.sort_order : "-"}</td>
      <td>
        <span
          className={`${item.active ? "good" : "bad"} text-center btn btn-sm`}
        >
          {item.active ? "Active" : "Disabled"}
        </span>
      </td>
      <td>
        {canEdit && (
          <button
            type="button"
            className="ml-2 btn btn-outline-success btn-sm "
            onClick={() => setEdit(true)}
          >
            Edit
          </button>
        )}

        {isAdmin && (
          <Popconfirm
            title="Are you sure to delete this banner?"
            onConfirm={onDelete}
            okText="Yes"
            cancelText="No"
          >
            <i className="ml-2 fas fa-trash cursor-pointer"></i>
          </Popconfirm>
        )}
      </td>
      <BannerModel
        isOpen={isEdit}
        setOpen={() => setEdit(false)}
        initialValue={item}
        refresh={refresh}
      />
    </tr>
  );
}
