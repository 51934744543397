import { useEffect, useState } from "react";
import { GET_BLOG_LIST_GQL } from "../../Apollo/AdminServices/QueryRequest";
import graphHandler from "../../Apollo/graphHandler";
import { sortArray } from "../Functions/filter";

function useBlogList(id) {
  const [blogList, setBlogList] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (id) {
      getBlogList(id);
    } else {
      setBlogList([]);
    }
  }, [id]);

  const getBlogList = async (req = {}, get_pending_update = true) => {
    setLoading(true);
    try {
      const { data } = await graphHandler().invoke(
        GET_BLOG_LIST_GQL,
        { get_pending_update, ...req },
        1,
        false,
        true
      );
      if (
        data &&
        data.getBlogList &&
        data.getBlogList.blogs &&
        data.getBlogList.blogs.length > 0
      ) {
        // console.log("getBlogList Success", data.getBlogList);
        const struct = data.getBlogList.blogs.map((x) => ({
          ...x.blog,
          label: x.blog.blog_name,
          value: x.blog.blog_id,
          pending_history: x.pending_blog_history,
        }));
        setBlogList([]);
        setBlogList(sortArray(struct));
        setLoading(false);
      } else {
        setBlogList([]);
        setLoading(false);
      }
    } catch (error) {
      console.log("getBlogList Error", error);
      setBlogList([]);
      setLoading(false);
    }
  };

  return {
    blogList,
    loading,
    getBlogList,
  };
}

export default useBlogList;
