import { Tabs } from "antd";
import { useState } from "react";
import { toast } from "react-toastify";
import QuestionSection from "./QuestionSection";
import TopicSection from "./TopicSection";
const { TabPane } = Tabs;
export default function CreateQTSection(props) {
  let { topicList = [], onDone = () => {}, isGame } = props;
  const [active, setActive] = useState("0");
  const onChangeTab = (e) => {
    if (e === "1" && topicList.length === 0) {
      return toast("Add some topics");
    } else {
      setActive(e);
    }
  };
  const onNext = () => {
    if (active === "0") {
      onChangeTab("1");
    } else {
      onDone();
    }
  };
  return isGame ? (
    <TopicSection {...props} onDone={onDone} />
  ) : (
    <Tabs
      centered
      onTabClick={onChangeTab}
      defaultActiveKey={0}
      activeKey={active}
    >
      <TabPane tab="Topic" key="0">
        {active == "0" ? <TopicSection {...props} onDone={onNext} /> : null}
      </TabPane>
      <TabPane tab="Question" key="1">
        {active == "1" ? <QuestionSection {...props} /> : null}
      </TabPane>
    </Tabs>
  );
}
