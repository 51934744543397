import { useState } from "react";
import UpdateCountry from "../modal/UpdateCountry";
import { Popconfirm } from "antd";
import useRemoveAPI from "../../../utils/CustomHooks/useRemoveAPI";

export default function ListItem({ item, refresh, access = {} }) {
  const [isEdit, setEdit] = useState(false);
  const { removeCountry } = useRemoveAPI();

  const onDelete = () => {
    removeCountry({ country_id: item.country_id }, refresh);
  };

  return (
    <tr>
      <td className="col-sm-2">{item.country_name ?? "-"}</td>
      <td>{item.country_short_code ?? "-"}</td>
      <td>{item.country_mobile_number_prefix ?? "-"}</td>
      <td>
        <span
          className={`${item.active ? "good" : "bad"} text-center btn btn-sm`}
        >
          {item.active ? "Active" : "Disabled"}
        </span>
      </td>
      {access.canEdit && (
        <td>
          <button
            type="button"
            className="btn btn-outline-success btn-sm "
            onClick={() => setEdit(true)}
          >
            Edit
          </button>
        </td>
      )}
      <td>
        <Popconfirm
          title="Are you sure to delete this Country?"
          onConfirm={onDelete}
          //  onCancel={cancel}
          okText="Yes"
          cancelText="No"
        >
          <i
            className="ml-2 fas fa-trash cursor-pointer"
            // onClick={onDelete}
          ></i>
        </Popconfirm>
      </td>
      <UpdateCountry
        isOpen={isEdit}
        setOpen={() => setEdit(false)}
        initialValue={item}
        refresh={refresh}
      />
    </tr>
  );
}
