import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { AdminRoles } from "../../../constants";
import useUserAdminRoles from "../../../utils/CustomHooks/useUserAdminRoles";
import UserRoleItem from "./UserRoleItem";

export default function UserRole({ user_id = "", access }) {
  const { roles, getAllAdminRoles } = useUserAdminRoles("");
  const [editedRole, setEditedRoles] = useState([]);
  const user = useSelector(({ Auth }) => Auth.user);
  const isMine = user.user_id === user_id;
  const isAdminUser = roles.filter((x) => x.role_type === "ADMIN").length > 0;
  const [edit, setEdit] = useState(false);
  const [count, setCount] = useState(0);
  useEffect(() => {
    if (user_id) {
      getAllAdminRoles(user_id);
    }
  }, [count, user_id]);

  useEffect(() => {
    setEditedRoles(roles);
  }, [roles]);

  const onDone = () => {
    getAllAdminRoles(user_id);
    setCount((x) => x + 1);
    setEditedRoles((x) => x.filter((j) => j.user_id));
    setEdit(false);
  };
  const onRemoveFromList = (i) => {
    const temp = [...editedRole];
    if (temp.length > 1) return toast.warn("Cannot remove all roles");
    temp.splice(i, 1);
    setEditedRoles(temp);
  };
  return (
    <div className="form-group d-flex flex-column">
      <p className="modal-sub-title font-weight-bold text-uppercase">
        Roles
        {!edit &&
          !isMine &&
          (access.isSuper ? isAdminUser : !isAdminUser) &&
          access.canEdit && (
            <i
              className="fas fa-edit  ml-5"
              onClick={() => setEdit((x) => !x)}
            ></i>
          )}
      </p>
      {editedRole.map((x, i) => (
        <UserRoleItem
          edit={edit}
          user_id={user_id}
          key={i}
          value={x}
          refresh={() => setCount((x) => x + 1)}
          onRemoveFromList={() => onRemoveFromList(i)}
          isLastItem={i === editedRole}
          roles={editedRole}
          isAdmin={access.isAdmin}
        />
      ))}
      {edit && (
        <div className="d-flex justify-content-between">
          <button className="btn" onClick={onDone}>
            Done
          </button>
          {editedRole.filter((x) => x.role_type === "ADMIN").length === 0 && (
            <button
              className="btn btn-outline-success"
              onClick={() => {
                if (
                  editedRole.filter((x) => x.role_types === "ADMIN").length > 0
                )
                  return toast.warn("Admin can access all the data!");
                setEditedRoles((x) => [...x, initialValues]);
              }}
            >
              Add New Role
            </button>
          )}
        </div>
      )}
      {/* {edit ? (
        <div className="mt-3 d-flex align-items-center">
          <button type="button" className="btn mr-3" onClick={onCancel}>
            Cancel
          </button>
          <button
            type="button"
            className="btn btn-outline-success"
            disabled={Object.keys(formik.errors).length !== 0}
            onClick={
              formik.isValid && Object.keys(formik.values).length !== 0
                ? formik.handleSubmit
                : handleError
            }
          >
            Save
          </button>
        </div>
      ) : null} */}
    </div>
  );
}

const initialValues = {
  role_types: "",
  sub_domain_id: "",
};
