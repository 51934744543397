import { Steps } from "antd";
import { useEffect, useState } from "react";
import { Modal, ModalBody } from "reactstrap";
import CustomLoader from "../../../components/Loader";
import useGameLevelList from "../../../utils/CustomHooks/useGameLevelList";
import ConfigSection from "../section/ConfigSection";
import LevelSection from "../section/LevelSection";
const { Step } = Steps;

export default function GameModal(props = {}) {
  let {
    isOpen,
    setOpen,
    refresh,
    initialValues = {},
    isScreenerEditable = false,
    isEditable = false,
    setLevelDta = () =>{},
  } = props;
  const [activeKey, setActive] = useState(0);
  const [config, setConfig] = useState({});
  const [loading, setLoading] = useState(false);
  const [reqCount, setReqCount] = useState(0);
  const {
    levelList,
    loading: levelLoading,
    getLevelList,
  } = useGameLevelList("");
  const isScreener = config.game_type === "Screener";
  useEffect(() => {
    if (initialValues.game_config_id) setConfig(initialValues);
  }, [initialValues]);


  useEffect(() => {
    if (config.game_config_id && !isScreener) {
      getLevelList({ game_config_id: config.game_config_id });
    }
  }, [config, reqCount]);

  useEffect(()=>{
    if(levelList){
      setLevelDta(levelList)
    }
  },[levelList])

  const onClose = () => {
    setOpen(false);
    setActive(0);
    setReqCount((x) => x + 1);
  };

  const onChangeStep = (e) => {
    if (e === 1 && !config.game_config_id) return;
    setActive(e);
  };

  const renderSteps = (key) => {
    switch (key) {
      case 0:
        return (
          <ConfigSection
            refresh={refresh}
            onDone={() => setActive(1)}
            onClose={onClose}
            values={config}
            isEditable={isScreenerEditable && isEditable}
            setConfig={setConfig}
            setLoading={setLoading}
            {...props}
          />
        );
      case 1:
        return (
          <LevelSection
            refresh={() => setReqCount((x) => x + 1)}
            levelList={levelList}
            isEditable={isEditable}
            values={config}
            onDone={onClose}
            onBack={() => setActive(0)}
            setLoading={setLoading}
            onClose={onClose}
          />
        );

      default:
        return <h5>No step found</h5>;
    }
  };
  return (
    <Modal
      toggle={onClose}
      isOpen={isOpen}
      size="lg"
      modalClassName="modal-fullscreen modal-dialog-scrollable"
    >
      {isScreener ? (
        <div className="modal-header">
          <button type="button" className="close" onClick={onClose}>
            <span aria-hidden="true">×</span>
          </button>
          <h5 className="modal-title text-center mb-3">
            {config.game_config_id ? "Config" : "Create Config"}
          </h5>
        </div>
      ) : (
        <div className="modal-header">
          <Steps
            type="navigation"
            current={activeKey}
            onChange={onChangeStep}
            className="site-navigation-steps"
          >
            <Step
              status={config.game_config_id ? "finish" : "process"}
              title={config.game_config_id ? "Config" : "Create Config"}
            />
            <Step
              status={levelList.length > 0 ? "finish" : "wait"}
              title="Level Data"
            />
          </Steps>
        </div>
      )}
      <ModalBody>
        {(loading || levelLoading) && <CustomLoader />}
        {renderSteps(activeKey)}
      </ModalBody>
    </Modal>
  );
}
