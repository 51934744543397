import { useState } from "react";
import ThresholdSectionItem from "../Components/ThresholdSectionItem";
import CreateThresholdSection from "./CreateThresholdSection";
import UpdateThresholdSection from "./UpdateThresholdSection";
import { Popconfirm } from "antd";
import { useEffect } from "react";

export default function ThresholdSection(props) {
  let {
    screener = {},
    thresholdList = [],
    refresh = () => {},
    onClose = () => {},
    onBack = () => {},
    handleDiscord=()=>{},
    isEditable,
  } = props;
  const [thres, setThres] = useState({});
  const screener_history = thres.pending_history ? thres.pending_history : {};
  const hasUpdated = Boolean(screener_history && screener_history.updated_data);
  const [history, setHistory] = useState(false);
  
  useEffect(() => {
    refresh();
  }, []);
  
  const ActionComponent = thres.screener_threshold_id
    ? UpdateThresholdSection
    : CreateThresholdSection;
  return (
    <div className="row justify-content-center ">
      <div className="col-md-12">
        <div className="table-responsive border p-2">
          <table className="table questionnaire-table table-borderless mb-0">
            <thead>
              <tr className=" ">
                <th className="text-center">Type</th>
                <th className="text-center">Reference</th>
                <th className="text-center">Min Threshold</th>
                <td className="text-center">Action</td>
              </tr>
            </thead>
            <tbody>
              {thresholdList.length > 0 ? (
                thresholdList
                  .slice()
                  .filter((x)=>x?.active === true)
                  .sort((a, b) => a.sort_order - b.sort_order)
                  .map((item, i) => (
                    <ThresholdSectionItem
                      {...props}
                      item={item}
                      key={i}
                      isEditable={isEditable}
                      onEdit={setThres}
                      canDelete={!screener.published}
                      refresh={refresh}
                      setHistory={setHistory}
                    />
                  ))
              ) : (
                <tr>
                  <td className="text-center" colSpan="5">
                    No Data Found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      {isEditable && (
        <ActionComponent {...props} values={thres} reset={() => setThres({})} />
      )}
      <div className="d-flex align-items-end justify-content-center col-md-12 p-5">
      {isEditable && (hasUpdated || history) && (
          <Popconfirm
            title="Are you sure to discard and close?"
            onConfirm={handleDiscord}
            //  onCancel={cancel}
            okText="Yes"
            cancelText="No"
          >
            <button
             type="button"
              className="btn btn-danger btn-sm float-right btn-add-domain mr-2"
            >
              Discard All & Close
            </button>
          </Popconfirm>
        )}
        <button
          onClick={onClose}
          type="button"
          className="btn btn-danger btn-sm float-right btn-add-domain mr-2"
        >
          Close
        </button>{" "}
        <button
          onClick={onBack}
          type="button"
          className="btn btn-error btn-sm float-right btn-add-domain mr-2"
        >
          Back
        </button>
        {/* <button
          onClick={onClose}
          type="button"
          className="btn btn-secondary btn-sm float-right btn-add-domain mr-2"
        >
          Done
        </button> */}
      </div>
    </div>
  );
}
