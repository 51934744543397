import { useState } from "react";
import { GET_ORGANIZATION_LIST_GQL } from "../../Apollo/ApplicationService/OrganizationResponse";
import graphHandler from "../../Apollo/graphHandler";

function useOrganizationList(preload = false) {
  const [organizationList, setOrganizationList] = useState([]);
  const [loading, setLoading] = useState(preload);

  const getOrganizationList = async (req = {}) => {
    setOrganizationList([]);
    setLoading(true);
    try {
      const { data } = await graphHandler().invoke(
        GET_ORGANIZATION_LIST_GQL,
        { ...req },
        2,
        false,
        true
      );
      if (
        data &&
        data.getOrganizationList &&
        data.getOrganizationList.organizations &&
        data.getOrganizationList.organizations.length > 0
      ) {
         setOrganizationList(
          data.getOrganizationList.organizations
            .slice()
            .sort(
              (a, b) =>
                new Date(b.updated_at ? b.updated_at : b.created_at) -
                new Date(a.updated_at ? a.updated_at : a.created_at)
            )
        );
        setLoading(false);
      } else {
        setOrganizationList([]);
        setLoading(false);
      }
    } catch (error) {
      console.log("getOrganizationList Error", error);
      setOrganizationList([]);
      setLoading(false);
    }
  };

  return {
    organizationList,
    loading,
    getOrganizationList,
  };
}

export default useOrganizationList;
