import { useState } from "react";
import { GET_TEMPLATE_LIST_GQL, REMOVE_TEMPLATE_GQL } from "../../Apollo/AdminServices/MailRuleReportRequest";
import graphHandler from "../../Apollo/graphHandler";
import { sortArray } from "../Functions/filter";

function useTemplateList() {
  const [mailList, setMailList] = useState([]);
  const [loading, setLoading] = useState(false);

  const getTemplateList = async (req = {}) => {
    setLoading(true);
    try {
      const { data } = await graphHandler().invoke(
        GET_TEMPLATE_LIST_GQL,
        { ...req },
        1,
        false,
        true
      );
      if (
        data &&
        data.getTemplateList &&
        data.getTemplateList.templates &&
        data.getTemplateList.templates.length > 0
      ) {
        setMailList([]);
        setMailList(sortArray(data.getTemplateList.templates));
        setLoading(false);
      } else {
        setMailList([]);
        setLoading(false);
      }
    } catch (error) {
      console.log("getTemplateList Error", error);
      setMailList([]);
      setLoading(false);
    }
  };

  const removeTemplate = async (
    req = {},
    callback = () => {},
    eCallBack = () => {}
  ) => {
    setLoading(true);
    try {
      await graphHandler().invoke(REMOVE_TEMPLATE_GQL, req, 1, false, true);
      callback();
      setLoading(false);
    } catch (error) {
      eCallBack(error);
      console.log("removeTemplate Error", error);
      setLoading(false);
    }
  };

  return { mailList, loading, getTemplateList,removeTemplate };
}

export default useTemplateList;
