import { defaultRoleKeys, roleBasedAccess } from "../../constants";
import { SET_ACCESS_CONFIG } from "./types";
import * as _ from "lodash";

export const rConfigAccessData =
  (userRole = []) =>
  async (dispatch) => {
    let access = userRole.map((x) => roleBasedAccess(x.role_type));
    let final = { labels: [], ...roleBasedAccess("") };
    access.map((x) => {
      defaultRoleKeys.map((key) => {
        final[key] = _.uniq([
          ...(final[key] ? final[key] : []),
          ...(x[key] ? x[key] : []),
        ]);
        final.label = x.label;
        final.labels = _.uniq([...final.labels, x.label]);
      });
    });
    dispatch({
      type: SET_ACCESS_CONFIG,
      payload: { ...final, access, userRole, isDone: true },
    });
    // console.log("final", final);
  };
