import { useCSVDownloader } from "react-papaparse";
import { QuestionTemplate } from "../modal/types";

export default function DownloadSection({
  onClose = () => {},
  onNext = () => {},
  onDownload = () => {},
  template = [],
}) {
  const { CSVDownloader, Type } = useCSVDownloader();
  return (
    <div className="row">
      <div className="col-12" style={{ height: "75vh" }}>
        <div className="container">
          <ol type="disc" className="">
            <li className="mb-4"> Please download the template.</li>
            <li className="mb-4">
              Enter all the fields (Try not to change the title fields while
              editing.)
            </li>
            <li className="mb-4">
              Upload the sheet without leaving an empty field.
            </li>
          </ol>
          <CSVDownloader
            type={Type.Button}
            filename={"Questions"}
            className="btn btn-success float-right"
            // config={{
            //   delimiter: ",",
            // }}
            onClick={() => onDownload(true)}
            data={template.length > 0 ? template : QuestionTemplate}
          >
            Download template
            <i className="fas fa-file-download ml-2" />
          </CSVDownloader>
        </div>
      </div>
      <div className="col-12 d-flex align-items-end justify-content-end">
        <button
          onClick={onClose}
          type="button"
          className="btn btn-danger btn-sm float-right btn-add-domain mr-2"
        >
          Close
        </button>
        <button
          onClick={onNext}
          type="button"
          className="btn btn-secondary btn-sm float-right btn-add-domain mr-2"
        >
          Next
        </button>
      </div>
    </div>
  );
}
