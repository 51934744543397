import { useFormik } from "formik";
import { useEffect, useMemo, useState } from "react";
import * as Yup from "yup";
import {
  getDescription,
  IsNullOrEmpty,
  mergeDescriptionArray,
} from "../../utils/Functions/filter";
import EditorModal from "../Modal/EditorModal";

export default function DescriptionInputField({
  parentForm = {},
  className = "col-md-6",
  col = "col-md-12",
  updateValue = "",
  actualValue = "",
  disabled = false,
  webTitle = "Description",
  parentKey = "description",
  mobileTitle = "Mobile Description",
  webName = "description",
  mobileName = "mobile_description",
  validation = {},
  inputProps = {},
  showEditor = false,
  refreshId = "",
}) {
  const [isOpenWeb, setOpenWeb] = useState(false);
  const [isOpenMobile, setOpenMobile] = useState(false);
  const [old, setOld] = useState({});
  const [mergedData, setMerged] = useState([]);
  const formik = useFormik({
    initialValues: {},
    validationSchema: Yup.object().shape(validation),
    onSubmit: () => {},
    validateOnChange: true,
  });

  useEffect(() => {
    if (updateValue && updateValue.length > 0 && parentForm) {
      parentForm.setFieldValue(parentKey, updateValue);
    }
  }, [updateValue]);
  useEffect(() => {
    if (formik.errors[webName] || formik.errors[mobileName]) {
      const errors = parentForm.errors ? parentForm.errors : {};
      parentForm.setErrors({ ...errors, ...formik.errors });
    }
  }, [formik.errors, parentForm.errors]);

  useMemo(() => {
    let description = mergeDescriptionArray(actualValue, updateValue);
    setMerged(description);
    let model = {},
      actualData = {};
    if (getDescription(description, "Web")) {
      model[webName] = getDescription(description, "Web").description;
    }
    if (getDescription(description, "Mobile")) {
      model[mobileName] = getDescription(description, "Mobile").description;
    }
    if (
      actualValue &&
      actualValue.length > 0 &&
      updateValue &&
      updateValue.length
    ) {
      let updated = updateValue.filter(
        (x) => x.update_type !== "Remove" && x.update_data
      );
      if (
        updated.find((x) => x.update_data.client_type === "Web") &&
        getDescription(actualValue, "Web")
      ) {
        actualData[webName] = getDescription(actualValue, "Web").description;
      }
      if (
        updated.find((x) => x.update_data.client_type === "Mobile") &&
        getDescription(actualValue, "Mobile")
      ) {
        actualData[mobileName] = getDescription(
          actualValue,
          "Mobile"
        ).description;
      }
    }
    setOld(actualData);
    formik.setValues({ ...model });
  }, [refreshId]);

  const onChange = (e) => {
    let { name, value } = e.target;
    formik.setFieldValue(name, value);
    setParent(name, value);
  };

  const setParent = (name, description) => {
    if (parentForm && name) {
      let client_type = name.toLowerCase().includes("mobile")
          ? "Mobile"
          : "Web",
        parentData = [
          ...(parentForm.values[parentKey]
            ? parentForm.values[parentKey]
            : []
          ).map((x) => x),
        ],
        index = mergedData.findIndex((x) => x.client_type === client_type),
        i = parentData.findIndex((x) =>
          x.update_type === "Add"
            ? x.update_data.client_type === client_type
            : index > -1
            ? x.index === index
            : false
        );
      if (index > -1) {
        parentData[i > -1 ? i : parentData.length] = description
          ? {
              update_type: "Update",
              index,
              update_data: {
                client_type,
                description,
              },
            }
          : {
              update_type: "Remove",
              index,
            };
      } else {
        parentData[i > -1 ? i : parentData.length] = {
          update_type: "Add",
          update_data: {
            client_type,
            description,
          },
        };
      }
      parentForm.setFieldValue(parentKey, parentData);
    }
  };

  const onCopy = () => {
    if (disabled) return;
    formik.setFieldValue(mobileName, formik.values[webName]);
    setParent(mobileName, formik.values[webName]);
  };

  return (
    <div className={className}>
      <div className="row">
        <div className={col}>
          <div className="row">
            {old[webName] && (
              <div className="col-md-12 form-group">
                <label htmlFor={webName} className="col-form-label w-100 mb-2">
                  {webTitle}
                  <span className="badge badge-good float-right">Existing</span>
                </label>
                <textarea
                  className="form-control"
                  value={old[webName] ? old[webName] : ""}
                  disabled
                />
              </div>
            )}
            <div className="form-group col-md-12">
              <label htmlFor={webName} className="col-form-label w-100 mb-2">
                {webTitle}
                {old[webName] && (
                  <span className="ml-3 badge badge-error float-right">
                    New
                  </span>
                )}
                {showEditor && !disabled && (
                  <button
                    className="btn-link-common float-right"
                    onClick={() => setOpenWeb(true)}
                  >
                    Use Editor
                  </button>
                )}
              </label>
              <textarea
                className="form-control"
                id={webName}
                name={webName}
                value={formik.values[webName] ? formik.values[webName] : ""}
                onChange={onChange}
                onBlur={formik.handleBlur}
                disabled={disabled}
                {...inputProps}
              />
              <small className="text-danger">
                {formik.errors[webName] ? formik.errors[webName] : ""}
              </small>
            </div>
          </div>
        </div>
        <div className={col}>
          <div className="row">
            {old[mobileName] && (
              <div className="col-md-12 form-group">
                <label
                  htmlFor={mobileName}
                  className="col-form-label w-100 mb-2"
                >
                  {mobileTitle}
                  <span className="badge badge-good float-right">Existing</span>
                </label>
                <textarea
                  className="form-control"
                  value={old[mobileName] ? old[mobileName] : ""}
                  disabled
                />
              </div>
            )}
            <div className="col-md-12 form-group">
              <label htmlFor={mobileName} className="col-form-label w-100 mb-2">
                {mobileTitle}
                {old[mobileName] && (
                  <span className="ml-3 badge badge-error float-right">
                    New
                  </span>
                )}
                {showEditor && !disabled && (
                  <button
                    className="btn-link-common float-right ml-3"
                    onClick={() => setOpenMobile(true)}
                  >
                    Use Editor
                  </button>
                )}
                <label
                  onClick={onCopy}
                  className="float-right text-right cursor-pointer mr-0"
                >
                  Copy <i className="fas fa-copy ml-2" />
                </label>
              </label>
              <textarea
                className="form-control"
                id={mobileName}
                name={mobileName}
                value={
                  formik.values[mobileName] ? formik.values[mobileName] : ""
                }
                onChange={onChange}
                onBlur={formik.handleBlur}
                disabled={disabled}
                {...inputProps}
              ></textarea>
              <small className="text-danger">
                {formik.errors[mobileName] ? formik.errors[mobileName] : ""}
              </small>
            </div>
          </div>
        </div>
        {showEditor && !disabled && (
          <EditorModal
            isOpen={isOpenWeb}
            defaultValue={formik.values[webName] ? formik.values[webName] : ""}
            close={() => setOpenWeb(false)}
            save={(e) => {
              formik.setFieldValue(webName, e);
              setParent(webName, e);
            }}
          />
        )}
        {showEditor && !disabled && (
          <EditorModal
            isOpen={isOpenMobile}
            defaultValue={
              formik.values[mobileName] ? formik.values[mobileName] : ""
            }
            close={() => setOpenMobile(false)}
            save={(e) => {
              formik.setFieldValue(mobileName, e);
              setParent(mobileName, e);
            }}
          />
        )}
      </div>
    </div>
  );
}
