import { combineReducers } from "redux";
import Auth from "./Auth/reducer";
import DataSet from "./DataSets/reducer";
import Routes from "./Routes/reducer";
import Role from "./Role/reducer";

const root = combineReducers({
  Auth,
  Routes,
  DataSet,
  Role,
});

export default root;
