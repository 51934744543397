import { useEffect, useState } from "react";
import graphHandler from "../../Apollo/graphHandler";
import { GET_USER_ACTIVITY_LIST_GQL } from "../../Apollo/ProfileService/profileQueryRequest";

function UseUserHistory(id) {
  const [userHistory, setUserHistory] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (id) {
      getUserHistory(id);
    } else {
      setUserHistory([]);
    }
  }, [id]);

  const getUserHistory = async (user_id) => {
    setLoading(true);
    try {
      const { data } = await graphHandler().invoke(
        GET_USER_ACTIVITY_LIST_GQL,
        { user_id },
        0,
        false,
        true
      );
      if (
        data &&
        data.getUserActivity &&
        data.getUserActivity.user_activity_list
      ) {
        // console.log("getUserActivity Success", data.getUserActivity);
        let history = [
          ...data.getUserActivity.user_activity_list.map((x) => x),
        ];
        setUserHistory(history);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.log("getUserActivity Error", error);
      setUserHistory([]);
      setLoading(false);
    }
  };

  return {
    userHistory,
    loading,
    getUserHistory,
    clear: () => setUserHistory([]),
  };
}

export default UseUserHistory;
