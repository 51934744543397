import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./ant.css";
import App from "./App.js";
import { HashRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store";
import history from "./utils/Functions/history";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.Fragment>
    <Provider store={store}>
      <HashRouter basename="/" history={history}>
        <App />
      </HashRouter>
    </Provider>
  </React.Fragment>
);
