import { useEffect, useState } from "react";
import { Modal, ModalBody } from "reactstrap";
import CustomLoader from "../../../components/Loader";
import { Steps } from "antd";
import useRelatedContentList from "../../../utils/CustomHooks/useRelatedContentList";
import RelatedContentsGroup from "../../../components/RelatedContents/component/RelatedContentsGroup";
import AddBlogModal from "./AddBlogModal";
import EditBlogModal from "./EditBlogModal";
import { useSelector } from "react-redux";
import useCategoryList from "../../../utils/CustomHooks/useCategoryList";
import useAuthorList from "../../../utils/CustomHooks/useAuthorList";
const { Step } = Steps;

export default function BlogModal({
  initialValues = {},
  isOpen,
  setOpen,
  refresh,
  isEditable = true,
  isFirst = false,
}) {
  const [activeKey, setActive] = useState(0);
  const [blog, setBlog] = useState({});
  const [loading, setLoading] = useState(false);
  const [reqCount, setReqCount] = useState(0);
  const { categoryList, getCategoryList } = useCategoryList("");
  const { authorList, getAuthorList } = useAuthorList(true);

  const subDomains = useSelector(({ DataSet }) => DataSet.subDomains);
  const [authors, setAuthors] = useState([]);
  const [categories, setCategories] = useState([]);
  useEffect(() => {
    getCategoryList({ supported_content_types: ["BLOG"] });
    getAuthorList();
  }, []);

  useEffect(() => {
    if (authorList && authorList.length > 0) {
      let list = authorList.map((x) => ({
        label: x.author_name,
        value: x.author_id,
      }));
      setAuthors(list);
    }
  }, [authorList]);

  useEffect(() => {
    if (categoryList && categoryList.length > 0) {
      let list = categoryList.map((x) => ({
        label: x.category_name,
        value: x.category_id,
      }));
      setCategories(list);
    }
  }, [categoryList]);

  const {
    relatedContents,
    loading: relatedContentLoading,
    getRelatedContents,
  } = useRelatedContentList();

  useEffect(() => {
    initialValues.blog_id && setBlog(initialValues);
  }, [initialValues]);

  useEffect(() => {
    if (blog.blog_id && isOpen) {
      getRelatedContents({
        content_id: blog.blog_id,
        content_type: "BLOG",
      });
    }
  }, [blog, reqCount, isOpen]);

  const BlogSection = blog.blog_id ? EditBlogModal : AddBlogModal;
  const renderSteps = (key) => {
    switch (key) {
      case 0:
        return (
          <BlogSection
            refresh={refresh}
            onDone={() => setActive(1)}
            onClose={onClose}
            blog={blog}
            isOpen={isOpen}
            isEditable={isEditable}
            isFirst={isFirst}
            initialValues={blog}
            setBlog={setBlog}
            subDomainList={subDomains}
            authors={authors}
            categories={categories}
            setLoading={setLoading}
          />
        );
      case 1:
        return (
          <RelatedContentsGroup
            refresh={() => setReqCount((x) => x + 1)}
            onClose={onClose}
            onBack={() => setActive(0)}
            contentId={blog.blog_id}
            isEditable={isFirst || isEditable}
            setLoading={setLoading}
            contentType="BLOG"
            relatedContentList={relatedContents}
            showDesc={true}
          />
        );
      default:
        return <h5>No step found</h5>;
    }
  };
  const onClose = () => {
    setOpen(false);
    setActive(0);
    setBlog({});
    refresh();
  };

  const onChangeStep = (e) => {
    if (e === 1 && !blog.blog_id) return;
    setActive(e);
  };

  return (
    <Modal
      toggle={onClose}
      isOpen={isOpen}
      size="lg"
      keyboard={false}
      modalClassName="modal-fullscreen modal-dialog-scrollable"
    >
      <div className="modal-header">
        <Steps
          type="navigation"
          current={activeKey}
          onChange={onChangeStep}
          className="site-navigation-steps"
        >
          <Step
            status={blog.blog_id ? "finish" : "process"}
            title={blog.blog_id ? "Blog" : "Create Blog"}
          />
          <Step
            status={
              relatedContents.length > 0
                ? "finish"
                : blog.blog_id
                ? "process"
                : "wait"
            }
            disabled={!blog.blog_id}
            title="Related Content"
          />
        </Steps>
      </div>
      <ModalBody>
        {(loading || relatedContentLoading) && <CustomLoader />}
        {renderSteps(activeKey)}
      </ModalBody>
    </Modal>
  );
}
