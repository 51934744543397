import React, { useEffect, useState } from "react";
import graphHandler from "../../../Apollo/graphHandler";
import CustomLoader from "../../../components/Loader";
import useScreener from "../../../utils/CustomHooks/useScreener";
import EditScreenerLeft from "./EditScreenerLeft";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { UPDATE_SCREENER } from "../../../Apollo/AdminServices/MutationRequest";

export default function ScreenerInfo({ canUpdate, setCanUpdate, screener_id }) {
  const { screener, loading, getScreener } = useScreener("");
  const [editable, setEditable] = useState(false);
  const formik = useFormik({
    initialValues: {},
    validationSchema,
    onSubmit: (value) => onSubmit(value),
  });
  useEffect(() => {
    screener_id && getScreener(screener_id);
  }, [screener_id]);

  useEffect(() => {
    formik.resetForm();
    setValuesScreener();
  }, [editable]);

  useEffect(() => {
    setValuesScreener();
  }, [screener]);

  const setValuesScreener = () => {
    if (
      screener &&
      screener.screener_name &&
      screener.screener_result_calculation_type &&
      screener.screener_time
    ) {
      const splitTime = screener.screener_time.split(" ");
      formik.setValues({
        screener_timeNo: Number(splitTime[0]) ? splitTime[0] : "1",
        screener_time_unit: splitTime[1] ? splitTime[1] : "Mins",
        screener_result_calculation_type:
          screener.screener_result_calculation_type,
        screener_meta: screener.screener_meta
          ? [...screener.screener_meta.map((x) => x)]
          : [],
      });
    }
  };

  const addOption = (i) => {
    const screener_meta = [...formik.values.screener_meta.map((x) => x)];
    if (
      !screener_meta[i].type ||
      !screener_meta[i].key ||
      !screener_meta[i].value
    )
      return;
    formik.setFieldValue("screener_meta", [
      ...screener_meta,
      { ...initialMeta },
    ]);
  };

  const removeOption = (i, name = "screener_meta") => {
    const value = [...formik.values[name].map((x) => x)];
    value.splice(i, 1);
    formik.setFieldValue(name, value);
  };
  const updateScreener = async (update_request) => {
    try {
      const { data } = await graphHandler().invoke(
        UPDATE_SCREENER,
        {
          update_request,
        },
        1,
        false
      );
      if (data && data.updateScreener && data.updateScreener.screener) {
        // console.log("updateScreener Success", data.updateScreener.screener);
        getScreener(screener_id);
        setEditable(false);
        toast.success("Updated Successfully. Approve before updating again.");
        //   refresh();
      }
      if (data && data.updateScreener && data.updateScreener.screener_history) {
        setCanUpdate(false);
      }
    } catch (error) {
      console.log("updateScreener Error", error);
    }
  };

  const onSubmit = (values) => {
    let req = { ...values, screener_id };
    Object.keys(req).forEach((key) => {
      if (!req[key]) delete req[key];
    });
    req.screener_time =
      values.screener_timeNo + " " + values.screener_time_unit;
    delete req.screener_timeNo;
    delete req.screener_time_unit;

    let description = [];
    if (req.screener_description) {
      description.push({
        client_type: "Web",
        description: req.screener_description,
      });
    }
    if (req.mobile_description) {
      description.push({
        client_type: "Mobile",
        description: req.mobile_description,
      });
      delete req.mobile_description;
    }
    if (description.length > 0) req.screener_description = description;
    updateScreener(req);
  };

  if (loading) {
    return <CustomLoader />;
  }
  //   console.log(screener);
  return (
    <>
      <EditScreenerLeft
        screener={screener}
        formik={formik}
        editable={editable}
      />
      <div className="col-md-6   mb-3 text-right">
        <div className="content2">
          <div className="average d-flex justify-content-end">
            {canUpdate && (
              <button id="edit_content" className="btn-edit-screener">
                <i
                  className="fas fa-edit"
                  onClick={() => setEditable(!editable)}
                />
              </button>
            )}
            {!editable ? (
              <p className="mb-0">
                {screener.screener_result_calculation_type
                  ? screener.screener_result_calculation_type
                  : ""}
              </p>
            ) : (
              <div className=" form-group">
                <select
                  name="screener_result_calculation_type"
                  className="custom-select"
                  id="screener_result_calculation_type"
                  onChange={formik.handleChange}
                  value={
                    formik.values &&
                    formik.values.screener_result_calculation_type
                      ? formik.values.screener_result_calculation_type
                      : ""
                  }
                  onBlur={formik.handleBlur}
                >
                  <option value="" hidden>
                    Select Type
                  </option>
                  <option value="Average">Average</option>
                  <option value="Total">Total</option>
                </select>
              </div>
            )}
          </div>
          <p className="mb-0">
            {!editable ? (
              <>
                <i className="fas fa-clock" />{" "}
                <span>
                  {screener.screener_time ? screener.screener_time : ""}
                </span>
              </>
            ) : (
              <div className="row">
                <div className="col-md-6"></div>
                <input
                  type="text"
                  className="col-md-2"
                  name="screener_timeNo"
                  id="screener_timeNo"
                  onChange={(e) =>
                    formik.setFieldValue(
                      "screener_timeNo",
                      e.target.value.replace(/[^0-9\.]/g, "")
                    )
                  }
                  value={formik.values.screener_timeNo}
                  placeholder="Time"
                />
                <select
                  name="screener_time_unit"
                  id="screener_time_unit"
                  onChange={formik.handleChange}
                  value={formik.values.screener_time_unit}
                  className="col-md-4"
                >
                  <option value="" hidden>
                    Select Unit
                  </option>
                  <option value="Mins">Mins</option>
                  <option value="Hrs">Hrs</option>
                </select>
              </div>
            )}
          </p>
          <p className="mb-0">
            {!editable ? (
              <>
                <span>
                  {screener.screener_meta && screener.screener_meta.length > 0
                    ? screener.screener_meta.map((x, i) => (
                        <span key={i}>
                          <b>{x.type}</b>: {x.key} - {x.value}
                          <br />
                        </span>
                      ))
                    : ""}
                </span>
              </>
            ) : (
              <div className="row mt-3">
                {formik.values.screener_meta &&
                formik.values.screener_meta.length > 0
                  ? formik.values.screener_meta.map((x, i) => (
                      <div
                        key={i}
                        className="d-flex p-0 flex-row w-100 align-items-start justify-content-start row"
                      >
                        <div className="col-md-4 p-0 form-group">
                          <label
                            htmlFor={x.type + i}
                            className="col-form-label w-100 mb-2"
                          >
                            Type
                          </label>
                          <select
                            name={`screener_meta.${i}.type`}
                            className="custom-select"
                            id={x.type + i}
                            onChange={formik.handleChange}
                            value={x.type ? x.type : ""}
                          >
                            <option value="" hidden>
                              Select Type
                            </option>
                            <option value="Params">Params</option>
                            <option value="Instruction">Instruction</option>
                          </select>
                        </div>
                        <div className="col-md-4 p-0 form-group">
                          <label
                            htmlFor={x.key + i}
                            className="col-form-label w-100 mb-2"
                          >
                            Key
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name={`screener_meta.${i}.key`}
                            id={x.key + i}
                            onChange={formik.handleChange}
                            placeholder=""
                            value={x.key ? x.key : ""}
                          />
                          <div className="col-md-4"></div>
                        </div>

                        <div className="col-md-3 p-0 form-group">
                          <label
                            htmlFor={x.value + i}
                            className="col-form-label w-100 mb-2"
                          >
                            Value
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name={`screener_meta.${i}.value`}
                            id={x.value + i}
                            onChange={formik.handleChange}
                            placeholder=""
                            value={x.value ? x.value : ""}
                          />
                        </div>
                        <div className="col-md-1 p-0 d-flex align-items-center h-85p">
                          {x.type &&
                            x.key &&
                            x.value &&
                            i === formik.values.screener_meta.length - 1 && (
                              <i
                                className="fas fa-plus ml-2"
                                onClick={() => addOption(i)}
                                style={{ color: "#5e6c84" }}
                              />
                            )}
                          {formik.values.screener_meta.length != 0 && (
                            <i
                              className="fa fa-minus text-danger ml-2"
                              onClick={() => removeOption(i, "screener_meta")}
                              style={{ color: "#5e6c84" }}
                            />
                          )}
                        </div>
                      </div>
                    ))
                  : null}
              </div>
            )}
          </p>
        </div>
        {editable && (
          <div>
            <button
              className="btn btn-primary btn-outline-success btn-sm btn-add-domain mt-2"
              disabled={Object.keys(formik.errors).length !== 0}
              onClick={formik.handleSubmit}
            >
              Save
            </button>
            {/* <button className="btn btn-primary btn-outline-success btn-sm btn-add-domain mt-2 ml-2">
              Cancel
            </button> */}
          </div>
        )}
      </div>
    </>
  );
}

const validationSchema = Yup.object().shape({
  screener_description: Yup.string(),
  // .min(4, "Description should be min of 4 characters")
  // .max(150, "Description should be maximum of 150 characters"),
  screener_name: Yup.string(),
  mobile_description: Yup.string(),
  // .min(4, "Name should be min of 4 characters")
  // .max(30, "Name should be maximum of 30 characters"),
  screener_result_calculation_type: Yup.string(),
  screener_timeNo: Yup.string().required("Enter a screener time"),
  screener_time_unit: Yup.string().required("Select a screener time unit"),
  screener_meta: Yup.array().of(
    Yup.object().shape({
      key: Yup.string().required(),
      type: Yup.string().required(),
      value: Yup.string().required(),
    })
  ),
});

const initialMeta = {
  type: "",
  key: "",
  value: "",
};
