import { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import graphHandler from "../../../Apollo/graphHandler";
import {
  isInputNumber,
  //   isMobileNumber,
} from "../../../utils/Functions/filter";
import { CREATE_BLOG_GQL } from "../../../Apollo/AdminServices/MutationRequest";
import MultiSelect from "../../../components/MultiSelect/MultiSelect";
import Description from "../../../components/Input/Description";
import CustomButton from "../../../components/Button/CustomButton";
import useInterventionList from "../../../utils/CustomHooks/useInterventionList";
import MetaInput from "../../../components/MetaInput";

export default function AddBlogModal({
  refresh = () => {},
  setLoading = () => {},
  onClose = () => {},
  isOpen = { isOpen },
  setBlog,
  subDomainList = [],
  categories = [],
  authors = [],
  // blog,
}) {
  const [interventions, setInterventions] = useState([]);
  // const [isContentOpen, setContentOpen] = useState(false);
  // const [preview, setPreview] = useState(false);
  const { interventionList, getInterventionList, resetIntervention } =
    useInterventionList("");
  const [type, setIsVideo] = useState(false);
  // console.log("subDomainList1", subDomainList);

  const formik = useFormik({
    initialValues: {
      sub_domain_ids: [],
      blog_type: "Default",
    },
    validationSchema: type ? videoValidation : validationSchema,
    onSubmit: (values) => {
      onSubmit(values);
    },
  });

  useEffect(() => {
    setIsVideo(formik.values.blog_type === "Video");
  }, [formik.values.blog_type]);

  useEffect(() => {
    formik.values.blog_type && formik.validateForm();
  }, [type]);

  useEffect(() => {
    setInterventions(
      interventionList.map((x) => ({
        label: x.intervention_name,
        value: x.intervention_id,
      }))
    );
  }, [interventionList]);

  useEffect(() => {
    if (
      formik.values.sub_domain_ids &&
      formik.values.sub_domain_ids.length &&
      !type
    ) {
      var sub_domain_ids = formik.values.sub_domain_ids.map((x) => x.value);
      getInterventionList({ sub_domain_ids, published: true, active: true });
    } else {
      formik.setFieldValue("intervention_ids", []);
      resetIntervention([]);
    }
  }, [formik.values.sub_domain_ids, type]);

  const onClosed = () => {
    onClose();
    formik.resetForm();
    formik.setValues({
      sub_domain_ids: [],
      blog_type: "Default",
    });
    // setOpen();
    // resetIntervention();
  };

  const onSubmit = async (value) => {
    const {
      sub_domain_ids,
      blog_content,
      blog_name,
      blog_type,
      blog_descriptions,
      // image,
      // mobileImage,
      mobile_description,
      intervention_ids,
      category_ids,
      read_duration,
      author_ids,
      sort_order,
      blog_meta,
      blog_code,
      alias_name,
    } = value;
    let create_request = {
      blog_type,
      blog_name,
      alias_name,
      blog_code,
      sub_domain_ids: [...sub_domain_ids.map((x) => x.value)],
    };
    if (category_ids) {
      create_request.category_ids = [...category_ids.map((x) => x.value)];
    }
    if (read_duration)
      create_request.read_duration = Number(read_duration) * 60;
    // if (!type && (!blog_content || blog_content.length === 0))
    //   return toast.error("Add blog content");
    if (intervention_ids && intervention_ids.length > 0)
      create_request.intervention_ids = [
        ...intervention_ids.map((x) => x.value),
      ];
    if (!type) {
      if (blog_content && blog_content.length > 0) {
        create_request.blog_contents = [
          ...blog_content.map((x) => {
            let obj = {
              sort_order: Number(x.sort_order),
              blog_content_type: x.blog_content_type,
            };
            if (x.title) obj.title = x.title;
            if (x.content) obj.content = x.content;
            if (x.image_urls) obj.image_urls = x.image_urls;
            return obj;
          }),
        ];
      }
    } else {
      create_request.sort_order = Number(sort_order);
    }
    let description = [];
    if (blog_descriptions) {
      description.push({ client_type: "Web", description: blog_descriptions });
    }
    if (mobile_description) {
      description.push({
        client_type: "Mobile",
        description: mobile_description,
      });
    }
    if (description.length > 0) create_request.blog_descriptions = description;
    if (blog_meta && blog_meta.length > 0) {
      create_request.blog_meta = blog_meta;
    }
    if (author_ids && author_ids.length > 0) {
      create_request.author_ids = author_ids.map((x) => x.value);
    }
    setLoading(true);
    // console.log("create_request", create_request);
    try {
      const { data } = await graphHandler().invoke(
        CREATE_BLOG_GQL,
        { create_request },
        1,
        true
      );
      if (data && data.createBlog && data.createBlog.blog) {
        setBlog({
          ...data.createBlog.blog,
          pending_history: data.createBlog.blog_history,
        });
      }
      // onClosed();
      refresh();
      setLoading(false);
      formik.setSubmitting(false);
    } catch (error) {
      setLoading(false);
      console.log("createBlog error", error);
      formik.setSubmitting(false);
    }
  };

  const handleError = () => {
    if (Object.keys(formik.errors).length > 0) {
      toast.error(formik.errors[Object.keys(formik.errors)[0]]);
    }
  };
  const handleAmount = (e) => {
    const { value, name } = e.target;
    let filter = value.replace(/[^0-9.]/g, "").replace(/-/g, "");
    formik.setFieldValue(
      name,
      filter.indexOf(".") >= 0
        ? filter.substr(0, filter.indexOf(".")) +
            filter.substr(filter.indexOf("."), 3)
        : filter
    );
  };
  return (
    <div className="container py-5 position-relative">
      <div className="intervention-form row justify-content-center ">
        <div className="col-md-6">
          <div className="row">
            <div className="col-md-12 form-group">
              <label
                htmlFor="sub_domain_ids"
                className="col-form-label w-100 mb-2"
              >
                Sub Domain
              </label>
              <MultiSelect
                option={subDomainList}
                onChange={(e) => formik.setFieldValue("sub_domain_ids", e)}
                placeholder="Select Sub Domain"
                isCreatable={false}
                isMulti={true}
                type="Sub Domain"
                name="sub_domain_ids"
                id="sub_domain_ids"
                onBlur={formik.handleBlur}
                defaultValue={formik.values.sub_domain_ids}
              />
              <small className="text-danger">
                {formik.touched.sub_domain_ids &&
                formik.errors &&
                formik.errors.sub_domain_ids
                  ? formik.errors.sub_domain_ids
                  : ""}
              </small>
            </div>

            <div className="col-md-12 form-group">
              <label htmlFor="blog_code" className="col-form-label w-100 mb-2">
                Code
              </label>
              <input
                type="text"
                className="form-control"
                id="blog_code"
                name="blog_code"
                onChange={formik.handleChange}
                value={formik.values.blog_code ? formik.values.blog_code : ""}
                onBlur={formik.handleBlur}
              />
              <small className="text-danger align-self-end">
                {formik.touched.blog_code &&
                formik.errors &&
                formik.errors.blog_code
                  ? formik.errors.blog_code
                  : ""}
              </small>
            </div>
            <div className="col-md-12 form-group">
              <label
                htmlFor="category_ids"
                className="col-form-label w-100 mb-2"
              >
                Category
              </label>
              <MultiSelect
                option={categories}
                onChange={(e) => formik.setFieldValue("category_ids", e)}
                placeholder="Select "
                isCreatable={false}
                isMulti={true}
                type="Category"
                name="category_ids"
                id="category_ids"
                onBlur={formik.handleBlur}
                defaultValue={formik.values.category_ids}
              />
              <small className="text-danger">
                {formik.touched.category_ids &&
                formik.errors &&
                formik.errors.category_ids
                  ? formik.errors.category_ids
                  : ""}
              </small>
            </div>
            {formik.values.sub_domain_ids &&
              formik.values.sub_domain_ids.length > 0 &&
              !type && (
                <div className="col-md-12 form-group">
                  <label
                    htmlFor="intervention_ids"
                    className="col-form-label w-100 mb-2"
                  >
                    Intervention
                  </label>
                  <MultiSelect
                    option={interventions}
                    onChange={(e) =>
                      formik.setFieldValue("intervention_ids", e)
                    }
                    placeholder="Select Intervention"
                    isCreatable={false}
                    isMulti={true}
                    type="Intervention"
                    name="intervention_ids"
                    id="intervention_ids"
                    onBlur={formik.handleBlur}
                    defaultValue={formik.values.intervention_ids}
                  />
                  <small className="text-danger">
                    {formik.touched.intervention_ids &&
                    formik.errors &&
                    formik.errors.intervention_ids
                      ? formik.errors.intervention_ids
                      : ""}
                  </small>
                </div>
              )}

            <div className="col-md-12 form-group">
              <label htmlFor="author_ids" className="col-form-label w-100 mb-2">
                Author
              </label>
              <MultiSelect
                option={authors}
                onChange={(e) => formik.setFieldValue("author_ids", e)}
                placeholder="Select Author"
                isCreatable={false}
                isMulti={true}
                type="Author"
                name="author_ids"
                id="author_ids"
                onBlur={formik.handleBlur}
                defaultValue={formik.values.author_ids}
              />
              <small className="text-danger">
                {formik.touched.author_ids &&
                formik.errors &&
                formik.errors.author_ids
                  ? formik.errors.author_ids
                  : ""}
              </small>
            </div>
            <div className="col-md-12">
              <div className="row">
                <div className={`${type ? "col-md-4" : "col-md-6"} form-group`}>
                  <label
                    htmlFor="read_duration"
                    className="col-form-label w-100 mb-2"
                  >
                    Read Duration(in Mins)
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder=""
                    id="read_duration"
                    name="read_duration"
                    onKeyPress={isInputNumber}
                    maxLength={10}
                    value={
                      formik.values && formik.values.read_duration
                        ? formik.values.read_duration
                        : ""
                    }
                    onBlur={formik.handleBlur}
                    onChange={handleAmount}
                  />
                  <small className="text-danger ">
                    {formik.touched.read_duration &&
                    formik.errors &&
                    formik.errors.read_duration
                      ? formik.errors.read_duration
                      : ""}
                  </small>
                </div>
                {type ? (
                  <div className="col-md-4 form-group">
                    <label
                      htmlFor="sort_order"
                      className="col-form-label w-100 mb-2"
                    >
                      Sort Order
                    </label>
                    <div className="d-flex flex-column p-0">
                      <input
                        type="number"
                        className="form-control h-100 w-100"
                        placeholder=""
                        id="sort_order"
                        name="sort_order"
                        max={100}
                        value={
                          formik.values && formik.values.sort_order
                            ? formik.values.sort_order
                            : ""
                        }
                        onBlur={formik.handleBlur}
                        onChange={(e) =>
                          formik.setFieldValue(
                            "sort_order",
                            e.target.value.replace(/[^0-9\.]/g, "")
                          )
                        }
                      />
                      <small className="text-danger">
                        {formik.touched.sort_order && formik.errors.sort_order
                          ? formik.errors.sort_order
                          : ""}
                      </small>
                    </div>
                  </div>
                ) : null}
                <div className={`${type ? "col-md-4" : "col-md-6"} form-group`}>
                  <label
                    htmlFor="blog_type"
                    className="col-form-label w-100 mb-2"
                  >
                    Type
                  </label>
                  <select
                    className="custom-select"
                    name="blog_type"
                    id="blog_type"
                    onChange={formik.handleChange}
                    value={
                      formik.values.blog_type ? formik.values.blog_type : ""
                    }
                    onBlur={formik.handleBlur}
                  >
                    <option hidden value="">
                      Select
                    </option>
                    <option value="Default">Default</option>
                    <option value="Video">Video</option>
                  </select>
                  <small className="text-danger ">
                    {formik.touched.blog_type &&
                    formik.errors &&
                    formik.errors.blog_type
                      ? formik.errors.blog_type
                      : ""}
                  </small>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="row">
            <div className="col-md-12 form-group">
              <label htmlFor="blog_name" className="col-form-label w-100 mb-2">
                Name
              </label>
              <input
                type="text"
                className="form-control"
                id="blog_name"
                name="blog_name"
                onChange={formik.handleChange}
                value={formik.values.blog_name ? formik.values.blog_name : ""}
                onBlur={formik.handleBlur}
              />
              <small className="text-danger align-self-end">
                {formik.touched.blog_name &&
                formik.errors &&
                formik.errors.blog_name
                  ? formik.errors.blog_name
                  : ""}
              </small>
            </div>
            <div className="col-md-12 form-group">
              <label htmlFor="alias_name" className="col-form-label w-100 mb-2">
                Alias Name
              </label>
              <input
                type="text"
                className="form-control"
                id="alias_name"
                name="alias_name"
                onChange={(e)=>formik.setFieldValue("alias_name", e.target.value.trimStart())}
                value={formik.values.alias_name ? formik.values.alias_name : ""}
                // onBlur={formik.handleBlur}
                onBlur={(e)=>{
                  formik.setFieldTouched("alias_name", true)
                  formik.setFieldValue("alias_name", e.target.value.replace(/\s+/g,' ').trim())
                }}
              />
              <small className="text-danger align-self-end">
                {formik.touched.alias_name &&
                formik.errors &&
                formik.errors.alias_name
                  ? formik.errors.alias_name
                  : ""}
              </small>
            </div>
            <Description
              formik={formik}
              webName="blog_descriptions"
              col="col-md-12"
              showEditor={true}
            />
          </div>
        </div>
        <div className="col-md-12">
          <MetaInput
            formik={formik}
            parentArray="blog_meta"
            typeKey="type"
            titleKey="key"
            valueKey="value"
          />
        </div>
        <div className="d-flex justify-content-end col-md-12 mt-3">
          <button
            onClick={onClosed}
            type="button"
            className="btn btn-danger btn-sm float-right btn-add-domain mr-2"
          >
            Close
          </button>
          <CustomButton
            type="button"
            className="btn btn-primary btn-outline-success float-right btn-add-domain"
            disabled={
              Object.keys(formik.errors).length !== 0 ||
              !formik.isValid ||
              formik.isSubmitting
            }
            onClick={
              formik.isValid && Object.keys(formik.values).length !== 0
                ? formik.handleSubmit
                : handleError
            }
          >
            Add
          </CustomButton>
        </div>
      </div>
    </div>
  );
}

const validationSchema = Yup.object().shape({
  sub_domain_ids: Yup.array()
    .of(
      Yup.object().shape({
        label: Yup.string(),
        value: Yup.string(),
      })
    )
    .test({
      message: "Select a sub domain",
      test: (arr) => {
        return arr && arr.length > 0;
      },
    }),
  intervention_ids: Yup.array().of(
    Yup.object().shape({
      label: Yup.string(),
      value: Yup.string(),
    })
  ),
  blog_type: Yup.string().required("Select type"),
  blog_name: Yup.string().required("Enter name"),
  alias_name: Yup.string(),
  blog_code: Yup.string().required("Enter code"),
  mobile_description: Yup.string().required("Enter a mobile description"),
  blog_descriptions: Yup.string().required("Enter description"),

  category_ids: Yup.array()
    .of(Yup.object())
    .test({
      message: "Select a category",
      test: (arr) => {
        return arr && arr.length > 0;
      },
    }),
  read_duration: Yup.string().required("Enter a read duration"),
  author_ids: Yup.array().of(
    Yup.object().shape({
      label: Yup.string(),
      value: Yup.string(),
    })
  ),
  blog_meta: Yup.array().of(
    Yup.object().shape({
      type: Yup.string().required("Select a data type"),
      key: Yup.string().trim().required("Enter a title"),
      value: Yup.string().trim().required("Enter a value"),
    })
  ),
});

const videoValidation = Yup.object().shape({
  sort_order: Yup.string().required("Enter sort order"),
  alias_name: Yup.string(),
  sub_domain_ids: Yup.array()
    .of(
      Yup.object().shape({
        label: Yup.string(),
        value: Yup.string(),
      })
    )
    .test({
      message: "Select a sub domain",
      test: (arr) => {
        return arr && arr.length > 0;
      },
    }),
  blog_meta: Yup.array().of(
    Yup.object().shape({
      type: Yup.string().required("Select a data type"),
      key: Yup.string().trim().required("Enter a title"),
      value: Yup.string().trim().required("Enter a value"),
    })
  ),
  blog_code: Yup.string().required("Enter code"),
  intervention_ids: Yup.array().of(
    Yup.object().shape({
      label: Yup.string(),
      value: Yup.string(),
    })
  ),
  blog_type: Yup.string().required("Select type"),
  blog_name: Yup.string().required("Enter name"),
  // .min(4, "Name should be min of 4 characters")
  // .max(30, "Name should be maximum of 30 characters")
  mobile_description: Yup.string().required("Enter a mobile description"),
  blog_descriptions: Yup.string().required("Enter description"),
  // .min(4, "Description should be min of 4 characters")
  // .max(150, "Description should be maximum of 150 characters")
  category_ids: Yup.array()
    .of(Yup.object())
    .test({
      message: "Select a category",
      test: (arr) => {
        return arr && arr.length > 0;
      },
    }),
  read_duration: Yup.string().required("Enter a read duration"),

  author_ids: Yup.array().of(
    Yup.object().shape({
      label: Yup.string(),
      value: Yup.string(),
    })
  ),
});
