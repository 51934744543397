// import { lazy } from "react";

import Login from "../Pages/Auth/Login";
import NotFound from "./ErrorPage";
import Home from "../Pages/Home";
import UserDetails from "../Pages/UserDetails";
import Domain from "../Pages/Domain";
import SubDomain from "../Pages/SubDomain";
// import Tags from "../Pages/Tags";
import Intervention from "../Pages/Intervention";
import TherapyDetails from "../Pages/TherapyDetails";
import Therapy from "../Pages/Therapy";
import Screener from "../Pages/Screener";
import ScreenerDetails from "../Pages/ScreenerDetails";
import Explainer from "../Pages/Explainer";
import Blog from "../Pages/Blog";
import Localize from "../Pages/Localize";
import category from "../Pages/Category";
import Plans from "../Pages/Plans";
// import Questionaire from "../Pages/Questionaire";
import ViewHistory from "../Pages/ViewHistory";
import Author from "../Pages/Author";
import FAQ from "../Pages/Faq";
import Policy from "../Pages/Policy";
import Profile from "../Pages/Profile";
import { Redirect } from "react-router-dom";
import country from "../Pages/Country";
import city from "../Pages/City";
import timeZone from "../Pages/TimeZone";
import Education from "../Pages/Education";
import Industry from "../Pages/Industry";
import Function from "../Pages/Function";
import Language from "../Pages/Language";
import Game from "../Pages/Game";
import Organization from "../Pages/Organization";
import OrganizationEmployee from "../Pages/OrganizationEmployee";
import OrganizationTransaction from "../Pages/OrganizationTransaction";
import License from "../Pages/License";
import PlatformContent from "../Pages/PlatformContent";
// import Objectives from "../Pages/Objectives";
import RuleEngine from "../Pages/RuleEngine";
import MailTemplate from "../Pages/MailTemplate";
import BannerSystem from "../Pages/BannerSystem";

const Routes = [
  {
    path: "/login",
    exact: true,
    name: "login",
    component: Login,
    type: "PUBLIC",
  },
  {
    path: "/home",
    exact: true,
    name: "Home",
    component: Home,
    type: "PRIVATE",
  },
  {
    path: "/domain",
    exact: true,
    name: "Domain",
    component: Domain,
    type: "PRIVATE",
  },
  {
    path: "/author",
    exact: true,
    name: "Author",
    component: Author,
    type: "PRIVATE",
  },
  {
    path: "/profile",
    exact: true,
    name: "Profile",
    component: Profile,
    type: "PRIVATE",
  },
  {
    path: "/category",
    exact: true,
    name: "category",
    component: category,
    type: "PRIVATE",
  },
  {
    path: "/Plans",
    exact: true,
    name: "Plans",
    component: Plans,
    type: "PRIVATE",
  },
  {
    path: "/platform",
    exact: true,
    name: "Platform",
    component: PlatformContent,
    type: "PRIVATE",
  },
  //   {
  //     path: "/objective",
  //     exact: true,
  //     name: "Objectives",
  //     component: Objectives,
  //     type: "PRIVATE",
  //   },
  {
    path: "/rule-engine",
    exact: true,
    name: "RuleEngine",
    component: RuleEngine,
    type: "PRIVATE",
  },
  {
    path: "/template",
    exact: true,
    name: "MailTemplate",
    component: MailTemplate,
    type: "PRIVATE",
  },
  {
    path: "/banner",
    exact: true,
    name: "Banner",
    component: BannerSystem,
    type: "PRIVATE",
  },
  {
    path: "/sub-domain",
    exact: true,
    name: "SubDomain",
    component: SubDomain,
    type: "PRIVATE",
  },
  //   {
  //     path: "/tags",
  //     exact: true,
  //     name: "Tags",
  //     component: Tags,
  //     type: "PRIVATE",
  //   },
  {
    path: "/localize",
    exact: true,
    name: "Localize",
    component: Localize,
    type: "PRIVATE",
  },
  {
    path: "/intervention",
    exact: true,
    name: "Intervention",
    component: Intervention,
    type: "PRIVATE",
  },
  {
    path: "/therapy-details",
    exact: true,
    name: "TherapyDetails",
    component: TherapyDetails,
    type: "PRIVATE",
  },
  {
    path: "/therapy",
    exact: true,
    name: "Therapy",
    component: Therapy,
    type: "PRIVATE",
  },
  {
    path: "/organization",
    exact: true,
    name: "Organization",
    component: Organization,
    type: "PRIVATE",
  },
  {
    path: "/org-employee",
    exact: true,
    name: "OrganizationEmployee",
    component: OrganizationEmployee,
    type: "PRIVATE",
  },
  {
    path: "/transaction",
    exact: true,
    name: "OrganizationTransaction",
    component: OrganizationTransaction,
    type: "PRIVATE",
  },
  {
    path: "/license",
    exact: true,
    name: "License",
    component: License,
    type: "PRIVATE",
  },
  //   {
  //     path: "/questionaire",
  //     exact: true,
  //     name: "Questionaire",
  //     component: Questionaire,
  //     type: "PRIVATE",
  //   },
  {
    path: "/user-details",
    exact: true,
    name: "UserDetails",
    component: UserDetails,
    type: "PRIVATE",
  },
  {
    path: "/screener",
    exact: true,
    name: "Screener",
    component: Screener,
    type: "PRIVATE",
  },
  {
    path: "/explainer",
    exact: true,
    name: "Explainer",
    component: Explainer,
    type: "PRIVATE",
  },
  {
    path: "/blog",
    exact: true,
    name: "Blog",
    component: Blog,
    type: "PRIVATE",
  },
  {
    path: "/screener-details",
    exact: true,
    name: "ScreenerDetails",
    component: ScreenerDetails,
    type: "PRIVATE",
  },
  {
    path: "/view-history",
    name: "ViewHistory",
    component: ViewHistory,
    type: "PRIVATE",
  },
  {
    path: "/faq",
    name: "FAQ",
    component: FAQ,
    type: "PRIVATE",
  },
  {
    path: "/policy",
    name: "Policy",
    component: Policy,
    type: "PRIVATE",
  },
  {
    path: "/country",
    exact: true,
    name: "country",
    component: country,
    type: "PRIVATE",
  },
  {
    path: "/city",
    exact: true,
    name: "city",
    component: city,
    type: "PRIVATE",
  },
  {
    path: "/timeZone",
    exact: true,
    name: "timeZone",
    component: timeZone,
    type: "PRIVATE",
  },
  {
    path: "/education",
    exact: true,
    name: "education",
    component: Education,
    type: "PRIVATE",
  },
  {
    path: "/game",
    exact: true,
    name: "Game",
    component: Game,
    type: "PRIVATE",
  },
  {
    path: "/industry",
    exact: true,
    name: "industry",
    component: Industry,
    type: "PRIVATE",
  },
  {
    path: "/function",
    exact: true,
    name: "function",
    component: Function,
    type: "PRIVATE",
  },
  {
    path: "/language",
    exact: true,
    name: "language",
    component: Language,
    type: "PRIVATE",
  },
  {
    path: "/",
    exact: true,
    render: () => <Redirect to="/home" />,
    // component: Home,
    type: "PRIVATE",
  },
  {
    path: "*",
    component: NotFound,
    type: "PUBLIC",
  },
];
export default Routes;
