import { useEffect, useState } from "react";
import { GET_RESULT_RANGE_LIST_GQL } from "../../Apollo/AdminServices/QueryRequest";
import graphHandler from "../../Apollo/graphHandler";
import { sortArray } from "../Functions/filter";
function useResultRangeList(id) {
  const [resultRangeList, setResultRange] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (id) {
      getResultRangeList(id);
    } else {
      setResultRange([]);
    }
  }, [id]);
  const getResultRangeList = async (req = {}) => {
    setLoading(true);
    try {
      const { data } = await graphHandler().invoke(
        GET_RESULT_RANGE_LIST_GQL,
        req,
        1,
        false,
        true
      );
      if (
        data &&
        data.getScreenerResultRangeList &&
        data.getScreenerResultRangeList.screener_result_ranges &&
        data.getScreenerResultRangeList.screener_result_ranges.length > 0
      ) {
        // console.log(
        //   "getResultRangeList Success",
        //   data.getScreenerResultRangeList.screener_result_ranges
        // );
        const struct = data.getScreenerResultRangeList.screener_result_ranges
          .slice()
          .map((x) => ({
            ...x.screener_result_range,
            pending_history: x.pending_screener_result_range_history,
          }));
        setResultRange([]);
        setResultRange(sortArray(struct));
        setLoading(false);
      } else {
        setResultRange([]);
        setLoading(false);
      }
    } catch (error) {
      console.log("getResultRangeList Error", error);
      setResultRange([]);
      setLoading(false);
    }
  };
  return {
    resultRangeList,
    loading,
    getResultRangeList,
    reset: () => setResultRange([]),
  };
}
export default useResultRangeList;
