import { Modal, ModalBody } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import graphHandler from "../../../Apollo/graphHandler";
import {
  CREATE_FAQ_GQL,
  UPDATE_FAQ_GQL,
} from "../../../Apollo/AdminServices/MutationRequest";
import Description from "../../../components/Input/Description";
import CustomButton from "../../../components/Button/CustomButton";
import useFAQCategoryList from "../../../utils/CustomHooks/useFAQCategoryList";
import { useEffect, useState } from "react";
import MultiSelect from "../../../components/MultiSelect/MultiSelect";
import { getDescription } from "../../../utils/Functions/filter";
import { FaqCategoryTypes } from "../../../constants";

const validation = Yup.object().shape({
  faq_category_name: Yup.object().shape({}).required("Select a category"),
  faq_category_type: Yup.string().required("Select a category type"),
  // .min(4, "Domain name should be min of 4 characters")
  // .max(30, "Domain name should be maximum of 30 characters")
  question: Yup.string().required("Enter question"),
  mobile_question: Yup.string(),
  answer: Yup.string().required("Enter answer"),
  mobile_answer: Yup.string(),
});

export default function FAQModal({
  isOpen,
  setOpen,
  refresh,
  initialValues = {},
}) {
  const { faqCategoryList, getFaqCategoryList, addCategory } =
    useFAQCategoryList();
  const [categories, setCategories] = useState([]);
  // console.log("faqCategoryList", faqCategoryList,categories);
  const formik = useFormik({
    initialValues: {},
    initialStatus: false,
    validationSchema: validation,
    onSubmit: (values) => {
      onSubmit(values);
    },
  });
  useEffect(() => {
    if (formik.values && formik.values.faq_category_type) {
      getFaqCategoryList({
        faq_category_type: formik.values.faq_category_type,
      });
      formik.setFieldValue("faq_category_name", undefined);
    }
  }, [formik.values.faq_category_type]);
  useEffect(() => {
    if (initialValues.faq_id) onEdit(initialValues);
  }, [initialValues, isOpen]);

  useEffect(() => {
    if (faqCategoryList.length > 0) {
      setCategories(
        faqCategoryList.map((x) => ({
          label: x.faq_category_name,
          value: x.faq_category_name,
          type: x.faq_category_type,
        }))
      );
      if (initialValues.faq_id ) {
        let cat = faqCategoryList.find(
          (x) => x.faq_category_name === initialValues.faq_category_name
        );
        if (cat && cat.faq_category_type )
          formik.setFieldValue("faq_category_type", cat.faq_category_type);
      }
    }
  }, [faqCategoryList,isOpen]);

  // useEffect(() => {
  //   if (faqCategoryList.length > 0 && initialValues.faq_id && isOpen) {
  //     let cat = faqCategoryList.find(
  //       (x) => x.faq_category_name === initialValues.faq_category_name
  //     );
  //     if (cat && cat.faq_category_type)
  //       formik.setFieldValue("faq_category_type", cat.faq_category_type);
  //   }
  // }, [isOpen, faqCategoryList, initialValues]);
  const onCreateCategory = (value, onCreateCB, onErrorCB) => {
    if (!value) return;
    // if(!formik.values.faq_category_type||!formik.values.faq_category_type.value)
    addCategory(
      {
        faq_category_name: value.trim(),
        faq_category_type: formik.values.faq_category_type,
      },
      onCreateCB,
      onErrorCB
    );
  };
  const onClose = () => {
    formik.resetForm();
    setOpen();
  };

  const onSubmit = async (values) => {
    const {
      faq_id,
      faq_category_name,
      question: questionWeb,
      mobile_question,
      answer: answerWeb,
      mobile_answer,
    } = values;
    const request = { faq_category_name: faq_category_name.label };
    let question = [];
    if (questionWeb) {
      question.push({ client_type: "Web", description: questionWeb });
    }
    if (mobile_answer && !mobile_question) {
      formik.setSubmitting(false);
      return toast.warn("please enter mobile question");
    } else if (!mobile_answer && mobile_question) {
      formik.setSubmitting(false);
      return toast.warn("please enter mobile answer");
    }

    if (mobile_question) {
      question.push({
        client_type: "Mobile",
        description: mobile_question,
      });
    }
    let answer = [];
    if (answerWeb) {
      answer.push({ client_type: "Web", description: answerWeb });
    }
    if (mobile_answer) {
      answer.push({
        client_type: "Mobile",
        description: mobile_answer,
      });
    }
    if (question.length > 0) {
      request.question = question;
    }
    if (answer.length > 0) {
      request.answer = answer;
    }
    if (faq_id) {
      updateFAQ({ ...request, faq_id });
    } else {
      createFAQ(request);
    }
  };

  const createFAQ = async (addReq = {}) => {
    try {
      await graphHandler().invoke(CREATE_FAQ_GQL, { addReq }, 1, false);
      toast.success("Added Successfully!");
      refresh();
      onClose();
      formik.setSubmitting(false);
    } catch (error) {
      console.log("createFAQ error", error);
      formik.setSubmitting(false);
    }
  };

  const updateFAQ = async (update_request) => {
    try {
      await graphHandler().invoke(UPDATE_FAQ_GQL, { update_request }, 1, false);
      toast.success("Update Successfully!");
      refresh();
      onClose();
      formik.setSubmitting(false);
    } catch (error) {
      console.log("updateFAQ error", error);
      formik.setSubmitting(false);
    }
  };

  const handleError = () => {
    if (Object.keys(formik.errors).length > 0) {
      toast.error(formik.errors[Object.keys(formik.errors)[0]]);
    }
  };

  const onEdit = (values = {}) => {
    if (values.faq_id) {
      let model = {
        faq_id: values.faq_id,
        faq_category_name: {
          label: values.faq_category_name,
          value: values.faq_category_name,
        },
      };
      if (values.faq_category_name) {
        let data = categories.find((x) => x.value === values.faq_category_name);
        model = {
          ...model,
          faq_category_type: data?.type,
        };
      }
      if (values.question && values.question.length > 0) {
        if (getDescription(values.question, "Web")) {
          model.question = getDescription(values.question, "Web").description;
        }
        if (getDescription(values.question, "Mobile")) {
          model.mobile_question = getDescription(
            values.question,
            "Mobile"
          ).description;
        }
      }
      if (values.answer && values.answer.length > 0) {
        if (getDescription(values.answer, "Web")) {
          model.answer = getDescription(values.answer, "Web").description;
        }
        if (getDescription(values.answer, "Mobile")) {
          model.mobile_answer = getDescription(
            values.answer,
            "Mobile"
          ).description;
        }
      }
      formik.setValues(model);
    }
  };
  return (
    <Modal
      toggle={onClose}
      isOpen={isOpen}
      size="lg"
      modalClassName="modal-fullscreen modal-dialog-scrollable"
    >
      <div className="modal-header">
        <button type="button" className="close" onClick={onClose}>
          <span aria-hidden="true">&times;</span>
        </button>
        <h5 className="modal-title">
          {formik.values.faq_id ? "Update" : "Create"} FAQ
        </h5>
      </div>
      <ModalBody>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-6">
              <div className="row">
                <div className="col-md-12 form-group">
                  <label
                    htmlFor="faq_category_type"
                    className="col-form-label w-100 mb-2"
                  >
                    Category Type
                  </label>
                  <select
                    id="faq_category_type"
                    name="faq_category_type"
                    className="form-control"
                    onChange={formik.handleChange}
                    value={
                      formik.values.faq_category_type
                        ? formik.values.faq_category_type
                        : ""
                    }
                    onBlur={formik.handleBlur}
                  >
                    <option key="Select" hidden>
                      Select
                    </option>
                    {FaqCategoryTypes.map((x, i) => (
                      <option value={x.value} key={i}>
                        {x.label}
                      </option>
                    ))}
                  </select>
                  <small className="text-danger">
                    {formik.touched.faq_category_type &&
                    formik.errors.faq_category_type
                      ? formik.errors.faq_category_type
                      : ""}
                  </small>
                </div>
                <Description
                  formik={formik}
                  webTitle="Question"
                  mobileTitle="Mobile Question"
                  webName="question"
                  mobileName="mobile_question"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="row">
                <div className="col-md-12 form-group">
                  <label
                    htmlFor="faq_category_name"
                    className="col-form-label w-100 mb-2"
                  >
                    Category Name
                  </label>
                  <MultiSelect
                    option={categories}
                    onChange={(e) =>
                      formik.setFieldValue("faq_category_name", e)
                    }
                    placeholder="Select"
                    isCreatable={true}
                    onCreate={onCreateCategory}
                    isMulti={false}
                    id="faq_category_name"
                    type="Category"
                    onBlur={formik.handleBlur}
                    name="faq_category_name"
                    defaultValue={formik.values.faq_category_name ?? "Reset" }
                  />
                  <small className="text-danger">
                    {formik.errors && formik.errors.faq_category_name && formik.touched.faq_category_name
                      ? formik.errors.faq_category_name.label
                        ? formik.errors.faq_category_name.label
                        : formik.errors.faq_category_name
                      : ""}
                  </small>
                </div>
                <Description
                  formik={formik}
                  webTitle="Answer"
                  mobileTitle="Mobile Answer"
                  webName="answer"
                  mobileName="mobile_answer"
                />
              </div>
            </div>
            <CustomButton
              type="button"
              className="btn btn-primary btn-outline-success float-right btn-add-domain"
              disabled={
                Object.keys(formik.errors).length !== 0 ||
                !formik.isValid ||
                formik.isSubmitting
              }
              onClick={
                formik.isValid && Object.keys(formik.values).length !== 0
                  ? formik.handleSubmit
                  : handleError
              }
            >
              {formik.values.faq_id ? "Update" : "Create"}
            </CustomButton>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
}
