import { useState } from "react";
import { ACTIVE_FAQ_GQL } from "../../Apollo/AdminServices/MutationRequest";
import { GET_FAQ_LIST_GQL } from "../../Apollo/AdminServices/QueryRequest";
import graphHandler from "../../Apollo/graphHandler";

function useFAQList() {
  const [faqList, setFaq] = useState([]);
  const [loading, setLoading] = useState(false);

  const getFaqList = async (req = {}) => {
    setLoading(true);
    try {
      const { data } = await graphHandler().invoke(
        GET_FAQ_LIST_GQL,
        { ...req },
        1,
        false,
        true
      );
      if (
        data &&
        data.getFAQList &&
        data.getFAQList.faqs &&
        data.getFAQList.faqs.length > 0
      ) {
        // console.log("getFAQList Success", data.getFAQList);
        setFaq([]);
        setFaq(data.getFAQList.faqs);
        setLoading(false);
      } else {
        setFaq([]);
        setLoading(false);
      }
    } catch (error) {
      console.log("getFAQList Error", error);
      setFaq([]);
      setLoading(false);
    }
  };

  const activateFAQ = async (req = {}, callback = () => {}) => {
    setLoading(true);
    try {
      await graphHandler().invoke(ACTIVE_FAQ_GQL, req, 1, false, true);
      //   console.log("activateFAQ Success");
      callback();
      setLoading(false);
    } catch (error) {
      console.log("activateFAQ Error", error);
      setLoading(false);
    }
  };
  return {
    faqList,
    loading,
    getFaqList,
    activateFAQ,
  };
}

export default useFAQList;