import {
  GET_BLOG_LIST_GQL,
  GET_CATEGORY_LIST_GQL,
  GET_DEFAULT_CHOICES_GQL,
  GET_DOMAIN_LIST_GQL,
  GET_FUNCTION_LIST_GQL,
  GET_INTERVENTION_LIST_GQL,
  GET_SCREENER_LIST_GQL,
  GET_SUBDOMAIN_LIST_GQL,
  GET_THERAPY_LIST_GQL,
} from "../../Apollo/AdminServices/QueryRequest";
import {
  GET_DOMAIN_LIST,
  GET_SUB_DOMAIN_LIST,
  GET_CATEGORY_LIST,
  GET_SCREENER_LIST,
  GET_INTERVENTION_LIST,
  GET_THERAPY_LIST,
  GET_BLOG_LIST,
  GET_PLAN_LIST,
  GET_OBJECTIVE_LIST,
  GET_FUNCTION_LIST,
  GET_DEFAULT_CHOICES,
} from "./types";
import graphHandler from "../../Apollo/graphHandler";
import { sortArray } from "../../utils/Functions/filter";
import { GET_PLAN_LIST_GQL } from "../../Apollo/AdminServices/NewQueryRequest";
import { GET_OBJECTIVE_LIST_GQL } from "../../Apollo/AdminServices/ObjectiveRequest";
export const rGetSubDomainList =
  (request = { active: true }) =>
  async (dispatch) => {
    try {
      const { data } = await graphHandler().invoke(
        GET_SUBDOMAIN_LIST_GQL,
        { ...request },
        1,
        false,
        true
      );
      if (
        data &&
        data.getSubDomainList &&
        data.getSubDomainList.status === "Success" &&
        data.getSubDomainList.sub_domains &&
        data.getSubDomainList.sub_domains.length
      ) {
        dispatch({
          type: GET_SUB_DOMAIN_LIST,
          payload: sortArray(data.getSubDomainList.sub_domains).map((x) => ({
            ...x,
            label: x.sub_domain_name,
            value: x.sub_domain_id,
          })),
        });
      } else {
        dispatch({
          type: GET_SUB_DOMAIN_LIST,
          payload: [],
        });
      }
    } catch (error) {
      console.log("rGetSubDomainList Error", error);
      dispatch({
        type: GET_SUB_DOMAIN_LIST,
        payload: [],
      });
    }
  };

export const rGetDomainList =
  (req = { active: true }) =>
  async (dispatch) => {
    try {
      const { data } = await graphHandler().invoke(
        GET_DOMAIN_LIST_GQL,
        { ...req },
        1,
        false,
        true
      );
      if (
        data &&
        data.getDomainList &&
        data.getDomainList.domains &&
        data.getDomainList.domains.length > 0
      ) {
        dispatch({
          type: GET_DOMAIN_LIST,
          payload: sortArray(data.getDomainList.domains).map((x) => ({
            ...x,
            label: x.domain_name,
            value: x.domain_id,
          })),
        });
      } else {
        dispatch({
          type: GET_DOMAIN_LIST,
          payload: [],
        });
      }
    } catch (error) {
      console.log("rGetDomainList Error", error);
      dispatch({
        type: GET_DOMAIN_LIST,
        payload: [],
      });
    }
  };

export const rGetCategoryList =
  (req = { active: true }) =>
  async (dispatch) => {
    try {
      let { data } = await graphHandler().invoke(
        GET_CATEGORY_LIST_GQL,
        { ...req },
        1,
        false,
        true
      );
      if (
        data &&
        data.getCategoryList &&
        data.getCategoryList.categories &&
        data.getCategoryList.categories.length > 0
      ) {
        dispatch({
          type: GET_CATEGORY_LIST,
          payload: sortArray(data.getCategoryList.categories)
            .slice()
            .map((x) => ({
              ...x,
              label: x.category_name,
              value: x.category_id,
            })),
        });
      } else {
        dispatch({
          type: GET_CATEGORY_LIST,
          payload: [],
        });
      }
    } catch (error) {
      console.log("rGetCategoryList Error", error);
      dispatch({
        type: GET_CATEGORY_LIST,
        payload: [],
      });
    }
  };

export const rGetScreenerList =
  (req = { published: true }, get_pending_update = false) =>
  async (dispatch) => {
    try {
      const { data } = await graphHandler().invoke(
        GET_SCREENER_LIST_GQL,
        { get_pending_update, ...req },
        1,
        false,
        true
      );
      if (
        data &&
        data.getScreenerList &&
        data.getScreenerList.screeners &&
        data.getScreenerList.screeners.length > 0
      ) {
        dispatch({
          type: GET_SCREENER_LIST,
          payload: sortArray(data.getScreenerList.screeners).map((x) => ({
            ...x.screener,
            label: x.screener.screener_name,
            value: x.screener.screener_id,
            pending_screener_history: x.pending_screener_history,
          })),
        });
      } else {
        dispatch({
          type: GET_SCREENER_LIST,
          payload: [],
        });
      }
    } catch (error) {
      console.log("rGetScreenerList Error", error);
      dispatch({
        type: GET_SCREENER_LIST,
        payload: [],
      });
    }
  };

export const rGetInterventionList =
  (req = { published: true }, get_pending_update = false) =>
  async (dispatch) => {
    try {
      const { data } = await graphHandler().invoke(
        GET_INTERVENTION_LIST_GQL,
        { get_pending_update, ...req },
        1,
        false,
        true
      );
      if (
        data &&
        data.getInterventionList &&
        data.getInterventionList.interventions &&
        data.getInterventionList.interventions.length > 0
      ) {
        dispatch({
          type: GET_INTERVENTION_LIST,
          payload: sortArray(data.getInterventionList.interventions).map(
            (x) => ({
              ...x.intervention,
              label: x.intervention.intervention_name,
              value: x.intervention.intervention_id,
              pending_intervention_history: x.pending_intervention_history,
            })
          ),
        });
      } else {
        dispatch({
          type: GET_INTERVENTION_LIST,
          payload: [],
        });
      }
    } catch (error) {
      console.log("rGetInterventionList Error", error);
      dispatch({
        type: GET_INTERVENTION_LIST,
        payload: [],
      });
    }
  };

export const rGetTherapyList =
  (req = { published: true }, get_pending_update = false) =>
  async (dispatch) => {
    try {
      const { data } = await graphHandler().invoke(
        GET_THERAPY_LIST_GQL,
        { get_pending_update, ...req },
        1,
        false,
        true
      );
      if (
        data &&
        data.getTherapyList &&
        data.getTherapyList.therapies &&
        data.getTherapyList.therapies.length > 0
      ) {
        dispatch({
          type: GET_THERAPY_LIST,
          payload: sortArray(data.getTherapyList.therapies).map((x) => ({
            ...x.therapy,
            label: x.therapy.therapy_name,
            value: x.therapy.therapy_id,
            pending_therapy_history: x.pending_therapy_history,
          })),
        });
      } else {
        dispatch({
          type: GET_THERAPY_LIST,
          payload: [],
        });
      }
    } catch (error) {
      console.log("rGetTherapyList Error", error);
      dispatch({
        type: GET_THERAPY_LIST,
        payload: [],
      });
    }
  };

export const rGetBlogList =
  (req = { published: true }, get_pending_update = false) =>
  async (dispatch) => {
    try {
      const { data } = await graphHandler().invoke(
        GET_BLOG_LIST_GQL,
        { get_pending_update, ...req },
        1,
        false,
        true
      );
      if (
        data &&
        data.getBlogList &&
        data.getBlogList.blogs &&
        data.getBlogList.blogs.length > 0
      ) {
        dispatch({
          type: GET_BLOG_LIST,
          payload: sortArray(data.getBlogList.blogs).map((x) => ({
            ...x.blog,
            label: x.blog.blog_name,
            value: x.blog.blog_id,
            pending_history: x.pending_blog_history,
          })),
        });
      } else {
        dispatch({
          type: GET_BLOG_LIST,
          payload: [],
        });
      }
    } catch (error) {
      console.log("rGetBlogList Error", error);
      dispatch({
        type: GET_BLOG_LIST,
        payload: [],
      });
    }
  };

export const rGetPlanList =
  (req = { published: true }, get_pending_update = false) =>
  async (dispatch) => {
    try {
      const { data } = await graphHandler().invoke(
        GET_PLAN_LIST_GQL,
        { get_pending_update, ...req },
        1,
        false,
        true
      );
      if (
        data &&
        data.getPlanList &&
        data.getPlanList.plans &&
        data.getPlanList.plans.length > 0
      ) {
        dispatch({
          type: GET_PLAN_LIST,
          payload: sortArray(data.getPlanList.plans).map((x) => ({
            ...x.plan,
            label: x.plan.plan_name,
            value: x.plan.plan_id,
            pending_history: x.pending_plan_history,
          })),
        });
      } else {
        dispatch({
          type: GET_PLAN_LIST,
          payload: [],
        });
      }
    } catch (error) {
      console.log("getPlanList Error", error);
      dispatch({
        type: GET_PLAN_LIST,
        payload: [],
      });
    }
  };

export const rGetFunctionList =
  (req = { active: true }) =>
  async (dispatch) => {
    try {
      const { data } = await graphHandler().invoke(
        GET_FUNCTION_LIST_GQL,
        { ...req },
        1,
        false,
        true
      );
      if (
        data &&
        data.getFunctionList &&
        data.getFunctionList.functions &&
        data.getFunctionList.functions.length > 0
      ) {
        dispatch({
          type: GET_FUNCTION_LIST,
          payload: sortArray(data.getFunctionList.functions).map((x) => ({
            ...x,
            label: x.function_name,
            value: x.function_id,
          })),
        });
      } else {
        dispatch({
          type: GET_FUNCTION_LIST,
          payload: [],
        });
      }
    } catch (error) {
      console.log("rGetFunctionList Error", error);
      dispatch({
        type: GET_FUNCTION_LIST,
        payload: [],
      });
    }
  };

// export const rGetObjectiveList =
//   (req = { active: true }) =>
//   async (dispatch) => {
//     try {
//       const { data } = await graphHandler().invoke(
//         GET_OBJECTIVE_LIST_GQL,
//         { ...req },
//         1,
//         false,
//         true
//       );
//       if (
//         data &&
//         data.getObjectiveList &&
//         data.getObjectiveList.objectives &&
//         data.getObjectiveList.objectives.length > 0
//       ) {
//         dispatch({
//           type: GET_OBJECTIVE_LIST,
//           payload: sortArray(data.getObjectiveList.objectives).map((x) => ({
//             ...x,
//             label: x.objective_name,
//             value: x.objective_id,
//           })),
//         });
//       } else {
//         dispatch({
//           type: GET_OBJECTIVE_LIST,
//           payload: [],
//         });
//       }
//     } catch (error) {
//       console.log("rGetFunctionList Error", error);
//       dispatch({
//         type: GET_OBJECTIVE_LIST,
//         payload: [],
//       });
//     }
//   };

export const rGetDefaultChoices = () => async (dispatch) => {
  try {
    const { data } = await graphHandler().invoke(
      GET_DEFAULT_CHOICES_GQL,
      {},
      1,
      false,
      true
    );
    if (
      data &&
      data.getDefaultScreenerQuestionChoices &&
      data.getDefaultScreenerQuestionChoices.status === "Success" &&
      data.getDefaultScreenerQuestionChoices.default_choices &&
      data.getDefaultScreenerQuestionChoices.default_choices.length
    ) {
      //   console.log(
      //     "rGetDefaultChoices Success",
      //     data.getDefaultScreenerQuestionChoices.default_choices
      //   );
      dispatch({
        type: GET_DEFAULT_CHOICES,
        payload: data.getDefaultScreenerQuestionChoices.default_choices,
      });
    } else {
      dispatch({
        type: GET_DEFAULT_CHOICES,
        payload: [],
      });
    }
  } catch (error) {
    console.log("rGetDefaultChoices Error", error);
    dispatch({
      type: GET_DEFAULT_CHOICES,
      payload: [],
    });
  }
};

export const rGetAllDataSets = () => (dispatch) => {
  dispatch(rGetSubDomainList());
  dispatch(rGetDomainList());
  dispatch(rGetCategoryList());
  dispatch(rGetScreenerList());
  dispatch(rGetInterventionList());
  dispatch(rGetTherapyList());
  dispatch(rGetBlogList());
  dispatch(rGetPlanList());
};

export const rGetRuleEngine = () => (dispatch) => {
  dispatch(rGetPlanList());
  dispatch(rGetFunctionList());
  //   dispatch(rGetObjectiveList());
};
