import { Modal, ModalBody } from "reactstrap";
import ComponentView from "./section/ComponentView";
import Editor from "./section/Editor";
import "./content.scss";
import "./blog.css";
import "react-markdown-editor-lite/lib/index.css";
// import "./wt-bootstrap.css";
import { DragDropContext } from "react-beautiful-dnd";
import { useEffect, useState } from "react";
import { BlogComponents } from "./constants";
import { GenerateID } from "../../utils/Functions/filter";
import Banner from "./components/Banner";
import Paragraph from "./components/Paragraph";
import MediaLeft from "./components/MediaLeft";
import MediaRight from "./components/MediaRight";
import Quote from "./components/Quote";

export default function BlogContentHistory({
  isOpen,
  setOpen,
  onSave = () => {},
  onCancel = () => {},
  preview = false,
  setPreview = () => {},
  reset,
  values,
  actualValue,
  updateValue,
  contentId = "",
  contentType = "BLOG",
}) {
  const [dataSet, setDataSet] = useState([]);
  const [currentEditor, setCurrentEditor] = useState(0);
  const [edit, setEdit] = useState({});
  const [isPreview, setIsPreview] = useState(false);
  const [isCompare, setCompare] = useState(false);
  const canCompare =
    updateValue &&
    updateValue.length > 0 &&
    actualValue &&
    actualValue.length > 0
      ? true
      : false;
  useEffect(() => {
    setDataSet([]);
    setCurrentEditor(0);
    setEdit({});
    setIsPreview(false);
  }, [reset]);
  useEffect(() => {
    setIsPreview(preview);
  }, [preview]);
  useEffect(() => {
    setPreview(isPreview);
  }, [isPreview]);
  useEffect(() => {
    const currentArray =
      values && values.length > 0
        ? values
        : updateValue && updateValue.length > 0
        ? updateValue
        : actualValue && actualValue.length > 0
        ? actualValue
        : [];
    if (currentArray && currentArray.length > 0) {
      setDataSet([
        ...currentArray
          .slice()
          .sort((a, b) => a.sort_order - b.sort_order)
          .map((x) => ({
            ...x,
            id: GenerateID(),
          })),
      ]);
    }
  }, [actualValue, values, updateValue]);

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result.map((x, i) => ({ ...x, sort_order: i + 1 }));
  };

  const onDragEnd = (result) => {
    if (!result.destination || result.destination.droppableId !== "editor")
      return;
    if (result.source.droppableId === "editor") {
      const items = reorder(
        dataSet,
        result.source.index,
        result.destination.index
      );
      setDataSet(items);
    } else {
      let value = BlogComponents.find(
        (x) => x.blog_content_type === result.draggableId
      );
      if (value) {
        let items = reorder(
          [
            ...dataSet,
            {
              ...value,
              sort_order: dataSet.length + 1,
              id: GenerateID(),
            },
          ],
          dataSet.length,
          result.destination.index
        );
        setDataSet(items);
      }
    }
  };

  const renderComponent = (value, key) => {
    switch (value.blog_content_type) {
      case "BANNER":
        return <Banner value={value} key={key || value.id} />;
      case "PARAGRAPH":
        return <Paragraph value={value} key={key || value.id} />;
      case "LEFTMEDIA":
        return <MediaLeft value={value} key={key || value.id} />;
      case "RIGHTMEDIA":
        return <MediaRight value={value} key={key || value.id} />;
      case "QUOTE":
        return (
          <div className="container" key={key || value.id}>
            <Quote value={value} isPreview={true} />
          </div>
        );
      default:
        return null;
    }
  };
  return (
    <Modal
      //   toggle={onClose}
      isOpen={isOpen}
      //   size="lg"
      //   centered
      keyboard={false}
      modalClassName="modal-fullscreen modal-dialog-scrollable"
      //   modalClassName="modal-fullscreen modal-dialog-scrollable"
    >
      <div className="modal-header position-relative">
        {/* <button type="button" className="close" onClick={onClose}>
          <span aria-hidden="true">&times;</span>
        </button> */}
        <div className="header-left-btn">
          {updateValue && updateValue.length > 0 && (
            <button
              type="button"
              className="btn btn-info btn-add-domain ml-3"
              onClick={() => setCompare((x) => !x)}
            >
              {isCompare ? "Edit" : "Compare"}
            </button>
          )}
        </div>
        <h5 className="modal-title">Content Design</h5>
        <div className="header-right-btn">
          {dataSet.length > 0 && !isCompare && (
            <button
              type="button"
              className="btn  btn-add-domain mr-3"
              onClick={() => setIsPreview((x) => !x)}
            >
              {isPreview ? "Edit" : "Preview"}
            </button>
          )}
          <button
            type="button"
            className="btn  btn-add-domain mr-3"
            onClick={onCancel}
          >
            Cancel
          </button>
          <button
            type="button"
            className="btn btn-outline-success mr-3"
            disabled={dataSet.length === 0}
            onClick={() =>
              onSave(dataSet.map((x, i) => ({ ...x, sort_order: i })))
            }
          >
            Save
          </button>
        </div>
      </div>
      <ModalBody className="">
        <div className="row">
          {isCompare && canCompare ? (
            <div className="col-12">
              <div className="row form-group">
                <div className="col-6 border-end  border-info">
                  <h6 className="text-center w-100 badge badge-good py-2">
                    <b>Existing</b>
                  </h6>
                  {actualValue.map((item, i) => (
                    <>{renderComponent(item, i)}</>
                  ))}
                </div>
                <div className="col-6">
                  <h6 className="text-center w-100 badge badge-error py-2">
                    <b>New</b>
                  </h6>
                  {updateValue.map((item, i) => (
                    <>{renderComponent(item, i)}</>
                  ))}
                </div>
              </div>
            </div>
          ) : isPreview ? (
            <div className="col-12">
              {dataSet.map((item, i) => (
                <>{renderComponent(item, i)}</>
              ))}
            </div>
          ) : (
            <DragDropContext onDragEnd={onDragEnd}>
              <div className="col-7">
                <Editor
                  {...{
                    dataSet,
                    setDataSet,
                    currentEditor,
                    isPreview,
                    setCurrentEditor,
                    edit,
                    setEdit,
                  }}
                />
              </div>
              <div className="col-5">
                <ComponentView
                  {...{
                    dataSet,
                    setDataSet,
                    currentEditor,
                    isPreview,
                    setCurrentEditor,
                    edit,
                    setEdit,
                    contentId,
                    contentType,
                  }}
                />
              </div>
            </DragDropContext>
          )}
        </div>
      </ModalBody>
    </Modal>
  );
}
