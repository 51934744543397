import { useEffect } from "react";
import { Route } from "react-router-dom";
import AuthRouter from "./routes/AuthRouterFunction";
// import AuthRouter from "./Routes/AuthRouter";
import { ToastContainer } from "react-toastify";
import { Suspense } from "react";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import { renderRoutes } from "react-router-config";
import routes from "./routes/routesNew";
import Layout from "./layout";
import "react-toastify/dist/ReactToastify.css";
import CustomLoader from "./components/Loader";
import { LoaderContainer } from "./components/Loader/Loader";
import "./assets/css/style.css";
import "./assets/css/modal.css";
import "./assets/scss/Component.scss";
import "bootstrap/dist/css/bootstrap.min.css";

function App() {
  //SET ROUTES
  // useEffect(() => {
  //   console.log(
  //     // eslint-disable-next-line no-undef
  //     `%cVersion-${process.env.REACT_APP_VERSION}-B${process.env.REACT_APP_BUILD}`,
  //     "color:#263a5be6; background:white; font-size:16px"
  //   );
  // }, []);
  return (
    <AuthRouter>
      <ToastContainer position="top-center" autoClose={3000} theme="dark" />
      <LoaderContainer />
      <Layout>
        <Route
          render={(state) => {
            const { location } = state;
            return (
              <SwitchTransition>
                <CSSTransition
                  key={location.pathname}
                  appear
                  classNames="slide-fadein-up"
                  timeout={300}
                >
                  {/* <Switch location={location}> */}
                  <div className="slide-fadein-up">
                    <Suspense fallback={<CustomLoader />}>
                      {renderRoutes(routes)}
                    </Suspense>
                  </div>
                  {/* </Switch> */}
                </CSSTransition>
              </SwitchTransition>
            );
          }}
        />
      </Layout>
    </AuthRouter>
  );
}

export default App;
