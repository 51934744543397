import { useState } from "react";
import {
  REMOVE_CONFIG_GQL,
  REMOVE_GAME_LEVEL_GQL,
} from "../../Apollo/AdminServices/MutationRequest";
import graphHandler from "../../Apollo/graphHandler";
function useGameAPI() {
  const [loading, setLoading] = useState(false);

  const removeLevel = async (
    game_level_id = "",
    callback = () => {},
    eCallBack = () => {}
  ) => {
    setLoading(true);
    try {
      await graphHandler().invoke(
        REMOVE_GAME_LEVEL_GQL,
        { game_level_id },
        1,
        false,
        true
      );
      //   console.log("removeLevel Success", data.removeGameLevel);
      callback();
      setLoading(false);
    } catch (error) {
      eCallBack(error);
      console.log("removeLevel Error", error);
      setLoading(false);
    }
  };

  const removeConfig = async (
    game_config_id = "",
    callback = () => {},
    eCallBack = () => {}
  ) => {
    setLoading(true);
    try {
      await graphHandler().invoke(
        REMOVE_CONFIG_GQL,
        { game_config_id },
        1,
        false,
        true
      );
      //   console.log("removeConfig Success", data.removeGameConfig);
      callback();
      setLoading(false);
    } catch (error) {
      eCallBack(error);
      console.log("removeConfig Error", error);
      setLoading(false);
    }
  };
  return {
    loading,
    removeLevel,
    removeConfig,
  };
}
export default useGameAPI;
