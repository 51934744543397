// import { toast } from "react-toastify";
// import useScreenerAPI from "../../../utils/CustomHooks/useScreenerAPI";
import { Popconfirm } from "antd";
import { useEffect } from "react";
import graphHandler from "../../../Apollo/graphHandler";
import { UPDATE_SCREENER_THRESHOLD_GQL } from "../../../Apollo/AdminServices/MutationRequest";

export default function ThresholdSectionItem({
  item = {},
  onEdit = () => {},
  topicList = [],
  subDomains = [],
  domainList = [],
  screener = {},
  canDelete = false,
  refresh,
  setHistory = () => {},
  isEditable,
}) {
  let { pending_history } = item;
  // const { loading, removeThreshold } = useScreenerAPI();
  // const hasPublished =
  //   pending_history &&
  //   pending_history.publish_data &&
  //   pending_history.publish_data.created_by;
  const getRefName = (type) => {
    if (!type) return "-";
    switch (type) {
      case "Domain":
        return domainList.find((x) => x.domain_id === item.entity_ref_id)
          ? domainList.find((x) => x.domain_id === item.entity_ref_id)
              .domain_name
          : "-";
      case "SubDomain":
        return subDomains.find((x) => x.sub_domain_id === item.entity_ref_id)
          ? subDomains.find((x) => x.sub_domain_id === item.entity_ref_id)
              .sub_domain_name
          : "-";
      case "Topic":
        return topicList.find((x) => x.screener_topic_id === item.entity_ref_id)
          ? topicList.find((x) => x.screener_topic_id === item.entity_ref_id)
              .screener_topic_title
          : "-";
      case "Screener":
        return screener.screener_name || "-";
      default:
        return "-";
    }
  };
  useEffect(() => {
    if (isEditable && pending_history?.updated_data?.active === false) {
      setHistory(Boolean(pending_history && pending_history.updated_data));
    }
  }, [pending_history]);

  const onDelete =async () => {
    // if (loading) return;
    // if (!canDelete) return toast.warn("Sorry screener was published");
    // removeThreshold(
    //   { screener_threshold_id: item.screener_threshold_id },
    //   refresh
    // );
    try {
      await graphHandler().invoke(
        UPDATE_SCREENER_THRESHOLD_GQL,
        {
          update_request: {
            active: false,
            screener_threshold_id: item.screener_threshold_id,
          },
        },
        1,
        false
      );
      refresh();
    } catch (error) {
      console.log("screener error", error);
    }
  };
  return (
    <tr
      className={`${
        pending_history?.updated_data?.active === false ? "bad" : ""
      } option-table`}
    >
      <td className="text-center">{item.screener_threshold_entity_type}</td>
      <td className="text-center">
        {getRefName(item.screener_threshold_entity_type)}
      </td>
      <td className="text-center">{item.min_threshold}</td>
      <td className="d-flex align-items-center justify-content-center">
        {/* {hasPublished && (
          <span className="badge badge-error float-right">Changes</span>
        )} */}
        {isEditable && pending_history?.updated_data?.active !== false ? (
          <>
            <button
              type="button"
              className="ml-2 btn btn-outline-success btn-sm "
              onClick={() => onEdit(item)}
            >
              Edit
            </button>
            <Popconfirm
              title="Are you sure to delete this threshold?"
              onConfirm={onDelete}
              //  onCancel={cancel}
              okText="Yes"
              cancelText="No"
            >
              <i
                className="ml-2 fas fa-trash cursor-pointer"
                // onClick={onDelete}
              ></i>
            </Popconfirm>
          </>
        ) : (
          "-"
        )}
      </td>
    </tr>
  );
}
