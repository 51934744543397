import { useEffect } from "react";
import { Modal, ModalBody } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import graphHandler from "../../../Apollo/graphHandler";
import { UPDATE_CATEGORY_GQL } from "../../../Apollo/AdminServices/MutationRequest";
import {
  getDescription, IsNullOrEmpty,
} from "../../../utils/Functions/filter";
import { ContentTypes, ImageFormat } from "../../../constants";
import Description from "../../../components/Input/Description";
import CustomButton from "../../../components/Button/CustomButton";
import MultiSelect from "../../../components/MultiSelect/MultiSelect";
import { useSelector } from "react-redux";
import SingleMediaFileUpload from "../../../components/MediaFileUpload/SingleMediaFileUpload";

export default function UpdateCategory({
  isOpen,
  setOpen,
  refresh,
  initialValue = {},
}) {
  const subDomains = useSelector(({ DataSet }) => DataSet.subDomains);

  const formik = useFormik({
    initialValues: {},
    validationSchema: validation,
    onSubmit: (values) => {
      onSubmit(values);
    },
  });
  useEffect(() => {
    if (initialValue.category_id) {
      const value = {
        category_id: initialValue.category_id,
        category_name: initialValue.category_name,
        category_code: initialValue.category_code,
        sort_order: initialValue.sort_order,
      };

      if (initialValue.sub_domain_id) {
        value.sub_domain_id = initialValue.sub_domain_id;
      }
      if (
        initialValue?.category_image_urls &&
        initialValue?.category_image_urls?.length > 0
      ) {
        value.media_datas = initialValue.category_image_urls.map((x) => {
          return {
            ...x,
            mediaUrl: {
              url: x.url,
              type: "Image",
            },
            media_format: x.image_type,
            media_device: x.is_mobile.toString(),
          };
        });
      }
      if (
        initialValue.supported_content_types &&
        initialValue.supported_content_types.length > 0
      ) {
        value.supported_content_types =
          initialValue.supported_content_types.map((x) =>
            ContentTypes.find((y) => y.value === x)
          );
      }
      if (
        initialValue.category_description &&
        initialValue.category_description.length > 0
      ) {
        if (getDescription(initialValue.category_description, "Web")) {
          value.category_description = getDescription(
            initialValue.category_description,
            "Web"
          ).description;
        }
        if (getDescription(initialValue.category_description, "Mobile")) {
          value.mobile_description = getDescription(
            initialValue.category_description,
            "Mobile"
          ).description;
        }
      }
      formik.setValues(value);
    }
  }, [initialValue, isOpen]);

  const onClose = () => {
    formik.resetForm();
    setOpen();
  };

  const onSubmit = async (values) => {
    const {
      category_name,
      category_description,
      mobile_description,
      supported_content_types,
      sub_domain_id,
      sort_order,
      media_datas,
    } = values;
    const update_request = {
      category_id: initialValue.category_id,
      // category_name,
      supported_content_types: [...supported_content_types.map((x) => x.value)],
    };
    if (initialValue.category_name !== category_name) {
      update_request.category_name = category_name;
    }
    if (
      IsNullOrEmpty(initialValue.sort_order) ||
      sort_order != initialValue.sort_order
    ) {
      update_request.sort_order = Number(sort_order);
    }
    if (sub_domain_id) {
      update_request.sub_domain_id = sub_domain_id;
    }
    let category_image_urls = [];

    // console.log("media_datas", media_datas);
    if (media_datas && media_datas.length > 0) {
      media_datas.map((x) => {
        category_image_urls.push({
          image_type: x.media_format,
          url: x.mediaUrl.url,
          is_mobile: x.media_device === "false" ? false : true,
        });
      });
    }
    if (category_image_urls.length > 0) {
      update_request.category_image_urls = category_image_urls;
    }else{
      update_request.category_image_urls =[]
    }
    let description = [];
    if (category_description) {
      description.push({
        client_type: "Web",
        description: category_description,
      });
    }
    if (mobile_description) {
      description.push({
        client_type: "Mobile",
        description: mobile_description,
      });
    }
    if (description.length > 0){
      update_request.category_description = description;
    }else {
      update_request.category_description = [];
    }
    try {
      const { data } = await graphHandler().invoke(
        UPDATE_CATEGORY_GQL,
        { update_request },
        1,
        true
      );
      refresh();
      onClose();
      formik.setSubmitting(false);
    } catch (error) {
      console.log("UpdateCategory error", error);
      formik.setSubmitting(false);
    }
  };

  const handleError = () => {
    if (Object.keys(formik.errors).length > 0) {
      toast.error(formik.errors[Object.keys(formik.errors)[0]]);
    }
  };

  return (
    <Modal toggle={onClose} isOpen={isOpen} centered scrollable size="xl">
      <div className="modal-header">
        <button type="button" className="close" onClick={onClose}>
          <span aria-hidden="true">&times;</span>
        </button>
        <h5 className="modal-title">Update Category</h5>
      </div>
      <ModalBody className="pt-0">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12 form-group">
              <label htmlFor="category_name" className="col-md-4 ">
                Name
              </label>
              <input
                type="text"
                className="form-control"
                id="category_name"
                name="category_name"
                // maxLength="30"
                value={
                  formik.values && formik.values.category_name
                    ? formik.values.category_name
                    : ""
                }
                onChange={(e) =>
                  formik.setFieldValue(
                    "category_name",
                    e.target.value.trimStart()
                  )
                }
                onBlur={(e) => {
                  formik.setFieldTouched("category_name", true);
                  formik.setFieldValue(
                    "category_name",
                    e.target.value.replace(/\s+/g, " ").trim()
                  );
                }}
              />
              <small className="text-danger">
                {formik.errors && formik.errors.category_name
                  ? formik.errors.category_name
                  : ""}
              </small>
            </div>
            <div className="col-md-12 form-group">
              <label
                htmlFor="category_code"
                className="col-form-label w-100 mb-2"
              >
                Code
              </label>
              <input
                type="text"
                className="form-control"
                id="category_code"
                name="category_code"
                onChange={formik.handleChange}
                value={
                  formik.values.category_code ? formik.values.category_code : ""
                }
                disabled={initialValue.sub_domain_id}
                onBlur={formik.handleBlur}
              />
              <small className="text-danger align-self-end">
                {formik.touched.category_code &&
                formik.errors &&
                formik.errors.category_code
                  ? formik.errors.category_code
                  : ""}
              </small>
            </div>
            <div className="col-md-12 form-group">
              <label htmlFor="sort_order" className="col-form-label w-100 mb-2">
                Sort Order
              </label>
              <div className="d-flex flex-column p-0">
                <input
                  type="number"
                  className="form-control h-100 w-100"
                  //   placeholder={questionaireList.length + 1}
                  placeholder=""
                  id="sort_order"
                  name="sort_order"
                  max={100}
                  value={
                    formik.values && formik.values.sort_order
                      ? formik.values.sort_order
                      : ""
                  }
                  onBlur={formik.handleBlur}
                  onChange={(e) =>
                    formik.setFieldValue(
                      "sort_order",
                      e.target.value.replace(/[^0-9\.]/g, "")
                    )
                  }
                />
                <small className="text-danger">
                  {formik.touched.sort_order && formik.errors.sort_order
                    ? formik.errors.sort_order
                    : ""}
                </small>
              </div>
            </div>
            <div className="col-md-12 form-group">
              <label
                htmlFor="supported_content_types"
                className="col-form-label w-100 mb-2"
              >
                Supported Content Type
              </label>
              <MultiSelect
                option={ContentTypes}
                onChange={(e) =>
                  formik.setFieldValue("supported_content_types", e)
                }
                placeholder="Select"
                isCreatable={false}
                isMulti={true}
                type="Category"
                onBlur={formik.handleBlur}
                name="supported_content_types"
                defaultValue={formik.values.supported_content_types}
              />
              <small className="text-danger align-self-end">
                {formik.touched["react-select-3-input"] &&
                formik.errors &&
                formik.errors.supported_content_types
                  ? formik.errors.supported_content_types
                  : ""}
              </small>
            </div>{" "}
            <div className="col-md-12 form-group ">
              <label
                htmlFor="sub_domain_id"
                className="col-form-label w-100 mb-2"
              >
                Sub Domain
              </label>
              <select
                id="sub_domain_id"
                name="sub_domain_id"
                className="custom-select"
                onChange={formik.handleChange}
                value={
                  formik.values.sub_domain_id ? formik.values.sub_domain_id : ""
                }
                onBlur={formik.handleBlur}
              >
                <option key="Select" hidden>
                  Select
                </option>
                {subDomains.map((x, i) => (
                  <option key={i} value={x.sub_domain_id}>
                    {x.sub_domain_name}
                  </option>
                ))}
              </select>
              <small className="text-danger align-self-end">
                {formik.touched.sub_domain_id &&
                formik.errors &&
                formik.errors.sub_domain_id
                  ? formik.errors.sub_domain_id
                  : ""}
              </small>
            </div>
            <Description formik={formik} webName="category_description" />
            <SingleMediaFileUpload
              formik={formik}
              heading="Image File"
              contentId={initialValue.category_id}
              contentType="CATEGORY"
              mediaType="Image"
              parentArray="media_datas"
              mediaFormat={ImageFormat}
            />
            
            <button
              onClick={onClose}
              type="button"
              className="btn btn-danger btn-sm float-right btn-add-domain mr-2"
            >
              Close
            </button>
            <CustomButton
              type="button"
              className="btn btn-primary btn-outline-success float-right btn-add-domain"
              disabled={
                Object.keys(formik.errors).length !== 0 ||
                !formik.isValid ||
                formik.isSubmitting
              }
              onClick={
                formik.isValid && Object.keys(formik.values).length !== 0
                  ? formik.handleSubmit
                  : handleError
              }
            >
              Update
            </CustomButton>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
}

const validation = Yup.object().shape({
  sub_domain_id: Yup.string(),
  category_description: Yup.string(),
  mobile_description: Yup.string(),
  category_code: Yup.string().required("Enter a code"),
  sort_order: Yup.string().required("Enter sort order"),
  category_name: Yup.string().required("Enter a name"),
  // .min(4, "Name should be min of 4 characters")
  // .max(30, "Name should be maximum of 30 characters")
  media_datas: Yup.array().of(
    Yup.object().shape({
      media_device: Yup.string().required("Select a media device"),
      media_format: Yup.string().required("Select a media format"),
      mediaUrl: Yup.object(),
    })
  ),
  supported_content_types: Yup.array()
    .of(Yup.object())
    .test({
      message: "Select content types",
      test: (arr) => {
        return arr && arr.length > 0;
      },
    }),
});
