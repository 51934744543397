import { useEffect } from "react";
import { Modal, ModalBody } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import graphHandler from "../../../Apollo/graphHandler";
import { UPDATE_LANGUAGE_GQL } from "../../../Apollo/AdminServices/MutationRequest";
import CustomButton from "../../../components/Button/CustomButton";

export default function UpdateLanguage({
  isOpen,
  setOpen,
  refresh,
  initialValue = {},
}) {
  const formik = useFormik({
    initialValues: {},
    validationSchema: validation,
    onSubmit: (values) => {
      onSubmit(values);
    },
  });
  useEffect(() => {
    if (initialValue.language_id) {
      const value = {
        language_id: initialValue.language_id,
        language_name: initialValue.language_name,
      };

      if (initialValue.language_iso_short_code) {
        value.language_iso_short_code = initialValue.language_iso_short_code;
      }
      if (initialValue.country_mobile_number_prefix) {
        value.country_mobile_number_prefix =
          initialValue.country_mobile_number_prefix;
      }
      formik.setValues(value);
    }
  }, [initialValue, isOpen]);

  const onClose = () => {
    formik.resetForm();
    setOpen();
  };

  const onSubmit = async (values) => {
    const { language_name, language_iso_short_code } = values;
    const update_request = {
      language_id: initialValue.language_id,
      // language_name: language_name.trim(),
    };
    if (
      initialValue.language_name !== language_name.trim()
    ) {
      update_request.language_name = language_name.trim();
    }
    if (
      initialValue.language_iso_short_code !== language_iso_short_code.trim()
    ) {
      update_request.language_iso_short_code = language_iso_short_code.trim();
    }
    if (Object.keys(update_request).length === 1) {
      refresh();
      onClose();
      formik.setSubmitting(false);
      return;
    }
    try {
      const { data } = await graphHandler().invoke(
        UPDATE_LANGUAGE_GQL,
        { update_request },
        1,
        true
      );
      refresh();
      onClose();
      formik.setSubmitting(false);
    } catch (error) {
      console.log("UpdateCategory error", error);
      formik.setSubmitting(false);
    }
  };

  const handleError = () => {
    if (Object.keys(formik.errors).length > 0) {
      toast.error(formik.errors[Object.keys(formik.errors)[0]]);
    }
  };

  return (
    <Modal toggle={onClose} isOpen={isOpen} centered scrollable size="xl">
      <div className="modal-header">
        <button type="button" className="close" onClick={onClose}>
          <span aria-hidden="true">&times;</span>
        </button>
        <h5 className="modal-title">Update Language</h5>
      </div>
      <ModalBody className="pt-0">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12 form-group">
              <label htmlFor="language_name" className="col-md-4 ">
                Name
              </label>
              <input
                type="text"
                className="form-control"
                id="language_name"
                name="language_name"
                // maxLength="30"
                value={
                  formik.values && formik.values.language_name
                    ? formik.values.language_name
                    : ""
                }
                onChange={(e) =>
                  formik.setFieldValue(
                    "language_name",
                    e.target.value.trimStart()
                  )
                }
              />
              <small className="text-danger">
                {formik.errors && formik.errors.language_name
                  ? formik.errors.language_name
                  : ""}
              </small>
            </div>
            <div className="col-md-12 form-group">
              <label htmlFor="language_name" className="col-md-4 ">
                Iso Short Code
              </label>
              <input
                type="text"
                className="form-control"
                id="language_iso_short_code"
                name="language_iso_short_code"
                maxLength="10"
                value={
                  formik.values && formik.values.language_iso_short_code
                    ? formik.values.language_iso_short_code
                    : ""
                }
                onChange={(e) =>
                  formik.setFieldValue(
                    "language_iso_short_code",
                    e.target.value.trimStart()
                  )
                }
                onBlur={formik.handleBlur}
              />
              <small className="text-danger">
                {formik.errors && formik.errors.language_iso_short_code
                  ? formik.errors.language_iso_short_code
                  : ""}
              </small>
            </div>{" "}
            <CustomButton
              type="button"
              className="btn btn-primary btn-outline-success float-right btn-add-domain"
              disabled={
                Object.keys(formik.errors).length !== 0 ||
                !formik.isValid ||
                formik.isSubmitting
              }
              onClick={
                formik.isValid && Object.keys(formik.values).length !== 0
                  ? formik.handleSubmit
                  : handleError
              }
            >
              Update
            </CustomButton>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
}

const validation = Yup.object().shape({
  language_name: Yup.string().required("Enter language"),
  language_iso_short_code: Yup.string().required("Enter  Shortcode"),
});
