import { useEffect, useState } from "react";
import ListItem from "./components/ListItem";
import CreateCity from "./modal/CreateCity";
import PaginationComponent from "../../components/PaginationComponent";
import "../Category/subdomain.css";
import withRoleBased from "../../utils/Functions/withRoleBased";
import useCityList from "../../utils/CustomHooks/useCityList";
import useCountryList from "../../utils/CustomHooks/useCountryList";
import { sortArray } from "../../utils/Functions/filter";

function City({ access }) {
  const [isOpen, setIsOpen] = useState(false);
  const [count, setCount] = useState(0);
  const { cityList, loading, getCityList } = useCityList();
  const { countryList, getCountryList } = useCountryList("");
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize] = useState(10);
  const pageCount = Math.ceil(cityList.length / pageSize);
  const [search, setSearch] = useState("");
  const [isSearching, setIsSearch] = useState(false);
  useEffect(() => {
    let req = {};
    if (search) req.city_name = search;
    getCityList(req);
  }, [count]);
  useEffect(() => {
    getCountryList();
  }, []);
  const handleClick = (event, index) => {
    event.preventDefault();
    setCurrentPage(index);
  };

  const onSearch = () => {
    setCurrentPage(0)
    setCount((x) => x + 1);
    setIsSearch(search ? true : false);
  };

  return (
    <main className="page-content">
      <div className="container-fluid">
        <div className="row m-0 w-100">
          <div className="col-md-6 mb-3">
            <h5 className="page-title"> City </h5>
          </div>
          <div className="col-md-6  mb-3 d-flex justify-content-md-end">
            {access.canCreate && (
              <button
                type="button"
                className="btn btn-outline-success "
                onClick={() => setIsOpen(true)}
              >
                Create City
              </button>
            )}
          </div>

          <div className="col-md-4">
            <div className="search" style={{ margin: "16px 0px 32px" }}>
              <input
                type="text"
                className="form-control"
                placeholder="Search"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                onKeyDown={(e) => e.key === "Enter" && onSearch()}
              />
              {!isSearching ? (
                <button onClick={onSearch}>
                  <i className="fa fa-search" />
                </button>
              ) : (
                <button
                  onClick={() => {
                    setSearch("");
                    setIsSearch(false);
                    setCount((x) => x + 1);
                  }}
                >
                  <i className="fa fa-times" />
                </button>
              )}
            </div>
          </div>
          <div className="col-md-12">
            <div className="table-responsive">
              <table className="table table-borderless ">
                <thead>
                  <tr>
                    <td className="col-sm-2">City</td>
                    <td className="col-sm-2">Country</td>
                    {/* <td className={access.canEdit ? "col-sm-2" : "col-sm-6"}>
                      Description
                    </td> */}
                    <td className="col-sm-4">Short code</td>
                    {/* <td className="col-sm-2">TimeZone </td>
                    <td className="col-sm-2">Offset </td>
                    <td className="col-sm-2">TimeZone Shortcode </td> */}
                    <td className="col-sm-2">Status </td>
                    {access.canEdit && <td className="col-sm-2">Action</td>}
                    <td className="col-sm-2">Activity </td>
                  </tr>
                </thead>
                <tbody>
                  {!loading && cityList && cityList.length > 0 ? (
                    sortArray(cityList)
                      .slice(
                        currentPage * pageSize,
                        (currentPage + 1) * pageSize
                      )
                      .map((x, i) => (
                        <ListItem
                          item={x}
                          key={i}
                          index={i}
                          refresh={() => setCount((x) => x + 1)}
                          access={access}
                          countryList={countryList}
                        />
                      ))
                  ) : (
                    <tr>
                      <td colSpan="5" className="text-center">
                        <span className="font-weight-bold">
                          {loading ? "Loading..." : "Oops! No Data Found"}
                        </span>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {countryList.length > 0 && pageCount != 1 && (
          <PaginationComponent
            handleClick={handleClick}
            currentPage={currentPage}
            pageCount={pageCount}
          />
        )}
        <CreateCity
          isOpen={isOpen}
          setOpen={() => setIsOpen(false)}
          refresh={() => setCount((x) => x + 1)}
          countryList={countryList}
          cityList={cityList}
        />
      </div>
    </main>
  );
}
export default withRoleBased(City, "tag");
