import { Steps } from "antd";
import { useEffect, useState } from "react";
import { Modal, ModalBody } from "reactstrap";
import CustomLoader from "../../../components/Loader";
import useQuestionaireList from "../../../utils/CustomHooks/useQuestionaireList";
import useTopicList from "../../../utils/CustomHooks/useTopicList";
import CalculationSection from "../section/CalculationSection";
import CreateQTSection from "../section/CreateQTSection";
import CreateScreenerSection from "../section/CreateScreenerSection";
import UpdateScreenerSection from "../section/UpdateScreenerSection";
import SortingSection from "../section/SortingSection";
import useThresholdList from "../../../utils/CustomHooks/useThresholdList";
import useResultRangeList from "../../../utils/CustomHooks/useResultRangeList";
import useTemplateList from "../../../utils/CustomHooks/useTemplateList";
import useScreenerAPI from "../../../utils/CustomHooks/useScreenerAPI";
import useScreenerList from "../../../utils/CustomHooks/useScreenerList";

const { Step } = Steps;

export default function ScreenerModal({
  isOpen,
  setOpen,
  refresh,
  initialValues = {},
  isScreenerEditable = false,
  isEditable = false,
}) {
  const [activeKey, setActive] = useState(0);
  const [screener, setScreener] = useState({});
  const [loading, setLoading] = useState(false);
  const [reqCount, setReqCount] = useState(0);
  const [reqResultCount, setResultReqCount] = useState(0);
  const [reqQuesCount, setReqQuesCount] = useState(0);
  const { topicList, loading: TopicLoading, getTopicsList } = useTopicList("");
  const { questionaireList, getQuestionaireList } = useQuestionaireList("");
  const { resultRangeList, getResultRangeList } = useResultRangeList("");
  const { thresholdList, getThresholdList } = useThresholdList("");
  const { mailList, getTemplateList } = useTemplateList();
  const [templateOption, setTemplateOption] = useState([]);
  const isGame = screener.screener_format === "Game";
  const { reviewScreener } = useScreenerAPI();
  const { screenerList, getScreenerList } = useScreenerList("");
  
  // console.log("initialValues",initialValues)
  useEffect(() => {
    if (initialValues.screener_id) setScreener(initialValues);
  }, [initialValues]);
  useEffect(()=>{
    getTemplateList({})
  },[])
  useEffect(()=>{
    if(mailList.length > 0) {
      setTemplateOption(mailList.filter((x)=>x.template_type === "Report").map((x) => ({
        label: x.template_name,
        value: x.template_id,
      })));
    }
  }, [mailList]);
  
  useEffect(() => {
    if (screener.screener_id && isOpen) {
      getTopicsList({
        screener_id: screener.screener_id,
        get_pending_update: true,
      });
      getScreenerList({ screener_id: screener.screener_id})
    }
  }, [screener, reqCount,reqQuesCount, isOpen]);

  useEffect(() => {
    if (
      screener.screener_id &&
      (activeKey === 1 || activeKey === (isGame ? 2 : 3))
    ) {
      getThresholdList({
        screener_id: screener.screener_id,
        get_pending_update: true,
      });
      getResultRangeList({
        screener_id: screener.screener_id,
        get_pending_update: true,
      });
      getScreenerList({ screener_id: screener.screener_id})
    }
  }, [screener, reqResultCount, activeKey]);

  useEffect(() => {
    if (screener.screener_id && isOpen) {
      getQuestionaireList({
        screener_id: screener.screener_id,
        get_pending_update: true,
      });
      getScreenerList({ screener_id: screener.screener_id})
    }
  }, [screener, reqQuesCount, isOpen]);
// console.log("questionaireList",questionaireList)
  const onClose = () => {
    setOpen(false);
    setActive(0);
    refresh();
    setReqCount((x) => x + 1);
    setResultReqCount((x) => x + 1);
    setScreener({});
    setReqQuesCount((x) => x + 1);
  };

  const handleDiscord = () => {
    const request = {
      review_request: {
        comment: "Discord Values",
        approve: false,
      },
      screener_history_id: screenerList[0]?.pending_screener_history?.screener_history_id,
    };
    reviewScreener(request, onClose);
  }; 

  const ScreenerSection = screener.screener_id
    ? UpdateScreenerSection
    : CreateScreenerSection;

  const renderSteps = (key) => {
    if (key === 0) {
      return (
        <ScreenerSection
          refresh={refresh}
          onDone={() => setActive(1)}
          onClose={onClose}
          values={screener}
          isEditable={isScreenerEditable && isEditable}
          setScreener={setScreener}
          setLoading={setLoading}
          templateOption={templateOption}
          handleDiscord={handleDiscord}
        />
      );
    } else if (key === 1) {
      return (
        <CreateQTSection
          refresh={() => setReqCount((x) => x + 1)}
          refreshQues={() => setReqQuesCount((x) => x + 1)}
          topicList={topicList}
          questionaireList={questionaireList}
          isEditable={isEditable}
          values={screener}
          onDone={() => setActive(2)}
          onBack={() => setActive(0)}
          onClose={onClose}
          isGame={isGame}
          handleDiscord={handleDiscord}
        />
      );
    } else if (key === 2 && !isGame) {
      return (
        <SortingSection
          refresh={() => setReqCount((x) => x + 1)}
          refreshQues={() => setReqQuesCount((x) => x + 1)}
          topicList={topicList}
          questionaireList={questionaireList}
          isEditable={isEditable}
          values={screener}
          onDone={() => setActive(3)}
          onBack={() => setActive(1)}
          onClose={onClose}
          handleDiscord={handleDiscord}
        />
      );
    } else if (key === isGame ? 3 : 2) {
      return (
        <CalculationSection
          {...{
            resultRangeList,
            thresholdList,
            topicList,
            setScreener,
            setLoading,
            screener,
            onClose,
          }}
          refresh={() => setResultReqCount((x) => x + 1)}
          isEditable={isEditable}
          onBack={() => setActive(isGame ? 1 : 2)}
          handleDiscord={handleDiscord}
        />
      );
    } else {
      return <h5>No step found</h5>;
    }
  };

  const onChangeStep = (e) => {
    if (e === 1 && !screener.screener_id) return;
    else if (
      (e === 2 || e === 3) &&
      (isGame
        ? false
        : screener.sort_by === "Question"
        ? questionaireList.length === 0
        : topicList.length === 0)
    )
      return;
    setActive(e);
  };

  return (
    <Modal
      toggle={onClose}
      isOpen={isOpen}
      size="lg"
      keyboard={false}
      modalClassName="modal-fullscreen modal-dialog-scrollable"
    >
      <div className="modal-header">
        <Steps
          type="navigation"
          current={activeKey}
          onChange={onChangeStep}
          className="site-navigation-steps"
        >
          <Step
            status={screener.screener_id ? "finish" : "process"}
            title={initialValues.screener_id ? "Screener" : "Create Screener"}
          />
          <Step
            status={
              topicList.length > 0
                ? "finish"
                : screener.screener_id
                ? "process"
                : "wait"
            }
            title={
              screener.screener_id
                ? isGame
                  ? "Add Topic"
                  : "Add Topic/Question"
                : "Topic/Question"
            }
          />
          {!isGame && (
            <>
              <Step
                status={
                  screener.screener_id &&
                  (screener.sort_by === "Question"
                    ? questionaireList.length > 1
                    : topicList.length > 1)
                    ? "finish"
                    : screener.screener_id &&
                      (screener.sort_by === "Question"
                        ? questionaireList.length > 0
                        : topicList.length > 0)
                    ? "process"
                    : "wait"
                }
                title="Sort Topic/Question"
              />
            </>
          )}
          <Step
            status={
              resultRangeList.length > 0 && thresholdList.length > 0
                ? "finish"
                : resultRangeList.length > 0 || thresholdList.length > 0
                ? "process"
                : "wait"
            }
            title="Result Interpretation"
          />
        </Steps>
      </div>
      {/* <div className="modal-header">
        <button type="button" className="close" onClick={onClose}>
        </button>
        <h5 className="modal-title text-center mb-3">Create Screener</h5>
      </div> */}
      <ModalBody>
        {(loading || TopicLoading) && <CustomLoader />}
        {renderSteps(activeKey)}
      </ModalBody>
    </Modal>
  );
}
