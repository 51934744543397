import { ROUTE_LIST, CLEAR_LIST } from "./types";

const InitialState = {
  publicRoutes: [],
  privateRoutes: [],
  all: [],
};

const routeReducer = (state = InitialState, action) => {
  const { type, payload } = action;
  switch (type) {
  case ROUTE_LIST:
    return {
      publicRoutes: payload.publicRoutes,
      privateRoutes: payload.privateRoutes,
      all: payload.all,
    };

  case CLEAR_LIST:
    return InitialState;

  default:
    return state ? state : InitialState;
  }
};

export default routeReducer;
