import { useState } from "react";
import { GET_RULE_ENGINE_LIST_GQL } from "../../Apollo/AdminServices/MailRuleReportRequest";
import graphHandler from "../../Apollo/graphHandler";
import { sortArray } from "../Functions/filter";

function useRuleEngineList() {
  const [ruleList, setRuleList] = useState([]);
  const [loading, setLoading] = useState(false);

  const getRuleEngineList = async (req = {}) => {
    setLoading(true);
    try {
      const { data } = await graphHandler().invoke(
        GET_RULE_ENGINE_LIST_GQL,
        { ...req },
        1,
        false,
        true
      );
      if (
        data &&
        data.getRuleEngineList &&
        data.getRuleEngineList.rule_engines &&
        data.getRuleEngineList.rule_engines.length > 0
      ) {
        setRuleList([]);
        setRuleList(sortArray(data.getRuleEngineList.rule_engines));
        setLoading(false);
      } else {
        setRuleList([]);
        setLoading(false);
      }
    } catch (error) {
      console.log("getRuleEngineList Error", error);
      setRuleList([]);
      setLoading(false);
    }
  };

  return { ruleList, loading, getRuleEngineList };
}

export default useRuleEngineList;
