import { useEffect,useState } from "react";
import SortByTopicAccordionItem from "./SortByTopicAccordionItem";
import { Collapse } from "reactstrap";
import useQuestionaireList from "../../../utils/CustomHooks/useQuestionaireList";

const SortByExitTopicItem = ({
  item = {},
  i = 0,
//   isEditable=false,
  current,
  setCurrent,
}) => {
    // console.log("item",item)
  const [questions, setQuestions] = useState([]);
  const { questionaireList, getQuestionaireList } = useQuestionaireList("");

  useEffect(() => {
    if (questionaireList)
      setQuestions([
        ...questionaireList.slice().filter((x)=>x?.active === true).sort((a, b) => a.sort_order - b.sort_order),
      ]);
  }, [questionaireList]);

  useEffect(() => {
    if (item.screener_topic_id) {
      getQuestionaireList({
        screener_topic_id: item.screener_topic_id,
        get_pending_update: true,
        active:true
      });
    }
  }, [item.screener_topic_id]);
  return (
    <div className="col-md-12 mb-3">
      <div
        className={`custom-accordion ${
          current === item.screener_topic_id && "active"
        }`}
      >
        <div
          className="accordion-header"
          onClick={() =>
            setCurrent((x) =>
              x === item.screener_topic_id ? "" : item.screener_topic_id
            )
          }
        >
          {item.screener_topic_title + " (" + questionaireList.length + ") "}
          <div>
            <i className="fa fa-chevron-down" />
            <i className="fas fa-grip-vertical ml-2" />
          </div>
        </div>
      </div>
      <Collapse isOpen={current === item.screener_topic_id}>
        <div className="col-md-12 p-0">
          <div className="border p-2">
            <table className="table questionnaire-table table-borderless mb-0">
              <thead>
                <tr className=" ">
                  <td className="col-md-5">Question</td>
                  <td className="col-md-4">Options</td>
                  <td className="col-md-1">Weightage</td>
                  <td className="col-md-2">Ignore Scoring</td>
                </tr>
              </thead>
              <tbody>
                {questions && questions.length > 0 ? (
                  questions.slice().map((data, j) => (
                    <tr
                      className="bg-white border border-top-0 rounded-sm option-table w-100"
                      key={i}
                    >
                      <SortByTopicAccordionItem
                        item={data}
                        i={j + 1}
                        isEditable={false}
                      />
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td className="text-center" colSpan="5">
                      No Question Found
                    </td>
                  </tr>
                )}
                {/* {provided.placeholder} */}
              </tbody>
            </table>
          </div>
        </div>
      </Collapse>
    </div>
  );
};

export default SortByExitTopicItem;
