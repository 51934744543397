import { useEffect } from "react";
import { Card, CardBody } from "reactstrap";
import { UPDATE_SCREENER_QUESTIONAIRE_GQL } from "../../../Apollo/AdminServices/MutationRequest";
import graphHandler from "../../../Apollo/graphHandler";
import { IsNullOrEmpty } from "../../../utils/Functions/filter";

export default function SortByQuestionItem({ item = {}, i = 0, isEditable }) {
  useEffect(() => {
    if (
      !IsNullOrEmpty(item.sort_order) &&
      isEditable &&
      i + 1 !== item.sort_order
    ) {
      let request = {
          screener_question_id: item.screener_question_id,
          sort_order: i + 1,
        },
        update_data =
          item && item.pending_history && item.pending_history.updated_data
            ? item.pending_history.updated_data
            : null;
      if (update_data) {
        if (update_data.screener_question_title) {
          request.screener_question_title = update_data.screener_question_title;
        }
        if (update_data.result_weightage) {
          request.result_weightage = Number(update_data.result_weightage);
        }
        if (update_data.screener_topic_id) {
          request.screener_topic_id = update_data.screener_topic_id;
        }
        if (update_data.ignore_for_scoring) {
          request.ignore_for_scoring = update_data.ignore_for_scoring;
        }
        if (update_data.sub_domain_id) {
          request.sub_domain_id = update_data.sub_domain_id;
        }
        if (
          update_data.options &&
          update_data.options &&
          update_data.options.options_update &&
          update_data.options.options_update.update_items
        ) {
          request.options = {
            options_update: {
              update_items: update_data.options.options_update.update_items,
            },
          };
        }
      }
      update({
        ...request,
      });
    }
  }, [i]);
  const update = async (update_request) => {
    try {
      const { data } = await graphHandler().invoke(
        UPDATE_SCREENER_QUESTIONAIRE_GQL,
        { update_request },
        1,
        false,
        true
      );
      if (
        data &&
        data.updateScreenerQuestion &&
        data.updateScreenerQuestion.screener_question
      ) {
        // console.log(
        //   "Question Sort Success",
        //   data.updateScreenerQuestion.screener_question
        // );
      }
    } catch (error) {
      console.error("Question Sort Error", error);
    }
  };
  const canShowOptionsFn = (value) =>
    value &&
    value != "TextField" &&
    value != "DocumentUpload" &&
    value != "ImageCapture" &&
    value != "Date" &&
    value != "Rating" &&
    value != "TextArea";
  return (
    <Card className="col-md-12 mb-3 rounded shadow">
      <CardBody>
        <h6 className="card-title position-relative">
          {`${i + 1}). ${
            item.screener_question_title ? item.screener_question_title : ""
          }`}
        </h6>
        {canShowOptionsFn(item.screener_question_type) &&
          item.options.options &&
          item.options.options.length > 0 &&
          item.options.options.map((a, i) => (
            //   <p className="card-subtitle mb-2 text-muted" key={i}>
            <div className="form-check form-check-inline" key={i}>
              <input
                className="form-check-input"
                type="radio"
                name={a.data}
                id={a.data}
                value={a.data}
                disabled={true}
              />
              <label className="form-check-label" htmlFor={a.data}>
                {a.data}
              </label>
            </div>
            //   </p>
          ))}
      </CardBody>
    </Card>
  );
}
