import { useFormik } from "formik";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import {
  createArrayByUpdate,
  mergeMetaArray,
  mergeMultiSelectArray,
} from "../../utils/Functions/filter";
import CreateMetaCard from "./Components/CreateMetaCard";
import HistoryMetaTableItem from "./Components/HistoryMetaTableItem";
import UpdateMetaCard from "./Components/UpdateMetaCard";

export default function MetaHistoryInput(props) {
  const [isView, setVisible] = useState(false);
  const [data, setData] = useState([]);

  let {
    parentForm = {},
    validation = {},
    className = "col-md-12",
    actualValue = [],
    updateValue,
    parentArray = "meta_datas",
    typeKey = "meta_data_type",
    titleKey = "meta_data_title",
    valueKey = "meta_data_value",
    heading = "Meta",
  } = props;

  const defaultValue = {
    [typeKey]: "Instruction",
    [titleKey]: "",
    [valueKey]: "",
  };
  useEffect(() => {
    if (updateValue && updateValue.length > 0 && parentForm) {
      parentForm.setFieldValue(parentArray, updateValue);
    }
  }, [updateValue]);
  const formik = useFormik({
    initialValues: {},
    validationSchema: Yup.object().shape(validation),
    onSubmit: () => {},
  });
  const formik1 = useFormik({
    initialValues: {
      ...defaultValue,
    },
    validationSchema: Yup.object().shape({
      [typeKey]: Yup.string().trim().required("Select a data type"),
      [titleKey]: Yup.string().trim().required("Enter a key"),
      [valueKey]: Yup.string().trim().required("Enter a value"),
    }),
    onSubmit: (values) => onSubmit(values),
  });
  const isUpdate = Number.isInteger(formik1.values.index);
  useEffect(() => {
    if (actualValue && actualValue.length > 0) {
      let array = [];
      if (!updateValue || updateValue.length === 0) {
        array = actualValue.map((x, index) => ({ ...x, index }));
      } else {
        let value = mergeMetaArray(actualValue, updateValue);
        value.map((x, index) => {
          let returnData = { ...x, index };
          if (x.isRemoved) returnData.index = index;
          array.push(returnData);
        });
      }
      formik.setValues({ [parentArray]: array });
      setData(array);
    } else if (updateValue && updateValue.length > 0) {
      let array = [],
        value = createArrayByUpdate(updateValue);
      value.map((x, index) => {
        let returnData = { ...x, index };
        if (x.isRemoved) returnData.index = index;
        array.push(returnData);
      });
      formik.setValues({ [parentArray]: array });
      setData(array);
    }
  }, [actualValue, updateValue]);
  //   useEffect(() => {
  //     setVisible(
  //       Boolean(
  //         parentArray &&
  //           formik.values[parentArray] &&
  //           formik.values[parentArray].length > 0
  //       )
  //     );
  //   }, [formik.values, parentArray]);

  const onSubmit = (values = {}) => {
    let modal = { ...values };
    let meta_data = [
        ...(formik.values[parentArray] ? formik.values[parentArray] : []).map(
          (x) => x
        ),
      ],
      parentData = [
        ...(parentForm.values[parentArray]
          ? parentForm.values[parentArray]
          : []
        ).map((x) => x),
      ];
    if (!values[typeKey] || !values[titleKey] || !values[valueKey])
      return console.error("Data Not found", values);
    if (Number.isInteger(values.index) && meta_data.length > values.index) {
      delete modal.index;
      parentData.push({
        update_type: "Update",
        index: values.index,
        update_data: {
          [typeKey]: values[typeKey],
          [titleKey]: values[titleKey],
          [valueKey]: values[valueKey],
        },
      });
      meta_data[values.index] = { ...modal, index: values.index ,update_type: "Update"};
      formik.setFieldValue(parentArray, [...meta_data]);
      parentForm.setFieldValue(parentArray, [...parentData]);
      reset();
    } else {
      formik.setFieldValue(parentArray, [
        ...meta_data,
        { ...modal, index: meta_data.length ,update_type: "Add"},
      ]);
      parentData.push({
        update_type: "Add",
        update_data: {
          [typeKey]: values[typeKey],
          [titleKey]: values[titleKey],
          [valueKey]: values[valueKey],
        },
      });
      parentForm.setFieldValue(parentArray, [...parentData]);
      reset();
    }
  };

  const reset = () => {
    formik1.setValues({ [typeKey]: "Instruction" });
    formik1.setTouched({});
  };

  const remove = (i, arrayIndex, values) => {
    const parentData = [
      ...(parentForm.values[parentArray]
        ? parentForm.values[parentArray]
        : []
      ).map((x) => x),
    ];
    const meta_data = [
      ...(formik.values[parentArray] ? formik.values[parentArray] : []).map(
        (x) => x
      ),
    ];

    if (isUpdate && formik1.values.index === meta_data[arrayIndex].index) {
      reset();
    }
    meta_data.splice(arrayIndex, 1);
    parentData.push({
      update_type: "Remove",
      index: i,
      update_data: {
        [typeKey]: values[typeKey],
        [titleKey]: values[titleKey],
        [valueKey]: values[valueKey],
      },
    });
    parentForm.setFieldValue(parentArray, [...parentData]);
    formik.setFieldValue(parentArray, meta_data);
  };
  //   console.log(formik1.values, formik.values, parentForm.values);
  const onEdit = (values = {}) => {
    setVisible(true);
    formik1.setValues({
      ...values,
    });
    const container = document.getElementById("metaSection" + parentArray);
    if (container) container.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className={`form-group ${className}`} id={"metaSection" + parentArray}>
      <div className="row mb-2">
        <div style={{ display: "flex", alignItems: "center" }}>
          <label className="col-form-label w-100">{heading} </label>
          {!isView && (
            <button
              className="btn btn-outline-success ml-3 btn-sm"
              disabled={props.disabled}
              onClick={() => setVisible(true)}
            >
              Add Meta
            </button>
          )}
        </div>
      </div>
      {formik.values[parentArray] && formik.values[parentArray].length > 0 ? (
        <div className="row ">
          <div
            className="table-responsive mb-3 table-borderless  questionnaire-table"
            style={{ maxHeight: "200px" }}
          >
            <table className="table  table-striped mb-0">
              <thead>
                <tr>
                  <th className="col-sm-3">Key</th>
                  <th className="col-sm-5">Value</th>
                  {!props.disabled && <th className="col-sm-2">Action</th>}
                </tr>
              </thead>
              <tbody>
                {formik.values[parentArray]
                  .slice()
                  .sort((a, b) => a.sort_order - b.sort_order)
                  .map((x, i) => (
                    <HistoryMetaTableItem
                      key={i}
                      arrayIndex={i}
                      item={x}
                      {...props}
                      remove={remove}
                      onEdit={onEdit}
                    />
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      ) : null}
      {isView && !props.disabled && (
        <>
          {isUpdate ? (
            <UpdateMetaCard
              formik={formik1}
              reset={reset}
              setVisible={setVisible}
              {...props}
            />
          ) : (
            <CreateMetaCard
              formik={formik1}
              reset={reset}
              setVisible={setVisible}
              {...props}
            />
          )}
        </>
      )}
    </div>
  );
}
