// import { Modal, ModalBody } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import graphHandler from "../../../Apollo/graphHandler";
import CustomButton from "../../../components/Button/CustomButton";
import { UPDATE_ORGANIZATION_EMPLOYEE_GQL } from "../../../Apollo/ApplicationService/OrganizationResponse";
import { OrgImageFormat, organizationEmployeeStatus } from "../../../constants";
import SingleMediaFileUpload from "../../../components/MediaFileUpload/SingleMediaFileUpload";
import Description from "../../../components/Input/Description";
import { getDescription } from "../../../utils/Functions/filter";
import { useEffect } from "react";
import { useState } from "react";
import useCountryList from "../../../utils/CustomHooks/useCountryList";
import useCityList from "../../../utils/CustomHooks/useCityList";

export default function EditOrganizationEmployee({
  values = {},
  isEditable = false,
  refresh = () => {},
  onClose = () => {},
  setLoading = () => {},
  organizationOption = [],
}) {
  // console.log("values", values);

  const formik = useFormik({
    initialValues: {},
    validationSchema: validationSchema,
    onSubmit: (values) => {
      onSubmit(values);
    },
  });
  // console.log("formik", formik.values);

  const [countryOption, setCountryOption] = useState([]);
  const [cityOption, setCityOption] = useState([]);
  const { countryList, getCountryList } = useCountryList("");
  const { cityList, getCityList } = useCityList();
  useEffect(() => {
    if (values.organization_id) {
      getCountryList();
      getCityList();
    }
  }, [values]);
  useEffect(() => {
    if (formik?.values?.country_id)
      getCityList({ country_id: formik?.values?.country_id });
  }, [formik?.values?.country_id]);

  useEffect(() => {
    setCountryOption(
      countryList.map((x) => ({
        label: x.country_name,
        value: x.country_id,
      }))
    );
  }, [countryList]);

  useEffect(() => {
    setCityOption(
      cityList
        .filter((x) => x.active === true)
        .sort((a, b) => a.sort_order - b.sort_order)
        .map((x) => ({
          label: x.city_name,
          value: x.city_id,
        }))
    );
  }, [cityList]);

  useEffect(() => {
    if (values.organization_id) {
      const value = {
        user_id: values.user_id,
        organization_id: values.organization_id,
        organization_employee_name: values.organization_employee_name,
        organization_employee_email: values.organization_employee_email,
        // organization_employee_mobile_number:
        // values.organization_employee_mobile_number,
      };
      if (values.organization_employee_mobile_number) {
        value.organization_employee_mobile_number =
          values.organization_employee_mobile_number;
      }
      if (values?.organization_employee_address?.line_1) {
        value.line_1 = values?.organization_employee_address?.line_1;
      }
      if (values?.organization_employee_address?.line_2) {
        value.line_2 = values?.organization_employee_address?.line_2;
      }
      if (values?.organization_employee_address?.postcode) {
        value.postcode = values?.organization_employee_address?.postcode;
      }
      if (values?.organization_employee_address?.region) {
        value.region = values?.organization_employee_address?.region;
      }
      if (values?.city_id) {
        value.city_id = values?.city_id;
      }
      if (values?.country_id) {
        value.country_id = values?.country_id;
      }
      if (values?.organization_employee_status) {
        value.organization_employee_status =
          values?.organization_employee_status;
      }
      if (
        values?.organization_employee_image_urls &&
        values?.organization_employee_image_urls?.length > 0
      ) {
        value.media_datas = values.organization_employee_image_urls.map((x) => {
          return {
            ...x,
            mediaUrl: {
              url: x.url,
              type: "Image",
            },
            media_format: x.image_type,
            media_device: x.is_mobile?.toString(),
          };
        });
      }
      if (
        values.organization_employee_description &&
        values.organization_employee_description.length > 0
      ) {
        if (getDescription(values.organization_employee_description, "Web")) {
          value.organization_employee_description = getDescription(
            values.organization_employee_description,
            "Web"
          ).description;
        }
        if (
          getDescription(values.organization_employee_description, "Mobile")
        ) {
          value.mobile_description = getDescription(
            values.organization_employee_description,
            "Mobile"
          ).description;
        }
      }
      formik.setValues(value);
    }
  }, [values]);

  const onClosed = () => {
    onClose();
    formik.resetForm();
    formik.setSubmitting(false);
  };

  const onSubmit = async (value) => {
    formik.setSubmitting(true);
    const {
      organization_employee_name,
      organization_employee_email,
      organization_employee_mobile_number,
      domain_name,
      organization_id,
      // media_datas,
      // mobile_description,
      // organization_employee_description,
      city_id,
      country_id,
      user_id,
      line_1,
      line_2,
      region,
      postcode,
      organization_employee_status,
    } = value;
    let update_request = {
      // organization_id,
      user_id,
    };
    if (values.organization_employee_status !== organization_employee_status) {
      update_request.organization_employee_status =
        organization_employee_status;
    }
    if (values.organization_id !== organization_id) {
      update_request.organization_id = organization_id;
    }
    if (values.organization_employee_name !== organization_employee_name) {
      update_request.organization_employee_name = organization_employee_name;
    }
    if (values.organization_employee_email !== organization_employee_email) {
      update_request.organization_employee_email = organization_employee_email;
    }
    if (
      values.organization_employee_mobile_number !==
      organization_employee_mobile_number
    ) {
      update_request.organization_employee_mobile_number =
        organization_employee_mobile_number;
    }
    if (values.domain_name !== domain_name) {
      update_request.domain_name = domain_name;
    }
    // let organization_employee_image_urls = [];
    // if (media_datas && media_datas.length > 0) {
    //   media_datas.map((x) => {
    //     organization_employee_image_urls.push({
    //       image_type: x.media_format,
    //       url: x.mediaUrl.url,
    //       is_mobile: x.media_device === "false" ? false : true,
    //     });
    //   });
    // }
    // if (organization_employee_image_urls.length > 0) {
    //   update_request.organization_employee_image_urls =
    //     organization_employee_image_urls;
    // } else {
    //   update_request.organization_employee_image_urls = [];
    // }

    // let description = [];
    // if (organization_employee_description) {
    //   description.push({
    //     client_type: "Web",
    //     description: organization_employee_description,
    //   });
    // }
    // if (mobile_description) {
    //   description.push({
    //     client_type: "Mobile",
    //     description: mobile_description,
    //   });
    // }
    // if (description.length > 0) {
    //   update_request.organization_employee_description = description;
    // } else {
    //   update_request.organization_employee_description = [];
    // }
    if (values.country_id !== country_id) {
      update_request.country_id = country_id;
    }
    if (values.city_id !== city_id) {
      update_request.city_id = city_id;
    }
    formik.setSubmitting(false);

    let organization_employee_address = {};
    if (line_1) {
      organization_employee_address.line_1 = line_1;
    }
    if (line_2) {
      organization_employee_address.line_2 = line_2;
    }
    if (region) {
      organization_employee_address.region = region;
    }
    if (postcode) {
      organization_employee_address.postcode = postcode;
    }
    if (Object.keys(organization_employee_address).length > 0) {
      update_request.organization_employee_address =
        organization_employee_address;
    }
    // console.log("update_request", update_request);
    try {
      await graphHandler().invoke(
        UPDATE_ORGANIZATION_EMPLOYEE_GQL,
        { update_request },
        0,
        true
      );
      refresh();
      onClosed();
      setLoading(false);
      formik.setSubmitting(false);
    } catch (error) {
      console.error("createOrganization", error);
      formik.setSubmitting(false);
      setLoading(false);
    }
  };

  const handleError = () => {
    if (Object.keys(formik.errors).length > 0) {
      toast.error(formik.errors[Object.keys(formik.errors)[0]]);
    }
  };

  return (
    // <Modal toggle={onClose} isOpen={isOpen} centered scrollable size="lg">
    //   <div className="modal-header">
    //     <button type="button" className="close" onClick={onClose}>
    //       <span aria-hidden="true">&times;</span>
    //     </button>
    //     <h5 className="modal-title">Edit Organization</h5>
    //   </div>
    //   <ModalBody className="pt-0">
    <div className="container">
      <div className="row justify-content-start">
        <div className="col-md-6 form-group">
          <label
            htmlFor="organization_id"
            className="col-form-label w-100 mb-2"
          >
            Organization
          </label>
          <select
            id="organization_id"
            name="organization_id"
            className="form-control"
            onChange={formik.handleChange}
            value={formik.values.organization_id}
            onBlur={formik.handleBlur}
          >
            <option key="Select" hidden disabled>
              Select
            </option>
            {organizationOption.map((x, i) => (
              <option value={x.value} key={i}>
                {x.label}
              </option>
            ))}
          </select>
          <small className="text-danger">
            {formik.touched.organization_id &&
            formik.errors &&
            formik.errors.organization_id
              ? formik.errors.organization_id
              : ""}
          </small>
        </div>
        <div className="col-md-6 form-group">
          <label
            htmlFor="organization_employee_name"
            className="col-form-label w-100 mb-2"
          >
            Name
          </label>
          <input
            type="text"
            className="form-control"
            id="organization_employee_name"
            name="organization_employee_name"
            // maxLength="30"
            value={
              formik.values && formik.values.organization_employee_name
                ? formik.values.organization_employee_name
                : ""
            }
            onChange={(e) =>
              formik.setFieldValue(
                "organization_employee_name",
                e.target.value.trimStart()
              )
            }
            onBlur={(e) => {
              formik.setFieldTouched("organization_employee_name", true);
              formik.setFieldValue(
                "organization_employee_name",
                e.target.value.replace(/\s+/g, " ").trim()
              );
            }}
          />
          <small className="text-danger">
            {formik.touched.organization_employee_name &&
            formik.errors &&
            formik.errors.organization_employee_name
              ? formik.errors.organization_employee_name
              : ""}
          </small>
        </div>
        <div className="col-md-6 form-group">
          <label
            htmlFor="organization_employee_email"
            className="col-form-label mb-2 w-100"
          >
            Email
          </label>
          <input
            type="text"
            className="form-control"
            name="organization_employee_email"
            // maxLength="50"
            id="organization_employee_email"
            value={
              formik.values && formik.values.organization_employee_email
                ? formik.values.organization_employee_email
                : ""
            }
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <small className="text-danger">
            {formik.touched.organization_employee_email &&
            formik.errors &&
            formik.errors.organization_employee_email
              ? formik.errors.organization_employee_email
              : ""}
          </small>
        </div>
        <div className="col-md-6 form-group">
          <label
            htmlFor="organization_employee_mobile_number"
            className="col-form-label w-100 mb-2"
          >
            Mobile
          </label>
          <input
            type="text"
            className="form-control"
            name="organization_employee_mobile_number"
            maxLength="10"
            value={
              formik.values && formik.values.organization_employee_mobile_number
                ? formik.values.organization_employee_mobile_number
                : ""
            }
            onChange={(e) =>
              formik.setFieldValue(
                "organization_employee_mobile_number",
                e.target.value.replace(/[^0-9+]/gi, "")
              )
            }
            onBlur={formik.handleBlur}
          />
          <small className="text-danger">
            {formik.touched.organization_employee_mobile_number &&
            formik.errors &&
            formik.errors.organization_employee_mobile_number
              ? formik.errors.organization_employee_mobile_number
              : ""}
          </small>
        </div>

        {/* <div > */}
        {/* <Description
          col="col-md-6"
          formik={formik}
          webName="organization_employee_description"
        /> */}
        <div className="col-md-6 form-group">
          <label
            htmlFor="organization_employee_status"
            className="col-form-label w-100 mb-2"
          >
            Organization Employee Status
          </label>
          <select
            id="organization_employee_status"
            name="organization_employee_status"
            className="form-control"
            onChange={formik.handleChange}
            value={formik.values.organization_employee_status}
            onBlur={formik.handleBlur}
          >
            <option key="Select" hidden>
              Select
            </option>
            {organizationEmployeeStatus.map((x, i) => (
              <option value={x.value} key={i}>
                {x.label}
              </option>
            ))}
          </select>
          <small className="text-danger">
            {formik.touched.organization_employee_status &&
            formik.errors &&
            formik.errors.organization_employee_status
              ? formik.errors.organization_employee_status
              : ""}
          </small>
        </div>

        {/* <div className="col-md-12">
          <SingleMediaFileUpload
            formik={formik}
            heading="Image File"
            contentId={values.user_id}
            contentType="ORGANIZATIONEMPLOYEE"
            mediaType="Image"
            parentArray="media_datas"
            mediaFormat={OrgImageFormat}
          />
        </div> */}
        <label htmlFor="city_id" className="col-form-label w-100 mb-2 ml-3">
          Address:
        </label>
        <div className="col-md-6 form-group">
          <label htmlFor="line_1" className="col-form-label w-100 mb-2">
            Address Line 1
          </label>
          <input
            type="text"
            className="form-control"
            name="line_1"
            value={
              formik.values && formik.values.line_1 ? formik.values.line_1 : ""
            }
            onChange={(e) =>
              formik.setFieldValue("line_1", e.target.value.trimStart())
            }
            onBlur={(e) => {
              formik.setFieldTouched("line_1", true);
              formik.setFieldValue(
                "line_1",
                e.target.value.replace(/\s+/g, " ").trim()
              );
            }}
          />
          <small className="text-danger">
            {formik.touched.line_1 && formik.errors && formik.errors.line_1
              ? formik.errors.line_1
              : ""}
          </small>
        </div>
        <div className="col-md-6 form-group">
          <label htmlFor="line_2" className="col-form-label w-100 mb-2">
            Address Line 2
          </label>
          <input
            type="text"
            className="form-control"
            name="line_2"
            value={
              formik.values && formik.values.line_2 ? formik.values.line_2 : ""
            }
            onChange={(e) =>
              formik.setFieldValue("line_2", e.target.value.trimStart())
            }
            onBlur={(e) => {
              formik.setFieldTouched("line_2", true);
              formik.setFieldValue(
                "line_2",
                e.target.value.replace(/\s+/g, " ").trim()
              );
            }}
          />
          <small className="text-danger">
            {formik.touched.line_2 && formik.errors && formik.errors.line_2
              ? formik.errors.line_2
              : ""}
          </small>
        </div>
        <div className="col-md-6 form-group">
          <label htmlFor="region" className="col-form-label w-100 mb-2">
            Region
          </label>
          <input
            type="text"
            className="form-control"
            name="region"
            value={
              formik.values && formik.values.region ? formik.values.region : ""
            }
            onChange={(e) =>
              formik.setFieldValue("region", e.target.value.trimStart())
            }
            onBlur={(e) => {
              formik.setFieldTouched("region", true);
              formik.setFieldValue(
                "region",
                e.target.value.replace(/\s+/g, " ").trim()
              );
            }}
          />
          <small className="text-danger">
            {formik.touched.region && formik.errors && formik.errors.region
              ? formik.errors.region
              : ""}
          </small>
        </div>
        <div className="col-md-6 form-group">
          <label htmlFor="postcode" className="col-form-label w-100 mb-2">
            Postcode
          </label>
          <input
            type="text"
            className="form-control"
            name="postcode"
            value={
              formik.values && formik.values.postcode
                ? formik.values.postcode
                : ""
            }
            onChange={(e) =>
              formik.setFieldValue("postcode", e.target.value.trimStart())
            }
            onBlur={(e) => {
              formik.setFieldTouched("postcode", true);
              formik.setFieldValue(
                "postcode",
                e.target.value.replace(/\s+/g, " ").trim()
              );
            }}
          />
          <small className="text-danger">
            {formik.touched.postcode && formik.errors && formik.errors.postcode
              ? formik.errors.postcode
              : ""}
          </small>
        </div>
        <div className="col-md-6 form-group">
          <label htmlFor="country_id" className="col-form-label w-100 mb-2">
            Country
          </label>
          <select
            id="country_id"
            name="country_id"
            className="form-control"
            onChange={formik.handleChange}
            value={formik.values.country_id}
            onBlur={formik.handleBlur}
          >
            <option key="Select" hidden>
              Select
            </option>
            {countryOption.map((x, i) => (
              <option value={x.value} key={i}>
                {x.label}
              </option>
            ))}
          </select>
          <small className="text-danger">
            {formik.touched.country_id &&
            formik.errors &&
            formik.errors.country_id
              ? formik.errors.country_id
              : ""}
          </small>
        </div>
        <div className="col-md-6 form-group">
          <label htmlFor="city_id" className="col-form-label w-100 mb-2">
            City
          </label>
          <select
            id="city_id"
            name="city_id"
            className="form-control"
            onChange={formik.handleChange}
            value={formik.values.city_id}
            onBlur={formik.handleBlur}
          >
            <option key="Select" hidden>
              Select
            </option>
            {cityOption.map((x, i) => (
              <option value={x.value} key={i}>
                {x.label}
              </option>
            ))}
          </select>
          <small className="text-danger">
            {formik.touched.city_id && formik.errors && formik.errors.city_id
              ? formik.errors.city_id
              : ""}
          </small>
        </div>
        
        <div className="d-flex align-items-end justify-content-end col-md-12 mt-3">
          <button
            onClick={onClosed}
            type="button"
            className="btn btn-danger btn-sm float-right btn-add-domain mr-2"
          >
            Close
          </button>
          <CustomButton
            type="button"
            className="btn btn-primary btn-outline-success btn-sm float-right btn-add-domain"
            disabled={
              Object.keys(formik.errors).length !== 0 ||
              !formik.isValid ||
              formik.isSubmitting
            }
            onClick={
              formik.isValid && Object.keys(formik.values).length !== 0
                ? formik.handleSubmit
                : handleError
            }
          >
            {formik.values.organization_id ? "Update" : "Create"}
          </CustomButton>
        </div>
      </div>
    </div>
    //   </ModalBody>
    // </Modal>
  );
}

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const validationSchema = Yup.object().shape({
  organization_employee_name: Yup.string().required("Enter name"),
  organization_employee_email: Yup.string()
    .email("Invalid email")
    .required("Enter a email"),
  organization_employee_mobile_number: Yup.string()
    // .required("Enter mobile number")
    .matches(phoneRegExp, "Mobile number is not valid"),
  organization_id: Yup.string().required("Organization is required"),
  line_1: Yup.string(),
  postcode: Yup.string(),
  city_id: Yup.string().required("city is required"),
  country_id: Yup.string().required("Country is required"),
  organization_employee_status: Yup.string().required("Status is required"),
  organization_employee_description: Yup.string().trim(),
  // .required("Enter description"),
  mobile_description: Yup.string().trim(),
  // .required("Enter mobile description"),
});
