import { LOGIN_ADMIN } from "../../Apollo/ProfileService/profileMutateRequest";
import {
  SET_USERS,
  LOGIN_SUCCESS,
  LOGIN_FAILED,
  LOGOUT_SUCCESS,
  LOADING_START,
  LOADING_STOP,
  SET_USER_ROLES,
} from "./types";
import graphHandler from "../../Apollo/graphHandler";
import ApolloService from "../../Apollo/ApolloService";
import { rGetAllDataSets } from "../DataSets/action";
import { toast } from "react-toastify";
import { GET_USER_ADMIN_ROLES_GQL } from "../../Apollo/AdminServices/QueryRequest";
import { rConfigAccessData } from "../Role/action";
export const rLoginUser = (login_request) => async (dispatch) => {
  if (!login_request || !login_request.email) {
    dispatch({
      type: LOGIN_FAILED,
    });
    dispatch({
      type: LOADING_STOP,
    });
    return;
  }
  dispatch({
    type: LOADING_START,
  });
  try {
    const { data } = await graphHandler().invoke(
      LOGIN_ADMIN,
      { login_request },
      0,
      false
    );
    if (
      data &&
      data.loginAdmin &&
      data.loginAdmin.status === "Success" &&
      data.loginAdmin.user &&
      data.loginAdmin.user.user_type === "Admin"
    ) {
      const sessionToken = data.loginAdmin.session.session_token;
      const user_id = data.loginAdmin.user.user_id;
      localStorage.setItem("ADMIN_TOKEN", sessionToken);
      localStorage.setItem("ADMIN_UserID", user_id);
      ApolloService.setProfileToken(sessionToken);
      ApolloService.setAppToken(sessionToken);
      ApolloService.setLicenseToken(sessionToken);
      localStorage.setItem("email", login_request.email);
      localStorage.setItem("password", login_request.password);
      dispatch(rGetUserRoles(user_id));
      dispatch({
        type: LOGIN_SUCCESS,
        payload: {
          user: data.loginAdmin.user,
          sessionToken: sessionToken,
        },
      });
      dispatch(rGetAllDataSets());
    } else {
      if (data.loginAdmin?.user?.user_type !== "Admin") {
        toast.error("Login with Admin Account!!!");
      }
      dispatch({
        type: LOADING_STOP,
      });
    }
  } catch (error) {
    console.log(error);
    dispatch({
      type: LOGIN_FAILED,
    });
    dispatch({
      type: LOADING_STOP,
    });
  }
};

export const rGetUserRoles = (user_id) => async (dispatch) => {
  try {
    const { data } = await graphHandler().invoke(
      GET_USER_ADMIN_ROLES_GQL,
      { user_id },
      1,
      false,
      true
    );
    if (
      data &&
      data.getAllAdminRoles &&
      data.getAllAdminRoles.roles &&
      data.getAllAdminRoles.roles.length > 0
    ) {
      dispatch(rConfigAccessData(data.getAllAdminRoles.roles));
      dispatch({
        type: SET_USER_ROLES,
        payload: data.getAllAdminRoles.roles,
      });
    } else {
      dispatch({
        type: SET_USER_ROLES,
        payload: [],
      });
    }
  } catch (error) {
    console.log("rGetUserRoles Error", error);
    dispatch({
      type: SET_USER_ROLES,
      payload: [],
    });
  }
};

export const rGetUser = () => (dispatch) => {
  try {
    dispatch({
      type: SET_USERS,
    });
  } catch (e) {
    dispatch({
      type: LOGIN_FAILED,
    });
  }
};

export const rLogout = () => (dispatch) => {
  localStorage.clear();
  ApolloService.clear();
  toast.error("Logged out!!!");
  dispatch({
    type: LOGOUT_SUCCESS,
  });
};
