import { Modal, ModalBody } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import graphHandler from "../../../Apollo/graphHandler";
import {
  ADD_LOCALIZE_GQL,
  EDIT_LOCALIZE_GQL,
} from "../../../Apollo/AdminServices/MutationRequest";
import Description from "../../../components/Input/Description";
import { useSelector } from "react-redux";
import CustomButton from "../../../components/Button/CustomButton";
import { useState } from "react";
import SingleImageUpload from "../../../components/MediaFileUpload/SingleImageUpload";

export default function CreateLocalize({
  languageList = [],
  isOpen,
  setOpen,
  refresh,
}) {
  const subDomains = useSelector(({ DataSet }) => DataSet.subDomains);
  const [localizeId, setLocalizeId] = useState("");
  const [localizeData, setLocalizeData] = useState({});
  const formik = useFormik({
    initialValues: {},
    validationSchema: localizeId ? ImgValidation : validation,
    onSubmit: (values) => {
      onSubmit(values);
    },
  });

  const onClose = () => {
    formik.resetForm();
    formik.setValues({});
    setOpen();
    setLocalizeId("");
  };

  const onSubmit = async (values) => {
    if (localizeId) {
      return handleUpdateData(values);
    }
    const {
      key,
      sub_domain_id,
      language_id,
      web_description,
      mobile_description,
      // media,
      // mobilemedia,
      localization_data_code,
    } = values;
    const addReq = {
      key,
      language_id,
      localization_data_code,
    };
    if (sub_domain_id) {
      addReq.sub_domain_id = sub_domain_id;
    }
    let value = {};
    let description_value = [];
    // let image_urls = [];
    if (!mobile_description && !web_description) {
      formik.setSubmitting(false);
      return toast.error("Enter description");
    }
    if (web_description) {
      description_value.push({
        client_type: "Web",
        description: web_description,
      });
    }
    if (mobile_description) {
      description_value.push({
        client_type: "Mobile",
        description: mobile_description,
      });
    }
    if (description_value.length > 0) {
      value.description_value = description_value;
    }
    if (Object.keys(value).length > 0) addReq.value = value;

    // console.log("addReq",addReq)
    try {
      const { data } = await graphHandler().invoke(
        ADD_LOCALIZE_GQL,
        { addReq },
        1,
        true
      );
      refresh();
      // onClose();
      if (
        data &&
        data.AddLocalizationData &&
        data.AddLocalizationData.localization_data &&
        data.AddLocalizationData.localization_data.localization_data_id
      ) {
        setLocalizeId(
          data.AddLocalizationData.localization_data.localization_data_id
        );
        formik.setFieldValue(
          "localization_data_id",
          data.AddLocalizationData.localization_data.localization_data_id
        );
        setLocalizeData(data.AddLocalizationData.localization_data);
      }
      formik.setSubmitting(false);
    } catch (error) {
      console.log("CreateLocalize error", error);
      formik.setSubmitting(false);
    }
  };
  const handleUpdateData = async (values) => {
    const { localization_data_id, image_urls, sub_domain_id } = values;
    const update_request = {
      localization_data_id,
    };
    if (sub_domain_id) {
      update_request.sub_domain_id = sub_domain_id;
    }
    let value = {};
    if (
      localizeData &&
      localizeData.value &&
      localizeData.value[0].description_value.length > 0
    ) {
      value = {
        ...value,
        description_value: localizeData.value[0].description_value,
      };
    }
    if (image_urls && image_urls.length > 0) {
      value.image_urls = image_urls.map((x) => {
        delete x.index;
        return { ...x };
      });
    }
    // if (value.length > 0) update_request.value = value;
    if (Object.keys(value).length > 0) update_request.value = value;
    // formik.setSubmitting(false);
    // console.log(update_request,"update_request")
    // return
    try {
      await graphHandler().invoke(
        EDIT_LOCALIZE_GQL,
        { update_request },
        1,
        true
      );
      refresh();
      onClose();
      formik.setSubmitting(false);
    } catch (error) {
      console.log("update Localize error", error);
      formik.setSubmitting(false);
    }
  };

  const handleError = () => {
    if (Object.keys(formik.errors).length > 0) {
      toast.error(formik.errors[Object.keys(formik.errors)[0]]);
    }
  };

  return (
    <Modal
      toggle={onClose}
      isOpen={isOpen}
      size="lg"
      modalClassName="modal-fullscreen modal-dialog-scrollable"
    >
      <div className="modal-header">
        <button type="button" className="close" onClick={onClose}>
          <span aria-hidden="true">&times;</span>
        </button>
        <h5 className="modal-title">Create Localized Data</h5>
      </div>
      <ModalBody className="pt-0">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-6">
              <div className="row">
                <div className="col-md-12 form-group  ">
                  <label htmlFor="key" className="col-form-label w-100 mb-2">
                    Key
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="key"
                    name="key"
                    // maxLength="30"
                    value={
                      formik.values && formik.values.key
                        ? formik.values.key
                        : ""
                    }
                    disabled={localizeId}
                    onChange={(e) =>
                      formik.setFieldValue("key", e.target.value.trimStart())
                    }
                    onBlur={(e) => {
                      formik.setFieldTouched("key", true);
                      formik.setFieldValue(
                        "key",
                        e.target.value.replace(/\s+/g, " ").trim()
                      );
                    }}
                  />
                  <small className="text-danger">
                    {formik.touched.key && formik.errors && formik.errors.key
                      ? formik.errors.key
                      : ""}
                  </small>
                </div>

                <div className="col-md-12 form-group">
                  <label
                    htmlFor="localization_data_code"
                    className="col-form-label w-100 mb-2"
                  >
                    Code
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="localization_data_code"
                    name="localization_data_code"
                    onChange={formik.handleChange}
                    disabled={localizeId}
                    // maxLength={30}
                    // disabled={interversionId !== ""}
                    value={
                      formik.values.localization_data_code
                        ? formik.values.localization_data_code
                        : ""
                    }
                    onBlur={formik.handleBlur}
                  />
                  <small className="text-danger align-self-end">
                    {formik.touched.localization_data_code &&
                    formik.errors &&
                    formik.errors.localization_data_code
                      ? formik.errors.localization_data_code
                      : ""}
                  </small>
                </div>

                <div className="col-md-12 form-group ">
                  <label
                    htmlFor="sub_domain_id"
                    className="col-form-label w-100 mb-2"
                  >
                    Sub Domain
                  </label>
                  <select
                    id="sub_domain_id"
                    name="sub_domain_id"
                    className="custom-select"
                    onChange={formik.handleChange}
                    disabled={localizeId}
                    value={
                      formik.values.sub_domain_id
                        ? formik.values.sub_domain_id
                        : ""
                    }
                    onBlur={formik.handleBlur}
                  >
                     <option key="Select" hidden>
                      Select
                    </option>
                    {subDomains.map((x, i) => (
                      <option key={i} value={x.sub_domain_id}>
                        {x.sub_domain_name}
                      </option>
                    ))}
                  </select>
                  <small className="text-danger align-self-end">
                    {formik.touched.sub_domain_id &&
                    formik.errors &&
                    formik.errors.sub_domain_id
                      ? formik.errors.sub_domain_id
                      : ""}
                  </small>
                </div>
                <div className="col-md-12 form-group ">
                  <label
                    htmlFor="language_id"
                    className="col-form-label w-100 mb-2"
                  >
                    Language
                  </label>
                  <select
                    id="language_id"
                    name="language_id"
                    className="custom-select"
                    onChange={formik.handleChange}
                    disabled={localizeId}
                    value={
                      formik.values.language_id ? formik.values.language_id : ""
                    }
                    onBlur={formik.handleBlur}
                  >
                    <option key="Select" hidden>
                      Select
                    </option>
                    {languageList.map((x, i) => (
                      <option key={i} value={x.language_id}>
                        {x.language_name}
                      </option>
                    ))}
                  </select>
                  <small className="text-danger align-self-end">
                    {formik.touched.language_id &&
                    formik.errors &&
                    formik.errors.language_id
                      ? formik.errors.language_id
                      : ""}
                  </small>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="row">
                <Description
                  formik={formik}
                  webName="web_description"
                  webTitle="Value"
                  mobileTitle="Mobile Value"
                  showEditor={true}
                  disabled={localizeId}
                />
              </div>
            </div>
            {localizeId !== "" ? (
              <>
                <div className="col-md-12">
                  <SingleImageUpload
                    formik={formik}
                    heading="Image File"
                    contentId={localizeId}
                    contentType="LOCALIZATIONDATA"
                    mediaType="Image"
                    parentArray="image_urls"
                  />
                </div>
              </>
            ) : null}
            <CustomButton
              type="button"
              className="btn btn-primary btn-outline-success float-right btn-add-domain"
              disabled={
                Object.keys(formik.errors).length !== 0 ||
                !formik.isValid ||
                formik.isSubmitting
              }
              onClick={
                formik.isValid && Object.keys(formik.values).length !== 0
                  ? formik.handleSubmit
                  : handleError
              }
            >
              {localizeId ? "Update" : "Create"}
            </CustomButton>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
}

const validation = Yup.object().shape({
  sub_domain_id: Yup.string(),
  language_id: Yup.string().required("Select a Language"),
  web_description: Yup.string(),
  mobile_description: Yup.string(),
  localization_data_code: Yup.string().required("Enter a code"),
  key: Yup.string().trim().required("Enter a Key"),
  // .min(4, "Name should be min of 4 characters")
  // .max(30, "Name should be maximum of 30 characters")
});

const ImgValidation = Yup.object().shape({
  image_urls: Yup.array().of(
    Yup.object().shape({
      is_mobile: Yup.boolean().required("Select a Image device"),
      image_type: Yup.string().required("Select a Image format"),
      url: Yup.string(),
    })
  ),
});
