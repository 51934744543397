import { useState } from "react";
import { GET_LEVEL_LIST_GQL } from "../../Apollo/AdminServices/QueryRequest";
import graphHandler from "../../Apollo/graphHandler";

function useGameLevelList(preload = false) {
  const [levelList, setLevelList] = useState([]);
  const [loading, setLoading] = useState(preload);

  const getLevelList = async (req = {}) => {
    setLevelList([]);
    setLoading(true);
    try {
      const { data } = await graphHandler().invoke(
        GET_LEVEL_LIST_GQL,
        { ...req },
        1,
        false,
        true
      );
      if (
        data &&
        data.getGameLevelList &&
        data.getGameLevelList.game_levels &&
        data.getGameLevelList.game_levels.length > 0
      ) {
        // console.log("getGameLevelList Success", data.getGameLevelList);
        setLevelList(
          data.getGameLevelList.game_levels
            .slice()
            .sort(
              (a, b) =>
                new Date(b.updated_at ? b.updated_at : b.created_at) -
                new Date(a.updated_at ? a.updated_at : a.created_at)
            )
        );
        setLoading(false);
      } else {
        setLevelList([]);
        setLoading(false);
      }
    } catch (error) {
      console.log("getGameLevelList Error", error);
      setLevelList([]);
      setLoading(false);
    }
  };

  return {
    levelList,
    loading,
    getLevelList,
  };
}

export default useGameLevelList;
