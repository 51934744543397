import { useEffect, useState } from "react";
import { GET_DOMAIN_LIST_GQL } from "../../Apollo/AdminServices/QueryRequest";
import graphHandler from "../../Apollo/graphHandler";

function useDomainList(id) {
  const [domainList, setDomains] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (id) {
      getDomainList(id);
    } else {
      setDomains([]);
    }
  }, [id]);

  const getDomainList = async (req = {}) => {
    setLoading(true);
    try {
      const { data } = await graphHandler().invoke(
        GET_DOMAIN_LIST_GQL,
        { ...req },
        1,
        false,
        true
      );
      if (
        data &&
        data.getDomainList &&
        data.getDomainList.domains &&
        data.getDomainList.domains.length > 0
      ) {
        // console.log("getDomainList Success", data.getDomainList);
        setDomains([]);
        setDomains(data.getDomainList.domains);
        setLoading(false);
      } else {
        setDomains([]);
        setLoading(false);
      }
    } catch (error) {
      console.log("getDomainList Error", error);
      setDomains([]);
      setLoading(false);
    }
  };

  return {
    domainList,
    loading,
    getDomainList,
  };
}

export default useDomainList;
