import { Popconfirm } from "antd";
import { useEffect } from "react";
import { toast } from "react-toastify";
import useModuleActions from "../../../utils/CustomHooks/useModuleActions";
// import useTherapyAPI from "../../../utils/CustomHooks/useTherapyAPI";
import { getDescription } from "../../../utils/Functions/filter";
import graphHandler from "../../../Apollo/graphHandler";
import { UPDATE_MODULE_GQL } from "../../../Apollo/AdminServices/MutationRequest";

const TherapyGroupListItem = ({
  reset,
  refresh,
  item = {},
  isEditable,
  onEdit,
  setHistory = () => {},
}) => {
  const { pending_history } = item;
  // const hasPublished =
  //   pending_history &&
  //   pending_history.publish_data &&
  //   pending_history.publish_data.created_by;
  const { actionList, loading, getActionList } = useModuleActions("");
  // const { removeModules, loading: loading1 } = useTherapyAPI("");
  useEffect(() => {
    if (item.therapy_module_id) {
      getActionList({ therapy_module_id: item.therapy_module_id ,get_pending_update:true,active:true});
    }
  }, [item.therapy_module_id]);
  // console.log("actionList",actionList,item.therapy_module_id)
  
  useEffect(() => {
    if (isEditable && pending_history?.updated_data?.active === false) {
      setHistory(Boolean(pending_history && pending_history.updated_data));
    }
  }, [pending_history]);

  const onDelete = async () => {
    const { therapy_module_id } = item;
    if (loading) return;
    let data = await actionList.filter((x)=> x.pending_history?.updated_data?.active !== false)
    // console.log("data",data)
    if (data&&data.length > 0){
      return toast.error("Please delete all the actions inside this module");
    }
    onEdit({});
    try {
      // removeModules(therapy_module_id , refresh);
      await graphHandler().invoke(
        UPDATE_MODULE_GQL,
        {
          update_request: {
            active: false,
            therapy_module_id,
          },
        },
        1,
        true
      );
      refresh();
    } catch (error) {
      console.log("therapyGroup error", error);
    }
  };

  return (
    <tr  className={`${
      pending_history?.updated_data?.active === false ? "bad" : ""
    } option-table`}>
      <td className="text-none"> {item.therapy_module_name || "-"}</td>
      <td className="text-none">
        {item.therapy_module_description &&
        getDescription(item.therapy_module_description, "Web")
          ? getDescription(item.therapy_module_description, "Web").description
          : "-"}
      </td>
      <td className="text-none">{item.min_completion_days}</td>
      <td className="text-none">{item.max_completion_days}</td>
      <td className="text-none">
        {Number(item.start_interval_seconds) / 86400}
      </td>
      <td className="text-none">{item.sort_order}</td>
      <td className="d-flex align-items-center justify-content-center">
        {/* {hasPublished && (
          <span className="badge badge-error float-right">Changes</span>
        )} */}
        {isEditable && pending_history?.updated_data?.active !== false ? (  <>
          <button
            type="button"
            className="ml-2 btn btn-outline-success btn-sm "
            onClick={async () => {
              await reset();
              onEdit(item);
            }}
          >
            Edit
          </button>
        <Popconfirm
          title="Are you sure to delete this module?"
          onConfirm={onDelete}
          okText="Yes"
          cancelText="No"
        >
          <i className="ml-2 fas fa-trash cursor-pointer"></i>
        </Popconfirm>
        </>
        ):"-"}
      </td>
    </tr>
  );
};

export default TherapyGroupListItem;
