import { useEffect, useState } from "react";
import PaginationComponent from "../../components/PaginationComponent";
import "./questionaire.css";
import withRoleBased from "../../utils/Functions/withRoleBased";
import CreateMailTemplate from "./modal/CreateMailTemplate";
import MailItem from "./components/MailItem";
import { useDispatch } from "react-redux";
import { rGetRuleEngine } from "../../redux/DataSets/action";
import useTemplateList from "../../utils/CustomHooks/useTemplateList";

function MailTemplate({ access = {} }) {
  const { mailList, loading, getTemplateList } = useTemplateList();
  const [count, setCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [pageSize] = useState(10);
  const pageCount = Math.ceil(mailList.length / pageSize);
  const [search, setSearch] = useState("");
  const [isSearching, setIsSearch] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    let req = {};
    if (search) req.template_name = search;
    setCurrentPage(0);
    getTemplateList(req);
  }, [count]);

  const onSearch = () => {
    setCurrentPage(0)
    setCount((x) => x + 1);
    setIsSearch(search ? true : false);
  };

  useEffect(() => {
    dispatch(rGetRuleEngine());
  }, []);

  const handleClick = (event, index) => {
    event.preventDefault();
    setCurrentPage(index);
  };
  return (
    <main className="page-content">
      <div className="container-fluid">
        <div className="row ">
          <div className="col-md-6 mb-3">
            <h5 className="page-title">Template</h5>
          </div>
          <div className="col-md-6  mb-3 d-flex justify-content-end">
            {access.canCreate && (
              <button
                type="button"
                className="btn btn-outline-success "
                onClick={() => setIsOpen(true)}
              >
                Create
              </button>
            )}
          </div>
          <div className="col-md-4">
            <div className="search" style={{ margin: "16px 0px 32px" }}>
              <input
                type="text"
                className="form-control"
                placeholder="Search"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                onKeyDown={(e) => e.key === "Enter" && onSearch()}
              />
              {!isSearching ? (
                <button onClick={onSearch}>
                  <i className="fa fa-search" />
                </button>
              ) : (
                <button
                  onClick={() => {
                    setSearch("");
                    setIsSearch(false);
                    setCount((x) => x + 1);
                  }}
                >
                  <i className="fa fa-times" />
                </button>
              )}
            </div>
          </div>
          <div className="col-md-12">
            <div className="table-responsive ">
              <table className="table domain-table  table-borderless ">
                <thead>
                  <tr className=" ">
                    <td className="">Name</td>
                    <td className="">Code</td>
                    <td className="">Has Document</td>
                    <td className="">Status</td>
                    {access.canCreate && <td className="">Action</td>}
                    <td className="">Activity </td>
                  </tr>
                </thead>
                <tbody>
                  {!loading && mailList.length > 0 ? (
                    mailList
                      .slice(
                        currentPage * pageSize,
                        (currentPage + 1) * pageSize
                      )
                      .map((x, i) => (
                        <MailItem
                          item={x}
                          key={i}
                          index={i}
                          access={access}
                          refresh={() => {
                            setCount((x) => x + 1);
                          }}
                        />
                      ))
                  ) : (
                    <tr>
                      <td className="text-center font-weight-bold" colSpan="7">
                        {loading ? "Loading..." : "No Data Found"}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {mailList.length > 0 && pageCount != 1 && (
          <PaginationComponent
            handleClick={handleClick}
            currentPage={currentPage}
            pageCount={pageCount}
          />
        )}
        {access.canCreate && (
          <CreateMailTemplate
            isOpen={isOpen}
            setOpen={() => setIsOpen(false)}
            refresh={() => setCount((x) => x + 1)}
          />
        )}
      </div>
    </main>
  );
}
export default withRoleBased(MailTemplate, "tag");
