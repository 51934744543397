import { useState, useEffect } from "react";
import "./details.css";
import CustomLoader from "../../components/Loader";
import UserDetailsComponent from "./components/UserDetailsComponent";
import UserRole from "./components/UserRole";
import useEmployee from "../../utils/CustomHooks/useEmployee";
import { useSelector } from "react-redux";
import UpdatePassword from "./components/UpdatePassword";
const Profile = () => {
  const [userCount, setUserCount] = useState(0);
  const { user_id } = useSelector(({ Auth }) => Auth.user);
  const { user, profile, loading, getEmployee } = useEmployee("", true);

  useEffect(() => {
    user_id && getEmployee(user_id);
  }, [user_id]);

  useEffect(() => {
    if (user_id) {
      getEmployee(user_id);
    }
  }, [userCount]);

  if (loading) return <CustomLoader />;
  return (
    <main className="page-content">
      <div className="container-fluid">
        <div className="row ">
          <div className="view  col-md-12" id="mainform">
            <UserDetailsComponent
              value={user}
              profile={profile}
              refresh={() => setUserCount((x) => x + 1)}
            />
            <UpdatePassword />
            <UserRole user_id={user_id} />
          </div>
        </div>
      </div>
    </main>
  );
};

export default Profile;
