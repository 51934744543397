import { Popconfirm } from "antd";

export default function ContentTableItem({
  item,
  index,
  subDomains = [],
  categoryList = [],
  interventionList = [],
  therapyList = [],
  blogList = [],
  planList = [],
  onEdit = () => {},
  remove = () => {},
}) {
  const getContent = () => {
    if (!item.related_content_type) return "-";
    switch (item.related_content_type) {
      case "SUBDOMAIN":
        return subDomains.find(
          (x) => x.sub_domain_id == item.related_content_id
        )
          ? subDomains.find((x) => x.sub_domain_id == item.related_content_id)
              .sub_domain_name
          : "-";
      case "CATEGORY":
        return categoryList.find(
          (x) => x.category_id == item.related_content_id
        )
          ? categoryList.find((x) => x.category_id == item.related_content_id)
              .category_name
          : "-";
      case "INTERVENTION":
        return interventionList.find(
          (x) => x.intervention_id == item.related_content_id
        )
          ? interventionList.find(
              (x) => x.intervention_id == item.related_content_id
            ).intervention_name
          : "-";
      case "THERAPY":
        return therapyList.find((x) => x.therapy_id == item.related_content_id)
          ? therapyList.find((x) => x.therapy_id == item.related_content_id)
              .therapy_name
          : "-";
      case "BLOG":
        return blogList.find((x) => x.blog_id == item.related_content_id)
          ? blogList.find((x) => x.blog_id == item.related_content_id).blog_name
          : "-";
      case "PLAN":
        return planList.find((x) => x.plan_id == item.related_content_id)
          ? planList.find((x) => x.plan_id == item.related_content_id).plan_name
          : "-";
      default:
        return "-";
    }
  };
  return (
    <tr className="">
      <td className="text-none">{getContent()}</td>
      <td className="text-none">{item.related_content_type}</td>
      <td className="d-flex flex-row">
        <i
          className="fas fa-edit cursor-pointer"
          onClick={() => onEdit({ ...item, index })}
        ></i>
        <Popconfirm
          title="Are you sure to delete this related content?"
          onConfirm={() => remove(item.related_content_row_id)}
          okText="Yes"
          cancelText="No"
        >
          <i className="ml-2 fas fa-trash cursor-pointer"></i>
        </Popconfirm>
      </td>
    </tr>
  );
}
