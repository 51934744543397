import { useState } from "react";
import { GET_PLAN_GROUP_LIST_GQL } from "../../Apollo/AdminServices/NewQueryRequest";
import graphHandler from "../../Apollo/graphHandler";
import { sortArray } from "../Functions/filter";

function usePlanGroupList() {
  const [groupList, setGroupList] = useState([]);
  const [loading, setLoading] = useState(false);

  const getGroupList = async (req = {}, get_pending_update = true) => {
    setLoading(true);
    try {
      const { data } = await graphHandler().invoke(
        GET_PLAN_GROUP_LIST_GQL,
        { get_pending_update, ...req },
        1,
        false,
        true
      );
      if (
        data &&
        data.getPlanGroupList &&
        data.getPlanGroupList.plan_groups &&
        data.getPlanGroupList.plan_groups.length > 0
      ) {
        // console.log("getPlanGroupList Success", data.getPlanGroupList);
        const struct = data.getPlanGroupList.plan_groups.map((x) => ({
          ...x.plan_group,
          pending_history: x.pending_plan_group_history,
        }));
        setGroupList([]);
        setGroupList(sortArray(struct));
        setLoading(false);
      } else {
        setGroupList([]);
        setLoading(false);
      }
    } catch (error) {
      console.log("getPlanGroupList Error", error);
      setGroupList([]);
      setLoading(false);
    }
  };

  return { groupList, loading, getGroupList };
}

export default usePlanGroupList;
