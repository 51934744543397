const defaultStyles = {
  control: (base) => ({
    ...base,
    padding: "0px",
    borderColor: "#dfe1e6",
    background: "#fff",
    borderRadius: "0.25rem",
    "&:hover": {
      borderColor: "#dfe1e6",
      background: "#FFF",
    },
    // width: "100%",
  }),
  container: (base) => ({
    ...base,
    // -webkit-box-flex: 0,
    width: "100%",
    // flex: "0 0 66.666667%",
    // maxWidth: "66.666667%",
    // position: "relative",
  }),
  input: (
    provided
    // state
  ) => ({
    ...provided,
    color: "#172b4d",
    fontSize: "14px",
    fontWeight: 400,
    border: "0px",
    // marginTop: "20px",
    // :focus{
    // 	outline: none;
    // }
    "&:focus": {
      outLine: "none",
    },
  }),
  multiValue: (provided, state) => ({
    ...provided,
    opacity: state.isDisabled ? 0.5 : 1,
    transition: "opacity 300ms",
    background: state.data.isAdded
      ? "#cfc"
      : state.data.isRemoved
      ? "#DC3545"
      : state.data.isFixed
      ? "gray"
      : "#42526E",
    color: "#FFF",
    borderRadius: 100,
    paddingLeft: 10,
    paddingRight: (state.data.isFixed || state.data.isRemoved || state.data.isAdded ) ? 10 : 0,
    overflow: "hidden",
  }),
  multiValueLabel: (provided, state) => ({
    ...provided,
    fontFamily: "Roboto",
    fontSize: "13px",
    fontWeight: 500,
    color: state.data.isAdded ? "#064" : "#fff",
  }),
  multiValueRemove: (provided, state) =>
    state.data.isFixed || state.data.isRemoved||state.data.isAdded
      ? { ...provided, display: "none" }
      : {
          ...provided,
          color: "gray",
          "&:hover": {
            borderColor: "#42526E",
            color: "#FFF",
            background: "transparent",
            cursor: "pointer",
          },
        },
  valueContainer: (base) => ({
    ...base,
    fontSize: "14px",
    marginLeft: "4px",
    overflow: "visible",
  }),
  placeholder: (base, state) => ({
    ...base,
    color: "#172b4d",
    fontSize: "14px",
    // marginTop: "20px",
    position: "relative",
    display: state.isFocused && "none",
  }),
  menu: (base) => ({
    ...base,
    textAlign: "left",
    wordWrap: "break-word",
    overflow: "hidden",
  }),
  menuList: (base) => ({
    ...base,
    padding: 0,
  }),
  option: (base, { isSelected }) => ({
    ...base,
    color: isSelected ? "#FFF" : "#263a5be6 ",
    fontSize: "14px",
    background: isSelected ? "#263a5be6 " : "#fff",
    "&:hover": {
      color: "#fff",
      background: "#263a5baa",
      cursor: "pointer",
    },
    width: "100%",
  }),
};

export default defaultStyles;
