import { useState } from "react";
// import { toast } from "react-toastify";
// import { Popover } from "reactstrap";
// import { ACTIVATE_ORGANIZATION_EMPLOYEE_GQL } from "../../../Apollo/ApplicationService/OrganizationResponse";
// import graphHandler from "../../../Apollo/graphHandler";
// import OrganizationEmployeeModal from "../modal/OrganizationEmployeeModal";

export default function ListItem({
  item = {},
  i,
  organizationList=[],
  licenseList=[],
  refresh = () => {},
  access = {},
}) {
  const [isEdit, setEdit] = useState(false);
  // console.log("organization_employee_status",item.organization_employee_status)
  // const [showPop, setShowPop] = useState(false);
  // const toggle = () => setShowPop((x) => !x);

  // const activateOrganization = async (activate) => {
  //   toggle();
  //   let active_request = {
  //     activate,
  //     user_id: item.organization_employee.user_id,
  //   };
  //   try {
  //     await graphHandler().invoke(
  //       ACTIVATE_ORGANIZATION_EMPLOYEE_GQL,
  //       { active_request },
  //       0,
  //       false,
  //       true
  //     );
  //     toast.success(
  //       `${item.organization_employee.organization_employee_name} ${activate ? "Activated" : "Deactivated"} Successfully`
  //     );
  //     refresh();
  //   } catch (error) {
  //     console.log("activateUser error", error);
  //   }
  // };
  const getOrganization =()=>{
    let data = organizationList.find((x)=>x.organization_id === item.organization_id)?.organization_name;
    return data ?? "-"
  }
  const getLicenseNO =()=>{
    let data = licenseList.find((x)=>x.organization_license_id === item.organization_license_id)?.organization_license_number;
    return data ?? "-"
  }
  return (
    <tr className="option-table">
      <td>{getOrganization() || "-"}</td>
      <td>{getLicenseNO() || "-"}</td>
      <td>{item.transaction_id || "-"}</td>
      <td>{item.transaction_type || "-"}</td>
      <td>{item.amount || "-"}</td>
      <td>{item.cgst || "-"}</td>
      <td>{item.igst || "-"}</td>
      <td>{item.sgst || "-"}</td>
      <td>{item.vat || "-"}</td>
      <td>{item.total_amount || "-"}</td>
      <td></td>
      {/* {access.canEdit && (
        <td>
          <button
            type="button"
            className="btn btn-outline-success btn-sm "
            onClick={() => setEdit(true)}
          >
            Edit
          </button>
        </td>
      )} */}
      {/* <td
        className="accordion-menu"
        id={`AccordionTableMenu${i}`}
        onClick={toggle}
      >
        <i className="fas fa-ellipsis-v" />
      </td>
      <Popover
        isOpen={showPop}
        toggle={toggle}
        placement="left"
        target={`AccordionTableMenu${i}`}
        trigger="legacy"
      >
        <div className="accordion-menu-header">Options</div>

        <div
          className="accordion-menu-item"
          onClick={() => activateOrganization(!item.organization_employee.organization_employee_status)}
        >
          {!item.organization_employee.organization_employee_status ? "Activate" : "Deactivate"}
        </div>
      </Popover> */}
      {/* {access.canEdit && (
        <OrganizationEmployeeModal
          isOpen={isEdit}
          setOpen={() => setEdit(false)}
          refresh={refresh}
          values={item.organization_employee}
        />
      )} */}
    </tr>
  );
}
