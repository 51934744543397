import { useState } from "react";
import { GET_CATEGORY_LIST_GQL } from "../../Apollo/AdminServices/QueryRequest";
import graphHandler from "../../Apollo/graphHandler";
function useCategoryList() {
  const [categoryList, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);

  const getCategoryList = async (req = {}) => {
    setLoading(true);
    try {
      let { data } = await graphHandler().invoke(
        GET_CATEGORY_LIST_GQL,
        { ...req },
        1,
        false,
        true
      );
      if (
        data &&
        data.getCategoryList &&
        data.getCategoryList.categories &&
        data.getCategoryList.categories.length > 0
      ) {
        // console.log(
        //   "getCategoryList Success",
        //   data.getCategoryList.categories
        // );
        setCategories([]);
        setCategories(
          data.getCategoryList.categories
            .slice()
            .map((x) => ({
              ...x,
              label: x.category_name,
              value: x.category_id,
            }))
        );
        setLoading(false);
      } else {
        setCategories([]);
        setLoading(false);
      }
    } catch (error) {
      console.log("getCategoryList Error", error);
      setCategories([]);
      setLoading(false);
    }
  };
  return {
    categoryList,
    loading,
    getCategoryList,
  };
}
export default useCategoryList;
