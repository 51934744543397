import { useState } from "react";
import { GET_COUNTRY } from "../../Apollo/AdminServices/QueryRequest";
import graphHandler from "../../Apollo/graphHandler";

function useCountry() {
  const [country, setCountry] = useState({});
  const [loading, setLoading] = useState(true);

  const getCountry = async (country_id) => {
    setLoading(true);
    try {
      const { data } = await graphHandler().invoke(
        GET_COUNTRY,
        { country_id },
        1,
        false,
        true
      );
      if (data && data.getCountry && data.getCountry.country) {
        // console.log("getCountry Success", data.getCountry);
        setCountry({});
        setCountry(data.getCountry.country);
        setLoading(false);
      } else {
        setCountry({});
        setLoading(false);
      }
    } catch (error) {
      console.log("getCountry Error", error);
      setCountry({});
      setLoading(false);
    }
  };

  return {
    country,
    loading,
    getCountry,
  };
}

export default useCountry;
