import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { Popover } from "reactstrap";
import { Switch } from "antd";
import ApproveModal from "../../../components/Modal/ApproveModal";
import JsonCompareModal from "../../../components/Modal/JsonCompareModal";
// import useCategory from "../../../utils/CustomHooks/useCategory";
import useInterventionAPI from "../../../utils/CustomHooks/useInterventionAPI";
import EditInterventionModal from "../modal/EditInterventionModal";
import useForcePublish from "../../../utils/CustomHooks/useForcePublish";
import InterventionModal from "../modal/InterventionModal";

export default function InterventionListItem({
  index,
  item = {},
  refresh = () => {},
  access = {},
  categoryList = [],
}) {
  const { pending_intervention_history: intHistory } = item;
  const [isEdit, setEdit] = useState(false);
  const [isView, setView] = useState(false);
  const [showPop, setShowPop] = useState(false);
  const toggle = () => setShowPop((x) => !x);
  const [isOpenApprove, setOpenApprove] = useState(false);
  const [isApprove, setApprove] = useState(true);
  const isDone = true;
  const { forcePublishTool } = useForcePublish();
  // const { category, getCategory } = useCategory("");
  const history = useHistory();
  // console.log(item,"category",category,categoryList)
  const {
    approveIntervention,
    loading,
    reviewIntervention,
    activateIntervention,
  } = useInterventionAPI("");
  const hasHistory = intHistory && intHistory.update_status;

  const { canEdit, canApprove, canReview, isAdmin } = access;
  const readyApprove =
    intHistory &&
    ((intHistory.update_status == "ReviewSuccess" && canApprove) ||
      (intHistory.update_status == "Draft" && canReview));
  // useEffect(() => {
  //   if (item.category_ids) getCategory(item.category_ids[0]);
  // }, [item]);

  const activateItem = async (active) => {
    if (!item.intervention_id) return;
    toggle();
    const request = {
      active_request: {
        active,
        intervention_id: item.intervention_id,
      },
    };

    activateIntervention(request, onActivate(active));
  };

  const renderStatus = (status) => {
    if (
      hasHistory &&
      status !== "Rejected" &&
      status !== "Draft" &&
      status !== "Reviewfailed"
    ) {
      return <span className="good text-center btn btn-sm">{status}</span>;
    } else if (!hasHistory && item.active && item.published) {
      return <span className="good text-center btn btn-sm">Published</span>;
    } else if (!hasHistory && !item.active && item.published) {
      return <span className="bad text-center btn btn-sm">Disabled</span>;
    } else {
      return (
        <span className="bad text-center btn btn-sm">
          {status || "Rejected"}
        </span>
      );
    }
  };

  const onActivate = (active) => {
    setTimeout(() => {
      toast.success(
        `${item.intervention_name} ${
          active ? "Activated" : "Deactivated"
        } Successfully`
      );
      refresh();
    }, [1000]);
  };

  const onDone = (value) => {
    if (!hasHistory) return;
    if (intHistory.update_status == "Draft") {
      const request = {
        review_request: { ...value, approve: isApprove },
        intervention_history_id: intHistory.intervention_history_id,
      };
      reviewIntervention(request, refresh);
    } else if (intHistory.update_status == "ReviewSuccess") {
      const request = {
        approve_request: { ...value, approve: isApprove },
        intervention_history_id: intHistory.intervention_history_id,
      };
      approveIntervention(request, refresh);
    }
  };

  const getFilteredObject = (obj) => {
    const temp = { ...obj };
    delete temp.created_at;
    delete temp.created_by;
    delete temp.updated_at;
    delete temp.updated_by;
    delete temp.pending_intervention_history;
    delete temp.sub_domain_ids;
    delete temp.intervention_id;
    return temp;
  };

  return (
    <tr className="option-table">
      {isAdmin && (
        <td>
          <Switch checked={item.active} onChange={activateItem} />
        </td>
      )}
      <td>{item.intervention_name}</td>
      <td>
        {" "}
        {categoryList.length > 0 && item.category_ids
          ? item.category_ids
              .map((id) =>
                categoryList.find((x) => x.category_id == id)
                  ? categoryList.find((x) => x.category_id == id).category_name
                  : ""
              )
              .filter((x) => x)
              .join(", ")
          : "-"}
      </td>
      <td> {item.activity_type}</td>
      <td>
        {" "}
        {item.intervention_formats ? item.intervention_formats.join(", ") : "-"}
      </td>
      <td onClick={() => hasHistory && setView(true)}>
        {renderStatus(intHistory ? intHistory.update_status : "")}
      </td>
      <td className="d-flex">
        {isDone && hasHistory && readyApprove ? (
          <>
            <button
              type="button"
              className="btn btn-outline-success btn-sm "
              onClick={() => {
                setOpenApprove(true);
                setApprove(true);
              }}
            >
              {intHistory.update_status == "ReviewSuccess"
                ? "Approve"
                : "Review"}
            </button>
            <button
              type="button"
              className="ml-2 btn btn-danger btn-sm "
              onClick={() => {
                setOpenApprove(true);
                setApprove(false);
              }}
            >
              Reject
            </button>
          </>
        ) : (
          <button
            type="button"
            className="ml-2 btn btn-outline-success btn-sm "
            onClick={() => setEdit(true)}
          >
            {canEdit ? "Edit" : "View"}
          </button>
        )}
         {!hasHistory && isAdmin && item.published ? (
          <button
            type="button"
            className="ml-2 btn btn-outline-success btn-sm "
            onClick={() =>
              forcePublishTool(
                {
                  content_id: item.intervention_id,
                  content_type: "INTERVENTION",
                },
                refresh
              )
            }
          >
            Force Publish
          </button>
        ) : null}
      </td>
      <td className="accordion-menu" id={`AccordionTableMenu${index}`}>
        <i className="fas fa-ellipsis-v" />
      </td>
      <Popover
        placement="left"
        target={`AccordionTableMenu${index}`}
        trigger="legacy"
        isOpen={showPop}
        toggle={toggle}
      >
        <div className="accordion-menu-header">Options</div>
        {/* <div
          className="accordion-menu-item"
          onClick={() =>
            history.push(
              `/view-history?type=intervention&id=${item.intervention_id}`
            )
          }
        >
          View History
        </div> */}
        <div
          className="accordion-menu-item"
          onClick={() => {
            toggle();
            setEdit(true);
          }}
        >
          {isDone && !canEdit ? "View" : "Edit"}
        </div>
        {/* {!hasHistory && item.published && (
          <div
            className="accordion-menu-item"
            onClick={() => activateItem(!item.active)}
          >
            {!item.active ? "Activate" : "Deactivate"}
          </div>
        )} */}
        {hasHistory && (
          <div
            className="accordion-menu-item"
            onClick={() => {
              toggle();
              setView(true);
            }}
          >
            Compare
          </div>
        )}
      </Popover>
      <InterventionModal
        isOpen={isEdit}
        setOpen={() => setEdit(false)}
        initialValues={item}
        // title={category.category_name}
        refresh={() => {
          refresh((x) => x + 1);
        }}
        categoryList={categoryList}
        isEditable={canEdit}
      />
      {readyApprove && (
        <ApproveModal
          setOpen={() => setOpenApprove(false)}
          isOpen={isOpenApprove}
          isApprove={isApprove}
          title={
            isApprove
              ? intHistory.update_status == "ReviewSuccess"
                ? "Approve"
                : "Review"
              : "Reject"
          }
          refresh={refresh}
          onDone={onDone}
          isRequire={true}
          isShowDate={intHistory && intHistory.update_status == "ReviewSuccess"}
        />
      )}
      {hasHistory && (
        <JsonCompareModal
          setOpen={() => setView(false)}
          isOpen={isView}
          title={"Intervention Compare"}
          actualDate={getFilteredObject(item)}
          newDate={getFilteredObject(intHistory.updated_data)}
        />
      )}
    </tr>
  );
}
