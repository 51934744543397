import { Modal, ModalBody } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import graphHandler from "../../../Apollo/graphHandler";
import { ADD_COUNTRY_GQL } from "../../../Apollo/AdminServices/MutationRequest";
import CustomButton from "../../../components/Button/CustomButton";

export default function CreateCountry({ isOpen, setOpen, refresh }) {
  const formik = useFormik({
    initialValues: {},
    validationSchema: validation,
    onSubmit: (values) => {
      onSubmit(values);
    },
  });
  const onClose = () => {
    formik.resetForm();
    setOpen();
  };
  const onSubmit = async (values) => {
    const { country_name, country_short_code, country_mobile_number_prefix } =
      values;
    const addReq = {
      country_name:country_name.trim(),
      country_short_code:country_short_code.trim(),
      country_mobile_number_prefix,
    };

    try {
      await graphHandler().invoke(ADD_COUNTRY_GQL, { addReq }, 1, true);
      refresh();
      onClose();
      formik.setSubmitting(false);
    } catch (error) {
      console.log("CreateCountry error", error);
      formik.setSubmitting(false);
    }
  };

  const handleError = () => {
    if (Object.keys(formik.errors).length > 0) {
      toast.error(formik.errors[Object.keys(formik.errors)[0]]);
    }
  };

  return (
    <Modal toggle={onClose} isOpen={isOpen} centered scrollable size="xl">
      <div className="modal-header">
        <button type="button" className="close" onClick={onClose}>
          <span aria-hidden="true">&times;</span>
        </button>
        <h5 className="modal-title">Create Country</h5>
      </div>
      <ModalBody className="pt-0">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12 form-group  ">
              <label
                htmlFor="country_name"
                className="col-form-label w-100 mb-2"
              >
                Country
              </label>
              <input
                type="text"
                className="form-control"
                id="country_name"
                name="country_name"
                maxLength="40"
                value={
                  formik.values && formik.values.country_name
                    ? formik.values.country_name
                    : ""
                }
                onChange={(e) =>
                  formik.setFieldValue(
                    "country_name",
                    e.target.value.trimStart()
                  )
                }
                onBlur={(e) => {
                  formik.setFieldTouched("country_name", true);
                  formik.setFieldValue(
                    "country_name",
                    e.target.value.replace(/\s+/g, " ").trim()
                  );
                }}
              />
              <small className="text-danger">
                {formik.touched.country_name &&
                formik.errors &&
                formik.errors.country_name
                  ? formik.errors.country_name
                  : ""}
              </small>
            </div>
            <div className="col-md-12 form-group">
              <label
                htmlFor="supported_content_types"
                className="col-form-label w-100 mb-2"
              >
                Short Code
              </label>
              <input
                type="text"
                className="form-control"
                id="country_short_code"
                name="country_short_code"
                maxLength="10"
                value={
                  formik.values && formik.values.country_short_code
                    ? formik.values.country_short_code
                    : ""
                }
                onChange={(e) =>
                  formik.setFieldValue(
                    "country_short_code",
                    e.target.value.trimStart()
                  )
                }
                onBlur={formik.handleBlur}
              />
              <small className="text-danger">
                {formik.touched.country_short_code &&
                formik.errors &&
                formik.errors.country_short_code
                  ? formik.errors.country_short_code
                  : ""}
              </small>
            </div>
            <div className="col-md-12 form-group ">
              <label
                htmlFor="sub_domain_id"
                className="col-form-label w-100 mb-2"
              >
                Mobile Code
              </label>
              <input
                type="text"
                className="form-control"
                id="country_mobile_number_prefix"
                name="country_mobile_number_prefix"
                maxLength="6"
                value={
                  formik.values && formik.values.country_mobile_number_prefix
                    ? formik.values.country_mobile_number_prefix
                    : ""
                }
                onChange={(e) =>
                  formik.setFieldValue(
                    "country_mobile_number_prefix",
                    e.target.value.replace(/[^0-9+]/gi, "")
                  )
                }
                onBlur={formik.handleBlur}
              />
              <small className="text-danger">
                {formik.touched.country_mobile_number_prefix &&
                formik.errors &&
                formik.errors.country_mobile_number_prefix
                  ? formik.errors.country_mobile_number_prefix
                  : ""}
              </small>
            </div>

            <CustomButton
              type="button"
              className="btn btn-primary btn-outline-success float-right btn-add-domain"
              disabled={
                Object.keys(formik.errors).length !== 0 ||
                !formik.isValid ||
                formik.isSubmitting
              }
              onClick={
                formik.isValid && Object.keys(formik.values).length !== 0
                  ? formik.handleSubmit
                  : handleError
              }
            >
              Create
            </CustomButton>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
}

const CountryCode = /^[+-]?\d+$/;
const validation = Yup.object().shape({
  country_name: Yup.string().required("Enter country"),
  country_short_code: Yup.string().required("Enter Shortcode"),
  country_mobile_number_prefix: Yup.string()
    .required("Enter Mobile Code")
    .matches(CountryCode, "Country Code is not valid"),
});
