import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  ADD_ADMIN_ROLE_GQL,
  REMOVE_ADMIN_ROLE_GQL,
} from "../../../Apollo/AdminServices/MutationRequest";
import graphHandler from "../../../Apollo/graphHandler";
import { AdminRoles } from "../../../constants";

export default function UserRoleItem({
  edit,
  user_id,
  value = {},
  refresh,
  onRemoveFromList,
  roles = [],
  isAdmin,
}) {
  const subDomains = useSelector(({ DataSet }) => DataSet.subDomains);
  const [role, setRole] = useState(initialValues);
  const canAccessDomain = role.role_types.toLowerCase().includes("content");
  useEffect(() => {
    if (value.role_type) {
      setRole({
        role_types: value.role_type,
        sub_domain_id: value.sub_domain_id ? value.sub_domain_id : "",
      });
    }
  }, [value]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "role_types" && !value.toLowerCase().includes("content"))
      setRole((x) => ({ sub_domain_id: "", [name]: value }));
    else setRole((x) => ({ ...x, [name]: value }));
  };

  const onSubmit = async () => {
    if (!role.role_types) return toast.error("Please select a role");
    if (canAccessDomain && !role.sub_domain_id)
      return toast.error("Please select a domain");
    if (
      roles.filter((x) => x.role_type).length > 0 &&
      role.role_types === "ADMIN"
    ) {
      return toast.error("Please remove other roles before adding admin");
    }

    if (value.admin_role_id) {
      onRemove(value.admin_role_id, false, addRolesToAdmin);
    } else {
      if (roles.filter((x) => x.role_type === "ADMIN").length > 0)
        return toast.warn("Admin can access all the data!");
      addRolesToAdmin();
    }
  };

  const addRolesToAdmin = async () => {
    const temp = role;
    if (!temp.sub_domain_id) delete temp.sub_domain_id;
    const add_req = { user_id, allowed_roles: [temp] };
    try {
      const { data } = await graphHandler().invoke(
        ADD_ADMIN_ROLE_GQL,
        { add_req },
        1,
        true
      );
      refresh();
    } catch (error) {
      console.log("createAdmin error", error);
    }
  };

  const onRemove = async (
    admin_role_id = "",
    showSuccess = false,
    callback = () => {}
  ) => {
    if (admin_role_id) {
      try {
        const { data } = await graphHandler().invoke(
          REMOVE_ADMIN_ROLE_GQL,
          { admin_role_id },
          1,
          showSuccess
        );
        callback();
      } catch (error) {
        console.log("RemoveAdmin error", error);
      }
    } else {
      onRemoveFromList();
    }
  };

  return (
    <div className="role-wrapper d-flex">
      <div className="form-group  mr-3">
        <select
          name="role_types"
          disabled={!edit}
          className=""
          value={role.role_types}
          onChange={handleChange}
        >
          <option key="Select" hidden>
            Select
          </option>
          {AdminRoles.filter((x) =>
            edit ? (isAdmin ? x.value !== "ADMIN" : x.value === "ADMIN") : true
          ).map((x, i) => (
            <option key={i} value={x.value}>
              {x.name}
            </option>
          ))}
        </select>
      </div>
      <div className="form-group  mr-3">
        {canAccessDomain && (
          <select
            name="sub_domain_id"
            className=""
            value={role.sub_domain_id}
            disabled={!edit || !canAccessDomain}
            onChange={handleChange}
          >
            <option key="Select" hidden>
              {canAccessDomain ? "Select Domain" : "Non Available"}
            </option>
            {subDomains.map((x, i) => (
              <option key={i} value={x.sub_domain_id}>
                {x.sub_domain_name}
              </option>
            ))}
          </select>
        )}
        {edit && role.role_types ? (
          <i
            className="ml-2 text-success cursor-pointer fa fa-check"
            onClick={() => onSubmit()}
          />
        ) : null}
        {edit && role.role_types ? (
          <i
            className="ml-2 text-danger cursor-pointer fa fa-times"
            onClick={() =>
              roles.length === 1
                ? toast.warn("Cannot remove all roles")
                : onRemove(value.admin_role_id, refresh())
            }
          />
        ) : null}
      </div>
    </div>
  );
}

const initialValues = {
  role_types: "",
  sub_domain_id: "",
};
