import { useState } from "react";
import {
  ACTIVATE_PLAN_GQL,
  APPROVE_PLAN_GQL,
  REMOVE_PLAN_ACTION_GQL,
  REMOVE_PLAN_GROUP_GQL,
  REVIEW_PLAN_GQL,
} from "../../Apollo/AdminServices/NewMutationRequest";
import graphHandler from "../../Apollo/graphHandler";
function usePlanAPI() {
  const [loading, setLoading] = useState(false);
  const approvePlan = async (req = {}, callback = () => {}) => {
    setLoading(true);
    try {
      const { data } = await graphHandler().invoke(
        APPROVE_PLAN_GQL,
        req,
        1,
        false,
        true
      );
      // console.log("approvePlan Success", data.approvePlan);
      callback();
      setLoading(false);
    } catch (error) {
      console.log("approvePlan Error", error);
      setLoading(false);
    }
  };
  const reviewPlan = async (req = {}, callback = () => {}) => {
    setLoading(true);
    try {
      const { data } = await graphHandler().invoke(
        REVIEW_PLAN_GQL,
        req,
        1,
        false,
        true
      );

      // console.log("reviewPlan Success", data.reviewPlan);
      callback();
      setLoading(false);
    } catch (error) {
      console.log("reviewPlan Error", error);
      setLoading(false);
    }
  };
  const activatePlan = async (req = {}, callback = () => {}) => {
    setLoading(true);
    try {
      const { data } = await graphHandler().invoke(
        ACTIVATE_PLAN_GQL,
        req,
        1,
        false,
        true
      );
      // console.log("activatePlan Success", data.activatePlan);
      callback();
      setLoading(false);
    } catch (error) {
      console.log("activatePlan Error", error);
      setLoading(false);
    }
  };

  const removeGroup = async (
    req = {},
    callback = () => {},
    eCallBack = () => {}
  ) => {
    setLoading(true);
    try {
      const { data } = await graphHandler().invoke(
        REMOVE_PLAN_GROUP_GQL,
        req,
        1,
        false,
        true
      );
      // console.log("removeGroup Success", data.removePlanGroup);
      callback();
      setLoading(false);
    } catch (error) {
      eCallBack(error);
      console.log("removeGroup Error", error);
      setLoading(false);
    }
  };

  const removeAction = async (
    req = {},
    callback = () => {},
    eCallBack = () => {}
  ) => {
    setLoading(true);
    try {
      const { data } = await graphHandler().invoke(
        REMOVE_PLAN_ACTION_GQL,
        req,
        1,
        false,
        true
      );
      // console.log("removeAction Success", data.removePlanAction);
      callback();
      setLoading(false);
    } catch (error) {
      eCallBack(error);
      console.log("removeAction Error", error);
      setLoading(false);
    }
  };

  return {
    approvePlan,
    loading,
    reviewPlan,
    activatePlan,
    removeAction,
    removeGroup,
  };
}
export default usePlanAPI;
