import { useEffect, useState } from "react";
import { Modal, ModalBody } from "reactstrap";
import { useSelector } from "react-redux";
import CustomLoader from "../../../components/Loader";
import { Steps } from "antd";
// import RelatedContentsGroup from "./RelatedContentsGroup";
import useRelatedContentList from "../../../utils/CustomHooks/useRelatedContentList";
import RelatedContentsGroup from "../../../components/RelatedContents/component/RelatedContentsGroup";
import AddInterventionModal from "./AddInterventionModal";
import EditInterventionModal from "./EditInterventionModal";
// import RelatedContents from "../../../components/RelatedContents";
const { Step } = Steps;

export default function InterventionModal({
  initialValues = {},
  isOpen,
  setOpen,
  refresh,
  categoryList,
  isEditable = true,
  isFirst = false,
}) {
  const [activeKey, setActive] = useState(0);
  const [intervention, setIntervention] = useState({});
  const [loading, setLoading] = useState(false);
  const [reqCount, setReqCount] = useState(0);
  // console.log("reqCount",reqCount)
  const {
    relatedContents,
    loading: relatedContentLoading,
    getRelatedContents,
  } = useRelatedContentList();

  useEffect(() => {
    initialValues.intervention_id && setIntervention(initialValues);
  }, [initialValues, isOpen]);

  useEffect(() => {
    if (intervention.intervention_id && isOpen) {
      getRelatedContents({
        content_id: intervention.intervention_id,
        content_type: "INTERVENTION",
      });
    }
  }, [intervention, reqCount, isOpen]);

  const InterventionSection = intervention.intervention_id
    ? EditInterventionModal
    : AddInterventionModal;
  const renderSteps = (key) => {
    switch (key) {
      case 0:
        return (
          <InterventionSection
            refresh={refresh}
            onDone={() => setActive(1)}
            onClose={onClose}
            intervention={intervention}
            isEditable={isEditable}
            isFirst={isFirst}
            values={intervention}
            categoryList={categoryList}
            setIntervention={setIntervention}
            setLoading={setLoading}
          />
        );
      case 1:
        return (
          <RelatedContentsGroup
            refresh={() => setReqCount((x) => x + 1)}
            onClose={onClose}
            onBack={() => setActive(0)}
            contentId={intervention.intervention_id}
            isEditable={isFirst || isEditable}
            setLoading={setLoading}
            contentType="INTERVENTION"
            relatedContentList={relatedContents}
            showDesc={true}
          />
        );
      default:
        return <h5>No step found</h5>;
    }
  };
  const onClose = () => {
    setOpen(false);
    setActive(0);
    setIntervention({});
    refresh();
  };

  const onChangeStep = (e) => {
    if (e === 1 && !intervention.intervention_id) return;
    setActive(e);
  };

  return (
    <Modal
      toggle={onClose}
      isOpen={isOpen}
      size="lg"
      keyboard={false}
      modalClassName="modal-fullscreen modal-dialog-scrollable"
    >
      <div className="modal-header">
        <Steps
          type="navigation"
          current={activeKey}
          onChange={onChangeStep}
          className="site-navigation-steps"
        >
          <Step
            status={intervention.intervention_id ? "finish" : "process"}
            title={
              intervention.intervention_id
                ? "Intervention"
                : "Create Intervention"
            }
          />
          <Step
            status={
              relatedContents.length > 0
                ? "finish"
                : intervention.intervention_id
                ? "process"
                : "wait"
            }
            disabled={!intervention.intervention_id}
            title="Related Content"
          />
        </Steps>
      </div>
      <ModalBody>
        {(loading || relatedContentLoading) && <CustomLoader />}
        {renderSteps(activeKey)}
      </ModalBody>
    </Modal>
  );
}
