import { useState } from "react";
import { GET_SUBDOMAIN_LIST_GQL } from "../../Apollo/AdminServices/QueryRequest";
import graphHandler from "../../Apollo/graphHandler";

function useSubDomainList(preload = false) {
  const [subDomains, setSubDomains] = useState([]);
  const [loading, setLoading] = useState(preload);

  const getSubDomains = async (req = {}, clear = false) => {
    setLoading(true);
    try {
      const { data } = await graphHandler().invoke(
        GET_SUBDOMAIN_LIST_GQL,
        { ...req },
        1,
        false,
        clear
      );
      if (
        data &&
        data.getSubDomainList &&
        data.getSubDomainList.status === "Success" &&
        data.getSubDomainList.sub_domains &&
        data.getSubDomainList.sub_domains.length > 0
      ) {
        // console.log("getSubDomain Success", data.getSubDomain.sub_domain);
        setSubDomains(data.getSubDomainList.sub_domains);
        setLoading(false);
      } else {
        setSubDomains([]);
        setLoading(false);
      }
    } catch (error) {
      console.log("getSubDomains Error", error);
      setSubDomains([]);
      setLoading(false);
    }
  };

  return {
    subDomains,
    loading,
    getSubDomains,
  };
}

export default useSubDomainList;
