import { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import graphHandler from "../../../Apollo/graphHandler";
import {
  IsNullOrEmpty,
  mergeMultiSelectArray,
  objectHasKey,
  removeEmptyObjValue,
} from "../../../utils/Functions/filter";
import { UPDATE_INTERVENTION_GQL } from "../../../Apollo/AdminServices/MutationRequest";
import { ActivityType, InterventionFormat } from "../../../constants";
import TextInputField from "../../../components/HistoryEditInput/TextInputField";
// import ReactSelectInputField from "../../../components/HistoryEditInput/ReactSelectInputField";
import SelectInputField from "../../../components/HistoryEditInput/SelectInputField";
import DescriptionInputField from "../../../components/HistoryEditInput/DescriptionInputField";
import MetaHistoryInput from "../../../components/HistoryEditInput/MetaHistoryInput";
import DocumentHistoryInput from "../../../components/HistoryEditInput/DocumentHistoryInput";
import ImageHistoryInput from "../../../components/HistoryEditInput/ImageHistoryInput";
import VideoHistoryInput from "../../../components/HistoryEditInput/VideoHistoryInput";
import CustomButton from "../../../components/Button/CustomButton";
import NewReactSelectInputField from "../../../components/HistoryEditInput/NewReactSelectInputField";
import useInterventionAPI from "../../../utils/CustomHooks/useInterventionAPI";
import { Popconfirm } from "antd";

export default function EditInterventionModal({
  values = {},
  isEditable = false,
  onDone = () => {},
  refresh = () => {},
  categoryList = [],
  setIntervention = () => {},
  setLoading = () => {},
  onClose = () => {},
}) {
  const [categories, setCategories] = useState([]);
  const subDomains = useSelector(({ DataSet }) => DataSet.subDomains);
  const [subDomainList, setSubDomainList] = useState([]);
  const [selectedList, setSelectedList] = useState([]);
  const [selectedCategoryList, setSelectedCategoryList] = useState([]);
  const [selectedFormatList, setSelectedFormatList] = useState([]);
  // const [loading, setLoading] = useState(true);
  const [canEdit, setEdit] = useState(false);

  // const [removeBanner, setRemoveBanner] = useState([]);
  //   const { interventionList, getInterventionList, resetIntervention } =
  //     useInterventionList();
  const intervention_history = values.pending_intervention_history
    ? values.pending_intervention_history
    : {};
  const hasUpdated = Boolean(
    intervention_history && intervention_history.updated_data
  );
  const updated_data = hasUpdated ? intervention_history.updated_data : {};
  const formik = useFormik({
    initialValues: {
      // ...defaultValue,
    },
    // validationSchema: validationSchema,
    onSubmit: (values) => {
      onSubmit(values);
    },
  });
  const { reviewIntervention } = useInterventionAPI("");
  useEffect(() => {
    if (values?.intervention_id) {
      onSetDefault(values, hasUpdated ? updated_data : {});
    }
  }, [values, subDomainList, categories, InterventionFormat]);

  const onSetDefault = async (data = {}, publish = {}) => {
    const { sub_domain_ids, category_ids, intervention_formats } = data;
    if (subDomainList.length > 0) {
      let subArray = [],
        sub_domains =
          sub_domain_ids && sub_domain_ids.length > 0 ? sub_domain_ids : [],
        publishedSub =
          publish.sub_domain_ids &&
          publish.sub_domain_ids.update_items &&
          publish.sub_domain_ids.update_items.length > 0
            ? publish.sub_domain_ids.update_items
            : [];
      let value = mergeMultiSelectArray(sub_domains, publishedSub);
      let i = 0;
      subArray = value.map((x) => {
        let ret = {
          ...subDomainList.find((y) => y.value === x.data),
          ...x,
          index: i,
        };
        if (x.isRemoved) {
          ret.value = subDomainList.find((y) => y.value === x.data).label || "";
        } else {
          i++;
        }
        return ret;
      });
      setSelectedList(subArray);
    }
    if (categories.length > 0) {
      let subArray = [],
        categoryArray =
          (await category_ids) && category_ids.length > 0 ? category_ids : [],
        publishedSub =
          (await publish.category_ids) &&
          publish.category_ids.update_items &&
          publish.category_ids.update_items.length > 0
            ? publish.category_ids.update_items
            : [];
      let value = await mergeMultiSelectArray(categoryArray, publishedSub);
      let i = 0;
      subArray = value.map((x) => {
        let ret = {
          ...categories.find((y) => y.value === x.data),
          ...x,
          index: i,
        };
        if (x.isRemoved) {
          ret.value = categories.find((y) => y.value === x.data).label || "";
        } else {
          i++;
        }
        return ret;
      });
      setSelectedCategoryList(subArray);
    }
    if (InterventionFormat.length > 0) {
      let subArray = [],
        formatArray =
          (await intervention_formats) && intervention_formats.length > 0
            ? intervention_formats
            : [],
        publishedSub =
          (await publish.intervention_formats) &&
          publish.intervention_formats.update_items &&
          publish.intervention_formats.update_items.length > 0
            ? publish.intervention_formats.update_items
            : [];
      let value = await mergeMultiSelectArray(formatArray, publishedSub);
      let i = 0;
      subArray = value.map((x) => {
        let ret = {
          ...InterventionFormat.find((y) => y.value === x.data),
          ...x,
          index: i,
        };
        if (x.isRemoved) {
          ret.value =
            InterventionFormat.find((y) => y.value === x.data).label +
              Date.now() || "";
        } else {
          i++;
        }
        return ret;
      });
      setSelectedFormatList(subArray);
    }
  };

  useEffect(() => {
    setSubDomainList(
      subDomains.map((x) => ({
        label: x.sub_domain_name,
        value: x.sub_domain_id,
      }))
    );
  }, [subDomains, values]);

  useEffect(() => {
    setCategories(
      categoryList.map((x) => ({
        label: x.category_name,
        value: x.category_id,
      }))
    );
  }, [categoryList, values]);

  const onClosed = () => {
    onClose();
    formik.resetForm();
    formik.setValues({ ...defaultValue });
  };

  useEffect(() => {
    if (
      values.intervention_id &&
      subDomainList.length > 0 &&
      categories.length > 0
    ) {
      // const value = {intervention_id:values.intervention_id};
      // formik.setValues(value);
      setLoading(false);
    }
  }, [values, categories, subDomainList]);

  const onSubmit = async (value) => {
    const {
      intervention_name,
      intervention_code,
      intervention_description,
      activity_type,
      intervention_formats,
      intervention_duration,
      days_per_week,
      intervention_meta_datas,
      alias_name,
      sub_domain_ids,
      category_ids,
      intervention_image_urls,
      intervention_video_urls,
      intervention_document_urls,
    } = value;

    const update_request = { intervention_id: values?.intervention_id };

    if (
      sub_domain_ids &&
      sub_domain_ids.length > 0 &&
      objectHasKey(value, "sub_domain_ids")
    ) {
      update_request.sub_domain_ids = { update_items: sub_domain_ids };
    }
    if (objectHasKey(value, "intervention_code")) {
      update_request.intervention_code = intervention_code;
    }
    if (objectHasKey(value, "category_ids")) {
      update_request.category_ids = { update_items: category_ids };
    }
    if (objectHasKey(value, "intervention_formats")) {
      update_request.intervention_formats = {
        update_items: intervention_formats,
      };
    }
    if (objectHasKey(value, "activity_type")) {
      update_request.activity_type = activity_type;
    }
    if (
      intervention_name != value.intervention_name ||
      intervention_name != updated_data.intervention_name
    ) {
      delete update_request.intervention_name;
    }
    if (objectHasKey(value, "intervention_name")) {
      update_request.intervention_name = intervention_name;
    }
    if (objectHasKey(value, "alias_name")) {
      update_request.alias_name = alias_name;
    }
    if (objectHasKey(value, "intervention_duration")) {
      update_request.intervention_duration = Number(intervention_duration) * 60;
    }
    if (objectHasKey(value, "days_per_week")) {
      update_request.days_per_week = Number(days_per_week);
    }
    if (objectHasKey(value, "intervention_description")) {
      update_request.intervention_description = {
        update_items: intervention_description,
      };
    }
    if (objectHasKey(value, "intervention_meta_datas")) {
      update_request.intervention_meta_datas = {
        update_items: intervention_meta_datas.map((x) =>
          removeEmptyObjValue(x)
        ),
      };
    }
    // if (objectHasKey(value, "related_contents")) {
    //   update_request.related_contents = {
    //     update_items: related_contents
    //    };
    // }
    if (objectHasKey(value, "intervention_image_urls")) {
      update_request.intervention_image_urls = {
        update_items: intervention_image_urls.map((x) =>
          removeEmptyObjValue(x)
        ),
      };
    }
    if (objectHasKey(value, "intervention_video_urls")) {
      update_request.intervention_video_urls = {
        update_items: intervention_video_urls.map((x) =>
          removeEmptyObjValue(x)
        ),
      };
    }
    if (objectHasKey(value, "intervention_document_urls")) {
      update_request.intervention_document_urls = {
        update_items: intervention_document_urls.map((x) =>
          removeEmptyObjValue(x)
        ),
      };
    }
    if (Object.keys(update_request).length === 1) {
      refresh();
      formik.setSubmitting(false);
      setLoading(false);
      return;
    }
    try {
      // console.log("update_request", update_request);
      // formik.setSubmitting(false);
      //  return
      let { data } = await graphHandler().invoke(
        UPDATE_INTERVENTION_GQL,
        { update_request },
        1,
        true
      );
      if (
        data &&
        data.updateIntervention &&
        data.updateIntervention.intervention
      ) {
        // console.log("updatePlan Success", data.updatePlan.plan);
        setIntervention({
          ...data.updateIntervention.intervention,
          pending_intervention_history:
            data.updateIntervention.intervention_history,
        });
        onDone();
        refresh();
        setLoading(false);
        formik.setSubmitting(false);
      }
      // console.log("update data", data);
    } catch (error) {
      console.log("update Intervention error", error);
    }
  };
  const handleError = () => {
    if (Object.keys(formik.errors).length > 0) {
      toast.error(formik.errors[Object.keys(formik.errors)[0]]);
    }
  };
  const handleDiscord = () => {
    const request = {
      review_request: {
        comment: "Discord Values",
        approve: false,
      },
      intervention_history_id: intervention_history.intervention_history_id,
    };
    let close = () => {
      refresh();
      onClosed();
    };
    reviewIntervention(request, close);
  };
  return (
    <>
      <div className="container p-5 position-relative">
        {isEditable && (
          <button
            type="button"
            className="btn btn-link position-absolute"
            style={{ top: "0px", right: "60px" }}
            onClick={() => setEdit((x) => !x)}
          >
            {canEdit ? (
              <>
                <i className="fas fa-times"></i> Cancel
              </>
            ) : (
              <>
                <i className="fas fa-pen"></i> Edit
              </>
            )}
          </button>
        )}
        <div className="intervention-form row">
          <NewReactSelectInputField
            parentForm={formik}
            id="sub_domain_ids"
            className="col-md-6"
            title="Sub Domain"
            placeholder="Select Sub Domain"
            options={subDomainList}
            isCreatable={false}
            isMulti={true}
            disabled={!canEdit}
            isPublished={values.published}
            defaultValue={selectedList}
            updateValue={
              updated_data.sub_domain_ids &&
              updated_data.sub_domain_ids.update_items &&
              updated_data.sub_domain_ids.update_items.length > 0
                ? updated_data.sub_domain_ids.update_items
                : false
            }
            validation={{
              sub_domain_ids: Yup.array()
                .of(
                  Yup.object().shape({
                    label: Yup.string(),
                    value: Yup.string(),
                  })
                )
                .test({
                  message: "Select a sub domain",
                  test: (arr) => {
                    return arr && arr.length > 0;
                  },
                }),
            }}
          />
          <NewReactSelectInputField
            parentForm={formik}
            className="col-md-6"
            id="category_ids"
            title="Category"
            placeholder="Select a category"
            options={categories}
            isPublished={values.published}
            isCreatable={false}
            isMulti={true}
            disabled={!canEdit}
            defaultValue={selectedCategoryList}
            updateValue={
              updated_data.category_ids &&
              updated_data.category_ids.update_items &&
              updated_data.category_ids.update_items.length > 0
                ? updated_data.category_ids.update_items
                : false
            }
            validation={{
              category_ids: Yup.array().of(
                Yup.object().shape({
                  label: Yup.string(),
                  value: Yup.string(),
                })
              ),
              // .test({
              //   message: "Select a category",
              //   test: (arr) => {
              //     return arr && arr.length > 0;
              //   },
              // }),
            }}
          />
          <TextInputField
            parentForm={formik}
            className="col-md-6"
            updateValue={updated_data.intervention_code}
            actualValue={values.intervention_code}
            id="intervention_code"
            title="Code"
            disabled={values.intervention_id}
            validation={{
              intervention_code: Yup.string()
                .trim()
                .required("Enter a intervention code"),
            }}
          />

          <TextInputField
            parentForm={formik}
            className="col-md-6"
            updateValue={updated_data.intervention_name}
            actualValue={values.intervention_name}
            id="intervention_name"
            title="Name"
            disabled={!canEdit}
            customBlur={true}
            validation={{
              intervention_name: Yup.string()
                .trim()
                .required("Enter a intervention name"),
            }}
          />
          <div className="col-md-6">
            <div className="row">
              <TextInputField
                parentForm={formik}
                className="col-md-12"
                updateValue={updated_data.alias_name ?? ""}
                actualValue={values.alias_name ?? ""}
                id="alias_name"
                title="Alias Name"
                disabled={!canEdit}
                customBlur={true}
                validation={{
                  alias_name: Yup.string().trim(),
                }}
              />
              <SelectInputField
                parentForm={formik}
                className="col-md-12"
                updateValue={updated_data.activity_type}
                actualValue={values.activity_type}
                id="activity_type"
                title="Activity Type"
                disabled={!canEdit}
                validation={{
                  activity_type: Yup.string().required(
                    "Select a activity type"
                  ),
                }}
                options={[
                  ...ActivityType.map((x) => {
                    return {
                      label: x,
                      value: x,
                    };
                  }),
                ]}
              />

              <NewReactSelectInputField
                parentForm={formik}
                className="col-md-12"
                id="intervention_formats"
                title="Format"
                isPublished={values.published}
                placeholder="Select a format"
                options={InterventionFormat}
                isCreatable={false}
                isMulti={true}
                disabled={!canEdit}
                defaultValue={selectedFormatList}
                updateValue={
                  updated_data.intervention_formats &&
                  updated_data.intervention_formats.update_items &&
                  updated_data.intervention_formats.update_items.length > 0
                    ? updated_data.intervention_formats.update_items
                    : false
                }
                validation={{
                  intervention_formats: Yup.array()
                    .of(
                      Yup.object().shape({
                        label: Yup.string(),
                        value: Yup.string(),
                      })
                    )
                    .test({
                      message: "Select a format",
                      test: (arr) => {
                        return arr && arr.length > 0;
                      },
                    }),
                }}
              />

              <div className="col-md-12">
                <div className="row">
                  <TextInputField
                    parentForm={formik}
                    className="col-md-6"
                    updateValue={
                      !IsNullOrEmpty(updated_data.intervention_duration)
                        ? updated_data.intervention_duration / 60
                        : ""
                    }
                    disabled={!canEdit}
                    actualValue={
                      !IsNullOrEmpty(values.intervention_duration)
                        ? values.intervention_duration / 60
                        : ""
                    }
                    id="intervention_duration"
                    title="Duration Per Day (in minutes)"
                    validation={{
                      intervention_duration: Yup.string().trim(),
                    }}
                    isNumber={true}
                    inputProps={{
                      maxLength: 10,
                    }}
                  />
                  <TextInputField
                    parentForm={formik}
                    className="col-md-6"
                    updateValue={
                      !IsNullOrEmpty(updated_data.days_per_week)
                        ? updated_data.days_per_week
                        : ""
                    }
                    disabled={!canEdit}
                    actualValue={
                      !IsNullOrEmpty(values.days_per_week)
                        ? values.days_per_week
                        : ""
                    }
                    id="days_per_week"
                    title="Days Per Week (in days)"
                    validation={{
                      days_per_week: Yup.string().trim(),
                    }}
                    isNumber={true}
                    inputProps={{
                      maxLength: 10,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <DescriptionInputField
            parentForm={formik}
            parentKey="intervention_description"
            className="col-md-6"
            updateValue={
              updated_data.intervention_description &&
              updated_data.intervention_description.update_items
                ? updated_data.intervention_description.update_items
                : false
            }
            disabled={!canEdit}
            actualValue={values.intervention_description}
            validation={{
              description: Yup.string().trim().required("Enter a description"),
              mobile_description: Yup.string()
                .trim()
                .required("Enter a mobile description"),
            }}
            showEditor={true}
          />
          <div className="col-md-12">
            <MetaHistoryInput
              parentForm={formik}
              parentArray="intervention_meta_datas"
              typeKey="meta_data_type"
              titleKey="meta_data_title"
              valueKey="meta_data_value"
              updateValue={
                updated_data.intervention_meta_datas &&
                updated_data.intervention_meta_datas.update_items
                  ? updated_data.intervention_meta_datas.update_items
                  : false
              }
              isPublished={values.published}
              disabled={!canEdit}
              validation={{
                intervention_meta_datas: Yup.array().of(
                  Yup.object().shape({
                    meta_data_type: Yup.string()
                      .trim()
                      .required("Select a data type"),
                    meta_data_title: Yup.string()
                      .trim()
                      .required("Enter a title"),
                    meta_data_value: Yup.string()
                      .trim()
                      .required("Enter a value"),
                  })
                ),
              }}
              actualValue={values.intervention_meta_datas}
            />
          </div>
          <div className="col-md-12">
            <ImageHistoryInput
              parentForm={formik}
              contentId={values.intervention_id}
              contentType="INTERVENTION"
              parentArray="intervention_image_urls"
              actualValue={values.intervention_image_urls}
              updateValue={
                updated_data.intervention_image_urls &&
                updated_data.intervention_image_urls.update_items
                  ? updated_data.intervention_image_urls.update_items
                  : false
              }
              isPublished={values.published}
              disabled={!canEdit}
              validation={{
                intervention_image_urls: Yup.array().of(
                  Yup.object().shape({
                    is_mobile: Yup.boolean().required("Select a Image device"),
                    image_type: Yup.string().required("Select a Image format"),
                    url: Yup.string(),
                  })
                ),
              }}
            />
          </div>
          <div className="col-md-12">
            <VideoHistoryInput
              parentForm={formik}
              contentId={values.intervention_id}
              contentType="INTERVENTION"
              parentArray="intervention_video_urls"
              actualValue={values.intervention_video_urls}
              updateValue={
                updated_data.intervention_video_urls &&
                updated_data.intervention_video_urls.update_items
                  ? updated_data.intervention_video_urls.update_items
                  : false
              }
              isPublished={values.published}
              disabled={!canEdit}
              validation={{
                intervention_video_urls: Yup.array().of(
                  Yup.object().shape({
                    is_mobile: Yup.boolean().required("Select a video device"),
                    video_type: Yup.string().required("Select a video format"),
                    video_url: Yup.string(),
                  })
                ),
              }}
            />
          </div>
          <div className="col-md-12">
            <DocumentHistoryInput
              parentForm={formik}
              contentId={values.intervention_id}
              contentType="INTERVENTION"
              parentArray="intervention_document_urls"
              actualValue={values.intervention_document_urls}
              updateValue={
                updated_data.intervention_document_urls &&
                updated_data.intervention_document_urls.update_items
                  ? updated_data.intervention_document_urls.update_items
                  : false
              }
              isPublished={values.published}
              disabled={!canEdit}
              validation={{
                intervention_document_urls: Yup.array().of(
                  Yup.object().shape({
                    is_mobile: Yup.boolean().required("Select a video device"),
                    document_type: Yup.string().required(
                      "Select a video format"
                    ),
                    url: Yup.string(),
                    name: Yup.string(),
                  })
                ),
              }}
            />
          </div>
          <div className="d-flex align-items-end justify-content-end col-md-12">
            {isEditable && canEdit && hasUpdated && (
              <Popconfirm
                title="Are you sure to discard and close?"
                onConfirm={handleDiscord}
                //  onCancel={cancel}
                okText="Yes"
                cancelText="No"
              >
                <button
                  type="button"
                  className="btn btn-danger btn-sm float-right btn-add-domain mr-2"
                >
                  Discard All & Close
                </button>
              </Popconfirm>
            )}
            <button
              onClick={onClosed}
              type="button"
              className="btn btn-danger btn-sm float-right btn-add-domain mr-2"
            >
              Close
            </button>
            {canEdit ? (
              <CustomButton
                type="button"
                className="btn btn-primary btn-outline-success btn-sm float-right btn-add-domain"
                disabled={Object.keys(formik.errors).length !== 0}
                onClick={
                  formik.isValid && Object.keys(formik.values).length !== 0
                    ? formik.handleSubmit
                    : handleError
                }
              >
                Update
              </CustomButton>
            ) : (
              <button
                onClick={onDone}
                type="button"
                className="btn btn-secondary btn-sm float-right btn-add-domain mr-2"
              >
                Next
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

var defaultValue = {
  sub_domain_ids: [],
  // intervention_meta_datas: [],
};
