export default function InputFormik({
  formik,
  id = "id",
  label = "label",
  props = {},
  className = "col-md-6",
}) {
  return (
    <div className={`form-group ${className}`}>
      <label htmlFor={id} className="col-form-label w-100 mb-2">
        {label}
      </label>
      <div className="d-flex flex-column p-0">
        <input
          type="text"
          className="form-control h-100 w-100"
          //   placeholder={questionaireList.length + 1}
          placeholder=""
          id={id}
          name={id}
          max={100}
          value={formik.values && formik.values[id] ? formik.values[id] : ""}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          {...props}
        />
        <small className="text-danger">
          {formik.touched[id] && formik.errors && formik.errors[id]
            ? formik.errors[id]
            : ""}
        </small>
      </div>
    </div>
  );
}
