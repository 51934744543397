import React, { useEffect, useState } from "react";
import useInterventionList from "../../../utils/CustomHooks/useInterventionList";
import useModuleList from "../../../utils/CustomHooks/useModuleList";
import useScreenerList from "../../../utils/CustomHooks/useScreenerList";
import CreateModuleModal from "../modal/CreateModuleModal";
import ModuleTableItem from "./ModuleTableItem";

export default function ModuleList({ therapy_id, therapy = {}, access = {} }) {
  const { moduleList, getModuleList } = useModuleList("");
  const [count, setCount] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const { interventionList, getInterventionList } = useInterventionList("");
  const { screenerList, getScreenerList } = useScreenerList("");

  const { canEdit, canCreate } = access;
  useEffect(() => {
    getInterventionList({ published: true, get_pending_update: false });
    getScreenerList(
      { published: true, screener_type: "DomainScreener" },
      false
    );
  }, []);

  useEffect(() => {
    if (therapy_id) getModuleList({ therapy_id });
  }, [count, therapy_id]);

  return (
    <>
      <h5 className="mb-4">
        Modules{" "}
        <span className="cursor-pointer">
          {(canCreate || canEdit) && (
            <button
              type="button"
              className="btn btn-primary btn-outline-success btn-sm float-right btn-add-domain"
              onClick={() => setIsOpen(true)}
            >
              Add or Update
            </button>
          )}
        </span>
      </h5>
      <table
        className="table table-borderless overflow-auto questionnaire-table"
        style={{ maxHeight: "200px" }}
      >
        <thead>
          <tr className=" ">
            <td className="col-sm-4">Name</td>
            <td className="col-sm-4">Description</td>
            <td className="col-sm-2">No of Sessions</td>
            <td className="col-sm-2" colSpan="2">
              Order
            </td>
          </tr>
        </thead>
        <tbody>
          {moduleList && moduleList.length > 0 ? (
            moduleList
              .slice()
              .sort((a, b) => a.sort_order - b.sort_order)
              .map((item, i) => (
                <ModuleTableItem
                  item={item}
                  key={i}
                  i={i}
                  refresh={() => setCount((x) => x + 1)}
                  interventionList={interventionList}
                  screenerList={screenerList}
                />
              ))
          ) : (
            <tr className="border-bottom">
              <td className="text-center" colSpan="5">
                No Modules Found. Add Some Modules
              </td>
            </tr>
          )}
        </tbody>
      </table>
      <CreateModuleModal
        isOpen={isOpen}
        setOpen={() => setIsOpen(false)}
        refresh={() => {
          setCount((x) => x + 1);
        }}
        therapy={therapy}
      />
    </>
  );
}
