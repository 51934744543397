import { useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Modal, ModalBody } from "reactstrap";
import { toast } from "react-toastify";
import graphHandler from "../../../Apollo/graphHandler";
import CustomButton from "../../../components/Button/CustomButton";
import { UPDATE_TEMPLATE_GQL } from "../../../Apollo/AdminServices/MailRuleReportRequest";
import CreateMediaFileUpload from "../../../components/MediaFileUpload/CreateMediaFileUpload";
import { TemplateType } from "../../../constants";

export default function UpdateMailTemplate({
  values = {},
  isOpen,
  setOpen = () => {},
  refresh = () => {},
}) {
  const form = useFormik({
    initialValues: {},
    validationSchema: validation,
    onSubmit: (values) => {
      onSubmit(values);
    },
  });

  useEffect(() => {
    if (values.template_id && isOpen) {
      let obj = {
        template_id: values.template_id,
        template_type: values.template_type,
      };
      if (values.template_name) {
        obj.template_name = values.template_name;
      }
      if (values.template_code) {
        obj.template_code = values.template_code;
      }
      if (values.template_document && values.template_document.url) {
        obj.template_document = {
          name: values.template_document.name,
          url: values.template_document.url,
          type: values.template_document.document_type,
          is_mobile: Boolean(values.template_document.is_mobile),
        };
      }
      form.setValues(obj);
    }
  }, [values, isOpen]);

  const onClose = () => {
    form.resetForm();
    form.setValues({});
    setOpen();
  };

  const onSubmit = async (modal) => {
    form.setSubmitting(false);
    const { template_id, template_name, template_document } = modal;
    if (!template_document || !template_document.url) {
      form.setSubmitting(false);
      return toast.warn("Template document is required");
    }
    form.setSubmitting(true);
    let update_request = {
      template_id,
    };
    if (template_name !== values.template_name) {
      update_request.template_name = template_name;
    }
    if (
      !values.template_document && template_document.url
        ? true
        : template_document.url !== values.template_document.url
    ) {
      update_request.template_document = {
        name: template_document.name,
        document_type: template_document.type,
        url: template_document.url,
        is_mobile: false,
      };
    }
    try {
      await graphHandler().invoke(
        UPDATE_TEMPLATE_GQL,
        { update_request },
        1,
        true
      );
      refresh();
      onClose();
      form.setSubmitting(false);
    } catch (error) {
      console.error("Update Rule", error);
      form.setSubmitting(false);
    }
  };

  const handleError = () => {
    if (Object.keys(form.errors).length > 0) {
      toast.error(form.errors[Object.keys(form.errors)[0]]);
    }
  };
  return (
    <Modal toggle={onClose} isOpen={isOpen} centered scrollable size="xl">
      <div className="modal-header">
        <button type="button" className="close" onClick={onClose}>
          <span aria-hidden="true">&times;</span>
        </button>
        <h5 className="modal-title">Update Template</h5>
      </div>
      <ModalBody className="pt-0">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12 form-group  ">
              <label
                htmlFor="template_name"
                className="col-form-label w-100 mb-2"
              >
                Name
              </label>
              <input
                type="text"
                className="form-control"
                id="template_name"
                name="template_name"
                value={
                  form.values && form.values.template_name
                    ? form.values.template_name
                    : ""
                }
                onChange={(e) =>
                  form.setFieldValue(
                    "template_name",
                    e.target.value.trimStart()
                  )
                }
                onBlur={(e) => {
                  form.setFieldTouched("template_name", true);
                  form.setFieldValue(
                    "template_name",
                    e.target.value.replace(/\s+/g, " ").trim()
                  );
                }}
              />
              <small className="text-danger">
                {form.touched.template_name && form.errors.template_name
                  ? form.errors.template_name
                  : ""}
              </small>
            </div>
            <div className="col-md-12 form-group">
              <label
                htmlFor="template_code"
                className="col-form-label w-100 mb-2"
              >
                Code
              </label>
              <input
                {...form.getFieldProps("template_code")}
                type="text"
                className="form-control "
                id="template_code"
                value={
                  form.values.template_code ? form.values.template_code : ""
                }
                disabled
              />
              <small className="text-danger align-self-end">
                {form.touched.template_code &&
                form.errors &&
                form.errors.template_code
                  ? form.errors.template_code
                  : ""}
              </small>
            </div>
            <div className="col-md-12 form-group">
              <label
                htmlFor="template_type"
                className="col-form-label w-100 mb-2"
              >
                Type
              </label>
              <select
                className="custom-select"
                name="template_type"
                id="template_type"
                onChange={form.handleChange}
                value={
                  form.values.template_type ? form.values.template_type : ""
                }
                onBlur={form.handleBlur}
                disabled
              >
                <option key="Select" hidden>
                  Select
                </option>
                {TemplateType.map((data, i) => (
                  <option key={i} value={data.value} hidden={!data.value}>
                    {data.name}
                  </option>
                ))}
              </select>
              <small className="text-danger align-self-end">
                {form.touched.template_type &&
                form.errors &&
                form.errors.template_type
                  ? form.errors.template_type
                  : ""}
              </small>
            </div>
            <div className="col-12 form-group">
              <label className="col-form-label w-100">Document</label>
              <div className="col-form-label row w-100">
                <CreateMediaFileUpload
                  id="template_document"
                  name="template_document"
                  formik={form}
                  mediaType="Document"
                  contentId={form.values.template_id}
                  contentType="TEMPLATE"
                />
              </div>
              <small className="text-danger align-self-end">
                {form.errors.template_document
                  ? form.errors.template_document
                  : ""}
              </small>
            </div>
            <CustomButton
              type="button"
              className="btn btn-primary btn-outline-success float-right btn-add-domain"
              disabled={
                !form.values ||
                !form.values.template_document ||
                !form.values.template_document.url ||
                form.values.template_document.isUpload
              }
              onClick={
                form.isValid && Object.keys(form.values).length !== 0
                  ? form.handleSubmit
                  : handleError
              }
            >
              Update
            </CustomButton>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
}

const validation = Yup.object().shape({
  template_name: Yup.string().required("Enter name"),
  template_code: Yup.string().required("Enter code"),
  template_type: Yup.string().required("Select type"),
  template_document: Yup.object()
    .shape({
      url: Yup.string(),
    })
    .required("Document is required"),
});
