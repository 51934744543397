import React, { useEffect, useState } from "react";
import TherapyListItem from "./components/TherapyListItem";
import "./InterventionPlan.css";
import PaginationComponent from "../../components/PaginationComponent";
import CreateTherapy from "./modal/CreateTherapy";
import moment from "moment";
import withRoleBased from "../../utils/Functions/withRoleBased";
import useTherapyList from "../../utils/CustomHooks/useTherapyList";
import TherapyModal from "./modal/TherapyModal";

function Index({ access = {} }) {
  const [count, setCount] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const { therapyList, loading, getTherapyList } = useTherapyList("");
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const pageCount = Math.ceil(therapyList.length / pageSize);
  const [search, setSearch] = useState("");
  const [isSearching, setIsSearch] = useState(false);

  const handleClick = (event, index) => {
    event.preventDefault();
    setCurrentPage(index);
  };
  useEffect(() => {
    let req = {};
    if (search) req.therapy_name = search;
    getTherapyList(req);
  }, [count]);

  const onSearch = () => {
    setCount((x) => x + 1);
    setCurrentPage(0)
    setIsSearch(search ? true : false);
  };
  return (
    <main className="page-content">
      <div className="container-fluid">
        <div className="row ">
          <div className="col-md-6 mb-3">
            <h1 className="page-title"> Therapy </h1>
          </div>

          <div className="col-md-6 mb-3 d-flex justify-content-md-end">
            {access.canCreate && (
              <button
                type="submit"
                className="btn btn-outline-success"
                onClick={() => setIsOpen(true)}
              >
                Create Therapy
              </button>
            )}
          </div>
          <div className="col-md-4">
            <div className="search" style={{ margin: "16px 0px 32px" }}>
              <input
                type="text"
                className="form-control"
                placeholder="Search"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                onKeyDown={(e) => e.key === "Enter" && onSearch()}
              />
              {!isSearching ? (
                <button onClick={onSearch}>
                  <i className="fa fa-search" />
                </button>
              ) : (
                <button
                  onClick={() => {
                    setSearch("");
                    setIsSearch(false);
                    setCount((x) => x + 1);
                  }}
                >
                  <i className="fa fa-times" />
                </button>
              )}
            </div>
          </div>
          <div className="col-md-12">
            <div className="table-responsive ">
              <table className="table domain-table  table-borderless ">
                <thead>
                  <tr className=" ">
                    {access.isAdmin && <td className=""></td>}
                    <td className="">Name</td>
                    <td className="">Sub Domain</td>
                    {/* <td className="">Min - Max</td> */}
                    <td className="">Created At</td>
                    <td className="">Updated At</td>
                    <td className="">Status</td>
                    <td className="">Action</td>
                  </tr>
                </thead>
                <tbody>
                  {therapyList.length > 0 ? (
                    therapyList
                      .slice()
                      .sort(
                        (a, b) =>
                          moment(b.updated_at ? b.updated_at : b.created_at) -
                          moment(a.updated_at ? a.updated_at : a.created_at)
                      )
                      .slice(
                        currentPage * pageSize,
                        (currentPage + 1) * pageSize
                      )
                      .map((x, i) => (
                        <TherapyListItem
                          item={x}
                          key={i}
                          index={i}
                          refresh={() => {
                            setSearch("");
                            setCount((x) => x + 1);
                          }}
                          access={access}
                        />
                      ))
                  ) : (
                    <tr>
                      <td className="text-center font-weight-bold" colSpan="7">
                        {loading ? "Loading..." : "No Data Found"}
                      </td>
                    </tr>
                  )}
                  {/* <tr>
                    <td className="col-sm-2">Plan1</td>
                    <td>Stress </td>
                    <td> Plan Game</td>
                    <td> Name1 Date & Time</td>
                    <td>Name1 Date & Time </td>
                    <td>Activated</td>
                    <td>
                      <a className="text-deceration-none s"> Show Details</a>
                    </td>
                  </tr> */}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {therapyList.length > 0 && pageCount != 1 && (
          <PaginationComponent
            handleClick={handleClick}
            currentPage={currentPage}
            pageCount={pageCount}
          />
        )}
      </div>
      <TherapyModal
        isOpen={isOpen}
        setOpen={() => setIsOpen(false)}
        refresh={() => {
          setSearch("");
          setCount((x) => x + 1);
        }}
        isEditable={access.canEdit}
      />
    </main>
  );
}

export default withRoleBased(Index, "therapy");
