import React, { useEffect, useState } from "react";
import ScreenerInfo from "./Components/ScreenerInfo";
import "./screenerDetails.css";
import QuestionComponent from "./Components/QuestionComponent";
import ScreenerHistory from "./Components/ScreenerHistory";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import useTopicList from "../../utils/CustomHooks/useTopicList";
import useScreenerHistory from "../../utils/CustomHooks/useScreenerHistory";
import ScreenerHistoryCommentsTable from "./Components/ScreenerHistoryCommentsTable";
export default function Index({ location, history }) {
  const [count, setCount] = useState(0);
  const [screenerId, setScreenerId] = useState("");
  const [tabIndex, setTabIndex] = useState(0);
  const [allTopics, setAllTopics] = useState([]);
  const [loading, setLoading] = useState(true);
  const [canUpdate, setCanUpdate] = useState(false);
  const [historyCount, setHistoryCount] = useState(0);
  const { screenerHistory, getScreenerHistory } = useScreenerHistory("");

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    if (loading && query.has("id") && query.get("id")) {
      setScreenerId(query.get("id"));
      setLoading(false);
    } else if (history.previous) {
      history.goBack();
    } else {
      history.push("/screener");
    }
    if (location.state && location.state.published) {
      setCanUpdate(location.state.published);
    }
  }, [location]);

  useEffect(() => {
    if (screenerId) getScreenerHistory(screenerId);
  }, [screenerId, historyCount]);

  return (
    <main className="page-content">
      <div className="container-fluid">
        <div className="row">
          <ScreenerInfo
            refresh={() => setHistoryCount((x) => x + 1)}
            screener_id={screenerId}
            canUpdate={canUpdate}
            setCanUpdate={setCanUpdate}
          />
          <div className="col-lg-12">
            <div className="accordion" id="accordionExample">
              <QuestionComponent screener_id={screenerId} />
              {/* <TopicComponent
                screenerId={screenerId}
                allTopics={allTopics}
                setReqCount={setReqCount}
              /> */}
            </div>
            <Tabs
              selectedIndex={tabIndex}
              onSelect={(index) => setTabIndex(index)}
              className="mt-4"
            >
              <TabList>
                <Tab>
                  <span className="font-weight-bold">Screener History</span>
                </Tab>
                <Tab>
                  <span className="font-weight-bold">Comments</span>
                </Tab>
              </TabList>
              <TabPanel>
                <ScreenerHistory screenerHistory={screenerHistory} />
              </TabPanel>
              <TabPanel>
                <ScreenerHistoryCommentsTable
                  screenerHistory={screenerHistory}
                />
              </TabPanel>
            </Tabs>
            {/* <div className="col-md-12 mb-3 d-flex">
              <button
                type="button"
                className="btn btn-outline-success mr-2 mt-3 mb-3"
              >
                Screener History
              </button>
              <button
                type="button"
                className="btn  btn-outline-success mt-3 mb-3"
              >
                Comments
              </button>
            </div> */}

            {/* <ScreenerHistory screenerId={screenerId} /> */}
          </div>
        </div>
      </div>
    </main>
  );
}
