import { useState } from "react";
import { Link } from "react-router-dom";
import { getDescription } from "../../../utils/Functions/filter";
import { toast } from "react-toastify";
import ReadMore from "../../../components/ReadMore";
import FAQModal from "../modal/FAQModal";
import { Switch } from "antd";
import useFAQList from "../../../utils/CustomHooks/useFAQList";

export default function FaqListItem({ access = {}, item = {}, refresh }) {
  const [isEdit, setEdit] = useState(false);
  const { canEdit, isAdmin } = access;
  const { activateFAQ } = useFAQList("");
  const activateItem = async (active) => {
    if (!item.faq_id) return;
    const request = {
      active_request: {
        active,
        faq_id: item.faq_id,
      },
    };
    activateFAQ(request, onActivate(active));
  };
  const onActivate = (active) => {
    setTimeout(() => {
      toast.success(
        `${
          getDescription(item.question, "Web")
            ? getDescription(item.question, "Web").description
            : ""
        } ${active ? "Activated" : "Deactivated"} Successfully`
      );
      refresh();
    }, 1000);
  };

  return (
    <tr>
      {isAdmin && (
        <td>
          <Switch checked={item.active} onChange={activateItem} />
        </td>
      )}
      <td className="col-sm-2">{item.faq_category_name}</td>
      <td>
        <ReadMore>
          {getDescription(item.question, "Web")
            ? getDescription(item.question, "Web").description
            : ""}
        </ReadMore>
      </td>
      <td>
        <ReadMore>
          {getDescription(item.answer, "Web")
            ? getDescription(item.answer, "Web").description
            : ""}
        </ReadMore>
      </td>
      <td>
        <span
          className={`${item.active ? "good" : "bad"} text-center btn btn-sm`}
        >
          {item.active ? "Active" : "Disabled"}
        </span>
      </td>
      {canEdit && (
        <td>
          <button
            type="button"
            className="btn btn-outline-success btn-sm "
            onClick={() => setEdit(true)}
          >
            Edit
          </button>
        </td>
      )}
      <FAQModal
        isOpen={isEdit}
        setOpen={() => setEdit(false)}
        initialValues={item}
        refresh={refresh}
      />
    </tr>
  );
}
