import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import {
  CREATE_GAME_LEVEL_GQL,
  UPDATE_GAME_LEVEL_GQL,
} from "../../../Apollo/AdminServices/MutationRequest";
import graphHandler from "../../../Apollo/graphHandler";
import CustomButton from "../../../components/Button/CustomButton";
import LevelListItem from "../Components/LevelListItem";
import useGameAPI from "../../../utils/CustomHooks/useGameAPI";
import Description from "../../../components/Input/Description";
import MetaInput from "../../../components/MetaInput";
import SingleImageUpload from "../../../components/MediaFileUpload/SingleImageUpload";
import {
  getDescription,
  removeEmptyObjValue,
} from "../../../utils/Functions/filter";

export default function LevelSection({
  values = {},
  levelList = [],
  setLoading = () => {},
  refresh = () => {},
  onClose = () => {},
  onDone = () => {},
  onBack = () => {},
  isEditable,
}) {
  const { game_config_id, game_type, intervention_id, screener_id } = values;
  const { loading, removeLevel } = useGameAPI();
  const isScreener = game_type === "Screener";
  const formik = useFormik({
    initialValues: {},
    validationSchema: validation,
    onSubmit: (values) => {
      onSubmit(values);
    },
  });

  const handleError = () => {
    if (Object.keys(formik.errors).length > 0) {
      toast.error(formik.errors[Object.keys(formik.errors)[0]]);
    }
  };

  // console.log("value",formik.isSubmitting,formik.isValid)
  // console.log("valid",Object.keys(formik.errors).length !== 0 ||
  // !formik.isValid ||
  // formik.isSubmitting)
  // console.log("errors",Object.keys(formik.errors).length !== 0)
  // console.log("isValid", !formik.isValid)
  // console.log("isSubmitting",formik.isSubmitting)
  const onSubmit = async (value) => {
    const {
      game_level_id,
      level_id,
      stage_id,
      mile_stone_meta,
      mile_stone_image_urls,
      level_data,
      mobile_description,
      game_level_code,
      mile_stone_score,
      mile_stone_name,
      mile_stone_descriptions,
    } = value;
    if (!game_level_id && (!game_config_id || !game_type))
      return toast.error("Required config id not found");
    const request = {
      level_id: Number(level_id),
      stage_id: Number(stage_id),
      level_data: JSON.parse(level_data),
      game_config_id,
      game_type,
    };
    if (game_level_code) {
      request.game_level_code = game_level_code;
    }
    if (mile_stone_meta && mile_stone_meta.length > 0) {
      request.mile_stone_meta = mile_stone_meta;
    } else {
      request.mile_stone_meta = [];
    }
    if (mile_stone_name) {
      request.mile_stone_name = mile_stone_name;
    }
    if (mile_stone_score) {
      request.mile_stone_score = parseFloat(mile_stone_score);
    }
    let description = [];
    if (mile_stone_descriptions) {
      description.push({
        client_type: "Web",
        description: mile_stone_descriptions,
      });
    }
    if (mobile_description) {
      description.push({
        client_type: "Mobile",
        description: mobile_description,
      });
    }
    if (description && description.length > 0) {
      request.mile_stone_descriptions = description;
    } else {
      request.mile_stone_descriptions = [];
    }
    if (mile_stone_image_urls && mile_stone_image_urls.length > 0) {
      request.mile_stone_image_urls = mile_stone_image_urls.map((x) => {
        delete x.index;
        return removeEmptyObjValue(x);
      });
    } else {
      request.mile_stone_image_urls = [];
    }
    if (isScreener) {
      request.screener_id = screener_id;
    } else {
      request.intervention_id = intervention_id;
    }
    if (game_level_id) {
      let update_request = { ...request, game_level_id };
      update(update_request);
    } else {
      create(request);
    }
    formik.setSubmitting(false);
  };

  const create = async (create_request) => {
    setLoading(true);
    // formik.setSubmitting(true);
    try {
      const { data } = await graphHandler().invoke(
        CREATE_GAME_LEVEL_GQL,
        { create_request },
        1,
        false,
        true
      );
      if (data && data.createGameLevel && data.createGameLevel.game_level) {
        // console.log(
        //   "createGameLevel Success",
        //   data.createGameLevel.game_level
        // );
        setLoading(false);
        toast.success("Created Successfully");
        refresh();
        resetModal();
        // onClose();
      }
      formik.setSubmitting(false);
    } catch (error) {
      console.error("createLevel", error);
      setLoading(false);
      formik.setSubmitting(false);
    }
  };

  const update = async (update_request) => {
    // let update_request = { ...request };
    // delete update_request.sub_domain_id;
    delete update_request.stage_id;
    delete update_request.level_id;
    delete update_request.game_type;
    delete update_request.game_level_code;
    delete update_request.intervention_id;
    delete update_request.game_config_id;
    setLoading(true);
    formik.setSubmitting(true);
    try {
      const { data } = await graphHandler().invoke(
        UPDATE_GAME_LEVEL_GQL,
        { update_request },
        1,
        false,
        true
      );
      if (data && data.updateGameLevel && data.updateGameLevel.game_level) {
        // console.log(
        //   "updateGameLevel Success",
        //   data.updateGameLevel.game_level
        // );
        setLoading(false);
        toast.success("Updated Successfully");
        refresh();
        resetModal();
      }
      formik.setSubmitting(false);
    } catch (error) {
      console.error("updateLevel", error);
      setLoading(false);
      formik.setSubmitting(false);
    }
  };

  const handleNumber = (e) => {
    const { name, value } = e.target;
    const re = /^[0-9\b]+$/;
    let temp = !value
      ? value
      : re.test(value)
      ? value
      : formik.values[name]
      ? formik.values[name]
      : "";
    formik.setFieldValue(name, temp ? temp.substring(0, 10) : temp);
  };
  const handleFloatNumber = (e) => {
    const { name, value } = e.target;
    const re = /^(\d*)([,.]\d{0,2})?$/;
    let temp = !value
      ? value
      : re.test(value)
      ? value
      : formik.values[name]
      ? formik.values[name]
      : "";
    formik.setFieldValue(name, temp);
  };

  const onEdit = (data = {}) => {
    if (data.game_level_id) {
      const value = {
        game_level_id: data.game_level_id,
        level_id: data.level_id,
        stage_id: data.stage_id,
      };
      if (data.mile_stone_name) {
        value.mile_stone_name = data.mile_stone_name;
      }
      if (data.game_level_code) {
        value.game_level_code = data.game_level_code;
      }
      if (data.mile_stone_score) {
        value.mile_stone_score = data.mile_stone_score;
      }
      if (data.mile_stone_meta && data.mile_stone_meta.length > 0) {
        value.mile_stone_meta = data.mile_stone_meta;
      }
      if (data.mile_stone_image_urls) {
        value.mile_stone_image_urls = data.mile_stone_image_urls;
      }
      if (
        data.mile_stone_descriptions &&
        data.mile_stone_descriptions.length > 0
      ) {
        if (getDescription(data.mile_stone_descriptions, "Web")) {
          value.mile_stone_descriptions = getDescription(
            data.mile_stone_descriptions,
            "Web"
          ).description;
        }
        if (getDescription(data.mile_stone_descriptions, "Mobile")) {
          value.mobile_description = getDescription(
            data.mile_stone_descriptions,
            "Mobile"
          ).description;
        }
      }
      if (data.level_data) {
        value.level_data = JSON.stringify(data.level_data, undefined, 4);
      }
      // if (data.level_data) {
      //   value.level_data =
      //     typeof data.level_data === "object"
      //       ? data.level_data
      //       : JSON.parse(data.level_data);
      //   if (document.getElementById("level_data")) {
      //     document.getElementById("level_data").value = JSON.stringify(
      //       typeof data.level_data === "object"
      //         ? data.level_data
      //         : JSON.parse(data.level_data),
      //       undefined,
      //       4
      //     );
      //   }
      // }
      formik.setValues(value);
      setLoading(false);
      let view = document.getElementById("CreateLevel");
      if (view) view.scrollIntoView({ behavior: "smooth" });
    }
  };
  const resetModal = () => {
    formik.resetForm();
    formik.setValues({});
    formik.setSubmitting(false);
    if (document.getElementById("level_data"))
      document.getElementById("level_data").value = "";
  };

  const onDelete = (id) => {
    if (loading) return;
    removeLevel(id, refresh);
  };

  return (
    <div className="row justify-content-center ">
      <div className="col-md-12">
        <div className="table-responsive border p-2">
          <table className="table questionnaire-table table-borderless mb-0">
            <thead>
              <tr className=" ">
                <td>Level ID</td>
                <td>Stage ID</td>
                <td>Level Data</td>
                <td>Action</td>
              </tr>
            </thead>
            <tbody>
              {levelList.length > 0 ? (
                levelList
                  .slice()
                  .sort((a, b) => a.level_id - b.level_id)
                  .map((x, i) => (
                    <LevelListItem
                      key={i}
                      item={x}
                      onEdit={onEdit}
                      onDelete={onDelete}
                    />
                  ))
              ) : (
                <tr>
                  <td className="text-center" colSpan="4">
                    No Levels Found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      {isEditable && (
        <>
          <div className="col-md-12 modal-header" id="CreateLevel">
            <h5 className="modal-title text-center mb-3">
              {formik.values.game_level_id ? "Update" : "Add"} Level
            </h5>
          </div>
          <div className="col-md-12">
            <div className="  border">
              <div className="questionnaire-form row p-3">
                <div className="col-md-6">
                  <div className="row">
                    <div className="col-md-12 form-group">
                      <label
                        htmlFor="level_id"
                        className="col-form-label w-100 mb-2"
                      >
                        Level Id
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="level_id"
                        name="level_id"
                        onChange={handleNumber}
                        value={
                          formik.values.level_id ? formik.values.level_id : ""
                        }
                        onBlur={formik.handleBlur}
                        disabled={formik.values.game_level_id}
                      />
                      <small className="text-danger align-self-end">
                        {formik.touched.level_id &&
                        formik.errors &&
                        formik.errors.level_id
                          ? formik.errors.level_id
                          : ""}
                      </small>
                    </div>
                    <div className="col-md-12 form-group">
                      <label
                        htmlFor="stage_id"
                        className="col-form-label w-100 mb-2"
                      >
                        Stage Id
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="stage_id"
                        name="stage_id"
                        onChange={handleNumber}
                        value={
                          formik.values.stage_id ? formik.values.stage_id : ""
                        }
                        onBlur={formik.handleBlur}
                        disabled={formik.values.game_level_id}
                      />
                      <small className="text-danger align-self-end">
                        {formik.touched.stage_id &&
                        formik.errors &&
                        formik.errors.stage_id
                          ? formik.errors.stage_id
                          : ""}
                      </small>
                    </div>
                    <div className="form-group col-md-12">
                      <label
                        htmlFor="mile_stone_name"
                        className="col-form-label w-100 mb-2"
                      >
                        Mile Stone Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="mile_stone_name"
                        name="mile_stone_name"
                        // maxLength="30"
                        value={
                          formik.values.mile_stone_name
                            ? formik.values.mile_stone_name
                            : ""
                        }
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      <small className="text-danger">
                        {formik.touched.mile_stone_name &&
                        formik.errors &&
                        formik.errors.mile_stone_name
                          ? formik.errors.mile_stone_name
                          : ""}
                      </small>
                    </div>
                    <div className="col-md-6 form-group">
                      <label
                        htmlFor="game_level_code"
                        className="col-form-label w-100 mb-2"
                      >
                        Code
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="game_level_code"
                        name="game_level_code"
                        onChange={formik.handleChange}
                        value={
                          formik.values.game_level_code
                            ? formik.values.game_level_code
                            : ""
                        }
                        onBlur={formik.handleBlur}
                        disabled={formik.values.game_level_id}
                      />
                      <small className="text-danger align-self-end">
                        {formik.touched.game_level_code &&
                        formik.errors &&
                        formik.errors.game_level_code
                          ? formik.errors.game_level_code
                          : ""}
                      </small>
                    </div>
                    <div className="col-md-6 form-group">
                      <label
                        htmlFor="mile_stone_score"
                        className="col-form-label w-100 mb-2"
                      >
                        Score
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="mile_stone_score"
                        name="mile_stone_score"
                        // maxLength={4}
                        onChange={handleFloatNumber}
                        value={
                          formik.values.mile_stone_score
                            ? formik.values.mile_stone_score
                            : ""
                        }
                        onBlur={formik.handleBlur}
                        disabled={formik.values.game_mile_stone_score}
                      />
                      <small className="text-danger align-self-end">
                        {formik.touched.mile_stone_score &&
                        formik.errors &&
                        formik.errors.mile_stone_score
                          ? formik.errors.mile_stone_score
                          : ""}
                      </small>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 form-group">
                  <label htmlFor="level_data" className="col-form-label w-100">
                    Level Data (JSON)
                    <button
                      className="float-right btn btn-dark btn-sm mb-2"
                      disabled={formik.errors.level_data}
                      onClick={() => {
                        if (
                          formik.touched.level_data &&
                          document.getElementById("level_data")
                        ) {
                          const ugly =
                            document.getElementById("level_data").value;
                          document.getElementById("level_data").value =
                            JSON.stringify(JSON.parse(ugly), undefined, 4);
                        }
                      }}
                    >
                      Pretty
                    </button>
                  </label>
                  <textarea
                    className="form-control"
                    id="level_data"
                    name="level_data"
                    cols="50"
                    rows="10"
                    style={{ maxHeight: "100%", minHeight: "50vh" }}
                    value={
                      formik.values && formik.values.level_data
                        ? formik.values.level_data
                        : ""
                    }
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  <small className="text-danger">
                    {formik.touched.level_data &&
                    formik.errors &&
                    formik.errors.level_data
                      ? formik.errors.level_data
                      : ""}
                  </small>
                </div>
                <div className="col-md-12">
                  <div className="row">
                    <Description
                      formik={formik}
                      webName="mile_stone_descriptions"
                      col="col-md-6"
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <MetaInput
                    formik={formik}
                    parentArray="mile_stone_meta"
                    typeKey="type"
                    titleKey="key"
                    valueKey="value"
                  />
                </div>
                {formik.values.game_level_id &&
                formik.values.game_level_code ? (
                  <>
                    <div className="col-md-12">
                      <SingleImageUpload
                        formik={formik}
                        heading="Image File"
                        contentId={formik.values.game_level_id}
                        contentType="GAMELEVEL"
                        mediaType="Image"
                        parentArray="mile_stone_image_urls"
                      />
                    </div>
                  </>
                ) : null}
                <div
                  className={`d-flex align-items-end justify-content-end col-md-12`}
                >
                  {formik.values.game_level_id && (
                    <button
                      onClick={resetModal}
                      type="button"
                      className="btn btn-light btn-sm float-right btn-add-domain mr-2"
                    >
                      Reset
                    </button>
                  )}
                  <CustomButton
                    type="button"
                    className="btn btn-primary btn-outline-success btn-sm float-right btn-add-domain"
                    disabled={
                      Object.keys(formik.errors).length !== 0 ||
                      !formik.isValid ||
                      formik.isSubmitting
                    }
                    onClick={
                      formik.isValid && Object.keys(formik.values).length !== 0
                        ? formik.handleSubmit
                        : handleError
                    }
                  >
                    {formik.values.game_level_id ? "Update" : "Create"}
                  </CustomButton>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      <div className="d-flex align-items-end justify-content-center col-md-12 p-5">
        <button
          onClick={onClose}
          type="button"
          className="btn btn-danger btn-sm float-right btn-add-domain mr-2"
        >
          Close
        </button>{" "}
        <button
          onClick={onBack}
          type="button"
          className="btn btn-error btn-sm float-right btn-add-domain mr-2"
        >
          Back
        </button>
      </div>
    </div>
  );
}
const validation = Yup.object().shape({
  level_id: Yup.string().required("Enter a level id"),
  stage_id: Yup.string().required("Enter a stage id"),
  level_data: Yup.object()
    .typeError("Invalid JSON")
    .required("Enter a level JSON"),
  mile_stone_descriptions: Yup.string(),
  mobile_description: Yup.string(),
  mile_stone_name: Yup.string(),
  game_level_code: Yup.string().required("Enter a code"),
  // mile_stone_meta: Yup.array().of(
  //   Yup.object().shape({
  //     type: Yup.string().required("Select a data type"),
  //     key: Yup.string().trim().required("Enter a title"),
  //     value: Yup.string().trim().required("Enter a value"),
  //   })
  // ),
});
