import { useFormik } from "formik";
import { useMemo } from "react";
import { useEffect } from "react";
import * as Yup from "yup";
import { IsNullOrEmpty } from "../../utils/Functions/filter";
import usePrevious from "../../utils/Functions/usePrevious";

export default function SelectInputField({
  parentForm = {},
  className = "col-md-6",
  updateValue = "",
  actualValue = "",
  id = "",
  title = "",
  disabled = false,
  validation = {},
  inputProps = {},
  options = [{ label: "Select", value: "", prop: { hidden: true } }],
  oldOptions = [],
  setChildRef = () => {},
}) {
  const formik = useFormik({
    initialValues: {},
    validationSchema: Yup.object().shape(validation),
    onSubmit: () => {},
    validateOnChange: true,
  });
  const previous = usePrevious(formik.errors[id]);
  useMemo(() => {
    if (updateValue && parentForm) {
      parentForm.setFieldValue(id, updateValue);
    }
  }, [updateValue]);

  useEffect(() => {
    if (formik.errors[id]) {
      parentForm.setFieldError(id, formik.errors[id] ? formik.errors[id] : "");
    } else if (previous !== formik.errors[id]) {
      let errors = { ...parentForm.errors };
      delete errors[id];
      parentForm.setErrors(errors);
    }
  }, [formik.errors, parentForm.errors]);

  useEffect(() => {
    formik && setChildRef(formik);
    formik.setFieldValue(
      id,
      !IsNullOrEmpty(updateValue) ? updateValue : actualValue
    );
  }, [updateValue, actualValue]);

  const onChange = (e) => {
    const { name, value } = e.target;
    formik.setFieldValue(name, value);
    parentForm && parentForm.setFieldValue(name, value);
  };
  return (
    <div className={className}>
      <div className="row">
        {updateValue && (
          <div className="col-md-12 form-group">
            <label htmlFor={id} className="col-form-label w-100 mb-2">
              {title}
              <span className="badge badge-good float-right">Existing</span>
            </label>
            <select
              name="screener_type"
              className="custom-select"
              value={actualValue}
              disabled
            >
              {(oldOptions.length > 0 ? oldOptions : options).map((x, i) => (
                <option value={x.value} key={i} {...(x.props ? x.props : {})}>
                  {x.label}
                </option>
              ))}
            </select>
          </div>
        )}
        <div className="col-md-12 form-group">
          <label htmlFor={id} className="col-form-label w-100 mb-2">
            {title}
            {updateValue && (
              <span className="badge badge-error float-right">New</span>
            )}
          </label>
          <select
            className="custom-select"
            id={id}
            name={id}
            value={formik.values[id] ? formik.values[id] : ""}
            onChange={onChange}
            onBlur={formik.handleBlur}
            disabled={disabled}
            {...inputProps}
          >
            {options.map((x, i) => (
              <option value={x.value} key={i} {...(x.props ? x.props : {})}>
                {x.label}
              </option>
            ))}
          </select>
          <small className="text-danger">
            {formik.errors[id] ? formik.errors[id] : ""}
          </small>
        </div>
      </div>
    </div>
  );
}
