import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Popover } from "reactstrap";
import { Switch } from "antd";
import ApproveModal from "../../../components/Modal/ApproveModal";
import JsonCompareModal from "../../../components/Modal/JsonCompareModal";
import useScreenerAPI from "../../../utils/CustomHooks/useScreenerAPI";
import useSubDomain from "../../../utils/CustomHooks/useSubDomain";
import ScreenerModal from "../modal/ScreenerModal";

export default function ScreenerData({
  item,
  i,
  refresh = () => {},
  access = {},
}) {
  const { subDomain, getSubDomain } = useSubDomain("");
  const [isEdit, setEdit] = useState(false);
  const [isView, setView] = useState(false);
  const [isOpenApprove, setOpenApprove] = useState(false);
  const [isApprove, setApprove] = useState(true);
  const [showPop, setShowPop] = useState(false);
  const toggle = () => setShowPop((x) => !x);
  const { pending_screener_history: screenerHistory } = item;
  const { approveScreener, reviewScreener, activateScreener } =
    useScreenerAPI();
  const hasHistory = screenerHistory && screenerHistory.update_status;
  const { canEdit, canApprove, canReview, isAdmin } = access;
  const readyApprove =
    screenerHistory &&
    ((screenerHistory.update_status == "ReviewSuccess" && canApprove) ||
      (screenerHistory.update_status == "Draft" && canReview));
  useEffect(() => {
    if (item.sub_domain_ids && item.sub_domain_ids.length > 0)
      getSubDomain(item.sub_domain_ids[0]);
  }, [item]);

  const activateItem = async (active) => {
    if (!item.screener_id) return;
    toggle();
    const request = {
      active_request: {
        active,
        screener_id: item.screener_id,
      },
    };
    activateScreener(request, onActivate(active));
  };

  const onActivate = (active) => {
    setTimeout(() => {
      toast.success(
        `${item.screener_name} ${
          active ? "Activated" : "Deactivated"
        } Successfully`
      );

      refresh();
    }, [1000]);
  };

  const onDone = (value) => {
    if (!hasHistory) return;
    if (screenerHistory.update_status == "Draft") {
      const request = {
        review_request: { ...value, approve: isApprove },
        screener_history_id: screenerHistory.screener_history_id,
      };
      reviewScreener(request, refresh);
    } else if (screenerHistory.update_status == "ReviewSuccess") {
      const request = {
        approve_request: { ...value, approve: isApprove },
        screener_history_id: screenerHistory.screener_history_id,
      };
      approveScreener(request, refresh);
    }
  };

  const renderStatus = (status) => {
    if (
      hasHistory &&
      status !== "Rejected" &&
      status !== "Draft" &&
      status !== "Reviewfailed"
    ) {
      return <span className="good text-center btn btn-sm">{status}</span>;
    } else if (!hasHistory && item.active && item.published) {
      return <span className="good text-center btn btn-sm">Published</span>;
    } else if (!hasHistory && !item.active && item.published) {
      return <span className="bad text-center btn btn-sm">Disabled</span>;
    } else {
      return (
        <span className="bad text-center btn btn-sm">
          {status || "Rejected"}
        </span>
      );
    }
  };

  const getFilteredObject = (obj) => {
    const temp = { ...obj };
    delete temp.created_at;
    delete temp.created_by;
    delete temp.updated_at;
    delete temp.updated_by;
    delete temp.screener_id;
    delete temp.pending_screener_history;
    return temp;
  };
  return (
    <tr className="option-table">
      {isAdmin && (
        <td>
          <Switch checked={item.active} onChange={activateItem} />
        </td>
      )}
      <td>{item.screener_name ? item.screener_name : "-"}</td>
      <td>{item.screener_type ? item.screener_type : "-"}</td>
      <td>
        {subDomain && subDomain.sub_domain_name
          ? subDomain.sub_domain_name
          : "-"}
      </td>
      <td onClick={() => hasHistory && setView(true)}>
        {renderStatus(screenerHistory ? screenerHistory.update_status : "")}
      </td>
      <td>
        {hasHistory && readyApprove ? (
          <>
            <button
              type="button"
              className="btn btn-outline-success btn-sm "
              onClick={() => {
                setOpenApprove(true);
                setApprove(true);
              }}
            >
              {screenerHistory.update_status == "ReviewSuccess"
                ? "Approve"
                : "Review"}
            </button>
            <button
              type="button"
              className="ml-2 btn btn-danger btn-sm "
              onClick={() => {
                setOpenApprove(true);
                setApprove(false);
              }}
            >
              Reject
            </button>
          </>
        ) : (
          <button
            className="btn btn-outline-success btn-sm "
            onClick={() => setEdit(true)}
          >
            {canEdit ? "Edit" : "View"}
          </button>
        )}
      </td>
      <td className="accordion-menu" id={`AccordionTableMenu${i}`}>
        <i className="fas fa-ellipsis-v" />
      </td>
      <Popover
        placement="left"
        target={`AccordionTableMenu${i}`}
        trigger="legacy"
        isOpen={showPop}
        toggle={toggle}
      >
        <div className="accordion-menu-header">Options</div>
        {/* <div
          className="accordion-menu-item"
          onClick={() =>
            history.push(`/view-history?type=screener&id=${item.screener_id}`)
          }
        >
          View History
        </div> */}
        <div
          className="accordion-menu-item"
          onClick={() => {
            toggle();
            setEdit(true);
          }}
        >
          {!canEdit ? "View" : "Edit"}
        </div>
        {/* {!hasHistory && item.published && (
          <div
            className="accordion-menu-item"
            onClick={() => activateItem(!item.active)}
          >
            {!item.active ? "Activate" : "Deactivate"}
          </div>
        )} */}
        {hasHistory && (
          <div
            className="accordion-menu-item"
            onClick={() => {
              toggle();
              setView(true);
            }}
          >
            Compare
          </div>
        )}
      </Popover>
      {readyApprove && (
        <ApproveModal
          setOpen={() => setOpenApprove(false)}
          isOpen={isOpenApprove}
          isApprove={isApprove}
          title={
            isApprove
              ? screenerHistory.update_status == "ReviewSuccess"
                ? "Approve"
                : "Review"
              : "Reject"
          }
          refresh={refresh}
          onDone={onDone}
          isRequire={true}
          isShowDate={
            screenerHistory && screenerHistory.update_status === "ReviewSuccess"
          }
        />
      )}
      {hasHistory && (
        <JsonCompareModal
          setOpen={() => setView(false)}
          isOpen={isView}
          title="Screener Compare"
          actualDate={getFilteredObject(item)}
          newDate={getFilteredObject(screenerHistory.updated_data)}
        />
      )}
      <ScreenerModal
        isOpen={isEdit}
        setOpen={setEdit}
        initialValues={item}
        refresh={() => {
          refresh((x) => x + 1);
        }}
        isEditable={canEdit}
        isScreenerEditable={canEdit}
        // isScreenerEditable={canEdit ? !hasHistory : false}
      />
    </tr>
  );
}
