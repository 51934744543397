import { useState } from "react";
import { Link } from "react-router-dom";
import ReadMore from "../../../components/ReadMore";
import PolicyModal from "../modal/PolicyModal";

export default function PolicyListItem({ item, refresh, access = {} }) {
  const [isEdit, setEdit] = useState(false);
  return (
    <tr>
      <td className="col-sm-2">{item.policy_title}</td>
      <td>
        <ReadMore>{item.policy_body || "-"}</ReadMore>
      </td>
      <td>{item.policy_version || "-"}</td>
      <td>{item.policy_type || "-"}</td>
      <td>
        <span
          className={`${item.active ? "good" : "bad"} text-center btn btn-sm`}
        >
          {item.active ? "Active" : "Disabled"}
        </span>
      </td>
      {access.canEdit && (
        <td>
          <button
            type="button"
            className="btn btn-outline-success btn-sm "
            onClick={() => setEdit(true)}
          >
            Edit
          </button>
        </td>
      )}
      <PolicyModal
        isOpen={isEdit}
        setOpen={() => setEdit(false)}
        initialValues={item}
        refresh={refresh}
      />
    </tr>
  );
}
