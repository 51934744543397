import { useState } from "react";
import UpdateLicense from "../modal/UpdateLicense";
import { Popover } from "reactstrap";
// import { Link } from "react-router-dom";
import moment from "moment";
import AddTransaction from "../modal/AddTransaction";

export default function ListItem({
  item,
  index,
  refresh,
  access = {},
  organizationOption = [],
}) {
  const [isEdit, setEdit] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [showPop, setShowPop] = useState(false);
  const toggle = () => setShowPop((x) => !x);

  const getOrganization = () => {
    return organizationOption.find((x) => x?.value == item?.organization_id)
      ?.label;
  };
  // console.log("item",item)
  return (
    <tr className="option-table">
      <td>{getOrganization()}</td>
      {/* <td>{item.organization_license_id}</td> */}
      <td>{item.organization_license_number ?? "-"}</td>
      <td>{item.total_seat_count ?? "-"}</td>
      <td>{item.license_duration_months ?? "-"}</td>
      {/* <td>{item.amount??"-"}</td> */}
      <td>{moment(item.license_start_ts).format("DD-MM-YYYY")}</td>
      <td>{moment(item.license_expiry_ts).format("DD-MM-YYYY")}</td>
      <td>
        <span
          className={`${
            item.organization_license_status === "Active" ? "good" : "bad"
          } text-center btn btn-sm`}
        >
          {item.organization_license_status}
        </span>
      </td>
      {access.canEdit && (
        <>
          <td>
            <button
              type="button"
              className="btn btn-outline-success btn-sm "
              onClick={() => setEdit(true)}
            >
              Edit
            </button>
          </td>
          <td
            className="accordion-menu"
            id={`AccordionTableMenu${index}`}
            onClick={toggle}
          >
            <i className="fas fa-ellipsis-v" />
          </td>
          <Popover
            isOpen={showPop}
            toggle={toggle}
            placement="left"
            target={`AccordionTableMenu${index}`}
            trigger="legacy"
          >
            <div className="accordion-menu-header">Options</div>

            <div
              className="accordion-menu-item"
              onClick={() => {
                setIsOpen(true)
                toggle()
              }}
            >
              Add Transaction
            </div>
          </Popover>
        </>
      )}
      <AddTransaction
        isOpen={isOpen}
        setOpen={() => setIsOpen(false)}
        initialValue={item}
        refresh={refresh}
        organizationOption={organizationOption}
      />
      <UpdateLicense
        isOpen={isEdit}
        setOpen={() => setEdit(false)}
        initialValue={item}
        refresh={refresh}
        organizationOption={organizationOption}
      />
    </tr>
  );
}
