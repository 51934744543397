import { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { isImage, isVideo, MetaDataType } from "../../../constants";
import {
  CREATE_MODULE_GQL,
  UPDATE_MODULE_GQL,
} from "../../../Apollo/AdminServices/MutationRequest";
import graphHandler from "../../../Apollo/graphHandler";
import {
  formatBytes,
  GenerateID,
  getDescription,
} from "../../../utils/Functions/filter";
import useModuleList from "../../../utils/CustomHooks/useModuleList";
import ModuleListItem from "./ModuleListItem";
import useInterventionList from "../../../utils/CustomHooks/useInterventionList";
import Description from "../../../components/Input/Description";
import useTherapyAPI from "../../../utils/CustomHooks/useTherapyAPI";
import useScreenerList from "../../../utils/CustomHooks/useScreenerList";
import MetaInput from "../../../components/MetaInput";
import { uploadHandler } from "../../../utils/CustomHooks/useUploadImage";

export default function CreateModule({ therapy = {}, refresh = () => {} }) {
  const { therapy_id } = therapy;
  const { moduleList, getModuleList } = useModuleList("");
  const { interventionList, getInterventionList } = useInterventionList("");
  const { screenerList, getScreenerList } = useScreenerList("");
  const { removeModules, removeActions, loading } = useTherapyAPI("");
  const [interventions, setInterventions] = useState([]);
  const formik = useFormik({
    initialValues: { ...initialValues },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      onSubmit(values);
    },
  });

  useEffect(() => {
    setInterventions(
      interventionList.map((x) => ({
        label: x.intervention_name,
        value: x.intervention_id,
      }))
    );
  }, [interventionList]);
  useEffect(() => {
    if (therapy_id) getModuleList({ therapy_id });
    getInterventionList({ published: true, get_pending_update: false });
    getScreenerList(
      { published: true, screener_type: "DomainScreener" },
      false
    );
  }, [therapy]);

  const handleError = () => {
    if (Object.keys(formik.errors).length > 0) {
      toast.error(formik.errors[Object.keys(formik.errors)[0]]);
    }
  };

  const onSubmit = async (value) => {
    const {
      therapy_module_id,
      therapy_module_name,
      therapy_module_description,
      sort_order,
      therapy_module_meta,
      mobile_description,
      min_completion_days,
      max_completion_days,
      start_interval,
      mobilebanner,
      banner,
    } = value;
    if (Number(min_completion_days) > Number(max_completion_days))
      return toast.error("Max completion days should need to be more than min");
    const request = {
      therapy_module_name,
      sort_order: Number(sort_order),
      min_completion_days: Number(min_completion_days),
      max_completion_days: Number(max_completion_days),
      start_interval_seconds: Number(start_interval) * 86400,
    };
    let description = [];
    if (therapy_module_description) {
      description.push({
        client_type: "Web",
        description: therapy_module_description,
      });
    }
    if (mobile_description) {
      description.push({
        client_type: "Mobile",
        description: mobile_description,
      });
    }
    if (description.length > 0)
      request.therapy_module_description = description;
    let therapy_module_image_urls = [];
    if (banner && banner.url)
      therapy_module_image_urls.push({
        image_type: banner.type,
        url: banner.url,
        is_mobile: false,
      });
    if (mobilebanner && mobilebanner.url)
      therapy_module_image_urls.push({
        image_type: mobilebanner.type,
        url: mobilebanner.url,
        is_mobile: true,
      });
    if (therapy_module_image_urls.length > 0)
      request.therapy_module_image_urls = therapy_module_image_urls;
    // request.therapy_module_sessions = [
    //   ...therapy_module_sessions.map((x) => ({
    //     intervention_id: x.intervention_id,
    //     index: Number(x.index),
    //     session_duration:
    //       x.session_unit === "Mins"
    //         ? Number(x.session_duration) * 60
    //         : Number(x.session_duration) * 3600,
    //     session_count: Number(x.session_count),
    //     min_interval_hours: Number(x.min_interval_hours),
    //     total_session_window: Number(x.total_session_window),
    //   })),
    // ];
    if (therapy_module_meta && therapy_module_meta.length)
      request.therapy_module_meta = [...therapy_module_meta.map((x) => x)];
    if (therapy_module_id) {
      updateModule({ therapy_module_id, ...request });
    } else {
      createModule({ therapy_id, ...request });
    }
  };

  const updateModule = async (update_request = {}) => {
    try {
      formik.setSubmitting(true);
      await graphHandler().invoke(
        UPDATE_MODULE_GQL,
        { update_request },
        1,
        true
      );
      getModuleList({ therapy_id });
      formik.resetForm();
      formik.setValues({ ...initialValues });
      formik.setSubmitting(false);
      refresh();
    } catch (error) {
      console.log("updateTherapyModule error", error);
      formik.setSubmitting(false);
    }
  };

  const createModule = async (create_request = {}) => {
    try {
      formik.setSubmitting(true);
      await graphHandler().invoke(
        CREATE_MODULE_GQL,
        { create_request },
        1,
        true
      );
      getModuleList({ therapy_id });
      formik.resetForm();
      formik.setValues({ ...initialValues });
      formik.setSubmitting(false);
      refresh();
    } catch (error) {
      console.log("createTherapyModule error", error);
      formik.setSubmitting(false);
    }
  };

  const handleFileChange = async (event) => {
    const { name, files } = event.target;
    if (!files) return;
    let options = { ...formik.values[name] };
    if (!files[0]) return;
    options = { isUpload: true };
    options.size = formatBytes(files[0].size);
    options.name = files[0].name;
    formik.setFieldValue(name, options);
    const file = new File([files[0]], files[0].name.replace(/\s/g, ""), {
      type: files[0].type,
    });
    if (
      isImage(file) ||
      (name.toLowerCase().includes("banner") && isVideo(file))
    ) {
      uploadToCloud(file, name, options);
    } else {
      toast.error("Please select a valid file");
      formik.setFieldValue(name, undefined);
    }
  };

  async function uploadToCloud(file, name, obj) {
    const options = { ...obj };
    try {
      let data = await uploadHandler("intervention").upload(file);
      options.name = file.name;
      options.url = data.url;
      options.isUpload = false;
      options.progress = 100;
      options.type = isImage(file) ? "Banner" : "Video";
      // console.log("Uploading then", options, data);
      formik.setFieldValue(name, options);
    } catch (error) {
      console.log("Upload error", error);
      formik.setFieldValue(name, undefined);
    }
    // const params = {
    //   ACL: "public-read",
    //   Body: file,
    //   Bucket: S3_BUCKET + "/Image",
    //   Key: GenerateID() + file.name,
    // };
    // myBucket
    //   .putObject(params)
    //   .on("httpUploadProgress", (event) => {
    //     options.size = `${formatBytes(event.loaded)}/${formatBytes(
    //       event.total
    //     )}`;
    //     options.progress = parseInt(
    //       Math.round((100 * event.loaded) / event.total)
    //     );
    //     formik.setFieldValue(name, options);
    //   })
    //   .promise()
    //   .then((res) => {
    //     options.name = file.name;
    //     options.url = `http://s3.${REGION}.amazonaws.com/${params.Bucket}/${params.Key}`;
    //     options.isUpload = false;
    //     options.type = isImage(file) ? "Banner" : "Video";
    //     console.log("Uploading then", options);
    //     formik.setFieldValue(name, options);
    //   })
    //   .catch((err) => {
    //     console.log("Upload error", err);
    //     toast.error(err.toString());
    //     formik.setFieldValue(name, undefined);
    //   });
  }

  const handleNumber = (e) => {
    const { name, value } = e.target;
    const re = /^[0-9\b]+$/;
    let temp = !value ? value : re.test(value) ? value : formik.values[name];
    formik.setFieldValue(name, temp ? temp.substring(0, 10) : temp);
  };

  const onEdit = (item) => {
    if (item.therapy_module_id) {
      let model = {
        therapy_module_id: item.therapy_module_id,
        therapy_module_name: item.therapy_module_name,
        sort_order: item.sort_order,
        min_completion_days: item.min_completion_days,
        max_completion_days: item.max_completion_days,
        start_interval: item.start_interval_seconds / 86400,
        // therapy_module_sessions: item.therapy_module_sessions
        //   ? [...item.therapy_module_sessions]
        //   : [],
        therapy_module_meta: item.therapy_module_meta
          ? item.therapy_module_meta
          : [],
      };
      if (
        item.therapy_module_description &&
        item.therapy_module_description.length > 0
      ) {
        if (getDescription(item.therapy_module_description, "Web")) {
          model.therapy_module_description = getDescription(
            item.therapy_module_description,
            "Web"
          ).description;
        }
        if (getDescription(item.therapy_module_description, "Mobile")) {
          model.mobile_description = getDescription(
            item.therapy_module_description,
            "Mobile"
          ).description;
        }
      }
      if (item.therapy_module_image_urls) {
        let url = item.therapy_module_image_urls;
        if (
          url.find(
            (x) =>
              (x.image_type == "Banner" || x.image_type == "Video") &&
              !x.is_mobile
          )
        ) {
          model.banner = {
            url: url.find(
              (x) =>
                (x.image_type == "Banner" || x.image_type == "Video") &&
                !x.is_mobile
            ).url,
            type: url.find(
              (x) =>
                (x.image_type == "Banner" || x.image_type == "Video") &&
                !x.is_mobile
            ).image_type,
            is_mobile: false,
          };
        }
        if (
          url.find(
            (x) =>
              (x.image_type == "Banner" || x.image_type == "Video") &&
              x.is_mobile
          )
        ) {
          model.mobilebanner = {
            url: url.find(
              (x) =>
                (x.image_type == "Banner" || x.image_type == "Video") &&
                x.is_mobile
            ).url,
            type: url.find(
              (x) =>
                (x.image_type == "Banner" || x.image_type == "Video") &&
                x.is_mobile
            ).image_type,
            is_mobile: true,
          };
        }
      }
      //   console.log(item, model);
      formik.setValues(model);
    }
  };

  const resetModal = () => {
    getModuleList({ therapy_id });
    formik.resetForm();
    formik.setValues({ ...initialValues });
    formik.setSubmitting(false);
  };

  return (
    <div className="col-md-12">
      <div className="row ">
        <div className="table-responsive">
          <table
            className="table table-borderless overflow-auto questionnaire-table"
            style={{ maxHeight: "200px" }}
          >
            <thead>
              <tr className=" ">
                <th className="col-sm-3">Name</th>
                <th className="col-sm-4">Description</th>
                <th className="col-sm-1">Min Days</th>
                <th className="col-sm-1">Max Days</th>
                <th className="col-sm-1">Interval</th>
                <th className="col-sm-1">Order</th>
                <th className="col-sm-2">Action</th>
              </tr>
            </thead>
            <tbody>
              {moduleList.length > 0 ? (
                moduleList
                  .slice()
                  .sort((a, b) => a.sort_order - b.sort_order)
                  .map((x, i) => (
                    <ModuleListItem
                      key={i}
                      item={x}
                      {...{
                        refresh: () => {
                          refresh();
                          getModuleList({ therapy_id });
                        },
                        removeModules,
                        removeActions,
                        loading,
                        screenerList,
                        interventionList,
                        onEdit,
                      }}
                    />
                  ))
              ) : (
                <tr className="border-bottom">
                  <td className="text-center" colSpan="6">
                    No Modules Found. Add Some Modules
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      <div className="questionnaire-form row p-0">
        <div className="col-md-6">
          <div className="row">
            <div className="col-md-12 form-group">
              <label
                htmlFor="therapy_module_name"
                className="col-form-label w-100 mb-2"
              >
                Module Name
              </label>
              <input
                type="text"
                className="form-control"
                id="therapy_module_name"
                name="therapy_module_name"
                onChange={formik.handleChange}
                // maxLength={30}
                value={
                  formik.values.therapy_module_name
                    ? formik.values.therapy_module_name
                    : ""
                }
                onBlur={formik.handleBlur}
              />
              <small className="text-danger align-self-end">
                {formik.touched.therapy_module_name &&
                formik.errors &&
                formik.errors.therapy_module_name
                  ? formik.errors.therapy_module_name
                  : ""}
              </small>
            </div>

            <div className="col-md-12">
              <div className="row">
                <div className="col-md-12 form-group mb-0">
                  <label
                    htmlFor="min_completion_days"
                    className="col-form-label w-100 mb-2"
                  >
                    Completion Days
                  </label>
                  <div className="row">
                    <div className="col-md-6 form-group">
                      <label
                        htmlFor="min_completion_days"
                        className="col-form-label w-100 mb-2"
                      >
                        Min
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="min_completion_days"
                        name="min_completion_days"
                        onChange={handleNumber}
                        maxLength={10}
                        value={
                          formik.values.min_completion_days
                            ? formik.values.min_completion_days
                            : ""
                        }
                        onBlur={formik.handleBlur}
                      />
                      <small className="text-danger align-self-end">
                        {formik.touched.min_completion_days &&
                        formik.errors &&
                        formik.errors.min_completion_days
                          ? formik.errors.min_completion_days
                          : ""}
                      </small>
                    </div>
                    <div className="col-md-6 form-group">
                      <label
                        htmlFor="max_completion_days"
                        className="col-form-label w-100 mb-2"
                      >
                        Max
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="max_completion_days"
                        name="max_completion_days"
                        onChange={handleNumber}
                        maxLength={10}
                        value={
                          formik.values.max_completion_days
                            ? formik.values.max_completion_days
                            : ""
                        }
                        onBlur={formik.handleBlur}
                      />
                      <small className="text-danger align-self-end">
                        {formik.touched.max_completion_days &&
                        formik.errors &&
                        formik.errors.max_completion_days
                          ? formik.errors.max_completion_days
                          : ""}
                      </small>
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-md-6 form-group">
                      <label
                        htmlFor="start_interval"
                        className="col-form-label w-100 mb-2"
                      >
                        Interval (in days)
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="start_interval"
                        name="start_interval"
                        onChange={handleNumber}
                        maxLength={10}
                        value={
                          formik.values.start_interval
                            ? formik.values.start_interval
                            : ""
                        }
                        onBlur={formik.handleBlur}
                      />
                      <small className="text-danger align-self-end">
                        {formik.touched.start_interval &&
                        formik.errors &&
                        formik.errors.start_interval
                          ? formik.errors.start_interval
                          : ""}
                      </small>
                    </div>
                    <div className="col-md-6 form-group">
                      <label
                        htmlFor="sort_order"
                        className="col-form-label w-100 mb-2"
                      >
                        Sort Order
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="sort_order"
                        name="sort_order"
                        onChange={handleNumber}
                        maxLength={10}
                        value={
                          formik.values.sort_order
                            ? formik.values.sort_order
                            : ""
                        }
                        onBlur={formik.handleBlur}
                      />
                      <small className="text-danger align-self-end">
                        {formik.touched.sort_order &&
                        formik.errors &&
                        formik.errors.sort_order
                          ? formik.errors.sort_order
                          : ""}
                      </small>
                    </div>
                  </div>
                </div>
                <div className="input-group  form-group col-md-12 d-flex align-items-start justify-content-start">
                  <label className="col-form-label mb-2 w-100">
                    Module Banner
                  </label>
                  <div className="d-flex w-100 flex-row justify-content-between">
                    <button
                      id="custom-file"
                      onClick={() => document.getElementById("banner1").click()}
                    >
                      Upload File (Web)
                    </button>
                    <button
                      id="custom-file"
                      onClick={() =>
                        document.getElementById("mobilebanner1").click()
                      }
                    >
                      Upload File (Mobile)
                    </button>
                  </div>
                  <input
                    type="file"
                    className="custom-file-input d-none"
                    id="banner1"
                    name="banner"
                    onBlur={formik.handleBlur}
                    onChange={handleFileChange}
                  />
                  <input
                    type="file"
                    className="custom-file-input d-none"
                    id="mobilebanner1"
                    name="mobilebanner"
                    onBlur={formik.handleBlur}
                    onChange={handleFileChange}
                  />
                  {formik.values && formik.values.banner ? (
                    <div className="upload__list col-md-12 mt-2">
                      <div className="upload__info flex">
                        {formik.values.banner.type === "Video" ? (
                          <video
                            src={formik.values.banner.url}
                            className="upload__img"
                          />
                        ) : (
                          <img
                            src={formik.values.banner.url}
                            className="upload__img"
                          />
                        )}
                        <div className="flex w-100 wrap">
                          <p className="upload__name">
                            {formik.values.banner.name || "Uploaded Banner"}
                          </p>
                          {formik.values.banner.name &&
                            formik.values.banner.size != 0 && (
                              <p className="upload__size">
                                ( {formik.values.banner.size} )
                              </p>
                            )}
                          {formik.values.banner.name &&
                            formik.values.banner.progress != 0 && (
                              <p className="upload__long">
                                {formik.values.banner.progress}%
                              </p>
                            )}
                          {formik.values.banner.name &&
                            formik.values.banner.progress == 100 && (
                              <p className="upload__long text-success">Done</p>
                            )}
                          <a className="upload__delete upload__btn">
                            <i
                              className="fas fa-minus-circle"
                              onClick={() =>
                                formik.setFieldValue("banner", undefined)
                              }
                            />
                          </a>
                          {formik.values.banner.name &&
                            formik.values.banner.progress != 0 &&
                            formik.values.banner.progress != 100 && (
                              <div className="upload__progress">
                                <div
                                  className="upload__bar"
                                  style={{
                                    width: formik.values.banner.progress + "%",
                                  }}
                                />
                              </div>
                            )}
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {formik.values && formik.values.mobilebanner ? (
                    <div className="upload__list col-md-12 mt-2">
                      <div className="upload__info flex">
                        {formik.values.mobilebanner.type === "Video" ? (
                          <video
                            src={formik.values.mobilebanner.url}
                            className="upload__img"
                          />
                        ) : (
                          <img
                            src={formik.values.mobilebanner.url}
                            className="upload__img"
                          />
                        )}
                        <div className="flex w-100 wrap">
                          <p className="upload__name">
                            {formik.values.mobilebanner.name ||
                              "Uploaded Banner"}
                          </p>
                          {formik.values.mobilebanner.name &&
                            formik.values.mobilebanner.size != 0 && (
                              <p className="upload__size">
                                ( {formik.values.mobilebanner.size} )
                              </p>
                            )}
                          {formik.values.mobilebanner.name &&
                            formik.values.mobilebanner.progress != 0 && (
                              <p className="upload__long">
                                {formik.values.mobilebanner.progress}%
                              </p>
                            )}
                          {formik.values.mobilebanner.name &&
                            formik.values.mobilebanner.progress == 100 && (
                              <p className="upload__long text-success">Done</p>
                            )}
                          <a className="upload__delete upload__btn">
                            <i
                              className="fas fa-minus-circle"
                              onClick={() =>
                                formik.setFieldValue("mobilebanner", undefined)
                              }
                            />
                          </a>
                          {formik.values.mobilebanner.name &&
                            formik.values.mobilebanner.progress != 0 &&
                            formik.values.mobilebanner.progress != 100 && (
                              <div className="upload__progress">
                                <div
                                  className="upload__bar"
                                  style={{
                                    width:
                                      formik.values.mobilebanner.progress + "%",
                                  }}
                                />
                              </div>
                            )}
                        </div>
                      </div>
                    </div>
                  ) : null}
                  <small className="text-danger col-md-12 mt-1">
                    {formik.errors && formik.errors.banner
                      ? formik.errors.banner
                      : formik.errors.mobilebanner
                      ? formik.errors.mobilebanner
                      : ""}
                  </small>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="row">
            <Description formik={formik} webName="therapy_module_description" />{" "}
          </div>
        </div>

        <div className="col-md-12">
          <MetaInput
            formik={formik}
            parentArray="therapy_module_meta"
            typeKey="type"
            titleKey="key"
            valueKey="value"
          />
        </div>
        <div className={`d-flex align-items-end justify-content-end col-md-12`}>
          {formik.values.therapy_module_id && (
            <button
              type="button"
              className="btn  float-right mr-3"
              onClick={resetModal}
            >
              Add New
            </button>
          )}
          <button
            type="button"
            className="btn btn-primary btn-outline-success float-right"
            disabled={Object.keys(formik.errors).length !== 0}
            onClick={
              formik.isValid && Object.keys(formik.values).length !== 0
                ? formik.handleSubmit
                : handleError
            }
          >
            {!formik.values.therapy_module_id
              ? "Create Module"
              : "Update Module"}
          </button>
        </div>
      </div>
    </div>
  );
}

const validationSchema = Yup.object().shape({
  therapy_module_name: Yup.string().required("Enter a name"),
  // .min(4, "Name should be min of 4 characters")
  // .max(30, "Name should be maximum of 30 characters"),
  //   screener_topic_id: Yup.object().required("Select a topic"),
  therapy_module_description: Yup.string().required("Enter a Description"),
  // .min(4, "Description should be min of 4 characters")
  // .max(150, "Description should be maximum of 150 characters")
  mobile_description: Yup.string().required("Enter mobile description"),
  sort_order: Yup.string().required("Enter sort order"),
  min_completion_days: Yup.string().required("Enter min completion days"),
  max_completion_days: Yup.string().required("Enter max completion days"),
  start_interval: Yup.string().required("Enter start interval"),
  therapy_module_meta: Yup.array().of(
    Yup.object().shape({
      type: Yup.string().required("Select a type"),
      key: Yup.string().required("Enter a key"),
      value: Yup.string().required("Enter a value"),
    })
  ),
});

const initialValues = {
  //   therapy_module_sessions: [],
  therapy_module_meta: [],
};
