import {
  GET_DOMAIN_LIST,
  GET_SUB_DOMAIN_LIST,
  GET_CATEGORY_LIST,
  GET_SCREENER_LIST,
  GET_INTERVENTION_LIST,
  GET_THERAPY_LIST,
  GET_BLOG_LIST,
  GET_PLAN_LIST,
  GET_OBJECTIVE_LIST,
  GET_FUNCTION_LIST,
  GET_DEFAULT_CHOICES,
} from "./types";

const InitialState = {
  domains: [],
  subDomains: [],
  categories: [],
  screeners: [],
  interventions: [],
  therapies: [],
  blogs: [],
  plans: [],
  //   objectives: [],
  functions: [],
  defaultChoices: [],
};

const Reducer = (state = InitialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_DOMAIN_LIST:
      return {
        ...state,
        domains: payload || [],
      };
    case GET_SUB_DOMAIN_LIST:
      return {
        ...state,
        subDomains: payload || [],
      };
    case GET_CATEGORY_LIST:
      return {
        ...state,
        categories: payload || [],
      };
    case GET_SCREENER_LIST:
      return {
        ...state,
        screeners: payload || [],
      };
    case GET_INTERVENTION_LIST:
      return {
        ...state,
        interventions: payload || [],
      };
    case GET_THERAPY_LIST:
      return {
        ...state,
        therapies: payload || [],
      };
    case GET_BLOG_LIST:
      return {
        ...state,
        blogs: payload || [],
      };
    case GET_PLAN_LIST:
      return {
        ...state,
        plans: payload || [],
      };
    case GET_FUNCTION_LIST:
      return {
        ...state,
        functions: payload || [],
      };
    // case GET_OBJECTIVE_LIST:
    //   return {
    //     ...state,
    //     objectives: payload || [],
    //   };
    case GET_DEFAULT_CHOICES:
      return {
        ...state,
        defaultChoices: payload,
      };
    default:
      return state;
  }
};

export default Reducer;
