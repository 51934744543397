import { useState } from "react";
import { toast } from "react-toastify";
import graphHandler from "../../../Apollo/graphHandler";
import { UPDATE_PASSWORD_GQL } from "../../../Apollo/ProfileService/profileMutateRequest";

export default function UpdatePassword() {
  const [password, setPassword] = useState("");
  const [editPass, setEditPass] = useState(false);

  const updatePassword = async (password) => {
    try {
      await graphHandler().invoke(UPDATE_PASSWORD_GQL, { password }, 0, false);
      toast.success("Password Updated successfully!!!");
      setEditPass(false);
      setPassword("");
    } catch (error) {
      console.log("updatePassword error", error);
    }
  };
  return (
    <div className="col-md-6 p-0 mb-3">
      {editPass ? (
        <div className="d-flex flew-wrap justify-content-between">
          <input
            type="password"
            className="form-control mr-3"
            placeholder="New password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <button
            type="button"
            className="btn btn-outline-success"
            onClick={() => updatePassword("12345678")}
            disabled={!password || password.length < 4}
          >
            Update
          </button>
        </div>
      ) : (
        <button
          type="button"
          className="btn btn-dark"
          onClick={() => setEditPass(true)}
        >
          Change Password
        </button>
      )}
    </div>
  );
}
