import { useState } from "react";
import PaginationComponent from "../../../components/PaginationComponent";
import { sortArray } from "../../../utils/Functions/filter";
import FaqCategoryListItem from "../components/FaqCategoryListItem";

export default function Categories({
  faqCategoryList = [],
  access = {},
  loading,
  refresh = {},
}) {
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize] = useState(10);
  const pageCount = Math.ceil(faqCategoryList.length / pageSize);
  const handleClick = (event, index) => {
    event.preventDefault();
    setCurrentPage(index);
  };
  return (
    <>
      <div className="table-responsive ">
        <table className="table table-borderless">
          <thead>
            <tr className=" ">
              {access.isAdmin && <td className=""></td>}
              <td className="col-sm-4">Category Name</td>
              <td className="col-sm-6">Status</td>
              {access.canEdit && <td className="col-sm-2">Action</td>}
              {access.canEdit && <td className="col-sm-2">Activity</td>}
            </tr>
          </thead>
          <tbody>
            {!loading && faqCategoryList && faqCategoryList.length > 0 ? (
              sortArray(faqCategoryList)
                .slice(currentPage * pageSize, (currentPage + 1) * pageSize)
                .map((x, i) => (
                  <FaqCategoryListItem
                    key={i}
                    item={x}
                    index={i}
                    refresh={refresh}
                    access={access}
                  />
                ))
            ) : (
              <tr>
                <td colSpan="3" className="text-center">
                  <span className="font-weight-bold">
                    {loading ? "Loading..." : "Oops! No Data Found"}
                  </span>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {faqCategoryList.length > 0 && pageCount != 1 && (
        <PaginationComponent
          handleClick={handleClick}
          currentPage={currentPage}
          pageCount={pageCount}
        />
      )}
    </>
  );
}
