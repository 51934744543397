import { useEffect } from "react";
import { Modal, ModalBody } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import graphHandler from "../../../Apollo/graphHandler";
import { UPDATE_COUNTRY_GQL } from "../../../Apollo/AdminServices/MutationRequest";
import CustomButton from "../../../components/Button/CustomButton";

export default function UpdateCountry({
  isOpen,
  setOpen,
  refresh,
  initialValue = {},
}) {
  const formik = useFormik({
    initialValues: {},
    validationSchema: validation,
    onSubmit: (values) => {
      onSubmit(values);
    },
  });
  useEffect(() => {
    if (initialValue.country_id) {
      const value = {
        country_id: initialValue.country_id,
        country_name: initialValue.country_name,
      };

      if (initialValue.country_short_code) {
        value.country_short_code = initialValue.country_short_code;
      }
      if (initialValue.country_mobile_number_prefix) {
        value.country_mobile_number_prefix =
          initialValue.country_mobile_number_prefix;
      }
      formik.setValues(value);
    }
  }, [initialValue, isOpen]);

  const onClose = () => {
    formik.resetForm();
    setOpen();
  };

  const onSubmit = async (values) => {
    const { country_name, country_short_code, country_mobile_number_prefix } =
      values;
    const update_request = {
      // country_name:country_name.trim(),
      // country_short_code:country_short_code.trim(),
      country_id: initialValue.country_id,
      country_mobile_number_prefix,
    };
    if(initialValue.country_short_code !== country_short_code.trim()){
      update_request.country_short_code=country_short_code.trim()
    }
    if(initialValue.country_name !== country_name.trim()){
      update_request.country_name=country_name.trim()
    }
    try {
      await graphHandler().invoke(
        UPDATE_COUNTRY_GQL,
        { update_request },
        1,
        true
      );
      refresh();
      onClose();
      formik.setSubmitting(false);
    } catch (error) {
      console.log("UpdateCountry error", error);
      formik.setSubmitting(false);
    }
  };

  const handleError = () => {
    if (Object.keys(formik.errors).length > 0) {
      toast.error(formik.errors[Object.keys(formik.errors)[0]]);
    }
  };

  return (
    <Modal toggle={onClose} isOpen={isOpen} centered scrollable size="xl">
      <div className="modal-header">
        <button type="button" className="close" onClick={onClose}>
          <span aria-hidden="true">&times;</span>
        </button>
        <h5 className="modal-title">Update Country</h5>
      </div>
      <ModalBody className="pt-0">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12 form-group">
              <label htmlFor="country_name" className="col-md-4 ">
                Country
              </label>
              <input
                type="text"
                className="form-control"
                id="country_name"
                name="country_name"
                // maxLength="30"
                value={
                  formik.values && formik.values.country_name
                    ? formik.values.country_name
                    : ""
                }
                onChange={(e) =>
                  formik.setFieldValue(
                    "country_name",
                    e.target.value.trimStart()
                  )
                }
                onBlur={(e) => {
                  formik.setFieldTouched("country_name", true);
                  formik.setFieldValue(
                    "country_name",
                    e.target.value.replace(/\s+/g, " ").trim()
                  );
                }}
              />
              <small className="text-danger">
                {formik.errors && formik.errors.country_name
                  ? formik.errors.country_name
                  : ""}
              </small>
            </div>
            <div className="col-md-12 form-group">
              <label htmlFor="country_name" className="col-md-4 ">
                Short Code
              </label>
              <input
                type="text"
                className="form-control"
                id="country_short_code"
                name="country_short_code"
                maxLength="10"
                value={
                  formik.values && formik.values.country_short_code
                    ? formik.values.country_short_code
                    : ""
                }
                onChange={(e) =>
                  formik.setFieldValue(
                    "country_short_code",
                    e.target.value.trimStart()
                  )
                }
              />
              <small className="text-danger">
                {formik.errors && formik.errors.country_short_code
                  ? formik.errors.country_short_code
                  : ""}
              </small>
            </div>{" "}
            <div className="col-md-12 form-group ">
              <label
                htmlFor="country_mobile_number_prefix"
                className="col-md-4 "
              >
                Mobile Code
              </label>
              <input
                type="text"
                className="form-control"
                id="country_mobile_number_prefix"
                name="country_mobile_number_prefix"
                // maxLength="30"
                value={
                  formik.values && formik.values.country_mobile_number_prefix
                    ? formik.values.country_mobile_number_prefix
                    : ""
                }
                onChange={(e) =>
                  formik.setFieldValue(
                    "country_mobile_number_prefix",
                    e.target.value.replace(/[^0-9+]/gi, "")
                  )
                }
              />
              <small className="text-danger">
                {formik.errors && formik.errors.country_mobile_number_prefix
                  ? formik.errors.country_mobile_number_prefix
                  : ""}
              </small>
            </div>
            <CustomButton
              type="button"
              className="btn btn-primary btn-outline-success float-right btn-add-domain"
              disabled={
                Object.keys(formik.errors).length !== 0 ||
                !formik.isValid ||
                formik.isSubmitting
              }
              onClick={
                formik.isValid && Object.keys(formik.values).length !== 0
                  ? formik.handleSubmit
                  : handleError
              }
            >
              Update
            </CustomButton>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
}

const CountryCode = /^\+?\d+$/;
const validation = Yup.object().shape({
  country_name: Yup.string().required("Enter country"),
  country_short_code: Yup.string().required("Enter Shortcode"),
  country_mobile_number_prefix: Yup.string()
    .required("Enter Mobile Code")
    .matches(CountryCode, "Country Code is not valid"),
});
