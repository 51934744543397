import { useFormik } from "formik";
import { useMemo } from "react";
import { useEffect } from "react";
import * as Yup from "yup";
import { IsNullOrEmpty } from "../../utils/Functions/filter";
import usePrevious from "../../utils/Functions/usePrevious";

export default function TimeInputField({
  parentForm = {},
  className = "col-md-12",
  updateValue = "",
  actualValue = "",
  id = "",
  title = "",
  disabled = false,
  validation = {},
  inputProps = {},
  customBlur = false
}) {
  const formik = useFormik({
    initialValues: {},
    validationSchema: Yup.object().shape(validation),
    onSubmit: () => {},
    validateOnChange: true,
  });
  const previous = usePrevious(formik.errors[id]);
  useEffect(() => {
    if (!IsNullOrEmpty(updateValue) && parentForm) {
      parentForm.setFieldValue(id, updateValue);
    }
  }, [updateValue]);

  useEffect(() => {
    if (formik.errors[id]) {
      parentForm.setFieldError(id, formik.errors[id] ? formik.errors[id] : "");
    } else if (previous !== formik.errors[id]) {
      let errors = { ...parentForm.errors };
      delete errors[id];
      parentForm.setErrors(errors);
    }
  }, [formik.errors, parentForm.errors]);

  useMemo(() => {
    formik.setFieldValue(
      id,
      !IsNullOrEmpty(updateValue) ? updateValue : actualValue
    );
  }, [updateValue, actualValue]);

  const onChange = (e) => {
    let { name, value } = e.target;
    formik.setFieldValue(name, value);
    parentForm && parentForm.setFieldValue(name, value);
  };

  const onBlue = (e) => {
    let { name, value } = e.target;
    formik.setFieldTouched(name, true)
    formik.setFieldValue(name, value);
    parentForm && parentForm.setFieldValue(name, value);
  };

  return (
    <div className={className}>
      <div className="row">
        {updateValue ? (
          <div className="col-md-12 form-group">
            <label htmlFor={id} className="col-form-label w-100 mb-2">
              {title ?? ""}
              <span className="badge badge-good float-right">Existing</span>
            </label>
            <input
              type="time"
              className="form-control"
              value={!IsNullOrEmpty(actualValue) ? actualValue : ""}
              disabled
            />
          </div>
        ) : null}
        <div className="col-md-12 form-group">
          <label htmlFor={id} className="col-form-label w-100 mb-2">
            {title ?? ""}
            {updateValue ? (
              <span className="badge badge-error float-right">New</span>
            ) : null}
          </label>
          <input
            type="time"
            className="form-control"
            id={id}
            name={id}
            value={!IsNullOrEmpty(formik.values[id]) ? formik.values[id] : ""}
            onChange={onChange}
            onBlur={customBlur ? onBlue :formik.handleBlur}
            disabled={disabled}
            {...inputProps}
          />
          <small className="text-danger">
            {formik.errors[id] ? formik.errors[id] : ""}
          </small>
        </div>
      </div>
    </div>
  );
}
