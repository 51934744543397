import { useEffect, useState } from "react";
// import { useHistory } from "react-router-dom";
// import { Link } from "react-router-dom";
import { Popover } from "reactstrap";
import { Switch } from "antd";
import ApproveModal from "../../../components/Modal/ApproveModal";
import JsonCompareModal from "../../../components/Modal/JsonCompareModal";
import useBlogAPI from "../../../utils/CustomHooks/useBlogAPI";
import useSubDomain from "../../../utils/CustomHooks/useSubDomain";
// import EditBlogModal from "../modal/EditBlogModal";
// import MarkdownIt from "markdown-it";
// import parse from "html-react-parser";
import { getDescription } from "../../../utils/Functions/filter";
import { toast } from "react-toastify";
import ReadMore from "../../../components/ReadMore";
import BlogModal from "../modal/BlogModal";
// const mdParser = new MarkdownIt(/* Markdown-it options */);

export default function BlogItem({ item, i, refresh = () => {}, access = {} }) {
  const { subDomain, getSubDomain } = useSubDomain("");
  const [isEdit, setEdit] = useState(false);
  const [isView, setView] = useState(false);
  const [showPop, setShowPop] = useState(false);
  const toggle = () => setShowPop((x) => !x);
  const [isOpenApprove, setOpenApprove] = useState(false);
  const [isApprove, setApprove] = useState(true);
  const { pending_history } = item;
  // const history = useHistory();
  const { canEdit, canApprove, canReview, isAdmin } = access;
  const { approveBlog, reviewBlog, activateBlog } = useBlogAPI();
  const isDone = true;
  const hasHistory = pending_history && pending_history.update_status;
  const readyApprove =
    pending_history &&
    ((pending_history.update_status == "ReviewSuccess" && canApprove) ||
      (pending_history.update_status == "Draft" && canReview));
  useEffect(() => {
    if (item.sub_domain_ids && item.sub_domain_ids.length > 0)
      getSubDomain(item.sub_domain_ids[0]);
  }, [item]);

  const activateItem = async (active) => {
    if (!item.blog_id) return;
    const request = {
      active_request: {
        active,
        blog_id: item.blog_id,
      },
    };
    activateBlog(request, () => {
      setTimeout(() => {
        toast.success(
          `${item.blog_name} ${
            active ? "Activated" : "Deactivated"
          } Successfully`
        );

        refresh();
      }, [1000]);
    });
  };

  const onDone = (value) => {
    if (!hasHistory) return;
    if (pending_history.update_status == "Draft") {
      const request = {
        review_request: { ...value, approve: isApprove },
        blog_history_id: pending_history.blog_history_id,
      };
      reviewBlog(request, refresh);
    } else if (pending_history.update_status == "ReviewSuccess") {
      const request = {
        approve_request: { ...value, approve: isApprove },
        blog_history_id: pending_history.blog_history_id,
      };
      approveBlog(request, refresh);
    }
  };

  const renderStatus = (status) => {
    if (
      hasHistory &&
      status !== "Rejected" &&
      status !== "Draft" &&
      status !== "Reviewfailed"
    ) {
      return <span className="good text-center btn btn-sm">{status}</span>;
    } else if (!hasHistory && item.active && item.published) {
      return <span className="good text-center btn btn-sm">Published</span>;
    } else if (!hasHistory && !item.active && item.published) {
      return <span className="bad text-center btn btn-sm">Disabled</span>;
    } else {
      return (
        <span className="bad text-center btn btn-sm">
          {status || "Rejected"}
        </span>
      );
    }
  };

  const getFilteredObject = (obj) => {
    const temp = { ...obj };
    delete temp.created_at;
    delete temp.created_by;
    delete temp.updated_at;
    delete temp.updated_by;
    delete temp.blog_id;
    delete temp.pending_history;
    delete temp.sub_domain_ids;
    delete temp.content_version;
    delete temp.published;
    delete temp.active;
    return temp;
  };

  // const getContent = (txt) => {
  //   let html = mdParser.renderInline(txt);
  //   return parse(html);
  // };
  return (
    <tr className="option-table">
      {isAdmin && (
        <td>
          <Switch checked={item.active} onChange={activateItem} />
        </td>
      )}
      <td>{item.blog_name ? item.blog_name : "-"}</td>
      <td>
        <ReadMore>
          {item.blog_descriptions && getDescription(item.blog_descriptions, "Web")
            ? getDescription(item.blog_descriptions, "Web").description
            : "-"}
        </ReadMore>
      </td>
      {/* <td>{item.blog_content ? getContent(item.blog_content) : "-"}</td> */}
      <td>
        {subDomain && subDomain.sub_domain_name
          ? subDomain.sub_domain_name
          : "-"}
      </td>
      <td onClick={() => hasHistory && setView(true)}>
        {renderStatus(pending_history ? pending_history.update_status : "")}
      </td>
      <td className="d-flex">
        {isDone && hasHistory && readyApprove ? (
          <>
            <button
              type="button"
              className="btn btn-outline-success btn-sm "
              onClick={() => {
                setOpenApprove(true);
                setApprove(true);
              }}
            >
              {pending_history.update_status == "ReviewSuccess"
                ? "Approve"
                : "Review"}
            </button>
            <button
              type="button"
              className="ml-2 btn btn-danger btn-sm "
              onClick={() => {
                setOpenApprove(true);
                setApprove(false);
              }}
            >
              Reject
            </button>
          </>
        ) : (
          <button
            type="button"
            className="ml-2 btn btn-outline-success btn-sm "
            onClick={() => setEdit(true)}
          >
            {canEdit ? "Edit" : "View"}
          </button>
        )}
      </td>
      <td className="accordion-menu" id={`AccordionTableMenu${i}`}>
        <i className="fas fa-ellipsis-v" />
      </td>
      <Popover
        placement="left"
        target={`AccordionTableMenu${i}`}
        trigger="legacy"
        isOpen={showPop}
        toggle={toggle}
      >
        <div className="accordion-menu-header">Options</div>
        {/* <div
          className="accordion-menu-item"
          onClick={() =>
            history.push(
              `/view-history?type=intervention&id=${item.intervention_id}`
            )
          }
        >
          View History
        </div> */}
        <div
          className="accordion-menu-item"
          onClick={() => {
            toggle();
            setEdit(true);
          }}
        >
          {isDone && !canEdit ? "View" : "Edit"}
        </div>
        {/* {!hasHistory && item.published && (
          <div
            className="accordion-menu-item"
            onClick={() => activateItem(!item.active)}
          >
            {!item.active ? "Activate" : "Deactivate"}
          </div>
        )} */}
        {hasHistory && (
          <div
            className="accordion-menu-item"
            onClick={() => {
              toggle();
              setView(true);
            }}
          >
            Compare
          </div>
        )}
      </Popover>
      <BlogModal
          isOpen={isEdit}
          setOpen={() => setEdit(false)}
          initialValues={item}
          refresh={() => {
            refresh((x) => x + 1);
          }}
          isEditable={canEdit}
        />
      {readyApprove && (
        <ApproveModal
          setOpen={() => setOpenApprove(false)}
          isOpen={isOpenApprove}
          isApprove={isApprove}
          title={
            isApprove
              ? pending_history.update_status == "ReviewSuccess"
                ? "Approve"
                : "Review"
              : "Reject"
          }
          refresh={refresh}
          onDone={onDone}
          isRequire={true}
          isShowDate={
            pending_history && pending_history.update_status == "ReviewSuccess"
          }
        />
      )}
      {hasHistory && (
        <JsonCompareModal
          setOpen={() => setView(false)}
          isOpen={isView}
          title="Screener Compare"
          actualDate={getFilteredObject(item)}
          newDate={getFilteredObject(pending_history.data)}
        />
      )}
    </tr>
  );
}
