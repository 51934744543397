import { useEffect } from "react";
import { Modal, ModalBody } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { FaqCategoryTypes } from "../../../constants";
import graphHandler from "../../../Apollo/graphHandler";
import { CREATE_FAQ_CATEGORY_GQL, UPDATE_FAQ_CATEGORY_GQL } from "../../../Apollo/AdminServices/MutationRequest";
import CustomButton from "../../../components/Button/CustomButton";
// import { getDescription } from "../../../utils/Functions/filter";
const validation = Yup.object().shape({
  faq_category_name: Yup.string().required("Enter a name"),
  faq_category_type: Yup.string().required("Select a category type"),
});

export default function FAQCategoryModal({
  isOpen,
  setOpen,
  refresh,
  initialValues = {},
}) {
  const formik = useFormik({
    initialValues: {},
    initialStatus: false,
    validationSchema: validation,
    onSubmit: (values) => {
      onSubmit(values);
    },
  });

  useEffect(() => {
    if (initialValues.faq_category_name && isOpen) onEdit(initialValues);
  }, [initialValues, isOpen]);

  const onClose = () => {
    formik.resetForm();
    setOpen();
  };

  const onSubmit = async (values) => {
    const { faq_category_name, faq_category_type } = values;
    const request = { faq_category_name, faq_category_type };
    if (initialValues.faq_category_name) {
      updateFAQCategory(request);
    } else {
      createFAQCategory(request);
    }
  };

  const createFAQCategory = async (addReq = {}) => {
    try {
      await graphHandler().invoke(
        CREATE_FAQ_CATEGORY_GQL,
        { addReq },
        1,
        false
      );
      toast.success("Added Successfully!");
      refresh();
      onClose();
      formik.setSubmitting(false);
    } catch (error) {
      console.log("createFAQ error", error);
      formik.setSubmitting(false);
    }
  };

  const updateFAQCategory = async (update_request) => {
    try {
      await graphHandler().invoke(
        UPDATE_FAQ_CATEGORY_GQL,
        { update_request },
        1,
        false
      );
      toast.success("Update Successfully!");
      refresh();
      onClose();
      formik.setSubmitting(false);
    } catch (error) {
      console.log("updateFAQCategory error", error);
      formik.setSubmitting(false);
    }
  };

  const handleError = () => {
    if (Object.keys(formik.errors).length > 0) {
      toast.error(formik.errors[Object.keys(formik.errors)[0]]);
    }
  };

  const onEdit = (values = {}) => {
    if (values.faq_category_name) {
      let model = {
        faq_category_name: values.faq_category_name,
      };
      if (values.faq_category_type)
        model.faq_category_type = values.faq_category_type;
      formik.setValues(model);
    }
  };

  return (
    <Modal toggle={onClose} isOpen={isOpen} centered scrollable size="xl">
      <div className="modal-header">
        <button type="button" className="close" onClick={onClose}>
          <span aria-hidden="true">&times;</span>
        </button>
        <h5 className="modal-title">
          {initialValues.faq_category_name ? "Update" : "Create"} FAQ Category
        </h5>
      </div>
      <ModalBody>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12 form-group">
              <label
                htmlFor="faq_category_name"
                className="col-form-label w-100 mb-2"
              >
                Category Name
              </label>
              <input
                type="text"
                className="form-control"
                id="faq_category_name"
                name="faq_category_name"
                // maxLength="30"
                value={
                  formik.values && formik.values.faq_category_name
                    ? formik.values.faq_category_name
                    : ""
                }
                onChange={(e) =>
                  formik.setFieldValue(
                    "faq_category_name",
                    e.target.value.trimStart()
                  )
                }
                onBlur={(e) => {
                  formik.setFieldTouched("faq_category_name", true);
                  formik.setFieldValue(
                    "faq_category_name",
                    e.target.value.replace(/\s+/g, " ").trim()
                  );
                }}
              />
              <small className="text-danger">
                {formik.errors &&
                formik.errors.faq_category_name &&
                formik.touched.faq_category_name
                  ? formik.errors.faq_category_name
                  : ""}
              </small>
            </div>
            <div className="col-md-12 form-group">
              <label
                htmlFor="faq_category_type"
                className="col-form-label w-100 mb-2"
              >
                Category Type
              </label>
              <select
                id="faq_category_type"
                name="faq_category_type"
                className="form-control"
                onChange={formik.handleChange}
                value={
                  formik.values.faq_category_type
                    ? formik.values.faq_category_type
                    : ""
                }
                onBlur={formik.handleBlur}
              >
                <option key="Select" hidden>
                  Select
                </option>
                {FaqCategoryTypes.map((x, i) => (
                  <option value={x.value} key={i}>
                    {x.label}
                  </option>
                ))}
              </select>
              <small className="text-danger">
                {formik.touched.faq_category_type &&
                formik.errors.faq_category_type
                  ? formik.errors.faq_category_type
                  : ""}
              </small>
            </div>
            <CustomButton
              type="button"
              className="btn btn-primary btn-outline-success float-right btn-add-domain"
              disabled={
                Object.keys(formik.errors).length !== 0 ||
                !formik.isValid ||
                formik.isSubmitting
              }
              onClick={
                formik.isValid && Object.keys(formik.values).length !== 0
                  ? formik.handleSubmit
                  : handleError
              }
            >
              {initialValues.faq_category_name ? "Update" : "Create"}
            </CustomButton>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
}
