import { useState } from "react";
import { GET_COUNTRY_LIST } from "../../Apollo/AdminServices/QueryRequest";
import graphHandler from "../../Apollo/graphHandler";
function useCountryList(){
    const [countryList, setCountryList] = useState([]);
    const [loading, setLoading] = useState(false);
    
  const getCountryList = async (req = {}) => {
    setLoading(true);
    try {
      let { data } = await graphHandler().invoke(
        GET_COUNTRY_LIST,
        req,
        1,
        false,
        true
      );
      if (
        data &&
        data.getCountryList &&
        data.getCountryList.countries &&
        data.getCountryList.countries.length > 0
      ) {
        // console.log(
        //   "getCountryList Success",
        //   data.getCountryList.countries
        // );
        setCountryList([]);
        setCountryList(data.getCountryList.countries.slice());
        setLoading(false);
      } else {
        setCountryList([]);
        setLoading(false);
      }
    } catch (error) {
      console.log("getCountryList Error", error);
      setCountryList([]);
      setLoading(false);
    }
  };
  return {
    countryList,
    loading,
    getCountryList,
  };
  
}
export default useCountryList;