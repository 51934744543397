import { useEffect, useState } from "react";
import FAQModal from "./modal/FAQModal";
import withRoleBased from "../../utils/Functions/withRoleBased";
import useFAQList from "../../utils/CustomHooks/useFAQList";
import useFAQCategoryList from "../../utils/CustomHooks/useFAQCategoryList";
import { Tabs } from "antd";
import Categories from "./section/Categories";
import Questions from "./section/Questions";
const { TabPane: TabPanel } = Tabs;

function Domain({ access }) {
  const [count, setCount] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const { faqList, loading, getFaqList } = useFAQList("");
  const {
    faqCategoryList,
    loading: loading1,
    getFaqCategoryList,
  } = useFAQCategoryList("");
  const [search, setSearch] = useState("");
  const [isSearching, setIsSearch] = useState(false);
  const [active, setActive] = useState("Question");

  useEffect(() => {
    let req = {};
    if (search) req.faq_category_name = search;
    getFaqList(req);
    getFaqCategoryList(req);
  }, [count]);

  const onSearch = () => {
    setCurrentPage(0)
    setCount((x) => x + 1);
    setIsSearch(search ? true : false);
  };
  return (
    <main className="page-content">
      <div className="container-fluid">
        <div className="row  m-0 w-100">
          <div className="col-md-6 mb-3">
            <h5 className="page-title"> FAQ </h5>
          </div>

          <div className="col-md-6  mb-3 d-flex justify-content-end">
            {access.canCreate && (
              <button
                type="button"
                className="btn btn-outline-success "
                onClick={() => setIsOpen(true)}
              >
                Create FAQ
              </button>
            )}
          </div>

          <div className="col-md-4">
            <div className="search" style={{ margin: "16px 0px 32px" }}>
              <input
                type="text"
                className="form-control"
                placeholder="Search"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                onKeyDown={(e) => e.key === "Enter" && onSearch()}
              />
              {!isSearching ? (
                <button onClick={onSearch}>
                  <i className="fa fa-search" />
                </button>
              ) : (
                <button
                  onClick={() => {
                    setSearch("");
                    setIsSearch(false);
                    setCount((x) => x + 1);
                  }}
                >
                  <i className="fa fa-times" />
                </button>
              )}
            </div>
          </div>
          <div className="col-md-12">
            <Tabs centered onTabClick={setActive} activeKey={active}>
              <TabPanel tab="Question" key="Question">
                <Questions
                  {...{
                    faqList,
                    access,
                    loading,
                    refresh: () => setCount((x) => x + 1),
                  }}
                />
              </TabPanel>
              <TabPanel tab="Category" key="Category">
                <Categories
                  {...{
                    faqCategoryList,
                    access,
                    loading: loading1,
                    refresh: () => setCount((x) => x + 1),
                  }}
                />
              </TabPanel>
            </Tabs>
          </div>
        </div>

        <FAQModal
          isOpen={isOpen}
          setOpen={() => setIsOpen(false)}
          refresh={() => setCount((x) => x + 1)}
        />
      </div>
    </main>
  );
}

export default withRoleBased(Domain, "domain");
