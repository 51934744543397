import { useState } from "react";
import { GET_PLAN_LIST_GQL } from "../../Apollo/AdminServices/NewQueryRequest";
import graphHandler from "../../Apollo/graphHandler";
import { sortArray } from "../Functions/filter";

function usePlanList() {
  const [planList, setPlanList] = useState([]);
  const [loading, setLoading] = useState(false);

  const getPlanList = async (req = {}, get_pending_update = true) => {
    setLoading(true);
    try {
      const { data } = await graphHandler().invoke(
        GET_PLAN_LIST_GQL,
        { get_pending_update, ...req },
        1,
        false,
        true
      );
      if (
        data &&
        data.getPlanList &&
        data.getPlanList.plans &&
        data.getPlanList.plans.length > 0
      ) {
        // console.log("getPlanList Success", data.getPlanList);
        const struct = data.getPlanList.plans.map((x) => ({
          ...x.plan,
          label: x.plan.plan_name,
          value: x.plan.plan_id,
          pending_history: x.pending_plan_history,
        }));
        setPlanList([]);
        setPlanList(sortArray(struct));
        setLoading(false);
      } else {
        setPlanList([]);
        setLoading(false);
      }
    } catch (error) {
      console.log("getPlanList Error", error);
      setPlanList([]);
      setLoading(false);
    }
  };

  return { planList, loading, getPlanList };
}

export default usePlanList;
