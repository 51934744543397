import { Popconfirm, Switch } from "antd";
import { useEffect, useState } from "react";
import useGetContent from "../../../utils/CustomHooks/useGetContent";
import usePlatformList from "../../../utils/CustomHooks/usePlatformList";
import UpdatePlatformContent from "../modal/UpdatePlatformContent";

export default function ContentListItem({
  item = {},
  access,
  refresh = () => {},
}) {
  const { content, loading, getContent } = useGetContent(item.content_type);
  const [isEdit, setEdit] = useState(false);
  const { removePlatfom } = usePlatformList(true,true);
  useEffect(() => {
    if (item.content_id) {
      getContent(item.content_id);
    }
  }, [item]);

  const onDelete = () => {
    if (loading) return;
    removePlatfom({ platform_content_mode_id: item.platform_content_mode_id }, refresh);
  };

  const getName = () => {
    if (loading || !content || !item.content_type) return "";
    else if (item.content_type === "SCREENER") return content.screener_name;
    else if (item.content_type === "INTERVENTION")
      return content.intervention_name;
    else if (item.content_type === "THERAPY") return content.therapy_name;
    else if (item.content_type === "BLOG") return content.blog_name;
    else if (item.content_type === "PLAN") return content.plan_name;
    else if (item.content_type === "CATEGORY") return content.category_name;
    else if (item.content_type === "SUBDOMAIN") return content.sub_domain_name;
    else if (item.content_type === "DOMAIN") return content.domain_name;
    else return "";
  };

  return (
    <tr className="option-table">
      <td>{item.platform_content_mode_name ?? "-"}</td>
      <td>{item.content_type}</td>
      <td>{getName() || "-"}</td>
      <td>{item.platform_content_mode_tag??"-"}</td>
      <td>
        <Switch checked={item.is_featured} />
      </td>
      <td>
        <Switch checked={item.is_pilot} />
      </td>
      <td>
        <Switch checked={item.is_live} />
      </td>
      {/* <td>
        <span
          className={`${item.active ? "good" : "bad"} text-center btn btn-sm`}
        >
          {item.active ? "Active" : "Disabled"}
        </span>
      </td> */}
      {access.canCreate && (
        <td>
          <button
            type="button"
            className="ml-2 btn btn-outline-success btn-sm "
            onClick={() => setEdit(true)}
          >
            Edit
          </button>
          <Popconfirm
            title="Are you sure to delete this platform content mode?"
            onConfirm={onDelete}
            okText="Yes"
            cancelText="No"
          >
            <i className="ml-2 fas fa-trash cursor-pointer"></i>
          </Popconfirm>
        </td>
      )}
      {access.canCreate && (
        <UpdatePlatformContent
          isOpen={isEdit}
          setOpen={() => setEdit(false)}
          refresh={refresh}
          values={item}
        />
      )}
    </tr>
  );
}
