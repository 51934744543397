import { useState } from "react";
import { toast } from "react-toastify";
import { Popover } from "reactstrap";
import { ACTIVATE_ORGANIZATION_GQL } from "../../../Apollo/ApplicationService/OrganizationResponse";
import graphHandler from "../../../Apollo/graphHandler";
import OrganizationModal from "../modal/OrganizationModal";

export default function ListItem({
  item = {},
  i,
  refresh = () => {},
  access = {},
}) {
  const [isEdit, setEdit] = useState(false);
  // const [showPop, setShowPop] = useState(false);
  // const toggle = () => setShowPop((x) => !x);

  // const activateOrganization = async (active) => {
  //   toggle();
  //   let active_request = {
  //     active,
  //     organization_id: item.organization_id,
  //   };
  //   try {
  //     await graphHandler().invoke(
  //       ACTIVATE_ORGANIZATION_GQL,
  //       { active_request },
  //       2,
  //       false,
  //       true
  //     );
  //     toast.success(
  //       `${item.organization_name} ${active ? "Activated" : "Deactivated"} Successfully`
  //     );
  //     refresh();
  //   } catch (error) {
  //     console.log("activateUser error", error);
  //   }
  // };
  return (
    <tr className="">
      <td>{item.organization_name || "-"}</td>
      <td>{item.organization_email || "-"}</td>
      <td>{item.domain_name || "-"}</td>
      {/* <td>
        <span
          className={`${
            item.organization_status === "Active" ? "good" : "bad"
          } text-center btn btn-sm`}
        >
          {item.organization_status}
        </span>
      </td> */}
      <td>
        {" "}
        {item?.email_domains?.length > 0 && item.email_domains
          ? item.email_domains.join(", ")
          : "-"}
      </td>
      <td>
        <span
          className={`${item.organization_status === "Active"? "good" : "bad"} text-center btn btn-sm`}
        >
          {item.organization_status}
        </span>
      </td>
      {access.canEdit && (
        <>
          <td>
            <button
              type="button"
              className="btn btn-outline-success btn-sm "
              onClick={() => setEdit(true)}
            >
              Edit
            </button>
          </td>
          {/* <td
            className="accordion-menu"
            id={`AccordionTableMenu${i}`}
            onClick={toggle}
          >
            <i className="fas fa-ellipsis-v" />
          </td>
          <Popover
            isOpen={showPop}
            toggle={toggle}
            placement="left"
            target={`AccordionTableMenu${i}`}
            trigger="legacy"
          >
            <div className="accordion-menu-header">Options</div>

            <div
              className="accordion-menu-item"
              onClick={() => activateOrganization(!item.active)}
            >
              {!item.active ? "Activate" : "Deactivate"}
            </div>
          </Popover> */}
        </>
      )}
      {access.canEdit && (
        <OrganizationModal
          isOpen={isEdit}
          setOpen={() => setEdit(false)}
          refresh={refresh}
          values={item}
        />
      )}
    </tr>
  );
}
