import { useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { withoutFormat } from "../../constants";

function useUploadImage(path = "admin") {
  const [url, setUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const { session, user } = useSelector(({ Auth }) => Auth);
  const { user_id } = user;
  const upload = async (file, type = "Image") => {
    setLoading(true);
    try {
      let formHeader = new Headers();
      formHeader.append("path", path);
      formHeader.append("asset_key", user_id);
      formHeader.append("x-access-token", session);
      formHeader.append("document_type", type);
      formHeader.append("file_name");
      let formdata = new FormData();
      formdata.append("document", file, "[PROXY]");
      let options = {
        method: "POST",
        headers: formHeader,
        body: formdata,
        redirect: "follow",
      };
      fetch(
        // eslint-disable-next-line no-undef
        process.env.REACT_APP_DOCUMENT_URL,
        options
      )
        .then((response) => response.json())
        .then((data) => {
          // console.log(data);
          if (data.document && data.document.url) {
            setUrl(data.document.url);
          } else if (data.error) {
            toast(data.error.toString());
            setUrl("");
          }
        })
        .finally(() => setLoading(false))
        .catch((error) => {
          toast(error.message);
          console.error("Upload Error", error);
          setUrl("");
          setLoading(false);
        });
    } catch (error) {
      toast(error.message);
      console.error("Upload Error", error);
      setUrl("");
      setLoading(false);
    }
  };

  return { url, loading, upload, reset: () => setUrl(""), setUrl };
}

export default useUploadImage;
export function uploadHandler(path = "admin") {
  const session = localStorage.getItem("ADMIN_TOKEN");
  const user_id = localStorage.getItem("ADMIN_UserID");
  const upload = (file, type = "Image",content_id="",content_type="") => {
    let formHeader = new Headers();
    formHeader.append("path", path);
    formHeader.append("user_id", user_id);
    formHeader.append("x-access-token", session);
    formHeader.append("document_type", type);
    formHeader.append("file_name", withoutFormat(file.name));
    formHeader.append("content_id", content_id);
    formHeader.append("content_type", content_type);
    let formdata = new FormData();
    formdata.append("document", file, "[PROXY]");
    let options = {
      method: "POST",
      headers: formHeader,
      body: formdata,
      redirect: "follow",
    };
    return new Promise(async (resolve, reject) => {
      try {
        let response = await fetch(
          // eslint-disable-next-line no-undef
          process.env.REACT_APP_DOCUMENT_URL,
          options
        );
        let data = await response.json();
        if (data.document && data.document) {
          resolve(data.document);
        } else if (data.error) {
          reject(data.error.toString());
        }
      } catch (error) {
        console.error("Upload Error", error);
        reject(error.toString());
      }
    });
  };

  return { upload };
}
export function uploadAxiosHandler(path = "admin") {
  const session = localStorage.getItem("ADMIN_TOKEN");
  const user_id = localStorage.getItem("ADMIN_UserID");
  const upload = (file, type = "Image") => {
    let formHeader = new Headers();
    formHeader.append("path", path);
    formHeader.append("user_id", user_id);
    formHeader.append("x-access-token", session);
    formHeader.append("document_type", type);
    formHeader.append("file_name", file.name);
    let formdata = new FormData();
    formdata.append("document", file, "[PROXY]");
    let options = {
      method: "POST",
      headers: formHeader,
      body: formdata,
      redirect: "follow",
    };
    return new Promise(async (resolve, reject) => {
      try {
        let response = await fetch(
          // eslint-disable-next-line no-undef
          process.env.REACT_APP_DOCUMENT_URL,
          options
        );
        let data = await response.json();
        if (data.document && data.document) {
          resolve(data.document);
        } else if (data.error) {
          reject(data.error.toString());
        }
      } catch (error) {
        console.error("Upload Error", error);
        reject(error.toString());
      }
    });
  };

  return { upload };
}

//   Image,PDF,Document,Link
