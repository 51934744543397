import { useState, useEffect } from "react";
import graphHandler from "../../Apollo/graphHandler";
import { GET_USER } from "../../Apollo/ProfileService/profileQueryRequest";
import "./details.css";
import CustomLoader from "../../components/Loader";
import UserDetailsComponent from "./components/UserDetailsComponent";
import UserRole from "./components/UserRole";
import UseUserHistory from "../../utils/CustomHooks/useUserHistory";
import UseUserContentHistory from "../../utils/CustomHooks/useUserContentHistory";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import moment from "moment";
import ActionHistory from "./components/ActionHistory";
import ContentHistory from "./components/ContentHistory";
import withRoleBased from "../../utils/Functions/withRoleBased";
import useEmployee from "../../utils/CustomHooks/useEmployee";
const UserDetails = ({ location, history, access }) => {
  const [userCount, setUserCount] = useState(0);

  const { user, profile, loading, getEmployee } = useEmployee("", true);
  const [active, setActive] = useState(1);
  const { userHistory, getUserHistory, clear } = UseUserHistory("");
  const { userContentHistory, getUserContentHistory } =
    UseUserContentHistory("");
  useEffect(() => {
    const query = new URLSearchParams(location.search);
    if (loading && query.has("id") && query.get("id")) {
      getEmployee(query.get("id"));
      getUserHistory(query.get("id"));
      getUserContentHistory(query.get("id"));
    } else if (!query.has("id")) {
      if (history.previous) {
        history.goBack();
      } else {
        history.push("/");
      }
    }
  }, [loading, location.search]);
  useEffect(() => {
    if (userCount != 0 && user.user_id) {
      getEmployee(user.user_id);
      getUserHistory(user.user_id);
      getUserContentHistory(user.user_id);
    }
  }, [userCount]);

  if (loading) return <CustomLoader />;
  return (
    <main className="page-content">
      <div className="container-fluid">
        <div className="row ">
          <div className="view  col-md-12" id="mainform">
            <UserDetailsComponent
              value={user}
              profile={profile}
              refresh={() => setUserCount((x) => x + 1)}
              access={access}
            />
            <UserRole user_id={user.user_id} access={access} />
          </div>
          {/* <div className="assign-wrapper col-md-12 d-flex flex-column  ">
            <p className="modal-sub-title font-weight-bold text-uppercase">
              action history
            </p>

             <div className="form-group">
              <div className="table-responsive ">
                <table className="table   table-borderless">
                  <thead>
                    <tr>
                      <th scope="col-3" className="w-25">
                        Action
                      </th>
                      <th scope="col" className="w-25">
                        Time
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td scope="row">Changed Name in Screener</td>
                      <td>12.30</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div> 
          </div> */}
        </div>
        <Nav tabs>
          <NavItem>
            <NavLink
              className={`mb-0 font-weight-bold text-uppercase ${
                active === 1 && "active"
              }`}
              onClick={() => setActive(1)}
            >
              Action history
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={`mb-0 font-weight-bold text-uppercase ${
                active === 2 && "active"
              }`}
              onClick={() => setActive(2)}
            >
              Content history
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent
          activeTab={active.toString()}
          style={{ border: "1px 1px 1px 1px" }}
        >
          <TabPane tabId="1">
            <ActionHistory historyData={userHistory} />
          </TabPane>
          <TabPane tabId="2">
            <ContentHistory historyData={userContentHistory} />
          </TabPane>
        </TabContent>
        {/* {userList.length > 0 && (
          <PaginationComponent
            handleClick={handleClick}
            currentPage={currentPage}
            pageCount={pageCount}
          />
        )} */}
        {/* <nav aria-label="Page navigation ">
          <ul className="pagination justify-content-center  ">
            <li className="page-item">
              <a className="page-link" href="#" aria-label="Previous">
                <span aria-hidden="true">&laquo;</span>
              </a>
            </li>
            <li className="page-item active">
              <a className="page-link" href="#">
                1
              </a>
            </li>

            <li className="page-item">
              <a className="page-link" href="#" aria-label="Next">
                <span aria-hidden="true">&raquo;</span>
              </a>
            </li>
          </ul>
        </nav> */}
      </div>
    </main>
  );
};

export default withRoleBased(UserDetails);
