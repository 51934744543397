import React, { useState, useEffect } from "react";
import PaginationComponent from "../../../components/PaginationComponent";
import useQuestionaireHistories from "../../../utils/CustomHooks/useQuestionaireHistories";
import { sortArray } from "../../../utils/Functions/filter";
import QuestionTableItem from "../components/QuestionTableItem";

export default function QuestionaireHistoryTable({ id = "" }) {
  const { screenerHistories, loading, getQuestionHistory } =
    useQuestionaireHistories("");
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const pageCount = Math.ceil(screenerHistories.length / pageSize);
  useEffect(() => {
    if (id) getQuestionHistory(id);
  }, [id]);
  const handleClick = (event, index) => {
    event.preventDefault();
    setCurrentPage(index);
  };

  return (
    <>
      <table className="table table-borderless ">
        <thead>
          <tr className=" ">
            <td className="">Action</td>
            <td className="">Time </td>
            <td className="">By</td>
          </tr>
        </thead>
        <tbody>
          {screenerHistories.length > 0 ? (
            sortArray(screenerHistories)
              .slice(currentPage * pageSize, (currentPage + 1) * pageSize)
              .map((x, i) => <QuestionTableItem data={x} key={i} />)
          ) : (
            <h5 className="text-center">
              {loading ? "loading..." : "No Hisory Found..."}
            </h5>
          )}
        </tbody>
      </table>
      {screenerHistories.length > 0 && (
        <PaginationComponent
          handleClick={handleClick}
          currentPage={currentPage}
          pageCount={pageCount}
        />
      )}
    </>
  );
}
