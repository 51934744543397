import { useFormik } from "formik";
import { useEffect } from "react";
import * as Yup from "yup";
import { IsNullOrEmpty } from "../../utils/Functions/filter";
import usePrevious from "../../utils/Functions/usePrevious";
import NewHistoryMultiSelect from "../MultiSelect/NewHistoryMultiSelect";

export default function NewReactSelectInputField({
  parentForm = {},
  className = "col-md-12",
  id = "",
  title = "",
  disabled = false,
  validation = {},
  inputProps = {},
  options = [],
  actualValue = [],
  placeholder = "",
  isCreatable = false,
  isMulti = false,
  defaultValue = [],
  displayName = "label",
  uniqueKey = "value",
  onChange = () => {},
  onCreate = () => {},
  style,
  isClearable = false,
  isPublished =false,
  autoClose,
  updateValue,
}) {
  const formik = useFormik({
    initialValues: {},
    validationSchema: Yup.object().shape(validation),
    onSubmit: () => {},
  });
  const previous = usePrevious(formik.errors[id]);
  useEffect(() => {
    const parentData = [
      ...(parentForm.values[id] ? parentForm.values[id] : []).map((x) => x),
    ];
    if (updateValue && updateValue.length > 0 && parentForm) {
      parentForm.setFieldValue(id, [...updateValue, ...parentData]);
    }
  }, [updateValue]);

  useEffect(() => {
    if (formik.errors[id]) {
      parentForm.setFieldError(id, formik.errors[id] ? formik.errors[id] : "");
    } else if (previous !== formik.errors[id]) {
      let errors = { ...parentForm.errors };
      delete errors[id];
      parentForm.setErrors(errors);
    }
  }, [formik.errors]);

  useEffect(() => {
    formik.setFieldValue(id, defaultValue);
  }, [defaultValue]);

  const onChangeValue = (e) => {
    formik.setFieldValue(id, e);
    actualValue.parentForm && parentForm.setFieldValue(id, e);
    onChange(e);
  };

  const onAction = (e) => {
    let parentData = [
      ...(parentForm.values[id] ? parentForm.values[id] : []).map((x) => x),
    ];
    let { action, option, removedValue } = e;
    if (
      action === "remove-value" &&
      removedValue &&
      !IsNullOrEmpty(removedValue.index)
    ) {
      parentData.push({
        update_type: "Remove",
        index: removedValue?.index,
        update_data: removedValue.value,
      });
    } else if (action === "select-option" && option.value) {
      parentData.push({
        update_type: "Add",
        update_data: option.value,
      });
    }
    else if (action === "remove-value" && removedValue &&removedValue.value) {
      // console.log("filter",parentData)
      let data = parentData.filter((x)=>x.update_data !== removedValue.value);
      parentData = data
    }
    // console.log("parentData",parentData)

    parentForm.setFieldValue(id, parentData);
  };
  return (
    <div className={className}>
      <div className="row">
        <div className="col-md-12 form-group">
          <label htmlFor={id} className="col-form-label w-100 mb-2">
            {title}
            {/* {defaultValue.length > 0 && (
              <span className="badge badge-error float-right">Updated</span>
            )} */}
          </label>
          <NewHistoryMultiSelect
            id={id}
            option={options}
            onChange={onChangeValue}
            placeholder="Select"
            onBlur={formik.handleBlur}
            // defaultValue={[id]}
            {...{
              onAction,
              placeholder,
              isCreatable,
              isClearable,
              isMulti,
              defaultValue,
              displayName,
              uniqueKey,
              onCreate,
              style,
              autoClose,
              disabled,
              isPublished,
              ...inputProps,
            }}
          />
          <small className="text-danger">
            {formik.errors[id] ? formik.errors[id] : ""}
          </small>
        </div>
      </div>
    </div>
  );
}
