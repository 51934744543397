import { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import MultiSelect from "../../../components/MultiSelect/MultiSelect";
import graphHandler from "../../../Apollo/graphHandler";
import {
  CREATE_CONFIG_GQL,
  UPDATE_CONFIG_GQL,
} from "../../../Apollo/AdminServices/MutationRequest";
import { toast } from "react-toastify";
import CustomButton from "../../../components/Button/CustomButton";
import { gameNameKey } from "../../../constants";

export default function ConfigSection({
  refresh = () => {},
  values = {},
  isEditable = false,
  onDone = () => {},
  onClose = () => {},
  setConfig = () => {},
  setLoading = () => {},
  screenerList = [],
  interventionList = [],
}) {
  const [type, setType] = useState(false);
  const [canEdit, setEdit] = useState(isEditable);
  const [screeners, setScreeners] = useState([]);
  const [interventions, setInterVentions] = useState([]);
  const formik = useFormik({
    initialValues: {},
    validationSchema: type ? sValidation : iValidation,
    onSubmit: (values) => {
      onSubmit(values);
    },
  });

  useEffect(() => {
    setInterVentions(
      interventionList.map((x) => ({
        label: x.intervention_name,
        value: x.intervention_id,
      }))
    );
  }, [interventionList]);

  useEffect(() => {
    setScreeners(
      screenerList.map((x) => ({
        label: x.screener_name,
        value: x.screener_id,
      }))
    );
  }, [screenerList]);

  useEffect(() => {
    if (values.game_config_id) {
      onSetDefault(values);
    } else {
      setEdit(true);
    }
  }, [values, screeners, interventions]);

  const onSetDefault = (data) => {
    const {
      game_config_id,
      game_name_key,
      game_view_id,
      game_type,
      screener_id,
      intervention_id,
      game_config,
    } = data;
    let model = {
      game_config_id,
      game_name_key,
      game_view_id,
      game_type,
      //   screener_id,
      //   intervention_id,
    };
    if (
      game_type === "Screener" &&
      screeners.find((x) => x.value === screener_id)
    ) {
      model.screener_id = screeners.find((x) => x.value === screener_id);
    }
    if (
      game_type === "Intervention" &&
      interventions.find((x) => x.value === intervention_id)
    ) {
      model.intervention_id = interventions.find(
        (x) => x.value === intervention_id
      );
    }
    if (game_config) {
      model.game_config = JSON.stringify(game_config, undefined, 4);
    }
    // if (game_config) {
    //   model.game_config =
    //     typeof game_config === "object" ? game_config : JSON.parse(game_config);
    //   if (document.getElementById("game_config")) {
    //     document.getElementById("game_config").value = JSON.stringify(
    //       typeof game_config === "object"
    //         ? game_config
    //         : JSON.parse(game_config),
    //       undefined,
    //       4
    //     );
    //   }
    // }
    formik.setValues(model);
    setEdit(false);
  };
  useEffect(() => {
    formik.values.game_type && formik.validateForm();
  }, [type]);

  useEffect(() => {
    setType(formik.values.game_type === "Screener");
  }, [formik.values.game_type]);

  const handleError = () => {
    if (Object.keys(formik.errors).length > 0) {
      toast.error(formik.errors[Object.keys(formik.errors)[0]]);
    }
  };
  const onSubmit = (formikValues) => {
    formik.setSubmitting(true);
    setLoading(true);
    const {
      game_config_id,
      game_name_key,
      game_view_id,
      game_type,
      screener_id,
      intervention_id,
      game_config,
    } = formikValues;
    let request = {
      game_name_key,
      game_view_id: Number(game_view_id),
      game_type,
      // game_config,
        game_config: JSON.parse(game_config),
    };
    if (formik.values.game_type === "Screener") {
      request.screener_id = screener_id.value;
    } else {
      request.intervention_id = intervention_id.value;
    }
    if (game_config_id) {
      delete request.game_type;
      delete request.screener_id;
      delete request.intervention_id;
      update({ ...request, game_config_id });
    } else {
      create(request);
    }
  };

  const create = async (create_request) => {
    formik.setSubmitting(true);
    try {
      const { data } = await graphHandler().invoke(
        CREATE_CONFIG_GQL,
        { create_request },
        1,
        false,
        true
      );
      if (data && data.createGameConfig && data.createGameConfig.game_config) {
        // console.log("createGameConfig Success", data.createGameConfig.game_config);
        if (create_request.game_type === "Screener") {
          onClose();
        } else {
          setConfig(data.createGameConfig.game_config);
          onDone();
          refresh();
        }
        setLoading(false);
        formik.setSubmitting(false);
      }
    } catch (error) {
      console.error("createGameConfig", error);
      setLoading(false);
      formik.setSubmitting(false);
    }
  };

  const update = async (update_request) => {
    formik.setSubmitting(true);
    try {
      const { data } = await graphHandler().invoke(
        UPDATE_CONFIG_GQL,
        { update_request },
        1,
        false,
        true
      );
      if (data && data.updateGameConfig && data.updateGameConfig.game_config) {
        // console.log("updateGameConfig Success", data.updateGameConfig.game_config);
        setConfig(data.updateGameConfig.game_config);
        onDone();
        refresh();
        formik.setSubmitting(false);
      }
      setLoading(false);
    } catch (error) {
      console.error("updateGameConfig", error);
      setLoading(false);
      formik.setSubmitting(false);
    }
  };

  const handleNumber = (e) => {
    const { name, value } = e.target;
    const re = /^[0-9\b]+$/;
    let temp = !value
      ? value
      : re.test(value)
      ? value
      : formik.values[name]
      ? formik.values[name]
      : "";
    formik.setFieldValue(name, temp ? temp.substring(0, 10) : temp);
  };
  return (
    <div className="p-5 position-relative">
      {isEditable && values.game_config_id && (
        <button
          type="button"
          className="btn btn-link position-absolute"
          style={{ top: "0px", right: "60px" }}
          onClick={() => setEdit((x) => !x)}
        >
          {canEdit ? (
            <>
              <i className="fas fa-times"></i> Cancel
            </>
          ) : (
            <>
              <i className="fas fa-pen"></i> Edit
            </>
          )}
        </button>
      )}
      <div className="row justify-content-center ">
        <div className="col-md-6">
          <div className="row">
            <div className="col-md-12 form-group">
              <label htmlFor="game_type" className="col-form-label w-100 mb-2">
                Game Type
              </label>
              <select
                name="game_type"
                className="custom-select"
                id="game_type"
                onChange={formik.handleChange}
                value={
                  formik.values && formik.values.game_type
                    ? formik.values.game_type
                    : ""
                }
                onBlur={formik.handleBlur}
                disabled={!canEdit || values.game_config_id}
              >
                <option value="" hidden>
                  Select Type
                </option>
                <option value="Intervention">Intervention</option>
                <option value="Screener">Screener</option>
              </select>

              <small className="text-danger">
                {formik.touched.game_type &&
                formik.errors &&
                formik.errors.game_type
                  ? formik.errors.game_type
                  : ""}
              </small>
            </div>
            {formik.values.game_type ? (
              formik.values.game_type === "Intervention" ? (
                <div className="col-md-12 form-group">
                  <label
                    htmlFor="intervention_id"
                    className="col-form-label w-100 mb-2"
                  >
                    Intervention
                  </label>
                  <MultiSelect
                    option={interventions}
                    onChange={(e) => formik.setFieldValue("intervention_id", e)}
                    placeholder="Select a intervention"
                    isCreatable={false}
                    isMulti={false}
                    type="Intervention"
                    name="intervention_id"
                    id="intervention_id"
                    onBlur={formik.handleBlur}
                    defaultValue={formik.values.intervention_id}
                    disabled={!canEdit || values.game_config_id}
                  />
                  <small className="text-danger align-self-end">
                    {formik.touched.intervention_id &&
                    formik.errors &&
                    formik.errors.intervention_id
                      ? formik.errors.intervention_id
                      : ""}
                  </small>
                </div>
              ) : (
                <div className="col-md-12 form-group">
                  <label
                    htmlFor="category_id"
                    className="col-form-label w-100 mb-2"
                  >
                    Screener
                  </label>
                  <MultiSelect
                    option={screeners}
                    onChange={(e) => formik.setFieldValue("screener_id", e)}
                    placeholder="Select a screener"
                    isCreatable={false}
                    isMulti={false}
                    type="Screener"
                    name="screener_id"
                    id="screener_id"
                    onBlur={formik.handleBlur}
                    defaultValue={formik.values.screener_id}
                    disabled={!canEdit || values.game_config_id}
                  />
                  <small className="text-danger align-self-end">
                    {formik.touched.screener_id &&
                    formik.errors &&
                    formik.errors.screener_id
                      ? formik.errors.screener_id
                      : ""}
                  </small>
                </div>
              )
            ) : null}
            <div className="col-md-12 form-group">
              <label
                htmlFor="game_name_key"
                className="col-form-label w-100 mb-2"
              >
                Key Name
              </label>
              <select
                name="game_name_key"
                className="custom-select"
                id="game_name_key"
                onChange={formik.handleChange}
                value={
                  formik.values && formik.values.game_name_key
                    ? formik.values.game_name_key
                    : ""
                }
                onBlur={formik.handleBlur}
                disabled={!canEdit}
              >
                <option value="" hidden>
                  Select Key
                </option>
                {gameNameKey.map((x, i) => (
                  <option value={x} key={i}>
                    {x}
                  </option>
                ))}
              </select>
              <small className="text-danger">
                {formik.touched.game_name_key &&
                formik.errors &&
                formik.errors.game_name_key
                  ? formik.errors.game_name_key
                  : ""}
              </small>
            </div>
            <div className="col-md-12 form-group">
              <label
                htmlFor="game_view_id"
                className="col-form-label w-100 mb-2"
              >
                View Id
              </label>
              <input
                type="text"
                className="form-control"
                id="game_view_id"
                name="game_view_id"
                maxLength={4}
                onChange={handleNumber}
                value={
                  formik.values.game_view_id ? formik.values.game_view_id : ""
                }
                onBlur={formik.handleBlur}
                disabled={!canEdit}
              />
              <small className="text-danger align-self-end">
                {formik.touched.game_view_id &&
                formik.errors &&
                formik.errors.game_view_id
                  ? formik.errors.game_view_id
                  : ""}
              </small>
            </div>
          </div>
        </div>
        <div className="col-md-6 form-group">
          <label htmlFor="game_config" className="col-form-label w-100">
            Game Config (JSON)
            <button
              className="float-right btn btn-dark btn-sm mb-2"
              disabled={formik.errors.game_config}
              onClick={() => {
                if (
                  formik.touched.game_config &&
                  document.getElementById("game_config")
                ) {
                  const ugly = document.getElementById("game_config").value;
                  document.getElementById("game_config").value = JSON.stringify(
                    JSON.parse(ugly),
                    undefined,
                    4
                  );
                }
              }}
            >
              Pretty
            </button>
          </label>
          <textarea
            className="form-control"
            id="game_config"
            name="game_config"
            cols="50"
            rows="10"
            style={{ maxHeight: "100%", minHeight: "50vh" }}
            value={
              formik.values && formik.values.game_config
                ? formik.values.game_config
                : ""
            }
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            disabled={!canEdit}
          />
          <small className="text-danger">
            {formik.touched.game_config &&
            formik.errors &&
            formik.errors.game_config
              ? formik.errors.game_config
              : ""}
          </small>
        </div>

        <div className={"d-flex align-items-end justify-content-end col-md-12"}>
          <button
            onClick={onClose}
            type="button"
            className="btn btn-danger btn-sm float-right btn-add-domain mr-2"
          >
            Close
          </button>
          {canEdit ? (
            <CustomButton
              type="button"
              className="btn btn-primary btn-outline-success btn-sm float-right btn-add-domain"
              disabled={
                Object.keys(formik.errors).length !== 0 ||
                !formik.isValid ||
                formik.isSubmitting
              }
              onClick={
                formik.isValid && Object.keys(formik.values).length !== 0
                  ? formik.handleSubmit
                  : handleError
              }
            >
              {formik.values.game_config_id ? "Update" : "Create"}
            </CustomButton>
          ) : (
            <button
              onClick={onDone}
              type="button"
              className="btn btn-secondary btn-sm float-right btn-add-domain mr-2"
            >
              Next
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

const sValidation = Yup.object().shape({
  game_type: Yup.string().required("Screener type is required"),
  game_name_key: Yup.string().required("Enter a screener name"),
  screener_id: Yup.object()
    .required("Select a screener")
    .typeError("Select a screener"),
  game_view_id: Yup.string().required("Enter a view id"),
  game_config: Yup.object()
    .typeError("Invalid JSON")
    .required("Enter a config JSON"),
});

const iValidation = Yup.object().shape({
  game_type: Yup.string().required("Screener type is required"),
  game_name_key: Yup.string().required("Enter a screener name"),
  intervention_id: Yup.object()
    .required("Select a intervention")
    .typeError("Select a intervention"),
  game_view_id: Yup.string().required("Enter a view id"),
  game_config: Yup.object()
    .typeError("Invalid JSON")
    .required("Enter a config JSON"),
});
