import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { useFormik } from "formik";
import graphHandler from "../../../Apollo/graphHandler";
import { UPDATE_SCREENER_RESULT_RANGE_GQL } from "../../../Apollo/AdminServices/MutationRequest";
import MetaHistoryInput from "../../../components/HistoryEditInput/MetaHistoryInput";
import DescriptionInputField from "../../../components/HistoryEditInput/DescriptionInputField";
import SelectInputField from "../../../components/HistoryEditInput/SelectInputField";
import TextInputField from "../../../components/HistoryEditInput/TextInputField";
import ColorHistoryField from "../../../components/HistoryEditInput/ColorHistoryField";
import CustomButton from "../../../components/Button/CustomButton";
import { objectHasKey } from "../../../utils/Functions/filter";
import useDelay from "../../../utils/CustomHooks/useDelay";
import { useMemo } from "react";
import TextAreaField from "../../../components/HistoryEditInput/TextAreaField";
import {
  resultRangeGameNameKey,
  screenerResultRangeEntityType,
} from "../../../constants";

export default function UpdateResultSection({
  screener = {},
  disabled,
  subDomains = [],
  topicList = [],
  domainList = [],
  refresh = () => {},
  reset = () => {},
  setLoading = () => {},
  values = {},
}) {
  const { screener_id, screener_format } = screener;
  const { screener_result_range_id, pending_history } = values;
  const hasUpdated = Boolean(pending_history && pending_history.updated_data);
  const updated_data = hasUpdated ? pending_history.updated_data : {};
  const referenceRef = useRef();
  const [loading, setLocalLoading] = useState(false);
  const { done } = useDelay();
  const formik = useFormik({
    initialValues: {},
    validationSchema: validation,
    onSubmit: (values) => {
      onSubmit(values);
    },
  });
  useMemo(() => {
    setLocalLoading(true);
    formik.resetForm();
    formik.setValues({});
    formik.setSubmitting(false);
    setTimeout(() => setLocalLoading(false), [10]);
  }, [screener_result_range_id]);

  useEffect(() => {
    if (done && formik.values.screener_result_range_entity_type) {
      let cond = formik.values.screener_result_range_entity_type === "Screener";
      formik.setFieldValue("entity_ref_id", cond ? screener_id : "");
      referenceRef.current &&
        referenceRef.current.setFieldValue(
          "entity_ref_id",
          cond ? screener_id : ""
        );
    }
  }, [formik.values.screener_result_range_entity_type]);

  const handleError = () => {
    if (Object.keys(formik.errors).length > 0) {
      toast.error(formik.errors[Object.keys(formik.errors)[0]]);
    }
  };
  const onSubmit = async (modal) => {
    formik.setSubmitting(true);
    setLoading(true);
    let {
      screener_result_range_color,
      screener_result_range_entity_type,
      entity_ref_id,
      game_name_key,
      screener_result_range_meta,
      screener_result_range_name,
      screener_result_range_min,
      screener_result_range_max,
      screener_result_range_descriptions,
      screener_result_range_report_description,
      screener_result_range_type_name,
    } = modal;
    let update_request = { screener_result_range_id };

    if (objectHasKey(modal, "screener_result_range_entity_type")) {
      update_request.screener_result_range_entity_type =
        screener_result_range_entity_type;
    }
    if (objectHasKey(modal, "screener_result_range_type_name")) {
      update_request.screener_result_range_type_name =
        screener_result_range_type_name;
    }
    if (objectHasKey(modal, "screener_result_range_color")) {
      update_request.screener_result_range_color = screener_result_range_color;
    }
    if (objectHasKey(modal, "entity_ref_id")) {
      update_request.entity_ref_id = entity_ref_id;
    }
    if (objectHasKey(modal, "game_name_key")) {
      update_request.game_name_key = game_name_key;
    }
    if (objectHasKey(modal, "screener_result_range_name")) {
      update_request.screener_result_range_name = screener_result_range_name;
    }
    if (objectHasKey(modal, "screener_result_range_min")) {
      update_request.screener_result_range_min = Number(
        screener_result_range_min
      );
    }
    if (objectHasKey(modal, "screener_result_range_max")) {
      update_request.screener_result_range_max = Number(
        screener_result_range_max
      );
    }
    if (
      objectHasKey(modal, "screener_result_range_descriptions") &&
      screener_result_range_descriptions.length > 0
    ) {
      update_request.screener_result_range_descriptions = {
        update_items: screener_result_range_descriptions,
      };
    }
    // if (
    //   objectHasKey(modal, "screener_result_range_report_descriptions") &&
    //   screener_result_range_report_descriptions.length > 0
    // ) {
    //   update_request.screener_result_range_report_descriptions = {
    //     update_items: screener_result_range_report_descriptions,
    //   };
    // }
    if (objectHasKey(modal, "screener_result_range_report_description")) {
      update_request.screener_result_range_report_description =
        screener_result_range_report_description;
    }
    if (
      objectHasKey(modal, "screener_result_range_meta") &&
      screener_result_range_meta.length > 0
    ) {
      update_request.screener_result_range_meta = {
        update_items: screener_result_range_meta,
      };
    }
    try {
      const { data } = await graphHandler().invoke(
        UPDATE_SCREENER_RESULT_RANGE_GQL,
        { update_request },
        1,
        false,
        true
      );
      if (
        data &&
        data.updateScreenerResultRange &&
        data.updateScreenerResultRange.screener_result_range
      ) {
        // console.log(
        //   "createScreenerTopic Success",
        //   data.updateScreenerResultRange.screener_result_range
        // );
        refresh();
        setLoading(false);
        toast.success("Updated Successfully");
        formik.setSubmitting(false);
        resetModal();
      }
    } catch (error) {
      console.error("updateScreenerResultRange Error", error);
      setLoading(false);
      formik.setSubmitting(false);
    }
  };
  const resetModal = () => {
    formik.resetForm();
    formik.setValues({});
    formik.setSubmitting(false);
    reset();
  };
  const entityType = formik.values.screener_result_range_entity_type
    ? formik.values.screener_result_range_entity_type
    : values.screener_result_range_entity_type;
  return (
    <>
      <div className="col-md-12 modal-header text-center align-items-center">
        <h5 className="modal-title text-center">Update Interpretation</h5>
      </div>
      <div className="col-md-12">
        <div className="border">
          <div className="row p-3">
            <SelectInputField
              parentForm={formik}
              className="col-md-4"
              updateValue={updated_data.screener_result_range_entity_type}
              actualValue={values.screener_result_range_entity_type}
              id="screener_result_range_entity_type"
              title="Format"
              disabled={disabled}
              validation={{
                screener_result_range_entity_type:
                  Yup.string().required("Select a type"),
              }}
              options={[
                ...(screener_format === "Game"
                  ? [
                      ...screenerResultRangeEntityType,
                      {
                        label: "Game Name Key",
                        value: "GameNameKey",
                      },
                    ]
                  : [...screenerResultRangeEntityType]),
              ]}
            />
            {entityType === "GameNameKey" && (
              <SelectInputField
                parentForm={formik}
                className="col-md-4"
                updateValue={updated_data.game_name_key}
                actualValue={values.game_name_key}
                id="game_name_key"
                title="Game Name Key"
                disabled={disabled}
                validation={{
                  game_name_key: Yup.string().required(
                    `Select a game name key`
                  ),
                }}
                oldOptions={[
                  { label: "Select", value: "", props: { hidden: true } },
                  ...resultRangeGameNameKey.map((item) => ({
                    label: item.label,
                    value: item.value,
                  })),
                ]}
                options={[
                  { label: "Select", value: "", props: { hidden: true } },
                  ...resultRangeGameNameKey.map((item) => ({
                    label: item.label,
                    value: item.value,
                  })),
                ]}
                // setChildRef={(e) => (referenceRef.current = e)}
              />
            )}
            {entityType !== "GameNameKey" && entityType !== "Screener" && (
              <SelectInputField
                parentForm={formik}
                className="col-md-4"
                updateValue={updated_data.entity_ref_id}
                actualValue={values.entity_ref_id}
                id="entity_ref_id"
                title={
                  entityType === "SubDomain"
                    ? "Sub Domain"
                    : entityType || "Reference"
                }
                disabled={disabled}
                validation={{
                  entity_ref_id: Yup.string().required(
                    `Select a ${entityType}`
                  ),
                }}
                oldOptions={[
                  { label: "Select", value: "", props: { hidden: true } },
                  ...(values.screener_result_range_entity_type === "SubDomain"
                    ? subDomains.map((item) => ({
                        label: item.sub_domain_name,
                        value: item.sub_domain_id,
                      }))
                    : values.screener_result_range_entity_type === "Domain"
                    ? domainList.map((item) => ({
                        label: item.domain_name,
                        value: item.domain_id,
                      }))
                    : topicList.map((item) => ({
                        label: item.screener_topic_title,
                        value: item.screener_topic_id,
                      }))),
                ]}
                options={[
                  { label: "Select", value: "", props: { hidden: true } },
                  ...(entityType === "SubDomain"
                    ? subDomains.map((item) => ({
                        label: item.sub_domain_name,
                        value: item.sub_domain_id,
                      }))
                    : entityType === "Domain"
                    ? domainList.map((item) => ({
                        label: item.domain_name,
                        value: item.domain_id,
                      }))
                    : topicList.map((item) => ({
                        label: item.screener_topic_title,
                        value: item.screener_topic_id,
                      }))),
                ]}
                setChildRef={(e) => (referenceRef.current = e)}
              />
            )}
            <ColorHistoryField
              parentForm={formik}
              className="col-md-4"
              updateValue={updated_data.screener_result_range_color}
              actualValue={values.screener_result_range_color}
              id="screener_result_range_color"
              title="Color"
              disabled={disabled}
              validation={{
                screener_result_range_color:
                  Yup.string().required("Select a color"),
              }}
            />
            <TextInputField
              parentForm={formik}
              className="col-md-4"
              updateValue={updated_data.screener_result_range_name}
              actualValue={values.screener_result_range_name}
              id="screener_result_range_name"
              title="Name"
              disabled={disabled}
              validation={{
                screener_result_range_name: Yup.string()
                  .trim()
                  .required("Enter name"),
              }}
            />
            <TextInputField
              parentForm={formik}
              className="col-md-4"
              updateValue={updated_data.screener_result_range_min}
              actualValue={values.screener_result_range_min}
              id="screener_result_range_min"
              title="Min"
              disabled={disabled}
              validation={{
                screener_result_range_min: Yup.string()
                  .trim()
                  .required("Enter min"),
              }}
              isNumber={true}
              // inputProps={{
              //   maxLength: 10,
              // }}
            />
            <TextInputField
              parentForm={formik}
              className="col-md-4"
              updateValue={updated_data.screener_result_range_max}
              actualValue={values.screener_result_range_max}
              id="screener_result_range_max"
              title="Max"
              disabled={disabled}
              validation={{
                screener_result_range_max: Yup.string()
                  .trim()
                  .required("Enter max"),
              }}
              isNumber={true}
              // inputProps={{
              //   maxLength: 10,
              // }}
            />
            <DescriptionInputField
              parentForm={formik}
              parentKey="screener_result_range_descriptions"
              col="col-md-6"
              className="col-md-12"
              updateValue={
                updated_data.screener_result_range_descriptions &&
                updated_data.screener_result_range_descriptions.update_items
                  ? updated_data.screener_result_range_descriptions.update_items
                  : false
              }
              actualValue={values.screener_result_range_descriptions}
              disabled={disabled}
              validation={{
                description: Yup.string(),
                mobile_description: Yup.string(),
              }}
              showEditor={true}
              refreshId={screener_result_range_id}
            />
            {/* <DescriptionInputField
              parentForm={formik}
              parentKey="screener_result_range_report_descriptions"
              col="col-md-6"
              className="col-md-12"
              updateValue={
                updated_data.screener_result_range_report_descriptions &&
                updated_data.screener_result_range_report_descriptions
                  .update_items
                  ? updated_data.screener_result_range_report_descriptions
                      .update_items
                  : false
              }
              actualValue={values.screener_result_range_report_descriptions}
              disabled={disabled}
              validation={{
                description: Yup.string(),
                mobile_description: Yup.string(),
              }}
              showEditor={true}
              webTitle="Report Description"
              mobileTitle="Mobile Report Description"
              refreshId={screener_result_range_id}
            /> */}
            <TextAreaField
              parentForm={formik}
              className="col-md-6"
              updateValue={
                updated_data.screener_result_range_report_description
              }
              actualValue={
                values.screener_result_range_report_description || ""
              }
              id="screener_result_range_report_description"
              title="Report Description"
              disabled={disabled}
              validation={{
                screener_result_range_report_description: Yup.string().trim(),
              }}
            />
            <TextInputField
              parentForm={formik}
              className="col-md-4"
              updateValue={updated_data.screener_result_range_type_name}
              actualValue={values.screener_result_range_type_name || ""}
              id="screener_result_range_type_name"
              title="Type Name"
              disabled={disabled}
              validation={{
                screener_result_range_type_name: Yup.string().trim(),
              }}
            />
            {!loading && (
              <>
                <div className="col-md-12">
                  <MetaHistoryInput
                    parentForm={formik}
                    parentArray="screener_result_range_meta"
                    typeKey="type"
                    titleKey="key"
                    valueKey="value"
                    updateValue={
                      updated_data.screener_result_range_meta &&
                      updated_data.screener_result_range_meta.update_items
                        ? updated_data.screener_result_range_meta.update_items
                        : false
                    }
                    isPublished={values.published}
                    validation={{
                      screener_result_range_meta: Yup.array().of(
                        Yup.object().shape({
                          type: Yup.string().required("Select a data type"),
                          key: Yup.string().trim().required("Enter a title"),
                          value: Yup.string().trim().required("Enter a value"),
                        })
                      ),
                    }}
                    actualValue={values.screener_result_range_meta}
                    disabled={disabled}
                  />
                </div>
              </>
            )}

            <div
              className={"d-flex align-items-end justify-content-end col-md-12"}
            >
              {values.screener_result_range_id && (
                <button
                  onClick={resetModal}
                  type="button"
                  className="btn btn-light btn-sm float-right btn-add-domain mr-2"
                >
                  Reset
                </button>
              )}
              <CustomButton
                type="button"
                className="btn btn-primary btn-outline-success btn-sm float-right btn-add-domain"
                disabled={!formik.isValid || formik.isSubmitting}
                onClick={
                  formik.isValid && Object.keys(formik.values).length !== 0
                    ? formik.handleSubmit
                    : handleError
                }
              >
                {values.screener_result_range_id ? "Update" : "Create"}
              </CustomButton>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const validation = Yup.object().shape({});
