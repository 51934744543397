export default function TextAreaFormik({
  formik,
  id = "id",
  label = "label",
  props = {},
  className = "col-md-6",
}) {
  return (
    <div className={`form-group ${className}`}>
      <label htmlFor={id} className="col-form-label w-100 mb-2">
        {label}
      </label>
      <textarea
        className="form-control"
        placeholder=""
        id={id}
        name={id}
        value={formik.values && formik.values[id] ? formik.values[id] : ""}
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        {...props}
      />
      <small className="text-danger">
        {formik.touched[id] && formik.errors && formik.errors[id]
          ? formik.errors[id]
          : ""}
      </small>
    </div>
  );
}
