import { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import graphHandler from "../../../Apollo/graphHandler";
import { UPDATE_SCREENER } from "../../../Apollo/AdminServices/MutationRequest";
import { toast } from "react-toastify";
import CustomButton from "../../../components/Button/CustomButton";
import {
  IsNullOrEmpty,
  mergeMultiSelectArray,
  objectHasKey,
} from "../../../utils/Functions/filter";
import TextInputField from "../../../components/HistoryEditInput/TextInputField";
import SelectInputField from "../../../components/HistoryEditInput/SelectInputField";
import DescriptionInputField from "../../../components/HistoryEditInput/DescriptionInputField";
import NewReactSelectInputField from "../../../components/HistoryEditInput/NewReactSelectInputField";
import MetaHistoryInput from "../../../components/HistoryEditInput/MetaHistoryInput";
import { Popconfirm } from "antd";

export default function UpdateScreenerSection({
  refresh = () => {},
  values = {},
  isEditable = false,
  onDone = () => {},
  onClose = () => {},
  setScreener = () => {},
  setLoading = () => {},
  handleDiscord = () => {},
  templateOption = [],
}) {
  const subDomains = useSelector(({ DataSet }) => DataSet.subDomains);
  const [type, setType] = useState(false);
  const [canEdit, setEdit] = useState(isEditable);
  const [subDomainList, setSubDomainList] = useState([]);
  const [selectedList, setSelectedList] = useState([]);
  const screener_history = values.pending_screener_history
    ? values.pending_screener_history
    : {};
  const hasUpdated = Boolean(screener_history && screener_history.updated_data);
  // console.log("pending_screener_history",values)
  const { screener_type, screener_id } = values;
  const updated_data = hasUpdated ? screener_history.updated_data : {};
  const formik = useFormik({
    initialValues: {},
    // validateOnBlur:false,
    // validationSchema: type ? validation : otherValidation,
    onSubmit: (values) => {
      onSubmit(values);
    },
  });
  const screenerType = updated_data.screener_type
    ? updated_data.screener_type
    : values.screener_type;
  useEffect(() => {
    if (screener_id) {
      onSetDefault(values, hasUpdated ? updated_data : {});
    } else {
      setEdit(true);
    }
  }, [values, subDomainList]);

  const onSetDefault = (data = {}, publish = {}) => {
    // console.log("Update Callingggg", values);
    const { sub_domain_ids } = data;
    if (subDomainList.length > 0) {
      let subArray = [],
        sub_domains =
          sub_domain_ids && sub_domain_ids.length > 0 ? sub_domain_ids : [],
        publishedSub =
          publish.sub_domain_ids &&
          publish.sub_domain_ids.update_items &&
          publish.sub_domain_ids.update_items.length > 0
            ? publish.sub_domain_ids.update_items
            : [];
      let value = mergeMultiSelectArray(sub_domains, publishedSub);
      let i = 0;
      subArray = value.map((x) => {
        let ret = {
          ...subDomainList.find((y) => y.value === x.data),
          ...x,
          index: i,
        };
        if (x.isRemoved) {
          ret.value = subDomainList.find((y) => y.value === x.data).label || "";
        } else {
          i++;
        }
        return ret;
      });
      setSelectedList(subArray);
    }
    setEdit(false);
  };

  useEffect(() => {
    formik.values.screener_type && formik.validateForm();
  }, [type]);

  useEffect(() => {
    setType(formik.values.screener_type === "MultiDimensionalScreener");
  }, [formik.values.screener_type]);

  useEffect(() => {
    setSubDomainList(
      subDomains.map((x) => ({
        label: x.sub_domain_name,
        value: x.sub_domain_id,
      }))
    );
  }, [subDomains, values]);

  const handleError = () => {
    if (Object.keys(formik.errors).length > 0) {
      toast.error(formik.errors[Object.keys(formik.errors)[0]]);
    }
  };

  const onSubmit = async (formikValues) => {
    formik.setSubmitting(true);
    setLoading(true);
    let {
      screener_name,
      screener_description,
      screener_time,
      screener_code,
      screener_format,
      template_id,
      sort_type,
      screener_meta,
      sub_domain_ids,
      alias_name,
    } = formikValues;
    let update_request = {
      screener_id,
    };
    if (objectHasKey(formikValues, "screener_name")) {
      update_request.screener_name = screener_name;
    }
    if (objectHasKey(formikValues, "alias_name")) {
      update_request.alias_name = alias_name;
    }
    if (objectHasKey(formikValues, "screener_code")) {
      update_request.screener_code = screener_code;
    }
    if (objectHasKey(formikValues, "screener_time")) {
      update_request.screener_time = (Number(screener_time) * 60).toString();
    }
    if (objectHasKey(formikValues, "screener_format")) {
      update_request.screener_format = screener_format;
    }
    if (objectHasKey(formikValues, "template_id")) {
      update_request.template_id = template_id;
    }
    if (objectHasKey(formikValues, "screener_type")) {
      update_request.screener_type = screener_type;
    }
    if (objectHasKey(formikValues, "sort_type")) {
      update_request.sort_type = sort_type;
    }
    if (
      screener_type !== "MultiDimensionalScreener" &&
      objectHasKey(formikValues, "sub_domain_ids")
    ) {
      update_request.sub_domain_ids = { update_items: sub_domain_ids };
    }
    if (objectHasKey(formikValues, "screener_description")) {
      update_request.screener_description = {
        update_items: screener_description,
      };
    }
    if (objectHasKey(formikValues, "screener_meta")) {
      update_request.screener_meta = { update_items: screener_meta };
    }

    // formik.setSubmitting(false);
    // setLoading(false);
    // return console.log(update_request, formik.values);
    // console.log("update_request",update_request)
    try {
      const { data } = await graphHandler().invoke(
        UPDATE_SCREENER,
        { update_request },
        1,
        true,
        true
      );
      if (data && data.updateScreener && data.updateScreener.screener) {
        // console.log("updateScreener Success", data.updateScreener.screener);
        setScreener({
          ...data.updateScreener.screener,
          pending_screener_history: data.updateScreener.screener_history,
        });
        onDone();
        refresh();
        setLoading(false);
        formik.setSubmitting(false);
      }
    } catch (error) {
      console.error("updateScreener Error", error);
      setLoading(false);
      formik.setSubmitting(false);
    }
  };
  // console.log(updated_data.screener_meta);
  return (
    <div className="p-5 position-relative">
      {isEditable && (
        <button
          type="button"
          className="btn btn-link position-absolute"
          style={{ top: "0px", right: "60px" }}
          onClick={() => setEdit((x) => !x)}
        >
          {canEdit ? (
            <>
              <i className="fas fa-times"></i> Cancel
            </>
          ) : (
            <>
              <i className="fas fa-pen"></i> Edit
            </>
          )}
        </button>
      )}
      <div className="row justify-content-center ">
        <div className="col-md-6">
          <div className="row">
            <SelectInputField
              parentForm={formik}
              className="col-md-12"
              updateValue={updated_data.screener_type}
              actualValue={values.screener_type}
              id="screener_type"
              title="Screener Type"
              disabled={!canEdit}
              validation={{
                screener_type: Yup.string().required(
                  "Screener type is required"
                ),
              }}
              options={[
                { label: "Select Type", value: "", props: { hidden: true } },
                {
                  label: "Multidimensional",
                  value: "MultiDimensionalScreener",
                },
                {
                  label: "Other",
                  value: "DomainScreener",
                },
              ]}
            />
            {screenerType && screenerType !== "MultiDimensionalScreener" ? (
              <NewReactSelectInputField
                parentForm={formik}
                id="sub_domain_ids"
                title="Sub Domain"
                placeholder="Select Sub Domain"
                options={subDomainList}
                isPublished={values.published}
                isCreatable={false}
                isMulti={true}
                defaultValue={selectedList}
                updateValue={
                  updated_data.sub_domain_ids &&
                  updated_data.sub_domain_ids.update_items &&
                  updated_data.sub_domain_ids.update_items.length > 0
                    ? updated_data.sub_domain_ids.update_items
                    : false
                }
                disabled={!canEdit}
                validation={{
                  sub_domain_ids: Yup.array()
                    .of(
                      Yup.object().shape({
                        label: Yup.string(),
                        value: Yup.string(),
                      })
                    )
                    .test({
                      message: "Select a sub domain",
                      test: (arr) => {
                        return arr && arr.length > 0;
                      },
                    }),
                }}
              />
            ) : null}
            <TextInputField
              parentForm={formik}
              className="col-md-12"
              updateValue={updated_data.screener_code}
              actualValue={values.screener_code}
              id="screener_code"
              title="Code"
              disabled={!canEdit || values.screener_id}
              validation={{
                screener_code: Yup.string()
                  .trim()
                  .required("Enter a screener code"),
              }}
            />
            <SelectInputField
              parentForm={formik}
              className="col-md-12"
              updateValue={updated_data.template_id}
              actualValue={values.template_id || ""}
              id="template_id"
              title="Template"
              disabled={!canEdit}
              validation={{
                template_id: Yup.string(),
              }}
              options={[
                { label: "Select", value: "", props: { hidden: true } },
                ...templateOption,
              ]}
            />

            <SelectInputField
              parentForm={formik}
              className="col-md-12"
              updateValue={updated_data.screener_format}
              actualValue={values.screener_format}
              id="screener_format"
              title="Format"
              disabled={!canEdit || screener_id}
              validation={{
                screener_format: Yup.string().required(
                  "Select a screener format"
                ),
              }}
              options={[
                { label: "Select", value: "", props: { hidden: true } },
                {
                  label: "Screener",
                  value: "Screener",
                },
                {
                  label: "Game",
                  value: "Game",
                },
              ]}
            />
            <SelectInputField
              parentForm={formik}
              className="col-md-12"
              updateValue={updated_data.sort_type}
              actualValue={values.sort_type}
              id="sort_type"
              title="Sort Type"
              disabled={!canEdit}
              validation={{
                sort_type: Yup.string().required("Select a sort type"),
              }}
              options={[
                { label: "Select", value: "", props: { hidden: true } },
                {
                  label: "Topic",
                  value: "Topic",
                },
                {
                  label: "Question",
                  value: "Question",
                },
              ]}
            />
            <TextInputField
              parentForm={formik}
              className="col-md-12"
              updateValue={
                !IsNullOrEmpty(updated_data.screener_time)
                  ? updated_data.screener_time / 60
                  : ""
              }
              actualValue={
                !IsNullOrEmpty(values.screener_time)
                  ? values.screener_time / 60
                  : ""
              }
              id="screener_time"
              title="Duration (in minutes)"
              disabled={!canEdit}
              validation={{
                screener_time: Yup.string()
                  .trim()
                  .required("Enter screener duration"),
              }}
              isNumber={true}
              inputProps={{
                maxLength: 10,
              }}
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="row">
            <TextInputField
              parentForm={formik}
              className="col-md-12"
              updateValue={updated_data.screener_name ?? ""}
              actualValue={values.screener_name ?? ""}
              id="screener_name"
              title="Name"
              disabled={!canEdit}
              customBlur={true}
              validation={{
                screener_name: Yup.string()
                  .trim()
                  .required("Enter a screener name"),
              }}
            />
            <TextInputField
              parentForm={formik}
              className="col-md-12"
              updateValue={updated_data.alias_name ?? ""}
              actualValue={values.alias_name ?? ""}
              id="alias_name"
              title="Alias Name"
              disabled={!canEdit}
              customBlur={true}
              validation={{
                alias_name: Yup.string().trim(),
              }}
            />
            <DescriptionInputField
              parentForm={formik}
              parentKey="screener_description"
              className="col-md-12"
              updateValue={
                updated_data.screener_description &&
                updated_data.screener_description.update_items
                  ? updated_data.screener_description.update_items
                  : false
              }
              actualValue={values.screener_description}
              disabled={!canEdit}
              validation={{
                description: Yup.string()
                  .trim()
                  .required("Screener description is required"),
                mobile_description: Yup.string()
                  .trim()
                  .required("Screener mobile description is required"),
              }}
              showEditor={true}
            />
          </div>
        </div>
        <div className="col-md-12">
          <MetaHistoryInput
            parentForm={formik}
            parentArray="screener_meta"
            typeKey="type"
            titleKey="key"
            valueKey="value"
            updateValue={
              updated_data.screener_meta &&
              updated_data.screener_meta.update_items
                ? updated_data.screener_meta.update_items
                : false
            }
            validation={{
              screener_meta: Yup.array().of(
                Yup.object().shape({
                  type: Yup.string().required("Select a data type"),
                  key: Yup.string().trim().required("Enter a title"),
                  value: Yup.string().trim().required("Enter a value"),
                })
              ),
            }}
            isPublished={values.published}
            actualValue={values.screener_meta}
            disabled={!canEdit}
          />
        </div>
        <div className={"d-flex align-items-end justify-content-end col-md-12"}>
          {isEditable && canEdit && hasUpdated && (
            <Popconfirm
            title="Are you sure to discard and close?"
            onConfirm={handleDiscord}
            //  onCancel={cancel}
            okText="Yes"
            cancelText="No"
          >
            <button
              // onClick={() => handleDiscord()}
              type="button"
              className="btn btn-danger btn-sm float-right btn-add-domain mr-2"
            >
              Discard All & Close
            </button>
          </Popconfirm>
          )}
          <button
            onClick={onClose}
            type="button"
            className="btn btn-danger btn-sm float-right btn-add-domain mr-2"
          >
            Close
          </button>
          {canEdit ? (
            <CustomButton
              type="button"
              className="btn btn-primary btn-outline-success btn-sm float-right btn-add-domain"
              disabled={!formik.isValid || formik.isSubmitting}
              onClick={
                formik.isValid && Object.keys(formik.values).length !== 0
                  ? formik.handleSubmit
                  : handleError
              }
            >
              Update
            </CustomButton>
          ) : (
            <button
              onClick={onDone}
              type="button"
              className="btn btn-secondary btn-sm float-right btn-add-domain mr-2"
            >
              Next
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
