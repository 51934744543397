import { useState } from "react";
import { GET_FUNCTION_LIST_GQL } from "../../Apollo/AdminServices/QueryRequest";
import graphHandler from "../../Apollo/graphHandler";
import { sortArray } from "../Functions/filter";
function useFunctionList() {
  const [functionList, setFunctionList] = useState([]);
  const [loading, setLoading] = useState(false);

  const getFunctionList = async (req = {}) => {
    setLoading(true);
    try {
      let { data } = await graphHandler().invoke(
        GET_FUNCTION_LIST_GQL,
        req,
        1,
        false,
        true
      );
      if (
        data &&
        data.getFunctionList &&
        data.getFunctionList.functions &&
        data.getFunctionList.functions.length > 0
      ) {
        setFunctionList([]);
        setFunctionList(
          sortArray(data.getFunctionList.functions).map((x) => ({
            ...x,
            label: x.function_name,
            value: x.function_id,
          }))
        );
        setLoading(false);
      } else {
        setFunctionList([]);
        setLoading(false);
      }
    } catch (error) {
      console.log("getFunctionList Error", error);
      setFunctionList([]);
      setLoading(false);
    }
  };
  return {
    functionList,
    loading,
    getFunctionList,
  };
}
export default useFunctionList;
