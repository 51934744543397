import { Col, Row } from "reactstrap";
import Header from "./components/Header";
import SideBar from "./components/SideBar";
function MainLayout({ children }) {
  return (
    <section>
      <Row>
        <Col>
          <Header />
        </Col>
      </Row>
      <div className="d-flex flex-row">
        <SideBar />
        <div className="page-wrapper">{children}</div>
      </div>
    </section>
  );
}
export default MainLayout;
