import React, { useEffect } from "react";
import useScreenerHistory from "../../../utils/CustomHooks/useScreenerHistory";
import moment from "moment";
import ScreenerHistoryComments from "./ScreenerHistoryComments";
export default function ScreenerHistoryCommentsTable({ screenerHistory }) {
  return (
    <div className="col-md-12">
      <div className="table-responsive ">
        <table className="table domain-table table-bordered">
          <thead>
            <tr className=" ">
              <td className="">Comments</td>
              <td className="">Time </td>
              <td className="">By</td>
            </tr>
          </thead>
          <tbody>
            {screenerHistory &&
            screenerHistory.filter((x) => x.approved_by || x.reviewed_by)
              .length > 0 ? (
              screenerHistory.map((x, i) => (
                <ScreenerHistoryComments x={x} key={i} />
              ))
            ) : (
              <tr>
                <td colSpan="3" className="text-center">
                  <span className="font-weight-bold">Oops! No Data Found</span>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}
