import { useEffect, useState } from "react";
import SortByTopicItem from "../Components/SortByTopicItem";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import SortByExitTopicItem from "../Components/SortByExitTopicItem";
import { Popconfirm } from "antd";

export default function TopicSortSection(props) {
  const [topics, setTopics] = useState([]);
  const [current, setCurrent] = useState("");
  const [exitCurrent, setExitCurrent] = useState("");
  const [prevTopics, setPrevTopics] = useState([]);
  const [isUpdated, setIsUpdated] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const { topicList = [], handleDiscord = () => {} } = props;

  useEffect(() => {
    if (topicList) {
      setIsUpdated(false);
      let data =
        topicList &&
        topicList.length > 0 &&
        topicList.filter((x)=>x?.active === true).map((x) => {
          const hasUpdated = Boolean(
            x && x?.pending_history && x?.pending_history?.updated_data
          );
          // x?.pending_history?.updated_data && setIsUpdated(true);
          return {
            ...x,
            sort_order: hasUpdated
              ? x?.pending_history?.updated_data?.sort_order
              : x.sort_order,
          };
        });
      if (data && data[0]?.pending_history?.updated_data?.sort_order) {
        setIsUpdated(true);
      } else {
        setIsUpdated(false);
      }
      setTopics([...data.slice().sort((a, b) => a.sort_order - b.sort_order)]);
      setPrevTopics([
        ...topicList.slice().filter((x)=>x?.active === true).sort((a, b) => a.sort_order - b.sort_order),
      ]);
    }
  }, [topicList]);

  const reorder = (list, startIndex, endIndex) => {
    let newData = [
      ...list.map((x, i) => ({
        ...x,
        sort_order:
          i === startIndex
            ? startIndex + 1
            : i === endIndex
            ? endIndex + 1
            : x.sort_order,
      })),
    ];
    const result = Array.from(newData);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result.filter((x) => x);
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;
    const items = reorder(
      topics,
      result.source.index,
      result.destination.index
    );
    setTopics(items);
    props.refresh();
  };
  return (
    <>
      {isUpdated && (
        <div className="d-flex align-items-end justify-content-center p-0 col-md-12">
          <Popconfirm
            title="Are you sure to discard and close?"
            onConfirm={handleDiscord}
            //  onCancel={cancel}
            okText="Yes"
            cancelText="No"
          >
            <button
              type="button"
              className="btn btn-danger btn-sm float-right btn-add-domain mr-2"
            >
              Discard All & Close
            </button>
          </Popconfirm>
          <button
            type="button"
            className="btn btn-primary btn-outline-success btn-sm float-right btn-add-domain"
            onClick={() => setIsOpen(!isOpen)}
          >
            Compare
          </button>
        </div>
      )}
      <div className="pl-5 pr-5 pb-5 pt-3 position-relative row justify-content-center">
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided) => (
              <div
                {...provided.droppableProps}
                className={isOpen ? `col-md-6` : `col-md-12`}
                ref={provided.innerRef}
              >
                {isOpen && (
                  <label className="badge row badge-error ml-1">New</label>
                )}
                {topics &&
                  topics.length > 0 &&
                  topics.map((item, i) => (
                    <div className="row w-100" key={i}>
                      <SortByTopicItem
                        {...props}
                        item={item}
                        key={i}
                        i={i + 1}
                        current={current}
                        setCurrent={setCurrent}
                      />
                    </div>
                  ))}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        {isOpen && (
          <div className="col-md-6">
            <label className="badge row badge-good ml-1">Existing</label>
            {prevTopics &&
              prevTopics.length > 0 &&
              prevTopics.map((item, i) => {
                return (
                  <div className="row w-100" key={i}>
                    <SortByExitTopicItem
                      {...props}
                      item={item}
                      key={i}
                      i={i + 1}
                      current={exitCurrent}
                      setCurrent={setExitCurrent}
                    />
                  </div>
                );
              })}
          </div>
        )}
      </div>
    </>
  );
}
