import { useEffect } from "react";
import { Modal, ModalBody } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import graphHandler from "../../../Apollo/graphHandler";
import { UPDATE_FUNCTION_GQL } from "../../../Apollo/AdminServices/MutationRequest";
import CustomButton from "../../../components/Button/CustomButton";

export default function UpdateFunction({
  isOpen,
  setOpen,
  refresh,
  initialValue = {},
  industryList=[]
}) {
  const formik = useFormik({
    initialValues: {},
    validationSchema: validation,
    onSubmit: (values) => {
      onSubmit(values);
    },
  });
  useEffect(() => {
    if (initialValue.function_id) {
      const value = {
        function_id: initialValue.function_id,
        function_name: initialValue.function_name,
        // industry_id: initialValue.industry_id,
      };
      formik.setValues(value);
    }
  }, [initialValue, isOpen]);

  const onClose = () => {
    formik.resetForm();
    setOpen();
  };

  const onSubmit = async (values) => {
    const { function_name ,
      // industry_id
    } = values;
    const update_request = {
      // function_name:function_name.trim(),
      function_id: initialValue.function_id,
    };
    // if(industry_id) update_request.industry_id = industry_id;

    if(initialValue.function_name !== function_name.trim()){
      update_request.function_name=function_name.trim()
    }
    if(Object.keys(update_request).length === 1){
      refresh();
      onClose();
      formik.setSubmitting(false);
      return;
    }
    try {
      await graphHandler().invoke(
        UPDATE_FUNCTION_GQL,
        { update_request },
        1,
        true
      );
      refresh();
      onClose();
      formik.setSubmitting(false);
    } catch (error) {
      console.log("UpdateCategory error", error);
      formik.setSubmitting(false);
    }
  };

  const handleError = () => {
    if (Object.keys(formik.errors).length > 0) {
      toast.error(formik.errors[Object.keys(formik.errors)[0]]);
    }
  };

  return (
    <Modal toggle={onClose} isOpen={isOpen} centered scrollable size="xl">
      <div className="modal-header">
        <button type="button" className="close" onClick={onClose}>
          <span aria-hidden="true">&times;</span>
        </button>
        <h5 className="modal-title">Update Function</h5>
      </div>
      <ModalBody className="pt-0">
        <div className="container">
          <div className="row justify-content-center">
          {/* <div className="col-md-12 form-group">
              <label
                htmlFor="industry_id"
                className="col-form-label w-100 mb-2"
              >
                Industry
              </label>
              <select
                name="industry_id"
                className="form-control"
                id="industry_id"
                onChange={formik.handleChange}
                value={
                  formik.values.industry_id ? formik.values.industry_id : ""
                }
                onBlur={formik.handleBlur}
              >
                <option key="Select" hidden>
                  Select
                </option>
                {industryList.map((x, i) => (
                  <option value={x.industry_id} key={i}>
                    {x.industry_name}
                  </option>
                ))}
              </select>
              <small className="text-danger">
                {formik.touched.industry_id &&
                formik.errors &&
                formik.errors.industry_id
                  ? formik.errors.industry_id
                  : ""}
              </small>
            </div> */}
            <div className="col-md-12 form-group  ">
              <label
                htmlFor="function_name"
                className="col-form-label w-100 mb-2"
              >
                Name
              </label>
              <input
                type="text"
                className="form-control"
                id="function_name"
                name="function_name"
                // maxLength="30"
                value={formik.values.function_name
                    ? formik.values.function_name
                    : ""
                }
                onChange={(e) =>
                  formik.setFieldValue(
                    "function_name",
                    e.target.value.trimStart()
                    // .replace(/[^a-z ]/gi, "")
                  )
                }
                onBlur={formik.handleBlur}
              />
              <small className="text-danger">
                {formik.touched.function_name &&
                formik.errors &&
                formik.errors.function_name
                  ? formik.errors.function_name
                  : ""}
              </small>
            </div>

            <CustomButton
              type="button"
              className="btn btn-primary btn-outline-success float-right btn-add-domain"
              disabled={
                Object.keys(formik.errors).length !== 0 ||
                !formik.isValid ||
                formik.isSubmitting
              }
              onClick={
                formik.isValid && Object.keys(formik.values).length !== 0
                  ? formik.handleSubmit
                  : handleError
              }
            >
              Update
            </CustomButton>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
}

const validation = Yup.object().shape({
  function_name: Yup.string().trim().required("Enter function"),
});
