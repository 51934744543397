import { useState } from "react";
import UpdateEducation from "../modal/UpdateEducation";
import { Link } from "react-router-dom";
import { Popconfirm } from "antd";
import useRemoveAPI from "../../../utils/CustomHooks/useRemoveAPI";

export default function ListItem({ item, refresh, access = {} }) {
  const [isEdit, setEdit] = useState(false);
  const { removeEducation } = useRemoveAPI();
  const onDelete = () => {
    removeEducation({ education_id: item.education_id }, refresh);
  };
  return (
    <tr>
      <td className="col-sm-2">{item.education_name??"-"}</td>
      <td>
        <span
          className={`${item.active ? "good" : "bad"} text-center btn btn-sm`}
        >
          {item.active ? "Active" : "Disabled"}
        </span>
      </td>
      {access.canEdit && (
        <td>
        <button
          type="button"
          className="btn btn-outline-success btn-sm "
          onClick={() => setEdit(true)}
        >
          Edit
        </button>
      </td>
      )}
        <td>
      <Popconfirm
           title="Are you sure to delete this Education?"
           onConfirm={onDelete}
          //  onCancel={cancel}
           okText="Yes"
           cancelText="No"
          >
        <i
        className="ml-2 fas fa-trash cursor-pointer"
        // onClick={onDelete}
      ></i>
      </Popconfirm>
      </td>
      <UpdateEducation
        isOpen={isEdit}
        setOpen={() => setEdit(false)}
        initialValue={item}
        refresh={refresh}
      />
    </tr>
  );
}
