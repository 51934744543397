import { useState } from "react";
import {
  ACTIVE_THERAPY_GQL,
  APPROVE_THERAPY_GQL,
  REMOVE_ACTION_GQL,
  REMOVE_MODULE_GQL,
  REVIEW_THERAPY_GQL,
} from "../../Apollo/AdminServices/MutationRequest";
import graphHandler from "../../Apollo/graphHandler";
function useTherapyAPI() {
  const [loading, setLoading] = useState(false);
  const approveTherapy = async (req = {}, callback = () => {}) => {
    setLoading(true);
    try {
      const { data } = await graphHandler().invoke(
        APPROVE_THERAPY_GQL,
        req,
        1,
        false,
        true
      );
      // console.log("approveTherapy Success", data.approveTherapy);
      callback();
      setLoading(false);
    } catch (error) {
      console.log("approveTherapy Error", error);
      setLoading(false);
    }
  };
  const reviewTherapy = async (req = {}, callback = () => {}) => {
    setLoading(true);
    try {
      const { data } = await graphHandler().invoke(
        REVIEW_THERAPY_GQL,
        req,
        1,
        false,
        true
      );

      // console.log("reviewTherapy Success", data.reviewTherapy);
      callback();
      setLoading(false);
    } catch (error) {
      console.log("reviewTherapy Error", error);
      setLoading(false);
    }
  };
  const activateTherapy = async (req = {}, callback = () => {}) => {
    setLoading(true);
    try {
      const { data } = await graphHandler().invoke(
        ACTIVE_THERAPY_GQL,
        req,
        1,
        false,
        true
      );
      // console.log("activateTherapy Success", data.activateTherapy);
      callback();
      setLoading(false);
    } catch (error) {
      console.log("activateTherapy Error", error);
      setLoading(false);
    }
  };
  const removeModules = async (therapy_module_id = "", callback = () => {}) => {
    if (loading || !therapy_module_id) return;
    setLoading(true);
    try {
      const { data } = await graphHandler().invoke(
        REMOVE_MODULE_GQL,
        { therapy_module_id },
        1,
        false,
        true
      );
      // console.log("activateTherapy Success", data.activateTherapy);
      callback();
      setLoading(false);
    } catch (error) {
      console.log("activateTherapy Error", error);
      setLoading(false);
    }
  };
  const removeActions = async (
    therapy_module_action_id = "",
    callback = () => {}
  ) => {
    if (loading || !therapy_module_action_id) return;
    setLoading(true);
    try {
      const { data } = await graphHandler().invoke(
        REMOVE_ACTION_GQL,
        { therapy_module_action_id },
        1,
        false,
        true
      );
      // console.log("activateTherapy Success", data.activateTherapy);
      callback();
      setLoading(false);
    } catch (error) {
      console.log("activateTherapy Error", error);
      setLoading(false);
    }
  };
  return {
    approveTherapy,
    loading,
    reviewTherapy,
    activateTherapy,
    removeModules,
    removeActions,
  };
}
export default useTherapyAPI;
