import { useState, useEffect } from "react";
import graphHandler from "../../Apollo/graphHandler";
import { GET_USER_LIST } from "../../Apollo/ProfileService/profileQueryRequest";
import UserTableItems from "./components/UserTableItems";
import InviteUserModal from "./modal/InviteUserModal";
import PaginationComponent from "../../components/PaginationComponent";
import withRoleBased from "../../utils/Functions/withRoleBased";
import { sortArray } from "../../utils/Functions/filter";

const Home = ({ access }) => {
  const [userList, setUserList] = useState([]);
  const [count, setCount] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [isSearching, setIsSearch] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const pageCount = Math.ceil(userList.length / pageSize);
  const { canCreate } = access;
  useEffect(() => {
    getUserList({
      role_types: [
        "ADMIN",
        "CONTENT_CREATOR",
        "CONTENT_REVIEWER",
        "CONTENT_APPROVER",
      ],
      user_types: ["Employee", "Admin"],
    });
  }, [count]);
  const handleClick = (event, index) => {
    event.preventDefault();
    setCurrentPage(index);
  };
  const getUserList = async (reqs = {}) => {
    try {
      const req = { ...reqs };
      if (search) req.name = search;
      const { data } = await graphHandler().invoke(
        GET_USER_LIST,
        req,
        0,
        false,
        true
      );
      //   console.log("getUserList Success", data);
      if (
        data.getUserList &&
        data.getUserList.users &&
        data.getUserList.users.length > 0
      )
        setUserList(data.getUserList.users);
      else {
        setUserList([]);
      }
    } catch (error) {
      console.log("getUserList error", error);
      setIsSearch(false);
    }
  };
  const onSearch = () => {
    setCurrentPage(0)
    setCount((x) => x + 1);
    setIsSearch(search ? true : false);
  };
  return (
    <main className="page-content">
      <div className="container-fluid">
        <div className="row m-0 w-100">
          <div className="col-md-6">
            <h1 className="page-title"> Users </h1>
          </div>
          <div className="col-md-6 d-flex justify-content-md-end">
            {canCreate && (
              <button
                className="btn btn-outline-success"
                type="submit"
                onClick={() => setIsOpen(true)}
              >
                Add User
              </button>
            )}
          </div>
          <div className="col-md-4">
            <div className="search" style={{ margin: "16px 0px 32px" }}>
              <input
                type="text"
                className="form-control"
                placeholder="Search"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                onKeyDown={(e) => e.key === "Enter" && onSearch()}
              />
              {!isSearching ? (
                <button onClick={onSearch}>
                  <i className="fa fa-search" />
                </button>
              ) : (
                <button
                  onClick={() => {
                    setSearch("");
                    setIsSearch(false);
                    setCount((x) => x + 1);
                  }}
                >
                  <i className="fa fa-times" />
                </button>
              )}
            </div>
          </div>
          <div className="col-md-12">
            <div className="table-responsive ">
              <table className="table table-borderless ">
                <thead>
                  <tr className=" ">
                    <td className="col-sm-2">Users</td>
                    <td className="col-sm-4">Email</td>
                    <td className="col-sm-3">Status</td>
                    <td className="col-sm-3">Action</td>
                  </tr>
                </thead>
                <tbody>
                  {userList && userList.length > 0 ? (
                    sortArray(userList)
                      .slice(
                        currentPage * pageSize,
                        (currentPage + 1) * pageSize
                      )
                      .map((x, i) => (
                        <UserTableItems
                          data={x}
                          key={i}
                          index={i}
                          refresh={() => setCount((x) => x + 1)}
                          access={access}
                        />
                      ))
                  ) : (
                    <tr>
                      <td colSpan="6" className="text-center">
                        <span className="font-weight-bold">
                          Oops! No Data Found
                        </span>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        {/* <!-- Navigation --> */}
        {userList.length > 0 && pageCount != 1 && (
          <PaginationComponent
            handleClick={handleClick}
            currentPage={currentPage}
            pageCount={pageCount}
          />
        )}
      </div>
      <InviteUserModal
        isOpen={isOpen}
        setOpen={() => setIsOpen(false)}
        refresh={() => {
          setSearch("");
          setCount((x) => x + 1);
        }}
        access={access}
      />
    </main>
  );
};

export default withRoleBased(Home, "user");
