import { useFormik } from "formik";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import { IsNullOrEmpty, mergeMetaArray } from "../../utils/Functions/filter";
import CreateOptionCard from "./Components/CreateOptionCard";
import HistoryOptionTableItem from "./Components/HistoryOptionTableItem";
import UpdateOptionCard from "./Components/UpdateOptionCard";

export default function OptionHistoryInput(props) {
  const [isView, setVisible] = useState(true);
  const [data, setData] = useState([]);

  let {
    parentForm = {},
    validation = {},
    className = "col-md-12",
    col = "col-md-6",
    actualValue = [],
    updateValue,
    parentArray = "options",
    titleKey = "data",
    valueKey = "value",
    heading = "Options",
  } = props;

  const defaultValue = {
    [titleKey]: "",
    [valueKey]: "",
  };

  useEffect(() => {
    const parentData = [
      ...(parentForm.values[parentArray]
        ? parentForm.values[parentArray]
        : []
      ).map((x) => x),
    ];
    if (updateValue && updateValue.length > 0 && parentForm) {
      parentForm.setFieldValue(parentArray, [...updateValue, ...parentData]);
    }
  }, [updateValue]);
  const formik = useFormik({
    initialValues: {},
    validationSchema: Yup.object().shape(validation),
    onSubmit: () => {},
  });
  const formik1 = useFormik({
    initialValues: {
      ...defaultValue,
    },
    validationSchema: Yup.object().shape({
      [titleKey]: Yup.string().trim().required("Enter a data"),
      [valueKey]: Yup.string().trim().required("Enter a value"),
    }),
    onSubmit: (values) => onSubmit(values),
  });
  const isUpdate = Number.isInteger(formik1.values.index);
  useEffect(() => {
    if (actualValue.length > 0) {
      let array = [];
      if (!updateValue || updateValue.length === 0) {
        array = actualValue.map((x, index) => ({ ...x, index }));
      } else {
        let value = mergeMetaArray(actualValue, updateValue);
        value.map((x, index) => {
          let returnData = { ...x };
          if (x.isRemoved) returnData.index = index;
          array.push(returnData);
        });
      }
      formik.setValues({ [parentArray]: array });
      setData(array);
    }
  }, [actualValue, updateValue]);

  const onSubmit = (values = {}) => {
    // console.log(values);
    let modal = { ...values };
    let meta_data = [
        ...(formik.values[parentArray] ? formik.values[parentArray] : []).map(
          (x) => x
        ),
      ],
      parentData = [
        ...(parentForm.values[parentArray]
          ? parentForm.values[parentArray]
          : []
        ).map((x) => x),
      ];
    if (!values[titleKey] || IsNullOrEmpty(values[valueKey]))
      return console.error("Data Not found", values);
    if (Number.isInteger(values.index) && meta_data.length > values.index) {
      delete modal.index;
      parentData.push({
        update_type: "Update",
        index: values.index,
        update_data: {
          [titleKey]: values[titleKey],
          [valueKey]: Number(values[valueKey]),
        },
      });
      meta_data[values.index] = { ...modal };
    } else {
      let index = !IsNullOrEmpty(values.i) ? values.i : meta_data.length;
      meta_data[index] = {
        ...modal,
        index,
      };
      let query = {
        update_data: {
          [titleKey]: values[titleKey],
          [valueKey]: Number(values[valueKey]),
        },
      };
      if (Number.isInteger(values.index)) {
        query.update_type = "Update";
        query.index = values.index;
      } else {
        query.update_type = "Add";
      }
      //   formik.setFieldValue(parentArray, [
      //     ...meta_data,
      //     { ...modal, index: meta_data.length },
      //   ]);
      parentData.push(query);
    }
    formik.setFieldValue(parentArray, [...meta_data]);
    parentForm.setFieldValue(parentArray, [...parentData]);
    // console.log(meta_data, values, parentData);
    reset();
  };

  const reset = () => {
    formik1.setValues({});
    formik1.setTouched({});
  };

  const remove = (i, arrayIndex) => {
    const parentData = [
      ...(parentForm.values[parentArray]
        ? parentForm.values[parentArray]
        : []
      ).map((x) => x),
    ];
    const meta_data = [
      ...(formik.values[parentArray] ? formik.values[parentArray] : []).map(
        (x) => x
      ),
    ];

    if (isUpdate && formik1.values.index === meta_data[arrayIndex].index) {
      reset();
    }
    meta_data.splice(arrayIndex, 1);
    parentData.push({
      update_type: "Remove",
      index: i,
    });
    parentForm.setFieldValue(parentArray, [...parentData]);
    formik.setFieldValue(parentArray, meta_data);
  };
  //   console.log(formik1.values, formik.values, parentForm.values);
  const onEdit = (values = {}, i) => {
    setVisible(true);
    formik1.setValues({
      ...values,
      i,
    });
    const container = document.getElementById("OptionSection" + parentArray);
    if (container) container.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div
      className={`form-group ${className}`}
      id={"OptionSection" + parentArray}
    >
      <div className="row mb-2">
        <label className="col-form-label w-100">
          {heading}{" "}
          {!isView && !props.disabled && (
            <button
              className="btn btn-outline-success ml-5 btn-sm"
              onClick={() => setVisible(true)}
            >
              Add
            </button>
          )}
        </label>
      </div>
      <div className="row  mb-3">
        {formik.values[parentArray] && formik.values[parentArray].length > 0 ? (
          <div className={col}>
            <div
              className="row table-responsive table-borderless  questionnaire-table"
              style={{ maxHeight: "200px" }}
            >
              <table className="table table-striped mb-0">
                <thead>
                  <tr>
                    <th className="col-sm-3">Data</th>
                    <th className="col-sm-5">Value</th>
                    {!props.disabled && <th className="col-sm-2">Action</th>}
                  </tr>
                </thead>
                <tbody>
                  {formik.values[parentArray]
                    .slice()
                    .sort((a, b) => a.sort_order - b.sort_order)
                    .map((x, i) => (
                      <HistoryOptionTableItem
                        key={i}
                        arrayIndex={i}
                        item={x}
                        {...props}
                        remove={remove}
                        onEdit={onEdit}
                      />
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        ) : null}

        {isView && !props.disabled && (
          <div className={col}>
            {isUpdate ? (
              <UpdateOptionCard
                formik={formik1}
                reset={reset}
                setVisible={setVisible}
                {...props}
              />
            ) : (
              <CreateOptionCard
                formik={formik1}
                reset={reset}
                setVisible={setVisible}
                {...props}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
}
