import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Modal, ModalBody } from "reactstrap";
import { toast } from "react-toastify";
import graphHandler from "../../../Apollo/graphHandler";
import CustomButton from "../../../components/Button/CustomButton";
import { UPDATE_RULE_ENGINE_GQL } from "../../../Apollo/AdminServices/MailRuleReportRequest";
import {
  platformInputType,
  platformOutputType,
  platformType,
} from "../../../constants";
import { IsNullOrEmpty } from "../../../utils/Functions/filter";

export default function UpdateRule({
  values = {},
  isOpen,
  setOpen = () => {},
  refresh = () => {},
}) {
  const { subDomains, therapies, plans, functions } = useSelector(
    ({ DataSet }) => DataSet
  );
  const formik = useFormik({
    initialValues: {},
    validationSchema: validation,
    onSubmit: (values) => {
      onSubmit(values);
    },
  });
  useEffect(() => {
    if (values.rule_engine_id && isOpen) {
      let obj = {
        rule_engine_id: values.rule_engine_id,
        platform_type: values.platform_type,
        weightage: values.weightage,
        rule_input_type: values.rule_input_type,
        rule_input_id: values.rule_input_id,
        rule_output_type: values.rule_output_type,
        rule_output_id: values.rule_output_id,
      };
      formik.setValues(obj);
    }
  }, [values, isOpen]);

  const onClose = () => {
    formik.resetForm();
    formik.setValues({});
    setOpen();
  };
  const getOptions = (type) => {
    switch (type) {
      case "SUBDOMAIN":
        return subDomains;
      case "THERAPY":
        return therapies;
      case "FUNCTION":
        return functions;
      case "PLAN":
        return plans;
      default:
        return [];
    }
  };
  const onSubmit = async (modal) => {
    // formik.setSubmitting(false);
    const {
      rule_engine_id,
      rule_input_id,
      rule_input_type,
      rule_output_id,
      rule_output_type,
      platform_type,
      weightage,
    } = modal;
    // formik.setSubmitting(true);
    let update_request = {
      rule_engine_id,
    };
    if (IsNullOrEmpty(values.weightage) || weightage != values.weightage) {
      update_request.weightage = Number(weightage);
    }
    if (rule_input_id && rule_input_id !== values.rule_input_id) {
      update_request.rule_input_id = rule_input_id;
    }
    if (rule_input_type && rule_input_type !== values.rule_input_type) {
      update_request.rule_input_type = rule_input_type;
    }
    if (rule_output_id && rule_output_id !== values.rule_output_id) {
      update_request.rule_output_id = rule_output_id;
    }
    if (rule_output_type && rule_output_type !== values.rule_output_type) {
      update_request.rule_output_type = rule_output_type;
    }
    if (platform_type && platform_type !== values.platform_type) {
      update_request.platform_type = platform_type;
    }
    if (Object.keys(update_request).length === 1) {
      // refresh();
      onClose();
      formik.setSubmitting(false);
      return;
    }
    try {
      await graphHandler().invoke(
        UPDATE_RULE_ENGINE_GQL,
        { update_request },
        1,
        true
      );
      refresh();
      onClose();
      formik.setSubmitting(false);
    } catch (error) {
      console.error("Update Rule", error);
      formik.setSubmitting(false);
    }
  };

  const handleError = () => {
    if (Object.keys(formik.errors).length > 0) {
      toast.error(formik.errors[Object.keys(formik.errors)[0]]);
    }
  };

  return (
    <Modal toggle={onClose} isOpen={isOpen} centered scrollable size="xl">
      <div className="modal-header">
        <button type="button" className="close" onClick={onClose}>
          <span aria-hidden="true">&times;</span>
        </button>
        <h5 className="modal-title">Update Rule</h5>
      </div>
      <ModalBody className="pt-0">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-6 form-group">
              <label
                htmlFor="platform_type"
                className="col-form-label w-100 mb-2"
              >
                Platform Type
              </label>
              <select
                id="platform_type"
                name="platform_type"
                className="form-control"
                placeholder="Select Platform Type"
                onChange={formik.handleChange}
                value={
                  formik.values.platform_type ? formik.values.platform_type : ""
                }
                onBlur={formik.handleBlur}
              >
                <option key="Select" hidden>
                  Select
                </option>
                {platformType.map((x, i) => (
                  <option value={x} key={i}>
                    {x}
                  </option>
                ))}
              </select>
              <small className="text-danger">
                {formik.touched.platform_type &&
                formik.errors &&
                formik.errors.platform_type
                  ? formik.errors.platform_type
                  : ""}
              </small>
            </div>
            <div className="col-md-6 form-group">
              <label htmlFor="weightage" className="col-form-label w-100 mb-2">
                Weightage
              </label>
              <div className="d-flex flex-column p-0">
                <input
                  type="number"
                  className="form-control h-100 w-100"
                  placeholder=""
                  id="weightage"
                  name="weightage"
                  max={100}
                  value={
                    formik.values && formik.values.weightage
                      ? formik.values.weightage
                      : ""
                  }
                  onBlur={formik.handleBlur}
                  onChange={(e) =>
                    formik.setFieldValue(
                      "weightage",
                      e.target.value.replace(/[^0-9\.]/g, "")
                    )
                  }
                />
                <small className="text-danger">
                  {formik.touched.weightage && formik.errors.weightage
                    ? formik.errors.weightage
                    : ""}
                </small>
              </div>
            </div>
            <div className="col-md-6 form-group">
              <label
                htmlFor="rule_input_type"
                className="col-form-label w-100 mb-2"
              >
                Content Input Type
              </label>
              <select
                id="rule_input_type"
                name="rule_input_type"
                className="form-control"
                placeholder="Select Input Type"
                onChange={(e) => {
                  // formik.handleChange();
                  formik.setFieldValue("rule_input_type", e.target.value);
                  formik.setFieldValue("rule_input_id", undefined);
                }}
                value={
                  formik.values.rule_input_type
                    ? formik.values.rule_input_type
                    : ""
                }
                onBlur={formik.handleBlur}
              >
                <option key="Select" hidden>
                  Select
                </option>
                {platformInputType.map((x, i) => (
                  <option value={x.value} key={i}>
                    {x.label}
                  </option>
                ))}
              </select>
              <small className="text-danger">
                {formik.touched.rule_input_type &&
                formik.errors &&
                formik.errors.rule_input_type
                  ? formik.errors.rule_input_type
                  : ""}
              </small>
            </div>
            <div className="col-md-6 form-group">
              <label
                htmlFor="rule_input_id"
                className="col-form-label w-100 mb-2"
              >
                Content Input
              </label>
              <select
                id="rule_input_id"
                name="rule_input_id"
                className="form-control"
                placeholder="Select Content Input"
                onChange={formik.handleChange}
                value={
                  formik.values.rule_input_id ? formik.values.rule_input_id : ""
                }
                onBlur={formik.handleBlur}
              >
                <option key="Select" hidden>
                  Select
                </option>
                {getOptions(formik.values.rule_input_type).map((x, i) => (
                  <option value={x.value} key={i}>
                    {x.label}
                  </option>
                ))}
              </select>
              <small className="text-danger">
                {formik.touched.rule_input_id &&
                formik.errors &&
                formik.errors.rule_input_id
                  ? formik.errors.rule_input_id
                  : ""}
              </small>
            </div>
            <div className="col-md-6 form-group">
              <label
                htmlFor="rule_output_type"
                className="col-form-label w-100 mb-2"
              >
                Content Output Type
              </label>
              <select
                id="rule_output_type"
                name="rule_output_type"
                className="form-control"
                placeholder="Select Output Type"
                onChange={(e) => {
                  // formik.handleChange;
                  formik.setFieldValue("rule_output_type", e.target.value);
                  formik.setFieldValue("rule_output_id", undefined);
                }}
                value={
                  formik.values.rule_output_type
                    ? formik.values.rule_output_type
                    : ""
                }
                onBlur={formik.handleBlur}
              >
                <option key="Select" hidden>
                  Select
                </option>
                {platformOutputType.map((x, i) => (
                  <option value={x.value} key={i}>
                    {x.label}
                  </option>
                ))}
              </select>
              <small className="text-danger">
                {formik.touched.rule_output_type &&
                formik.errors &&
                formik.errors.rule_output_type
                  ? formik.errors.rule_output_type
                  : ""}
              </small>
            </div>
            <div className="col-md-6 form-group">
              <label
                htmlFor="rule_output_id"
                className="col-form-label w-100 mb-2"
              >
                Content Output
              </label>
              <select
                id="rule_output_id"
                name="rule_output_id"
                className="form-control"
                placeholder="Select Content Output"
                onChange={formik.handleChange}
                value={
                  formik.values.rule_output_id
                    ? formik.values.rule_output_id
                    : ""
                }
                onBlur={formik.handleBlur}
              >
                <option key="Select" hidden>
                  Select
                </option>
                {getOptions(formik.values.rule_output_type).map((x, i) => (
                  <option value={x.value} key={i}>
                    {x.label}
                  </option>
                ))}
              </select>
              <small className="text-danger">
                {formik.touched.rule_output_id &&
                formik.errors &&
                formik.errors.rule_output_id
                  ? formik.errors.rule_output_id
                  : ""}
              </small>
            </div>
          </div>

          <div className={"d-flex  justify-content-center col-md-12"}>
            <button
              onClick={onClose}
              type="button"
              className="btn btn-danger btn-sm float-right btn-add-domain mr-2"
            >
              Close
            </button>
            <CustomButton
              type="button"
              className="btn btn-primary btn-outline-success float-right btn-add-domain"
              disabled={!formik.isValid || formik.isSubmitting}
              onClick={
                formik.isValid && Object.keys(formik.values).length !== 0
                  ? formik.handleSubmit
                  : handleError
              }
            >
              Update
            </CustomButton>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
}

const validation = Yup.object().shape({
  platform_type: Yup.string().required("Select platform type"),
  rule_input_type: Yup.string().required("Select input type"),
  rule_input_id: Yup.string().required("Select input"),
  rule_output_type: Yup.string().required("Select output type"),
  rule_output_id: Yup.string().required("Select output"),
  weightage: Yup.string().required("Enter weightage"),
});
