import { useFormik } from "formik";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { CREATE_PLAN_GQL } from "../../../Apollo/AdminServices/NewMutationRequest";
import graphHandler from "../../../Apollo/graphHandler";
import CustomButton from "../../../components/Button/CustomButton";
import Description from "../../../components/Input/Description";
import MetaInput from "../../../components/MetaInput";
import MultiSelect from "../../../components/MultiSelect/MultiSelect";

export default function CreatePlan({
  refresh = () => {},
  categories = [],
  subDomainList = [],
  setPlan = () => {},
  setLoading = () => {},
  onClose = () => {},
}) {
  const formik = useFormik({
    initialValues: {
      sub_domain_ids: [],
      category_ids: [],
      auto_start: false,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      onSubmit(values);
    },
  });

  const onSubmit = async (value) => {
    formik.setSubmitting(true);
    const {
      sub_domain_ids,
      plan_name,
      auto_start,
      plan_description,
      mobile_description,
      plan_code,
      alias_name,
      category_ids,
      plan_meta,
    } = value;
    let create_request = {
      sub_domain_ids: [...sub_domain_ids.map((x) => x.value)],
      plan_name,
      plan_code,
      alias_name,
      auto_start: Boolean(auto_start),
    };
    if (category_ids && category_ids.length > 0) {
      create_request.category_ids = [...category_ids.map((x) => x.value)];
    }
    let description = [];
    if (plan_description) {
      description.push({
        client_type: "Web",
        description: plan_description,
      });
    }
    if (mobile_description) {
      description.push({
        client_type: "Mobile",
        description: mobile_description,
      });
    }
    if (description.length > 0) create_request.plan_descriptions = description;
    if (plan_meta && plan_meta.length > 0) {
      create_request.plan_meta = plan_meta;
    }
    formik.setSubmitting(true);
    setLoading(true);
    try {
      const { data } = await graphHandler().invoke(
        CREATE_PLAN_GQL,
        { create_request },
        1,
        false
      );
      toast.success("Created Successfully");
      if (data && data.createPlan && data.createPlan.plan) {
        setPlan({
          ...data.createPlan.plan,
          pending_history: data.createPlan.plan_history,
        });
      }
      refresh();
      setLoading(false);
      formik.setSubmitting(false);
    } catch (error) {
      console.log("createPlan error", error);
      setLoading(false);
      formik.setSubmitting(false);
    }
  };
  const handleError = () => {
    if (Object.keys(formik.errors).length > 0) {
      toast.error(formik.errors[Object.keys(formik.errors)[0]]);
    }
  };
  return (
    <div className="p-5 position-relative">
      <div className="row justify-content-center ">
        <div className="col-md-6">
          <div className="row">
            <div className="col-md-12 form-group">
              <label
                htmlFor="sub_domain_ids"
                className="col-form-label w-100 mb-2"
              >
                Sub Domains
              </label>
              <MultiSelect
                option={subDomainList}
                onChange={(e) => formik.setFieldValue("sub_domain_ids", e)}
                placeholder="Select Sub Domain"
                isCreatable={false}
                type="Sub Domain"
                name="sub_domain_ids"
                id="sub_domain_ids"
                onBlur={formik.handleBlur}
                defaultValue={formik.values.sub_domain_ids}
              />
              <small className="text-danger">
                {formik.touched.sub_domain_ids &&
                formik.errors &&
                formik.errors.sub_domain_ids
                  ? formik.errors.sub_domain_ids
                  : ""}
              </small>
            </div>
            <div className="col-md-12 form-group">
              <label
                htmlFor="category_ids"
                className="col-form-label w-100 mb-2"
              >
                Category
              </label>
              <MultiSelect
                option={categories}
                onChange={(e) => formik.setFieldValue("category_ids", e)}
                placeholder="Select category"
                isCreatable={false}
                type="Category"
                name="category_ids"
                id="category_ids"
                onBlur={formik.handleBlur}
                defaultValue={formik.values.category_ids}
              />
              <small className="text-danger align-self-end">
                {formik.touched.category_ids &&
                formik.errors &&
                formik.errors.category_ids
                  ? formik.errors.category_ids
                  : ""}
              </small>
            </div>
            <div className="col-md-12 form-group">
              <label htmlFor="alias_name" className="col-form-label w-100 mb-2">
                Alias Name
              </label>
              <input
                type="text"
                className="form-control"
                id="alias_name"
                name="alias_name"
                value={
                  formik.values && formik.values.alias_name
                    ? formik.values.alias_name
                    : ""
                }
                onChange={(e) =>
                  formik.setFieldValue("alias_name", e.target.value.trimStart())
                }
                // onBlur={formik.handleBlur}
                onBlur={(e) => {
                  formik.setFieldTouched("alias_name", true);
                  formik.setFieldValue(
                    "alias_name",
                    e.target.value.replace(/\s+/g, " ").trim()
                  );
                }}
              />
              <small className="text-danger">
                {formik.touched.alias_name &&
                formik.errors &&
                formik.errors.alias_name
                  ? formik.errors.alias_name
                  : ""}
              </small>
            </div>
            <div className="col-md-12 form-group">
              <label htmlFor="plan_code" className="col-form-label w-100 mb-2">
                Code
              </label>
              <input
                {...formik.getFieldProps("plan_code")}
                type="text"
                className="form-control "
                id="plan_code"
                value={formik.values.plan_code ? formik.values.plan_code : ""}
              />
              <small className="text-danger align-self-end">
                {formik.touched.plan_code &&
                formik.errors &&
                formik.errors.plan_code
                  ? formik.errors.plan_code
                  : ""}
              </small>
            </div>
            <div className="col-md-12 form-group">
              <div className="d-flex flex-row">
                <input
                  className="mr-2"
                  type="checkbox"
                  id="auto_start"
                  name="auto_start"
                  onChange={(e) =>
                    formik.setFieldValue("auto_start", e.target.checked)
                  }
                  checked={formik.values.auto_start}
                  onBlur={formik.handleBlur}
                  //   disabled={formik.values.screener_question_id}
                />
                <label className="col-form-label w-100">Auto Start</label>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="row">
            <div className="col-md-12 form-group">
              <label htmlFor="plan_name" className="col-form-label w-100 mb-2">
                Plan Name
              </label>
              <input
                type="text"
                className="form-control"
                id="plan_name"
                name="plan_name"
                // maxLength={30}
                value={formik.values.plan_name ? formik.values.plan_name : ""}
                onChange={(e) =>
                  formik.setFieldValue("plan_name", e.target.value.trimStart())
                }
                onBlur={(e) => {
                  formik.setFieldTouched("plan_name", true);
                  formik.setFieldValue(
                    "plan_name",
                    e.target.value.replace(/\s+/g, " ").trim()
                  );
                }}
              />
              <small className="text-danger align-self-end">
                {formik.touched.plan_name &&
                formik.errors &&
                formik.errors.plan_name
                  ? formik.errors.plan_name
                  : ""}
              </small>
            </div>
            <Description
              formik={formik}
              webName="plan_description"
              showEditor={true}
            />
          </div>
        </div>
        <div className="col-md-12">
          <MetaInput
            formik={formik}
            parentArray="plan_meta"
            typeKey="type"
            titleKey="key"
            valueKey="value"
          />
        </div>
        <div className={"d-flex align-items-end justify-content-end col-md-12"}>
          <button
            onClick={onClose}
            type="button"
            className="btn btn-danger btn-sm float-right btn-add-domain mr-2"
          >
            Close
          </button>
          <CustomButton
            type="button"
            className="btn btn-primary btn-outline-success btn-sm float-right btn-add-domain"
            disabled={
              Object.keys(formik.errors).length !== 0 ||
              !formik.isValid ||
              formik.isSubmitting
            }
            onClick={
              formik.isValid && Object.keys(formik.values).length !== 0
                ? formik.handleSubmit
                : handleError
            }
          >
            Create
          </CustomButton>
        </div>
      </div>
    </div>
  );
}

const validationSchema = Yup.object().shape({
  sub_domain_ids: Yup.array()
    .of(
      Yup.object().shape({
        label: Yup.string(),
        value: Yup.string(),
      })
    )
    .test({
      message: "Select sub domain",
      test: (arr) => {
        return arr && arr.length > 0;
      },
    }),
  plan_name: Yup.string().required("Enter plan name"),
  alias_name: Yup.string(),
  // .min(4, "Name should be min of 4 characters")
  // .max(30, "Name should be maximum of 30 characters")
  plan_description: Yup.string().required("Enter description"),
  mobile_description: Yup.string().required("Enter mobile description"),
  plan_code: Yup.string().required("Enter plan code"),
  category_ids: Yup.array().of(
    Yup.object().shape({
      label: Yup.string(),
      value: Yup.string(),
    })
  ),
  // .test({
  //   message: "Select category",
  //   test: (arr) => {
  //     return arr && arr.length > 0;
  //   },
  // }),
  auto_start: Yup.boolean(),
  plan_meta: Yup.array().of(
    Yup.object().shape({
      type: Yup.string().required("Select a data type"),
      key: Yup.string().trim().required("Enter a title"),
      value: Yup.string().trim().required("Enter a value"),
    })
  ),
});
