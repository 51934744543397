import { useState } from "react";
import { Popconfirm, Switch } from "antd";
import { toast } from "react-toastify";
import useFAQCategoryList from "../../../utils/CustomHooks/useFAQCategoryList";
import FAQCategoryModal from "../modal/FAQCategoryModal";
import { REMOVE_FAQ_CATEGORY_GQL } from "../../../Apollo/AdminServices/MutationRequest";
import graphHandler from "../../../Apollo/graphHandler";

export default function FaqCategoryListItem({
  access = {},
  item = {},
  refresh,
}) {
  const [isEdit, setEdit] = useState(false);
  const { activateCategory } = useFAQCategoryList("");
  const activateItem = async (active) => {
    if (!item.faq_category_name) return;
    const request = {
      active_request: {
        active,
        faq_category_name: item.faq_category_name,
      },
    };
    activateCategory(request, onActivate(active));
  };
  const onActivate = (active) => {
    setTimeout(() => {
      toast.success(
        `${item.faq_category_name} ${
          active ? "Activated" : "Deactivated"
        } Successfully`
      );
      refresh();
    }, 1000);
  };
  const onDelete = async () => {
    try {
      await graphHandler().invoke(
        REMOVE_FAQ_CATEGORY_GQL,
        { faq_category_name: item.faq_category_name },
        1,
        false
      );
      // toast.success("Removed Successfully!");
      refresh();
    } catch (error) {
      console.log("removeFAQcategory error", error);
    }
  };

  return (
    <tr>
      {access.isAdmin && (
        <td>
          <Switch checked={item.active} onChange={activateItem} />
        </td>
      )}
      <td className="col-sm-2">{item.faq_category_name}</td>
      <td>
        <span
          className={`${item.active ? "good" : "bad"} text-center btn btn-sm`}
        >
          {item.active ? "Active" : "Disabled"}
        </span>
      </td>
      {access.canEdit && (
        <>
          <td>
            <button
              type="button"
              className="btn btn-outline-success btn-sm "
              onClick={() => setEdit(true)}
            >
              Edit
            </button>
          </td>
          <td>
          <Popconfirm
            title="Are you sure to delete this FAQ category?"
            onConfirm={onDelete}
            okText="Yes"
            cancelText="No"
          >
            <i className="ml-2 fas fa-trash cursor-pointer"></i>
          </Popconfirm>
          </td>
        </>
      )}

      <FAQCategoryModal
        isOpen={isEdit}
        setOpen={() => setEdit(false)}
        initialValues={item}
        refresh={refresh}
      />
    </tr>
  );
}
