import { useFormik } from "formik";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { CREATE_MODULE_GQL } from "../../../Apollo/AdminServices/MutationRequest";
import graphHandler from "../../../Apollo/graphHandler";
import CustomButton from "../../../components/Button/CustomButton";
import Description from "../../../components/Input/Description";
import MetaInput from "../../../components/MetaInput";
import MultiSelect from "../../../components/MultiSelect/MultiSelect";

const CreateTherapyGroup = ({
  refresh = () => {},
  setLoading = () => {},
  subDomainList = [],
  setGroup,
  therapy,
}) => {
  const { therapy_id } = therapy;
  const formik = useFormik({
    initialValues: {
      sub_domain_ids: [],
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      onSubmit(values);
    },
  });

  const onSubmit = async (value) => {
    formik.setSubmitting(true);
    const {
      sub_domain_ids,
      therapy_module_name,
      therapy_module_description,
      sort_order,
      therapy_module_meta,
      mobile_description,
      min_completion_days,
      max_completion_days,
      start_interval_seconds,
      therapy_module_code,
    } = value;
    let create_request = {
      therapy_id,
      sub_domain_ids: [...sub_domain_ids.map((x) => x.value)],
      therapy_module_name,
      therapy_module_code,
      sort_order: Number(sort_order),
      min_completion_days: Number(min_completion_days),
      max_completion_days: Number(max_completion_days),
    };
    if (start_interval_seconds) {
      create_request.start_interval_seconds =
        Number(start_interval_seconds) * 86400;
    }
    let description = [];
    if (therapy_module_description) {
      description.push({
        client_type: "Web",
        description: therapy_module_description,
      });
    }
    if (mobile_description) {
      description.push({
        client_type: "Mobile",
        description: mobile_description,
      });
    }
    if (description.length > 0)
      create_request.therapy_module_description = description;

    if (therapy_module_meta && therapy_module_meta.length > 0) {
      create_request.therapy_module_meta = therapy_module_meta;
    }
    formik.setSubmitting(true);
    setLoading(true);
    // console.log("create_request",create_request)
    try {
      const {data}=await graphHandler().invoke(
        CREATE_MODULE_GQL,
        { create_request },
        1,
        false
      );
      if (data && data.createTherapyModule && data.createTherapyModule.therapy_module) {
        setGroup({
          ...data.createTherapyModule.therapy_module,
          pending_history: data.createTherapyModule.therapy_module_history,
        });
      }
      refresh();
      setLoading(false);
      toast.success("Created Successfully");
      formik.setSubmitting(false);
      resetModal();
    } catch (error) {
      console.log("createPlanGroup error", error);
      setLoading(false);
      formik.setSubmitting(false);
    }
  };
  const handleError = () => {
    if (Object.keys(formik.errors).length > 0) {
      toast.error(formik.errors[Object.keys(formik.errors)[0]]);
    }
  };

  const handleNumber = (e) => {
    const { name, value } = e.target;
    const re = /^[0-9\b]+$/;
    let temp = !value ? value : re.test(value) ? value : formik.values[name];
    formik.setFieldValue(name, temp ? temp.substring(0, 10) : temp);
  };

  const resetModal = () => {
    formik.resetForm();
    formik.setValues({});
    formik.setSubmitting(false);
  };

  return (
    <>
      <div className="col-md-12 modal-header" id="CreateTopic">
        <h5 className="modal-title text-center mb-3">
          {formik.values.therapy_module_id ? "Update" : "Create"} Modules
        </h5>
      </div>
      <div className="col-md-12">
        <div className="  border">
          <div className="row p-3">
            <div className="col-md-6">
              <div className="row">
                <div className="col-md-12 form-group">
                  <label
                    htmlFor="sub_domain_ids"
                    className="col-form-label w-100 mb-2"
                  >
                    Sub Domains
                  </label>
                  <MultiSelect
                    option={subDomainList}
                    onChange={(e) => formik.setFieldValue("sub_domain_ids", e)}
                    placeholder="Select Sub Domain"
                    isCreatable={false}
                    type="Sub Domain"
                    name="sub_domain_ids"
                    id="sub_domain_ids"
                    onBlur={formik.handleBlur}
                    defaultValue={formik.values.sub_domain_ids || "Reset"}
                  />
                  <small className="text-danger">
                    {formik.touched.sub_domain_ids &&
                    formik.errors &&
                    formik.errors.sub_domain_ids
                      ? formik.errors.sub_domain_ids
                      : ""}
                  </small>
                </div>
                <div className="col-md-6 form-group">
                  <label
                    htmlFor="therapy_module_name"
                    className="col-form-label w-100 mb-2"
                  >
                    Module Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="therapy_module_name"
                    name="therapy_module_name"
                    onChange={formik.handleChange}
                    // maxLength={30}
                    value={
                      formik.values.therapy_module_name
                        ? formik.values.therapy_module_name
                        : ""
                    }
                    onBlur={formik.handleBlur}
                  />
                  <small className="text-danger align-self-end">
                    {formik.touched.therapy_module_name &&
                    formik.errors &&
                    formik.errors.therapy_module_name
                      ? formik.errors.therapy_module_name
                      : ""}
                  </small>
                </div>
                <div className="col-md-6 form-group">
                  <label
                    htmlFor="therapy_module_code"
                    className="col-form-label w-100 mb-2"
                  >
                    Code
                  </label>
                  <input
                    {...formik.getFieldProps("therapy_module_code")}
                    type="text"
                    className="form-control "
                    id="therapy_module_code"
                    value={
                      formik.values.therapy_module_code
                        ? formik.values.therapy_module_code
                        : ""
                    }
                  />
                  <small className="text-danger align-self-end">
                    {formik.touched.therapy_module_code &&
                    formik.errors &&
                    formik.errors.therapy_module_code
                      ? formik.errors.therapy_module_code
                      : ""}
                  </small>
                </div>
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-md-12 form-group mb-0">
                      <label
                        htmlFor="min_completion_days"
                        className="col-form-label w-100 mb-2"
                      >
                        Completion Days
                      </label>
                      <div className="row">
                        <div className="col-md-6 form-group">
                          <label
                            htmlFor="min_completion_days"
                            className="col-form-label w-100 mb-2"
                          >
                            Minimum
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="min_completion_days"
                            name="min_completion_days"
                            onChange={handleNumber}
                            maxLength={10}
                            value={
                              formik.values.min_completion_days
                                ? formik.values.min_completion_days
                                : ""
                            }
                            onBlur={formik.handleBlur}
                          />
                          <small className="text-danger align-self-end">
                            {formik.touched.min_completion_days &&
                            formik.errors &&
                            formik.errors.min_completion_days
                              ? formik.errors.min_completion_days
                              : ""}
                          </small>
                        </div>
                        <div className="col-md-6 form-group">
                          <label
                            htmlFor="max_completion_days"
                            className="col-form-label w-100 mb-2"
                          >
                            Maximum
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="max_completion_days"
                            name="max_completion_days"
                            onChange={handleNumber}
                            maxLength={10}
                            value={
                              formik.values.max_completion_days
                                ? formik.values.max_completion_days
                                : ""
                            }
                            onBlur={formik.handleBlur}
                          />
                          <small className="text-danger align-self-end">
                            {formik.touched.max_completion_days &&
                            formik.errors &&
                            formik.errors.max_completion_days
                              ? formik.errors.max_completion_days
                              : ""}
                          </small>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-6 form-group">
                          <label
                            htmlFor="start_interval_seconds"
                            className="col-form-label w-100 mb-2"
                          >
                            Interval (in days)
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="start_interval_seconds"
                            name="start_interval_seconds"
                            onChange={handleNumber}
                            maxLength={10}
                            value={
                              formik.values.start_interval_seconds
                                ? formik.values.start_interval_seconds
                                : ""
                            }
                            onBlur={formik.handleBlur}
                          />
                          <small className="text-danger align-self-end">
                            {formik.touched.start_interval_seconds &&
                            formik.errors &&
                            formik.errors.start_interval_seconds
                              ? formik.errors.start_interval_seconds
                              : ""}
                          </small>
                        </div>
                        <div className="col-md-6 form-group">
                          <label
                            htmlFor="sort_order"
                            className="col-form-label w-100 mb-2"
                          >
                            Sort Order
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="sort_order"
                            name="sort_order"
                            onChange={handleNumber}
                            maxLength={10}
                            value={
                              formik.values.sort_order
                                ? formik.values.sort_order
                                : ""
                            }
                            onBlur={formik.handleBlur}
                          />
                          <small className="text-danger align-self-end">
                            {formik.touched.sort_order &&
                            formik.errors &&
                            formik.errors.sort_order
                              ? formik.errors.sort_order
                              : ""}
                          </small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="row">
                <Description
                  formik={formik}
                  showEditor={true}
                  webName="therapy_module_description"
                />{" "}
              </div>
            </div>

            <div className="col-md-12">
              <MetaInput
                formik={formik}
                parentArray="therapy_module_meta"
                typeKey="type"
                titleKey="key"
                valueKey="value"
              />
            </div>
            <div
              className={"d-flex align-items-end justify-content-end col-md-12"}
            >
              {formik.values.therapy_module_id && (
                <button
                  onClick={resetModal}
                  type="button"
                  className="btn btn-light btn-sm float-right btn-add-domain mr-2"
                >
                  Reset
                </button>
              )}
              <CustomButton
                type="button"
                className="btn btn-primary btn-outline-success btn-sm float-right btn-add-domain"
                disabled={
                  Object.keys(formik.errors).length !== 0 ||
                  !formik.isValid ||
                  formik.isSubmitting
                }
                onClick={
                  formik.isValid && Object.keys(formik.values).length !== 0
                    ? formik.handleSubmit
                    : handleError
                }
              >
                {formik.values.therapy_module_id ? "Update" : "Create"}
              </CustomButton>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default CreateTherapyGroup;

const validationSchema = Yup.object().shape({
  sub_domain_ids: Yup.array()
    .of(
      Yup.object().shape({
        label: Yup.string(),
        value: Yup.string(),
      })
    )
    .test({
      message: "Select sub domain",
      test: (arr) => {
        return arr && arr.length > 0;
      },
    }),
  therapy_module_name: Yup.string().required("Enter a name"),
  therapy_module_code: Yup.string().required("Enter a code"),
  therapy_module_description: Yup.string().required("Enter a Description"),
  mobile_description: Yup.string().required("Enter mobile description"),
  sort_order: Yup.string().required("Enter sort order"),
  min_completion_days: Yup.string().required("Enter min completion days"),
  max_completion_days: Yup.string().required("Enter max completion days")
  .when('min_completion_days', (min_completion_days, schema) => {
    return schema.test({
      test: max_completion_days => !!min_completion_days && Number(max_completion_days) > Number(min_completion_days),
      message: "Max days should be > min days"
    })
  }),
  start_interval_seconds: Yup.string().required("Enter start interval"),
  therapy_module_meta: Yup.array().of(
    Yup.object().shape({
      type: Yup.string().required("Select a type"),
      key: Yup.string().required("Enter a key"),
      value: Yup.string().required("Enter a value"),
    })
  ),
});
