import { useEffect } from "react";
import { Modal, ModalBody } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import graphHandler from "../../../Apollo/graphHandler";
import CustomButton from "../../../components/Button/CustomButton";
import { transactionType } from "../../../constants";
import { ADD_LICENSE_TRANSACTION } from "../../../Apollo/ApplicationService/OrganizationResponse";

export default function AddTransaction({
  isOpen,
  setOpen,
  refresh,
  initialValue = {},
}) {
  const formik = useFormik({
    initialValues: {},
    validationSchema: validation,
    onSubmit: (values) => {
      onSubmit(values);
    },
  });

  useEffect(() => {
    if (initialValue.organization_license_id) {
      const value = {
        organization_license_id: initialValue.organization_license_id,
        organization_id: initialValue.organization_id,
      };
      formik.setValues(value);
    }
  }, [initialValue, isOpen]);

  const onClose = () => {
    formik.resetForm();
    setOpen();
  };

  const onSubmit = async (values) => {
    const {
      organization_license_id,
      organization_id,
      transaction_type,
      comment,
      amount,
      cgst,
      igst,
      sgst,
      vat,
    } = values;
    const addReq = {
      organization_license_id,
      organization_id,
      transaction_type,
      amount: Number(amount),
    };
    if (cgst) {
      addReq.cgst = Number(cgst);
    }
    if (igst) {
      addReq.igst = Number(igst);
    }
    if (sgst) {
      addReq.sgst = Number(sgst);
    }
    if (vat) {
      addReq.vat = Number(vat);
    }
    if (comment) {
      addReq.comment = comment;
    }
    try {
      await graphHandler().invoke(
        ADD_LICENSE_TRANSACTION,
        { addReq },
        2,
        true
      );
      refresh();
      onClose();
      formik.setSubmitting(false);
    } catch (error) {
      console.log("UpdateCountry error", error);
      formik.setSubmitting(false);
    }
  };

  const handleError = () => {
    if (Object.keys(formik.errors).length > 0) {
      toast.error(formik.errors[Object.keys(formik.errors)[0]]);
    }
  };

  return (
    <Modal toggle={onClose} isOpen={isOpen} centered scrollable size="xl">
      <div className="modal-header">
        <button type="button" className="close" onClick={onClose}>
          <span aria-hidden="true">&times;</span>
        </button>
        <h5 className="modal-title">Add Transaction</h5>
      </div>
      <ModalBody className="pt-0">
        <div className="container">
          <div className="intervention-form justify-content-center row">
            <div className="col-md-12 form-group">
              <label
                htmlFor="transaction_type"
                className="col-form-label w-100 mb-2"
              >
                Transaction Type
              </label>
              <select
                id="transaction_type"
                name="transaction_type"
                className="form-control"
                onChange={formik.handleChange}
                value={formik.values.transaction_type}
                onBlur={formik.handleBlur}
              >
                <option key="Select" hidden>
                  Select
                </option>
                {transactionType.map((x, i) => (
                  <option value={x.value} key={i}>
                    {x.label}
                  </option>
                ))}
              </select>
              <small className="text-danger">
                {formik.touched.transaction_type &&
                formik.errors &&
                formik.errors.transaction_type
                  ? formik.errors.transaction_type
                  : ""}
              </small>
            </div>
            <div className="col-md-12 form-group ">
              <label htmlFor="amount" className="col-form-label w-100 mb-2">
                Amount
              </label>
              <input
                type="text"
                className="form-control"
                id="amount"
                name="amount"
                value={
                  formik.values && formik.values.amount
                    ? formik.values.amount
                    : ""
                }
                onChange={(e) =>
                  formik.setFieldValue(
                    "amount",
                    e.target.value.replace(/[^0-9+]/gi, "")
                  )
                }
                onBlur={formik.handleBlur}
              />
              <small className="text-danger">
                {formik.touched.amount && formik.errors && formik.errors.amount
                  ? formik.errors.amount
                  : ""}
              </small>
            </div>

            <div className="col-md-12">
              <div className="row">
                <div className="col-md-6 form-group ">
                  <label htmlFor="cgst" className="col-form-label w-100 mb-2">
                    CGST
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="cgst"
                    name="cgst"
                    value={
                      formik.values && formik.values.cgst
                        ? formik.values.cgst
                        : ""
                    }
                    onChange={(e) =>
                      formik.setFieldValue(
                        "cgst",
                        e.target.value.replace(/[^0-9+]/gi, "")
                      )
                    }
                    onBlur={formik.handleBlur}
                  />
                  <small className="text-danger">
                    {formik.touched.cgst && formik.errors && formik.errors.cgst
                      ? formik.errors.cgst
                      : ""}
                  </small>
                </div>
                <div className="col-md-6 form-group ">
                  <label htmlFor="igst" className="col-form-label w-100 mb-2">
                    IGST
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="igst"
                    name="igst"
                    value={
                      formik.values && formik.values.igst
                        ? formik.values.igst
                        : ""
                    }
                    onChange={(e) =>
                      formik.setFieldValue(
                        "igst",
                        e.target.value.replace(/[^0-9+]/gi, "")
                      )
                    }
                    onBlur={formik.handleBlur}
                  />
                  <small className="text-danger">
                    {formik.touched.igst && formik.errors && formik.errors.igst
                      ? formik.errors.igst
                      : ""}
                  </small>
                </div>
                <div className="col-md-6 form-group ">
                  <label htmlFor="sgst" className="col-form-label w-100 mb-2">
                    SGST
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="sgst"
                    name="sgst"
                    value={
                      formik.values && formik.values.sgst
                        ? formik.values.sgst
                        : ""
                    }
                    onChange={(e) =>
                      formik.setFieldValue(
                        "sgst",
                        e.target.value.replace(/[^0-9+]/gi, "")
                      )
                    }
                    onBlur={formik.handleBlur}
                  />
                  <small className="text-danger">
                    {formik.touched.sgst && formik.errors && formik.errors.sgst
                      ? formik.errors.sgst
                      : ""}
                  </small>
                </div>
                <div className="col-md-6 form-group ">
                  <label htmlFor="vat" className="col-form-label w-100 mb-2">
                    VAT
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="vat"
                    name="vat"
                    value={
                      formik.values && formik.values.vat
                        ? formik.values.vat
                        : ""
                    }
                    onChange={(e) =>
                      formik.setFieldValue(
                        "vat",
                        e.target.value.replace(/[^0-9+]/gi, "")
                      )
                    }
                    onBlur={formik.handleBlur}
                  />
                  <small className="text-danger">
                    {formik.touched.vat && formik.errors && formik.errors.vat
                      ? formik.errors.vat
                      : ""}
                  </small>
                </div>
              </div>
            </div>
            <div className="col-md-12 form-group">
              <label htmlFor="comment" className="col-form-label w-100 mb-2">
                Comments
              </label>
              <textarea
                rows="3"
                className="form-control"
                type="text"
                id="comment"
                name="comment"
                onChange={formik.handleChange}
                value={formik.values.comment}
                onBlur={formik.handleBlur}
              />
              <small className="text-danger">
                {formik.touched.comment &&
                formik.errors &&
                formik.errors.comment
                  ? formik.errors.comment
                  : ""}
              </small>
            </div>

            <div className="d-flex align-items-end justify-content-end col-md-12 mt-3">
              <button
                onClick={onClose}
                type="button"
                className="btn btn-danger btn-sm float-right btn-add-domain mr-2"
              >
                Close
              </button>
              <CustomButton
                type="button"
                className="btn btn-primary btn-outline-success btn-sm float-right btn-add-domain"
                disabled={
                  Object.keys(formik.errors).length !== 0 ||
                  !formik.isValid ||
                  formik.isSubmitting
                }
                onClick={
                  formik.isValid && Object.keys(formik.values).length !== 0
                    ? formik.handleSubmit
                    : handleError
                }
              >
                {"Create"}
              </CustomButton>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
}

const validation = Yup.object().shape({
  transaction_type: Yup.string().required("Select transaction type"),
  amount: Yup.string().required("Enter amount"),
  cgst: Yup.string(),
  igst: Yup.string(),
  sgst: Yup.string(),
  vat: Yup.string(),
});
