import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import * as Yup from "yup";
import CreateMediaFileUpload from "./CreateMediaFileUpload";
import { MediaDevice, ImageFormat } from "../../constants";
import HistoryImageTableItem from "../HistoryEditInput/Components/HistoryImageTableItem";

const SingleImageUpload = (props) => {
  const [isView, setVisible] = useState(true);

  let {
    formik,
    heading,
    contentId = "",
    contentType = "",
    parentArray,
    mediaType = "Image",
    documentCol = "col-md-6",
  } = props;
  const formik1 = useFormik({
    initialValues: {},
    validationSchema: Yup.object().shape({
      is_mobile: Yup.boolean().required("Select a device"),
      image_type: Yup.string().required("Select a format"),
      mediaUrl: Yup.object().shape({
        url: Yup.string(),
      }).required("upload a file"),
    }),
    onSubmit: (values) => onSubmit(values),
  });

  useEffect(() => {
    setVisible(
      Boolean(
        parentArray &&
          formik.values[parentArray] &&
          formik.values[parentArray].length > 0
      )
    );
  }, [formik.values, parentArray]);

  const onSubmit = (values = {}) => {
    // values = {...values,index: values.i,}
    let final = {
      image_type: values.image_type,
      url: values.mediaUrl.url,
      is_mobile: `${values.is_mobile}` === "true" ? true : false,
    };
    let media_data = [
      ...(formik.values[parentArray] ? formik.values[parentArray] : []).map(
        (x) => x
      ),
    ];
    // if (!values.is_mobile || !values.image_type)
    //   return console.error("Data Not found", values);
    if (Number.isInteger(values.index) && media_data.length > values.index) {
      delete final.index;
      media_data[values.index] = { ...final, index: values.index };
      formik.setFieldValue(parentArray, [...media_data]);
      //   console.log()
      reset();
    } else {
      delete final.index;
      formik.setFieldValue(parentArray, [
        ...media_data,
        { ...final, index: media_data.length },
      ]);
      reset();
    }
    setVisible(false);
  };

  const onEdit = (values = {}) => {
    setVisible(true);
    formik1.setValues({
      ...values,
      index: values.i,
      mediaUrl: {
        url: values.url,
      },
    });
    const container = document.getElementById("mediaSection" + parentArray);
    if (container) container.scrollIntoView({ behavior: "smooth" });
  };

  const handleError = () => {
    if (Object.keys(formik1.errors).length > 0) {
      toast.error(formik1.errors[Object.keys(formik1.errors)[0]]);
    }
  };

  const remove = (i) => {
    let media_data = [
      ...(formik.values[parentArray] ? formik.values[parentArray] : []).map(
        (x) => x
      ),
    ];
    media_data.splice(i, 1);
    formik.setFieldValue(parentArray, media_data);
  };

  const reset = () => {
    formik1.resetForm();
    formik1.setTouched({});
  };
//   console.log(" formik1.values", formik1.values);
  return (
    <div className="col-md-12 form-group" id={"mediaSection" + parentArray}>
      <div className="row mb-2">
        <div style={{ display: "flex", alignItems: "center" }}>
          <label className="col-form-label w-100">{heading} </label>
          {!isView && (
            <button
              className="btn btn-outline-success ml-3 btn-sm"
              disabled={props.disabled}
              onClick={() => setVisible(true)}
            >
              Add Image
            </button>
          )}
        </div>
      </div>
      {formik.values[parentArray] && formik.values[parentArray].length > 0 ? (
        <div className="row ">
          <div
            className="table-responsive mb-3 table-borderless  questionnaire-table"
            style={{ maxHeight: "200px" }}
          >
            <table className="table  table-striped mb-0">
              <thead>
                <tr>
                  <th className="col-sm-1">#</th>
                  <th className="col-sm-2">File</th>
                  <th className="col-sm-3">Name</th>
                  <th className="col-sm-2">Device</th>
                  <th className="col-sm-2">Format</th>
                  <th className="col-sm-2">Action</th>
                </tr>
              </thead>
              <tbody>
                {formik.values[parentArray]
                  .slice()
                  .sort((a, b) => a.sort_order - b.sort_order)
                  .map((x, i) => (
                    <HistoryImageTableItem
                      key={i}
                      arrayIndex={i}
                      item={x}
                      {...props}
                      remove={remove}
                      onEdit={onEdit}
                    />
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      ) : null}
      {isView && !props.disabled && (
        <div className="row px-3 pt-3 position-relative rounded-4 shadow-common">
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-6">
                <div htmlFor="is_mobile" className="col-form-label w-100 mb-2">
                  <select
                    className="custom-select"
                    id="is_mobile"
                    name="is_mobile"
                    onChange={formik1.handleChange}
                    onBlur={formik1.handleBlur}
                    value={formik1.values.is_mobile}
                  >
                    <option key="Select" hidden>
                      Select a device
                    </option>
                    {MediaDevice.map((x, i) => (
                      <option value={x.value} key={i}>
                        {x.label}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-md-6">
                <div htmlFor="image_type" className="col-form-label w-100 mb-2">
                  <select
                    className="custom-select"
                    id="image_type"
                    name="image_type"
                    onChange={formik1.handleChange}
                    onBlur={formik1.handleBlur}
                    value={formik1.values.image_type}
                  >
                    <option key="Select" hidden>
                      Select a format
                    </option>
                    {ImageFormat.map((x, i) => (
                      <option value={x.value} key={i}>
                        {x.label}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              {formik1.values.image_type ? (
                <div className={documentCol}>
                  <div
                    htmlFor="media_upload"
                    className="col-form-label w-100 mb-2"
                  >
                    <CreateMediaFileUpload
                      id="imageUrl"
                      name="mediaUrl"
                      formik={formik1}
                      mediaType={mediaType}
                      contentId={contentId}
                      contentType={contentType}
                      format={formik1.values.image_type}
                    />
                  </div>
                </div>
              ) : null}
              <div className="col-md-12 d-flex align-items-end justify-content-end mb-5">
                {Number.isInteger(formik1.values.index) ? (
                  <button className="btn btn-light btn-sm mr-3" onClick={reset}>
                    Reset
                  </button>
                ) : null}
                <button
                  className="btn btn-primary btn-outline-success btn-sm"
                  onClick={
                    formik1.isValid && Object.keys(formik1.values).length !== 0
                      ? formik1.handleSubmit
                      : handleError
                  }
                  disabled={Object.keys(formik1.errors).length !== 0 || !formik1?.values?.mediaUrl?.url}
                >
                  {!Number.isInteger(formik1.values.index) ? "Save" : "Update"}
                </button>
              </div>
            </div>
          </div>
          <button
            type="button"
            className="btn btn-link position-absolute"
            style={{ top: "0px", right: "0px" }}
            onClick={() => {
              setVisible(false);
              reset();
            }}
          >
            <i className="fas fa-times"></i>
          </button>
        </div>
      )}
    </div>
  );
};

export default SingleImageUpload;
