import { useState } from "react";
import { GET_AUTHOR_LIST_GQL } from "../../Apollo/AdminServices/QueryRequest";
import graphHandler from "../../Apollo/graphHandler";

function useAuthorList(preLoad = false) {
  const [authorList, setAuthorList] = useState([]);
  const [loading, setLoading] = useState(preLoad);

  const getAuthorList = async (req = {}) => {
    setLoading(true);
    try {
      const { data } = await graphHandler().invoke(
        GET_AUTHOR_LIST_GQL,
        { ...req },
        1,
        false,
        true
      );
      if (
        data &&
        data.getAuthorList &&
        data.getAuthorList.authors &&
        data.getAuthorList.authors.length > 0
      ) {
        // console.log("getAuthorList Success", data.getAuthorList);
        setAuthorList([]);
        setAuthorList(data.getAuthorList.authors);
        setLoading(false);
      } else {
        setAuthorList([]);
        setLoading(false);
      }
    } catch (error) {
      console.error("getAuthorList", error);
      setAuthorList([]);
      setLoading(false);
    }
  };

  return {
    authorList,
    loading,
    getAuthorList,
  };
}

export default useAuthorList;
