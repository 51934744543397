import { useDispatch, useSelector } from "react-redux";
import { Navbar, NavbarBrand, NavbarText } from "reactstrap";
import { rLogout } from "../../redux/Auth/action";
import { getNames } from "../../utils/Functions/filter";
import ReadMore from "../../components/ReadMore";
import logo from "../../assets/logo.png";
import { useHistory } from "react-router-dom";

const Header = () => {
  const user = useSelector(({ Auth }) => Auth.user);
  const roles = useSelector(({ Auth }) => Auth.roles);
  const dispatch = useDispatch();
  const history = useHistory();
  return (
    <Navbar color="#FFFFFF" light expand="md" style={{ zIndex: 100 }}>
      <NavbarBrand href="/#/home">
        <img src={logo} alt="" />
      </NavbarBrand>
      <NavbarText className="p-0">
        <div className="d-flex align-items-center m-0">
          <i
            className="fas fa-user mr-3 cursor-pointer"
            style={{ fontSize: "20px" }}
            onClick={() => history.push("/profile")}
          ></i>
          <i
            className="fas fa-sign-out-alt mr-3 cursor-pointer"
            style={{ fontSize: "20px" }}
            onClick={() => dispatch(rLogout())}
          ></i>
          <ul className=" text-small m-0" style={{ listStyleType: "none" }}>
            <li>
              <h2 className=" text-decoration-none list user-name m-0">
                <b>{user.name || "Loading"}</b>
              </h2>
            </li>
            <li>
              <small className=" text-decoration-none user-role m-0">
                <ReadMore maxLength="45" canShow={false}>
                  {roles && roles.length
                    ? roles.map(
                        (x, i) =>
                          getNames(x.role_type) +
                          (i + 1 == roles.length ? "" : " ")
                      )
                    : "Admin"}
                </ReadMore>
              </small>
            </li>
          </ul>
        </div>
      </NavbarText>
    </Navbar>
  );
};

export default Header;
