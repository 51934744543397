import { useEffect, useState } from "react";
import { GET_THERAPY_GQL } from "../../Apollo/AdminServices/QueryRequest";
import graphHandler from "../../Apollo/graphHandler";

function useTherapy(id) {
  const [therapy, setTherapy] = useState({});
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (id) {
      getTherapy(id);
    } else {
      setTherapy({});
    }
  }, [id]);

  const getTherapy = async (therapy_id) => {
    if (!therapy_id) return;
    setLoading(true);
    try {
      const { data } = await graphHandler().invoke(
        GET_THERAPY_GQL,
        { therapy_id },
        1,
        false,
        true
      );
      if (data && data.getTherapy && data.getTherapy.therapy) {
        // console.log("getTherapy Success", data.getTherapy);
        setTherapy({});
        setTherapy(data.getTherapy.therapy);
        setLoading(false);
      } else {
        setTherapy({});
        setLoading(false);
      }
    } catch (error) {
      console.log("getTherapy Error", error);
      setTherapy({});
      setLoading(false);
    }
  };

  return {
    therapy,
    loading,
    getTherapy,
  };
}

export default useTherapy;
