import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { MetaDataType, SubDomainMetaDataType } from "../../constants";
import EditorModal from "../Modal/EditorModal";
import MetaTableItem from "./MetaTableItem";

export default function MetaInput(props) {
  const [isOpen, setOpen] = useState(false);
  const [isView, setVisible] = useState(true);

  let {
    constType = "Instruction",
    formik = {},
    parentArray = "meta_datas",
    typeKey = "meta_data_type",
    titleKey = "meta_data_title",
    valueKey = "meta_data_value",
    heading = "Meta",
    metaType = "",
    canEdit = true
  } = props;

  const defaultValue = {
    [typeKey]: metaType==="Subdomain"?"ReportDescription":"Instruction",
    [titleKey]: "",
    [valueKey]: "",
  };
  const formik1 = useFormik({
    initialValues: {
      ...defaultValue,
    },
    validationSchema: Yup.object().shape({
      [typeKey]: Yup.string().required("Select a data type"),
      [titleKey]: Yup.string().trim().required("Enter a key"),
      [valueKey]: Yup.string().trim().required("Enter a value"),
    }),
    onSubmit: (values) => onSubmit(values),
  });
  useEffect(() => {
    setVisible(
      Boolean(
        parentArray &&
          formik.values[parentArray] &&
          formik.values[parentArray].length > 0
      )
    );
  }, [formik.values, parentArray]);

  const onSubmit = (values = {}) => {
    let modal = { ...values };
    let meta_data = [
      ...(formik.values[parentArray] ? formik.values[parentArray] : []).map(
        (x) => x
      ),
    ];
    if (!values[typeKey] || !values[titleKey] || !values[valueKey])
      return console.error("Data Not found", values);
    if (Number.isInteger(values.index) && meta_data.length > values.index) {
      delete modal.index;
      meta_data[values.index] = { ...modal };
      formik.setFieldValue(parentArray, [...meta_data]);
      reset();
    } else {
      delete modal.index;
      formik.setFieldValue(parentArray, [...meta_data, { ...modal }]);
      reset();
    }
  };

  const remove = (i) => {
    if (props.disabled === true) {
      return;
    }
    const meta_data = [
      ...(formik.values[parentArray] ? formik.values[parentArray] : []).map(
        (x) => x
      ),
    ];
    meta_data.splice(i, 1);
    formik.setFieldValue(parentArray, meta_data);
  };

  const onEdit = (values = {}) => {
    if (props.disabled === true) {
      return;
    }
    setVisible(true);
    formik1.setValues({
      ...values,
    });
    const container = document.getElementById("metaSection" + parentArray);
    if (container) container.scrollIntoView({ behavior: "smooth" });
  };

  const handleError = () => {
    if (Object.keys(formik1.errors).length > 0) {
      toast.error(formik1.errors[Object.keys(formik1.errors)[0]]);
    }
  };

  const reset = () => {
    formik1.setValues({ [typeKey]:  metaType==="Subdomain"? "ReportDescription":"Instruction" });
    formik1.setTouched({});
  };

  return (
    <div className="col-md-12 form-group" id={"metaSection" + parentArray}>
      <EditorModal
        isOpen={isOpen}
        defaultValue={formik1.values[valueKey] ? formik1.values[valueKey] : ""}
        close={() => setOpen(false)}
        save={(e) => {
          formik1.setFieldValue(valueKey, e);
        }}
        disabled={props.disabled}
      />
      <div className="row mb-2">
        <div style={{ display: "flex", alignItems: "center" }}>
          <label className="col-form-label">{heading} </label>
          {!isView && !props.disabled && (
            <button
              className="btn btn-outline-success ml-3 btn-sm"
              onClick={() => setVisible(true)}
              disabled={!canEdit&&true}
            >
              Add Meta
            </button>
          )}
        </div>
      </div>
      {formik.values[parentArray] && formik.values[parentArray].length > 0 ? (
        <div className="row ">
          <div
            className="table-responsive mb-3 table-borderless  questionnaire-table"
            style={{ maxHeight: "200px" }}
          >
            <table className="table  table-striped mb-0">
              <thead>
                <tr>
                  {metaType === "Subdomain" && (
                    <th className="col-sm-3">Type</th>
                  )}
                  <th className="col-sm-3">Key</th>
                  <th className="col-sm-5">Value</th>
                  <th className="col-sm-2">Action</th>
                </tr>
              </thead>
              <tbody>
                {formik.values[parentArray]
                  .slice()
                  .sort((a, b) => a.sort_order - b.sort_order)
                  .map((x, i) => (
                    <MetaTableItem
                      key={i}
                      index={i}
                      item={x}
                      {...props}
                      remove={remove}
                      onEdit={onEdit}
                      metaType={metaType}
                      canEdit={canEdit}
                    />
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      ) : null}
      {isView && !props.disabled && (
        <div className="row px-3 pt-3 position-relative rounded-4 shadow-common">
          <div className="col-md-4">
            <div className="row">
              {!constType && (
                <div className="col-md-12 form-group">
                  <label
                    htmlFor={typeKey}
                    className="col-form-label w-100 mb-2"
                  >
                    Type
                  </label>
                  <select
                    className="custom-select"
                    name={typeKey}
                    id={typeKey}
                    onChange={formik1.handleChange}
                    value={
                      formik1.values[typeKey] ? formik1.values[typeKey] : ""
                    }
                    onBlur={formik1.handleBlur}
                    disabled={props.disabled}
                  >
                    <option key="Select" hidden>
                      Select
                    </option>
                    {MetaDataType.map((x, i) => (
                      <option value={x} key={i}>
                        {x}
                      </option>
                    ))}
                  </select>
                  <small className="text-danger col-md-12">
                    {formik1.touched[typeKey] && formik1.errors[typeKey]
                      ? formik1.errors[typeKey]
                      : ""}
                  </small>
                </div>
              )}
              {metaType === "Subdomain" && (
                <div className="col-md-12 form-group">
                  <label
                    htmlFor={typeKey}
                    className="col-form-label w-100 mb-2"
                  >
                    Type
                  </label>
                  <select
                    className="custom-select"
                    name={typeKey}
                    id={typeKey}
                    onChange={formik1.handleChange}
                    value={
                      formik1.values[typeKey] ? formik1.values[typeKey] : ""
                    }
                    onBlur={formik1.handleBlur}
                    disabled={props.disabled}
                  >
                    <option key="Select" hidden>
                      Select
                    </option>
                    {SubDomainMetaDataType.map((x, i) => (
                      <option value={x} key={i}>
                        {x}
                      </option>
                    ))}
                  </select>
                  <small className="text-danger col-md-12">
                    {formik1.touched[typeKey] && formik1.errors[typeKey]
                      ? formik1.errors[typeKey]
                      : ""}
                  </small>
                </div>
              )}
              <div className="col-md-12 form-group">
                <label htmlFor={titleKey} className="col-form-label w-100 mb-2">
                  Key
                </label>
                <input
                  type="text"
                  className="form-control"
                  name={titleKey}
                  id={titleKey}
                  onChange={formik1.handleChange}
                  placeholder=""
                  value={
                    formik1.values[titleKey] ? formik1.values[titleKey] : ""
                  }
                  onBlur={formik1.handleBlur}
                  disabled={props.disabled}
                />
                <small className="text-danger col-md-12">
                  {formik1.touched[titleKey] && formik1.errors[titleKey]
                    ? formik1.errors[titleKey]
                    : ""}
                </small>
              </div>
            </div>
          </div>
          <div className="col-md-8 ">
            <div className="row">
              <div className="col-md-8 form-group">
                <label htmlFor={valueKey} className="col-form-label w-100 mb-2">
                  Value
                  <button
                    className="btn-link-common float-right"
                    onClick={() => setOpen(true)}
                  >
                    Use Editor
                  </button>
                </label>
                <textarea
                  type="text"
                  className="form-control"
                  name={valueKey}
                  id={valueKey}
                  onChange={formik1.handleChange}
                  placeholder=""
                  value={
                    formik1.values[valueKey] ? formik1.values[valueKey] : ""
                  }
                  onBlur={formik1.handleBlur}
                  disabled={props.disabled}
                />
                <small className="text-danger col-md-12">
                  {formik1.touched[valueKey] && formik1.errors[valueKey]
                    ? formik1.errors[valueKey]
                    : ""}
                </small>
              </div>
              <div className="col-md-4 d-flex align-items-end justify-content-end mb-5">
                {Number.isInteger(formik1.values.index) ? (
                  <button className="btn btn-light btn-sm mr-3" onClick={reset}>
                    Reset
                  </button>
                ) : null}
                <button
                  className="btn btn-primary btn-outline-success btn-sm"
                  onClick={
                    formik1.isValid && Object.keys(formik1.values).length !== 0
                      ? formik1.handleSubmit
                      : handleError
                  }
                  disabled={Object.keys(formik1.errors).length !== 0}
                >
                  {!Number.isInteger(formik1.values.index) ? "Save" : "Update"}
                </button>
              </div>
            </div>
          </div>
          <button
            type="button"
            className="btn btn-link position-absolute"
            style={{ top: "0px", right: "0px" }}
            onClick={() => {
              setVisible(false);
              reset();
            }}
          >
            <i className="fas fa-times"></i>
          </button>
        </div>
      )}
    </div>
  );
}
