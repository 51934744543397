import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import graphHandler from "../../../Apollo/graphHandler";
import CustomButton from "../../../components/Button/CustomButton";
import { CREATE_ORGANIZATION_GQL } from "../../../Apollo/ApplicationService/OrganizationResponse";

export default function AddOrganization({
  refresh = () => {},
  setOrganization = () => {},
  setLoading = () => {},
  onClose = () => {},
}) {
  const formik = useFormik({
    initialValues: {
      email_domains: [],
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      onSubmit(values);
    },
  });

  const onClosed = () => {
    formik.resetForm();
    // setOpen();
    onClose();
    formik.setValues({});
  };

  const onSubmit = async (value) => {
    formik.setSubmitting(true);
    setLoading(true);
    const {
      organization_name,
      organization_email,
      organization_mobile_number,
      domain_name,
      email_domains,
    } = value;
    let create_req = {
      organization_name,
      organization_email,
      // organization_mobile_number,
      domain_name,
      email_domains,
    };
    if (organization_mobile_number) {
      create_req.organization_mobile_number = organization_mobile_number;
    }
    try {
      const { data } = await graphHandler().invoke(
        CREATE_ORGANIZATION_GQL,
        { create_req },
        1,
        true
      );
      if (
        data &&
        data.createOrganization &&
        data.createOrganization.organization
      ) {
        setOrganization(data.createOrganization.organization);
      }
      refresh();
      setLoading(false);
      // onClosed();
      formik.setSubmitting(false);
    } catch (error) {
      console.error("createOrganization", error);
      formik.setSubmitting(false);
      setLoading(false);
    }
  };

  const handleError = () => {
    if (Object.keys(formik.errors).length > 0) {
      toast.error(formik.errors[Object.keys(formik.errors)[0]]);
    }
  };

  const handleFilter = (val) => {
    let data = formik.values?.email_domains.filter((x) => x !== val);
    formik.setFieldValue("email_domains", data);
  };
  return (
    // <Modal toggle={onClosed} isOpen={isOpen} size="lg" centered>
    //   <div className="modal-header">
    //     <button type="button" className="close" onClick={onClose}>
    //       <span aria-hidden="true">&times;</span>
    //     </button>
    //     <h5 className="modal-title">Add Organization</h5>
    //   </div>
    //   <ModalBody>
    <div className="container">
      <div className="intervention-form row">
        <div className="col-md-6 form-group">
          <label
            htmlFor="organization_name"
            className="col-form-label w-100 mb-2"
          >
            Name
          </label>
          <input
            type="text"
            className="form-control"
            id="organization_name"
            name="organization_name"
            // maxLength="30"
            value={
              formik.values && formik.values.organization_name
                ? formik.values.organization_name
                : ""
            }
            onChange={(e) =>
              formik.setFieldValue(
                "organization_name",
                e.target.value.trimStart()
              )
            }
            onBlur={(e) => {
              formik.setFieldTouched("organization_name", true);
              formik.setFieldValue(
                "organization_name",
                e.target.value.replace(/\s+/g, " ").trim()
              );
            }}
          />
          <small className="text-danger">
            {formik.touched.organization_name &&
            formik.errors &&
            formik.errors.organization_name
              ? formik.errors.organization_name
              : ""}
          </small>
        </div>
        <div className="col-md-6 form-group">
          <label
            htmlFor="organization_email"
            className="col-form-label mb-2 w-100"
          >
            Email
          </label>
          <input
            type="text"
            className="form-control"
            name="organization_email"
            maxLength="50"
            value={
              formik.values && formik.values.organization_email
                ? formik.values.organization_email
                : ""
            }
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <small className="text-danger">
            {formik.touched.organization_email &&
            formik.errors &&
            formik.errors.organization_email
              ? formik.errors.organization_email
              : ""}
          </small>
        </div>
        <div className="col-md-6 form-group">
          <label
            htmlFor="organization_mobile_number"
            className="col-form-label mb-2"
          >
            Mobile
          </label>
          <input
            type="text"
            className="form-control"
            name="organization_mobile_number"
            maxLength="10"
            value={
              formik.values && formik.values.organization_mobile_number
                ? formik.values.organization_mobile_number
                : ""
            }
            onChange={(e) =>
              formik.setFieldValue(
                "organization_mobile_number",
                e.target.value.replace(/[^0-9+]/gi, "")
              )
            }
            onBlur={formik.handleBlur}
          />
          <small className="text-danger">
            {formik.touched.organization_mobile_number &&
            formik.errors &&
            formik.errors.organization_mobile_number
              ? formik.errors.organization_mobile_number
              : ""}
          </small>
        </div>

        <div className="col-md-6 form-group">
          <label htmlFor="domain_name" className="col-form-label w-100 mb-2">
            Domain Name
          </label>
          <input
            type="text"
            className="form-control"
            name="domain_name"
            value={
              formik.values && formik.values.domain_name
                ? formik.values.domain_name
                : ""
            }
            onChange={(e) =>
              formik.setFieldValue("domain_name", e.target.value.trimStart())
            }
            onBlur={(e) => {
              formik.setFieldTouched("domain_name", true);
              formik.setFieldValue(
                "domain_name",
                e.target.value.replace(/\s+/g, " ").trim()
              );
            }}
          />
          <small className="text-danger">
            {formik.touched.domain_name &&
            formik.errors &&
            formik.errors.domain_name
              ? formik.errors.domain_name
              : ""}
          </small>
        </div>
        <div className="col-md-10 form-group">
          <label htmlFor="org_domain" className="col-form-label w-100 mb-2">
            Email Domains
          </label>
          <input
            type="text"
            className="form-control"
            name="org_domain"
            value={
              formik.values && formik.values.org_domain
                ? formik.values.org_domain
                : ""
            }
            onChange={(e) =>
              formik.setFieldValue("org_domain", e.target.value.trimStart())
            }
            onBlur={(e) => {
              formik.setFieldTouched("org_domain", true);
              formik.setFieldValue(
                "org_domain",
                e.target.value.replace(/\s+/g, " ").trim()
              );
            }}
          />
        </div>
        <div className="col-md-2 d-flex align-items-center justify-content-center mt-1">
          <CustomButton
            className="btn-sm btn btn-primary btn-outline-success"
            disabled={!formik.values.org_domain}
            onClick={async () => {
              let data = [
                ...formik.values.email_domains,
                formik.values.org_domain,
              ];
              await formik.setFieldValue("email_domains", data);
              formik.setFieldValue("org_domain", "");
            }}
          >
            {" "}
            Add{" "}
          </CustomButton>
        </div>
        <div
          className="col-md-12 form-group d-flex flex-wrap"
          style={{ gap: "0.5rem" }}
        >
          {formik.values?.email_domains &&
            formik.values?.email_domains?.length > 0 && (
              <>
                {formik.values?.email_domains.map((x, i) => (
                  <span className="good text-center btn btn-sm" key={i}>
                    {x}{" "}
                    <span
                      className="badge border border-dark border-5"
                      onClick={() => handleFilter(x)}
                    >
                      X
                    </span>
                  </span>
                ))}
              </>
            )}
        </div>
        <div className="d-flex align-items-end justify-content-end col-md-12 mt-3">
          <button
            onClick={onClosed}
            type="button"
            className="btn btn-danger btn-sm float-right btn-add-domain mr-2"
          >
            Close
          </button>
          <CustomButton
            type="button"
            className="btn btn-primary btn-outline-success btn-sm float-right btn-add-domain"
            disabled={
              Object.keys(formik.errors).length !== 0 ||
              !formik.isValid ||
              formik.isSubmitting
            }
            onClick={
              formik.isValid && Object.keys(formik.values).length !== 0
                ? formik.handleSubmit
                : handleError
            }
          >
            {formik.values.organization_id ? "Update" : "Create"}
          </CustomButton>
        </div>
      </div>
    </div>
    //   </ModalBody>
    // </Modal>
  );
}

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const validationSchema = Yup.object().shape({
  organization_name: Yup.string().required("Enter name"),
  organization_email: Yup.string()
    .email("Invalid email")
    .required("Enter a email"),
  organization_mobile_number: Yup.string()
    // .required("Enter mobile number")
    .matches(phoneRegExp, "Mobile number is not valid"),
  domain_name: Yup.string().required("Domain is required"),
  org_domain: Yup.string(),
  email_domains: Yup.array().test({
    message: "Enter Email Domains",
    test: (arr) => {
      return arr && arr.length > 0;
    },
  }),
});
