import { useEffect, useState } from "react";
import { GET_DOMAIN_GQL } from "../../Apollo/AdminServices/QueryRequest";
import graphHandler from "../../Apollo/graphHandler";

function useDomain(id) {
  const [domain, setDomain] = useState({});
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (id) {
      getDomain(id);
    } else {
      setDomain({});
    }
  }, [id]);

  const getDomain = async (domain_id) => {
    setLoading(true);
    try {
      const { data } = await graphHandler().invoke(
        GET_DOMAIN_GQL,
        { domain_id },
        1,
        false,
        true
      );
      if (data && data.getDomain && data.getDomain.domain) {
        // console.log("getDomain Success", data.getDomain);
        setDomain({});
        setDomain(data.getDomain.domain);
        setLoading(false);
      } else {
        setDomain({});
        setLoading(false);
      }
    } catch (error) {
      console.log("getDomain Error", error);
      setDomain({});
      setLoading(false);
    }
  };

  return {
    domain,
    loading,
    getDomain,
  };
}

export default useDomain;
