import moment from "moment";
export function formatDate(date) {
  return moment(date).format("DD MMM YYYY");
}

export function formatTime(date) {
  return moment(date).format("h:mm a");
}

export function formatDateTime(date) {
  return moment(date).format("D MMMM [at] h:mm A z");
}

export const secondsToTime = (secs) => {
  secs = secs || 0;
  secs = Number(secs);
  const d = Math.floor(secs / (3600 * 24));
  const h = Math.floor((secs % (3600 * 24)) / 3600);
  const m = Math.floor((secs % 3600) / 60);
  const s = Math.floor(secs % 60);
  // var d = Math.floor(secs / (3600*24));
  //   var h = Math.floor(secs / 3600);
  //   var m = Math.floor((secs % 3600) / 60);
  //   var s = Math.floor((secs % 3600) % 60);
  return (
    d +
    (d ? ":" : "") +
    h +
    (h ? ":" : "") +
    parseFloat(m).toFixed(2) +
    (m ? ", " : "") +
    parseFloat(s).toFixed(2)
  );
};

export const secondsToDuration = (secs) => {
  secs = secs || 0;
  secs = Number(secs);
  const d = Math.floor(secs / (3600 * 24));
  const h = Math.floor((secs % (3600 * 24)) / 3600);
  const m = Math.floor((secs % 3600) / 60);
  const s = Math.floor(secs % 60);
  return (
    (d ? d + (" day" + (d > 1 ? "s " : " ")) : "") +
    (h ? h + " hr " : "") +
    (m ? m + " min " : "") +
    (s ? s + " sec " : "")
  );
};

export function GenerateID() {
  return (
    Math.random().toString(36).substr(2, 10).toUpperCase() +
    Date.now().toString(36).substr(6, 5) +
    Math.random().toString(36).substr(2, 10).toUpperCase() +
    Date.now().toString(36).substr(7, 6)
  );
}
export function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return "0 Bytes";
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}

export const getDescription = (data = [], type) =>
  data.find((x) => x.client_type === type);

export const getNames = (text) => {
  switch (text) {
    case "SUPER_ADMIN":
      return "Super Admin";
    case "ADMIN":
      return "Admin";
    case "CONTENT_CREATOR":
      return "Content Creator";
    case "CONTENT_REVIEWER":
      return "Content Reviewer";
    case "CONTENT_APPROVER":
      return "Content Approver";
    default:
      return text;
  }
};

export const getSingleData = (data = [], key, value) =>
  data && key && value && data.find((x) => x[key] === value);

export const getFilteredData = (data = [], key, value) =>
  data && key && value && data.filter((x) => x[key] === value);

export function isInputNumber(e) {
  //   console.log(e.target.value, e.which);
  if (
    !/[0-9.]/.test(String.fromCharCode(e.which)) ||
    (String.fromCharCode(e.which) === "." && e.target.value.includes("."))
  ) {
    e.preventDefault();
  }
}
export function isMobileNumber(e) {
  if (!/[0-9]/.test(String.fromCharCode(e.which))) {
    e.preventDefault();
  }
}

export function IsNullOrEmpty(value) {
  if (value === null || value === undefined || value === "") return true;
  return false;
}

export function objectHasKey(object, key = "") {
  if (typeof object !== "object" || !key) return false;
  return Object.prototype.hasOwnProperty.call(object, key);
}

export const getImage = (
  data = [],
  image_type = "Default",
  name = "image_type"
) => data && data.find((x) => x[name] === image_type && !x.is_mobile);

var s = ["th", "st", "nd", "rd"];
export const suffix = (n) => {
  // return function(n) {
  var d = (Number(n) | 0) % 100;
  return d > 3 && d < 21 ? "th" : s[d % 10] || "th";
  // }
};

export const getObjectArray = (
  obj = {},
  filter = ["sort_order", "blog_content_type", "id"]
) => {
  if (typeof obj !== "object" || Object.keys(obj).length === 0) return [];
  return Object.keys(obj)
    .filter((x) => obj[x] !== null && !filter.find((v) => v === x))
    .map((x) => x);
};

export function isEqualArray(x = [], y = []) {
  var same = true;
  if (x.length !== y.length) return false;
  for (let i = 0; i < x.length; i++) {
    if (!isEqualObject(x[i], y[i])) {
      same = false;
      break;
    }
  }
  return same;
}

export function isEqualObject(x = {}, y = {}) {
  var same = true;
  for (var propertyName in x) {
    if (x[propertyName] !== y[propertyName]) {
      same = false;
      break;
    }
  }
  return same;
}

export const isEqual = (value, other) => {
  // Get the value type
  var type = Object.prototype.toString.call(value);

  // If the two objects are not the same type, return false
  if (type !== Object.prototype.toString.call(other)) return false;

  // If items are not an object or array, return false
  if (["[object Array]", "[object Object]"].indexOf(type) < 0) return false;

  // Compare the length of the length of the two items
  var valueLen =
    type === "[object Array]" ? value.length : Object.keys(value).length;
  var otherLen =
    type === "[object Array]" ? other.length : Object.keys(other).length;
  if (valueLen !== otherLen) return false;

  // Compare two items
  var compare = function (item1, item2) {
    // Get the object type
    var itemType = Object.prototype.toString.call(item1);

    // If an object or array, compare recursively
    if (["[object Array]", "[object Object]"].indexOf(itemType) >= 0) {
      if (!isEqual(item1, item2)) return false;
    }

    // Otherwise, do a simple comparison
    else {
      // If the two items are not the same type, return false
      if (itemType !== Object.prototype.toString.call(item2)) return false;

      // Else if it's a function, convert to a string and compare
      // Otherwise, just compare
      if (itemType === "[object Function]") {
        if (item1.toString() !== item2.toString()) return false;
      } else {
        if (item1 !== item2) return false;
      }
    }
  };

  // Compare properties
  if (type === "[object Array]") {
    for (var i = 0; i < valueLen; i++) {
      if (compare(value[i], other[i]) === false) return false;
    }
  } else {
    for (var key in value) {
      if (Object.prototype.hasOwnProperty.call(value, key)) {
        if (compare(value[key], other[key]) === false) return false;
      }
    }
  }

  // If nothing failed, return true
  return true;
};

export const sortArray = (arr = [], New = true) =>
  arr
    .slice()
    .sort(
      (a, b) =>
        moment((New ? b : a).updated_at || (New ? b : a).created_at) -
        moment((New ? a : b).updated_at || (New ? a : b).created_at)
    );

export const mergeStringArray = (actual = [], update = []) => {
  if (!actual || !update) return [];
  let returnData = [...actual];
  update.map((x) => {
    switch (x.update_type) {
      case "Add":
        returnData.push(x.update_data);
        break;
      case "Remove":
        returnData.splice(x.index, 1);
        break;
      case "Update":
        returnData[x.index] = x.update_data;
        break;
      default:
        break;
    }
  });
  return returnData;
};

export const mergeResultsRangeArray = (actual = [], update = []) => {
  if (!actual || !update) return [];
  let returnData = [...actual];
  update.map((x) => {
    switch (x.update_type) {
      case "Add":
        returnData.push(x.update_data);
        break;
      case "Remove":
        returnData.splice(x.index, 1);
        break;
      case "Update":
        // let current = {...returnData[x.index]};
        // Object.keys(current).map(x=>console.log(x))
        returnData[x.index] = x.update_data;
        break;
      default:
        break;
    }
  });
  return returnData;
};

export const mergeDescriptionArray = (actual = [], update = []) => {
  if (!actual || !update) return actual ? actual : [];
  let returnData = [...actual];
  const updated = update.filter((x) => x.update_type === "Update");
  updated.map((x) => {
    if (!IsNullOrEmpty(x.index)) {
      returnData[x.index] = x.update_data;
    }
  });
  const removed = update.filter((x) => x.update_type === "Remove");
  removed.map((x) => {
    returnData.splice(x.index, 1);
  });
  const added = update.filter((x) => x.update_type === "Add");
  added.map((x) => {
    returnData.push(x.update_data);
  });
  return returnData;
};

export const compareObjectString = (actual = {}, update = {}, Objects = []) => {
  if (!actual || !update || !Objects) return {};
  let returnData = {};
  Objects.map((x) => {
    if (actual[x] !== update[x]) {
      returnData[x] = update[x];
    }
  });
  return returnData;
};

export const validateResultInterpretationArray = (range, formik) => {
  let val = range.filter((a) => a.range_min && a.range_max);
  try {
    if (val.length < 2 || !val) return true;
    let arrayBunch = [];
    for (let i = 0; i < val.length; i++) {
      let index = arrayBunch.findIndex(
        (x) => x.entity_ref_id === val[i].entity_ref_id
      );
      if (index < 0) {
        arrayBunch.push({
          entity_ref_id: val[i].entity_ref_id,
          data: [
            {
              i,
              ...val[i],
              range_min: Number(val[i].range_min),
              range_max: Number(val[i].range_max),
            },
          ],
        });
      } else {
        arrayBunch[index].data.push({
          i,
          ...val[i],
          range_min: Number(val[i].range_min),
          range_max: Number(val[i].range_max),
        });
      }
    }
    if (arrayBunch.length === 0) return true;
    for (let i = 0; i < arrayBunch.length; i++) {
      arrayBunch[i].data = arrayBunch[i].data.sort(
        (a, b) => a.range_min - b.range_min
      );
      let arr = arrayBunch[i].data;
      for (let j = 0; j < arr.length; j++) {
        if (arr[j].range_min >= arr[j].range_max) {
          formik.setFieldError(
            `result_range.${arr[j].i}.range_min`,
            "value should be less than max"
          );
          // console.log("Hello inter", arr[j].i, arr[j]);
          break;
        }
        for (let k = j + 1; k < arr.length; k++) {
          if (
            arr[j].range_min >= arr[k].range_min ||
            arr[j].range_min >= arr[k].range_max
          ) {
            //   console.log(
            //     "Hello Min",
            //     arr[j].i,
            //     arr[k].i,
            //     arr[j].range_max,
            //     arr[j].range_max,
            //     arr[k].range_min,
            //     arr[k].range_max
            //   );
            formik.setFieldError(
              `result_range.${arr[j].i}.range_min`,
              "value should be less than max"
            );
          } else if (
            arr[j].range_max > arr[k].range_min ||
            arr[j].range_max >= arr[k].range_max
          ) {
            formik.setFieldError(
              `result_range.${arr[j].i}.range_max`,
              "value should be greater than max"
            );
            //   console.log(
            //     "Hello Max",
            //     arr[j].i,
            //     arr[k].i,
            //     arr[j].range_max,
            //     arr[j].range_max,
            //     arr[k].range_min,
            //     arr[k].range_max
            //   );
          }
        }
      }
    }
  } catch (error) {
    console.log("Error", error);
  }
};

export const mergeMultiSelectArray = (actual = [], update = []) => {
  if (!actual || !update) return actual ? actual : [];
  let returnData = [...actual.map((x) => ({ data: x }))];
  const updated = update.filter((x) => x.update_type === "Update");
  updated.length > 0 &&
    updated.map((x) => {
      if (!IsNullOrEmpty(x.index)) {
        returnData[x.index] = { data: x.update_data, isUpdated: true };
      }
    });
  const added = update.filter((x) => x.update_type === "Add");
  added.length > 0 &&
    added.map((x) => {
      returnData.push({ data: x.update_data, isAdded: true });
    });
    const removed = update.filter((x) => x.update_type === "Remove");
    removed && removed.length > 0 &&
      removed.map((x) => {
        let data = returnData[x.index]?.data;
        if(data){
          returnData[x.index] = { data, isRemoved: true };
        }
      });
  return returnData;
};

export const mergeMetaArray = (actual = [], update = []) => {
  if (!actual || !update) return actual ? actual : [];
  let returnData = [...actual];
  const updated = update.filter((x) => x.update_type === "Update");
  updated.length > 0 &&
    updated.map((x) => {
      if (!IsNullOrEmpty(x.index)) {
        returnData[x.index] = {
          ...returnData[x.index],
          update_data: x.update_data,
          ...returnValidDataInObjects(x.update_data),
          isUpdated: true,
        };
      }
    });
  const removed = update.filter((x) => x.update_type === "Remove");

  removed.length > 0 &&
    removed.map((x) => {
      let data = returnData[x.index];
      returnData[x.index] = { ...data, isRemoved: true };
    });
  const added = update.filter((x) => x.update_type === "Add");

  added.length > 0 &&
    added.map((x) => {
      returnData.push({ ...x.update_data, isAdded: true });
    });
  return returnData;
};

export const mergeMediaArray = (actual = [], update = []) => {
  if (!actual || !update) return actual ? actual : [];
  let returnData = [...actual];
  const updated = update.filter((x) => x.update_type === "Update");
  updated.length > 0 &&
    updated.map((x) => {
      if (!IsNullOrEmpty(x.index)) {
        returnData[x.index] = {
          ...returnData[x.index],
          update_data: x.update_data,
          ...returnValidDataInObjects(x.update_data),
          isUpdated: true,
        };
      }
    });
  const removed = update.filter((x) => x.update_type === "Remove");

  removed.length > 0 &&
    removed.map((x) => {
      let data = returnData[x.index];
      returnData[x.index] = { ...data, isRemoved: true };
    });
  const added = update.filter((x) => x.update_type === "Add");

  added.length > 0 &&
    added.map((x) => {
      returnData.push({ ...x, isAdded: true });
    });
  return returnData;
};

export const createArrayByUpdate = (update = []) => {
  if (!update) return [];
  let returnData = [];
  const updateFunction = (x) => {
    if (!IsNullOrEmpty(x.index) && returnData[x.index]) {
      returnData[x.index] = {
        ...returnData[x.index],
        update_data: x.update_data,
        ...returnValidDataInObjects(x.update_data),
        isUpdated: true,
      };
    }
  };
  const addFunction = (x) => {
    returnData.push({ ...x.update_data, isAdded: true });
  };
  const removeFunction = (x) => {
    let data = returnData[x.index];
    returnData[x.index] = { ...data, isRemoved: true };
  };
  for (let i = 0; i < update.length; i++) {
    const { update_type } = update[i];
    if (update_type === "Update") {
      updateFunction(update[i]);
    } else if (update_type === "Remove") {
      removeFunction(update[i]);
    } else if (update_type === "Add") {
      addFunction(update[i]);
    }
  }
  return returnData;
};

export const createMediaArrayByUpdate = (update = []) => {
  if (!update) return [];
  let returnData = [];
  const updateFunction = (x) => {
    if (!IsNullOrEmpty(x.index) && returnData[x.index]) {
      returnData[x.index] = {
        ...returnData[x.index],
        update_data: x.update_data,
        ...returnValidDataInObjects(x.update_data),
        isUpdated: true,
      };
    }
  };
  const addFunction = (x) => {
    returnData.push({ ...x, isAdded: true });
  };
  const removeFunction = (x) => {
    let data = returnData[x.index];
    returnData[x.index] = { ...data, isRemoved: true };
  };
  for (let i = 0; i < update.length; i++) {
    const { update_type } = update[i];
    if (update_type === "Update") {
      updateFunction(update[i]);
    } else if (update_type === "Remove") {
      removeFunction(update[i]);
    } else if (update_type === "Add") {
      addFunction(update[i]);
    }
  }
  return returnData;
};

export const returnValidDataInObjects = (object) => {
  if (typeof object !== "object") return false;
  let returnData = {};
  Object.keys(object).map((x) => {
    if (!IsNullOrEmpty(object[x])) returnData[x] = object[x];
  });
  return returnData;
};

export function removeEmptyObjValue(val) {
  const entries = Object.entries(val).filter(([, value]) => value != null);
  const clean = entries.map(([key, v]) => {
    const value = typeof v == "object" ? removeEmptyObjValue(v) : v;
    return [key, value];
  });
  return Object.fromEntries(clean);
}

export function secToFormat(seconds) {
  return moment.utc(seconds * 1000).format("HH:mm:ss");
}
