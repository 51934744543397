export const GET_DOMAIN_LIST = "GET_DOMAIN_LIST";
export const GET_SUB_DOMAIN_LIST = "GET_SUB_DOMAIN_LIST";
export const GET_CATEGORY_LIST = "GET_CATEGORY_LIST";
export const GET_SCREENER_LIST = "GET_SCREENER_LIST";
export const GET_INTERVENTION_LIST = "GET_INTERVENTION_LIST";
export const GET_THERAPY_LIST = "GET_THERAPY_LIST";
export const GET_BLOG_LIST = "GET_BLOG_LIST";
export const GET_PLAN_LIST = "GET_PLAN_LIST";
export const GET_FUNCTION_LIST = "GET_FUNCTION_LIST";
export const GET_OBJECTIVE_LIST = "GET_OBJECTIVE_LIST";
export const GET_DEFAULT_CHOICES = "GET_DEFAULT_CHOICES";
