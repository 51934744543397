import { useEffect } from "react";
import { useState } from "react";
import CreateTherapyAction from "../section/CreateTherapyAction";
import UpdateTherapyAction from "../section/UpdateTherapyAction";
import TherapyActionListItem from "./TherapyActionListItem";
import { Popconfirm } from "antd";

export default function TherapyAction(props) {
  let {
    therapy = {},
    actionList = [],
    refresh = () => {},
    onClose = () => {},
    onDone = () => {},
    onBack = () => {},
    handleDiscord = () => {},
    isEditable,
  } = props;
  const [therapyAction, setAction] = useState({});
  const [history, setHistory] = useState(false);
  const pending_history = therapyAction.pending_history
    ? therapyAction.pending_history
    : {};
  const hasUpdated = Boolean(pending_history && pending_history.updated_data);

  useEffect(() => {
    refresh();
  }, []);

  const ActionComponent = therapyAction.therapy_module_action_id
    ? UpdateTherapyAction
    : CreateTherapyAction;
  return (
    <div className="row justify-content-center ">
      <div className="col-md-12">
        <div className="table-responsive border p-2">
          <table className="table questionnaire-table table-borderless mb-0">
            <thead>
              <tr className=" ">
                <td className="text-center">Action Name</td>
                <td className="text-center">Module Name</td>
                <td className="text-center">Action Type</td>
                <td className="text-center">Data</td>
                <td className="text-center">Order</td>
                <td className="text-center">Action</td>
              </tr>
            </thead>
            <tbody>
              {actionList.length > 0 ? (
                actionList
                  .slice()
                  // .filter((x) =>
                  //   x?.pending_history?.updated_data?.active
                  //     ? x?.pending_history?.updated_data?.active === true
                  //     : x?.active === true
                  // )
                  .filter((x)=>x?.active === true)
                  .sort((a, b) => a.sort_order - b.sort_order)
                  .map((item, i) => (
                    <TherapyActionListItem
                      item={item}
                      {...props}
                      key={i}
                      isEditable={isEditable}
                      onEdit={setAction}
                      canDelete={!therapy.published}
                      refresh={refresh}
                      setHistory={setHistory}
                    />
                  ))
              ) : (
                <tr>
                  <td className="text-center" colSpan="5">
                    No Plan Action Found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      {isEditable && (
        <ActionComponent
          {...props}
          setAction={setAction}
          therapyAction={therapyAction}
          reset={() => setAction({})}
        />
      )}
      <div className="d-flex align-items-end justify-content-center col-md-12 p-5">
        {isEditable && (hasUpdated || history)&&(
          <Popconfirm
            title="Are you sure to discard and close?"
            onConfirm={handleDiscord}
            //  onCancel={cancel}
            okText="Yes"
            cancelText="No"
          >
            <button
              type="button"
              className="btn btn-danger btn-sm float-right btn-add-domain mr-2"
            >
              Discard All & Close
            </button>
          </Popconfirm>
        )}
        <button
          onClick={onClose}
          type="button"
          className="btn btn-danger btn-sm float-right btn-add-domain mr-2"
        >
          Close
        </button>{" "}
        <button
          onClick={onBack}
          type="button"
          className="btn btn-error btn-sm float-right btn-add-domain mr-2"
        >
          Back
        </button>
        {actionList.length > 0 && (
          <button
            onClick={onDone}
            type="button"
            className="btn btn-secondary btn-sm float-right btn-add-domain mr-2"
          >
            Next
          </button>
        )}
      </div>
    </div>
  );
}
