import { useFormik } from "formik";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { CREATE_PLAN_GROUP_GQL } from "../../../Apollo/AdminServices/NewMutationRequest";
import graphHandler from "../../../Apollo/graphHandler";
import CustomButton from "../../../components/Button/CustomButton";
import Description from "../../../components/Input/Description";
import MetaInput from "../../../components/MetaInput";
import MultiSelect from "../../../components/MultiSelect/MultiSelect";

export default function CreatePlanGroup({
  refresh = () => {},
  subDomainList = [],
  setLoading = () => {},
  setGroup,
  plan,
}) {
  const formik = useFormik({
    initialValues: {
      sub_domain_ids: [],
      mandatory: false,
      auto_generate: false,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      onSubmit(values);
    },
  });

  const onSubmit = async (value) => {
    formik.setSubmitting(true);
    const {
      sub_domain_ids,
      plan_group_name,
      mandatory,
      auto_generate,
      description,
      mobile_description,
      plan_group_code,
      plan_group_meta,
      sort_order,
      min_completion_days,
      max_completion_days,
      start_interval_seconds,
    } = value;
    let create_request = {
      plan_id: plan.plan_id,
      plan_group_name,
      plan_group_code,
      plan_group_type: "Default",
      mandatory: Boolean(mandatory),
      auto_generate: Boolean(auto_generate),
    };
    if (sub_domain_ids && sub_domain_ids.length > 0) {
      create_request.sub_domain_ids = [...sub_domain_ids.map((x) => x.value)];
    }
    if (sort_order) {
      create_request.sort_order = Number(sort_order);
    }
    if (start_interval_seconds) {
      create_request.start_interval_seconds =
        Number(start_interval_seconds) * 86400;
    }
    if (max_completion_days) {
      create_request.max_completion_days = Number(max_completion_days);
    }
    if (min_completion_days) {
      create_request.min_completion_days = Number(min_completion_days);
    }
    let descriptions = [];
    if (description) {
      descriptions.push({
        client_type: "Web",
        description: description,
      });
    }
    if (mobile_description) {
      descriptions.push({
        client_type: "Mobile",
        description: mobile_description,
      });
    }
    if (descriptions.length > 0)
      create_request.plan_group_descriptions = descriptions;
    if (plan_group_meta && plan_group_meta.length > 0) {
      create_request.plan_group_meta = plan_group_meta;
    }
    formik.setSubmitting(true);
    setLoading(true);
    try {
      const {data} =await graphHandler().invoke(
        CREATE_PLAN_GROUP_GQL,
        { create_request },
        1,
        false
      );
      if (data && data.createPlanGroup && data.createPlanGroup.plan_group) {
        setGroup({
          ...data.createPlanGroup.plan_group,
          pending_history: data.createPlanGroup.plan_group_history,
        });
      }
      refresh();
      setLoading(false);
      toast.success("Created Successfully");
      formik.setSubmitting(false);
      resetModal();
    } catch (error) {
      console.log("createPlanGroup error", error);
      setLoading(false);
      formik.setSubmitting(false);
    }
  };
  const handleError = () => {
    if (Object.keys(formik.errors).length > 0) {
      toast.error(formik.errors[Object.keys(formik.errors)[0]]);
    }
  };

  const handleNumber = (e) => {
    const { name, value } = e.target;
    const re = /^[0-9\b]+$/;
    let temp = !value ? value : re.test(value) ? value : formik.values[name];
    formik.setFieldValue(name, temp ? temp.substring(0, 10) : temp);
  };

  const resetModal = () => {
    formik.resetForm();
    formik.setValues({
      mandatory: false,
      auto_generate: false,
    });
    formik.setSubmitting(false);
  };
  return (
    <>
      <div className="col-md-12 modal-header" id="CreateTopic">
        <h5 className="modal-title text-center mb-3">
          {formik.values.plan_group_id ? "Update" : "Create"} Group
        </h5>
      </div>
      <div className="col-md-12">
        <div className="  border">
          <div className="row p-3">
            <div className="col-md-6">
              <div className="row">
                <div className="col-md-12 form-group">
                  <label
                    htmlFor="sub_domain_ids"
                    className="col-form-label w-100 mb-2"
                  >
                    Sub Domains
                  </label>
                  <MultiSelect
                    option={subDomainList}
                    onChange={(e) => formik.setFieldValue("sub_domain_ids", e)}
                    placeholder="Select Sub Domain"
                    isCreatable={false}
                    type="Sub Domain"
                    name="sub_domain_ids"
                    id="sub_domain_ids"
                    onBlur={formik.handleBlur}
                    defaultValue={formik.values.sub_domain_ids || "Reset"}
                  />
                  <small className="text-danger">
                    {formik.touched.sub_domain_ids &&
                    formik.errors &&
                    formik.errors.sub_domain_ids
                      ? formik.errors.sub_domain_ids
                      : ""}
                  </small>
                </div>
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-md-4 form-group">
                      <label
                        htmlFor="plan_group_code"
                        className="col-form-label w-100 mb-2"
                      >
                        Code
                      </label>
                      <input
                        {...formik.getFieldProps("plan_group_code")}
                        type="text"
                        className="form-control "
                        id="plan_group_code"
                        value={
                          formik.values.plan_group_code
                            ? formik.values.plan_group_code
                            : ""
                        }
                      />
                      <small className="text-danger align-self-end">
                        {formik.touched.plan_group_code &&
                        formik.errors &&
                        formik.errors.plan_group_code
                          ? formik.errors.plan_group_code
                          : ""}
                      </small>
                    </div>
                    <div className="col-md-4 form-group">
                      <label
                        htmlFor="sort_order"
                        className="col-form-label w-100 mb-2"
                      >
                        Sort Order
                      </label>
                      <input
                        {...formik.getFieldProps("sort_order")}
                        type="text"
                        className="form-control "
                        id="sort_order"
                        onChange={handleNumber}
                        maxLength={10}
                        value={
                          formik.values.sort_order
                            ? formik.values.sort_order
                            : ""
                        }
                      />
                      <small className="text-danger align-self-end">
                        {formik.touched.sort_order &&
                        formik.errors &&
                        formik.errors.sort_order
                          ? formik.errors.sort_order
                          : ""}
                      </small>
                    </div>
                    <div className="col-md-4 form-group">
                      <label
                        htmlFor="start_interval_seconds"
                        className="col-form-label w-100 mb-2"
                      >
                        Interval (in days)
                      </label>
                      <input
                        {...formik.getFieldProps("start_interval_seconds")}
                        type="text"
                        className="form-control "
                        id="start_interval_seconds"
                        onChange={handleNumber}
                        maxLength={10}
                        value={
                          formik.values.start_interval_seconds
                            ? formik.values.start_interval_seconds
                            : ""
                        }
                      />
                      <small className="text-danger align-self-end">
                        {formik.touched.start_interval_seconds &&
                        formik.errors &&
                        formik.errors.start_interval_seconds
                          ? formik.errors.start_interval_seconds
                          : ""}
                      </small>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 form-group">
                <div className="row">
                  <div className="col-md-4 form-group d-flex">
                    <input
                      className="mr-2"
                      type="checkbox"
                      id="mandatory"
                      name="mandatory"
                      onChange={(e) =>
                        formik.setFieldValue("mandatory", e.target.checked)
                      }
                      checked={formik.values.mandatory}
                      onBlur={formik.handleBlur}
                      //   disabled={formik.values.screener_question_id}
                    />
                    <label className="col-form-label w-100">Mandatory</label>
                  </div>
                  <div className="col-md-4 form-group  d-flex">
                    <input
                      className="mr-2"
                      type="checkbox"
                      id="auto_generate"
                      name="auto_generate"
                      onChange={(e) =>
                        formik.setFieldValue("auto_generate", e.target.checked)
                      }
                      checked={formik.values.auto_generate}
                      onBlur={formik.handleBlur}
                      //   disabled={formik.values.screener_question_id}
                    />
                    <label className="col-form-label w-100">Auto Generate</label>
                  </div>
                  </div>
                </div>
                <div className="col-md-12 form-group mb-0">
                  <label
                    htmlFor="min_completion_days"
                    className="col-form-label w-100 mb-2"
                  >
                    <b>Completion Days</b>
                  </label>
                  <div className="row">
                    <div className="col-md-6 form-group">
                      <label
                        htmlFor="min_completion_days"
                        className="col-form-label w-100 mb-2"
                      >
                        Minimum
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="min_completion_days"
                        name="min_completion_days"
                        onChange={handleNumber}
                        maxLength={10}
                        value={
                          formik.values.min_completion_days
                            ? formik.values.min_completion_days
                            : ""
                        }
                        onBlur={formik.handleBlur}
                      />
                      <small className="text-danger align-self-end">
                        {formik.touched.min_completion_days &&
                        formik.errors &&
                        formik.errors.min_completion_days
                          ? formik.errors.min_completion_days
                          : ""}
                      </small>
                    </div>
                    <div className="col-md-6 form-group">
                      <label
                        htmlFor="max_completion_days"
                        className="col-form-label w-100 mb-2"
                      >
                        Maximum
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="max_completion_days"
                        name="max_completion_days"
                        onChange={handleNumber}
                        maxLength={10}
                        value={
                          formik.values.max_completion_days
                            ? formik.values.max_completion_days
                            : ""
                        }
                        onBlur={formik.handleBlur}
                      />
                      <small className="text-danger align-self-end">
                        {formik.touched.max_completion_days &&
                        formik.errors &&
                        formik.errors.max_completion_days
                          ? formik.errors.max_completion_days
                          : ""}
                      </small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="row">
                <div className="col-md-12 form-group">
                  <label
                    htmlFor="plan_group_name"
                    className="col-form-label w-100 mb-2"
                  >
                    Plan Group Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="plan_group_name"
                    name="plan_group_name"
                    onChange={formik.handleChange}
                    // maxLength={30}
                    value={
                      formik.values.plan_group_name
                        ? formik.values.plan_group_name
                        : ""
                    }
                    onBlur={formik.handleBlur}
                  />
                  <small className="text-danger align-self-end">
                    {formik.touched.plan_group_name &&
                    formik.errors &&
                    formik.errors.plan_group_name
                      ? formik.errors.plan_group_name
                      : ""}
                  </small>
                </div>
                <Description
                  formik={formik}
                  webName="description"
                  showEditor={true}
                />
              </div>
            </div>
            <div className="col-md-12">
              <MetaInput
                formik={formik}
                parentArray="plan_group_meta"
                typeKey="type"
                titleKey="key"
                valueKey="value"
              />
            </div>
            <div
              className={"d-flex align-items-end justify-content-end col-md-12"}
            >
              {formik.values.plan_group_id && (
                <button
                  onClick={resetModal}
                  type="button"
                  className="btn btn-light btn-sm float-right btn-add-domain mr-2"
                >
                  Reset
                </button>
              )}
              <CustomButton
                type="button"
                className="btn btn-primary btn-outline-success btn-sm float-right btn-add-domain"
                disabled={
                  Object.keys(formik.errors).length !== 0 ||
                  !formik.isValid ||
                  formik.isSubmitting
                }
                onClick={
                  formik.isValid && Object.keys(formik.values).length !== 0
                    ? formik.handleSubmit
                    : handleError
                }
              >
                {formik.values.plan_group_id ? "Update" : "Create"}
              </CustomButton>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const validationSchema = Yup.object().shape({
  sub_domain_ids: Yup.array().of(
    Yup.object().shape({
      label: Yup.string(),
      value: Yup.string(),
    })
  ),
  //   .test({
  //     message: "Select sub domain",
  //     test: (arr) => {
  //       return arr && arr.length > 0;
  //     },
  //   }),
  plan_group_name: Yup.string().required("Enter group name"),
  // .min(4, "Name should be min of 4 characters")
  // .max(30, "Name should be maximum of 30 characters")
  description: Yup.string().required("Enter description"),
  mobile_description: Yup.string().required("Enter mobile description"),
  plan_group_code: Yup.string().required("Enter group code"),
  sort_order: Yup.string(),
  // .required("Enter sort order"),
  min_completion_days: Yup.string().required("Enter min completion days"),
  max_completion_days: Yup.string().required("Enter max completion days")
  .when('min_completion_days', (min_completion_days, schema) => {
    return schema.test({
      test: max_completion_days => !!min_completion_days && Number(max_completion_days) > Number(min_completion_days),
      message: "Max days should be > min days"
    })
  }),
  start_interval_seconds: Yup.string(),
  mandatory: Yup.boolean(),
  auto_generate: Yup.boolean(),
  // .required("Enter start interval"),
  plan_group_meta: Yup.array().of(
    Yup.object().shape({
      type: Yup.string().required("Select a data type"),
      key: Yup.string().trim().required("Enter a title"),
      value: Yup.string().trim().required("Enter a value"),
    })
  ),
});
