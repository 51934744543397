import { useEffect, useState } from "react";
import { GET_ALL_THERAPY_HISTORIES } from "../../Apollo/AdminServices/QueryRequest";
import graphHandler from "../../Apollo/graphHandler";
function useTherapyHistory(id) {
  const [therapyHistory, setTherapyHistory] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (id) {
      getTherapyHistory(id);
    } else {
      setTherapyHistory([]);
    }
  }, [id]);
  const getTherapyHistory = async (therapy_id) => {
    if (!therapy_id) return;
    setLoading(true);
    try {
      const { data } = await graphHandler().invoke(
        GET_ALL_THERAPY_HISTORIES,
        { therapy_id },
        1,
        false,
        true
      );
      if (
        data &&
        data.getAllTherapyHistories &&
        data.getAllTherapyHistories.therapy_history_list
      ) {
        // console.log(
        //   "getAllTherapyHistories Success",
        //   data.getAllTherapyHistories.therapy_history_list
        // );
        setTherapyHistory(
          data.getAllTherapyHistories.therapy_history_list
            .slice()
            .sort(
              (a, b) =>
                new Date(b.updated_at ? b.updated_at : b.created_at) -
                new Date(a.updated_at ? a.updated_at : a.created_at)
            )
        );
        setLoading(false);
      } else {
        setTherapyHistory([]);
        setLoading(false);
      }
    } catch (error) {
      console.log("getAllTherapyHistories Error", error);
      setTherapyHistory([]);
      setLoading(false);
    }
  };
  return {
    therapyHistory,
    loading,
    getTherapyHistory,
  };
}
export default useTherapyHistory;
