import { useState } from "react";
import { GET_EDUCATION_LIST } from "../../Apollo/AdminServices/QueryRequest";
import graphHandler from "../../Apollo/graphHandler";
function useEducationList(){
    const [educationList, setEducationList] = useState([]);
    const [loading, setLoading] = useState(false);
    
  const getEducationList = async (req = {}) => {
    setLoading(true);
    try {
      let { data } = await graphHandler().invoke(
        GET_EDUCATION_LIST,
        req,
        1,
        false,
        true
      );
      if (
        data &&
        data.getEducationList &&
        data.getEducationList.educations &&
        data.getEducationList.educations.length > 0
      ) {
        // console.log(
        //   "getEducationList Success",
        //   data.getEducationList.educations
        // );
        setEducationList([]);
        setEducationList(data.getEducationList.educations.slice());
        setLoading(false);
      } else {
        setEducationList([]);
        setLoading(false);
      }
    } catch (error) {
      console.log("getEducationList Error", error);
      setEducationList([]);
      setLoading(false);
    }
  };
  return {
    educationList,
    loading,
    getEducationList,
  };
  
}
export default useEducationList;