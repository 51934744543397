import { useFormik } from "formik";
import { useState } from "react";
import { useEffect } from "react";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { UPDATE_PLAN_GQL } from "../../../Apollo/AdminServices/NewMutationRequest";
import graphHandler from "../../../Apollo/graphHandler";
import CustomButton from "../../../components/Button/CustomButton";
import DescriptionInputField from "../../../components/HistoryEditInput/DescriptionInputField";
import DocumentHistoryInput from "../../../components/HistoryEditInput/DocumentHistoryInput";
import ImageHistoryInput from "../../../components/HistoryEditInput/ImageHistoryInput";
import MetaHistoryInput from "../../../components/HistoryEditInput/MetaHistoryInput";
import NewReactSelectInputField from "../../../components/HistoryEditInput/NewReactSelectInputField";
import TextInputField from "../../../components/HistoryEditInput/TextInputField";
import VideoHistoryInput from "../../../components/HistoryEditInput/VideoHistoryInput";
import {
  mergeMultiSelectArray,
  objectHasKey,
  removeEmptyObjValue,
} from "../../../utils/Functions/filter";
import CheckInputField from "../../../components/HistoryEditInput/CheckInputField";
import { Popconfirm } from "antd";

export default function UpdatePlan({
  refresh = () => {},
  plan = {},
  isEditable = false,
  onDone = () => {},
  categories = [],
  subDomainList = [],
  setPlan = () => {},
  onClose = () => {},
  setLoading = () => {},
  handleDiscord = () => {},
  isFirst,
}) {
  const [canEdit, setEdit] = useState(isEditable);
  const [selectedSubDomainList, setSelectedSubDomainList] = useState([]);
  const [selectedCategoryList, setSelectedCategoryList] = useState([]);
  const pending_history = plan.pending_history ? plan.pending_history : {};
  let { plan_id, sub_domain_ids, category_ids } = plan;
  const hasUpdated = Boolean(pending_history && pending_history.updated_data);
  const updated_data = hasUpdated ? pending_history.updated_data : {};
  const formik = useFormik({
    initialValues: {},
    onSubmit: (values) => {
      onSubmit(values);
    },
  });
  console.log(plan, formik.values, updated_data, pending_history);

  useEffect(() => {
    if (plan_id) {
      let publish = hasUpdated ? updated_data : {};
      if (subDomainList.length > 0) {
        let subArray = [],
          sub_domains =
            sub_domain_ids && sub_domain_ids.length > 0 ? sub_domain_ids : [],
          publishedSub =
            publish.sub_domain_ids &&
            publish.sub_domain_ids.update_items &&
            publish.sub_domain_ids.update_items.length > 0
              ? publish.sub_domain_ids.update_items
              : [];
        let value = mergeMultiSelectArray(sub_domains, publishedSub);
        let i = 0;
        subArray = value.map((x) => {
          let ret = {
            ...subDomainList.find((y) => y.value === x.data),
            ...x,
            index: i,
          };
          if (x.isRemoved) {
            ret.value =
              subDomainList.find((y) => y.value === x.data).label || "";
          } else {
            i++;
          }
          return ret;
        });
        setSelectedSubDomainList(subArray);
      }
      if (
        category_ids &&
        category_ids.length > 0 &&
        categories &&
        categories.length > 0
      ) {
        let subArray = [],
          sub_domains =
            category_ids && category_ids.length > 0 ? category_ids : [],
          publishedSub =
            publish.category_ids &&
            publish.category_ids.update_items &&
            publish.category_ids.update_items.length > 0
              ? publish.category_ids.update_items
              : [];
        let value = mergeMultiSelectArray(sub_domains, publishedSub);
        let i = 0;
        subArray = value.map((x) => {
          let ret = {
            ...categories.find((y) => y.value === x.data),
            ...x,
            index: i,
          };
          if (x.isRemoved) {
            ret.value = categories.find((y) => y.value === x.data).label || "";
          } else {
            i++;
          }
          return ret;
        });
        setSelectedCategoryList(subArray);
      }
      setEdit(isFirst);
    } else {
      setEdit(!isFirst);
    }
  }, [plan, subDomainList, categories]);

  const onSubmit = async (formikValues) => {
    formik.setSubmitting(true);
    let {
      sub_domain_ids,
      plan_name,
      plan_descriptions,
      plan_code,
      category_ids,
      plan_meta,
      auto_start,
      plan_image_urls,
      plan_video_urls,
      plan_document_urls,
      alias_name,
    } = formikValues;
    let update_request = {
      plan_id,
    };
    if (objectHasKey(formikValues, "auto_start")) {
      update_request.auto_start = auto_start;
    }
    if (objectHasKey(formikValues, "plan_name")) {
      update_request.plan_name = plan_name;
    }
    if (objectHasKey(formikValues, "alias_name")) {
      update_request.alias_name = alias_name;
    }
    if (objectHasKey(formikValues, "plan_code")) {
      update_request.plan_code = plan_code;
    }

    if (objectHasKey(formikValues, "sub_domain_ids")) {
      update_request.sub_domain_ids = { update_items: sub_domain_ids };
    }

    if (objectHasKey(formikValues, "category_ids")) {
      update_request.category_ids = { update_items: category_ids };
    }

    if (objectHasKey(formikValues, "plan_descriptions")) {
      update_request.plan_descriptions = {
        update_items: plan_descriptions,
      };
    }
    if (objectHasKey(formikValues, "plan_meta")) {
      update_request.plan_meta = {
        update_items: plan_meta.map((x) => removeEmptyObjValue(x)),
      };
    }
    if (objectHasKey(formikValues, "plan_image_urls")) {
      update_request.plan_image_urls = {
        update_items: plan_image_urls.map((x) => removeEmptyObjValue(x)),
      };
    }
    if (objectHasKey(formikValues, "plan_video_urls")) {
      update_request.plan_video_urls = {
        update_items: plan_video_urls.map((x) => removeEmptyObjValue(x)),
      };
    }
    if (objectHasKey(formikValues, "plan_document_urls")) {
      update_request.plan_document_urls = {
        update_items: plan_document_urls.map((x) => removeEmptyObjValue(x)),
      };
    }
    formik.setSubmitting(true);
    try {
      const { data } = await graphHandler().invoke(
        UPDATE_PLAN_GQL,
        { update_request },
        1,
        false
      );
      toast.success("Updated Successfully");
      if (data && data.updatePlan && data.updatePlan.plan) {
        // console.log("updatePlan Success", data.updatePlan.plan);
        setPlan({
          ...data.updatePlan.plan,
          pending_history: data.updatePlan.plan_history,
        });
        onDone();
        refresh();
        setLoading(false);
        formik.setSubmitting(false);
      }
    } catch (error) {
      console.log("updatePlan error", error);
      formik.setSubmitting(false);
    }
  };

  const handleError = () => {
    if (Object.keys(formik.errors).length > 0) {
      toast.error(formik.errors[Object.keys(formik.errors)[0]]);
    }
  };
  return (
    <div className="p-5 position-relative">
      {isEditable && (
        <button
          type="button"
          className="btn btn-link position-absolute"
          style={{ top: "0px", right: "60px" }}
          onClick={() => setEdit((x) => !x)}
        >
          {canEdit ? (
            <>
              <i className="fas fa-times"></i> Cancel
            </>
          ) : (
            <>
              <i className="fas fa-pen"></i> Edit
            </>
          )}
        </button>
      )}
      <div className="row justify-content-center ">
        <div className="col-md-6">
          <div className="row">
            <NewReactSelectInputField
              parentForm={formik}
              id="sub_domain_ids"
              title="Sub Domain"
              placeholder="Select Sub Domain"
              options={subDomainList}
              isPublished={plan.published}
              isCreatable={false}
              isMulti={true}
              defaultValue={selectedSubDomainList}
              updateValue={
                updated_data.sub_domain_ids &&
                updated_data.sub_domain_ids.update_items &&
                updated_data.sub_domain_ids.update_items.length > 0
                  ? updated_data.sub_domain_ids.update_items
                  : false
              }
              disabled={!canEdit}
              validation={{
                sub_domain_ids: Yup.array()
                  .of(
                    Yup.object().shape({
                      label: Yup.string(),
                      value: Yup.string(),
                    })
                  )
                  .test({
                    message: "Select sub domain",
                    test: (arr) => {
                      return arr && arr.length > 0;
                    },
                  }),
              }}
            />
            <NewReactSelectInputField
              parentForm={formik}
              id="category_ids"
              title="Category"
              placeholder="Select category"
              options={categories}
              isPublished={plan.published}
              isCreatable={false}
              isMulti={true}
              defaultValue={
                selectedCategoryList.length > 0 ? selectedCategoryList : []
              }
              updateValue={
                updated_data.category_ids &&
                updated_data.category_ids.update_items &&
                updated_data.category_ids.update_items.length > 0
                  ? updated_data.category_ids.update_items
                  : false
              }
              disabled={!canEdit}
              validation={{
                category_ids: Yup.array().of(
                  Yup.object().shape({
                    label: Yup.string(),
                    value: Yup.string(),
                  })
                ),
                // .test({
                //   message: "Select category",
                //   test: (arr) => {
                //     return arr && arr.length > 0;
                //   },
                // }),
              }}
            />
            <TextInputField
              parentForm={formik}
              className="col-md-12"
              updateValue={updated_data.alias_name ?? ""}
              actualValue={plan.alias_name ?? ""}
              id="alias_name"
              title="Alias Name"
              disabled={!canEdit}
              customBlur={true}
              validation={{
                alias_name: Yup.string().trim(),
              }}
            />
            <TextInputField
              parentForm={formik}
              className="col-md-12"
              updateValue={updated_data.plan_code}
              actualValue={plan.plan_code}
              id="plan_code"
              title="Code"
              disabled={!canEdit || plan.plan_id}
              validation={{
                plan_code: Yup.string().trim().required("Enter plan code"),
              }}
            />
            <CheckInputField
              disabled={!canEdit}
              parentForm={formik}
              col="col-md-12"
              className="col-md-12"
              updateValue={updated_data.auto_start}
              hasUpdated={typeof updated_data.auto_start === "boolean"}
              actualValue={plan.auto_start}
              id="auto_start"
              title="Auto Start"
              validation={{
                auto_start: Yup.boolean(),
              }}
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="row">
            <TextInputField
              parentForm={formik}
              className="col-md-12"
              updateValue={updated_data.plan_name}
              actualValue={plan.plan_name}
              id="plan_name"
              customBlur={true}
              title="Plan Name"
              disabled={!canEdit}
              validation={{
                plan_name: Yup.string().trim().required("Enter plan name"),
              }}
            />
            <DescriptionInputField
              showEditor={true}
              parentForm={formik}
              parentKey="plan_descriptions"
              className="col-md-12"
              updateValue={
                updated_data.plan_descriptions &&
                updated_data.plan_descriptions.update_items
                  ? updated_data.plan_descriptions.update_items
                  : false
              }
              actualValue={plan.plan_descriptions}
              disabled={!canEdit}
              validation={{
                description: Yup.string()
                  .trim()
                  .required("Description is required"),
                mobile_description: Yup.string()
                  .trim()
                  .required("Mobile description is required"),
              }}
            />
          </div>
        </div>
        <div className="col-md-12">
          <MetaHistoryInput
            parentForm={formik}
            parentArray="plan_meta"
            typeKey="type"
            titleKey="key"
            valueKey="value"
            isPublished={plan.published}
            updateValue={
              updated_data.plan_meta && updated_data.plan_meta.update_items
                ? updated_data.plan_meta.update_items
                : false
            }
            validation={{
              plan_meta: Yup.array().of(
                Yup.object().shape({
                  type: Yup.string().required("Select a data type"),
                  key: Yup.string().trim().required("Enter a title"),
                  value: Yup.string().trim().required("Enter a value"),
                })
              ),
            }}
            actualValue={plan.plan_meta}
            disabled={!canEdit}
          />
          <ImageHistoryInput
            disabled={!canEdit}
            parentForm={formik}
            contentId={plan.plan_id}
            isPublished={plan.published}
            contentType="PLAN"
            heading="Image File"
            parentArray="plan_image_urls"
            actualValue={plan.plan_image_urls}
            updateValue={
              updated_data.plan_image_urls &&
              updated_data.plan_image_urls.update_items
                ? updated_data.plan_image_urls.update_items
                : false
            }
            validation={{
              plan_image_urls: Yup.array().of(
                Yup.object().shape({
                  is_mobile: Yup.boolean().required("Select a Image device"),
                  image_type: Yup.string().required("Select a Image format"),
                  url: Yup.string(),
                })
              ),
            }}
          />
          <VideoHistoryInput
            disabled={!canEdit}
            parentForm={formik}
            contentId={plan.plan_id}
            isPublished={plan.published}
            contentType="PLAN"
            heading="Video File"
            parentArray="plan_video_urls"
            actualValue={plan.plan_video_urls}
            updateValue={
              updated_data.plan_video_urls &&
              updated_data.plan_video_urls.update_items
                ? updated_data.plan_video_urls.update_items
                : false
            }
            validation={{
              plan_video_urls: Yup.array().of(
                Yup.object().shape({
                  is_mobile: Yup.boolean().required("Select a video device"),
                  video_type: Yup.string().required("Select a video format"),
                  video_url: Yup.string(),
                })
              ),
            }}
          />
          <DocumentHistoryInput
            disabled={!canEdit}
            parentForm={formik}
            contentId={plan.plan_id}
            isPublished={plan.published}
            contentType="PLAN"
            parentArray="plan_document_urls"
            actualValue={plan.plan_document_urls}
            updateValue={
              updated_data.plan_document_urls &&
              updated_data.plan_document_urls.update_items
                ? updated_data.plan_document_urls.update_items
                : false
            }
            validation={{
              plan_document_urls: Yup.array().of(
                Yup.object().shape({
                  is_mobile: Yup.boolean().required("Select a video device"),
                  document_type: Yup.string().required("Select a video format"),
                  url: Yup.string(),
                  name: Yup.string(),
                })
              ),
            }}
          />
        </div>
        <div className={"d-flex align-items-end justify-content-end col-md-12"}>
          {isEditable && canEdit && hasUpdated && (
            <Popconfirm
              title="Are you sure to discard and close?"
              onConfirm={handleDiscord}
              //  onCancel={cancel}
              okText="Yes"
              cancelText="No"
            >
              <button
                type="button"
                className="btn btn-danger btn-sm float-right btn-add-domain mr-2"
              >
                Discard All & Close
              </button>
            </Popconfirm>
          )}
          <button
            onClick={onClose}
            type="button"
            className="btn btn-danger btn-sm float-right btn-add-domain mr-2"
          >
            Close
          </button>
          {canEdit ? (
            <CustomButton
              type="button"
              className="btn btn-primary btn-outline-success btn-sm float-right btn-add-domain"
              disabled={!formik.isValid || formik.isSubmitting}
              onClick={
                formik.isValid && Object.keys(formik.values).length !== 0
                  ? formik.handleSubmit
                  : handleError
              }
            >
              Update
            </CustomButton>
          ) : (
            <button
              onClick={onDone}
              type="button"
              className="btn btn-secondary btn-sm float-right btn-add-domain mr-2"
            >
              Next
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
