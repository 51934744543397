import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import graphHandler from "../../../Apollo/graphHandler";
import {
  UPDATE_EMPLOYEE_USER,
  UPDATE_USER,
} from "../../../Apollo/ProfileService/profileMutateRequest";
import { toast } from "react-toastify";
import { GenerateID } from "../../../utils/Functions/filter";
import { isImage } from "../../../constants";
import avatar from "../../../assets/Images/avatar.png";
import { useSelector } from "react-redux";
import { uploadHandler } from "../../../utils/CustomHooks/useUploadImage";
const validation = Yup.object().shape({
  email: Yup.string()
    .email("Must be a valid email")
    .max(255)
    .required("Email is required"),
  name: Yup.string()
    .min(2, "Name should be min of 2 characters")
    .max(30, "Name should be maximum of 30 characters")
    .required("Enter a name"),
  profile_image_urls: Yup.string(),
});

export default function UserDetailsComponent({
  value = {},
  profile = {},
  refresh = () => {},
  access,
}) {

  const [edit, setEdit] = useState(false);
  const user = useSelector(({ Auth }) => Auth.user);
  //   console.log(roles);
  const isProfileEdit = edit & (value.user_id === user.user_id);
  const isMine = user.user_id === value.user_id;
  const { canEdit, isAdmin } = access;
  const formik = useFormik({
    initialValues: {
      user_id: value.user_id,
      name: value.name,
      email: value.email,
      designation: profile.designation,
      function: profile.function ? profile.function : "",
    },
    validationSchema: validation,
    onSubmit: (values) => {
      onSubmit(values);
    },
  });
  useEffect(() => {
    if (value.name && value.email && !edit && profile.user_id)
    // console.log("value")
      formik.setValues({
        user_id: value.user_id,
        name: value.name,
        email: value.email,
        profile_image_urls:
          value.profile_image_urls &&
          value.profile_image_urls[0] &&
          value.profile_image_urls[0].url
            ? value.profile_image_urls[0].url
            : "",
        designation: profile.designation ? profile.designation : "",
        function: profile.function ? profile.function : "",
      });
  }, [value, edit, profile]);

  const onSubmit = async (values) => {
    let update_request = { ...values };
    delete update_request.designation;
    delete update_request.function;
    // delete update_request.profile_image_urls;
    if (update_request.email === value.email) {
      delete update_request.email;
    }
    if (!values.profile_image_urls) {
      delete update_request.profile_image_urls;
    } else {
      update_request.profile_image_urls = [
        { image_type: "Default", url: values.profile_image_urls },
      ];
    }
    updateEmployee(values);
    try {
      await graphHandler().invoke(UPDATE_USER, { update_request }, 0, true);
      refresh();
      setEdit(false);
    } catch (error) {
      console.log("updateUser error", error);
    }
  };

  const updateEmployee = async (modal) => {
    let update_request = { user_id: modal.user_id };
    if (
      (modal.designation && modal.designation !== profile.designation) ||
      (modal.function && modal.function !== profile.function)
    ) {
      if (modal.designation) update_request.designation = modal.designation;
      if (modal.function) update_request.function = modal.function;
      try {
        await graphHandler().invoke(
          UPDATE_EMPLOYEE_USER,
          { update_request },
          0,
          false
        );
        refresh();
        setEdit(false);
      } catch (error) {
        console.log("updateEmployee error", error);
      }
    }
  };

  const handleError = () => {
    if (Object.keys(formik.errors).length > 0) {
      toast.error(formik.errors[Object.keys(formik.errors)[0]]);
    }
  };
  const onCancel = () => {
    formik.setValues({
      user_id: value.user_id,
      name: value.name,
      email: value.email,
      profile_image_urls:
        value.profile_image_urls &&
        value.profile_image_urls[0] &&
        value.profile_image_urls[0].url
          ? value.profile_image_urls[0].url
          : "",
    });
    setEdit(false);
  };

  const handleFileChange = async (event) => {
    if (!event.target.files[0]) return;
    const file = new File(
      [event.target.files[0]],
      event.target.files[0].name.replace(/\s/g, ""),
      {
        type: event.target.files[0].type,
      }
    );
    if (isImage(file)) {
      uploadToCloud(file);
    } else {
      toast.error("Please select a valid Image");
    }
  };
  async function uploadToCloud(file) {
    let fileType = "Image";
    let contentId= value.user_id;
    let contentType = "EMPLOYEE";
    try {
      let data = await uploadHandler("employee").upload(file,
        fileType,
        contentId,
        contentType
        );
      formik.setFieldValue("profile_image_urls", data.url);
    } catch (error) {
      console.log("Upload error", error);
      toast.error(error.toString());
    }
  }
  
  return (
    <div className="d-flex flew-wrap justify-content-between w-100 p-0 col-md-12">
      <div className="form-group d-flex flex-row align-items-center">
        <div
          className={`upload-profile mr-2 ${
            isProfileEdit && isMine && "cursor-pointer"
          }`}
          onClick={() => document.getElementById("profile")?.click()}
        >
          <input
            className="upload__input d-none"
            type="file"
            id="profile"
            disabled={!isProfileEdit && !isMine}
            onChange={handleFileChange}
          />
          <img
            src={
              formik.values.profile_image_urls
                ? formik.values.profile_image_urls
                : avatar
            }
          />
          {isProfileEdit && isMine && <i className="fas fa-upload" />}
        </div>
        <div className="d-flex flex-column">
          <p htmlFor="user-name" className=" user-name-readonly mb-0 ">
            <b>
              {formik.values && formik.values.name
                ? formik.values.name
                : "name"}
              's details
            </b>{" "}
            {(canEdit ? !edit : isMine && !edit) && (
              <i
                className="fas fa-edit ml-5"
                onClick={() => setEdit((x) => !x)}
              />
            )}
          </p>
          <p className=" user-email-readonly mb-0">
            <input
              type="text"
              className=""
              name="email"
              maxLength="30"
              value={
                formik.values && formik.values.email ? formik.values.email : ""
              }
              onChange={formik.handleChange}
              disabled={!edit || !canEdit}
            />{" "}
            <small className="text-danger">
              {formik.errors && formik.errors.email ? formik.errors.email : ""}
            </small>
          </p>
          <p className=" user-status-readonly mb-0">
            <input
              type="text"
              className=""
              name="name"
              maxLength="30"
              value={
                formik.values && formik.values.name ? formik.values.name : ""
              }
              onChange={formik.handleChange}
              disabled={!edit}
            />
          </p>
          <small className="text-danger">
            {formik.errors && formik.errors.name ? formik.errors.name : ""}
          </small>
          <p className=" user-email-readonly mb-0">
            <input
              type="text"
              className=""
              name="designation"
              placeholder="Designation"
              maxLength="50"
              value={
                formik.values && formik.values.designation
                  ? formik.values.designation
                  : ""
              }
              onChange={formik.handleChange}
              disabled={!edit}
            />
            <small className="text-danger">
              {formik.errors && formik.errors.designation
                ? formik.errors.designation
                : ""}
            </small>
          </p>
          <p className=" user-email-readonly mb-0">
            <input
              type="text"
              className=""
              name="function"
              placeholder="Function"
              maxLength="50"
              value={
                formik.values && formik.values.function
                  ? formik.values.function
                  : ""
              }
              onChange={formik.handleChange}
              disabled={!edit}
            />
            <small className="text-danger">
              {formik.errors && formik.errors.function
                ? formik.errors.function
                : ""}
            </small>
          </p>

          {edit ? (
            <div className="mt-3 d-flex align-items-center">
              <button type="button" className="btn mr-3" onClick={onCancel}>
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-outline-success"
                disabled={Object.keys(formik.errors).length !== 0}
                onClick={
                  formik.isValid && Object.keys(formik.values).length !== 0
                    ? formik.handleSubmit
                    : handleError
                }
              >
                Save
              </button>
            </div>
          ) : null}
        </div>
      </div>

      <div
        className={`${
          value.active ? "bg-success" : "bg-danger"
        } text-center btn btn-sm h-100 text-white`}
      >
        {value.active ? "Active" : "Disabled"}
      </div>
    </div>
  );
}
