import { Modal, ModalBody } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import CustomButton from "../../../components/Button/CustomButton";
import graphHandler from "../../../Apollo/graphHandler";
import { CREATE_LICENSE_GQL } from "../../../Apollo/ApplicationService/OrganizationResponse";
import { transactionType } from "../../../constants";
import moment from "moment";

export default function AddLicense({
  isOpen,
  setOpen,
  refresh,
  organizationOption = [],
}) {
  const formik = useFormik({
    initialValues: {
      allow_signup: true,
    },
    validationSchema: validation,
    onSubmit: (values) => {
      onSubmit(values);
    },
  });
  const onClose = () => {
    formik.resetForm();
    setOpen();
  };

  const onSubmit = async (values) => {
    const {
      organization_id,
      total_seat_count,
      license_duration_months,
      allow_signup,
      transaction_type,
      comment,
      amount,
      cgst,
      igst,
      sgst,
      vat,
      license_start_ts
    } = values;
    const addReq = {
      organization_id,
      total_seat_count: Number(total_seat_count),
      license_duration_months: Number(license_duration_months),
      allow_signup,
      transaction_type,
      amount: Number(amount),
      cgst: Number(cgst),
      igst: Number(igst),
      sgst: Number(sgst),
      vat: Number(vat),
      license_start_ts:moment(license_start_ts).toISOString()
    };
    if (comment) {
      addReq.comment = comment;
    }
    // console.log(addReq, "addReq");

    try {
      await graphHandler().invoke(CREATE_LICENSE_GQL, { addReq }, 2, true);
      // console.log("data",data)
      refresh();
      onClose();
      formik.setSubmitting(false);
    } catch (error) {
      console.log("CreateLicense error", error);
      formik.setSubmitting(false);
    }
  };

  const handleError = () => {
    if (Object.keys(formik.errors).length > 0) {
      toast.error(formik.errors[Object.keys(formik.errors)[0]]);
    }
  };
  return (
    <Modal toggle={onClose} isOpen={isOpen} centered scrollable size="xl">
      <div className="modal-header">
        <button type="button" className="close" onClick={onClose}>
          <span aria-hidden="true">&times;</span>
        </button>
        <h5 className="modal-title">Add License</h5>
      </div>
      <ModalBody className="pt-0">
        <div className="container">
          <div className="intervention-form justify-content-center row">
            <div className="col-md-12 form-group">
              <label
                htmlFor="organization_id"
                className="col-form-label w-100 mb-2"
              >
                Organization
              </label>
              <select
                id="organization_id"
                name="organization_id"
                className="form-control"
                onChange={formik.handleChange}
                value={formik.values.organization_id}
                onBlur={formik.handleBlur}
              >
                <option key="Select" hidden>
                  Select
                </option>
                {organizationOption.map((x, i) => (
                  <option value={x.value} key={i}>
                    {x.label}
                  </option>
                ))}
              </select>
              <small className="text-danger">
                {formik.touched.organization_id &&
                formik.errors &&
                formik.errors.organization_id
                  ? formik.errors.organization_id
                  : ""}
              </small>
            </div>
            
            <div className="col-md-12 form-group">
              <label
                htmlFor="license_duration_months"
                className="col-form-label w-100 mb-2"
              >
                License Duration (month)
              </label>
              <input
                type="text"
                className="form-control"
                id="license_duration_months"
                name="license_duration_months"
                value={
                  formik.values && formik.values.license_duration_months
                    ? formik.values.license_duration_months
                    : ""
                }
                onChange={(e) =>
                  formik.setFieldValue(
                    "license_duration_months",
                    e.target.value.replace(/[^0-9]/gi, "")
                  )
                }
                onBlur={formik.handleBlur}
              />
              <small className="text-danger">
                {formik.touched.license_duration_months &&
                formik.errors &&
                formik.errors.license_duration_months
                  ? formik.errors.license_duration_months
                  : ""}
              </small>
            </div>
           
           
            <div className="col-md-12 form-group">
              <label
                htmlFor="license_start_ts"
                className="col-form-label w-100 mb-2"
              >
                License Start Date
              </label>
              <input
                type="date"
                className="form-control"
                id="license_start_ts"
                value={formik.values.license_start_ts}
                min={moment().toISOString().split("T")[0]}
                // onChange={(e) => setStartDate(e.target.value)}
                onChange={formik.handleChange}
              />
              <small className="text-danger">
                {formik.touched.license_start_ts &&
                formik.errors &&
                formik.errors.license_start_ts
                  ? formik.errors.license_start_ts
                  : ""}
              </small>
            </div>
            <div className="col-md-12 form-group">
              <label
                htmlFor="total_seat_count"
                className="col-form-label w-100 mb-2"
              >
                Total Seat Count
              </label>
              <input
                type="text"
                className="form-control"
                id="total_seat_count"
                name="total_seat_count"
                value={
                  formik.values && formik.values.total_seat_count
                    ? formik.values.total_seat_count
                    : ""
                }
                onChange={(e) =>
                  formik.setFieldValue(
                    "total_seat_count",
                    e.target.value.replace(/[^0-9]/gi, "")
                  )
                }
                onBlur={formik.handleBlur}
              />
              <small className="text-danger">
                {formik.touched.total_seat_count &&
                formik.errors &&
                formik.errors.total_seat_count
                  ? formik.errors.total_seat_count
                  : ""}
              </small>
            </div>
            <div className="col-md-12 form-group">
              <div className="d-flex flex-row">
                <input
                  className="mr-2"
                  type="checkbox"
                  id="allow_signup"
                  name="allow_signup"
                  onChange={(e) =>
                    formik.setFieldValue("allow_signup", e.target.checked)
                  }
                  checked={formik.values.allow_signup}
                  onBlur={formik.handleBlur}
                  //   disabled={formik.values.screener_question_id}
                />
                <label className="col-form-label w-100">Allow Signup</label>
                <small className="text-danger"></small>
              </div>
            </div>
            <div className="col-md-12 form-group">
              <label
                htmlFor="transaction_type"
                className="col-form-label w-100 mb-2"
              >
                Transaction Type
              </label>
              <select
                id="transaction_type"
                name="transaction_type"
                className="form-control"
                onChange={formik.handleChange}
                value={formik.values.transaction_type}
                onBlur={formik.handleBlur}
              >
                <option key="Select" hidden>
                  Select
                </option>
                {transactionType.map((x, i) => (
                  <option value={x.value} key={i}>
                    {x.label}
                  </option>
                ))}
              </select>
              <small className="text-danger">
                {formik.touched.transaction_type &&
                formik.errors &&
                formik.errors.transaction_type
                  ? formik.errors.transaction_type
                  : ""}
              </small>
            </div>
            <div className="col-md-12 form-group ">
              <label htmlFor="amount" className="col-form-label w-100 mb-2">
                Amount
              </label>
              <input
                type="text"
                className="form-control"
                id="amount"
                name="amount"
                value={
                  formik.values && formik.values.amount
                    ? formik.values.amount
                    : ""
                }
                onChange={(e) =>
                  formik.setFieldValue(
                    "amount",
                    e.target.value.replace(/[^0-9+]/gi, "")
                  )
                }
                onBlur={formik.handleBlur}
              />
              <small className="text-danger">
                {formik.touched.amount && formik.errors && formik.errors.amount
                  ? formik.errors.amount
                  : ""}
              </small>
            </div>
           
            
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-6 form-group ">
                  <label htmlFor="cgst" className="col-form-label w-100 mb-2">
                    CGST
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="cgst"
                    name="cgst"
                    value={
                      formik.values && formik.values.cgst
                        ? formik.values.cgst
                        : ""
                    }
                    onChange={(e) =>
                      formik.setFieldValue(
                        "cgst",
                        e.target.value.replace(/[^0-9+]/gi, "")
                      )
                    }
                    onBlur={formik.handleBlur}
                  />
                  <small className="text-danger">
                    {formik.touched.cgst && formik.errors && formik.errors.cgst
                      ? formik.errors.cgst
                      : ""}
                  </small>
                </div>
                <div className="col-md-6 form-group ">
                  <label htmlFor="igst" className="col-form-label w-100 mb-2">
                    IGST
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="igst"
                    name="igst"
                    value={
                      formik.values && formik.values.igst
                        ? formik.values.igst
                        : ""
                    }
                    onChange={(e) =>
                      formik.setFieldValue(
                        "igst",
                        e.target.value.replace(/[^0-9+]/gi, "")
                      )
                    }
                    onBlur={formik.handleBlur}
                  />
                  <small className="text-danger">
                    {formik.touched.igst && formik.errors && formik.errors.igst
                      ? formik.errors.igst
                      : ""}
                  </small>
                </div>
                <div className="col-md-6 form-group ">
                  <label htmlFor="sgst" className="col-form-label w-100 mb-2">
                    SGST
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="sgst"
                    name="sgst"
                    value={
                      formik.values && formik.values.sgst
                        ? formik.values.sgst
                        : ""
                    }
                    onChange={(e) =>
                      formik.setFieldValue(
                        "sgst",
                        e.target.value.replace(/[^0-9+]/gi, "")
                      )
                    }
                    onBlur={formik.handleBlur}
                  />
                  <small className="text-danger">
                    {formik.touched.sgst && formik.errors && formik.errors.sgst
                      ? formik.errors.sgst
                      : ""}
                  </small>
                </div>
                <div className="col-md-6 form-group ">
                  <label htmlFor="vat" className="col-form-label w-100 mb-2">
                    VAT
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="vat"
                    name="vat"
                    value={
                      formik.values && formik.values.vat
                        ? formik.values.vat
                        : ""
                    }
                    onChange={(e) =>
                      formik.setFieldValue(
                        "vat",
                        e.target.value.replace(/[^0-9+]/gi, "")
                      )
                    }
                    onBlur={formik.handleBlur}
                  />
                  <small className="text-danger">
                    {formik.touched.vat && formik.errors && formik.errors.vat
                      ? formik.errors.vat
                      : ""}
                  </small>
                </div>
              </div>
            </div>
            <div className="col-md-12 form-group">
              <label htmlFor="comment" className="col-form-label w-100 mb-2">
                Comments
              </label>
              <textarea
                rows="3"
                className="form-control"
                type="text"
                id="comment"
                name="comment"
                onChange={formik.handleChange}
                value={formik.values.comment}
                onBlur={formik.handleBlur}
              />
              <small className="text-danger">
                {formik.touched.comment &&
                formik.errors &&
                formik.errors.comment
                  ? formik.errors.comment
                  : ""}
              </small>
            </div>

            <div className="d-flex align-items-end justify-content-end col-md-12 mt-3">
              <button
                onClick={onClose}
                type="button"
                className="btn btn-danger btn-sm float-right btn-add-domain mr-2"
              >
                Close
              </button>
              <CustomButton
                type="button"
                className="btn btn-primary btn-outline-success btn-sm float-right btn-add-domain"
                disabled={
                  Object.keys(formik.errors).length !== 0 ||
                  !formik.isValid ||
                  formik.isSubmitting
                }
                onClick={
                  formik.isValid && Object.keys(formik.values).length !== 0
                    ? formik.handleSubmit
                    : handleError
                }
              >
                {"Create"}
              </CustomButton>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
}

const validation = Yup.object().shape({
  // month: Yup.string(),
  organization_id: Yup.string().required("Select organization"),
  transaction_type: Yup.string().required("Select transaction type"),
  // country_name: Yup.string().required("Enter country"),
  total_seat_count: Yup.string().required("Enter total seat count"),
  amount: Yup.string().required("Enter amount"),
  license_duration_months: Yup.string().required("Enter license duration"),
  cgst: Yup.string(),
  igst: Yup.string(),
  sgst: Yup.string(),
  vat: Yup.string(),
  license_start_ts:Yup.date().required("Select start date"),
});
