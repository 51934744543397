import React from "react";
import CreateModule from "../../Therapy/components/CreateModule";
import { Modal, ModalBody } from "reactstrap";

export default function CreateModuleModal({
  isOpen,
  therapy = {},
  setOpen,
  refresh,
}) {
  const onClose = () => {
    setOpen();
    refresh();
  };
  return (
    <Modal
      toggle={onClose}
      isOpen={isOpen}
      modalClassName="modal-fullscreen modal-dialog-scrollable"
    >
      <div className="modal-header">
        <button type="button" className="close" onClick={onClose}>
          <span aria-hidden="true">&times;</span>
        </button>
        <h5 className="modal-title">Create or Update Module</h5>
      </div>
      <ModalBody>
        <div className="container">
          <CreateModule therapy={therapy} refresh={refresh} />
        </div>
      </ModalBody>
    </Modal>
  );
}
