import { useState } from "react";
import { Modal, ModalBody } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import graphHandler from "../../../Apollo/graphHandler";
import {
  CREATE_CATEGORY_GQL,
  UPDATE_CATEGORY_GQL,
} from "../../../Apollo/AdminServices/MutationRequest";
import { ContentTypes, ImageFormat } from "../../../constants";
import Description from "../../../components/Input/Description";
import CustomButton from "../../../components/Button/CustomButton";
import MultiSelect from "../../../components/MultiSelect/MultiSelect";
import { useSelector } from "react-redux";
import SingleMediaFileUpload from "../../../components/MediaFileUpload/SingleMediaFileUpload";

export default function CreateCategory({ isOpen, setOpen, refresh }) {
  const [categoryId, setCategoryId] = useState("");

  const subDomains = useSelector(({ DataSet }) => DataSet.subDomains);

  const formik = useFormik({
    initialValues: {},
    validationSchema: validation,
    onSubmit: (values) => {
      onSubmit(values);
    },
  });
  const formik1 = useFormik({
    initialValues: {},
    validationSchema: Yup.object().shape({
      media_datas: Yup.array().of(
        Yup.object().shape({
          media_device: Yup.string().required("Select a media device"),
          media_format: Yup.string().required("Select a media format"),
          mediaUrl: Yup.object(),
        })
      ),
    }),
    onSubmit: (values) => {
      onEditSubmit(values);
    },
  });
  const onClose = () => {
    formik.resetForm();
    formik.setValues({});
    setOpen();
    formik1.resetForm();
    formik1.setValues({});
    setCategoryId("");
    // setImage({});
  };
  const onSubmit = async (values) => {
    const {
      category_name,
      category_description,
      mobile_description,
      supported_content_types,
      sub_domain_id,
      category_code,
      sort_order,
    } = values;
    const addReq = {
      category_name,
      category_code,
      sort_order: Number(sort_order),
      supported_content_types: [...supported_content_types.map((x) => x.value)],
    };
    if (sub_domain_id) {
      addReq.sub_domain_id = sub_domain_id;
    }
    // if (image.url) {
    //   addReq.category_image_urls = [{ image_type: "Default", url: image.url }];
    // }
    let description = [];
    if (category_description) {
      description.push({
        client_type: "Web",
        description: category_description,
      });
    }
    if (mobile_description) {
      description.push({
        client_type: "Mobile",
        description: mobile_description,
      });
    }
    if (description.length > 0) addReq.category_description = description;
    try {
      const { data } = await graphHandler().invoke(
        CREATE_CATEGORY_GQL,
        { addReq },
        1,
        true
      );
      if (data) {
        setCategoryId(data?.AddCategory?.category?.category_id);
      }
      // refresh();
      // onClose();
      formik.setSubmitting(false);
    } catch (error) {
      console.log("CreateCategory error", error);
      formik.setSubmitting(false);
    }
  };

  const handleError = () => {
    if (Object.keys(formik.errors).length > 0) {
      toast.error(formik.errors[Object.keys(formik.errors)[0]]);
    }
  };

  const onEditSubmit = async (value) => {
    // console.log("submit", value);
    const { media_datas } = value;
    const update_request = {
      category_id: categoryId,
    };

    let category_image_urls = [];

    // console.log("media_datas", media_datas);
    if (media_datas && media_datas.length > 0) {
      media_datas.map((x) => {
        category_image_urls.push({
          image_type: x.media_format,
          url: x.mediaUrl.url,
          is_mobile: x.media_device === "false" ? false : true,
        });
      });
    }
    if (category_image_urls.length > 0) {
      update_request.category_image_urls = category_image_urls;
    }
    // console.log("update_request", update_request);
    try {
      // formik1.setSubmitting(false);
      // return;
      const { data } = await graphHandler().invoke(
        UPDATE_CATEGORY_GQL,
        { update_request },
        1,
        true
      );
      refresh();
      onClose();
      // console.log("update data", data);
    } catch (error) {
      console.log("update category error", error);
      formik1.setSubmitting(false);
    }
  };
  const handleEditError = () => {
    if (Object.keys(formik1.errors).length > 0) {
      toast.error(formik1.errors[Object.keys(formik1.errors)[0]]);
    }
  };
  return (
    <Modal toggle={onClose} isOpen={isOpen} centered scrollable size="xl">
      <div className="modal-header">
        <button type="button" className="close" onClick={onClose}>
          <span aria-hidden="true">&times;</span>
        </button>
        <h5 className="modal-title">Create Category</h5>
      </div>
      <ModalBody className="pt-0">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12 form-group  ">
              <label
                htmlFor="category_name"
                className="col-form-label w-100 mb-2"
              >
                Name
              </label>
              <input
                type="text"
                className="form-control"
                id="category_name"
                disabled={categoryId !== ""}
                name="category_name"
                // maxLength="30"
                value={
                  formik.values && formik.values.category_name
                    ? formik.values.category_name
                    : ""
                }
                onChange={(e) =>
                  formik.setFieldValue(
                    "category_name",
                    e.target.value.trimStart()
                  )
                }
                onBlur={(e) => {
                  formik.setFieldTouched("category_name", true);
                  formik.setFieldValue(
                    "category_name",
                    e.target.value.replace(/\s+/g, " ").trim()
                  );
                }}
              />
              <small className="text-danger">
                {formik.touched.category_name &&
                formik.errors &&
                formik.errors.category_name
                  ? formik.errors.category_name
                  : ""}
              </small>
            </div>
            <div className="col-md-12 form-group">
              <label
                htmlFor="category_code"
                className="col-form-label w-100 mb-2"
              >
                Code
              </label>
              <input
                type="text"
                className="form-control"
                id="category_code"
                disabled={categoryId !== ""}
                name="category_code"
                onChange={formik.handleChange}
                // disabled={subDomainId !== ""}
                value={
                  formik.values.category_code ? formik.values.category_code : ""
                }
                onBlur={formik.handleBlur}
              />
              <small className="text-danger align-self-end">
                {formik.touched.category_code &&
                formik.errors &&
                formik.errors.category_code
                  ? formik.errors.category_code
                  : ""}
              </small>
            </div>
            <div className="col-md-12 form-group">
              <label htmlFor="sort_order" className="col-form-label w-100 mb-2">
                Sort Order
              </label>
              <div className="d-flex flex-column p-0">
                <input
                  type="number"
                  className="form-control h-100 w-100"
                  placeholder=""
                  id="sort_order"
                  disabled={categoryId !== ""}
                  name="sort_order"
                  max={100}
                  // disabled={subDomainId !== ""}
                  value={
                    formik.values && formik.values.sort_order
                      ? formik.values.sort_order
                      : ""
                  }
                  onBlur={formik.handleBlur}
                  onChange={(e) =>
                    formik.setFieldValue(
                      "sort_order",
                      e.target.value.replace(/[^0-9\.]/g, "")
                    )
                  }
                />
                <small className="text-danger">
                  {formik.touched.sort_order && formik.errors.sort_order
                    ? formik.errors.sort_order
                    : ""}
                </small>
              </div>
            </div>
            <div className="col-md-12 form-group">
              <label
                htmlFor="supported_content_types"
                className="col-form-label w-100 mb-2"
              >
                Supported Content Type
              </label>
              <MultiSelect
                option={ContentTypes}
                onChange={(e) =>
                  formik.setFieldValue("supported_content_types", e)
                }
                placeholder="Select"
                isCreatable={false}
                isMulti={true}
                type="Content"
                onBlur={formik.handleBlur}
                disabled={categoryId !== ""}
                name="supported_content_types"
                defaultValue={formik.values.supported_content_types}
              />
              <small className="text-danger align-self-end">
                {formik.touched["react-select-3-input"] &&
                formik.errors &&
                formik.errors.supported_content_types
                  ? formik.errors.supported_content_types
                  : ""}
              </small>
            </div>
            <div className="col-md-12 form-group ">
              <label
                htmlFor="sub_domain_id"
                className="col-form-label w-100 mb-2"
              >
                Sub Domain
              </label>
              <select
                id="sub_domain_id"
                name="sub_domain_id"
                className="custom-select"
                disabled={categoryId !== ""}
                onChange={formik.handleChange}
                value={
                  formik.values.sub_domain_id ? formik.values.sub_domain_id : ""
                }
                onBlur={formik.handleBlur}
              >
                <option key="Select" hidden>
                  Select
                </option>
                {subDomains.map((x, i) => (
                  <option key={i} value={x.sub_domain_id}>
                    {x.sub_domain_name}
                  </option>
                ))}
              </select>
              <small className="text-danger align-self-end">
                {formik.touched.sub_domain_id &&
                formik.errors &&
                formik.errors.sub_domain_id
                  ? formik.errors.sub_domain_id
                  : ""}
              </small>
            </div>
            <Description
              formik={formik}
              webName="category_description"
              disabled={categoryId !== ""}
            />

            {categoryId === "" ? (
              <>
                <button
                  onClick={onClose}
                  type="button"
                  className="btn btn-danger btn-sm float-right btn-add-domain mr-2"
                >
                  Close
                </button>
                <CustomButton
                  type="button"
                  className="btn btn-primary btn-outline-success float-right btn-add-domain"
                  disabled={
                    Object.keys(formik.errors).length !== 0 ||
                    !formik.isValid ||
                    formik.isSubmitting
                  }
                  onClick={
                    formik.isValid && Object.keys(formik.values).length !== 0
                      ? formik.handleSubmit
                      : handleError
                  }
                >
                  Create
                </CustomButton>
              </>
            ) : null}
            {categoryId !== "" ? (
              <>
                <div className="col-md-12">
                  <SingleMediaFileUpload
                    formik={formik1}
                    heading="Image File"
                    contentId={categoryId}
                    contentType="CATEGORY"
                    mediaType="Image"
                    parentArray="media_datas"
                    mediaFormat={ImageFormat}
                  />
                </div>
                <div className="d-flex align-items-end justify-content-end col-md-12 mt-3">
                  <button
                    onClick={onClose}
                    type="button"
                    className="btn btn-danger btn-sm float-right btn-add-domain mr-2"
                  >
                    Close
                  </button>
                  <CustomButton
                    type="button"
                    className="btn btn-primary btn-outline-success float-right btn-add-domain"
                    disabled={
                      Object.keys(formik1.errors).length !== 0 ||
                      !formik1.isValid ||
                      formik1.isSubmitting
                    }
                    onClick={() => {
                      formik1.isValid &&
                      Object.keys(formik1.values).length !== 0
                        ? formik1.handleSubmit()
                        : handleEditError();
                    }}
                  >
                    Update
                  </CustomButton>
                </div>
              </>
            ) : null}
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
}

const validation = Yup.object().shape({
  sub_domain_id: Yup.string(),
  category_description: Yup.string(),
  mobile_description: Yup.string(),
  category_name: Yup.string().required("Enter a name"),
  category_code: Yup.string().required("Enter a code"),
  sort_order: Yup.string().required("Enter sort order"),
  // .min(4, "Name should be min of 4 characters")
  // .max(30, "Name should be maximum of 30 characters")
  supported_content_types: Yup.array()
    .of(Yup.object())
    .test({
      message: "Select content types",
      test: (arr) => {
        return arr && arr.length > 0;
      },
    }),
});
