import { useEffect, useState } from "react";
import { GET_QUESTIONAIRE_LIST_GQL } from "../../Apollo/AdminServices/QueryRequest";
import graphHandler from "../../Apollo/graphHandler";
import { sortArray } from "../Functions/filter";
function useQuestionaireList(id) {
  const [questionaireList, setQuestions] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (id) {
      getQuestionaireList(id);
    } else {
      setQuestions([]);
    }
  }, [id]);
  const getQuestionaireList = async (req = {}) => {
    setLoading(true);
    try {
      const { data } = await graphHandler().invoke(
        GET_QUESTIONAIRE_LIST_GQL,
        req,
        1,
        false,
        true
      );
      if (
        data &&
        data.getScreenerQuestionList &&
        data.getScreenerQuestionList.screener_questions &&
        data.getScreenerQuestionList.screener_questions.length > 0
      ) {
        // console.log(
        //   "getQuestionaireList Success",
        //   data.getScreenerQuestionList.screener_questions
        // );
        const struct = data.getScreenerQuestionList.screener_questions
          .slice()
          .map((x) => ({
            ...x.screener_question,
            pending_history: x.pending_screener_question_history,
          }));
        setQuestions([]);
        setQuestions(sortArray(struct));
        setLoading(false);
      } else {
        setQuestions([]);
        setLoading(false);
      }
    } catch (error) {
      console.log("getQuestionaireList Error", error);
      setQuestions([]);
      setLoading(false);
    }
  };
  return {
    questionaireList,
    loading,
    getQuestionaireList,
    resetQuestions: () => setQuestions([]),
  };
}
export default useQuestionaireList;
