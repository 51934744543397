import { toast } from "react-toastify";
import { isDocument, isImage, isVideo } from "../../constants";
import { uploadHandler } from "../../utils/CustomHooks/useUploadImage";
import { formatBytes } from "../../utils/Functions/filter";

export default function SingleMediaFormikWithType({
  formik,
  id = "id",
  mobileKey = "mobileid",
  label = "Label",
  props = {},
  className = "col-md-6",
  contentId,
  contentType,
  acceptedTypes = ["Image", "Video", "Document"],
}) {
  const hasVideo = acceptedTypes.includes("Video");
  const hasImage = acceptedTypes.includes("Image");
  const hasDocument = acceptedTypes.includes("Document");

  const handleFileChange = async (event) => {
    const { name, files } = event.target;
    if (!files) return;
    let options = { ...formik.values[name] };
    if (!files[0]) return;
    options = { isUpload: true };
    options.size = formatBytes(files[0].size);
    options.name = files[0].name;
    formik.setFieldValue(name, options);
    const file = new File([files[0]], files[0].name.replace(/\s/g, ""), {
      type: files[0].type,
    });
    if (
      (hasVideo && isVideo(file)) ||
      (hasImage && isImage(file)) ||
      (hasDocument && isDocument(file))
    ) {
      uploadToCloud(file, name, options);
    } else {
      toast.error("Please select a valid file");
      formik.setFieldValue(name, undefined);
    }
  };

  async function uploadToCloud(file, name, obj) {
    let options = { ...obj };
    let fileType = isImage(file)
      ? "Image"
      : isVideo(file)
      ? "Video"
      : "Document";
    try {
      let data = await uploadHandler(
        contentType ? contentType.toLowerCase() : "blog"
      ).upload(file, fileType, contentId, contentType);
      options.name = file.name;
      options.url = data.url;
      options.isUpload = false;
      options.progress = 100;
      options.type = fileType;
      // console.log("Uploading then", options, data);
      formik.setFieldValue(name, options);
    } catch (error) {
      console.log("Upload error", error);
      toast.warning(error);
      formik.setFieldValue(name, undefined);
    }
  }

  return (
    <div
      className={`input-group  form-group align-items-start justify-content-start  ${className}`}
    >
      <label className="col-form-label mb-2 w-100">{label}</label>
      <div className="d-flex w-100 flex-row justify-content-between">
        <button
          id="custom-file"
          onClick={() => document.getElementById(id).click()}
        >
          Upload File (Web)
        </button>
        <button
          id="custom-file"
          onClick={() => document.getElementById(mobileKey).click()}
        >
          Upload File (Mobile)
        </button>
      </div>
      <input
        type="file"
        className="custom-file-input d-none"
        id={id}
        name={id}
        onBlur={formik.handleBlur}
        onChange={handleFileChange}
        {...props}
      />
      <input
        type="file"
        className="custom-file-input d-none"
        id={mobileKey}
        name={mobileKey}
        onBlur={formik.handleBlur}
        onChange={handleFileChange}
        {...props}
      />
      {formik.values &&
      formik.values[id] &&
      (formik.values[id].url ||
        formik.values[id].progress ||
        formik.values[id].name) ? (
        <div className="upload__list col-md-12 mt-2">
          <div className="upload__info flex">
            {formik.values[id].type === "Video" ? (
              <video src={formik.values[id].url} className="upload__img" />
            ) : (
              <img src={formik.values[id].url} className="upload__img" />
            )}
            <div className="flex w-100 wrap">
              <p className="upload__name">
                {formik.values[id].name || "Uploaded Banner"}
              </p>
              {formik.values[id].name &&
                formik.values[id].size &&
                formik.values[id].size != 0 && (
                  <p className="upload__size">( {formik.values[id].size} )</p>
                )}
              {formik.values[id].name &&
                formik.values[id].progress != 0 &&
                formik.values[id].isUpload && (
                  <p className="upload__long">{formik.values[id].progress}%</p>
                )}
              {formik.values[id].name && formik.values[id].progress == 100 && (
                <p className="upload__long text-success">Done</p>
              )}
              <a className="upload__delete upload__btn">
                <i
                  className="fas fa-minus-circle"
                  onClick={() =>
                    formik.setFieldValue(id, {
                      image_type: "Default",
                      url: "",
                      is_mobile: false,
                    })
                  }
                />
              </a>
              {formik.values[id].name &&
                formik.values[id].isUpload &&
                formik.values[id].progress != 0 &&
                formik.values[id].progress != 100 && (
                  <div className="upload__progress">
                    <div
                      className="upload__bar"
                      style={{
                        width: formik.values[id].progress + "%",
                      }}
                    />
                  </div>
                )}
            </div>
          </div>
        </div>
      ) : null}
      {formik.values &&
      formik.values[mobileKey] &&
      (formik.values[mobileKey].url ||
        formik.values[mobileKey].progress ||
        formik.values[mobileKey].name) ? (
        <div className="upload__list col-md-12 mt-2">
          <div className="upload__info flex">
            {formik.values[mobileKey].type === "Video" ? (
              <video
                src={formik.values[mobileKey].url}
                className="upload__img"
              />
            ) : (
              <img src={formik.values[mobileKey].url} className="upload__img" />
            )}
            <div className="flex w-100 wrap">
              <p className="upload__name">
                {formik.values[mobileKey].name || "Uploaded Banner"}
              </p>
              {formik.values[mobileKey].name &&
                formik.values[mobileKey].size &&
                formik.values[mobileKey].size != 0 && (
                  <p className="upload__size">
                    ( {formik.values[mobileKey].size} )
                  </p>
                )}
              {formik.values[mobileKey].name &&
                formik.values[mobileKey].progress != 0 &&
                formik.values[mobileKey].isUpload && (
                  <p className="upload__long">
                    {formik.values[mobileKey].progress}%
                  </p>
                )}
              {formik.values[mobileKey].name &&
                formik.values[mobileKey].progress == 100 && (
                  <p className="upload__long text-success">Done</p>
                )}
              <a className="upload__delete upload__btn">
                <i
                  className="fas fa-minus-circle"
                  onClick={() =>
                    formik.setFieldValue(mobileKey, {
                      image_type: "Default",
                      url: "",
                      is_mobile: true,
                    })
                  }
                />
              </a>
              {formik.values[mobileKey].name &&
                formik.values[mobileKey].isUpload &&
                formik.values[mobileKey].progress != 0 &&
                formik.values[mobileKey].progress != 100 && (
                  <div className="upload__progress">
                    <div
                      className="upload__bar"
                      style={{
                        width: formik.values[mobileKey].progress + "%",
                      }}
                    />
                  </div>
                )}
            </div>
          </div>
        </div>
      ) : null}
      <small className="text-danger col-md-12 mt-1">
        {formik.errors && formik.errors[id]
          ? formik.errors.banner
          : formik.errors[mobileKey]
          ? formik.errors[mobileKey]
          : ""}
      </small>
    </div>
  );
}
