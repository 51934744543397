import { useState } from "react";
import { REMOVE_PLATFORM_CONTENT_GQL } from "../../Apollo/AdminServices/MutationRequest";
import { GET_PLATFORM_CONTENT_LIST_GQL } from "../../Apollo/AdminServices/QueryRequest";
import graphHandler from "../../Apollo/graphHandler";
function usePlatformList(preLoad = false) {
  const [platformContentList, setPlatformList] = useState([]);
  const [loading, setLoading] = useState(preLoad);

  const getPlatformList = async (req = {}) => {
    setPlatformList([]);
    setLoading(true);
    try {
      const { data } = await graphHandler().invoke(
        GET_PLATFORM_CONTENT_LIST_GQL,
        { ...req },
        1,
        false,
        true
      );
      if (
        data &&
        data.getPlatformContentModeList &&
        data.getPlatformContentModeList.platform_content_modes &&
        data.getPlatformContentModeList.platform_content_modes.length > 0
      ) {
        // console.log(
        //   "getPlatformContentModeList Success",
        //   data.getPlatformContentModeList.platform_content_modes
        // );
        let features = data.getPlatformContentModeList.platform_content_modes
          .slice()
          .sort(
            (a, b) =>
              new Date(b.updated_at ? b.updated_at : b.created_at) -
              new Date(a.updated_at ? a.updated_at : a.created_at)
          );

        setPlatformList(features);
        setLoading(false);
      } else {
        setPlatformList([]);
        setLoading(false);
      }
    } catch (error) {
      console.log("getPlatformContentModeList Error", error);
      setPlatformList([]);
      setLoading(false);
    }
  };
  const removePlatfom = async (
    req = {},
    callback = () => {},
    eCallBack = () => {}
  ) => {
    setLoading(true);
    try {
      await graphHandler().invoke(REMOVE_PLATFORM_CONTENT_GQL, req, 1, false, true);
      callback();
      setLoading(false);
    } catch (error) {
      eCallBack(error);
      console.log("removeBanner Error", error);
      setLoading(false);
    }
  };

  return { platformContentList, loading, getPlatformList,removePlatfom };
}
export default usePlatformList;
