const userMenuList = [
  {
    name: "System User",
    icon: <i className="fas fa-users" />,
    url: "home",
  },
  {
    name: "Organization",
    icon: <i className="fas fa-building" />,
    url: "organization",
  },
  {
    name: "Organization Employee",
    icon: <i className="fas fa-industry" />,
    url: "org-employee",
  },
  {
    name: "License",
    icon: <i className="fas fa-scroll" />,
    url: "license",
  },
  {
    name: "Transaction",
    icon: <i className="fas fa-scroll" />,
    url: "transaction",
  },
];

const contentMenuList = [
  {
    name: "Domain",
    icon: <i className="fas fa-adjust" />,
    url: "domain",
  },
  {
    name: "Sub Domain",
    icon: <i className="fas fa-chart-pie" />,
    url: "sub-domain",
  },
  {
    name: "Category",
    icon: <i className="fas fa-tag" />,
    url: "category",
  },
  {
    name: "Intervention",
    icon: <i className="fas fa-chart-area" />,
    url: "intervention",
  },
  {
    name: "Plans",
    icon: <i className="fa fa-box" />,
    url: "Plans",
  },
  {
    name: "Therapy",
    icon: <i className="fas fa-chart-line" />,
    url: "therapy",
  },
  {
    name: "Screener",
    icon: <i className="fas fa-clipboard-list" />,
    url: "screener",
  },
  {
    name: "Explainer Video",
    icon: <i className="fas fa-newspaper" />,
    url: "explainer",
  },
  {
    name: "Author",
    icon: <i className="fas fa-feather-alt" />,
    url: "author",
  },
  {
    name: "Blog",
    icon: <i className="fas fa-blog" />,
    url: "blog",
  },
  {
    name: "Platform Contents",
    icon: <i className="fas fa-star" />,
    url: "platform",
  },
  //   {
  //     name: "Objectives",
  //     icon: <i className="fas fa-bullseye" />,
  //     url: "objective",
  //   },
  {
    name: "Rule Engine",
    icon: <i className="fas fa-solar-panel" />,
    url: "rule-engine",
  },
  {
    name: "Template",
    icon: <i className="fas fa-envelope" />,
    url: "template",
  },
  {
    name: "Localized Data",
    icon: <i className="fas fa-globe" />,
    url: "localize",
  },
  {
    name: "Banner System",
    icon: <i className="fas fa-window-maximize" />,
    url: "banner",
  },
  {
    name: "FAQ",
    icon: <i className="fas fa-question" />,
    url: "faq",
  },
  {
    name: "Policy",
    icon: <i className="fas fa-file-signature" />,
    url: "policy",
  },
  // {
  //   name: "",
  //   icon: <i className="" />,
  //   url: "/",
  // },
];
const masterMenuList = [
  {
    name: "Country",
    icon: <i className="fas fa-flag" />,
    url: "country",
  },
  {
    name: "City",
    icon: <i className="fas fa-map-marker-alt" />,
    url: "city",
  },
  {
    name: "TimeZone",
    icon: <i className="fas fa-clock"></i>,
    url: "timeZone",
  },
  {
    name: "Industry",
    icon: <i className="fas fa-industry" />,
    url: "industry",
  },
  {
    name: "Function",
    icon: <i className="fas fa-jedi" />,
    url: "function",
  },
  {
    name: "Education",
    icon: <i className="fas fa-book-open" />,
    url: "education",
  },
  {
    name: "Language",
    icon: <i className="fas fa-language" />,
    url: "language",
  },
];
const gameMenuList = [
  {
    name: "Game config",
    icon: <i className="fas fa-gamepad" />,
    url: "/game",
  },
];

export { userMenuList, contentMenuList, masterMenuList, gameMenuList };

export const AdminRoles = [
  //   { name: "Super Admin", value: "SUPER_ADMIN" },
  { name: "Admin", value: "ADMIN" },
  { name: "Content Creator", value: "CONTENT_CREATOR" },
  { name: "Content Reviewer", value: "CONTENT_REVIEWER" },
  { name: "Content Approver", value: "CONTENT_APPROVER" },
];

export const QuestionOptionType = [
  "Text",
  "Image",
  //   "Country",
  //   "Language",
  //   "Currency",
  //   "Date",
];

export const DataOptionType = [
  "RadioButton",
  //   "Rating",
  //   "TextField",
  //   "CheckBox",
  //   "TextArea",
  //   "MultiSelectDropdown",
  //   "SingleSelectDropdown",
  //   "DocumentUpload",
  //   "ImageCapture",
  //   "Date",
];

export const ActionTypes = ["Intervention", "Document", "Video", "Screener"];

export const ActivityType = [
  "Meditation",
  "Activity",
  "Exercise",
  "Game",
  "Screener",
  "Music",
];

export const VisibilityStatus = [
  { name: "Active", value: "Active" },
  { name: "InActive", value: "InActive" },
  { name: "Coming Soon", value: "ComingSoon" },
];
export const RangeColor = [
  { label: "Red", value: "#FF0D0D" },
  { label: "Orange", value: "#FF4E11" },
  { label: "Amber", value: "#FF8E15" },
  { label: "Yellow", value: "#F7DE3A" },
  { label: "BRASS", value: "#ACB334" },
  { label: "APPLE", value: "#69B34C" },
];
export const MediaType = [
  { label: "Image", value: "Image" },
  { label: "Video", value: "Video" },
  { label: "Document", value: "Document" },
];
export const MediaDevice = [
  { label: "Web", value: false },
  { label: "Mobile", value: true },
];
export const ImageFormat = [
  { label: "Default", value: "Default" },
  { label: "Banner", value: "Banner" },
  { label: "Thumbnail", value: "Thumbnail" },
  { label: "Gif", value: "Gif" },
  { label: "Logo", value: "Logo" },
];
export const OrgImageFormat = [
  { label: "Default", value: "Default" },
  { label: "Banner", value: "Banner" },
  { label: "Thumbnail", value: "Thumbnail" },
  { label: "Gif", value: "Gif" },
  { label: "Logo", value: "Logo" },
  { label: "Favicon", value: "Favicon" },
];
export const VideoFormat = [
  { label: "Default", value: "Default" },
  { label: "Banner", value: "Banner" },
];
export const DocumentFormat = [
  { label: "Document", value: "Document" },
  { label: "PDF", value: "PDF" },
  // { label: "Image", value: "Image" },
];
export const PresentColor = [
  { title: "Red", color: "#FF0D0D" },
  { title: "Orange", color: "#FF4E11" },
  { title: "Amber", color: "#FF8E15" },
  { title: "Yellow", color: "#F7DE3A" },
  { title: "BRASS", color: "#ACB334" },
  { title: "APPLE", color: "#69B34C" },
  { title: "Black", color: "#000000" },
  { title: "White", color: "#ffffff" },
];

export const InterventionFormat = [
  { label: "Web", value: "Web" },
  { label: "App", value: "App" },
  { label: "VR", value: "VR" },
  //   { label: "Android Mobile", value: "AndroidMobile" },
  //   { label: "Android Tablets", value: "AndroidTablets" },
  //   { label: "IOS Mobile", value: "IOSMobile" },
  //   { label: "IOS Tablets", value: "IOSTablets" },
  //   { label: "Windows PC", value: "WindowsPC" },
  //   { label: "Mac", value: "Mac" },
  //   { label: "Oculus Quest", value: "OculusQuest" },
];

export const MetaDataType = ["Params", "Instruction"];

export const SubDomainMetaDataType = ["ReportDescription"];

export const FontSize = [
  { label: "H6", value: "1vw" },
  { label: "H5", value: "1.5vw" },
  { label: "H4", value: "2vw" },
  { label: "H3", value: "2.5vw" },
  { label: "H2", value: "3vw" },
  { label: "H1", value: "4vw" },
];

export const SocialProvider = [
  "Facebook",
  "Google",
  "Linkedin",
  "TrueCaller",
  "Twitter",
  "Youtube",
  "Instagram",
];

export const isImage = (file) =>
  file && file.name.toLowerCase().match(/\.(jpg|jfif|jpeg|png|gif|avif|webp)$/);
export const isDocument = (file) =>
  file &&
  file.name.toLowerCase().match(/\.(pdf|doc|docx|txt|csv|xls|xlsx|ppt|pptx)$/);

export const isPDF = (file) =>
  file && file.name.toLowerCase().match(/\.(pdf)$/);

export const isVideo = (file) =>
  file &&
  file.name
    .toLowerCase()
    .match(/\.(flv|mp4|m3u8|ts|3gp|mov|avi|wmv|webm|gif)$/);

export const isAudio = (file) =>
  file && file.name.toLowerCase().match(/\.(mp3|ogg|wav)$/);

// export const isImage = (file) =>
//   file && file.name.toLowerCase().match(/\.(jpg|jpeg|png|gif)$/);

export const roleBasedAccess = (type) => {
  switch (type) {
    case "SUPER_ADMIN":
      return {
        label: "Super Admin",
        user: ["View", "Create", "Edit", "Admin"],
        domain: ["View"],
        subDomain: ["View"],
        tag: ["View"],
        intervention: ["View"],
        therapy: ["View"],
        screener: ["View"],
        explainer: ["View"],
        blog: ["View"],
        localized: ["View"],
      };
    case "ADMIN":
      return {
        label: "Admin",
        user: ["View", "Create", "Edit", "Content"],
        domain: ["View", "Create", "Edit", "Review", "Approve"],
        subDomain: ["View", "Create", "Edit", "Review", "Approve"],
        tag: ["View", "Create", "Edit", "Review", "Approve"],
        intervention: ["View"],
        therapy: ["View"],
        screener: ["View", "Review", "Approve", "Create", "Edit"],
        explainer: ["View", "Create", "Edit"],
        blog: ["View"],
        localized: ["View", "Create", "Edit"],
      };
    case "CONTENT_CREATOR":
      return {
        label: "Content Creator",
        user: ["View"],
        domain: ["View"],
        subDomain: ["View"],
        tag: ["View"],
        intervention: ["View", "Create", "Edit"],
        therapy: ["View", "Create", "Edit"],
        screener: ["View", "Create", "Edit"],
        explainer: ["View"],
        blog: ["View", "Create", "Edit"],
        localized: ["View"],
      };
    case "CONTENT_REVIEWER":
      return {
        label: "Content Reviewer",
        user: ["View"],
        domain: ["View"],
        subDomain: ["View"],
        tag: ["View"],
        intervention: ["View", "Review"],
        therapy: ["View", "Review"],
        screener: ["View", "Review"],
        explainer: ["View", "Review"],
        blog: ["View", "Review"],
        localized: ["View"],
      };
    case "CONTENT_APPROVER":
      return {
        label: "Content Approver",
        user: ["View"],
        domain: ["View"],
        subDomain: ["View"],
        tag: ["View"],
        intervention: ["View", "Approve"],
        therapy: ["View", "Approve"],
        screener: ["View", "Approve"],
        explainer: ["View", "Approve"],
        blog: ["View", "Approve"],
        localized: ["View"],
      };
    default:
      return {
        label: "Default",
        user: ["View"],
        domain: ["View"],
        subDomain: ["View"],
        tag: ["View"],
        intervention: ["View"],
        therapy: ["View"],
        screener: ["View"],
        explainer: ["View"],
        blog: ["View"],
        localized: ["View"],
      };
  }
};

export const defaultRoleKeys = [
  "user",
  "domain",
  "subDomain",
  "tag",
  "intervention",
  "therapy",
  "screener",
  "blog",
  "explainer",
  "localized",
];
export const ContentTypes = [
  // { label: "Screener", value: "SCREENER" },
  { label: "Intervention", value: "INTERVENTION" },
  { label: "Therapy", value: "THERAPY" },
  { label: "Blog", value: "BLOG" },
  { label: "Plan", value: "PLAN" },
  { label: "Plan group", value: "PLANGROUP" },
  { label: "Plan action", value: "PLANACTION" },
  { label: "Master class", value: "MASTERCLASS" },
  { label: "Therapy Module", value: "THERAPYMODULE" },
];
export const ActionType = [
  { label: "Intervention", value: "Intervention" },
  { label: "Screener", value: "Screener" },
  { label: "Document", value: "Document" },
  { label: "Video", value: "Video" },
  { label: "Image", value: "Image" },
  //   { label: "Mail Template", value: "MailTemplate" },
  //   { label: "Report", value: "Report" },
];
export const PlanActionType = [
  { label: "Intervention", value: "Intervention" },
  { label: "Therapy", value: "Therapy" },
  { label: "Plan", value: "Plan" },
  { label: "Screener", value: "Screener" },
  { label: "Document", value: "Document" },
  { label: "Video", value: "Video" },
  { label: "Image", value: "Image" },
  { label: "Mail Template", value: "MailTemplate" },
  { label: "Report", value: "Report" },
];

export const PolicyType = [
  { label: "Terms of User", value: "TOU" },
  { label: "Cookie Consent", value: "CookieConsent" },
  { label: "Privacy Policy", value: "PrivacyPolicy" },
  { label: "Emergency Resources", value: "EmergencyResources" },
];

export const TemplateType = [
  {
    name: "Default",
    value: "Default",
  },
  {
    name: "Mail",
    value: "Mail",
  },
  {
    name: "Report",
    value: "Report",
  },
];
export const ContentTag = [
  {
    name: "Select",
    value: "",
  },
  {
    name: "Onboarding",
    value: "Onboarding",
  },
  {
    name: "For stressful days",
    value: "ForStressfulDays",
  },
  {
    name: "Wellness",
    value: "Wellness",
  },
  {
    name: "Productivity",
    value: "Productivity",
  },
];
export const ContentType = [
  {
    name: "Select",
    value: "",
  },
  {
    name: "SCREENER",
    value: "SCREENER",
  },
  {
    name: "INTERVENTION",
    value: "INTERVENTION",
  },
  {
    name: "THERAPY",
    value: "THERAPY",
  },
  {
    name: "BLOG",
    value: "BLOG",
  },
  {
    name: "PLAN",
    value: "PLAN",
  },
];

export const ObjectiveContentType = [
  {
    name: "Select",
    value: "",
  },
  {
    name: "Domain",
    value: "DOMAIN",
  },
  {
    name: "Subdomain",
    value: "SUBDOMAIN",
  },
  {
    name: "Category",
    value: "CATEGORY",
  },
  {
    name: "Screener",
    value: "SCREENER",
  },
  {
    name: "Intervention",
    value: "INTERVENTION",
  },
  {
    name: "Therapy",
    value: "THERAPY",
  },
  {
    name: "Blog",
    value: "BLOG",
  },
  {
    name: "Plan",
    value: "PLAN",
  },
];
export const PlatformContentType = [
  {
    name: "Select",
    value: "",
  },
  {
    name: "Domain",
    value: "DOMAIN",
  },
  {
    name: "Subdomain",
    value: "SUBDOMAIN",
  },
  {
    name: "Category",
    value: "CATEGORY",
  },
  {
    name: "Screener",
    value: "SCREENER",
  },
  {
    name: "Intervention",
    value: "INTERVENTION",
  },
  {
    name: "Therapy",
    value: "THERAPY",
  },
  {
    name: "Blog",
    value: "BLOG",
  },
  {
    name: "Plan",
    value: "PLAN",
  },
];

export const RelatedContentTypes = [
  { label: "Sub Domain", value: "SUBDOMAIN" },
  { label: "Category", value: "CATEGORY" },
  { label: "Intervention", value: "INTERVENTION" },
  { label: "Therapy", value: "THERAPY" },
  { label: "Blog", value: "BLOG" },
  { label: "Plan", value: "PLAN" },
  { label: "Masterclass", value: "MASTERCLASS" },
];

export const ResultRangeStringKeys = [
  "range_entity_type",
  "entity_ref_id",
  "range_name",
  "range_min",
  "range_max",
  "range_color",
];

export const AggregationTypes = [
  { label: "Domain", value: "Domain" },
  { label: "Topic", value: "Topic" },
  //   { label: "Screener", value: "Screener" },
];

export const withoutFormat = (file) => {
  const formats = [
    "png",
    "jpg",
    "jpeg",
    "gif",
    "jfif",
    "avif",
    "webp",
    "flv",
    "mp4",
    "mkv",
    "m2ts",
    "m2v",
    "m4v",
    "mxf",
    "mts",
    "lxf",
    "gxf",
    "quicktime",
    "mov.quicktime",
    "m3u8",
    "ps",
    "ts",
    "3gp",
    "mov",
    "avi",
    "wmv",
    "webm",
    "gif",
    "mp3",
    "og",
    "wav",
  ];
  const regex = new RegExp(`.(${formats.join("|")})$`, "gi");
  return file.replace(regex, "");
};

export const FaqCategoryTypes = [
  {
    label: "Customer",
    value: "Customer",
  },
  {
    label: "Organization",
    value: "OrgEmployee",
  },
];
export const platformType = ["Pilot", "Live"];

export const platformInputType = [
  { label: "Sub Domain", value: "SUBDOMAIN" },
  { label: "Therapy", value: "THERAPY" },
  { label: "Function", value: "FUNCTION" },
];
export const platformOutputType = [
  { label: "Sub Domain", value: "SUBDOMAIN" },
  { label: "Therapy", value: "THERAPY" },
  { label: "Plan", value: "PLAN" },
];
export const gameNameKey = [
  "WTI_DC",
  "WTI_BL",
  "WTI_AS",
  "WTI_CS",
  "WTI_BRT",
  "WTI_SAM",
  "WTS_BST",
  "WTS_CPT",
  "WTS_PMT",
  "WTS_DAT_AUD",
  "WTS_DAT_VIS",
  "WTS_DAT_CMB",
];

export const resultRangeGameNameKey = [
  {
    label: "WTS_BST",
    value: "WTS_BST",
  },
  {
    label: "WTS_CPT",
    value: "WTS_CPT",
  },
  {
    label: "WTS_PMT",
    value: "WTS_PMT",
  },
  {
    label: "WTS_DAT_AUD",
    value: "WTS_DAT_AUD",
  },
  {
    label: "WTS_DAT_VIS",
    value: "WTS_DAT_VIS",
  },
  {
    label: "WTS_DAT_CMB",
    value: "WTS_DAT_CMB",
  },
];
export const screenerResultRangeEntityType = [
  { label: "Select", value: "", props: { hidden: true } },
  {
    label: "Domain",
    value: "Domain",
  },
  {
    label: "Sub Domain",
    value: "SubDomain",
  },
  {
    label: "Topic",
    value: "Topic",
  },
  {
    label: "Screener",
    value: "Screener",
  },
];

export const organizationEmployeeStatus = [
  // { label: "Select", value: "", props: { hidden: true } },
  {
    label: "Active",
    value: "Active",
  },
  {
    label: "In Active",
    value: "InActive",
  },
  {
    label: "Suspended",
    value: "Suspended",
  },
];

export const organizationOnboardStatus = [
  // { label: "Select", value: "", props: { hidden: true } },
  {
    label: "Approved",
    value: "Approved",
  },
  {
    label: "Ready",
    value: "Ready",
  },
];


export const transactionType = [
  // { label: "Select", value: "", props: { hidden: true } },
  {
    label: "Credit",
    value: "Credit",
  },
  {
    label: "Debit",
    value: "Debit",
  },
];
