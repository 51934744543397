import { Modal, ModalBody } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import graphHandler from "../../../Apollo/graphHandler";
import {
  CREATE_MODULE_ACTION_GQL,
  UPDATE_MODULE_ACTION_GQL,
} from "../../../Apollo/AdminServices/MutationRequest";
import CustomButton from "../../../components/Button/CustomButton";
import { ActionTypes, isImage, isVideo } from "../../../constants";
import { useEffect, useState } from "react";
import { formatBytes, IsNullOrEmpty } from "../../../utils/Functions/filter";
import { videoDurationCheck } from "../../../components/Media";
import { uploadHandler } from "../../../utils/CustomHooks/useUploadImage";
const validation = Yup.object().shape({
  therapy_module_action_name: Yup.string().required("Name is required"),
  therapy_action_type: Yup.string().required("Action type is required"),
  sort_order: Yup.string().required("Sort order is required"),
  intervention_id: Yup.string().required("Select intervention"),
});
const documentValidation = Yup.object().shape({
  therapy_module_action_name: Yup.string().required("Name is required"),
  therapy_action_type: Yup.string().required("Action type is required"),
  sort_order: Yup.string().required("Sort order is required"),
  therapy_module_document_url: Yup.object({
    url: Yup.string().required("Upload a document"),
  }).required("Document is required"),
});
const videoValidation = Yup.object().shape({
  therapy_module_action_name: Yup.string().required("Name is required"),
  therapy_action_type: Yup.string().required("Action type is required"),
  sort_order: Yup.string().required("Sort order is required"),
  therapy_module_video_url: Yup.object({
    url: Yup.string().required("Video is required"),
  }).required("Video is required"),
});
const screenerValidation = Yup.object().shape({
  therapy_module_action_name: Yup.string().required("Name is required"),
  therapy_action_type: Yup.string().required("Action type is required"),
  sort_order: Yup.string().required("Sort order is required"),
  screener_id: Yup.string().required("Select a screener"),
});

export default function ActionModal({
  isOpen,
  setOpen,
  refresh,
  therapy_module_id = "",
  therapy_id = "",
  interventionList = [],
  defaultValues = {},
  actionList = [],
  screenerList = [],
}) {
  //   console.log(screenerList);
  const [type, setType] = useState("Intervention");
  const formik = useFormik({
    initialValues: {},
    initialStatus: false,
    validationSchema:
      type === "Intervention"
        ? validation
        : type === "Screener"
        ? screenerValidation
        : type === "Document"
        ? documentValidation
        : videoValidation,
    onSubmit: (values) => {
      onSubmit(values);
    },
  });
  useEffect(() => {
    if (defaultValues.therapy_module_action_id) {
      const {
        therapy_module_action_id,
        sort_order,
        therapy_action_type,
        intervention_id,
        therapy_module_document_url,
        therapy_module_video_url,
        therapy_module_action_name,
        screener_id,
      } = defaultValues;
      let value = {
        therapy_module_action_id,
        therapy_action_type,
        therapy_module_action_name,
      };
      if (!IsNullOrEmpty(sort_order)) value.sort_order = sort_order;
      if (therapy_action_type === "Intervention") {
        value.intervention_id = intervention_id;
      }
      if (therapy_action_type === "Screener") {
        value.screener_id = screener_id;
      } else if (
        therapy_action_type === "Document" &&
        therapy_module_document_url &&
        therapy_module_document_url.name
      ) {
        value.therapy_module_document_url = {
          name: therapy_module_document_url.name,
          type: therapy_module_document_url.document_type,
          url: therapy_module_document_url.url,
        };
      } else if (
        therapy_action_type === "Video" &&
        therapy_module_video_url &&
        therapy_module_video_url.video_url
      ) {
        value.therapy_module_video_url = {
          type: therapy_module_video_url.video_type,
          url: therapy_module_video_url.video_url,
          duration: therapy_module_video_url.duration,
        };
      }
      formik.setValues(value);
    }
  }, [defaultValues, isOpen]);

  useEffect(() => {
    formik.values.therapy_action_type && formik.validateForm();
  }, [type]);
  useEffect(() => {
    formik.values.therapy_action_type &&
      setType(formik.values.therapy_action_type);
  }, [formik.values.therapy_action_type]);
  const onClose = () => {
    formik.resetForm();
    setOpen();
  };
  const onSubmit = (values) => {
    // console.log("Submit", values);
    const {
      sort_order,
      therapy_action_type,
      intervention_id,
      therapy_module_document_url,
      therapy_module_video_url,
      therapy_module_action_name,
      therapy_module_action_id,
      screener_id,
    } = values;
    let request = {
      therapy_module_action_name,
      therapy_module_id,
      therapy_id,
      therapy_action_type,
      sort_order: IsNullOrEmpty(sort_order)
        ? actionList.length +
          (actionList.find((x) => x.sort_order === actionList.length + 1)
            ? 2
            : 1)
        : Number(sort_order),
    };
    if (therapy_action_type === "Intervention") {
      request.intervention_id = intervention_id;
    } else if (therapy_action_type === "Screener") {
      request.screener_id = screener_id;
    } else if (therapy_action_type === "Document") {
      request.therapy_module_document_url = {
        name: therapy_module_document_url.name,
        document_type: therapy_module_document_url.type,
        url: therapy_module_document_url.url,
      };
    } else if (therapy_action_type === "Video") {
      request.therapy_module_video_url = {
        video_type: therapy_module_video_url.type,
        video_url: therapy_module_video_url.url,
        duration: therapy_module_video_url.duration.toString(),
      };
    }
    if (therapy_module_action_id) {
      onUpdate({ ...request, therapy_module_action_id });
    } else {
      onCreate(request);
    }
  };
  const onUpdate = async (update_request) => {
    try {
      await graphHandler().invoke(
        UPDATE_MODULE_ACTION_GQL,
        { update_request },
        1,
        false
      );
      toast.success("Updated Successfully!");
      refresh();
      onClose();
      formik.setSubmitting(false);
    } catch (error) {
      console.log("updateAction error", error);
      formik.setSubmitting(false);
    }
  };

  const onCreate = async (create_request) => {
    try {
      await graphHandler().invoke(
        CREATE_MODULE_ACTION_GQL,
        { create_request },
        1,
        false
      );
      toast.success("Added Successfully!");
      refresh();
      onClose();
      formik.setSubmitting(false);
    } catch (error) {
      console.log("createAction error", error);
      formik.setSubmitting(false);
    }
  };

  const handleFileChange = async (event, type) => {
    const { name, files } = event.target;
    if (!files[0]) return;
    const formikData = {
      isUpload: true,
      size: formatBytes(files[0].size),
      name: files[0].name,
    };
    formik.setFieldValue(name, formikData);
    const file = new File([files[0]], files[0].name.replace(/\s/g, ""), {
      type: files[0].type,
    });
    if (type === "Video") {
      if (!isVideo(file)) return toast.error("Please select a valid video");
      videoDurationCheck(file)
        .then((res) => {
          uploadToCloud(file, name, formikData, type, res.duration);
        })
        .catch((e) => {
          toast.error(e.error);
          formik.setFieldValue(name, {});
        });
      uploadToCloud(file, name, formikData, type);
    } else {
      uploadToCloud(file, name, formikData, type);
    }
  };

  async function uploadToCloud(file, name, obj, type, duration = 0) {
    // let formikData = { ...obj };
    let formikData = { ...obj };
    try {
      let data = await uploadHandler("therapy").upload(
        file,
        file.type === "application/pdf"
          ? "PDF"
          : type === "Document"
          ? "Document"
          : "Image"
      );
      formikData.name = file.name;
      formikData.url = data.url;
      formikData.isUpload = false;
      formikData.progress = 100;
      if (type === "Document") {
        formikData.type = isImage(file)
          ? "Image"
          : file.type === "application/pdf"
          ? "PDF"
          : "Document";
      } else {
        formikData.type = isImage(file) ? "Banner" : "Default";
        formikData.duration = duration;
      }
      // console.log("Uploading then", formikData, data);
      formik.setFieldValue(name, formikData);
    } catch (error) {
      console.log("Upload error", error);
      formik.setFieldValue(name, undefined);
    }
  }

  const handleError = () => {
    if (Object.keys(formik.errors).length > 0) {
      toast.error(formik.errors[Object.keys(formik.errors)[0]]);
    }
  };

  const handleNumber = (e) => {
    const { name, value } = e.target;
    const re = /^[0-9\b]+$/;
    let temp = !value ? value : re.test(value) ? value : formik.values[name];
    formik.setFieldValue(name, temp ? temp.substring(0, 10) : temp);
  };

  const removeData = (name = "") => {
    formik.setFieldValue(name, {});
  };
  return (
    <Modal toggle={onClose} isOpen={isOpen} centered scrollable size="xl">
      <div className="modal-header">
        <button type="button" className="close" onClick={onClose}>
          <span aria-hidden="true">&times;</span>
        </button>
        <h5 className="modal-title">
          {formik.values.therapy_module_action_id ? "Edit" : "Create"} Action
        </h5>
      </div>
      <ModalBody>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12 form-group">
              <label
                htmlFor="therapy_module_action_name"
                className="col-form-label w-100 mb-2"
              >
                Action Name
              </label>
              <input
                type="text"
                className="form-control"
                id="therapy_module_action_name"
                name="therapy_module_action_name"
                onChange={formik.handleChange}
                // maxLength={30}
                value={
                  formik.values.therapy_module_action_name
                    ? formik.values.therapy_module_action_name
                    : ""
                }
                onBlur={formik.handleBlur}
              />
              <small className="text-danger align-self-end">
                {formik.touched.therapy_module_action_name &&
                formik.errors &&
                formik.errors.therapy_module_action_name
                  ? formik.errors.therapy_module_action_name
                  : ""}
              </small>
            </div>
            <div className="col-md-12 form-group">
              <label htmlFor="sort_order" className="col-form-label w-100 mb-2">
                Sort Order
              </label>
              <input
                type="text"
                className="form-control"
                id="sort_order"
                name="sort_order"
                onChange={handleNumber}
                maxLength={10}
                value={formik.values.sort_order ? formik.values.sort_order : ""}
                onBlur={formik.handleBlur}
              />
              <small className="text-danger align-self-end">
                {formik.touched.sort_order &&
                formik.errors &&
                formik.errors.sort_order
                  ? formik.errors.sort_order
                  : ""}
              </small>
            </div>
            <div className="col-md-12 form-group">
              <label
                htmlFor="therapy_action_type"
                className="col-form-label w-100 mb-2"
              >
                Action Type
              </label>
              <select
                className="form-control"
                id="therapy_action_type"
                name="therapy_action_type"
                value={
                  formik.values && formik.values.therapy_action_type
                    ? formik.values.therapy_action_type
                    : ""
                }
                onChange={formik.handleChange}
              >
                <option key="Select" hidden>
                  Select Type
                </option>
                {ActionTypes.map((x, i) => (
                  <option value={x} key={i}>
                    {x}
                  </option>
                ))}
              </select>
              <small className="text-danger align-self-end">
                {formik.touched.therapy_action_type &&
                formik.errors &&
                formik.errors.therapy_action_type
                  ? formik.errors.therapy_action_type
                  : ""}
              </small>
            </div>
            {formik.values.therapy_action_type === "Intervention" ? (
              <div className="col-md-12 form-group">
                <label
                  htmlFor="intervention_id"
                  className="col-form-label w-100 mb-2"
                >
                  Intervention
                </label>
                <select
                  className="form-control"
                  id="intervention_id"
                  name="intervention_id"
                  value={
                    formik.values && formik.values.intervention_id
                      ? formik.values.intervention_id
                      : ""
                  }
                  onChange={formik.handleChange}
                >
                  <option key="Select" hidden>
                    Select
                  </option>
                  {interventionList.map((x, i) => (
                    <option value={x.intervention_id} key={i}>
                      {x.intervention_name}
                    </option>
                  ))}
                </select>
                <small className="text-danger align-self-end">
                  {formik.touched.intervention_id &&
                  formik.errors &&
                  formik.errors.intervention_id
                    ? formik.errors.intervention_id
                    : ""}
                </small>
              </div>
            ) : formik.values.therapy_action_type === "Screener" ? (
              <div className="col-md-12 form-group">
                <label
                  htmlFor="screener_id"
                  className="col-form-label w-100 mb-2"
                >
                  Screener
                </label>
                <select
                  className="form-control"
                  id="screener_id"
                  name="screener_id"
                  value={
                    formik.values && formik.values.screener_id
                      ? formik.values.screener_id
                      : ""
                  }
                  onChange={formik.handleChange}
                >
                  <option key="Select" hidden>
                    Select
                  </option>
                  {screenerList.map((x, i) => (
                    <option value={x.screener_id} key={i}>
                      {x.screener_name}
                    </option>
                  ))}
                </select>
                <small className="text-danger align-self-end">
                  {formik.touched.screener_id &&
                  formik.errors &&
                  formik.errors.screener_id
                    ? formik.errors.screener_id
                    : ""}
                </small>
              </div>
            ) : formik.values.therapy_action_type === "Document" ? (
              <div className="input-group form-group d-flex justify-content-between w-100 col-md-12">
                <label
                  className="col-form-label w-50 mb-2"
                  htmlFor="therapy_module_document_url"
                >
                  Module Document
                </label>
                <button
                  id="custom-file"
                  onClick={() =>
                    document
                      .getElementById("therapy_module_document_url")
                      .click()
                  }
                >
                  Upload File
                </button>
                <input
                  type="file"
                  className="custom-file-input d-none"
                  id="therapy_module_document_url"
                  name="therapy_module_document_url"
                  onBlur={formik.handleBlur}
                  onChange={(e) => handleFileChange(e, "Document")}
                />
                {formik.values.therapy_module_document_url &&
                formik.values.therapy_module_document_url.name ? (
                  <div className="upload__list col-md-12 mt-2">
                    <div className="upload__info flex">
                      {formik.values.therapy_module_document_url.type ===
                      "Image" ? (
                        <img
                          src={formik.values.therapy_module_document_url.url}
                          className="upload__img"
                          alt=""
                        />
                      ) : (
                        <i
                          className="fas fa-file mr-5"
                          style={{ fontSize: "60px" }}
                        />
                      )}
                      <div className="flex w-100 wrap">
                        <p className="upload__name">
                          {formik.values.therapy_module_document_url.name ||
                            "Uploaded Document"}
                        </p>
                        {formik.values.therapy_module_document_url.name &&
                          formik.values.therapy_module_document_url.size &&
                          formik.values.therapy_module_document_url.size !=
                            0 && (
                            <p className="upload__size">
                              ( {formik.values.therapy_module_document_url.size}{" "}
                              )
                            </p>
                          )}
                        {formik.values.therapy_module_document_url.name &&
                          formik.values.therapy_module_document_url.progress &&
                          formik.values.therapy_module_document_url.progress !=
                            0 && (
                            <p className="upload__long">
                              {
                                formik.values.therapy_module_document_url
                                  .progress
                              }
                              %
                            </p>
                          )}
                        {formik.values.therapy_module_document_url.name &&
                          formik.values.therapy_module_document_url.progress &&
                          formik.values.therapy_module_document_url.progress ==
                            100 && (
                            <p className="upload__long text-success">Done</p>
                          )}
                        <a
                          className="upload__delete upload__btn"
                          onClick={() =>
                            removeData("therapy_module_document_url")
                          }
                        >
                          <i className="fas fa-minus-circle" />
                        </a>
                        {formik.values.therapy_module_document_url.name &&
                          formik.values.therapy_module_document_url.progress &&
                          formik.values.therapy_module_document_url.progress !=
                            0 &&
                          formik.values.therapy_module_document_url.progress !=
                            100 && (
                            <div className="upload__progress">
                              <div
                                className="upload__bar"
                                style={{
                                  width:
                                    formik.values.therapy_module_document_url
                                      .progress + "%",
                                }}
                              />
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                ) : null}
                <small className="text-danger col-md-12 w-100 mt-1">
                  {formik.errors &&
                  formik.errors.therapy_module_document_url &&
                  formik.errors.therapy_module_document_url.url
                    ? formik.errors.therapy_module_document_url.url
                    : ""}
                </small>
              </div>
            ) : formik.values.therapy_action_type === "Video" ? (
              <div className="input-group form-group d-flex justify-content-between w-100 col-md-12">
                <label
                  className="col-form-label w-50 mb-2"
                  htmlFor="therapy_module_video_url"
                >
                  Module Video
                </label>
                <button
                  id="custom-file"
                  onClick={() =>
                    document.getElementById("therapy_module_video_url").click()
                  }
                >
                  Upload
                </button>
                <input
                  type="file"
                  className="custom-file-input d-none"
                  id="therapy_module_video_url"
                  name="therapy_module_video_url"
                  onBlur={formik.handleBlur}
                  onChange={(e) => handleFileChange(e, "Video")}
                />
                {formik.values &&
                formik.values.therapy_module_video_url &&
                formik.values.therapy_module_video_url.type ? (
                  <div className="upload__list col-md-12 mt-2">
                    <div className="upload__info flex">
                      {formik.values.therapy_module_video_url.type ===
                      "Default" ? (
                        <video
                          src={formik.values.therapy_module_video_url.url}
                          className="upload__img"
                        />
                      ) : (
                        <img
                          src={formik.values.therapy_module_video_url.url}
                          className="upload__img"
                        />
                      )}
                      <div className="flex w-100 wrap">
                        <p className="upload__name">
                          {formik.values.therapy_module_video_url.name ||
                            "Uploaded"}
                        </p>
                        {formik.values.therapy_module_video_url.name &&
                          formik.values.therapy_module_video_url.size != 0 && (
                            <p className="upload__size">
                              ( {formik.values.therapy_module_video_url.size} )
                            </p>
                          )}
                        {formik.values.therapy_module_video_url.name &&
                          formik.values.therapy_module_video_url.progress !=
                            0 && (
                            <p className="upload__long">
                              {" "}
                              {formik.values.therapy_module_video_url.progress}%
                            </p>
                          )}
                        {formik.values.therapy_module_video_url.name &&
                          formik.values.therapy_module_video_url.progress ==
                            100 && (
                            <p className="upload__long text-success">Done</p>
                          )}
                        <a className="upload__delete upload__btn">
                          <i
                            className="fas fa-minus-circle"
                            onClick={() =>
                              removeData("therapy_module_video_url")
                            }
                          />
                        </a>
                        {formik.values.therapy_module_video_url.name &&
                          formik.values.therapy_module_video_url.progress !=
                            0 &&
                          formik.values.therapy_module_video_url.progress !=
                            100 && (
                            <div className="upload__progress">
                              <div
                                className="upload__bar"
                                style={{
                                  width:
                                    formik.values.therapy_module_video_url
                                      .progress + "%",
                                }}
                              />
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                ) : null}
                <small className="text-danger col-md-12 w-100 mt-1">
                  {formik.errors &&
                  formik.errors.therapy_module_video_url &&
                  formik.errors.therapy_module_video_url.url
                    ? formik.errors.therapy_module_video_url.url
                    : ""}
                </small>
              </div>
            ) : null}
            <CustomButton
              type="button"
              className="btn btn-primary btn-outline-success float-right btn-add-domain"
              disabled={
                Object.keys(formik.errors).length !== 0 ||
                !formik.isValid ||
                formik.isSubmitting
              }
              onClick={
                formik.isValid && Object.keys(formik.values).length !== 0
                  ? formik.handleSubmit
                  : handleError
              }
            >
              {formik.values.therapy_module_action_id ? "Update" : "Create"}
            </CustomButton>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
}
