import React, { useState } from "react";
import UpdateLocalize from "../modal/UpdateLocalize";
import { Link } from "react-router-dom";
import graphHandler from "../../../Apollo/graphHandler";
import { Popover } from "reactstrap";
import { getDescription, getSingleData } from "../../../utils/Functions/filter";
import { toast } from "react-toastify";
import ReadMore from "../../../components/ReadMore";
import { useSelector } from "react-redux";

export default function LocalizeListItem({
  item,
  refresh,
  index,
  languageList = [],
  access = {},
}) {
  const [isEdit, setEdit] = useState(false);
  const subDomains = useSelector(({ DataSet }) => DataSet.subDomains);
  let value = item?.value.find((x)=>x.description_value);
  return (
    <tr>
      <td className="col-sm-2">{item.key}</td>
      <td>
        <ReadMore>
          {value && value?.description_value && getDescription(value?.description_value, "Web")
            ? getDescription(value?.description_value, "Web")?.description
            : "-"}
        </ReadMore>
      </td>
      <td>
        {item.sub_domain_id &&
        getSingleData(subDomains, "sub_domain_id", item.sub_domain_id)
          ? getSingleData(subDomains, "sub_domain_id", item.sub_domain_id)
              .sub_domain_name
          : "-"}
      </td>
      <td>
        {item.language_id &&
        getSingleData(languageList, "language_id", item.language_id)
          ? getSingleData(languageList, "language_id", item.language_id)
              .language_name
          : "-"}
      </td>
      <td>
        <span
          className={`${item.active ? "good" : "bad"} text-center btn btn-sm`}
        >
          {item.active ? "Active" : "Disabled"}
        </span>
      </td>
      {access.canEdit && (
        <td>
        <button
          type="button"
          className="btn btn-outline-success btn-sm "
          onClick={() => setEdit(true)}
        >
          Edit
        </button>
      </td>
      )}
      {/* <td className="accordion-menu" id={`tagData${index}`} onClick={toggle}>
        <i className="fas fa-ellipsis-v" />
      </td>
      <Popover
        isOpen={showPop}
        toggle={toggle}
        placement="left"
        target={`tagData${index}`}
        trigger="legacy"
      >
        <div className="accordion-menu-header">Options</div>

        <div
          className="accordion-menu-item"
          onClick={() => activateItem(!item.active)}
        >
          {!item.active ? "Activate" : "Deactivate"}
        </div>
      </Popover> */}
      <UpdateLocalize
        isOpen={isEdit}
        setOpen={() => setEdit(false)}
        initialValue={item}
        refresh={refresh}
        languageList={languageList}
      />
    </tr>
  );
}
