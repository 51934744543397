import { useEffect, useState } from "react";
import { GET_INTERVENTION_HISTORIES_GQL } from "../../Apollo/AdminServices/QueryRequest";
import graphHandler from "../../Apollo/graphHandler";

function useInterventionHistory(id = "") {
  const [ interventionHistory, setInterventionHistory ] = useState([]);
  const [ loading, setLoading ] = useState(false);

  useEffect(() => {
    if (id) {
      getIntervetionHistory(id);
    } else {
      setInterventionHistory([]);
    }
  }, [ id ]);

  const getIntervetionHistory = async (intervention_id) => {
    if (!intervention_id) return;
    setLoading(true);
    try {
      const { data } = await graphHandler().invoke(
        GET_INTERVENTION_HISTORIES_GQL,
        { intervention_id },
        1
      );
      if (
        data &&
        data.getAllInterventionHistories &&
        data.getAllInterventionHistories.intervention_history_list &&
        data.getAllInterventionHistories.intervention_history_list.length > 0
      ) {
        // console.log(
        //   "getAllInterventionHistories Success",
        //   data.getAllInterventionHistories.intervention_history_list
        // );
        setInterventionHistory(
          data.getAllInterventionHistories.intervention_history_list
        );
        setLoading(false);
      } else {
        setInterventionHistory([]);
        setLoading(false);
      }
    } catch (error) {
      console.log("getAllInterventionHistories Error", error);
      setInterventionHistory([]);
      setLoading(false);
    }
  };
  return {
    interventionHistory,
    loading,
    getIntervetionHistory,
  };
}
export default useInterventionHistory;
