import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useMemo } from "react";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { UPDATE_PLAN_GROUP_GQL } from "../../../Apollo/AdminServices/NewMutationRequest";
import graphHandler from "../../../Apollo/graphHandler";
import CustomButton from "../../../components/Button/CustomButton";
import DescriptionInputField from "../../../components/HistoryEditInput/DescriptionInputField";
import ImageHistoryInput from "../../../components/HistoryEditInput/ImageHistoryInput";
import MetaHistoryInput from "../../../components/HistoryEditInput/MetaHistoryInput";
import NewReactSelectInputField from "../../../components/HistoryEditInput/NewReactSelectInputField";
import TextInputField from "../../../components/HistoryEditInput/TextInputField";
import VideoHistoryInput from "../../../components/HistoryEditInput/VideoHistoryInput";
import {
  mergeMultiSelectArray,
  objectHasKey,
  removeEmptyObjValue,
} from "../../../utils/Functions/filter";
import CheckInputField from "../../../components/HistoryEditInput/CheckInputField";

export default function UpdatePlanGroup({
  refresh = () => {},
  subDomainList = [],
  setLoading = () => {},
  planGroup = {},
  reset = () => {},
}) {
  const [selectedSubDomainList, setSelectedSubDomainList] = useState([]);
  const pending_history = planGroup.pending_history
    ? planGroup.pending_history
    : {};
  let { plan_group_id, sub_domain_ids } = planGroup;
  const hasUpdated = Boolean(pending_history && pending_history.updated_data);
  const updated_data = hasUpdated ? pending_history.updated_data : {};
  const formik = useFormik({
    initialValues: {},
    onSubmit: (values) => {
      onSubmit(values);
    },
  });
  // console.log(planGroup, formik.values,planGroup.start_interval_seconds / 86400);
  // console.log("planGroup",planGroup)
  useEffect(() => {
    //Reset onEdit
    formik.resetForm();
    formik.setValues({});
    formik.setSubmitting(false);
    if (plan_group_id) {
      let publish = hasUpdated ? updated_data : {};
      if (subDomainList&&subDomainList.length > 0) {
        let subArray = [],
          sub_domains =
            sub_domain_ids && sub_domain_ids.length > 0 ? sub_domain_ids : [],
          publishedSub =
            publish.sub_domain_ids &&
            publish.sub_domain_ids.update_items &&
            publish.sub_domain_ids.update_items.length > 0
              ? publish.sub_domain_ids.update_items
              : [];
        let value = mergeMultiSelectArray(sub_domains, publishedSub);
        let i = 0;
        subArray = value.map((x) => {
          let ret = {
            ...subDomainList.find((y) => y.value === x.data),
            ...x,
            index: i,
          };
          if (x.isRemoved) {
            ret.value =
              subDomainList.find((y) => y.value === x.data).label || "";
          } else {
            i++;
          }
          return ret;
        });
        setSelectedSubDomainList(subArray);
      }
    }
  }, [plan_group_id,subDomainList]);

  const onSubmit = async (formikValues) => {
    formik.setSubmitting(true);
    let {
      sub_domain_ids,
      plan_group_name,
      plan_group_code,
      plan_group_meta,
      sort_order,
      min_completion_days,
      max_completion_days,
      start_interval_seconds,
      plan_group_descriptions,
      plan_group_image_urls,
      plan_group_video_urls,
      mandatory,
      auto_generate
    } = formikValues;
    let update_request = {
      plan_group_id,
    };
    if (objectHasKey(formikValues, "mandatory")) {
      update_request.mandatory = mandatory;
    }
    if (objectHasKey(formikValues, "auto_generate")) {
      update_request.auto_generate = auto_generate;
    }
    if (objectHasKey(formikValues, "plan_group_name")) {
      update_request.plan_group_name = plan_group_name;
    }
    if (objectHasKey(formikValues, "plan_group_code")) {
      update_request.plan_group_code = plan_group_code;
    }

    if (objectHasKey(formikValues, "sort_order")) {
      update_request.sort_order = Number(sort_order);
    }

    if (objectHasKey(formikValues, "min_completion_days")) {
      update_request.min_completion_days = Number(min_completion_days);
    }

    if (objectHasKey(formikValues, "max_completion_days")) {
      update_request.max_completion_days = Number(max_completion_days);
    }

    if (objectHasKey(formikValues, "start_interval_seconds")) {
      update_request.start_interval_seconds =
        Number(start_interval_seconds) * 86400;
    }

    if (objectHasKey(formikValues, "sub_domain_ids")) {
      update_request.sub_domain_ids = { update_items: sub_domain_ids };
    }

    if (objectHasKey(formikValues, "plan_group_descriptions")) {
      update_request.plan_group_descriptions = {
        update_items: plan_group_descriptions,
      };
    }
    if (objectHasKey(formikValues, "plan_group_meta")) {
      update_request.plan_group_meta = {
        update_items: plan_group_meta.map((x) => removeEmptyObjValue(x)),
      };
    }
    if (objectHasKey(formikValues, "plan_group_image_urls")) {
      update_request.plan_group_image_urls = {
        update_items: plan_group_image_urls.map((x) => removeEmptyObjValue(x)),
      };
    }
    if (objectHasKey(formikValues, "plan_group_video_urls")) {
      update_request.plan_group_video_urls = {
        update_items: plan_group_video_urls.map((x) => removeEmptyObjValue(x)),
      };
    }
    formik.setSubmitting(true);
    try {
      await graphHandler().invoke(
        UPDATE_PLAN_GROUP_GQL,
        { update_request },
        1,
        false
      );
      toast.success("Updated Successfully");
      refresh();
      setLoading(false);
      formik.setSubmitting(false);
      resetModal();
    } catch (error) {
      console.log("createPlan error", error);
      formik.setSubmitting(false);
    }
  };

  const handleError = () => {
    if (Object.keys(formik.errors).length > 0) {
      toast.error(formik.errors[Object.keys(formik.errors)[0]]);
    }
  };
  const resetModal = () => {
    formik.resetForm();
    formik.setValues({});
    formik.setSubmitting(false);
    reset();
  };
  // console.log("formik.value", formik.values);
  return (
    <>
      <div className="col-md-12 modal-header" id="CreateTopic">
        <h5 className="modal-title text-center mb-3">Update Group</h5>
      </div>
      <div className="col-md-12">
        <div className="  border">
          <div className="row p-3">
            <div className="col-md-6">
              <div className="row">
                <NewReactSelectInputField
                  parentForm={formik}
                  id="sub_domain_ids"
                  title="Sub Domain"
                  placeholder="Select Sub Domain"
                  options={subDomainList}
                  isCreatable={false}
                  isMulti={true}
                  defaultValue={selectedSubDomainList}
                  isPublished={planGroup.published}
                  updateValue={
                    updated_data.sub_domain_ids &&
                    updated_data.sub_domain_ids.update_items &&
                    updated_data.sub_domain_ids.update_items.length > 0
                      ? updated_data.sub_domain_ids.update_items
                      : false
                  }
                  validation={{
                    sub_domain_ids: Yup.array().of(
                      Yup.object().shape({
                        label: Yup.string(),
                        value: Yup.string(),
                      })
                    ),
                    // .test({
                    //   message: "Select sub domain",
                    //   test: (arr) => {
                    //     return arr && arr.length > 0;
                    //   },
                    // }),
                  }}
                />
                <div className="col-md-12">
                  <div className="row">
                    <TextInputField
                      parentForm={formik}
                      className="col-md-4"
                      updateValue={updated_data.plan_group_code}
                      actualValue={planGroup.plan_group_code}
                      id="plan_group_code"
                      title="Code"
                      disabled={true}
                      validation={{
                        plan_group_code:
                          Yup.string().required("Enter group code"),
                      }}
                    />
                    <TextInputField
                      parentForm={formik}
                      className="col-md-4"
                      updateValue={updated_data.sort_order}
                      actualValue={planGroup.sort_order}
                      id="sort_order"
                      title="Sort Order"
                      validation={{
                        sort_order: Yup.string(),
                        // .required("Enter sort order"),
                      }}
                      isNumber={true}
                      inputProps={{
                        maxLength: 10,
                      }}
                    />
                    <TextInputField
                      parentForm={formik}
                      className="col-md-4"
                      updateValue={
                        updated_data.start_interval_seconds &&
                        updated_data.start_interval_seconds / 86400
                      }
                      actualValue={
                        planGroup.start_interval_seconds ?
                        planGroup.start_interval_seconds / 86400 :""
                      }
                      id="start_interval_seconds"
                      title="Interval (in days)"
                      validation={{
                        start_interval_seconds: Yup.string(),
                        // .required(
                        //   "Enter start interval"
                        // ),
                      }}
                      isNumber={true}
                      inputProps={{
                        maxLength: 10,
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="row">
                  <CheckInputField
                  parentForm={formik}
                  col="col-md-4"
                  className="col-md-4"
                  updateValue={updated_data.mandatory}
                  hasUpdated={typeof updated_data.mandatory === "boolean"}
                  actualValue={planGroup.mandatory}
                  id="mandatory"
                  title="Mandatory"
                  validation={{
                    mandatory: Yup.boolean(),
                  }}
                />
                <CheckInputField
                  parentForm={formik}
                  col="col-md-4"
                  className="col-md-4"
                  updateValue={updated_data.auto_generate}
                  hasUpdated={typeof updated_data.auto_generate === "boolean"}
                  actualValue={planGroup.auto_generate}
                  id="auto_generate"
                  title="Auto Generate"
                  validation={{
                    auto_generate: Yup.boolean(),
                  }}
                />
                    </div>
                    </div>
                <div className="col-md-12 form-group mb-0">
                  <label
                    htmlFor="min_completion_days"
                    className="col-form-label w-100 mb-2"
                  >
                    <b>Completion Days</b>
                  </label>
                  <div className="row">
                    <TextInputField
                      parentForm={formik}
                      className="col-md-6"
                      updateValue={updated_data.min_completion_days}
                      actualValue={planGroup.min_completion_days}
                      id="min_completion_days"
                      title="Minimum"
                      validation={{
                        min_completion_days: Yup.string(),
                        // .required("Enter minimum days"),
                      }}
                      isNumber={true}
                      inputProps={{
                        maxLength: 10,
                      }}
                    />
                    <TextInputField
                      parentForm={formik}
                      className="col-md-6"
                      updateValue={updated_data.max_completion_days}
                      actualValue={planGroup.max_completion_days}
                      id="max_completion_days"
                      title="Maximum"
                      validation={{
                        max_completion_days: Yup.string()
                          .required("Enter max completion days")
                          .when(
                            "min_completion_days",
                            (min_completion_days, schema) => {
                              return schema.test({
                                test: (max_completion_days) =>
                                  Number(
                                    updated_data.max_completion_days
                                      ? updated_data.max_completion_days
                                      : planGroup.max_completion_days
                                      ? planGroup.max_completion_days
                                      : max_completion_days
                                  ) > Number( updated_data.min_completion_days
                                    ? updated_data.min_completion_days
                                    : planGroup.min_completion_days
                                    ? planGroup.min_completion_days
                                    : min_completion_days),
                                message: "Max days should be > min days",
                              });
                            }
                          ),
                      }}
                      isNumber={true}
                      inputProps={{
                        maxLength: 10,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="row">
                <TextInputField
                  parentForm={formik}
                  className="col-md-12"
                  updateValue={updated_data.plan_group_name}
                  actualValue={planGroup.plan_group_name}
                  id="plan_group_name"
                  title="Plan Name"
                  validation={{
                    plan_group_name: Yup.string()
                      .trim()
                      .required("Enter group name"),
                  }}
                />
                <DescriptionInputField
                  parentForm={formik}
                  parentKey="plan_group_descriptions"
                  className="col-md-12"
                  updateValue={
                    updated_data.plan_group_descriptions &&
                    updated_data.plan_group_descriptions.update_items
                      ? updated_data.plan_group_descriptions.update_items
                      : false
                  }
                  actualValue={planGroup.plan_group_descriptions}
                  validation={{
                    description: Yup.string()
                      .trim()
                      .required("Description is required"),
                    mobile_description: Yup.string()
                      .trim()
                      .required("Mobile description is required"),
                  }}
                  showEditor={true}
                  refreshId={plan_group_id}
                />
              </div>
            </div>
            <div className="col-md-12">
              <MetaHistoryInput
                parentForm={formik}
                parentArray="plan_group_meta"
                typeKey="type"
                titleKey="key"
                valueKey="value"
                isPublished={planGroup.published}
                updateValue={
                  updated_data.plan_group_meta &&
                  updated_data.plan_group_meta.update_items
                    ? updated_data.plan_group_meta.update_items
                    : false
                }
                validation={{
                  plan_group_meta: Yup.array().of(
                    Yup.object().shape({
                      type: Yup.string().required("Select a data type"),
                      key: Yup.string().trim().required("Enter a title"),
                      value: Yup.string().trim().required("Enter a value"),
                    })
                  ),
                }}
                actualValue={planGroup.plan_group_meta}
              />
              <ImageHistoryInput
                parentForm={formik}
                contentId={planGroup.plan_group_id}
                contentType="PLANGROUP"
                heading="Image File"
                parentArray="plan_group_image_urls"
                actualValue={planGroup.plan_group_image_urls}
                isPublished={planGroup.published}
                updateValue={
                  updated_data.plan_group_image_urls &&
                  updated_data.plan_group_image_urls.update_items
                    ? updated_data.plan_group_image_urls.update_items
                    : false
                }
                validation={{
                  plan_group_image_urls: Yup.array().of(
                    Yup.object().shape({
                      is_mobile: Yup.boolean().required(
                        "Select a Image device"
                      ),
                      image_type: Yup.string().required(
                        "Select a Image format"
                      ),
                      url: Yup.string(),
                    })
                  ),
                }}
              />
              <VideoHistoryInput
                parentForm={formik}
                contentId={planGroup.plan_group_id}
                contentType="PLANGROUP"
                heading="Video File"
                isPublished={planGroup.published}
                parentArray="plan_group_video_urls"
                actualValue={planGroup.plan_group_video_urls}
                updateValue={
                  updated_data.plan_group_video_urls &&
                  updated_data.plan_group_video_urls.update_items
                    ? updated_data.plan_group_video_urls.update_items
                    : false
                }
                validation={{
                  plan_group_video_urls: Yup.array().of(
                    Yup.object().shape({
                      is_mobile: Yup.boolean().required(
                        "Select a video device"
                      ),
                      video_type: Yup.string().required(
                        "Select a video format"
                      ),
                      video_url: Yup.string(),
                    })
                  ),
                }}
              />
            </div>
            <div
              className={"d-flex align-items-end justify-content-end col-md-12"}
            >
              <button
                onClick={resetModal}
                type="button"
                className="btn btn-light btn-sm float-right btn-add-domain mr-2"
              >
                Reset
              </button>
              <CustomButton
                type="button"
                className="btn btn-primary btn-outline-success btn-sm float-right btn-add-domain"
                disabled={!formik.isValid || formik.isSubmitting}
                onClick={
                  formik.isValid && Object.keys(formik.values).length !== 0
                    ? formik.handleSubmit
                    : handleError
                }
              >
                Update
              </CustomButton>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
