import { useState } from "react";
import {
  FORCE_PUBLISH_BLOG_GQL,
  FORCE_PUBLISH_INTERVENTION_GQL,
  FORCE_PUBLISH_PLAN_GQL,
  FORCE_PUBLISH_THERAPY_GQL,
} from "../../Apollo/AdminServices/MutationRequest";
import graphHandler from "../../Apollo/graphHandler";

function useForcePublish(preLoad = false) {
  const [loading, setLoading] = useState(preLoad);

  const forcePublishTool = async (req = {}, sCB = () => {}, eCB = () => {}) => {
    setLoading(true);
    try {
      await graphHandler().invoke(
        FORCE_PUBLISH_INTERVENTION_GQL,
        { ...req },
        1,
        false,
        true
      );
      sCB();
      setLoading(false);
    } catch (error) {
      console.error("forcePublishTool", error);
      eCB();
      setLoading(false);
    }
  };

  const forcePublishBlog = async (req = {}, sCB = () => {}, eCB = () => {}) => {
    setLoading(true);
    try {
      await graphHandler().invoke(
        FORCE_PUBLISH_BLOG_GQL,
        { ...req },
        1,
        false,
        true
      );
      sCB();
      setLoading(false);
    } catch (error) {
      console.error("forcePublishBlog", error);
      eCB();
      setLoading(false);
    }
  };
  const forcePublishTherapy = async (
    req = {},
    sCB = () => {},
    eCB = () => {}
  ) => {
    setLoading(true);
    try {
      await graphHandler().invoke(
        FORCE_PUBLISH_THERAPY_GQL,
        { ...req },
        1,
        false,
        true
      );
      sCB();
      setLoading(false);
    } catch (error) {
      console.error("forcePublishTherapy", error);
      eCB();
      setLoading(false);
    }
  };
  const forcePublishPlan = async (
    req = {},
    sCB = () => {},
    eCB = () => {}
  ) => {
    setLoading(true);
    try {
      await graphHandler().invoke(
        FORCE_PUBLISH_PLAN_GQL,
        { ...req },
        1,
        false,
        true
      );
      sCB();
      setLoading(false);
    } catch (error) {
      console.error("forcePublishPlan", error);
      eCB();
      setLoading(false);
    }
  };
  return {
    loading,
    forcePublishTool,
    forcePublishBlog,
    forcePublishTherapy,
    forcePublishPlan,
  };
}

export default useForcePublish;
