import { Modal, ModalBody } from "reactstrap";
import { ReactSpreadsheetImport } from "react-spreadsheet-import";
import { Steps } from "antd";
import { useState } from "react";
import { optionsTypes, ScreenerFields } from "./types";
import DownloadSection from "../section/DownloadSection";
import { IsNullOrEmpty } from "../../../utils/Functions/filter";
import graphHandler from "../../../Apollo/graphHandler";
import { CREATE_SCREENER_QUESTIONAIRE_GQL } from "../../../Apollo/AdminServices/MutationRequest";
import UploadSection from "../section/UploadSection";
const { Step } = Steps;

export default function UploadQuestion({
  isOpen,
  values = {},
  setOpen = () => {},
  refresh = () => {},
  topicList = [],
  subDomains = [],
  questionaireList = [],
}) {
  const { screener_id } = values;
  const [activeKey, setActive] = useState(0);
  const [isSheetOpen, setSheetOpen] = useState(false);
  const [isDone, setDone] = useState(false);
  const [xcelData, setXcel] = useState([]);
  const [isProcessing, setProcess] = useState(false);
  const [errorData, setErrorData] = useState([]);
  const [successData, setSuccessData] = useState([]);
  const [validData, setValidData] = useState([]);
  let allQuestions = questionaireList
    .slice()
    .sort((a, b) => b.sort_order - a.sort_order);

  const onClose = () => {
    refresh();
    setOpen(false);
    setProcess(false);
    setSheetOpen(false);
    setDone(false);
    setActive(0);
    setErrorData([]);
    setSuccessData([]);
    setValidData([]);
    setXcel([]);
  };

  const getFields = () => {
    let fields = [...ScreenerFields];
    let subIndex = fields.findIndex((x) => x.key === "Sub Domain");
    let topIndex = fields.findIndex((x) => x.key === "Topic");
    fields[subIndex].fieldType = {
      type: "select",
      options: subDomains.map((x) => ({
        label: x.sub_domain_name,
        value: x.sub_domain_id,
      })),
    };
    fields[topIndex].fieldType = {
      type: "select",
      options: topicList.map((x) => ({
        label: x.screener_topic_title,
        value: x.screener_topic_id,
      })),
    };
    return fields;
  };

  const renderSteps = (key) => {
    switch (key) {
      case 0:
        return (
          <DownloadSection
            onClose={onClose}
            onNext={() => setSheetOpen(true)}
            onDownload={setDone}
          />
        );
      case 1:
        return (
          <UploadSection
            onClose={onClose}
            onDone={() => {
              setSheetOpen(false);
              setOpen(false);
            }}
            onBack={() => {
              setActive(0);
              setSheetOpen(false);
            }}
            setOpen={setSheetOpen}
            errorData={errorData}
            validData={validData}
            successData={successData}
            allData={xcelData}
            isProcessing={isProcessing}
          />
        );

      default:
        return <h5>No step found</h5>;
    }
  };

  const onSubmit = async (e) => {
    const { all, invalidData, validData } = e;
    setXcel(all);
    setValidData(validData);
    setErrorData(invalidData);
    setActive(1);
    setSheetOpen(false);
    setProcess(true);
    const requests = await excelToJSON(validData);
    // console.log("Submitted", e, requests);
    if (requests.length === 0) {
      setProcess(false);
      //   return setActive(0);
      return;
    }
    let sort_order = Number(allQuestions.length + 1);
    let error = [];
    let success = [];
    for (let index = 0; index < requests.length; index++) {
      const request = requests[index];
      let res = await createPromise({ ...request, sort_order });
      if (res) {
        success.push(
          validData.find(
            (x) => x["Question"] === request.screener_question_title
          )
        );
        sort_order += 1;
      } else {
        error.push(
          validData.find(
            (x) => x["Question"] === request.screener_question_title
          )
        );
      }
      if (index === requests.length - 1) {
        setErrorData((x) => [...x, ...error]);
        setSuccessData((x) => [...x, ...success]);
        setProcess(false);
        setActive(1);
        setTimeout(() => {
          if (error.length === 0) onClose();
        }, 1000);
      }
    }
  };
  const createPromise = async (create_request) => {
    try {
      const { data } = await graphHandler().invoke(
        CREATE_SCREENER_QUESTIONAIRE_GQL,
        { create_request },
        1,
        false,
        false,
        false
      );
      if (
        data &&
        data.createScreenerQuestion &&
        data.createScreenerQuestion.screener_question
      ) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.error("createScreenerQuestion Error", error);
      return false;
    }
  };

  const excelToJSON = async (array = []) => {
    let returnData = [];
    for (let index = 0; index < array.length; index++) {
      let temp = {
        screener_id,
        screener_question_type: "RadioButton",
        mandatory: true,
        option_type: "Text",
        screener_topic_id: array[index]["Topic"],
        screener_question_title: array[index]["Question"],
        ignore_for_scoring: Boolean(array[index]["Ignore Scoring"]),
      };
      let error = false;
      if (
        !IsNullOrEmpty(array[index]["Result Weightage"]) &&
        Number.isInteger(Number(array[index]["Result Weightage"]))
      ) {
        temp.result_weightage = Number(array[index]["Result Weightage"]);
      } else {
        error = true;
        setErrorData((x) => [...x, array[index]]);
      }
      if (array[index]["Sub Domain"]) {
        temp.sub_domain_id = array[index]["Sub Domain"];
      }
      let option = [];
      for (let j = 0; j < optionsTypes.length; j++) {
        let key = optionsTypes[j];
        if (
          array[index][key] &&
          !IsNullOrEmpty(array[index][key + " value"]) &&
          Number.isInteger(Number(array[index][key + " value"]))
        ) {
          option.push({
            data: array[index][key],
            value: Number(array[index][key + " value"]),
          });
        }
      }
      temp.options = {
        option_type: "Text",
        options: option,
      };
      !error && returnData.push(temp);
    }
    return returnData;
  };

  return (
    <Modal
      toggle={onClose}
      isOpen={isOpen}
      keyboard={false}
      size="lg"
      modalClassName="modal-fullscreen modal-dialog-scrollable"
    >
      <div className="modal-header align-items-center">
        <Steps
          type="navigation"
          current={activeKey}
          className="site-navigation-steps"
        >
          <Step
            status={isDone ? "finish" : "process"}
            title="Download Template"
          />
          <Step
            status={
              xcelData.length > 0 ? "finish" : isDone ? "process" : "wait"
            }
            title="Upload Question"
          />
        </Steps>
      </div>
      <ModalBody>
        {renderSteps(activeKey)}
        <ReactSpreadsheetImport
          uploadStepHook={(data) => {
            // console.log(data);
            let arr = [...data];
            for (let i = 0; i < arr.length; i++) {
              if (i === 0) {
                !arr[i].find((x) => x == "Topic") && arr[i].push("Topic");
                !arr[i].find((x) => x == "Sub Domain") &&
                  arr[i].push("Sub Domain");
              }
            }
            return data;
          }}
          isOpen={isSheetOpen}
          allowInvalidSubmit={false}
          onClose={() => {
            setSheetOpen(false);
          }}
          onSubmit={onSubmit}
          fields={getFields()}
        />
      </ModalBody>
    </Modal>
  );
}
