/* eslint-disable react/no-unescaped-entities */
import { useEffect } from "react";
import "../assets/css/error.css";
export default function ErrorPage({ history }) {
  useEffect(() => {
    history.replace("/404");
  }, []);

  return (
    <div id="notfound">
      <div className="notfound">
        <div className="notfound-404">
          <h1>Oops!</h1>
          <h2>404 - The Page can't be found</h2>
        </div>
        <a href="#/home">Go TO Homepage</a>
      </div>
    </div>
  );
}
