import { useState } from "react";
import { GET_ORGANIZATION_EMPLOYEE_LIST_GQL } from "../../Apollo/ApplicationService/OrganizationResponse";
import graphHandler from "../../Apollo/graphHandler";

function useOrganizationEmployeeList(preload = false) {
  const [organizationEmployeeList, setOrganizationEmployeeList] = useState([]);
  const [loading, setLoading] = useState(preload);

  const getOrganizationEmployeeList = async (req = {}) => {
    setOrganizationEmployeeList([]);
    setLoading(true);
    try {
      const { data } = await graphHandler().invoke(
        GET_ORGANIZATION_EMPLOYEE_LIST_GQL,
        { ...req },
        0,
        false,
        true
      );
      if (
        data &&
        data.getOrganizationEmployeeList &&
        data.getOrganizationEmployeeList.organization_employees &&
        data.getOrganizationEmployeeList.organization_employees.length > 0
      ) {
         setOrganizationEmployeeList(
          data.getOrganizationEmployeeList.organization_employees
            .slice()
            .sort(
              (a, b) =>
                new Date(b.organization_employee.updated_at ? b.organization_employee.updated_at : b.organization_employee.created_at) -
                new Date(a.organization_employee.updated_at ? a.organization_employee.updated_at : a.organization_employee.created_at)
            )
        );
        setLoading(false);
      } else {
        setOrganizationEmployeeList([]);
        setLoading(false);
      }
    } catch (error) {
      console.log("getOrganizationList Error", error);
      setOrganizationEmployeeList([]);
      setLoading(false);
    }
  };

  return {
    organizationEmployeeList,
    loading,
    getOrganizationEmployeeList,
  };
}

export default useOrganizationEmployeeList;
