import { useEffect, useState } from "react";
import { Collapse } from "reactstrap";
import { UPDATE_TOPIC_GQL } from "../../../Apollo/AdminServices/MutationRequest";
import graphHandler from "../../../Apollo/graphHandler";
import useQuestionaireList from "../../../utils/CustomHooks/useQuestionaireList";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import SortByTopicAccordionItem from "./SortByTopicAccordionItem";
import { IsNullOrEmpty } from "../../../utils/Functions/filter";

export default function SortByTopicItem({
  item = {},
  i = 0,
  isEditable,
  current,
  setCurrent,
}) {
  const [questions, setQuestions] = useState([]);
  const { questionaireList, getQuestionaireList } = useQuestionaireList("");

  useEffect(() => {
    if (questionaireList&&questionaireList.length > 0) {
      let data =
        questionaireList &&
        questionaireList.length > 0 &&
        questionaireList.slice().filter((x)=>x?.active === true).map((x) => {
          const hasUpdated = Boolean(
            x && x?.pending_history && x?.pending_history?.updated_data
          );
          return {
            ...x,
            sort_order: hasUpdated
              ? x?.pending_history?.updated_data?.sort_order
              : x?.sort_order,
          };
        });
      setQuestions([
        ...data.slice().sort((a, b) => a.sort_order - b.sort_order),
      ]);
    }
  }, [questionaireList]);

  const reorder = (list, startIndex, endIndex) => {
    let newData = [
      ...list.map((x, i) => ({
        ...x,
        sort_order:
          i === startIndex
            ? startIndex + 1
            : i === endIndex
            ? endIndex + 1
            : x.sort_order,
      })),
    ];
    const result = Array.from(newData);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result.map((x) => x);
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;
    const items = reorder(
      questions,
      result.source.index,
      result.destination.index
    );
    setQuestions(items);
  };
  useEffect(() => {
    if (item.screener_topic_id) {
      getQuestionaireList({
        screener_topic_id: item.screener_topic_id,
        get_pending_update: true,
        active:true
      });
    }
  }, [item.screener_topic_id]);

  useEffect(() => {
    if (
      !IsNullOrEmpty(item.sort_order) &&
      isEditable &&
      i !== item.sort_order &&
      item.screener_topic_id
    ) {
      let request = {
          screener_topic_id: item.screener_topic_id,
          sort_order: i,
        },
        update_data =
          item && item.pending_history && item.pending_history.updated_data
            ? item.pending_history.updated_data
            : null;
      if (update_data) {
        if (update_data.screener_topic_title) {
          request.screener_topic_title = update_data.screener_topic_title;
        }
        if (update_data.screener_result_calculation_type) {
          request.screener_result_calculation_type =
            update_data.screener_result_calculation_type;
        }
        if (update_data.domain_id) {
          request.domain_id = update_data.domain_id;
        }
        if (update_data.ignore_for_scoring) {
          request.ignore_for_scoring = update_data.ignore_for_scoring;
        }
        if (
          update_data.screener_topic_aggregate_type &&
          update_data.screener_topic_aggregate_type.update_items &&
          update_data.screener_topic_aggregate_type.update_items.length > 0
        ) {
          request.screener_topic_aggregate_type = {
            update_items:
              update_data.screener_topic_aggregate_type.update_items,
          };
        }
      }
      update({
        ...request,
      });
    }
  }, [i, item, isEditable]);
  //   console.log(item);
  const update = async (update_request) => {
    try {
      const { data } = await graphHandler().invoke(
        UPDATE_TOPIC_GQL,
        { update_request },
        1,
        false,
        true
      );
      if (
        data &&
        data.updateScreenerTopic &&
        data.updateScreenerTopic.screener_topic
      ) {
        // console.log(
        //   "Topic Sort Success",
        //   data.updateScreenerTopic.screener_topic
        // );
      }
    } catch (error) {
      console.error("Topic Sort Error", error);
    }
  };
  //   if (!item.screener_topic_id) return null;
  return (
    <div className="col-md-12 mb-3 w-100">
      <Draggable
        key={item.screener_topic_id}
        draggableId={item.screener_topic_id}
        index={i - 1}
        isDragDisabled={!isEditable}
      >
        {(provided) => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            className="col-md-12 p-0"
          >
            <div
              className={`custom-accordion ${
                current === item.screener_topic_id && "active"
              }`}
            >
              <div
                className="accordion-header"
                onClick={() =>
                  setCurrent((x) =>
                    x === item.screener_topic_id ? "" : item.screener_topic_id
                  )
                }
              >
                {item.screener_topic_title +
                  " (" +
                  questionaireList.length +
                  ") "}
                <div>
                  <i className="fa fa-chevron-down" />
                  <i
                    className="fas fa-grip-vertical ml-2"
                    {...provided.dragHandleProps}
                  />
                </div>
              </div>
            </div>

            {/* <h6 className="card-title position-relative">
          {`${i + 1}). ${
            item.screener_topic_title ? item.screener_topic_title : ""
          }`}
        </h6>
        <i className="fa fa-chevron-down mr-2" /> */}
            <Collapse isOpen={current === item.screener_topic_id}>
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable
                  droppableId={`droppable-${item.screener_topic_id}`}
                  type={`${item.screener_topic_id}`}
                >
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                      className="col-md-12 p-0"
                    >
                      <div className="border p-2">
                        <table className="table questionnaire-table table-borderless mb-0">
                          <thead>
                            <tr className=" ">
                              <td className="col-md-5">Question</td>
                              <td className="col-md-4">Options</td>
                              <td className="col-md-1">Weightage</td>
                              <td className="col-md-2">Ignore Scoring</td>
                            </tr>
                          </thead>
                          <tbody>
                            {questions && questions.length > 0 ? (
                              questions.slice().map((data, j) => (
                                <Draggable
                                  key={data.screener_question_id}
                                  draggableId={data.screener_question_id}
                                  index={j}
                                  isDragDisabled={!isEditable}
                                >
                                  {(provided) => (
                                    <tr
                                      className="bg-white border border-top-0 rounded-sm option-table w-100"
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                    >
                                      <SortByTopicAccordionItem
                                        item={data}
                                        i={j + 1}
                                        isEditable={isEditable}
                                      />
                                    </tr>
                                  )}
                                </Draggable>
                              ))
                            ) : (
                              <tr>
                                <td className="text-center" colSpan="5">
                                  No Question Found
                                </td>
                              </tr>
                            )}
                            {provided.placeholder}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </Collapse>
          </div>
        )}
      </Draggable>
    </div>
  );
}
