import { useFormik } from "formik";
import { useMemo } from "react";
import CustomButton from "../../../components/Button/CustomButton";
import * as Yup from "yup";
import { toast } from "react-toastify";
import MultiSelect from "../../../components/MultiSelect/MultiSelect";
import { ActionType } from "../../../constants";
import Description from "../../../components/Input/Description";
import graphHandler from "../../../Apollo/graphHandler";
import { CREATE_MODULE_ACTION_GQL } from "../../../Apollo/AdminServices/MutationRequest";

const CreateTherapyAction = ({
  refresh = () => {},
  subDomainList = [],
  setLoading = () => {},
  moduleList = [],
  therapy = {},
  setAction,
  interventions = [],
  screeners = [],
}) => {
  const { therapy_id } = therapy;

  const formik = useFormik({
    initialValues: {
      sub_domain_ids: [],
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      onSubmit(values);
    },
  });

  useMemo(() => {
    let values = { ...formik.values };
    delete values.intervention_id;
    delete values.screener_id;
    delete values.therapy_module_action_image_urls;
    delete values.therapy_module_action_document_urls;
    delete values.therapy_module_action_video_urls;
    formik.setValues({ ...values });
  }, [formik.values.therapy_action_type]);

  const onSubmit = async (value) => {
    formik.setSubmitting(false);
    const {
      sort_order,
      sub_domain_ids,
      therapy_action_type,
      intervention_id,
      description,
      mobile_description,
      therapy_module_action_code,
      therapy_module_action_name,
      therapy_module_id,
      screener_id,
    } = value;
    // console.log("value",value)
    if (
      therapy_action_type.value === "Intervention" &&
      (!intervention_id || !intervention_id.value)
    )
      return toast.warn("Intervention is required field");
    if (
      therapy_action_type.value === "Screener" &&
      (!screener_id || !screener_id.value)
    )
      return toast.warn("Screener is required field");
    let create_request = {
      therapy_id,
      therapy_module_id: therapy_module_id.value,
      therapy_action_type: therapy_action_type.value,
      sub_domain_ids: [...sub_domain_ids.map((x) => x.value)],
      therapy_module_action_name,
      therapy_module_action_code,
      sort_order: Number(sort_order),
    };
    let descriptions = [];
    if (description) {
      descriptions.push({
        client_type: "Web",
        description: description,
      });
    }
    if (mobile_description) {
      descriptions.push({
        client_type: "Mobile",
        description: mobile_description,
      });
    }
    if (descriptions.length > 0)
      create_request.therapy_module_action_description = descriptions;
    if (therapy_action_type.value === "Intervention" && intervention_id.value) {
      create_request.intervention_id = intervention_id.value;
    } else if (therapy_action_type.value === "Screener" && screener_id.value) {
      create_request.screener_id = screener_id.value;
    }
    try {
      const {data} = await graphHandler().invoke(
        CREATE_MODULE_ACTION_GQL,
        { create_request },
        1,
        false
      );
      if (data && data.createTherapyModuleAction && data.createTherapyModuleAction.therapy_module_action) {
        setAction({
          ...data.createTherapyModuleAction.therapy_module_action,
          pending_history: data.createTherapyModuleAction.therapy_module_action_history,
        });
      }
      refresh();
      setLoading(false);
      toast.success("Created Successfully");
      formik.setSubmitting(false);
      resetModal();
    } catch (error) {
      console.log("createPlanAction error", error);
      setLoading(false);
      formik.setSubmitting(false);
    }
  };
  const handleError = () => {
    if (Object.keys(formik.errors).length > 0) {
      toast.error(formik.errors[Object.keys(formik.errors)[0]]);
    }
  };

  const handleNumber = (e) => {
    const { name, value } = e.target;
    const re = /^[0-9\b]+$/;
    let temp = !value ? value : re.test(value) ? value : formik.values[name];
    formik.setFieldValue(name, temp ? temp.substring(0, 10) : temp);
  };

  const resetModal = () => {
    formik.resetForm();
    formik.setValues({});
    formik.setSubmitting(false);
  };

  const renderActionInput = (type) => {
    switch (type) {
      case "Intervention":
        return (
          <div className="col-md-6 form-group ">
            <label
              htmlFor="intervention_id"
              className="col-form-label w-100 mb-2"
            >
              Intervention
            </label>
            <MultiSelect
              option={interventions}
              onChange={(e) => formik.setFieldValue("intervention_id", e)}
              placeholder="Select intervention"
              isCreatable={false}
              isMulti={false}
              type="Sub Domain"
              name="intervention_id"
              id="intervention_id"
              onBlur={formik.handleBlur}
              defaultValue={formik.values.intervention_id || "Reset"}
            />
            <small className="text-danger align-self-end">
              {formik.touched.intervention_id &&
              formik.errors &&
              formik.errors.intervention_id
                ? formik.errors.intervention_id
                : ""}
            </small>
          </div>
        );
      case "Screener":
        return (
          <div className="col-md-6 form-group ">
            <label htmlFor="screener_id" className="col-form-label w-100 mb-2">
              Screener
            </label>
            <MultiSelect
              option={screeners}
              onChange={(e) => formik.setFieldValue("screener_id", e)}
              placeholder="Select screener"
              isCreatable={false}
              isMulti={false}
              type="Sub Domain"
              name="screener_id"
              id="screener_id"
              onBlur={formik.handleBlur}
              defaultValue={formik.values.screener_id || "Reset"}
            />
            <small className="text-danger align-self-end">
              {formik.touched.screener_id &&
              formik.errors &&
              formik.errors.screener_id
                ? formik.errors.screener_id
                : ""}
            </small>
          </div>
        );

      default:
        return !type ? null : (
          <div className="col-md-6 form-group ">
            <label className="col-form-label w-100 mb-2">{type}</label>
            <input
              type="text"
              className="form-control"
              value="You can upload after create"
              disabled
            />
          </div>
        );
    }
  };
  return (
    <>
      <div className="col-md-12 modal-header" id="CreateTopic">
        <h5 className="modal-title text-center mb-3">Create Action</h5>
      </div>
      <div className="col-md-12">
        <div className="  border">
          <div className="row p-3">
            <div className="col-md-6">
              <div className="row">
                <div className="col-md-12 form-group">
                  <label
                    htmlFor="sub_domain_ids"
                    className="col-form-label w-100 mb-2"
                  >
                    Sub Domains
                  </label>
                  <MultiSelect
                    option={subDomainList}
                    onChange={(e) => formik.setFieldValue("sub_domain_ids", e)}
                    placeholder="Select Sub Domain"
                    isCreatable={false}
                    type="Sub Domain"
                    name="sub_domain_ids"
                    id="sub_domain_ids"
                    onBlur={formik.handleBlur}
                    defaultValue={formik.values.sub_domain_ids || "Reset"}
                  />
                  <small className="text-danger">
                    {formik.touched.sub_domain_ids &&
                    formik.errors &&
                    formik.errors.sub_domain_ids
                      ? formik.errors.sub_domain_ids
                      : ""}
                  </small>
                </div>
                <div className="col-md-12 form-group ">
                  <label
                    htmlFor="therapy_module_id"
                    className="col-form-label w-100 mb-2"
                  >
                    Therapy Module
                  </label>
                  <MultiSelect
                    option={moduleList}
                    onChange={(e) =>
                      formik.setFieldValue("therapy_module_id", e)
                    }
                    placeholder="Select module"
                    isCreatable={false}
                    isMulti={false}
                    type="Sub Domain"
                    name="therapy_module_id"
                    id="therapy_module_id"
                    onBlur={formik.handleBlur}
                    defaultValue={formik.values.therapy_module_id || "Reset"}
                  />
                  <small className="text-danger align-self-end">
                    {formik.touched.therapy_module_id &&
                    formik.errors &&
                    formik.errors.therapy_module_id
                      ? formik.errors.therapy_module_id
                      : ""}
                  </small>
                </div>
                <div className="col-md-12 form-group">
                  <label
                    htmlFor="therapy_module_action_name"
                    className="col-form-label w-100 mb-2"
                  >
                    Action Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="therapy_module_action_name"
                    name="therapy_module_action_name"
                    onChange={formik.handleChange}
                    // maxLength={30}
                    value={
                      formik.values.therapy_module_action_name
                        ? formik.values.therapy_module_action_name
                        : ""
                    }
                    onBlur={formik.handleBlur}
                  />
                  <small className="text-danger align-self-end">
                    {formik.touched.therapy_module_action_name &&
                    formik.errors &&
                    formik.errors.therapy_module_action_name
                      ? formik.errors.therapy_module_action_name
                      : ""}
                  </small>
                </div>
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-md-6 form-group">
                      <label
                        htmlFor="therapy_module_action_code"
                        className="col-form-label w-100 mb-2"
                      >
                        Code
                      </label>
                      <input
                        {...formik.getFieldProps("therapy_module_action_code")}
                        type="text"
                        className="form-control "
                        id="therapy_module_action_code"
                        value={
                          formik.values.therapy_module_action_code
                            ? formik.values.therapy_module_action_code
                            : ""
                        }
                      />
                      <small className="text-danger align-self-end">
                        {formik.touched.therapy_module_action_code &&
                        formik.errors &&
                        formik.errors.therapy_module_action_code
                          ? formik.errors.therapy_module_action_code
                          : ""}
                      </small>
                    </div>
                    <div className="col-md-6 form-group">
                      <label
                        htmlFor="sort_order"
                        className="col-form-label w-100 mb-2"
                      >
                        Sort Order
                      </label>
                      <input
                        {...formik.getFieldProps("sort_order")}
                        type="text"
                        className="form-control "
                        id="sort_order"
                        onChange={handleNumber}
                        maxLength={10}
                        value={
                          formik.values.sort_order
                            ? formik.values.sort_order
                            : ""
                        }
                      />
                      <small className="text-danger align-self-end">
                        {formik.touched.sort_order &&
                        formik.errors &&
                        formik.errors.sort_order
                          ? formik.errors.sort_order
                          : ""}
                      </small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="row">
                <Description formik={formik} webName="description" />
              </div>
            </div>
            <div className="col-md-6 form-group ">
              <label
                htmlFor="therapy_action_type"
                className="col-form-label w-100 mb-2"
              >
                Action Type
              </label>
              <MultiSelect
                option={ActionType}
                onChange={(e) => formik.setFieldValue("therapy_action_type", e)}
                placeholder="Select group"
                isCreatable={false}
                isMulti={false}
                type="Sub Domain"
                name="therapy_action_type"
                id="therapy_action_type"
                onBlur={formik.handleBlur}
                defaultValue={formik.values.therapy_action_type || "Reset"}
              />
              <small className="text-danger align-self-end">
                {formik.touched.therapy_action_type &&
                formik.errors &&
                formik.errors.therapy_action_type
                  ? formik.errors.therapy_action_type
                  : ""}
              </small>
            </div>
            {renderActionInput(formik.values.therapy_action_type?.value)}

            <div
              className={"d-flex align-items-end justify-content-end col-md-12"}
            >
              <button
                onClick={resetModal}
                type="button"
                className="btn btn-light btn-sm float-right btn-add-domain mr-2"
              >
                Reset
              </button>
              <CustomButton
                type="button"
                className="btn btn-primary btn-outline-success btn-sm float-right btn-add-domain"
                disabled={
                  Object.keys(formik.errors).length !== 0 ||
                  !formik.isValid ||
                  formik.isSubmitting
                }
                onClick={
                  formik.isValid && Object.keys(formik.values).length !== 0
                    ? formik.handleSubmit
                    : handleError
                }
              >
                Create
              </CustomButton>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateTherapyAction;

const validationSchema = Yup.object().shape({
  sub_domain_ids: Yup.array()
    .of(
      Yup.object().shape({
        label: Yup.string(),
        value: Yup.string(),
      })
    ),
    // .test({
    //   message: "Select sub domain",
    //   test: (arr) => {
    //     return arr && arr.length > 0;
    //   },
    // }),
  therapy_module_id: Yup.object().required("Select group"),
  therapy_action_type: Yup.object().required("Select type"),
  sort_order: Yup.string().required("Enter sort order"),
  therapy_module_action_name: Yup.string().required("Enter action name"),
  description: Yup.string().required("Enter description"),
  mobile_description: Yup.string().required("Enter mobile description"),
  therapy_module_action_code: Yup.string().required("Enter action code"),
});
