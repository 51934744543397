import { toast } from "react-toastify";
import { isInputNumber, IsNullOrEmpty } from "../../../utils/Functions/filter";
import * as Yup from "yup";
import { useFormik } from "formik";
import CustomButton from "../../../components/Button/CustomButton";
import graphHandler from "../../../Apollo/graphHandler";
import { CREATE_SCREENER_THRESHOLD_GQL } from "../../../Apollo/AdminServices/MutationRequest";

export default function CreateThresholdSection({
  screener = {},
  disabled,
  subDomains = [],
  topicList = [],
  domainList = [],
  refresh = () => {},
  setLoading = () => {},
}) {
  const { screener_id } = screener;
  const formik = useFormik({
    initialValues: {},
    validationSchema: validation,
    onSubmit: (values) => {
      onSubmit(values);
    },
  });
  const handleError = () => {
    if (Object.keys(formik.errors).length > 0) {
      toast.error(formik.errors[Object.keys(formik.errors)[0]]);
    }
  };
  const onSubmit = async (modal) => {
    formik.setSubmitting(true);
    const { screener_threshold_entity_type, entity_ref_id, min_threshold } =
      modal;
    let create_request = {
      screener_id,
      screener_threshold_entity_type,
      entity_ref_id,
      min_threshold: Number(min_threshold),
    };
    try {
      const { data } = await graphHandler().invoke(
        CREATE_SCREENER_THRESHOLD_GQL,
        { create_request },
        1,
        false,
        true
      );
      if (
        data &&
        data.createScreenerThreshold &&
        data.createScreenerThreshold.screener_threshold
      ) {
        // console.log(
        //   "createScreenerTopic Success",
        //   data.createScreenerThreshold.screener_threshold
        // );
        refresh();
        setLoading(false);
        toast.success("Created Successfully");
        formik.setSubmitting(false);
        resetModal();
      }
    } catch (error) {
      console.error("createScreenerThreshold Error", error);
      setLoading(false);
      formik.setSubmitting(false);
    }
  };
  const handleTypeChange = (e) => {
    const { name, value } = e.target;
    formik.setFieldValue(name, value);
    formik.setFieldValue(
      "entity_ref_id",
      value === "Screener" ? screener_id : ""
    );
  };
  const resetModal = () => {
    formik.resetForm();
    formik.setValues({});
    formik.setSubmitting(false);
  };
  return (
    <>
      <div className="col-md-12 modal-header text-center align-items-center">
        <h5 className="modal-title text-center">Create Threshold</h5>
      </div>
      <div className="col-md-12">
        <div className="border">
          <div className="row p-3">
            <div className="col-md-4 form-group">
              <label
                htmlFor="screener_threshold_entity_type"
                className="col-form-label w-100 mb-2"
              >
                Type
              </label>
              <select
                className="custom-select"
                name="screener_threshold_entity_type"
                id="screener_threshold_entity_type"
                onChange={handleTypeChange}
                value={
                  formik.values.screener_threshold_entity_type
                    ? formik.values.screener_threshold_entity_type
                    : ""
                }
                onBlur={formik.handleBlur}
                disabled={disabled}
              >
                <option key="Select" hidden>
                  Select
                </option>
                <option value="Domain">Domain</option>
                <option value="SubDomain">Sub Domain</option>
                <option value="Topic">Topic</option>
                <option value="Screener">Screener</option>
              </select>
              <small className="text-danger col-md-12">
                {formik.touched.screener_threshold_entity_type &&
                formik.errors.screener_threshold_entity_type
                  ? formik.errors.screener_threshold_entity_type
                  : ""}
              </small>
            </div>
            {formik.values.screener_threshold_entity_type !== "Screener" && (
              <div className="col-md-4 form-group ">
                <label
                  htmlFor="entity_ref_id"
                  className="col-form-label w-100 mb-2"
                >
                  {formik.values.screener_threshold_entity_type === "SubDomain"
                    ? "Sub Domain"
                    : formik.values.screener_threshold_entity_type ||
                      "Reference"}
                </label>
                <select
                  className="custom-select"
                  name="entity_ref_id"
                  id="entity_ref_id"
                  onChange={formik.handleChange}
                  value={formik.values.entity_ref_id}
                  onBlur={formik.handleBlur}
                  disabled={disabled}
                >
                  <option key="Select" hidden value="">
                    Select
                  </option>

                  {!formik.values.screener_threshold_entity_type
                    ? null
                    : formik.values.screener_threshold_entity_type ===
                      "SubDomain"
                    ? subDomains.map((item, index) => (
                        <option
                          key={"SubDomain" + index}
                          value={item.sub_domain_id}
                        >
                          {item.sub_domain_name}
                        </option>
                      ))
                    : formik.values.screener_threshold_entity_type === "Domain"
                    ? domainList.map((item, index) => (
                        <option value={item.domain_id} key={"domain" + index}>
                          {item.domain_name}
                        </option>
                      ))
                    : topicList.map((item, index) => (
                        <option
                          key={"Topic" + index}
                          value={item.screener_topic_id}
                        >
                          {item.screener_topic_title}
                        </option>
                      ))}
                </select>
                <small className="text-danger col-md-12">
                  {formik.touched.entity_ref_id &&
                  formik.errors.entity_ref_id &&
                  formik.values.screener_threshold_entity_type
                    ? formik.errors.entity_ref_id +
                      (formik.values.screener_threshold_entity_type ===
                      "SubDomain"
                        ? "sub domain"
                        : formik.values.screener_threshold_entity_type.toLowerCase() ||
                          "Reference")
                    : ""}
                </small>
              </div>
            )}
            <div className="col-md-4 form-group">
              <label
                htmlFor="min_threshold"
                className="col-form-label w-100 mb-2"
              >
                Min Threshold
              </label>
              <input
                type="text"
                className="form-control"
                name="min_threshold"
                id="min_threshold"
                onChange={formik.handleChange}
                placeholder=""
                value={
                  !IsNullOrEmpty(formik.values.min_threshold)
                    ? formik.values.min_threshold
                    : ""
                }
                onBlur={formik.handleBlur}
                disabled={disabled}
                onKeyPress={isInputNumber}
              />
              <small className="text-danger col-md-12">
                {formik.touched.min_threshold && formik.errors.min_threshold
                  ? formik.errors.min_threshold
                  : ""}
              </small>
            </div>

            <div
              className={"d-flex align-items-end justify-content-end col-md-12"}
            >
              {formik.values.screener_threshold_id && (
                <button
                  onClick={resetModal}
                  type="button"
                  className="btn btn-light btn-sm float-right btn-add-domain mr-2"
                >
                  Reset
                </button>
              )}
              <CustomButton
                type="button"
                className="btn btn-primary btn-outline-success btn-sm float-right btn-add-domain"
                disabled={!formik.isValid || formik.isSubmitting}
                onClick={
                  formik.isValid && Object.keys(formik.values).length !== 0
                    ? formik.handleSubmit
                    : handleError
                }
              >
                {formik.values.screener_threshold_id ? "Update" : "Create"}
              </CustomButton>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const validation = Yup.object().shape({
  screener_threshold_entity_type: Yup.string().required("Select a type"),
  entity_ref_id: Yup.string().required("Select a "),
  min_threshold: Yup.string().required("Enter threshold"),
});
