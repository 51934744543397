import { useState } from "react";
import { GET_LICENSE_LIST_GQL } from "../../Apollo/ApplicationService/OrganizationResponse";
import graphHandler from "../../Apollo/graphHandler";

function useLicenseList(preload = false) {
  const [licenseList, setLicenseList] = useState([]);
  const [loading, setLoading] = useState(preload);

  const getLicenseList = async (req = {}) => {
    setLicenseList([]);
    setLoading(true);
    try {
      const { data } = await graphHandler().invoke(
        GET_LICENSE_LIST_GQL,
        { ...req },
        2,
        false,
        true
      );
      if (
        data &&
        data.getOrganizationLicenseList &&
        data.getOrganizationLicenseList.organization_licenses &&
        data.getOrganizationLicenseList.organization_licenses.length > 0
        ) {
        // console.log("getOrganizationLicenseList Success", data.getOrganizationLicenseList);
        setLicenseList(
          data.getOrganizationLicenseList.organization_licenses
            .slice()
            .sort(
              (a, b) =>
                new Date(b.updated_at ? b.updated_at : b.created_at) -
                new Date(a.updated_at ? a.updated_at : a.created_at)
            )
        );
        setLoading(false);
      } else {
        setLicenseList([]);
        setLoading(false);
      }
    } catch (error) {
      console.log("getOrganizationLicenseList Error", error);
      setLicenseList([]);
      setLoading(false);
    }
  };

  return {
    licenseList,
    loading,
    getLicenseList,
  };
}

export default useLicenseList;
