export default function Index({
  handleClick = () => {},
  currentPage = 0,
  pageCount = 10,
  className = "",
}) {
  return (
    <nav aria-label="Page navigation">
      <ul className={ `pagination justify-content-center  ${ className }` }>
        <li className="page-item">
          <a
            className="page-link"
            onClick={ (e) => currentPage > 0 && handleClick(e, currentPage - 1) }
            aria-label="Previous"
          >
            <span aria-hidden="true">&laquo;</span>
          </a>
        </li>
        {[ ...Array(pageCount).keys() ].map((x, i) => (
          <li key={ i } className={ `page-item ${ i === currentPage && "active" }` }>
            <div className="page-link" onClick={ (e) => handleClick(e, i) }>
              {i + 1}
            </div>
          </li>
        ))}

        <li className="page-item">
          <a
            className="page-link"
            onClick={ (e) =>
              currentPage < pageCount - 1 && handleClick(e, currentPage + 1)
            }
            aria-label="Next"
          >
            <span aria-hidden="true">&raquo;</span>
          </a>
        </li>
      </ul>
    </nav>
  );
}
