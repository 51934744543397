import QuestionSortSection from "./QuestionSortSection";
import TopicSortSection from "./TopicSortSection";

export default function SortingSection(props) {
  let { onClose, onBack, onDone, values } = props;

  const isTopic = values.sort_type === "Topic";
  return (
    <div className="row">
      <div className="col-md-12">
        <div className="row">
          <div className="col-md-12 modal-header" id="CreateQuestion">
            <h5 className="modal-title text-center mb-3">
              {isTopic ? "Topic" : "Question"}
            </h5>
          </div>
        </div>
        {isTopic ? (
          <TopicSortSection {...props} />
        ) : (
          <QuestionSortSection {...props} />
        )}
        <div className="d-flex align-items-end col-md-12 justify-content-center p-5">
          <button
            onClick={onClose}
            type="button"
            className="btn btn-danger btn-sm float-right btn-add-domain mr-2"
          >
            Close
          </button>{" "}
          <button
            onClick={onBack}
            type="button"
            className="btn btn-error btn-sm float-right btn-add-domain mr-2"
          >
            Back
          </button>
          <button
            onClick={onDone}
            type="button"
            className="btn btn-secondary btn-sm float-right btn-add-domain mr-2"
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
}
