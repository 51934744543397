import { useEffect } from "react";
import { Modal, ModalBody } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import graphHandler from "../../../Apollo/graphHandler";
import CustomButton from "../../../components/Button/CustomButton";
// import { transactionType } from "../../../constants";
import { UPDATE_LICENSE_GQL } from "../../../Apollo/ApplicationService/OrganizationResponse";
import moment from "moment";

export default function UpdateLicense({
  isOpen,
  setOpen,
  refresh,
  initialValue = {},
  organizationOption = [],
}) {
  const formik = useFormik({
    initialValues: {},
    validationSchema: validation,
    onSubmit: (values) => {
      onSubmit(values);
    },
  });

  useEffect(() => {
    if (initialValue.organization_license_id) {
      const value = {
        organization_license_id: initialValue.organization_license_id,
        organization_id: initialValue.organization_id,
        total_seat_count: initialValue.total_seat_count,
        license_duration_months: initialValue.license_duration_months,
        allow_signup: initialValue.allow_signup,
        license_start_ts:initialValue.license_start_ts.split("T")[0],
        license_expiry_ts:initialValue.license_expiry_ts.split("T")[0],
        // transaction_type: initialValue.transaction_type,
        // amount: initialValue.amount,
        // cgst: initialValue.cgst,
        // igst: initialValue.igst,
        // sgst: initialValue.sgst,
        // vat: initialValue.vat,
      };
      if (initialValue.comment) {
        value.comment = initialValue.comment;
      }
      formik.setValues(value);
    }
  }, [initialValue, isOpen]);

  const onClose = () => {
    formik.resetForm();
    setOpen();
  };

  const onSubmit = async (values) => {
    const {
      organization_license_id,
      organization_id,
      total_seat_count,
      // license_duration_months,
      allow_signup,
      // transaction_type,
      // comment,
      // amount,
      // cgst,
      // igst,
      // sgst,
      // vat,
    } = values;
    const update_request = {
      organization_license_id,
      organization_id
    };
    // if (organization_id !== initialValue.organization_id) {
    //   update_request.organization_id = organization_id;
    // }
    if (total_seat_count != initialValue.total_seat_count) {
      update_request.total_seat_count = Number(total_seat_count);
    }
    // if (license_duration_months != initialValue.license_duration_months) {
    //   update_request.license_duration_months = license_duration_months;
    // }
    // if (transaction_type != initialValue.transaction_type) {
    //   update_request.transaction_type = transaction_type;
    // }
    // if (amount != initialValue.amount) {
    //   update_request.amount = amount;
    // }
    if (allow_signup != initialValue.allow_signup) {
      update_request.allow_signup = allow_signup;
    }
    // if (cgst != initialValue.cgst) {
    //   update_request.cgst = cgst;
    // }
    // if (igst != initialValue.igst) {
    //   update_request.igst = igst;
    // }
    // if (sgst != initialValue.sgst) {
    //   update_request.sgst = sgst;
    // }
    // if (vat != initialValue.vat) {
    //   update_request.vat = vat;
    // }
    // if (comment !== initialValue.comment) {
    //   update_request.comment = comment;
    // }
    try {
      await graphHandler().invoke(
        UPDATE_LICENSE_GQL,
        { update_request },
        2,
        true
      );
      refresh();
      onClose();
      formik.setSubmitting(false);
    } catch (error) {
      console.log("UpdateCountry error", error);
      formik.setSubmitting(false);
    }
  };

  const handleError = () => {
    if (Object.keys(formik.errors).length > 0) {
      toast.error(formik.errors[Object.keys(formik.errors)[0]]);
    }
  };

  return (
    <Modal toggle={onClose} isOpen={isOpen} centered scrollable size="xl">
      <div className="modal-header">
        <button type="button" className="close" onClick={onClose}>
          <span aria-hidden="true">&times;</span>
        </button>
        <h5 className="modal-title">Update License</h5>
      </div>
      <ModalBody className="pt-0">
        <div className="container">
          <div className="intervention-form justify-content-center row">
            <div className="col-md-12 form-group">
              <label
                htmlFor="organization_id"
                className="col-form-label w-100 mb-2"
              >
                Organization
              </label>
              <select
                id="organization_id"
                name="organization_id"
                className="form-control"
                onChange={formik.handleChange}
                value={formik.values.organization_id}
                onBlur={formik.handleBlur}
                disabled={initialValue.organization_license_id}
              >
                <option key="Select" hidden>
                  Select
                </option>
                {organizationOption.map((x, i) => (
                  <option value={x.value} key={i}>
                    {x.label}
                  </option>
                ))}
              </select>
              <small className="text-danger">
                {formik.touched.organization_id &&
                formik.errors &&
                formik.errors.organization_id
                  ? formik.errors.organization_id
                  : ""}
              </small>
            </div>
            {/* <div className="col-md-12 form-group">
              <label
                htmlFor="transaction_type"
                className="col-form-label w-100 mb-2"
              >
                Transaction Type
              </label>
              <select
                id="transaction_type"
                name="transaction_type"
                className="form-control"
                onChange={formik.handleChange}
                value={formik.values.transaction_type}
                onBlur={formik.handleBlur}
              >
                <option key="Select" hidden>
                  Select
                </option>
                {transactionType.map((x, i) => (
                  <option value={x.value} key={i}>
                    {x.label}
                  </option>
                ))}
              </select>
              <small className="text-danger">
                {formik.touched.transaction_type &&
                formik.errors &&
                formik.errors.transaction_type
                  ? formik.errors.transaction_type
                  : ""}
              </small>
            </div> */}
            <div className="col-md-12 form-group">
              <label
                htmlFor="license_duration_months"
                className="col-form-label w-100 mb-2"
              >
                License Duration (month)
              </label>
              <input
                type="text"
                className="form-control"
                id="license_duration_months"
                name="license_duration_months"
                disabled={initialValue.organization_license_id}
                value={
                  formik.values && formik.values.license_duration_months
                    ? formik.values.license_duration_months
                    : ""
                }
                onChange={(e) =>
                  formik.setFieldValue(
                    "license_duration_months",
                    e.target.value.replace(/[^0-9]/gi, "")
                  )
                }
                onBlur={formik.handleBlur}
              />
              <small className="text-danger">
                {formik.touched.license_duration_months &&
                formik.errors &&
                formik.errors.license_duration_months
                  ? formik.errors.license_duration_months
                  : ""}
              </small>
            </div>
            <div className="col-md-12 form-group">
              <label
                htmlFor="total_seat_count"
                className="col-form-label w-100 mb-2"
              >
                Total Seat Count
              </label>
              <input
                type="text"
                className="form-control"
                id="total_seat_count"
                name="total_seat_count"
                value={
                  formik.values && formik.values.total_seat_count
                    ? formik.values.total_seat_count
                    : ""
                }
                onChange={(e) =>
                  formik.setFieldValue(
                    "total_seat_count",
                    e.target.value.replace(/[^0-9]/gi, "")
                  )
                }
                onBlur={formik.handleBlur}
              />
              <small className="text-danger">
                {formik.touched.total_seat_count &&
                formik.errors &&
                formik.errors.total_seat_count
                  ? formik.errors.total_seat_count
                  : ""}
              </small>
            </div>
            {/* <div className="col-md-12 form-group ">
              <label htmlFor="amount" className="col-form-label w-100 mb-2">
                Amount
              </label>
              <input
                type="text"
                className="form-control"
                id="amount"
                name="amount"
                value={
                  formik.values && formik.values.amount
                    ? formik.values.amount
                    : ""
                }
                onChange={(e) =>
                  formik.setFieldValue(
                    "amount",
                    e.target.value.replace(/[^0-9+]/gi, "")
                  )
                }
                onBlur={formik.handleBlur}
              />
              <small className="text-danger">
                {formik.touched.amount && formik.errors && formik.errors.amount
                  ? formik.errors.amount
                  : ""}
              </small>
            </div> */}
            <div className="col-md-12 form-group">
              <div className="d-flex flex-row">
                <input
                  className="mr-2"
                  type="checkbox"
                  id="allow_signup"
                  name="allow_signup"
                  onChange={(e) =>
                    formik.setFieldValue("allow_signup", e.target.checked)
                  }
                  checked={formik.values.allow_signup}
                  onBlur={formik.handleBlur}
                  //   disabled={formik.values.screener_question_id}
                />
                <label className="col-form-label w-100">Allow Signup</label>
                <small className="text-danger"></small>
              </div>
            </div>
            <div className="col-md-12 form-group">
              <label
                htmlFor="license_start_ts"
                className="col-form-label w-100 mb-2"
              >
                License Start Date
              </label>
              <input
                type="date"
                className="form-control"
                id="license_start_ts"
                value={formik.values.license_start_ts}
                // min={moment().toISOString().split("T")[0]}
                // onChange={(e) => setStartDate(e.target.value)}
                onChange={formik.handleChange}
                disabled={initialValue.organization_license_id}
              />
              <small className="text-danger">
                {formik.touched.license_start_ts &&
                formik.errors &&
                formik.errors.license_start_ts
                  ? formik.errors.license_start_ts
                  : ""}
              </small>
            </div>
            <div className="col-md-12 form-group">
              <label
                htmlFor="license_expiry_ts"
                className="col-form-label w-100 mb-2"
              >
                License End Date
              </label>
              <input
                type="date"
                className="form-control"
                id="license_expiry_ts"
                value={formik.values.license_expiry_ts}
                // min={moment().toISOString().split("T")[0]}
                disabled={initialValue.organization_license_id}
                onChange={formik.handleChange}
              />
              <small className="text-danger">
                {formik.touched.license_expiry_ts &&
                formik.errors &&
                formik.errors.license_expiry_ts
                  ? formik.errors.license_expiry_ts
                  : ""}
              </small>
            </div>

            {/* <div className="col-md-12">
              <div className="row">
                <div className="col-md-6 form-group ">
                  <label htmlFor="cgst" className="col-form-label w-100 mb-2">
                    CGST
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="cgst"
                    name="cgst"
                    value={
                      formik.values && formik.values.cgst
                        ? formik.values.cgst
                        : ""
                    }
                    onChange={(e) =>
                      formik.setFieldValue(
                        "cgst",
                        e.target.value.replace(/[^0-9+]/gi, "")
                      )
                    }
                    onBlur={formik.handleBlur}
                  />
                  <small className="text-danger">
                    {formik.touched.cgst && formik.errors && formik.errors.cgst
                      ? formik.errors.cgst
                      : ""}
                  </small>
                </div>
                <div className="col-md-6 form-group ">
                  <label htmlFor="igst" className="col-form-label w-100 mb-2">
                    IGST
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="igst"
                    name="igst"
                    value={
                      formik.values && formik.values.igst
                        ? formik.values.igst
                        : ""
                    }
                    onChange={(e) =>
                      formik.setFieldValue(
                        "igst",
                        e.target.value.replace(/[^0-9+]/gi, "")
                      )
                    }
                    onBlur={formik.handleBlur}
                  />
                  <small className="text-danger">
                    {formik.touched.igst && formik.errors && formik.errors.igst
                      ? formik.errors.igst
                      : ""}
                  </small>
                </div>
                <div className="col-md-6 form-group ">
                  <label htmlFor="sgst" className="col-form-label w-100 mb-2">
                    SGST
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="sgst"
                    name="sgst"
                    value={
                      formik.values && formik.values.sgst
                        ? formik.values.sgst
                        : ""
                    }
                    onChange={(e) =>
                      formik.setFieldValue(
                        "sgst",
                        e.target.value.replace(/[^0-9+]/gi, "")
                      )
                    }
                    onBlur={formik.handleBlur}
                  />
                  <small className="text-danger">
                    {formik.touched.sgst && formik.errors && formik.errors.sgst
                      ? formik.errors.sgst
                      : ""}
                  </small>
                </div>
                <div className="col-md-6 form-group ">
                  <label htmlFor="vat" className="col-form-label w-100 mb-2">
                    VAT
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="vat"
                    name="vat"
                    value={
                      formik.values && formik.values.vat
                        ? formik.values.vat
                        : ""
                    }
                    onChange={(e) =>
                      formik.setFieldValue(
                        "vat",
                        e.target.value.replace(/[^0-9+]/gi, "")
                      )
                    }
                    onBlur={formik.handleBlur}
                  />
                  <small className="text-danger">
                    {formik.touched.vat && formik.errors && formik.errors.vat
                      ? formik.errors.vat
                      : ""}
                  </small>
                </div>
              </div>
            </div> */}
            {/* <div className="col-md-12 form-group">
              <label htmlFor="comment" className="col-form-label w-100 mb-2">
                Comments
              </label>
              <textarea
                rows="3"
                className="form-control"
                type="text"
                id="comment"
                name="comment"
                onChange={formik.handleChange}
                value={formik.values.comment}
                onBlur={formik.handleBlur}
              />
              <small className="text-danger">
                {formik.touched.comment &&
                formik.errors &&
                formik.errors.comment
                  ? formik.errors.comment
                  : ""}
              </small>
            </div> */}

            <div className="d-flex align-items-end justify-content-end col-md-12 mt-3">
              <button
                onClick={onClose}
                type="button"
                className="btn btn-danger btn-sm float-right btn-add-domain mr-2"
              >
                Close
              </button>
              <CustomButton
                type="button"
                className="btn btn-primary btn-outline-success btn-sm float-right btn-add-domain"
                disabled={
                  Object.keys(formik.errors).length !== 0 ||
                  !formik.isValid ||
                  formik.isSubmitting
                }
                onClick={
                  formik.isValid && Object.keys(formik.values).length !== 0
                    ? formik.handleSubmit
                    : handleError
                }
              >
                {"Update"}
              </CustomButton>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
}

const validation = Yup.object().shape({
  // month: Yup.string(),
  // organization_id: Yup.string().required("Select organization"),
  // transaction_type: Yup.string().required("Select transaction type"),
  // country_name: Yup.string().required("Enter country"),
  total_seat_count: Yup.string().required("Enter total seat count"),
  // amount: Yup.string().required("Enter amount"),
  license_duration_months: Yup.string().required("Enter license duration"),
  // cgst: Yup.string(),
  // igst: Yup.string(),
  // sgst: Yup.string(),
  // vat: Yup.string(),
});
