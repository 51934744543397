import { useEffect, useState } from "react";
import { CREATE_FAQ_CATEGORY_GQL,ACTIVE_FAQ_CATEGORY_GQL, } from "../../Apollo/AdminServices/MutationRequest";
import { GET_FAQ_CATEGORY_LIST_GQL } from "../../Apollo/AdminServices/QueryRequest";
import graphHandler from "../../Apollo/graphHandler";

function useFAQCategoryList(preFetch = true) {
  const [faqCategoryList, setFaqCategories] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    preFetch && getFaqCategoryList();
  }, [preFetch]);
  const getFaqCategoryList = async (req = {}) => {
    setLoading(true);
    try {
      const { data } = await graphHandler().invoke(
        GET_FAQ_CATEGORY_LIST_GQL,
        { ...req },
        1,
        false,
        true
      );
      if (
        data &&
        data.getFAQCategoryList &&
        data.getFAQCategoryList.faq_categories &&
        data.getFAQCategoryList.faq_categories.length > 0
      ) {
        // console.log("getFAQCategoryList Success", data.getFAQCategoryList);
        setFaqCategories([]);
        setFaqCategories(data.getFAQCategoryList.faq_categories);
        setLoading(false);
      } else {
        setFaqCategories([]);
        setLoading(false);
      }
    } catch (error) {
      console.log("getFAQCategoryList Error", error);
      setFaqCategories([]);
      setLoading(false);
    }
  };

  const addCategory = async (
    addReq = {},
    successCB = () => {},
    errorCB = () => {}
  ) => {
    setLoading(true);
    try {
      const { data } = await graphHandler().invoke(
        CREATE_FAQ_CATEGORY_GQL,
        { addReq },
        1,
        false,
        true
      );
      if (data && data.AddFAQCategory && data.AddFAQCategory.faq_category) {
        successCB({
          label: addReq.faq_category_name,
          value: addReq.faq_category_name,
        });
        getFaqCategoryList({});
        setLoading(false);
      } else {
        errorCB();
        setLoading(false);
      }
    } catch (error) {
      console.log("AddFAQCategory Error", error);
      errorCB(error);
      setLoading(false);
    }
  };
  const activateCategory = async (req = {}, callback = () => {}) => {
    setLoading(true);
    try {
      const { data } = await graphHandler().invoke(
        ACTIVE_FAQ_CATEGORY_GQL,
        req,
        1,
        false,
        true
      );
      //   console.log("activateCategory Success", data.activateFAQCategory);
      callback();
      setLoading(false);
    } catch (error) {
      console.log("activateCategory Error", error);
      setLoading(false);
    }
  };
  return {
    faqCategoryList,
    loading,
    getFaqCategoryList,
    addCategory,
    activateCategory
  };
}

export default useFAQCategoryList;
