import { useFormik } from "formik";
import { useState, useMemo, useEffect } from "react";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { UPDATE_PLAN_ACTION_GQL } from "../../../Apollo/AdminServices/NewMutationRequest";
import graphHandler from "../../../Apollo/graphHandler";
import CustomButton from "../../../components/Button/CustomButton";
import CheckInputField from "../../../components/HistoryEditInput/CheckInputField";
import DescriptionInputField from "../../../components/HistoryEditInput/DescriptionInputField";
import DocumentHistoryInput from "../../../components/HistoryEditInput/DocumentHistoryInput";
import ImageHistoryInput from "../../../components/HistoryEditInput/ImageHistoryInput";
import NewReactSelectInputField from "../../../components/HistoryEditInput/NewReactSelectInputField";
import SelectInputField from "../../../components/HistoryEditInput/SelectInputField";
import TextInputField from "../../../components/HistoryEditInput/TextInputField";
import VideoHistoryInput from "../../../components/HistoryEditInput/VideoHistoryInput";
import { PlanActionType } from "../../../constants";
import {
  mergeMultiSelectArray,
  objectHasKey,
  removeEmptyObjValue,
  secToFormat,
} from "../../../utils/Functions/filter";
import TimeInputField from "../../../components/HistoryEditInput/TimeInputField";
import moment from "moment";

export default function UpdatePlanAction({
  refresh = () => {},
  subDomainList = [],
  setLoading = () => {},
  planAction = {},
  reset = () => {},
  groupList = {},
  reportOption = [],
  mailOption = [],
  interventions = [],
  screeners = [],
  therapyOption = [],
  planOption = [],
}) {
  const [selectedSubDomainList, setSelectedSubDomainList] = useState([]);
  const pending_history = planAction.pending_history
    ? planAction.pending_history
    : {};
  let { plan_action_id, sub_domain_ids } = planAction;
  const hasUpdated = Boolean(pending_history && pending_history.updated_data);
  const updated_data = hasUpdated ? pending_history.updated_data : {};
  const formik = useFormik({
    initialValues: {},
    onSubmit: (values) => {
      onSubmit(values);
    },
  });
  // console.log(planAction, formik.values, formik.errors);

  const action_type = formik.values.plan_action_type
    ? formik.values.plan_action_type
    : planAction.plan_action_type;

  useEffect(() => {
    //Reset on edit
    formik.resetForm();
    formik.setValues({});
    formik.setSubmitting(false);
    if (plan_action_id) {
      let publish = hasUpdated ? updated_data : {};
      if (subDomainList&&subDomainList.length > 0) {
        let subArray = [],
          sub_domains =
            sub_domain_ids && sub_domain_ids.length > 0 ? sub_domain_ids : [],
          publishedSub =
            publish.sub_domain_ids &&
            publish.sub_domain_ids.update_items &&
            publish.sub_domain_ids.update_items.length > 0
              ? publish.sub_domain_ids.update_items
              : [];
        let value = mergeMultiSelectArray(sub_domains, publishedSub);
        let i = 0;
        subArray = value.map((x) => {
          let ret = {
            ...subDomainList.find((y) => y.value === x.data),
            ...x,
            index: i,
          };
          if (x.isRemoved) {
            ret.value =
              subDomainList.find((y) => y.value === x.data).label || "";
          } else {
            i++;
          }
          return ret;
        });
        setSelectedSubDomainList(subArray);
      }
    }
  }, [plan_action_id,subDomainList]);

  useMemo(() => {
    let values = { ...formik.values };
    delete values.intervention_id;
    delete values.screener_id;
    delete values.template_id;
    delete values.plan_action_image_urls;
    delete values.plan_action_document_urls;
    delete values.plan_action_video_urls;
    formik.setValues({ ...values });
  }, [formik.values.plan_action_type]);

  const onSubmit = async (formikValues) => {
    formik.setSubmitting(false);
    let {
      sub_domain_ids,
      plan_action_name,
      plan_action_code,
      plan_action_type,
      sort_order,
      min_completion_days,
      max_completion_days,
      start_interval_seconds,
      plan_action_descriptions,
      plan_action_image_urls,
      plan_action_video_urls,
      plan_action_document_urls,
      mandatory,
      action_time_seconds,
      intervention_id,
      screener_id,
      template_id,
      therapy_id,
      action_plan_id,
    } = formikValues;
    // updateValue={updated_data.min_completion_days}
    // actualValue={planAction.min_completion_days}

    if (
      Number(
        max_completion_days
          ? max_completion_days
          : updated_data.max_completion_days
          ? updated_data.max_completion_days
          : planAction.max_completion_days
      ) <= Number(min_completion_days 
          ? min_completion_days
          : updated_data.min_completion_days
          ? updated_data.min_completion_days
          : planAction.min_completion_days
        )
    ) {
      formik.setSubmitting(false);
      return toast.warn("Max days should be > min days");
    }
    let update_request = {
      plan_action_id,
    };

    if (plan_action_type === "Intervention" && !intervention_id)
      return toast.warn("Intervention is required field");
    if (plan_action_type === "Screener" && !screener_id)
      return toast.warn("Screener is required field");
    if (plan_action_type === "Therapy" && !therapy_id)
      return toast.warn("Therapy is required field");
    if (plan_action_type === "Plan" && !action_plan_id)
      return toast.warn("Plan is required field");
    if (
      (plan_action_type === "MailTemplate" || plan_action_type === "Report") &&
      !template_id
    )
      return toast.warn("Template is required field");
    if (objectHasKey(formikValues, "plan_action_name")) {
      update_request.plan_action_name = plan_action_name;
    }
    if (objectHasKey(formikValues, "plan_action_code")) {
      update_request.plan_action_code = plan_action_code;
    }
    if (objectHasKey(formikValues, "plan_action_type")) {
      update_request.plan_action_type = plan_action_type;
    }
    if (objectHasKey(formikValues, "sort_order")) {
      update_request.sort_order = Number(sort_order);
    }

    if (objectHasKey(formikValues, "min_completion_days")) {
      update_request.min_completion_days = Number(min_completion_days);
    }

    if (objectHasKey(formikValues, "max_completion_days")) {
      update_request.max_completion_days = Number(max_completion_days);
    }
    if (
      update_request &&
      update_request.min_completion_days &&
      update_request.max_completion_days &&
      Number(update_request.max_completion_days) <=
        Number(update_request.min_completion_days)
    ) {
      formik.setSubmitting(false);
      return toast.warn("Max days should be > min days");
    }
    if (objectHasKey(formikValues, "start_interval_seconds")) {
      update_request.start_interval_seconds =
        Number(start_interval_seconds) * 86400;
    }
    if (objectHasKey(formikValues, "action_time_seconds")) {
      update_request.action_time_seconds = moment(
        action_time_seconds,
        "HH:mm"
      ).diff(moment().startOf("day"), "seconds");
    }

    if (objectHasKey(formikValues, "sub_domain_ids")) {
      update_request.sub_domain_ids = { update_items: sub_domain_ids };
    }
    if (objectHasKey(formikValues, "mandatory")) {
      update_request.mandatory = mandatory;
    }
    if (objectHasKey(formikValues, "plan_action_descriptions")) {
      update_request.plan_action_descriptions = {
        update_items: plan_action_descriptions,
      };
    }
    if (
      action_type === "Screener" &&
      objectHasKey(formikValues, "screener_id")
    ) {
      update_request.screener_id = screener_id;
    } else if (
      action_type === "Therapy" &&
      objectHasKey(formikValues, "therapy_id")
    ) {
      update_request.therapy_id = therapy_id;
    } else if (
      action_type === "Plan" &&
      objectHasKey(formikValues, "action_plan_id")
    ) {
      update_request.action_plan_id = action_plan_id;
    } else if (
      action_type === "Intervention" &&
      objectHasKey(formikValues, "intervention_id")
    ) {
      update_request.intervention_id = intervention_id;
    } else if (
      (action_type === "MailTemplate" || action_type === "Report") &&
      objectHasKey(formikValues, "template_id")
    ) {
      update_request.template_id = template_id;
    } else if (
      action_type === "Video" &&
      objectHasKey(formikValues, "plan_action_video_urls")
    ) {
      update_request.plan_action_video_urls = {
        update_items: plan_action_video_urls.map((x) => removeEmptyObjValue(x)),
      };
    } else if (
      action_type === "Image" &&
      objectHasKey(formikValues, "plan_action_image_urls")
    ) {
      update_request.plan_action_image_urls = {
        update_items: plan_action_image_urls.map((x) => removeEmptyObjValue(x)),
      };
    } else if (
      action_type === "Document" &&
      objectHasKey(formikValues, "plan_action_document_urls")
    ) {
      update_request.plan_action_document_urls = {
        update_items: plan_action_document_urls.map((x) =>
          removeEmptyObjValue(x)
        ),
      };
    }
    formik.setSubmitting(true);
    try {
      await graphHandler().invoke(
        UPDATE_PLAN_ACTION_GQL,
        { update_request },
        1,
        false
      );
      toast.success("Updated Successfully");
      refresh();
      setLoading(false);
      formik.setSubmitting(false);
      resetModal();
    } catch (error) {
      console.log("updatePlanAction error", error);
      formik.setSubmitting(false);
    }
  };

  const handleError = () => {
    if (Object.keys(formik.errors).length > 0) {
      toast.error(formik.errors[Object.keys(formik.errors)[0]]);
    }
  };

  const resetModal = () => {
    formik.resetForm();
    formik.setValues({});
    formik.setSubmitting(false);
    reset();
  };

  return (
    <>
      <div className="col-md-12 modal-header" id="CreateTopic">
        <h5 className="modal-title text-center mb-3">Update Action</h5>
      </div>
      <div className="col-md-12">
        <div className="  border">
          <div className="row p-3">
            <div className="col-md-6">
              <div className="row">
                <NewReactSelectInputField
                  parentForm={formik}
                  id="sub_domain_ids"
                  title="Sub Domain"
                  placeholder="Select Sub Domain"
                  options={subDomainList}
                  isCreatable={false}
                  isMulti={true}
                  isPublished={planAction.published}
                  defaultValue={selectedSubDomainList}
                  updateValue={
                    updated_data.sub_domain_ids &&
                    updated_data.sub_domain_ids.update_items &&
                    updated_data.sub_domain_ids.update_items.length > 0
                      ? updated_data.sub_domain_ids.update_items
                      : false
                  }
                  validation={{
                    sub_domain_ids: Yup.array().of(
                      Yup.object().shape({
                        label: Yup.string(),
                        value: Yup.string(),
                      })
                    ),
                    // .test({
                    //   message: "Select sub domain",
                    //   test: (arr) => {
                    //     return arr && arr.length > 0;
                    //   },
                    // }),
                  }}
                />
                <SelectInputField
                  parentForm={formik}
                  className="col-md-12"
                  updateValue={updated_data.plan_group_id}
                  actualValue={planAction.plan_group_id}
                  id="plan_group_id"
                  title="Plan Group"
                  validation={{
                    plan_group_id: Yup.string().required("Select group"),
                  }}
                  options={[
                    { label: "Select", value: "", props: { hidden: true } },
                    ...groupList,
                  ]}
                />
                <div className="col-md-12">
                  <div className="row">
                    <TextInputField
                      parentForm={formik}
                      className="col-md-6"
                      updateValue={updated_data.plan_action_code}
                      actualValue={planAction.plan_action_code}
                      id="plan_action_code"
                      title="Code"
                      disabled={true}
                      validation={{
                        plan_action_code:
                          Yup.string().required("Enter action code"),
                      }}
                    />
                    <TextInputField
                      parentForm={formik}
                      className="col-md-6"
                      updateValue={updated_data.sort_order}
                      actualValue={planAction.sort_order}
                      id="sort_order"
                      title="Sort Order"
                      validation={{
                        sort_order: Yup.string().required("Enter sort order"),
                      }}
                      isNumber={true}
                      inputProps={{
                        maxLength: 10,
                      }}
                    />
                    <TextInputField
                      parentForm={formik}
                      className="col-md-6"
                      updateValue={
                        updated_data.start_interval_seconds
                          ? updated_data.start_interval_seconds / 86400
                          : ""
                      }
                      actualValue={planAction.start_interval_seconds / 86400}
                      id="start_interval_seconds"
                      title="Interval (in days)"
                      validation={{
                        start_interval_seconds: Yup.string(),
                        // .required("Enter start interval"),
                      }}
                      isNumber={true}
                      inputProps={{
                        maxLength: 10,
                      }}
                    />
                    <TimeInputField
                      parentForm={formik}
                      className="col-md-4"
                      updateValue={
                        updated_data.action_time_seconds
                          ? secToFormat(updated_data.action_time_seconds)
                          : ""
                      }
                      actualValue={
                        planAction.action_time_seconds
                          ? secToFormat(planAction.action_time_seconds)
                          : ""
                      }
                      id="action_time_seconds"
                      title="Action Time"
                      validation={{
                        action_time_seconds: Yup.string(),
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-12 form-group mb-0">
                  <label
                    htmlFor="min_completion_days"
                    className="col-form-label w-100 mb-2"
                  >
                    <b>Completion Days</b>
                  </label>
                  <div className="row">
                    <TextInputField
                      parentForm={formik}
                      className="col-md-6"
                      updateValue={updated_data.min_completion_days}
                      actualValue={planAction.min_completion_days??""}
                      id="min_completion_days"
                      title="Minimum"
                      validation={{
                        min_completion_days: Yup.string(),
                        // .required("Enter minimum days"),
                      }}
                      isNumber={true}
                      inputProps={{
                        maxLength: 10,
                      }}
                    />
                    <TextInputField
                      parentForm={formik}
                      className="col-md-6"
                      updateValue={updated_data.max_completion_days}
                      actualValue={planAction.max_completion_days??""}
                      id="max_completion_days"
                      title="Maximum"
                      validation={{
                        max_completion_days: Yup.string(),
                        // .required("Enter maximum days"),
                      }}
                      isNumber={true}
                      inputProps={{
                        maxLength: 10,
                      }}
                    />
                  </div>
                </div>
                <CheckInputField
                  parentForm={formik}
                  col="col-md-6"
                  className="col-md-6"
                  updateValue={updated_data.mandatory}
                  hasUpdated={typeof updated_data.mandatory === "boolean"}
                  actualValue={planAction.mandatory}
                  id="mandatory"
                  title="Mandatory"
                  validation={{
                    mandatory: Yup.boolean(),
                  }}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="row">
                <TextInputField
                  parentForm={formik}
                  className="col-md-12"
                  updateValue={updated_data.plan_action_name}
                  actualValue={planAction.plan_action_name}
                  id="plan_action_name"
                  title="Action Name"
                  validation={{
                    plan_action_name: Yup.string()
                      .trim()
                      .required("Enter action name"),
                  }}
                />
                <DescriptionInputField
                  parentForm={formik}
                  parentKey="plan_action_descriptions"
                  className="col-md-12"
                  updateValue={
                    updated_data.plan_action_descriptions &&
                    updated_data.plan_action_descriptions.update_items
                      ? updated_data.plan_action_descriptions.update_items
                      : false
                  }
                  actualValue={planAction.plan_action_descriptions}
                  validation={{
                    description: Yup.string()
                      .trim()
                      .required("Description is required"),
                    mobile_description: Yup.string()
                      .trim()
                      .required("Mobile description is required"),
                  }}
                  showEditor={true}
                  refreshId={plan_action_id}
                />
              </div>
            </div>
            <SelectInputField
              parentForm={formik}
              className="col-md-6"
              updateValue={updated_data.plan_action_type}
              actualValue={planAction.plan_action_type}
              id="plan_action_type"
              title="Action Type"
              disabled={planAction.plan_action_id}
              validation={{
                plan_action_type: Yup.string().required("Select type"),
              }}
              options={[
                { label: "Select", value: "", props: { hidden: true } },
                ...PlanActionType,
              ]}
            />
            {
              <RenderActionInput
                type={action_type}
                formik={formik}
                updated_data={updated_data}
                planAction={planAction}
                interventions={interventions}
                screeners={screeners}
                reportOption={reportOption}
                mailOption={mailOption}
                therapyOption={therapyOption}
                planOption={planOption}
              />
            }
            <div
              className={"d-flex align-items-end justify-content-end col-md-12"}
            >
              <button
                onClick={resetModal}
                type="button"
                className="btn btn-light btn-sm float-right btn-add-domain mr-2"
              >
                Reset
              </button>
              <CustomButton
                type="button"
                className="btn btn-primary btn-outline-success btn-sm float-right btn-add-domain"
                disabled={!formik.isValid || formik.isSubmitting}
                onClick={
                  formik.isValid && Object.keys(formik.values).length !== 0
                    ? formik.handleSubmit
                    : handleError
                }
              >
                Update
              </CustomButton>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const RenderActionInput = ({
  type,
  formik,
  updated_data,
  planAction,
  interventions = [],
  screeners = [],
  reportOption = [],
  mailOption = [],
  therapyOption = [],
  planOption = [],
}) => {
  const fieldName =
    type === "Intervention"
      ? "intervention_id"
      : type === "Screener"
      ? "screener_id"
      : type === "Therapy"
      ? "therapy_id"
      : type === "Plan"
      ? "action_plan_id"
      : "template_id";
  if (
    type === "Intervention" ||
    type === "Screener" ||
    type === "Report" ||
    type === "Therapy" ||
    type === "Plan" ||
    type === "MailTemplate"
  ) {
    return (
      <SelectInputField
        parentForm={formik}
        className="col-md-6"
        updateValue={updated_data[fieldName]}
        actualValue={planAction[fieldName] ? planAction[fieldName] : ""}
        id={fieldName}
        title={type}
        validation={{
          [fieldName]: Yup.string().required(`Select a ${type}`),
        }}
        oldOptions={[
          { label: "Select", value: "", props: { hidden: true } },
          ...(planAction.plan_action_type === "Intervention"
            ? interventions
            : planAction.plan_action_type === "Screener"
            ? screeners
            : planAction.plan_action_type === "Therapy"
            ? therapyOption
            : planAction.plan_action_type === "Plan"
            ? planOption
            : planAction.plan_action_type === "MailTemplate"
            ? mailOption
            : reportOption),
        ]}
        options={[
          { label: "Select", value: "", props: { hidden: true } },
          ...(type === "Intervention"
            ? interventions
            : type === "Screener"
            ? screeners
            : planAction.plan_action_type === "Therapy"
            ? therapyOption
            : planAction.plan_action_type === "Plan"
            ? planOption
            : type === "MailTemplate"
            ? mailOption
            : reportOption),
        ]}
      />
    );
  } else if (type === "Image") {
    return (
      <div className="col-md-12">
        <ImageHistoryInput
          parentForm={formik}
          contentId={planAction.plan_action_id}
          isPublished={planAction.published}
          contentType="PLANACTION"
          heading="Image File"
          parentArray="plan_action_image_urls"
          actualValue={planAction.plan_action_image_urls}
          updateValue={
            updated_data.plan_action_image_urls &&
            updated_data.plan_action_image_urls.update_items
              ? updated_data.plan_action_image_urls.update_items
              : false
          }
          validation={{
            plan_action_image_urls: Yup.array().of(
              Yup.object().shape({
                is_mobile: Yup.boolean().required("Select a Image device"),
                image_type: Yup.string().required("Select a Image format"),
                url: Yup.string(),
              })
            ),
          }}
        />
      </div>
    );
  } else if (type === "Video") {
    return (
      <div className="col-md-12">
        <VideoHistoryInput
          parentForm={formik}
          isPublished={planAction.published}
          contentId={planAction.plan_action_id}
          contentType="PLANACTION"
          heading="Video File"
          parentArray="plan_action_video_urls"
          actualValue={planAction.plan_action_video_urls}
          updateValue={
            updated_data.plan_action_video_urls &&
            updated_data.plan_action_video_urls.update_items
              ? updated_data.plan_action_video_urls.update_items
              : false
          }
          validation={{
            plan_action_video_urls: Yup.array().of(
              Yup.object().shape({
                is_mobile: Yup.boolean().required("Select a video device"),
                video_type: Yup.string().required("Select a video format"),
                video_url: Yup.string(),
              })
            ),
          }}
        />
      </div>
    );
  } else if (type === "Document") {
    return (
      <div className="col-md-12">
        <DocumentHistoryInput
          parentForm={formik}
          isPublished={planAction.published}
          contentId={planAction.plan_action_id}
          contentType="PLANACTION"
          parentArray="plan_action_document_urls"
          actualValue={planAction.plan_action_document_urls}
          updateValue={
            updated_data.plan_action_document_urls &&
            updated_data.plan_action_document_urls.update_items
              ? updated_data.plan_action_document_urls.update_items
              : false
          }
          validation={{
            plan_action_document_urls: Yup.array().of(
              Yup.object().shape({
                is_mobile: Yup.boolean().required("Select a video device"),
                document_type: Yup.string().required("Select a video format"),
                url: Yup.string(),
                name: Yup.string(),
              })
            ),
          }}
        />
      </div>
    );
  } else return null;
};
