import { useState } from "react";
import { GET_PLAN_ACTION_LIST_GQL } from "../../Apollo/AdminServices/NewQueryRequest";
import graphHandler from "../../Apollo/graphHandler";
import { sortArray } from "../Functions/filter";

function usePlanActionList() {
  const [actionList, setActionList] = useState([]);
  const [loading, setLoading] = useState(false);

  const getActionList = async (req = {}, get_pending_update = true) => {
    setLoading(true);
    try {
      const { data } = await graphHandler().invoke(
        GET_PLAN_ACTION_LIST_GQL,
        { get_pending_update, ...req },
        1,
        false,
        true
      );
      if (
        data &&
        data.getPlanActionList &&
        data.getPlanActionList.plan_actions &&
        data.getPlanActionList.plan_actions.length > 0
      ) {
        // console.log("getPlanActionList Success", data.getPlanActionList);
        const struct = data.getPlanActionList.plan_actions.map((x) => ({
          ...x.plan_action,
          pending_history: x.pending_plan_action_history,
        }));
        setActionList([]);
        setActionList(sortArray(struct));
        setLoading(false);
      } else {
        setActionList([]);
        setLoading(false);
      }
    } catch (error) {
      console.log("getPlanActionList Error", error);
      setActionList([]);
      setLoading(false);
    }
  };

  return { actionList, loading, getActionList };
}

export default usePlanActionList;
