import { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import {
  IsNullOrEmpty,
  mergeMultiSelectArray,
  objectHasKey,
  removeEmptyObjValue,
} from "../../../utils/Functions/filter";
import { UPDATE_THERAPY_GQL } from "../../../Apollo/AdminServices/MutationRequest";
import graphHandler from "../../../Apollo/graphHandler";
import CustomButton from "../../../components/Button/CustomButton";
import NewReactSelectInputField from "../../../components/HistoryEditInput/NewReactSelectInputField";
import TextInputField from "../../../components/HistoryEditInput/TextInputField";
import DescriptionInputField from "../../../components/HistoryEditInput/DescriptionInputField";
import MetaHistoryInput from "../../../components/HistoryEditInput/MetaHistoryInput";
import DocumentHistoryInput from "../../../components/HistoryEditInput/DocumentHistoryInput";
import ImageHistoryInput from "../../../components/HistoryEditInput/ImageHistoryInput";
import VideoHistoryInput from "../../../components/HistoryEditInput/VideoHistoryInput";
import { Popconfirm } from "antd";

export default function UpdateTherapy({
  refresh = () => {},
  values = {},
  isEditable = false,
  onDone = () => {},
  onClose = () => {},
  subDomainList = [],
  setTherapy = () => {},
  setLoading = () => {},
  handleDiscord = ()=>{},
  isFirst,
}) {
  const [selectedList, setSelectedList] = useState([]);
  const [isEdit, setEdit] = useState(isEditable);
  const therapy_history = values.pending_therapy_history
    ? values.pending_therapy_history
    : {};
  const hasUpdated = Boolean(therapy_history && therapy_history.updated_data);
  const updated_data = hasUpdated ? therapy_history.updated_data : {};
  const formik = useFormik({
    initialValues: {},
    onSubmit: (values) => {
      onSubmit(values);
    },
  });
  // console.log(values, formik.values, updated_data,subDomainList);

  useEffect(() => {
    if (values?.therapy_id) {
      onSetDefault(values, hasUpdated ? updated_data : {});
      setEdit(isFirst);
    } else {
      setEdit(!isFirst);
    }
  }, [values, subDomainList]);

  const onSetDefault = (data = {}, publish = {}) => {
    const { sub_domain_ids } = data;
    if (subDomainList.length > 0) {
      let subArray = [],
        sub_domains =
          sub_domain_ids && sub_domain_ids.length > 0 ? sub_domain_ids : [],
        publishedSub =
          publish.sub_domain_ids &&
          publish.sub_domain_ids.update_items &&
          publish.sub_domain_ids.update_items.length > 0
            ? publish.sub_domain_ids.update_items
            : [];
      let value = mergeMultiSelectArray(sub_domains, publishedSub);
      let i = 0;
      subArray = value.map((x) => {
        let ret = {
          ...subDomainList.find((y) => y.value === x.data),
          ...x,
          index: i,
        };
        if (x.isRemoved) {
          ret.value = subDomainList.find((y) => y.value === x.data).label || "";
        } else {
          i++;
        }
        return ret;
      });
      setSelectedList(subArray);
    }
  };

  // console.log("values",values);
  const onSubmit = async (value) => {
    formik.setSubmitting(true);
    setLoading(true);
    const {
      sub_domain_ids,
      therapy_name,
      therapy_image_urls,
      therapy_video_urls,
      therapy_document_urls,
      therapy_description,
      screener_result_range_min,
      screener_result_range_max,
      time_per_module,
      total_duration,
      days_per_week,
      therapy_meta,
      alias_name,
    } = value;
    // console.log("values",value)
    if (Number(screener_result_range_min) > Number(screener_result_range_max))
      return toast.error("Result Max should be greater than Min");
    const update_request = {
      therapy_id: values.therapy_id,
    };
    if (objectHasKey(value, "sub_domain_ids")) {
      update_request.sub_domain_ids = { update_items: sub_domain_ids };
    }
    if (objectHasKey(value, "total_duration")) {
      update_request.total_duration = Number(total_duration);
    }
    if (objectHasKey(value, "days_per_week")) {
      update_request.days_per_week = Number(days_per_week);
    }
    if (objectHasKey(value, "time_per_module")) {
      update_request.time_per_module = Number(time_per_module) * 60;
    }
    if (objectHasKey(value, "therapy_name")) {
      update_request.therapy_name = therapy_name;
    }
    if (objectHasKey(value, "alias_name")) {
      update_request.alias_name = alias_name;
    }
    if (objectHasKey(value, "screener_result_range_min")) {
      update_request.screener_result_range_min = Number(
        screener_result_range_min
      );
    }
    if (objectHasKey(value, "screener_result_range_max")) {
      update_request.screener_result_range_max = Number(
        screener_result_range_max
      );
    }
    if (objectHasKey(value, "therapy_description")) {
      update_request.therapy_description = {
        update_items: therapy_description,
      };
    }
    if (objectHasKey(value, "therapy_meta")) {
      update_request.therapy_meta = {
        update_items: therapy_meta.map((x) => removeEmptyObjValue(x)),
      };
    }
    if (objectHasKey(value, "therapy_image_urls")) {
      update_request.therapy_image_urls = {
        update_items: therapy_image_urls.map((x) => removeEmptyObjValue(x)),
      };
    }
    if (objectHasKey(value, "therapy_video_urls")) {
      update_request.therapy_video_urls = {
        update_items: therapy_video_urls.map((x) => removeEmptyObjValue(x)),
      };
    }
    if (objectHasKey(value, "therapy_document_urls")) {
      update_request.therapy_document_urls = {
        update_items: therapy_document_urls.map((x) => removeEmptyObjValue(x)),
      };
    }
    if (Object.keys(update_request).length === 1) {
      refresh();
      formik.setSubmitting(false);
      return;
    }
    try {
      // console.log("update_request", update_request);
      // setLoading(false);
      // formik.setSubmitting(false)
      // return
      const { data } = await graphHandler().invoke(
        UPDATE_THERAPY_GQL,
        { update_request },
        1,
        true
      );
      if (data && data.updateTherapy && data.updateTherapy.therapy) {
        setTherapy({
          ...data.updateTherapy.therapy,
          pending_therapy_history: data.updateTherapy.therapy_history,
        });
      }
      onDone();
      refresh();
      setLoading(false);
      formik.setSubmitting(false);
    } catch (error) {
      console.log("updateTherapy error", error);
      setLoading(false);
      formik.setSubmitting(false);
    }
  };
  const handleError = () => {
    if (Object.keys(formik.errors).length > 0) {
      toast.error(formik.errors[Object.keys(formik.errors)[0]]);
    }
  };

  return (
    <>
      <div className="container">
        <div className="intervention-form row position-relative pt-5">
          {isEditable && (
            <button
              type="button"
              className="btn btn-link position-absolute"
              style={{ top: "0px", right: "0px" }}
              onClick={() => setEdit((x) => !x)}
            >
              {isEdit ? (
                <>
                  <i className="fas fa-times"></i> Cancel
                </>
              ) : (
                <>
                  <i className="fas fa-pen"></i> Edit
                </>
              )}
            </button>
          )}
          <div className="col-md-6">
            <div className="row">
              <NewReactSelectInputField
                parentForm={formik}
                id="sub_domain_ids"
                className="col-md-12"
                title="Sub Domain"
                placeholder="Select Sub Domain"
                options={subDomainList}
                isCreatable={false}
                isMulti={true}
                disabled={!isEdit}
                defaultValue={selectedList}
                isPublished={values.published}
                updateValue={
                  updated_data.sub_domain_ids &&
                  updated_data.sub_domain_ids.update_items &&
                  updated_data.sub_domain_ids.update_items.length > 0
                    ? updated_data.sub_domain_ids.update_items
                    : false
                }
                validation={{
                  sub_domain_ids: Yup.array()
                    .of(
                      Yup.object().shape({
                        label: Yup.string(),
                        value: Yup.string(),
                      })
                    )
                    .test({
                      message: "Select a sub domain",
                      test: (arr) => {
                        return arr && arr.length > 0;
                      },
                    }),
                }}
              />
              <TextInputField
                parentForm={formik}
                className="col-md-12"
                updateValue={updated_data.therapy_code}
                actualValue={values.therapy_code}
                id="therapy_code"
                title="Code"
                disabled={values.therapy_id}
                validation={{
                  therapy_code: Yup.string().trim().required("Enter a code"),
                }}
              />
              <TextInputField
                parentForm={formik}
                className="col-md-12"
                id="total_duration"
                title="Total Duration (in Weeks)"
                updateValue={
                  !IsNullOrEmpty(updated_data.total_duration)
                    ? updated_data.total_duration
                    : ""
                }
                actualValue={
                  !IsNullOrEmpty(values.total_duration)
                    ? values.total_duration
                    : ""
                }
                validation={{
                  total_duration: Yup.string()
                    .trim()
                    .required("Enter total duration"),
                }}
                isNumber={true}
                disabled={!isEdit}
              />
              <div className="col-md-12">
                <div className="row">
                  <TextInputField
                    parentForm={formik}
                    className="col-md-6"
                    updateValue={
                      !IsNullOrEmpty(updated_data.time_per_module)
                        ? updated_data.time_per_module / 60
                        : ""
                    }
                    actualValue={
                      !IsNullOrEmpty(values.time_per_module)
                        ? values.time_per_module / 60
                        : ""
                    }
                    disabled={!isEdit}
                    id="time_per_module"
                    title="Time Per Module (in Mins)"
                    validation={{
                      time_per_module: Yup.string()
                        .trim()
                        .required("Enter time per module"),
                    }}
                    isNumber={true}
                    inputProps={{
                      maxLength: 10,
                    }}
                  />
                  <TextInputField
                    parentForm={formik}
                    className="col-md-6"
                    updateValue={
                      !IsNullOrEmpty(updated_data.days_per_week)
                        ? updated_data.days_per_week
                        : ""
                    }
                    actualValue={
                      !IsNullOrEmpty(values.days_per_week)
                        ? values.days_per_week
                        : ""
                    }
                    id="days_per_week"
                    title="Days Per Week"
                    validation={{
                      days_per_week: Yup.string()
                        .trim()
                        .required("Enter days per week"),
                    }}
                    disabled={!isEdit}
                    isNumber={true}
                    inputProps={{
                      maxLength: 10,
                    }}
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div className="row">
                  <TextInputField
                    parentForm={formik}
                    className="col-md-6"
                    updateValue={
                      !IsNullOrEmpty(updated_data.screener_result_range_min)
                        ? updated_data.screener_result_range_min
                        : ""
                    }
                    actualValue={
                      !IsNullOrEmpty(values.screener_result_range_min)
                        ? values.screener_result_range_min
                        : ""
                    }
                    id="screener_result_range_min"
                    title="Result Range Min"
                    validation={{
                      screener_result_range_min: Yup.string()
                        .trim()
                        .required("Enter min result mark"),
                    }}
                    disabled={!isEdit}
                    isNumber={true}
                    inputProps={{
                      maxLength: 10,
                    }}
                  />
                  <TextInputField
                    parentForm={formik}
                    className="col-md-6"
                    disabled={!isEdit}
                    updateValue={
                      !IsNullOrEmpty(updated_data.screener_result_range_max)
                        ? updated_data.screener_result_range_max
                        : ""
                    }
                    actualValue={
                      !IsNullOrEmpty(values.screener_result_range_max)
                        ? values.screener_result_range_max
                        : ""
                    }
                    id="screener_result_range_max"
                    title="Result Range Max"
                    validation={{
                      screener_result_range_max: Yup.string()
                        .trim()
                        .required("Enter max result mark"),
                    }}
                    isNumber={true}
                    inputProps={{
                      maxLength: 10,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="row">
              <TextInputField
                parentForm={formik}
                className="col-md-12"
                updateValue={updated_data.therapy_name}
                actualValue={values.therapy_name}
                id="therapy_name"
                title="Therapy Name"
                customBlur={true}
                disabled={!isEdit}
                validation={{
                  therapy_name: Yup.string().trim().required("Enter a name"),
                }}
              />
              <TextInputField
                parentForm={formik}
                className="col-md-12"
                updateValue={updated_data.alias_name ?? ""}
                actualValue={values.alias_name ?? ""}
                id="alias_name"
                title="Alias Name"
                disabled={!isEdit}
                customBlur={true}
                validation={{
                  alias_name: Yup.string().trim(),
                }}
              />
              <DescriptionInputField
                disabled={!isEdit}
                parentForm={formik}
                parentKey="therapy_description"
                className="col-md-12"
                updateValue={
                  updated_data.therapy_description &&
                  updated_data.therapy_description.update_items
                    ? updated_data.therapy_description.update_items
                    : false
                }
                // webName="therapy_description"
                actualValue={values.therapy_description}
                validation={{
                  description: Yup.string()
                    .trim()
                    .required("Enter a description"),
                  mobile_description: Yup.string()
                    .trim()
                    .required("Enter a mobile description"),
                }}
                showEditor={true}
              />
            </div>
          </div>

          <div className="col-md-12">
            {/* <RelatedContentHistoryInput
                parentForm={formik}
                parentArray="related_contents"
                updateValue={
                  updated_data.related_contents &&
                  updated_data.related_contents.update_items
                    ? updated_data.related_contents.update_items
                    : false
                }
                validation={{
                  related_contents: Yup.array().of(
                    Yup.object().shape({
                      content_type: Yup.string()
                        .trim()
                        .required("Content type is mandatory"),
                    })
                  ),
                }}
                disabled={!isEdit}
                actualValue={values.related_contents}
              /> */}
            <MetaHistoryInput
              disabled={!isEdit}
              parentForm={formik}
              parentArray="therapy_meta"
              typeKey="type"
              titleKey="key"
              valueKey="value"
              updateValue={
                updated_data.therapy_meta &&
                updated_data.therapy_meta.update_items
                  ? updated_data.therapy_meta.update_items
                  : false
              }
              isPublished={values.published}
              validation={{
                therapy_meta: Yup.array().of(
                  Yup.object().shape({
                    type: Yup.string().trim().required("Select a data type"),
                    key: Yup.string().trim().required("Enter a title"),
                    value: Yup.string().trim().required("Enter a value"),
                  })
                ),
              }}
              actualValue={values.therapy_meta}
            />
            <ImageHistoryInput
              disabled={!isEdit}
              parentForm={formik}
              contentId={values.therapy_id}
              contentType="THERAPY"
              heading="Image File"
              parentArray="therapy_image_urls"
              actualValue={values.therapy_image_urls}
              updateValue={
                updated_data.therapy_image_urls &&
                updated_data.therapy_image_urls.update_items
                  ? updated_data.therapy_image_urls.update_items
                  : false
              }
              isPublished={values.published}
              validation={{
                therapy_image_urls: Yup.array().of(
                  Yup.object().shape({
                    is_mobile: Yup.boolean().required("Select a Image device"),
                    image_type: Yup.string().required("Select a Image format"),
                    url: Yup.string(),
                  })
                ),
              }}
            />
            <VideoHistoryInput
              disabled={!isEdit}
              parentForm={formik}
              contentId={values.therapy_id}
              contentType="THERAPY"
              parentArray="therapy_video_urls"
              actualValue={values.therapy_video_urls}
              isPublished={values.published}
              updateValue={
                updated_data.therapy_video_urls &&
                updated_data.therapy_video_urls.update_items
                  ? updated_data.therapy_video_urls.update_items
                  : false
              }
              validation={{
                therapy_video_urls: Yup.array().of(
                  Yup.object().shape({
                    is_mobile: Yup.boolean().required("Select a video device"),
                    video_type: Yup.string().required("Select a video format"),
                    video_url: Yup.string(),
                  })
                ),
              }}
            />
            <DocumentHistoryInput
              disabled={!isEdit}
              isPublished={values.published}
              parentForm={formik}
              contentId={values.therapy_id}
              contentType="THERAPY"
              parentArray="therapy_document_urls"
              actualValue={values.therapy_document_urls}
              updateValue={
                updated_data.therapy_document_urls &&
                updated_data.therapy_document_urls.update_items
                  ? updated_data.therapy_document_urls.update_items
                  : false
              }
              validation={{
                therapy_document_urls: Yup.array().of(
                  Yup.object().shape({
                    is_mobile: Yup.boolean().required("Select a video device"),
                    document_type: Yup.string().required(
                      "Select a video format"
                    ),
                    url: Yup.string(),
                    name: Yup.string(),
                  })
                ),
              }}
            />
          </div>
          <div className="d-flex align-items-end justify-content-end col-md-12 mt-3">
          {isEditable && isEdit && hasUpdated && (
              <Popconfirm
              title="Are you sure to discard and close?"
              onConfirm={handleDiscord}
              //  onCancel={cancel}
              okText="Yes"
              cancelText="No"
            >
              <button
               type="button"
                className="btn btn-danger btn-sm float-right btn-add-domain mr-2"
              >
                Discard All & Close
              </button>
            </Popconfirm>
            )}
            <button
              onClick={onClose}
              type="button"
              className="btn btn-danger btn-sm float-right btn-add-domain mr-2"
            >
              Close
            </button>
            {isEdit ? (
              <CustomButton
                type="button"
                className="btn btn-primary btn-outline-success btn-sm float-right btn-add-domain"
                disabled={
                  Object.keys(formik.errors).length !== 0 ||
                  !formik.isValid ||
                  formik.isSubmitting
                }
                onClick={
                  formik.isValid && Object.keys(formik.values).length !== 0
                    ? formik.handleSubmit
                    : handleError
                }
              >
                Update
              </CustomButton>
            ) : (
              <button
                onClick={onDone}
                type="button"
                className="btn btn-secondary btn-sm float-right btn-add-domain mr-2"
              >
                Next
              </button>
            )}
          </div>
        </div>
        {/* {therapy.therapy_id && !isEdit && (
            <>
              <div className="modal-header border-top">
                <h5 className="modal-title" id="exampleModalLabel">
                  Modules
                </h5>
              </div>
              <CreateModule therapy={therapy} />
            </>
          )} */}
      </div>
      {/* </ModalBody>
    </Modal> */}
    </>
  );
}
