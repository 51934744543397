import { useEffect, useState } from "react";
import { GET_MODULE_LIST_GQL } from "../../Apollo/AdminServices/QueryRequest";
import graphHandler from "../../Apollo/graphHandler";
import { sortArray } from "../Functions/filter";
function useModuleList(id) {
  const [moduleList, setModules] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (id) {
      getModuleList(id);
    } else {
      setModules([]);
    }
  }, [id]);
  const getModuleList = async (req = {}) => {
    setLoading(true);
    try {
      const { data } = await graphHandler().invoke(
        GET_MODULE_LIST_GQL,
        req,
        1,
        false,
        true
      );
      if (
        data &&
        data.getTherapyModuleList &&
        data.getTherapyModuleList.therapy_modules &&
        data.getTherapyModuleList.therapy_modules.length > 0
      ) {
        const struct = data.getTherapyModuleList.therapy_modules.map((x) => ({
          ...x.therapy_module,
          pending_history: x.pending_therapy_module_history,
        }));
        // console.log(
        //   "getModuleList Success",
        //   data.getTherapyModuleList.therapy_modules
        // );
        setModules([]);
        setModules(sortArray(struct));
        setLoading(false);
      } else {
        setModules([]);
        setLoading(false);
      }
    } catch (error) {
      console.log("getModuleList Error", error);
      setModules([]);
      setLoading(false);
    }
  };
  return {
    moduleList,
    loading,
    getModuleList,
    resetQuestions: () => setModules([]),
  };
}
export default useModuleList;
