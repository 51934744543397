import { useFormik } from "formik";
import { useEffect } from "react";
import * as Yup from "yup";
import { IsNullOrEmpty } from "../../utils/Functions/filter";
import usePrevious from "../../utils/Functions/usePrevious";

export default function CheckInputField({
  parentForm = {},
  className = "col-md-12",
  col = "col-md-12",
  updateValue = "",
  actualValue = "",
  id = "",
  title = "",
  disabled = false,
  hasUpdated = "",
  validation = {},
  inputProps = {},
}) {
  const formik = useFormik({
    initialValues: {},
    validationSchema: Yup.object().shape(validation),
    onSubmit: () => {},
  });
  const previous = usePrevious(formik.errors[id]);
  //   console.log(
  //     "Formik",
  //     id,
  //     formik.values,
  //     formik.errors,
  //     {
  //       actualValue,
  //       updateValue,
  //       errors: parentForm.errors,
  //       values: parentForm.values,
  //     },
  //     updateValue ? true : false,
  //     formik.errors[id]
  //   );
  useEffect(() => {
    if (formik.errors[id]) {
      parentForm.setFieldError(id, formik.errors[id] ? formik.errors[id] : "");
    } else if (previous !== formik.errors[id]) {
      let errors = { ...parentForm.errors };
      delete errors[id];
      parentForm.setErrors(errors);
    }
  }, [formik.errors]);

  useEffect(() => {
    formik.setFieldValue(
      id,
      !IsNullOrEmpty(updateValue) ? updateValue : actualValue
    );
  }, [updateValue, actualValue]);

  const onChange = (e) => {
    let { name, checked } = e.target;
    formik.setFieldValue(name, checked);
    parentForm && parentForm.setFieldValue(name, checked);
  };

  return (
    <div className={className}>
      <div className="row">
        {hasUpdated && (
          <div className={`form-group ${col}`}>
            <div className="d-flex flex-row">
              <input
                className="mr-2"
                type="checkbox"
                checked={actualValue}
                disabled
              />
              <label 
              // htmlFor={id} 
              className="col-form-label w-100 mb-0">
                {title}
                <span className="badge badge-good float-right">Existing</span>
              </label>
            </div>
          </div>
        )}

        <div className={`form-group ${col}`}>
          <div className="d-flex flex-row">
            <input
              className="mr-2"
              type="checkbox"
              id={id}
              name={id}
              checked={formik.values[id] ? formik.values[id] : ""}
              onChange={onChange}
              onBlur={formik.handleBlur}
              disabled={disabled}
              {...inputProps}
            />
            <label 
            // htmlFor={id} 
            className="col-form-label w-100 mb-0">
              {title}
              {hasUpdated && (
                <span className="badge badge-error float-right">New</span>
              )}
            </label>
          </div>
          <small className="text-danger">
            {formik.errors[id] ? formik.errors[id] : ""}
          </small>
        </div>
      </div>
    </div>
  );
}
