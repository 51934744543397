import { useEffect, useState } from "react";
import useUserAdminRoles from "../../../utils/CustomHooks/useUserAdminRoles";
import UserRoleItem from "./UserRoleItem";

export default function UserRole({ user_id = "" }) {
  const { roles, getAllAdminRoles } = useUserAdminRoles("");
  const [editedRole, setEditedRoles] = useState([]);
  const [edit] = useState(false);
  useEffect(() => {
    if (user_id) {
      getAllAdminRoles(user_id);
    }
  }, [user_id]);

  useEffect(() => {
    setEditedRoles(roles);
  }, [roles]);

  return (
    <div className="form-group d-flex flex-column">
      <p className="modal-sub-title font-weight-bold text-uppercase">Roles</p>
      {editedRole.map((x, i) => (
        <UserRoleItem
          edit={edit}
          user_id={user_id}
          key={i}
          value={x}
          isLastItem={i === editedRole}
          roles={editedRole}
        />
      ))}
    </div>
  );
}
