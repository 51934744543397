import { useEffect, useState } from "react";
import PaginationComponent from "../../components/PaginationComponent";
import useBlogList from "../../utils/CustomHooks/useBlogList";
import BlogItem from "./components/BlogItem";
// import AddBlogModal from "./modal/AddBlogModal";
import "./blog.css";
import withRoleBased from "../../utils/Functions/withRoleBased";
import { sortArray } from "../../utils/Functions/filter";
import BlogModal from "./modal/BlogModal";

function Index({ access = {} }) {
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [count, setCount] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const { blogList, loading, getBlogList } = useBlogList("");
  const pageCount = Math.ceil(blogList.length / pageSize);
  const [search, setSearch] = useState("");
  const [isSearching, setIsSearch] = useState(false);

  useEffect(() => {
    let req = {};
    if (search) req.blog_name = search;
    getBlogList(req);
  }, [count]);

  const handleClick = (event, index) => {
    event.preventDefault();
    setCurrentPage(index);
  };

  const onSearch = () => {
    setCurrentPage(0)
    setCount((x) => x + 1);
    setIsSearch(search ? true : false);
  };
  return (
    <main className="page-content">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6 mb-3">
            <h5 className="page-title">Blogs</h5>
          </div>

          <div className="col-md-6  mb-3 d-flex justify-content-end">
            {access.canCreate && (
              <button
                type="button"
                className="btn btn-outline-success "
                onClick={() => setIsOpen(true)}
              >
                Create Blog
              </button>
            )}
          </div>

          <div className="col-md-4">
            <div className="search" style={{ margin: "16px 0px 32px" }}>
              <input
                type="text"
                className="form-control"
                placeholder="Search"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                onKeyDown={(e) => e.key === "Enter" && onSearch()}
              />
              {!isSearching ? (
                <button onClick={onSearch}>
                  <i className="fa fa-search" />
                </button>
              ) : (
                <button
                  onClick={() => {
                    setSearch("");
                    setIsSearch(false);
                    setCount((x) => x + 1);
                  }}
                >
                  <i className="fa fa-times" />
                </button>
              )}
            </div>
          </div>
          <div className="col-md-12">
            <div className="table-responsive">
              <table className="table domain-table table-borderless">
                <thead>
                  <tr className="">
                    {access.isAdmin && <td className=""></td>}
                    <td className="">Name</td>
                    <td className="">Description </td>
                    {/* <td className="">Content</td> */}
                    <td className="">Sub Domain</td>
                    <td className="">Status</td>
                    <td className="">Action</td>
                  </tr>
                </thead>
                <tbody>
                  {blogList && blogList.length > 0 && !loading ? (
                    sortArray(blogList)
                      .slice(
                        currentPage * pageSize,
                        (currentPage + 1) * pageSize
                      )
                      .map((item, i) => (
                        <BlogItem
                          item={item}
                          i={i}
                          key={i}
                          index={i}
                          refresh={() => {
                            setCount((x) => x + 1);
                          }}
                          access={access}
                        />
                      ))
                  ) : (
                    <tr>
                      <td colSpan="6" className="text-center">
                        <span className="font-weight-bold">
                          {loading ? "Loading..." : "Oops! No Data Found"}
                        </span>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {blogList.length > 0 && pageCount != 1 && (
          <PaginationComponent
            handleClick={handleClick}
            currentPage={currentPage}
            pageCount={pageCount}
          />
        )}
        <BlogModal
          isOpen={isOpen}
          setOpen={setIsOpen}
          refresh={() => {
            setCount((x) => x + 1);
          }}
        />
      </div>
    </main>
  );
}

export default withRoleBased(Index, "blog");
