import { useState } from "react";
import { Link } from "react-router-dom";
// import { Popover } from "reactstrap";
// import { toast } from "react-toastify";
import { getDescription } from "../../../utils/Functions/filter";
// import { ACTIVE_STORY_GQL } from "../../../Apollo/AdminServices/MutationRequest";
// import graphHandler from "../../../Apollo/graphHandler";
import ReadMore from "../../../components/ReadMore";
import Explainer from "../modal/Explainer";

export default function ExplainerListItem({
  item,
  refresh,
  index,
  access = {},
}) {
  const [isEdit, setEdit] = useState(false);
  //   const [showPop, setShowPop] = useState(false);
  //   const toggle = () => setShowPop((x) => !x);
  const { canEdit } = access;
  //   const activateItem = async (active) => {
  //     if (!item.story_id) return;
  //     toggle();
  //     try {
  //       const bae = await graphHandler().invoke(
  //         ACTIVE_STORY_GQL,
  //         { active_request: { active, story_id: item.story_id } },
  //         1,
  //         false,
  //         true
  //       );
  //       toast.success(
  //         `${item.explainer_name} ${
  //           active ? "Activated" : "Deactivated"
  //         } Successfully`
  //       );
  //       refresh();
  //     } catch (error) {
  //       console.log("activateSubDomain error", error);
  //     }
  //   };
  return (
    <tr>
      <td className="col-sm-2">{item.explainer_name}</td>
      <td>
        <ReadMore>
          {item.explainer_description &&
          getDescription(item.explainer_description, "Web")
            ? getDescription(item.explainer_description, "Web").description
            : ""}
        </ReadMore>
      </td>
      <td>
        <span
          className={`${item.active ? "good" : "bad"} text-center btn btn-sm`}
        >
          {item.active ? "Active" : "Disabled"}
        </span>
      </td>
      {canEdit && (
        <td>
          <button
            type="button"
            className="btn btn-outline-success btn-sm "
            onClick={() => setEdit(true)}
          >
            Edit
          </button>
        </td>
      )}
      {/* <td>{item.active ? "Activated" : "Disabled"} </td> */}
      {/* <td className="accordion-menu" id={`storyData${index}`} onClick={toggle}>
        <i className="fas fa-ellipsis-v" />
      </td>
      <Popover
        isOpen={showPop}
        toggle={toggle}
        placement="left"
        target={`storyData${index}`}
        trigger="legacy"
      >
        <div className="accordion-menu-header">Options</div>

        <div
          className="accordion-menu-item"
          onClick={() => activateItem(!item.active)}
        >
          {!item.active ? "Activate" : "Deactivate"}
        </div>
      </Popover> */}
      <Explainer
        isOpen={isEdit}
        setOpen={() => setEdit(false)}
        initialValue={item}
        refresh={refresh}
      />
    </tr>
  );
}
