import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Popover } from "reactstrap";
import { Switch } from "antd";
import ApproveModal from "../../../components/Modal/ApproveModal";
import JsonCompareModal from "../../../components/Modal/JsonCompareModal";
import useTherapyAPI from "../../../utils/CustomHooks/useTherapyAPI";
import useUserInfo from "../../../utils/CustomHooks/useUserInfo";
import { formatDate } from "../../../utils/Functions/filter";
import UpdateTherapy from "../modal/UpdateTherapy";
import useForcePublish from "../../../utils/CustomHooks/useForcePublish";
import TherapyModal from "../modal/TherapyModal";

export default function TherapyListItem({
  item = {},
  index,
  refresh = () => {},
  access = {},
}) {
  const [isEdit, setEdit] = useState(false);
  const [isView, setView] = useState(false);
  const [showPop, setShowPop] = useState(false);
  const toggle = () => setShowPop((x) => !x);
  const [isOpenApprove, setOpenApprove] = useState(false);
  const [isApprove, setApprove] = useState(true);
  const { approveTherapy, reviewTherapy, activateTherapy } = useTherapyAPI("");
  const { pending_therapy_history: therapyHistory } = item;
  const { userInfo, getUser } = useUserInfo("");
  const { userInfo: updatedInfo, getUser: getUser2 } = useUserInfo("");
  const { forcePublishTherapy } = useForcePublish();
  const isDone = true;
  useEffect(() => {
    if (item.created_by) getUser(item.created_by);
    if (item.updated_by) getUser2(item.updated_by);
  }, [item]);
  const subDomains = useSelector(({ DataSet }) => DataSet.subDomains);
  const hasHistory = therapyHistory && therapyHistory.update_status;
  const { canEdit, canApprove, canReview, isAdmin } = access;
  const readyApprove =
    therapyHistory &&
    ((therapyHistory.update_status == "ReviewSuccess" && canApprove) ||
      (therapyHistory.update_status == "Draft" && canReview));

  const renderStatus = (status) => {
    if (
      hasHistory &&
      status !== "Rejected" &&
      status !== "Draft" &&
      status !== "Reviewfailed"
    ) {
      return <span className="good text-center btn btn-sm">{status}</span>;
    } else if (!hasHistory && item.active && item.published) {
      return <span className="good text-center btn btn-sm">Published</span>;
    } else if (!hasHistory && !item.active && item.published) {
      return <span className="bad text-center btn btn-sm">Disabled</span>;
    } else {
      return (
        <span className="bad text-center btn btn-sm">
          {status || "Rejected"}
        </span>
      );
    }
  };

  const activateItem = async (active) => {
    if (!item.therapy_id) return;
    toggle();
    const request = {
      active_request: {
        active,
        therapy_id: item.therapy_id,
      },
    };
    activateTherapy(request, onActivate(active));
  };

  const onActivate = (active) => {
    toggle();
    setTimeout(() => {
      toast.success(
        `${item.therapy_name} ${
          active ? "Activated" : "Deactivated"
        } Successfully`
      );
      refresh();
    }, 1000);
  };

  const onDone = (value) => {
    if (!hasHistory) return;
    if (therapyHistory.update_status == "Draft") {
      const request = {
        review_request: { ...value, approve: isApprove },
        therapy_history_id: therapyHistory.therapy_history_id,
      };
      reviewTherapy(request, refresh);
    } else if (therapyHistory.update_status == "ReviewSuccess") {
      const request = {
        approve_request: { ...value, approve: isApprove },
        therapy_history_id: therapyHistory.therapy_history_id,
      };
      approveTherapy(request, refresh);
    }
  };
  const getFilteredObject = (obj) => {
    const temp = { ...obj };
    delete temp.created_at;
    delete temp.created_by;
    delete temp.updated_at;
    delete temp.updated_by;
    delete temp.sub_domain_ids;
    delete temp.therapy_id;
    delete temp.pending_therapy_history;
    return temp;
  };
  return (
    <tr className="option-table">
      {isAdmin && (
        <td>
          <Switch checked={item.active} onChange={activateItem} />
        </td>
      )}
      <td className="col-sm-2">{item.therapy_name}</td>
      <td>
        {subDomains.find((x) => x.sub_domain_id == item.sub_domain_ids[0])
          ? subDomains.find((x) => x.sub_domain_id == item.sub_domain_ids[0])
              .sub_domain_name
          : "-"}{" "}
      </td>
      {/* <td>
        {data.screener_result_range_min + "-" + data.screener_result_range_max}{" "}
      </td> */}
      <td> {(userInfo.name || "Admin") + " " + formatDate(item.created_at)}</td>
      <td>
        {(updatedInfo.name || "Admin") +
          " " +
          formatDate(item.updated_at ? item.updated_at : item.created_at)}{" "}
      </td>
      <td>
        {renderStatus(therapyHistory ? therapyHistory.update_status : "")}
      </td>
      <td className="d-flex">
      {isDone && hasHistory && readyApprove ? (
          <>
            <button
              type="button"
              className="btn btn-outline-success btn-sm "
              onClick={() => {
                setOpenApprove(true);
                setApprove(true);
              }}
            >
              {therapyHistory.update_status == "ReviewSuccess"
                ? "Approve"
                : "Review"}
            </button>
            <button
              type="button"
              className="ml-2 btn btn-danger btn-sm "
              onClick={() => {
                setOpenApprove(true);
                setApprove(false);
              }}
            >
              Reject
            </button>
          </>
        ) : (
          <button
            type="button"
            className="ml-2 btn btn-outline-success btn-sm "
            onClick={() => setEdit(true)}
          >
            {canEdit ? "Edit" : "View"}
          </button>
        )}
        {isAdmin && item.published ? (
          <button
            type="button"
            className="ml-2 btn btn-outline-success btn-sm "
            onClick={() =>
              forcePublishTherapy(
                {
                  content_id: item.therapy_id,
                  content_type: "THERAPY",
                },
                refresh
              )
            }
          >
            Force Publish
          </button>
        ) : null}
      </td>

      <td className="accordion-menu" id={`AccordionTableMenu${index}`}>
        <i className="fas fa-ellipsis-v" />
      </td>
      <Popover
        placement="left"
        target={`AccordionTableMenu${index}`}
        trigger="legacy"
        isOpen={showPop}
        toggle={toggle}
      >
        <div className="accordion-menu-header">Options</div>
        {/* <div
          className="accordion-menu-item"
          onClick={() =>
            history.push(`/view-history?type=therapy&id=${data.therapy_id}`)
          }
        >
          View History
        </div> */}
        <div className="accordion-menu-item" onClick={() => {toggle(); setEdit(true)}}>
        {isDone && !canEdit ? "View" : "Edit"}
        </div>
        {/* {!hasHistory && item.published && (
          <div
            className="accordion-menu-item"
            onClick={() => activateItem(!item.active)}
          >
            {!item.active ? "Activate" : "Deactivate"}
          </div>
        )} */}
        {hasHistory && (
          <div
            className="accordion-menu-item"
            onClick={() => {
              toggle();
              setView(true);
            }}
          >
            Compare
          </div>
        )}
      </Popover>
      <TherapyModal
        isOpen={isEdit}
        setOpen={() => setEdit(false)}
        initialValues={item}
        refresh={() => {
          refresh((x) => x + 1);
        }}
        isEditable={canEdit}
        // isEditable={canEdit}
      />
      {readyApprove && (
        <ApproveModal
          setOpen={() => setOpenApprove(false)}
          isOpen={isOpenApprove}
          isApprove={isApprove}
          title={
            isApprove
              ? therapyHistory.update_status == "ReviewSuccess"
                ? "Approve"
                : "Review"
              : "Reject"
          }
          refresh={refresh}
          onDone={onDone}
          isRequire={true}
          isShowDate={
            therapyHistory && therapyHistory.update_status == "ReviewSuccess"
          }
        />
      )}
      {hasHistory && (
        <JsonCompareModal
          setOpen={() => setView(false)}
          isOpen={isView}
          title={"Therapy Compare"}
          actualDate={getFilteredObject(item)}
          newDate={getFilteredObject(therapyHistory.updated_data)}
        />
      )}
    </tr>
  );
}
