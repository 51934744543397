import { useState } from "react";
import {
  ACTIVATE_BANNER_GQL,
  REMOVE_BANNER_GQL,
} from "../../Apollo/AdminServices/ObjectiveRequest";
import graphHandler from "../../Apollo/graphHandler";
function useBannerAPI() {
  const [loading, setLoading] = useState(false);
  const activateBanner = async (req = {}, callback = () => {}) => {
    setLoading(true);
    try {
      await graphHandler().invoke(ACTIVATE_BANNER_GQL, req, 1, false, true);
      callback();
      setLoading(false);
    } catch (error) {
      console.log("activateBanner Error", error);
      setLoading(false);
    }
  };

  const removeBanner = async (
    req = {},
    callback = () => {},
    eCallBack = () => {}
  ) => {
    setLoading(true);
    try {
      await graphHandler().invoke(REMOVE_BANNER_GQL, req, 1, false, true);
      callback();
      setLoading(false);
    } catch (error) {
      eCallBack(error);
      console.log("removeBanner Error", error);
      setLoading(false);
    }
  };

  return {
    loading,
    activateBanner,
    removeBanner,
  };
}
export default useBannerAPI;
