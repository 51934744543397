import { useEffect, useState } from "react";
import { Modal, ModalBody } from "reactstrap";
import CustomLoader from "../../../components/Loader";
import EditOrganizationEmployee from "./EditOrganizationEmployee";
import AddOrganizationEmployee from "./AddOrganizationEmployee";
import useOrganizationList from "../../../utils/CustomHooks/useOrganizationList";

export default function OrganizationEmployeeModal({
  values = {},
  isOpen,
  setOpen,
  refresh,
  isEditable = true,
  isFirst = false,
}) {
  //   const [activeKey, setActive] = useState(0);
  const [organizationEmployee, setOrganizationEmployee] = useState({});
  const [organizationOption, setOrganizationOption] = useState([]);
  const [loading, setLoading] = useState(false);
  const { organizationList, getOrganizationList } =
  useOrganizationList("");
  useEffect(() => {
      values.user_id && setOrganizationEmployee(values);
    }, [values]);
useEffect(()=>{
  getOrganizationList()
},[])
// console.log("organizationEmployee",organizationEmployee)
useEffect(() => {
  setOrganizationOption(
    organizationList.map((x) => ({
      label: x.organization_name,
      value: x.organization_id,
    }))
  );
}, [organizationList]);

  const OrganizationEmployeeSection = organizationEmployee.user_id
    ? EditOrganizationEmployee
    : AddOrganizationEmployee;

  const onClose = () => {
    setOpen(false);
    setOrganizationEmployee({});
    refresh();
  };

  return (
    <Modal
      toggle={onClose}
      isOpen={isOpen}
      size="lg"
      keyboard={false}
      modalClassName="modal-fullscreen modal-dialog-scrollable"
    >
      <div className="modal-header">
        <h5 className="modal-title">
          {organizationEmployee.user_id ? "Update" : "Add"} Organization Employee
        </h5>
      </div>
      <ModalBody>
        {loading && <CustomLoader />}
        <OrganizationEmployeeSection
          refresh={refresh}
          onClose={onClose}
          organizationOption = {organizationOption}
          organizationEmployee={organizationEmployee}
          isEditable={isEditable}
          isFirst={isFirst}
          values={organizationEmployee}
          setOrganizationEmployee={setOrganizationEmployee}
          setLoading={setLoading}
        />
      </ModalBody>
    </Modal>
  );
}
