import { useState } from "react";
import UpdateTimeZone from "../modal/UpdateTimeZone";
import { Link } from "react-router-dom";
// import useTimeZoneAPI from "../../../utils/CustomHooks/useTimeZoneAPI";
import useRemoveAPI from "../../../utils/CustomHooks/useRemoveAPI";
import { message, Popconfirm } from "antd";

export default function ListItem({
  item,
  refresh,
  access = {},
  timeZoneList = [],
}) {
  const [isEdit, setEdit] = useState(false);
  // const { removeTimeZone } = useTimeZoneAPI();
  const { removeTimeZone } = useRemoveAPI();
  const onDelete = () => {
    removeTimeZone({ time_zone_id: item.time_zone_id }, refresh);
  };

  return (
    <tr>
      <td>{item.time_zone_name}</td>
      <td>{item.time_zone_utc_offset}</td>
      <td>{item.time_zone_short_code}</td>
      <td>
        <span
          className={`${item.active ? "good" : "bad"} text-center btn btn-sm`}
        >
          {item.active ? "Active" : "Disabled"}
        </span>
      </td>
      {access.canEdit && (
        <td>
          <button
            type="button"
            className="btn btn-outline-success btn-sm "
            onClick={() => setEdit(true)}
          >
            Edit
          </button>
        </td>
      )}
      <td>
        <Popconfirm
          title="Are you sure to delete this timezone?"
          onConfirm={onDelete}
          //  onCancel={cancel}
          okText="Yes"
          cancelText="No"
        >
          <i
            className="ml-2 fas fa-trash cursor-pointer"
            // onClick={onDelete}
          ></i>
        </Popconfirm>
      </td>

      <UpdateTimeZone
        isOpen={isEdit}
        setOpen={() => setEdit(false)}
        initialValue={item}
        refresh={refresh}
        timeZoneList={timeZoneList}
        // city_id={item.city_id}
      />
    </tr>
  );
}
