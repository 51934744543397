import { useState } from "react";
import { GET_INTERVENTION_LIST_GQL } from "../../Apollo/AdminServices/QueryRequest";
import graphHandler from "../../Apollo/graphHandler";
function useInterventionList() {
  const [interventionList, setInterventions] = useState([]);
  const [loading, setLoading] = useState(false);

  const getInterventionList = async (req = {}, get_pending_update = true) => {
    setLoading(true);
    try {
      const { data } = await graphHandler().invoke(
        GET_INTERVENTION_LIST_GQL,
        { get_pending_update, ...req },
        1,
        false,
        true
      );
      if (
        data &&
        data.getInterventionList &&
        data.getInterventionList.interventions &&
        data.getInterventionList.interventions.length > 0
      ) {
        // console.log(
        //   "getInterventionList Success",
        //   data.getInterventionList.interventions
        // );
        const struct = data.getInterventionList.interventions.map((x) => ({
          ...x.intervention,
          label: x.intervention.intervention_name,
          value: x.intervention.intervention_id,
          pending_intervention_history: x.pending_intervention_history,
        }));
        setInterventions([]);
        setInterventions(struct);
        setLoading(false);
      } else {
        setInterventions([]);
        setLoading(false);
      }
    } catch (error) {
      console.log("getInterventionList Error", error);
      setInterventions([]);
      setLoading(false);
    }
  };
  return {
    interventionList,
    loading,
    getInterventionList,
    resetIntervention: () => setInterventions([]),
  };
}
export default useInterventionList;
