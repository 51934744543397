import { gql } from "@apollo/client";
import { loginResponse, serviceResponse } from "./profileResponse";

export const LOGIN_ADMIN = gql`
mutation loginAdmin ($login_request: LoginRequest!) {
    loginAdmin (login_request: $login_request) {
		${loginResponse}
       ${serviceResponse}
	}
}
`;

export const UPDATE_EMPLOYEE_USER = gql`
  mutation updateEmployeeProfessionalInfo(
    $update_request: UpdateEmployeeProfessionalInfoRequest!
  ) {
    updateEmployeeProfessionalInfo(update_request: $update_request) {
      status
      error
      message
      version
      service
    }
  }
`;

export const UPDATE_PASSWORD_GQL = gql`
  mutation updatePassword($password: String!) {
    updatePassword(password: $password) {
      status
      error
      message
      version
      service
    }
  }
`;
export const UPDATE_USER = gql`
  mutation updateUser($update_request: UpdateUserRequest!) {
    updateUser(update_request: $update_request) {
      status
      error
      message
      version
      service
      user {
        created_at
        created_by
        updated_at
        updated_by
        user_id
        user_type
        user_name
        name
        mobile_number
        mobile_number_verified
        email
        email_verified
        password
        address {
          line_1
          line_2
          region
          town_city
          postcode
          state_id
          country_id
        }
        profile_image_urls {
          image_type
          url
          is_mobile
        }
        device_id
        pn_token
        active
      }
    }
  }
`;

export const ACTIVATE_USER_GQL = gql`
  mutation activateUser($activate: Boolean!, $user_id: String!) {
    activateUser(activate: $activate, user_id: $user_id) {
      status
      error
      message
      version
      service
      user {
        created_at
        created_by
        updated_at
        updated_by
        user_id
        user_type
        user_name
        name
        mobile_number
        mobile_number_verified
        email
        email_verified
        password
        address {
          line_1
          line_2
          region
          town_city
          postcode
          state_id
          country_id
        }
        profile_image_urls {
          image_type
          url
          is_mobile
        }
        device_id
        pn_token
        active
      }
    }
  }
`;
