export const ScreenerFields = [
  {
    label: "Question",
    key: "Question",
    alternateMatches: ["question", "questions", "Questions"],
    fieldType: {
      type: "input",
    },
    example: "How do you feel",
    validations: [
      {
        rule: "required",
        errorMessage: "Question is required",
        level: "error",
      },
    ],
  },
  {
    label: "Result Weightage",
    key: "Result Weightage",
    alternateMatches: [
      "ResultWeightage",
      "resultweightage",
      "result weightage",
      "resultweightage",
    ],
    fieldType: {
      type: "input",
    },
    example: "5",
    validations: [
      {
        rule: "required",
        errorMessage: "Result weightage is required",
        level: "error",
      },
      {
        rule: "regex",
        value: /^\d+$/,
        errorMessage: "Result weightage must be a number",
        level: "error",
      },
    ],
  },
  {
    label: "Topic",
    key: "Topic",
    alternateMatches: ["topic", "Topics", "topics"],
    fieldType: {
      type: "select",
    },
    validations: [
      {
        rule: "required",
        errorMessage: "Topic is required",
        level: "error",
      },
    ],
  },
  {
    label: "Sub Domain",
    key: "Sub Domain",
    alternateMatches: ["SubDomain", "Subdomain", "Sub domain", "subdomain"],
    fieldType: {
      type: "input",
    },
    validations: [
      {
        rule: "required",
        errorMessage: "Sub domain is required",
        level: "error",
      },
    ],
  },
  {
    label: "Option 1",
    key: "option 1",
    alternateMatches: ["Option A", "Option a", "Option 1"],
    fieldType: {
      type: "input",
    },
    example: "Good",
    validations: [
      {
        rule: "required",
        errorMessage: "option 1 is required",
        level: "error",
      },
      //   {
      //     rule: "unique",
      //     allowEmpty: false,
      //     errorMessage: "option 1 should be unique",
      //     level: "error",
      //   },
    ],
  },
  {
    label: "Option 1 Value",
    key: "option 1 value",
    alternateMatches: [
      "Option A Value",
      "Option a value",
      "Option 1 value",
      "Option 1 Value",
    ],
    fieldType: {
      type: "input",
    },
    example: "4",
    validations: [
      {
        rule: "required",
        errorMessage: "option 1 value is required",
        level: "error",
      },
      {
        rule: "regex",
        value: /^\d+$/,
        errorMessage: "Option value must be a number",
        level: "error",
      },
    ],
  },
  {
    label: "Option 2",
    key: "option 2",
    alternateMatches: ["Option B", "Option b", "Option 2"],
    fieldType: {
      type: "input",
    },
    example: "Good",
    validations: [
      {
        rule: "required",
        errorMessage: "option 2 is required",
        level: "error",
      },
      //   {
      //     rule: "unique",
      //     allowEmpty: false,
      //     errorMessage: "option 2 should be unique",
      //     level: "error",
      //   },
    ],
  },
  {
    label: "Option 2 Value",
    key: "option 2 value",
    alternateMatches: [
      "Option B Value",
      "Option b value",
      "Option 2 value",
      "Option 2 Value",
    ],
    fieldType: {
      type: "input",
    },
    example: "3",
    validations: [
      {
        rule: "required",
        errorMessage: "option 2 value is required",
        level: "error",
      },
      {
        rule: "regex",
        value: /^\d+$/,
        errorMessage: "Option Value must be a number",
        level: "error",
      },
    ],
  },
  {
    label: "Option 3",
    key: "option 3",
    alternateMatches: ["Option C", "Option c", "Option 3"],
    fieldType: {
      type: "input",
    },
    example: "Good",
    validations: [
      //   {
      //     rule: "unique",
      //     allowEmpty: true,
      //     errorMessage: "option 3 should be unique",
      //     level: "error",
      //   },
    ],
  },
  {
    label: "Option 3 Value",
    key: "option 3 value",
    alternateMatches: [
      "Option C Value",
      "Option c value",
      "Option 3 value",
      "Option 3 Value",
    ],
    fieldType: {
      type: "input",
    },
    example: "2",
    validations: [
      //   {
      //     rule: "regex",
      //     value: /^\d+$/,
      //     errorMessage: "Option value must be a number",
      //     level: "error",
      //   },
    ],
  },
  {
    label: "Option 4",
    key: "option 4",
    alternateMatches: ["Option D", "Option d", "Option 4"],
    fieldType: {
      type: "input",
    },
    example: "Good",
    validations: [
      //   {
      //     rule: "unique",
      //     allowEmpty: true,
      //     errorMessage: "option 4 is unque",
      //     level: "error",
      //   },
    ],
  },
  {
    label: "Option 4 Value",
    key: "option 4 value",
    alternateMatches: [
      "Option D Value",
      "Option d value",
      "Option 4 value",
      "Option 4 Value",
    ],
    fieldType: {
      type: "input",
    },
    example: "1",
    validations: [
      //   {
      //     rule: "regex",
      //     value: /^\d+$/,
      //     errorMessage: "Option value must be a number",
      //     level: "error",
      //   },
    ],
  },
  {
    label: "Option 5",
    key: "option 5",
    alternateMatches: ["Option E", "Option e", "Option 5"],
    fieldType: {
      type: "input",
    },
    example: "Good",
    validations: [
      //   {
      //     rule: "unique",
      //     allowEmpty: true,
      //     errorMessage: "option 5 is unque",
      //     level: "error",
      //   },
    ],
  },
  {
    label: "Option 5 Value",
    key: "option 5 value",
    alternateMatches: [
      "Option E Value",
      "Option e value",
      "Option 5 value",
      "Option 5 Value",
    ],
    fieldType: {
      type: "input",
    },
    example: "1",
    validations: [
      //   {
      //     rule: "regex",
      //     value: /^\d+$/,
      //     errorMessage: "Option value must be a number",
      //     level: "error",
      //   },
    ],
  },
  {
    label: "Option 6",
    key: "option 6",
    alternateMatches: ["Option F", "Option f", "Option 6"],
    fieldType: {
      type: "input",
    },
    example: "Good",
    validations: [
      //   {
      //     rule: "unique",
      //     allowEmpty: true,
      //     errorMessage: "option 6 is unque",
      //     level: "error",
      //   },
    ],
  },
  {
    label: "Option 6 Value",
    key: "option 6 value",
    alternateMatches: [
      "Option F Value",
      "Option f value",
      "Option 6 value",
      "Option 6 Value",
    ],
    fieldType: {
      type: "input",
    },
    example: "1",
    validations: [
      //   {
      //     rule: "regex",
      //     value: /^\d+$/,
      //     errorMessage: "Option value must be a number",
      //     level: "error",
      //   },
    ],
  },
  {
    label: "Option 7",
    key: "option 7",
    alternateMatches: ["Option G", "Option g", "Option 7"],
    fieldType: {
      type: "input",
    },
    example: "Good",
    validations: [
      //   {
      //     rule: "unique",
      //     allowEmpty: true,
      //     errorMessage: "option 7 is unque",
      //     level: "error",
      //   },
    ],
  },
  {
    label: "Option 7 Value",
    key: "option 7 value",
    alternateMatches: [
      "Option G Value",
      "Option g value",
      "Option 7 value",
      "Option 7 Value",
    ],
    fieldType: {
      type: "input",
    },
    example: "1",
    validations: [
      //   {
      //     rule: "regex",
      //     value: /^\d+$/,
      //     errorMessage: "Option value must be a number",
      //     level: "error",
      //   },
    ],
  },
  {
    label: "Option 8",
    key: "option 8",
    alternateMatches: ["Option H", "Option h", "Option 8"],
    fieldType: {
      type: "input",
    },
    example: "Good",
    validations: [
      //   {
      //     rule: "unique",
      //     allowEmpty: true,
      //     errorMessage: "option 8 is unque",
      //     level: "error",
      //   },
    ],
  },
  {
    label: "Option 8 Value",
    key: "option 8 value",
    alternateMatches: [
      "Option H Value",
      "Option h value",
      "Option 8 value",
      "Option 8 Value",
    ],
    fieldType: {
      type: "input",
    },
    example: "1",
    validations: [
      //   {
      //     rule: "regex",
      //     value: /^\d+$/,
      //     errorMessage: "Option value must be a number",
      //     level: "error",
      //   },
    ],
  },
  {
    label: "Option 9",
    key: "option 9",
    alternateMatches: ["Option I", "Option i", "Option 9"],
    fieldType: {
      type: "input",
    },
    example: "Good",
    validations: [
      //   {
      //     rule: "unique",
      //     allowEmpty: true,
      //     errorMessage: "option 9 is unque",
      //     level: "error",
      //   },
    ],
  },
  {
    label: "Option 9 Value",
    key: "option 9 value",
    alternateMatches: [
      "Option I Value",
      "Option i value",
      "Option 9 value",
      "Option 9 Value",
    ],
    fieldType: {
      type: "input",
    },
    example: "1",
    validations: [
      //   {
      //     rule: "regex",
      //     value: /^\d+$/,
      //     errorMessage: "Option value must be a number",
      //     level: "error",
      //   },
    ],
  },
  {
    label: "Option 10",
    key: "option 10",
    alternateMatches: ["Option J", "Option j", "Option 10"],
    fieldType: {
      type: "input",
    },
    example: "Good",
    validations: [
      //   {
      //     rule: "unique",
      //     allowEmpty: true,
      //     errorMessage: "option 10 is unque",
      //     level: "error",
      //   },
    ],
  },
  {
    label: "Option 10 Value",
    key: "option 10 value",
    alternateMatches: [
      "Option J Value",
      "Option j value",
      "Option 10 value",
      "Option 10 Value",
    ],
    fieldType: {
      type: "input",
    },
    example: "1",
    validations: [
      //   {
      //     rule: "regex",
      //     value: /^\d+$/,
      //     errorMessage: "Option value must be a number",
      //     level: "error",
      //   },
    ],
  },
  {
    label: "Ignore Scoring",
    key: "Ignore Scoring",
    alternateMatches: [
      "ignore scoring",
      "ignorescoring",
      "Ignore scoring",
      "ignore Scoring",
      "Ignorescoring",
      "ignoreScoring",
    ],
    fieldType: {
      type: "checkbox",
    },
    example: "4",
    validations: [],
  },
];

export const QuestionTemplate = [
  {
    Question: "Your question here",
    "Result Weightage": "4 (Over all weightage for this question)",
    "Option 1": "required",
    "Option 1 Value": "1",
    "Option 2": "required",
    "Option 2 Value": "2",
    "Option 3": "optional",
    "Option 3 Value": "3",
    "Option 4": "optional",
    "Option 4 Value": "4",
  },
];

export const GenerateQuestionCSV = (obj = {}) => {
  let returnDate = {
    Question: "Your question here",
    "Result Weightage": "4 (Over all weightage for this question)",
    "Option 1": "required",
    "Option 1 Value": "1",
    "Option 2": "required",
    "Option 2 Value": "2",
    Topic: "",
    "Sub Domain": "",
  };
  return returnDate;
};

export const optionsTypes = [
  "option 1",
  "option 2",
  "option 3",
  "option 4",
  "option 5",
  "option 6",
  "option 7",
  "option 8",
  "option 9",
  "option 10",
];
