import { useEffect, useState } from "react";
import { Modal, ModalBody } from "reactstrap";
import DatePicker from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";
export default function ApproveModal({
  isOpen,
  setOpen,
  isShowDate = false,
  onDone = () => {},
  title = "Approve",
  isApprove,
  isRequire = false,
}) {
  const [comment, setComment] = useState("");
  const [isCalenderOpen, setCalenderOpen] = useState(false);
  const [publish_at, setPublished] = useState(
    moment(new Date()).add({ minutes: 2 }).toDate()
  );
  useEffect(() => {
    setPublished(moment(new Date()).add({ minutes: 2 }).toDate());
  }, [isOpen]);

  const onClose = () => {
    setComment("");
    setOpen(false);
  };

  const onConfirm = () => {
    let value = { comment: comment ? comment : "" };
    if (isShowDate) {
      let now = moment(Date.now());
      if (now > publish_at) return toast.error("Select a valid time");
      value.publish_at_ms = moment(publish_at).valueOf();
    }
    // console.log(value,);
    onDone(value);
    onClose();
  };
  const calculateMinTime = (date) => {
    let isToday = moment(date).isSame(moment(), "day");
    if (isToday)
      return moment(new Date(Date.now())).add({ minutes: 2 }).toDate();
    return moment().startOf("day").toDate();
  };

  const onCalenderClose = () => {
    setCalenderOpen(false);
    let now = moment(Date.now()).toDate();
    if (now < moment(publish_at).toDate()) setPublished(publish_at);
    else setPublished(moment(now).add({ minutes: 2 }).toDate());
  };

  return (
    <Modal
      toggle={onClose}
      backdrop="static"
      isOpen={isOpen}
      centered
      scrollable
      size="xl"
    >
      <div className="modal-header pb-0">
        <button type="button" className="close" onClick={onClose}>
          <span aria-hidden="true">&times;</span>
        </button>
        <h5 className="modal-title">{title}</h5>
      </div>
      <ModalBody>
        {isShowDate && (
          <div className="form-group">
            <label htmlFor="date-picker" className="col-form-label w-100 mb-2">
              Date
            </label>
            <DatePicker
              id="date-picker"
              className="form-control"
              selected={publish_at}
              onChange={setPublished}
              shouldCloseOnSelect={false}
              onCalendarClose={onCalenderClose}
              onCalendarOpen={(e) => setCalenderOpen(true)}
              open={isCalenderOpen}
              //   showTimeSelect
              showTimeInput
              todayButton="Today"
              minDate={moment().toDate()}
              minTime={calculateMinTime(publish_at)}
              maxTime={moment().endOf("day").toDate()}
              startDate={new Date(Date.now())}
              timeIntervals={1}
              dateFormat="MMMM d, yyyy h:mm aa"
              onKeyDown={(e) => {
                e.preventDefault();
              }}
            />
          </div>
        )}
        <div className="form-group">
          <label htmlFor="Comments" className="col-form-label w-100 mb-2">
            {isApprove ? "Comments" : "Reason of rejection"}
          </label>
          <textarea
            id="Comments"
            className="form-control"
            maxLength="150"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          />
        </div>
        <button
          type="button"
          disabled={
            isCalenderOpen ||
            (isRequire && (!comment || (isShowDate ? !publish_at : false)))
          }
          className="btn btn-primary btn-outline-success btn-sm float-right btn-add-domain"
          onClick={onConfirm}
        >
          Confirm
        </button>
      </ModalBody>
    </Modal>
  );
}
