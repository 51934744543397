import moment from "moment";
import React, { useEffect } from "react";
import useUserInfo from "../../../utils/CustomHooks/useUserInfo";

export default function ScreenerHistoryData({ x = {}, i }) {
  const { userInfo, getUser } = useUserInfo("");
  useEffect(() => {
    if (x.created_by) getUser(x.created_by);
  }, [x]);
  return (
    <tr>
      <td> {x.update_type ? x.update_type : "-"}</td>
      <td>
        {moment(x.updated_at ? x.updated_at : x.created_at).format(
          "DD/MM/YYYY - hh:mm:ss A"
        ) || "-"}
      </td>
      <td> {userInfo && userInfo.name ? userInfo.name : "-"} </td>
    </tr>
  );
}
