import moment from "moment";
import { useEffect } from "react";
import useUserInfo from "../../../utils/CustomHooks/useUserInfo";

export default function QuestionTableItem({ data }) {
  const { userInfo, getUser } = useUserInfo("");
  useEffect(() => {
    if (data.created_by) getUser(data.created_by);
  }, [ data ]);
  const removeUnderScore = (string) => {
    const sentence = string.replace("_", " ");
    const words = sentence.split(" ");
    return words
      .map((word) => {
        return word[ 0 ].toUpperCase() + word.substring(1).toLowerCase();
      })
      .join(" ");
  };
  return (
    <tr>
      <td className="">
        {removeUnderScore(data.update_type + " " + data.content_type)}
      </td>
      <td>
        {data.created_at
          ? moment(data.created_at).format("DD/MM/YYYY - hh:mm:ss A")
          : "-"}
      </td>
      <td>{userInfo && userInfo.name ? userInfo.name : "-"}</td>
    </tr>
  );
}
