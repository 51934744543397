import { useSelector } from "react-redux";
import { Popconfirm } from "antd";
import { useEffect } from "react";

export default function QuestionTopicAccordionItem({
  item = {},
  onEdit,
  onDelete = () => {},
  setHistory = () => {},
}) {
  const subDomains = useSelector(({ DataSet }) => DataSet.subDomains);

  const { pending_history } = item;
  // const hasPublished =
  //   pending_history &&
  //   pending_history.publish_data &&
  //   pending_history.publish_data.created_by;

  useEffect(() => {
    if (pending_history?.updated_data?.active === false) {
      setHistory(Boolean(pending_history && pending_history.updated_data));
    }
  }, [pending_history]);

  const OptionRender = (option, key) => {
    if (item.option_type === "Text")
      return (
        <li key={key} className={key === 0 ? "ml-4" : "ml-4"}>
          {option.data + " (" + option.value + ")"}
        </li>
      );
    else
      return (
        <div key={key} className="option-panel mb-2">
          {key + 1 + ". "}
          <img src={option.data} />
        </div>
      );
  };
  const getSubDomain = (data) => {
    if (!data.sub_domain_id) return "-";
    let val = subDomains.find((x) => x.sub_domain_id === data.sub_domain_id);
    return val ? val.sub_domain_name : "-";
  };
  const canShowOptions = (value) =>
    value &&
    value != "TextField" &&
    value != "DocumentUpload" &&
    value != "ImageCapture" &&
    value != "Date" &&
    value != "Rating" &&
    value != "TextArea";
  return (
    <tr
      className={`${
        pending_history?.updated_data?.active === false ? "bad" : ""
      } option-table`}
    >
      <td className="text-center">{item.screener_question_title}</td>
      <td className="text-center">
        <ol type="A" className="d-flex flex-row flex-wrap">
          {canShowOptions(item.screener_question_type) &&
          item.options &&
          item.options.options
            ? item.options.options.map((q, j) => OptionRender(q, j))
            : item.screener_question_type}
        </ol>
      </td>
      <td className="text-center">{getSubDomain(item)}</td>
      <td className="text-center"> {item.result_weightage}</td>
      <td className="text-center"> {item.ignore_for_scoring ? "Yes" : "No"}</td>
      <td className="d-flex align-items-center justify-content-center">
        {pending_history?.updated_data?.active !== false ? (
          <>
            <button
              type="button"
              className="ml-2 btn btn-outline-success btn-sm "
              onClick={() => onEdit(item)}
            >
              Edit
            </button>
            <button
              type="button"
              className="ml-2 btn btn-outline-success btn-sm "
              onClick={() => onEdit({ ...item, duplicate: true })}
            >
              Duplicate
            </button>
            {/* {hasPublished && (
          <span className="badge badge-error float-right">Changes</span>
        )} */}
            <Popconfirm
              title="Are you sure to delete this question?"
              onConfirm={() => onDelete(item.screener_question_id)}
              okText="Yes"
              cancelText="No"
            >
              <i className="fas fa-trash cursor-pointer ml-2"></i>
            </Popconfirm>
          </>
        ) : (
          "-"
        )}
      </td>
      {/* )} */}
    </tr>
  );
}
