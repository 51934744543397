import { useEffect, useState } from "react";
import { Modal, ModalBody } from "reactstrap";
import { useSelector } from "react-redux";
import CustomLoader from "../../../components/Loader";
import { Steps } from "antd";
import CreateSubDomain from "./CreateSubDomain";
import UpdateSubDomain from "./UpdateSubDomain";
// import RelatedContentsGroup from "./RelatedContentsGroup";
import useRelatedContentList from "../../../utils/CustomHooks/useRelatedContentList";
import RelatedContentsGroup from "../../../components/RelatedContents/component/RelatedContentsGroup";
// import RelatedContents from "../../../components/RelatedContents";
const { Step } = Steps;

export default function SubDomainModal({
  initialValues = {},
  isOpen,
  setOpen,
  refresh,
  domainList,
  isEditable = true,
  isFirst = false,
}) {
  const [activeKey, setActive] = useState(0);
  const [subDomain, setSubDomain] = useState({});
  const [loading, setLoading] = useState(false);
  const [reqCount, setReqCount] = useState(0);
// console.log("reqCount",reqCount)
const { relatedContents,loading:relatedContentLoading,getRelatedContents } = useRelatedContentList();
 
useEffect(() => {
    initialValues.sub_domain_id && setSubDomain(initialValues);
  }, [initialValues]);

  useEffect(() => {
    if (subDomain.sub_domain_id && isOpen) {
      getRelatedContents({
        content_id: subDomain.sub_domain_id,
        content_type:"SUBDOMAIN"
      });
    }
  }, [subDomain, reqCount, isOpen]);

  const SubDomainSection = subDomain.sub_domain_id ? UpdateSubDomain : CreateSubDomain ;
  const renderSteps = (key) => {
    switch (key) {
      case 0:
        return (
          <SubDomainSection
            refresh={refresh}
            onDone={() => setActive(1)}
            onClose={onClose}
            subDomain={subDomain}
            isEditable={isEditable}
            isFirst={isFirst}
            initialValue={subDomain}
            domainList={domainList}
            setSubDomain={setSubDomain}
            setLoading={setLoading}
          />
        );
        case 1:
        return (
          <RelatedContentsGroup
            refresh={() => setReqCount((x) => x + 1)}
            onClose={onClose}
            onBack={() => setActive(0)}
            contentId={subDomain.sub_domain_id}
            isEditable={isFirst || isEditable}
            setLoading={setLoading}
            contentType="SUBDOMAIN"
            relatedContentList={relatedContents}
            showDesc={true}
        />
        );
      default:
        return <h5>No step found</h5>;
    }
  };
  const onClose = () => {
    setOpen(false);
    setActive(0);
    setSubDomain({});
    refresh();
  };

  const onChangeStep = (e) => {
    if (e === 1 && !subDomain.sub_domain_id) return;
     setActive(e);
  };

  return (
    <Modal
      toggle={onClose}
      isOpen={isOpen}
      size="lg"
      keyboard={false}
      modalClassName="modal-fullscreen modal-dialog-scrollable"
    >
      <div className="modal-header">
        <Steps
          type="navigation"
          current={activeKey}
          onChange={onChangeStep}
          className="site-navigation-steps"
        >
          <Step
            status={subDomain.sub_domain_id ? "finish" : "process"}
            title={subDomain.sub_domain_id ? "SubDomain" : "Create SubDomain"}
          />
          <Step
            status={
              relatedContents.length > 0
                ? "finish"
                : subDomain.sub_domain_id
                ? "process"
                : "wait"
            }
            disabled={!subDomain.sub_domain_id}
            title="Related Content"
          />
        </Steps>
      </div>
      <ModalBody>
        {(loading||relatedContentLoading) && <CustomLoader />}
        {renderSteps(activeKey)}
      </ModalBody>
    </Modal>
  );
}
