import { useState } from "react";
import { Tabs } from "antd";
import ResultSection from "./ResultSection";
import ThresholdSection from "./ThresholdSection";
import useDomainList from "../../../utils/CustomHooks/useDomainList";
import { useEffect } from "react";
import { useSelector } from "react-redux";
const { TabPane } = Tabs;
export default function CalculationSection(props) {
  let { onDone = () => {} } = props;
  const [active, setActive] = useState("0");
  const subDomains = useSelector(({ DataSet }) => DataSet.subDomains);
  const { domainList, getDomainList } = useDomainList("");
  const onChangeTab = (e) => setActive(e);
  useEffect(() => {
    getDomainList({});
  }, []);
  const onNext = () => {
    if (active === "0") {
      onChangeTab("1");
    } else {
      onDone();
    }
  };
  return (
    <Tabs
      centered
      onTabClick={onChangeTab}
      defaultActiveKey={0}
      activeKey={active}
    >
      <TabPane tab="Interpretation" key="0">
        <ResultSection
          {...{ ...props, subDomains, domainList }}
          onDone={onNext}
        />
      </TabPane>
      <TabPane tab="Threshold" key="1">
        <ThresholdSection {...{ ...props, subDomains, domainList }} />
      </TabPane>
    </Tabs>
  );
}
