import React, { useState } from "react";
import EditorModal from "../Modal/EditorModal";

export default function Description({
  formik,
  disabled = false,
  webTitle = "Description",
  mobileTitle = "Mobile Description",
  webName = "description",
  mobileName = "mobile_description",
  col = "col-md-12",
  value,
  mobileValue,
  showEditor = false,
}) {
  const [isOpenWeb, setOpenWeb] = useState(false);
  const [isOpenMobile, setOpenMobile] = useState(false);
  const onCopy = () => {
    if (disabled) return;
    formik.setFieldValue(mobileName, value ? value : formik.values[webName]);
  };
  return (
    <>
      <div className={`form-group ${col}`}>
        {showEditor ? (
          <EditorModal
            isOpen={isOpenWeb}
            defaultValue={formik.values[webName] ? formik.values[webName] : ""}
            close={() => setOpenWeb(false)}
            save={(e) => {
              formik.setFieldValue(webName, e);
            }}
          />
        ):null}
        {showEditor && (
          <EditorModal
            isOpen={isOpenMobile}
            defaultValue={
              formik.values[mobileName] ? formik.values[mobileName] : ""
            }
            close={() => setOpenMobile(false)}
            save={(e) => {
              formik.setFieldValue(mobileName, e);
            }}
          />
        )}
        <label htmlFor={webName} className="col-form-label w-100 mb-2">
          {webTitle}
          {!disabled&&showEditor && (
            <button
              className="btn-link-common float-right"
              onClick={() => setOpenWeb(true)}
            >
              Use Editor
            </button>
          )}
        </label>
        <textarea
          className="form-control"
          id={webName}
          name={webName}
          value={
            value
              ? value
              : formik.values && formik.values[webName]
              ? formik.values[webName]
              : ""
          }
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          disabled={disabled}
        />
        <small className="text-danger">
          {formik.touched[webName] && formik.errors && formik.errors[webName]
            ? formik.errors[webName]
            : ""}
        </small>
      </div>
      <div className={`form-group ${col}`}>
        <label htmlFor={mobileName} className="col-form-label w-100 mb-2">
          {mobileTitle}
          {!disabled&&showEditor && (
            <button
              className="btn-link-common float-right ml-3"
              onClick={() => setOpenMobile(true)}
            >
              Use Editor
            </button>
          )}
          <label
            onClick={onCopy}
            className="float-right text-right cursor-pointer mr-0"
          >
            Copy <i className="fas fa-copy ml-2" />
          </label>
        </label>
        <textarea
          className="form-control"
          id={mobileName}
          name={mobileName}
          value={
            mobileValue
              ? mobileValue
              : formik.values && formik.values[mobileName]
              ? formik.values[mobileName]
              : ""
          }
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          disabled={disabled}
        ></textarea>
        <small className="text-danger">
          {formik.touched[mobileName] &&
          formik.errors &&
          formik.errors[mobileName]
            ? formik.errors[mobileName]
            : ""}
        </small>
      </div>
    </>
  );
}
