import { useFormik } from "formik";
import { useState } from "react";
import { useEffect, useMemo } from "react";
import * as Yup from "yup";
import { IsNullOrEmpty } from "../../utils/Functions/filter";
import usePrevious from "../../utils/Functions/usePrevious";
import EditorModal from "../Modal/EditorModal";

export default function TextAreaField({
  parentForm = {},
  className = "col-md-12",
  updateValue = "",
  actualValue = "",
  id = "",
  title = "",
  disabled = false,
  validation = {},
  inputProps = {},
  isNumber = false,
  showEditor = false,
}) {
  const [isOpenWeb, setOpenWeb] = useState(false);

  const formik = useFormik({
    initialValues: {},
    validationSchema: Yup.object().shape(validation),
    onSubmit: () => {},
    validateOnChange: true,
  });
  const previous = usePrevious(formik.errors[id]);

  useEffect(() => {
    if (!IsNullOrEmpty(updateValue) && parentForm) {
      parentForm.setFieldValue(id, updateValue);
    }
  }, [updateValue]);
  //   console.log(
  //     "Formik",
  //     id,
  //     formik.values,
  //     formik.errors,
  //     {
  //       actualValue,
  //       updateValue,
  //       errors: parentForm.errors,
  //       values: parentForm.values,
  //     },
  //     updateValue ? true : false,
  //     formik.errors[id]
  //   );
  useEffect(() => {
    if (formik.errors[id]) {
      parentForm.setFieldError(id, formik.errors[id] ? formik.errors[id] : "");
    } else if (previous !== formik.errors[id]) {
      let errors = { ...parentForm.errors };
      delete errors[id];
      parentForm.setErrors(errors);
    }
  }, [formik.errors, parentForm.errors]);

  useMemo(() => {
    formik.setFieldValue(
      id,
      !IsNullOrEmpty(updateValue) ? updateValue : actualValue
    );
  }, [updateValue, actualValue]);

  const onChange = (e) => {
    let { name, value } = e.target;
    let final = isNumber ? handleNumber(e) : value;
    formik.setFieldValue(name, final);
    parentForm && parentForm.setFieldValue(name, final);
  };

  const handleNumber = (e) => {
    const { name, value } = e.target;
    const re = /^[0-9\b]+$/;
    let temp = !value
      ? value
      : re.test(value)
      ? value
      : formik.values[name]
      ? formik.values[name]
      : "";
    return temp ? temp.substring(0, 10) : temp;
  };

  return (
    <div className={className}>
      <div className="row">
        {updateValue && (
          <div className="col-md-12 form-group">
            <label htmlFor={id} className="col-form-label w-100 mb-2">
              {title ?? ""}
              <span className="badge badge-good float-right">Existing</span>
            </label>
            <textarea
              className="form-control"
              value={actualValue}
              disabled
              ></textarea>
          </div>
        )}
        <div className="col-md-12 form-group">
          <label htmlFor={id} className="col-form-label w-100 mb-2">
            {title ?? ""}
            {updateValue ? (
              <span className="badge badge-error float-right">New</span>
            ):null}
             {showEditor && !disabled && (
                  <button
                    className="btn-link-common float-right"
                    onClick={() => setOpenWeb(true)}
                  >
                    Use Editor
                  </button>
                )}
          </label>
          <textarea
            className="form-control"
            id={id}
            name={id}
            value={!IsNullOrEmpty(formik.values[id]) ? formik.values[id] : ""}
            onChange={onChange}
            onBlur={formik.handleBlur}
            disabled={disabled}
            {...inputProps}
          ></textarea>
          <small className="text-danger">
            {formik.errors[id] ? formik.errors[id] : ""}
          </small>
        </div>
      </div>
      {showEditor && !disabled && (
          <EditorModal
            isOpen={isOpenWeb}
            defaultValue={formik.values[id] ? formik.values[id] : ""}
            close={() => setOpenWeb(false)}
            save={(e) => {
              formik.setFieldValue(id, e);
            }}
          />
        )}
    </div>
  );
}
