import { useEffect, useState } from "react";
import useInterventionList from "../../utils/CustomHooks/useInterventionList";
import InterventionListItem from "./components/InterventionListItem";
import PaginationComponent from "../../components/PaginationComponent";
import "./Intervention.css";
import AddInterventionModal from "./modal/AddInterventionModal";
import withRoleBased from "../../utils/Functions/withRoleBased";
import useCategoryList from "../../utils/CustomHooks/useCategoryList";
import InterventionModal from "./modal/InterventionModal";
import { sortArray } from "../../utils/Functions/filter";
function Index({ access = {} }) {
  const { interventionList, loading, getInterventionList } =
    useInterventionList();
  const [count, setCount] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize] = useState(10);
  const { categoryList, getCategoryList } = useCategoryList("");
  const [search, setSearch] = useState("");
  const [isSearching, setIsSearch] = useState(false);

  const handleClick = (event, index) => {
    event.preventDefault();
    setCurrentPage(index);
  };
  const pageCount = Math.ceil(interventionList.length / pageSize);

  useEffect(() => {
    getCategoryList({ supported_content_types: ["INTERVENTION"] });
  }, []);

  useEffect(() => {
    let req = {};
    if (search) req.intervention_name = search;
    getInterventionList(req);
  }, [count]);

  const onSearch = () => {
    setCurrentPage(0)
    setCount((x) => x + 1);
    setIsSearch(search ? true : false);
  };

  return (
    <main className="page-content">
      <div className="container-fluid">
        <div className="row ">
          <div className="col-md-6 mb-3">
            <h5 className="page-title"> Intervention </h5>
          </div>

          <div className="col-md-6  mb-3 d-flex justify-content-end">
            {access.canCreate && (
              <button
                type="button"
                className="btn btn-outline-success"
                onClick={() => setIsOpen(true)}
              >
                Create Intervention
              </button>
            )}
          </div>
          <div className="col-md-4">
            <div className="search" style={{ margin: "16px 0px 32px" }}>
              <input
                type="text"
                className="form-control"
                placeholder="Search"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                onKeyDown={(e) => e.key === "Enter" && onSearch()}
              />
              {!isSearching ? (
                <button onClick={onSearch}>
                  <i className="fa fa-search" />
                </button>
              ) : (
                <button
                  onClick={() => {
                    setSearch("");
                    setIsSearch(false);
                    setCount((x) => x + 1);
                  }}
                >
                  <i className="fa fa-times" />
                </button>
              )}
            </div>
          </div>
          <div className="col-md-12">
            {/* {!loading ? (
              tagList.length > 0 ? (
                tagList.map((x, i) => (
                  <TagAccordion item={x} key={i} index={i} reqCount={count} />
                ))
              ) : (
                <h5 className="text-center">No Tag Found...</h5>
              )
            ) : (
              <h5>loading...</h5>
            )} */}
            <table className="table table-borderless mb-0">
              <thead>
                <tr className=" ">
                  {access.isAdmin && <td className=""></td>}
                  <td className="">Name</td>
                  <td className="">Category </td>
                  <td className="">Activity Type </td>
                  <td className="">Format</td>
                  <td className="">Status</td>
                  <td colSpan={2}>Action</td>
                </tr>
              </thead>
              <tbody>
                {!loading && interventionList.length > 0 ? (
                  sortArray(interventionList)
                    .slice(currentPage * pageSize, (currentPage + 1) * pageSize)
                    .map((x, i) => (
                      <InterventionListItem
                        key={i}
                        item={x}
                        index={i}
                        refresh={() => setCount((x) => x + 1)}
                        access={access}
                        categoryList={categoryList}
                      />
                    ))
                ) : (
                  <tr>
                    <td className="text-center" colSpan="6">
                      {loading ? "Loading..." : "No Data Found"}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
        {interventionList.length > 0 && pageCount != 1 && (
          <PaginationComponent
            handleClick={handleClick}
            currentPage={currentPage}
            pageCount={pageCount}
          />
        )}
        <InterventionModal
          isOpen={isOpen}
          setOpen={() => setIsOpen(false)}
          refresh={() => {
            setSearch("");
            setCount((x) => x + 1);
          }}
          categoryList={categoryList}
          isFirst={true}
        />
      </div>
    </main>
  );
}
export default withRoleBased(Index, "intervention");
