import { useEffect, useState } from "react";
import { GET_LOCALIZE_LIST_GQL } from "../../Apollo/AdminServices/QueryRequest";
import graphHandler from "../../Apollo/graphHandler";
function useLocalizeList(id) {
  const [localizeList, setTags] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (id) {
      getLocalizeList(id);
    } else {
      setTags([]);
    }
  }, [id]);

  const getLocalizeList = async (req = {}) => {
    // if (!screener_id) return;
    setLoading(true);
    try {
      const { data } = await graphHandler().invoke(
        GET_LOCALIZE_LIST_GQL,
        req,
        1,
        false,
        true
      );
      if (
        data &&
        data.getLocalizationDataList &&
        data.getLocalizationDataList.localization_datas &&
        data.getLocalizationDataList.localization_datas.length > 0
      ) {
        // console.log(
        //   "getLocalizeList Success",
        //   data.getLocalizationDataList.localization_datas
        // );
        setTags([]);
        setTags(data.getLocalizationDataList.localization_datas);
        setLoading(false);
      } else {
        setTags([]);
        setLoading(false);
      }
    } catch (error) {
      console.log("getLocalizeList Error", error);
      setTags([]);
      setLoading(false);
    }
  };
  return {
    localizeList,
    loading,
    getLocalizeList,
  };
}
export default useLocalizeList;
