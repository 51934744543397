import { useEffect, useState } from "react";
import { GET_TAG_HISTORIES_GQL } from "../../Apollo/AdminServices/QueryRequest";
import graphHandler from "../../Apollo/graphHandler";

function useTagHistory(id = "") {
  const [ tagHistory, setTagHistory ] = useState([]);
  const [ loading, setLoading ] = useState(false);

  useEffect(() => {
    if (id) {
      getTagHistory(id);
    } else {
      setTagHistory([]);
    }
  }, [ id ]);

  const getTagHistory = async (intervention_tag_id) => {
    if (!intervention_tag_id) return;
    setLoading(true);
    try {
      const { data } = await graphHandler().invoke(
        GET_TAG_HISTORIES_GQL,
        { intervention_tag_id },
        1
      );
      if (
        data &&
        data.getAllInterventionTagHistories &&
        data.getAllInterventionTagHistories.intervention_tag_history_list &&
        data.getAllInterventionTagHistories.intervention_tag_history_list
          .length > 0
      ) {
        // console.log(
        //   "getAllInterventionTagHistories Success",
        //   data.getAllInterventionTagHistories.intervention_tag_history_list
        // );
        setTagHistory(
          data.getAllInterventionTagHistories.intervention_tag_history_list
        );
        setLoading(false);
      } else {
        setTagHistory([]);
        setLoading(false);
      }
    } catch (error) {
      console.log("getAllInterventionTagHistories Error", error);
      setTagHistory([]);
      setLoading(false);
    }
  };
  return {
    tagHistory,
    loading,
    getTagHistory,
  };
}
export default useTagHistory;
