import { useState } from "react";
import { Popconfirm } from "antd";
import UpdateMailTemplate from "../modal/UpdateMailTemplate";
import useTemplateList from "../../../utils/CustomHooks/useTemplateList";

export default function MailItem({ item = {}, access, refresh = () => {} }) {
  const [isEdit, setEdit] = useState(false);
  const { canEdit } = access;
  const { removeTemplate } = useTemplateList();

  const onDelete = () => {
    removeTemplate({ template_id: item.template_id }, refresh);
  };


  return (
    <tr className="option-table">
      <td>{item.template_name || "-"}</td>
      <td>{item.template_code || "-"}</td>
      <td>{item.template_document ? "Yes" : "No"}</td>
      <td>
        <span
          className={`${item.active ? "good" : "bad"} text-center btn btn-sm`}
        >
          {item.active ? "Active" : "Disabled"}
        </span>
      </td>
        {canEdit && (
      <td>
          <button
            type="button"
            className="ml-2 btn btn-outline-success btn-sm "
            onClick={() => setEdit(true)}
          >
            Edit
          </button>
      </td>
        )}
      <td>
        <Popconfirm
          title="Are you sure to delete this template?"
          onConfirm={onDelete}
          //  onCancel={cancel}
          okText="Yes"
          cancelText="No"
        >
          <i
            className="ml-2 fas fa-trash cursor-pointer"
            // onClick={onDelete}
          ></i>
        </Popconfirm>
      </td>
      {canEdit && (
        <UpdateMailTemplate
          isOpen={isEdit}
          setOpen={() => setEdit(false)}
          refresh={refresh}
          values={item}
        />
      )}
    </tr>
  );
}
