import AWS from "aws-sdk";
export const S3_BUCKET = "witmer-document-dev";
// export const S3_BUCKET = "witmer-documents-dev";
export const REGION = "ap-south-1";
AWS.config.update({
  accessKeyId: "AKIAU6SNYZER26FNONR4",
  secretAccessKey: "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",
  // accessKeyId: "AKIAQPO5ZTEISH7ST467",
  // secretAccessKey: "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",
});
export const myBucket = new AWS.S3({
  params: { Bucket: S3_BUCKET },
  region: REGION,
});
