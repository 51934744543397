import { useState } from "react";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { AdminRoles } from "../../../constants";
import { useSelector } from "react-redux";
import graphHandler from "../../../Apollo/graphHandler";
import {
  CREATE_ADMIN_USER_GQL,
  CREATE_CONTENT_USER_GQL,
} from "../../../Apollo/AdminServices/MutationRequest";
import CustomButton from "../../../components/Button/CustomButton";
const validation = Yup.object().shape({
  email: Yup.string()
    .email("Must be a valid email")
    .max(255)
    .required("Email is required"),
  allowed_roles: Yup.array()
    .of(
      Yup.object().shape({
        role_types: Yup.string().required("Role type required"),
        sub_domain_id: Yup.string(),
      })
    )
    .test({
      message: "Add at least a single role",
      test: (arr) => {
        return arr.length > 0;
      },
    }),
  // .compact((v) => !v.checked)
  name: Yup.string()
    .min(4, "Name should be min of 4 characters")
    .max(30, "Name should be maximum of 30 characters")
    .required("Enter a name"),
});

export default function InviteUserModal({ isOpen, setOpen, refresh, access }) {
  const [role, setRole] = useState(initialValues);
  const subDomains = useSelector(({ DataSet }) => DataSet.subDomains);
  const canAccessDomain = role.role_types.toLowerCase().includes("content");
  const formik = useFormik({
    initialValues: {
      allowed_roles: [],
    },
    validationSchema: validation,
    onSubmit: (values) => {
      onSubmit(values);
    },
  });
  const onClose = () => {
    formik.resetForm();
    setRole(initialValues);
    setOpen();
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "role_types" && !value.toLowerCase().includes("content"))
      setRole((x) => ({ sub_domain_id: "", [name]: value }));
    else setRole((x) => ({ ...x, [name]: value }));
  };
  const addRole = () => {
    if (!role.role_types) return toast.error("Please select a role");
    if (canAccessDomain && !role.sub_domain_id)
      return toast.error("Please select a domain");
    const allowed_roles = formik.values.allowed_roles
      ? formik.values.allowed_roles
      : [];
    if (allowed_roles.filter((x) => x.role_types === "ADMIN").length > 0)
      return toast.warn("Admin can access all the data!");
    if (!role.role_types) return toast.error("Please select a role");
    const temp = role;
    if (!temp.sub_domain_id) delete temp.sub_domain_id;
    if (role.role_types === "ADMIN")
      formik.setFieldValue("allowed_roles", [role]);
    else formik.setFieldValue("allowed_roles", [...allowed_roles, role]);
    setRole(initialValues);
  };

  const onSubmit = async (create_req) => {
    let isAdmin =
      create_req.allowed_roles.filter((x) => x.role_types === "ADMIN").length >
      0;
    try {
      const { data } = await graphHandler().invoke(
        isAdmin ? CREATE_ADMIN_USER_GQL : CREATE_CONTENT_USER_GQL,
        { create_req },
        1,
        true
      );
      refresh();
      onClose();
      formik.setSubmitting(false);
    } catch (error) {
      console.log("createAdmin error", error);
      formik.setSubmitting(false);
    }
  };
  const handleError = () => {
    if (Object.keys(formik.errors).length > 0) {
      toast.error(formik.errors[Object.keys(formik.errors)[0]]);
    }
  };
  const removeRules = (i) => {
    const value = [...formik.values.allowed_roles.map((x) => x)];
    if (i > -1) {
      value.splice(i, 1); // 2nd parameter means remove one item only
    }
    // console.log(
    //   formik.values.allowed_roles,
    //   formik.values.allowed_roles.splice(ind, 1),
    //   value.splice(ind, 1)
    // );
    formik.setFieldValue("allowed_roles", value);
  };
  return (
    <Modal
      toggle={onClose}
      isOpen={isOpen}
      modalClassName="modal-fullscreen modal-dialog-scrollable"
    >
      <div className="modal-header">
        <button type="button" className="close" onClick={onClose}>
          <span aria-hidden="true">&times;</span>
        </button>
        <h5 className="modal-title" id="exampleModalLabel">
          Invite Users
        </h5>
      </div>
      <ModalBody>
        <div className="row d-flex flex-row w-100 d-flex justify-content-between">
          <div className="col-lg-3 form-group">
            <label htmlFor="user-email" className="col-form-label w-100">
              Email Addresses:
            </label>
            <input
              type="text"
              className="form-control"
              name="email"
              maxLength="30"
              value={
                formik.values && formik.values.email ? formik.values.email : ""
              }
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <small className="text-danger">
              {formik.touched.email && formik.errors && formik.errors.email
                ? formik.errors.email
                : ""}
            </small>
          </div>

          <div className="col-lg-3 form-group">
            <label htmlFor="user-email" className="col-form-label">
              Name:
            </label>
            <input
              type="text"
              className="form-control"
              name="name"
              maxLength="30"
              value={
                formik.values && formik.values.name ? formik.values.name : ""
              }
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <small className="text-danger">
              {formik.touched.name && formik.errors && formik.errors.name
                ? formik.errors.name
                : ""}
            </small>
          </div>

          <div className="col-lg-2 form-group">
            <label htmlFor="assign-roles" className="col-form-label w-100">
              Assign Role
            </label>
            <select
              name="role_types"
              className="custom-select"
              onChange={handleChange}
              value={role.role_types}
              onBlur={formik.handleBlur}
            >
              <option key="Select" hidden>
                Select
              </option>
              {AdminRoles.filter((x) =>
                access.isAdmin ? x.value !== "ADMIN" : x.value === "ADMIN"
              ).map((x, i) => (
                <option key={i} value={x.value}>
                  {x.name}
                </option>
              ))}
            </select>
            <small className="text-danger">
              {formik.touched.allowed_roles &&
              formik.errors &&
              formik.errors.allowed_roles
                ? formik.errors.allowed_roles
                : ""}
            </small>
          </div>

          {canAccessDomain ? (
            <div className="col-lg-3 form-group">
              <label htmlFor="sub_domain_id" className="col-form-label w-100">
                Sub Domain
              </label>
              <select
                id="sub_domain_id"
                name="sub_domain_id"
                className="custom-select"
                onChange={handleChange}
                value={role.sub_domain_id}
                disabled={!canAccessDomain}
              >
                <option key="Select" hidden>
                  {canAccessDomain ? "Select" : "Non Available"}
                </option>
                {subDomains.map((x, i) => (
                  <option key={i} value={x.sub_domain_id}>
                    {x.sub_domain_name}
                  </option>
                ))}
              </select>
            </div>
          ) : (
            <div className="col-lg-3 form-group" />
          )}

          <div className="col-lg-1 d-flex mb-3 w-100 align-items-end">
            <button
              className="btn btn-outline-success btn-add-role"
              role="button"
              onClick={addRole}
            >
              Add Role
            </button>
          </div>
        </div>
        <table className="table table-borderless ">
          <thead>
            <tr className=" ">
              {/* <td className="col-sm-4">
                          <label htmlFor="domain" className="col-form-label">
                            Email
                          </label>
                        </td> */}
              <td className="col-sm-3">
                <label htmlFor="Roles" className="col-form-label">
                  Roles
                </label>
              </td>
              <td className="col-sm-3">
                <label htmlFor="domain" className="col-form-label">
                  Sub Domain
                </label>
              </td>
            </tr>
          </thead>
          <tbody>
            {/* <td className="col-sm-2">zdzs</td> */}
            {formik.values.allowed_roles.map((x, i) => (
              <tr key={i}>
                <td>{x.role_types}</td>
                <td>
                  {x.sub_domain_id
                    ? subDomains.find((y) => y.sub_domain_id == x.sub_domain_id)
                        .sub_domain_name
                    : "-"}
                </td>
                <td className="cursor-pointer" onClick={() => removeRules(i)}>
                  X
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {/* <label htmlFor="Roles" className="col-form-label">
                    Roles
                  </label> */}
      </ModalBody>
      <ModalFooter className="d-flex justify-content-center">
        <button
          type="button"
          className="btn mr-3"
          data-dismiss="modal"
          onClick={onClose}
        >
          Cancel
        </button>
        <CustomButton
          type="button"
          className="btn btn-outline-success"
          disabled={
            Object.keys(formik.errors).length !== 0 ||
            !formik.isValid ||
            formik.isSubmitting
          }
          onClick={
            formik.isValid && Object.keys(formik.values).length !== 0
              ? formik.handleSubmit
              : handleError
          }
        >
          Invite User
        </CustomButton>
      </ModalFooter>
    </Modal>
  );
}

const initialValues = {
  role_types: "",
  sub_domain_id: "",
};
