import { Modal, ModalBody } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import graphHandler from "../../../Apollo/graphHandler";
import { ADD_PLATFORM_CONTENT_GQL } from "../../../Apollo/AdminServices/MutationRequest";
import CustomButton from "../../../components/Button/CustomButton";
import useCategoryList from "../../../utils/CustomHooks/useCategoryList";
import { useEffect, useState } from "react";
import useBlogList from "../../../utils/CustomHooks/useBlogList";
import useInterventionList from "../../../utils/CustomHooks/useInterventionList";
import useTherapyList from "../../../utils/CustomHooks/useTherapyList";
import useScreenerList from "../../../utils/CustomHooks/useScreenerList";
import { ContentTag, PlatformContentType } from "../../../constants";
import usePlanList from "../../../utils/CustomHooks/usePlanList";
import { Switch } from "antd";
import useDomainList from "../../../utils/CustomHooks/useDomainList";
import useSubDomainList from "../../../utils/CustomHooks/useSubDomainList";

export default function CreatePlatformContent({ isOpen, setOpen, refresh }) {
  const { categoryList, getCategoryList } = useCategoryList("");
  const { categoryList: categories, getCategoryList: getCategoriesList } =
    useCategoryList("");
  const { blogList, getBlogList } = useBlogList("");
  const { interventionList, getInterventionList } = useInterventionList("");
  const { therapyList, getTherapyList } = useTherapyList("");
  const { screenerList, getScreenerList } = useScreenerList("");
  const { planList, getPlanList } = usePlanList("");
  const { domainList, getDomainList } = useDomainList("");
  const { subDomains, getSubDomains } = useSubDomainList("");
  // const [isFeatured, setIsFeatured] = useState(false);
  const formik = useFormik({
    initialValues: {
      is_featured: false,
      is_pilot: true,
      is_live: true,
    },
    validationSchema: Yup.object().shape({
      content_type: Yup.string().required("Select content type"),
      platform_content_mode_name:Yup.string().trim(),
        // isFeatured === true
        //   ? Yup.string().required("Enter a name")
        //   : Yup.string(),
      category_id: Yup.string(),
      is_featured: Yup.boolean(),
      is_live: Yup.boolean(),
      is_pilot: Yup.boolean(),
      sort_order: Yup.string(),
      platform_content_mode_tag: Yup.string(),
      content_id: Yup.string().required("Select a content"),
    }),
    onSubmit: (values) => {
      onSubmit(values);
    },
  });
  const onClose = () => {
    formik.resetForm();
    formik.setValues({
      is_featured: false,
      is_pilot: true,
      is_live: true,
    });
    setOpen();
  };
  const onSubmit = async (values) => {
    formik.setSubmitting(true);
    const {
      content_type,
      category_id,
      content_id,
      is_featured,
      is_pilot,
      is_live,
      platform_content_mode_name,
      platform_content_mode_tag,
      sort_order
    } = values;
    if(Boolean(is_featured)=== false&&Boolean(is_pilot)=== false&&Boolean(is_live)=== false){
      toast.error("please select featured or Pilot or Live");
      formik.setSubmitting(false);
      return
    }
    let addReq = {
      content_type,
      content_id,
      is_featured: Boolean(is_featured),
      is_pilot: Boolean(is_pilot),
      is_live: Boolean(is_live),
    };

    if (category_id) addReq.category_id = category_id;
    if (sort_order) addReq.sort_order = Number(sort_order);
    if (platform_content_mode_name) {
      addReq.platform_content_mode_name = platform_content_mode_name;
    }
    if (platform_content_mode_tag) {
      addReq.platform_content_mode_tag = platform_content_mode_tag;
    }
    try {
      await graphHandler().invoke(
        ADD_PLATFORM_CONTENT_GQL,
        { addReq },
        1,
        true
      );
      refresh();
      onClose();
      formik.setSubmitting(false);
    } catch (error) {
      console.error("Create Platform", error);
      formik.setSubmitting(false);
    }
  };

  const handleError = () => {
    if (Object.keys(formik.errors).length > 0) {
      toast.error(formik.errors[Object.keys(formik.errors)[0]]);
    }
  };
  useEffect(() => {
    formik.setFieldValue("content_id", "");
    formik.setFieldValue("category_id", "");
    if (formik.values.content_type) {
      getCategoryList({
        supported_content_types: [formik.values.content_type],
      });
    }
    getContent();
  }, [formik.values.content_type]);
  const getContent = () => {
    let request = formik.values.category_id
      ? { category_ids: [formik.values.category_id], published: true }
      : { published: true };
    if (formik.values.content_type === "BLOG") {
      getBlogList({ ...request }, false);
    } else if (formik.values.content_type === "INTERVENTION") {
      getInterventionList({ ...request }, false);
    } else if (formik.values.content_type === "THERAPY") {
      getTherapyList({ ...request }, false);
    } else if (formik.values.content_type === "SCREENER") {
      getScreenerList({ ...request, screener_type: "DomainScreener" }, false);
    } else if (formik.values.content_type === "PLAN") {
      getPlanList({ ...request }, false);
    } else if (formik.values.content_type === "DOMAIN") {
      getDomainList({ ...request }, false);
    } else if (formik.values.content_type === "SUBDOMAIN") {
      getSubDomains({ ...request }, false);
    } else if (formik.values.content_type === "CATEGORY") {
      getCategoriesList({ ...request }, false);
    }
  };

  useEffect(() => {
    if(formik.values.category_id){
      getContent();
      formik.setFieldValue("content_id", "");
    }
  }, [formik.values.category_id]);
  return (
    <Modal toggle={onClose} isOpen={isOpen} centered scrollable size="xl">
      <div className="modal-header">
        <button type="button" className="close" onClick={onClose}>
          <span aria-hidden="true">&times;</span>
        </button>
        <h5 className="modal-title">Create Platform Content Mode</h5>
      </div>
      <ModalBody className="pt-0">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12 form-group  ">
              <label
                htmlFor="content_type"
                className="col-form-label w-100 mb-2"
              >
                Content Type
              </label>
              <select
                className="custom-select"
                name="content_type"
                id="content_type"
                onChange={formik.handleChange}
                value={
                  formik.values.content_type ? formik.values.content_type : ""
                }
                onBlur={formik.handleBlur}
              >
                {PlatformContentType.map((data, i) => (
                  <option key={i} value={data.value} hidden={!data.value}>
                    {data.name}
                  </option>
                ))}
              </select>
              <small className="text-danger">
                {formik.touched.content_type &&
                formik.errors &&
                formik.errors.content_type
                  ? formik.errors.content_type
                  : ""}
              </small>
            </div>
            {categoryList && categoryList.length > 0 && (
              <div className="col-md-12 form-group  ">
                <label
                  htmlFor="category_id"
                  className="col-form-label w-100 mb-2"
                >
                  Category
                </label>
                <select
                  className="custom-select"
                  name="category_id"
                  id="category_id"
                  onChange={formik.handleChange}
                  value={
                    formik.values.category_id ? formik.values.category_id : ""
                  }
                  onBlur={formik.handleBlur}
                >
                  <option value="" hidden>
                    Select
                  </option>
                  {categoryList.map((category, index) => (
                    <option key={index} value={category.category_id}>
                      {category.category_name}
                    </option>
                  ))}
                </select>
                <small className="text-danger">
                  {formik.touched.category_id &&
                  formik.errors &&
                  formik.errors.category_id
                    ? formik.errors.category_id
                    : ""}
                </small>
              </div>
            )}
            {formik.values.content_type && (
              <div className="col-md-12 form-group  ">
                <label
                  htmlFor="content_id"
                  className="col-form-label w-100 mb-2"
                >
                  Content
                </label>
                <select
                  className="custom-select"
                  name="content_id"
                  id="content_id"
                  onChange={formik.handleChange}
                  value={
                    formik.values.content_id ? formik.values.content_id : ""
                  }
                  onBlur={formik.handleBlur}
                >
                  <option key="" value="" hidden>
                    Select
                  </option>
                  {formik.values.content_type === "BLOG"
                    ? blogList.map((x, i) => (
                        <option key={i} value={x.blog_id}>
                          {x.blog_name}
                        </option>
                      ))
                    : formik.values.content_type === "INTERVENTION"
                    ? interventionList.map((x, i) => (
                        <option key={i} value={x.intervention_id}>
                          {x.intervention_name}
                        </option>
                      ))
                    : formik.values.content_type === "THERAPY"
                    ? therapyList.map((x, i) => (
                        <option key={i} value={x.therapy_id}>
                          {x.therapy_name}
                        </option>
                      ))
                    : formik.values.content_type === "PLAN"
                    ? planList.map((x, i) => (
                        <option key={i} value={x.plan_id}>
                          {x.plan_name}
                        </option>
                      ))
                    : formik.values.content_type === "DOMAIN"
                    ? domainList.map((x, i) => (
                        <option key={i} value={x.domain_id}>
                          {x.domain_name}
                        </option>
                      ))
                    : formik.values.content_type === "SUBDOMAIN"
                    ? subDomains.map((x, i) => (
                        <option key={i} value={x.sub_domain_id}>
                          {x.sub_domain_name}
                        </option>
                      ))
                    : formik.values.content_type === "CATEGORY"
                    ? categories.map((x, i) => (
                        <option key={i} value={x.category_id}>
                          {x.category_name}
                        </option>
                      ))
                    : screenerList.map((x, i) => (
                        <option key={i} value={x.screener_id}>
                          {x.screener_name}
                        </option>
                      ))}
                </select>
                <small className="text-danger">
                  {formik.touched.content_id && formik.errors.content_id
                    ? formik.errors.content_id
                    : ""}
                </small>
              </div>
            )}
            <div className="col-md-12 form-group  ">
              <label
                htmlFor="platform_content_mode_name"
                className="col-form-label w-100 mb-2"
              >
                Name
              </label>
              <input
                type="text"
                className="form-control"
                id="platform_content_mode_name"
                name="platform_content_mode_name"
                // maxLength="30"
                value={
                  formik.values && formik.values.platform_content_mode_name
                    ? formik.values.platform_content_mode_name
                    : ""
                }
                onChange={(e) =>
                  formik.setFieldValue(
                    "platform_content_mode_name",
                    e.target.value.trimStart()
                  )
                }
                onBlur={(e) => {
                  formik.setFieldTouched("platform_content_mode_name", true);
                  formik.setFieldValue(
                    "platform_content_mode_name",
                    e.target.value.replace(/\s+/g, " ").trim()
                  );
                }}
              />
              <small className="text-danger">
                {formik.touched.platform_content_mode_name &&
                formik.errors &&
                formik.errors.platform_content_mode_name
                  ? formik.errors.platform_content_mode_name
                  : ""}
              </small>
            </div>
            <div className="col-md-12 form-group">
              <label htmlFor="sort_order" className="col-form-label w-100 mb-2">
                Sort Order
              </label>
              <div className="d-flex flex-column p-0">
                <input
                  type="number"
                  className="form-control h-100 w-100"
                  placeholder=""
                  id="sort_order"
                  name="sort_order"
                  max={100}
                  value={
                    formik.values && formik.values.sort_order
                      ? formik.values.sort_order
                      : ""
                  }
                  onBlur={formik.handleBlur}
                  onChange={(e) =>
                    formik.setFieldValue(
                      "sort_order",
                      e.target.value.replace(/[^0-9\.]/g, "")
                    )
                  }
                />
                <small className="text-danger">
                  {formik.touched.sort_order && formik.errors.sort_order
                    ? formik.errors.sort_order
                    : ""}
                </small>
              </div>
            </div>
            <div className="col-md-12 form-group  ">
              <label
                htmlFor="platform_content_mode_tag"
                className="col-form-label w-100 mb-2"
              >
                Tag
              </label>
              <select
                className="custom-select"
                name="platform_content_mode_tag"
                id="platform_content_mode_tag"
                onChange={formik.handleChange}
                value={
                  formik.values.platform_content_mode_tag
                    ? formik.values.platform_content_mode_tag
                    : ""
                }
                onBlur={formik.handleBlur}
              >
                {ContentTag.map((data, i) => (
                  <option key={i} value={data.value} hidden={!data.value}>
                    {data.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-md-12">
              <div className="row ">
                <div className="col-md-4 form-group  ">
                  <label
                    htmlFor="is_featured"
                    className="col-form-label w-100 mb-2"
                  >
                    Featured
                  </label>
                  <Switch
                    id="is_featured"
                    checked={formik.values.is_featured}
                    onChange={(e) => {
                      formik.setFieldValue("is_featured", e);
                      // setIsFeatured(e);
                    }}
                  />
                </div>
                <div className="col-md-4 form-group  ">
                  <label
                    htmlFor="is_pilot"
                    className="col-form-label w-100 mb-2"
                  >
                    Pilot
                  </label>
                  <Switch
                    id="is_pilot"
                    checked={formik.values.is_pilot}
                    onChange={(e) => formik.setFieldValue("is_pilot", e)}
                  />
                </div>
                <div className="col-md-4 form-group  ">
                  <label
                    htmlFor="is_live"
                    className="col-form-label w-100 mb-2"
                  >
                    Live
                  </label>
                  <Switch
                    id="is_live"
                    checked={formik.values.is_live}
                    onChange={(e) => formik.setFieldValue("is_live", e)}
                  />
                </div>
              </div>
            </div>
            <CustomButton
              type="button"
              className="btn btn-primary btn-outline-success float-right btn-add-domain"
              disabled={
                Object.keys(formik.errors).length !== 0 ||
                !formik.isValid ||
                formik.isSubmitting
              }
              onClick={
                formik.isValid && Object.keys(formik.values).length !== 0
                  ? formik.handleSubmit
                  : handleError
              }
            >
              Create
            </CustomButton>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
}

// const validation = Yup.object().shape({
//   content_type: Yup.string().required("Select content type"),
//   category_id: Yup.string(),
//   is_featured: Yup.boolean(),
//   is_live: Yup.boolean(),
//   is_pilot: Yup.boolean(),
//   content_id: Yup.string().required("Select a content"),
// });
