import { useState } from "react";
import { GET_LANGUAGE_LIST_GQL } from "../../Apollo/AdminServices/QueryRequest";
import graphHandler from "../../Apollo/graphHandler";
function useLanguageList() {
  const [languageList, setLanguages] = useState([]);
  const [loading, setLoading] = useState(false);

  const getLanguageList = async (req = {}) => {
    // if (!screener_id) return;
    setLoading(true);
    try {
      const { data } = await graphHandler().invoke(
        GET_LANGUAGE_LIST_GQL,
        req,
        1,
        false,
        true
      );
      if (
        data &&
        data.getLanguageList &&
        data.getLanguageList.languages &&
        data.getLanguageList.languages.length > 0
      ) {
        // console.log("getLanguageList Success", data.getLanguageList.languages);
        setLanguages([]);
        setLanguages(data.getLanguageList.languages);
      } else {
        setLanguages([]);
      }
      setLoading(false);
    } catch (error) {
      console.log("getLanguageList Error", error);
      setLanguages([]);
      setLoading(false);
    }
  };
  return {
    languageList,
    loading,
    getLanguageList,
  };
}
export default useLanguageList;
