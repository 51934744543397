export const ComponentType = [
  "BANNER",
  "PARAGRAPH",
  "LEFTMEDIA",
  "RIGHTMEDIA",
  "QUOTE",
  "HEADER",
  "OTHER",
];
   









export const MediaType = ["Default", "Video", "AUDIO"];
export const BlogComponents = [
  {
    blog_content_type: "BANNER",
    content: null,
    image_urls: [
      {
        image_type: "Default",
        url: "",
        is_mobile: false,
      },
      {
        image_type: "Default",
        url: "",
        is_mobile: true,
      },
    ],
  },
  //   {
  //     blog_content_type: "HEADER",
  //     title: "LOREM IPSUM DOLOR",
  //     image_urls: null,
  //   },
  {
    blog_content_type: "LEFTMEDIA",
    title: "Lorem ipsum dolor",
    content: [
      "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit.",
    ],
    image_urls: [
      {
        image_type: "Default",
        url: "",
        is_mobile: false,
      },
      {
        image_type: "Default",
        url: "",
        is_mobile: true,
      },
    ],
  },
  {
    blog_content_type: "RIGHTMEDIA",
    title: "Lorem ipsum dolor",
    content: [
      "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit.",
    ],
    image_urls: [
      {
        image_type: "Default",
        url: "",
        is_mobile: false,
      },
      {
        image_type: "Default",
        url: "",
        is_mobile: true,
      },
    ],
  },
  {
    blog_content_type: "PARAGRAPH",
    content: [
      "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit.",
    ],
    image_urls: null,
  },
  {
    blog_content_type: "QUOTE",
    content: ["Sed ut perspiciatis"],
    image_urls: [
      {
        image_type: "Default",
        url: "",
        is_mobile: false,
      },
      {
        image_type: "Default",
        url: "",
        is_mobile: true,
      },
    ],
  },
];
