import { Popconfirm } from "antd";

export default function LevelListItem({
  item = {},
  onEdit,
  onDelete = () => {},
}) {
  return (
    <tr className="option-table">
      <td className="text-none">{item.level_id}</td>
      <td className="text-none">{item.stage_id}</td>
      <td className="text-none">
        {item.level_data
          ? JSON.stringify(
              typeof item.level_data === "object"
                ? item.level_data
                : JSON.parse(item.level_data),
              undefined,
              4
            )
          : "-"}
      </td>
      {onEdit && (
        <td className="d-flex align-items-center">
          <button
            type="button"
            className="ml-2 btn btn-outline-success btn-sm "
            onClick={() => onEdit(item)}
          >
            Edit
          </button>
          <Popconfirm
            title="Are you sure to delete this level?"
            onConfirm={() => onDelete(item.game_level_id)}
            okText="Yes"
            cancelText="No"
          >
            <i className="ml-2 fas fa-trash cursor-pointer"></i>
          </Popconfirm>
        </td>
      )}
    </tr>
  );
}
