import { useFormik } from "formik";
import { useEffect } from "react";
import * as Yup from "yup";
import { IsNullOrEmpty } from "../../utils/Functions/filter";
import usePrevious from "../../utils/Functions/usePrevious";
import ColorPicker from "../Input/ColorPicker";

export default function ColorHistoryField({
  parentForm = {},
  className = "col-md-12",
  updateValue = "",
  actualValue = "",
  id = "",
  title = "",
  disabled = false,
  validation = {},
  inputProps = {},
}) {
  const formik = useFormik({
    initialValues: {},
    validationSchema: Yup.object().shape(validation),
    onSubmit: () => {},
  });
  const previous = usePrevious(formik.errors[id]);
  useEffect(() => {
    if (updateValue && parentForm) {
      parentForm.setFieldValue(id, updateValue);
    }
  }, [updateValue]);
  useEffect(() => {
    if (formik.errors[id]) {
      parentForm.setFieldError(id, formik.errors[id] ? formik.errors[id] : "");
    } else if (previous !== formik.errors[id]) {
      let errors = { ...parentForm.errors };
      delete errors[id];
      parentForm.setErrors(errors);
    }
  }, [formik.errors]);

  useEffect(() => {
    formik.setFieldValue(
      id,
      !IsNullOrEmpty(updateValue) ? updateValue : actualValue
    );
  }, [updateValue, actualValue]);

  const onChange = (value) => {
    formik.setFieldValue(id, value);
    parentForm && parentForm.setFieldValue(id, value);
  };

  return (
    <div className={className}>
      <div className="row">
        {updateValue && (
          <div className="col-md-12 form-group">
            <label htmlFor={id} className="col-form-label w-100 mb-2">
              {title}
              <span className="badge badge-good float-right">Existing</span>
            </label>
            <ColorPicker type="text" value={actualValue} disabled />
          </div>
        )}
        <div className="col-md-12 form-group">
          <label htmlFor={id} className="col-form-label w-100 mb-2">
            {title}
            {updateValue && (
              <span className="badge badge-error float-right">New</span>
            )}
          </label>
          <ColorPicker
            type="text"
            id={id}
            name={id}
            value={formik.values[id] ? formik.values[id] : ""}
            onChange={onChange}
            onBlur={() => formik.setFieldTouched(id, true)}
            disabled={disabled}
            {...inputProps}
          />
          <small className="text-danger">
            {formik.errors[id] ? formik.errors[id] : ""}
          </small>
        </div>
      </div>
    </div>
  );
}
