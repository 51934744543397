import { useEffect, useState } from "react";
import PaginationComponent from "../../components/PaginationComponent";
import "./questionaire.css";
import withRoleBased from "../../utils/Functions/withRoleBased";
import CreateRule from "./modal/CreateRule";
import RuleListItem from "./components/RuleListItem";
import { useDispatch } from "react-redux";
import { rGetRuleEngine } from "../../redux/DataSets/action";
import useRuleEngineList from "../../utils/CustomHooks/useRuleEngineList";

function RuleEngine({ access = {} }) {
  const { ruleList, loading, getRuleEngineList } = useRuleEngineList();
  const [count, setCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [pageSize] = useState(10);
  const pageCount = Math.ceil(ruleList.length / pageSize);
  const dispatch = useDispatch();
  const handleClick = (event, index) => {
    event.preventDefault();
    setCurrentPage(index);
  };

  useEffect(() => {
    getRuleEngineList({});
  }, [count]);

  useEffect(() => {
    dispatch(rGetRuleEngine());
  }, []);

  return (
    <main className="page-content">
      <div className="container-fluid">
        <div className="row ">
          <div className="col-md-6 mb-3">
            <h5 className="page-title">Rule Engine</h5>
          </div>
          <div className="col-md-6  mb-3 d-flex justify-content-end">
            {access.canCreate && (
              <button
                type="button"
                className="btn btn-outline-success "
                onClick={() => setIsOpen(true)}
              >
                Create
              </button>
            )}
          </div>
          <div className="col-md-12">
            <div className="table-responsive ">
              <table className="table domain-table  table-borderless ">
                <thead>
                  <tr className=" ">
                    {access.isAdmin && <td className=""></td>}
                    <td className="">Platform type</td>
                    <td className="">Input type</td>
                    <td className="">Output type</td>
                    <td className="">Status</td>
                    {access.canCreate && <td className="">Action</td>}
                  </tr>
                </thead>
                <tbody>
                  {!loading && ruleList.length > 0 ? (
                    ruleList
                      .slice(
                        currentPage * pageSize,
                        (currentPage + 1) * pageSize
                      )
                      .map((x, i) => (
                        <RuleListItem
                          item={x}
                          key={i}
                          index={i}
                          access={access}
                          refresh={() => {
                            setCount((x) => x + 1);
                          }}
                        />
                      ))
                  ) : (
                    <tr>
                      <td className="text-center font-weight-bold" colSpan="7">
                        {loading ? "Loading..." : "No Data Found"}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {ruleList.length > 0 && pageCount != 1 && (
          <PaginationComponent
            handleClick={handleClick}
            currentPage={currentPage}
            pageCount={pageCount}
          />
        )}
        {access.canCreate && (
          <CreateRule
            isOpen={isOpen}
            setOpen={() => setIsOpen(false)}
            refresh={() => setCount((x) => x + 1)}
          />
        )}
      </div>
    </main>
  );
}
export default withRoleBased(RuleEngine, "tag");
