import { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "react-toastify";
import { Popover } from "reactstrap";
import { Switch } from "antd";
import ApproveModal from "../../../components/Modal/ApproveModal";
import JsonCompareModal from "../../../components/Modal/JsonCompareModal";
import PlanModal from "../modal/PlanModal";
import usePlanAPI from "../../../utils/CustomHooks/usePlanAPI";
import useForcePublish from "../../../utils/CustomHooks/useForcePublish";

export default function PlanListItem({
  item = {},
  index,
  refresh = () => {},
  access = {},
  categoryList = [],
  interventionList = [],
  screenerList = [],
}) {
  const [isEdit, setEdit] = useState(false);
  const [isView, setView] = useState(false);
  const [showPop, setShowPop] = useState(false);
  const toggle = () => setShowPop((x) => !x);
  const [isOpenApprove, setOpenApprove] = useState(false);
  const [isApprove, setApprove] = useState(true);
  const isDone = true;
  const { forcePublishPlan } = useForcePublish();
  const { approvePlan, reviewPlan, activatePlan } = usePlanAPI("");
  const { pending_history } = item;
  // const history = useHistory();
  const subDomains = useSelector(({ DataSet }) => DataSet.subDomains);
  const hasHistory = pending_history && pending_history.update_status;
  const { canEdit, canApprove, canReview, isAdmin } = access;
  const readyApprove =
    pending_history &&
    ((pending_history.update_status == "ReviewSuccess" && canApprove) ||
      (pending_history.update_status == "Draft" && canReview));

  const renderStatus = (status) => {
    if (
      hasHistory &&
      status !== "Rejected" &&
      status !== "Draft" &&
      status !== "Reviewfailed"
    ) {
      return <span className="good text-center btn btn-sm">{status}</span>;
    } else if (!hasHistory && item.active && item.published) {
      return <span className="good text-center btn btn-sm">Published</span>;
    } else if (!hasHistory && !item.active && item.published) {
      return <span className="bad text-center btn btn-sm">Disabled</span>;
    } else {
      return (
        <span className="bad text-center btn btn-sm">
          {status || "Rejected"}
        </span>
      );
    }
  };

  const activateItem = async (active) => {
    if (!item.plan_id) return;
    toggle();
    const request = {
      active_request: {
        active,
        plan_id: item.plan_id,
      },
    };
    activatePlan(request, onActivate(active));
  };

  const onActivate = (active) => {
    setTimeout(() => {
      toast.success(
        `${item.plan_name} ${active ? "Activated" : "Deactivated"} Successfully`
      );
      refresh();
    }, 1000);
  };

  const onDone = (value) => {
    if (!hasHistory) return;
    if (pending_history.update_status == "Draft") {
      const request = {
        review_request: { ...value, approve: isApprove },
        plan_history_id: pending_history.plan_history_id,
      };
      reviewPlan(request, refresh);
    } else if (pending_history.update_status == "ReviewSuccess") {
      const request = {
        approve_request: { ...value, approve: isApprove },
        plan_history_id: pending_history.plan_history_id,
      };
      approvePlan(request, refresh);
    }
  };
  const getFilteredObject = (obj) => {
    const temp = { ...obj };
    delete temp.created_at;
    delete temp.created_by;
    delete temp.updated_at;
    delete temp.updated_by;
    delete temp.sub_domain_ids;
    delete temp.therapy_id;
    delete temp.pending_history;
    return temp;
  };
  return (
    <tr className="option-table">
      {isAdmin && (
        <td>
          <Switch checked={item.active} onChange={activateItem} />
        </td>
      )}
      <td>{item.plan_name}</td>
      <td>
        {item.category_ids &&
        item.category_ids.length > 0 &&
        categoryList.length > 0
          ? item.category_ids
              .map((x) => {
                let value = categoryList.find((y) => y.category_id === x);
                return value ? value.category_name : x;
              })
              .join(", ")
          : "-"}
      </td>
      <td>
        {item.sub_domain_ids &&
        item.sub_domain_ids.length > 0 &&
        subDomains.length > 0
          ? item.sub_domain_ids
              .map((x) => {
                let value = subDomains.find((y) => y.sub_domain_id === x);
                return value ? value.sub_domain_name : x;
              })
              .join(", ")
          : "-"}
      </td>
      <td>
        {renderStatus(pending_history ? pending_history.update_status : "")}
      </td>
      <td className="d-flex">
        {/* {hasHistory && readyApprove ? (
          <>
            <button
              type="button"
              className="btn btn-outline-success btn-sm "
              onClick={() => {
                setOpenApprove(true);
                setApprove(true);
              }}
            >
              {pending_history.update_status == "ReviewSuccess"
                ? "Approve"
                : "Review"}
            </button>
            <button
              type="button"
              className="ml-2 btn btn-danger btn-sm "
              onClick={() => {
                setOpenApprove(true);
                setApprove(false);
              }}
            >
              Reject
            </button>
          </>
        ) : null}
        {!hasHistory && canEdit && (
          <button
            type="button"
            className="ml-2 btn btn-outline-success btn-sm "
            onClick={() => setEdit(true)}
          >
            Edit
          </button>
        )} */}
         {isDone && hasHistory && readyApprove ? (
          <>
            <button
              type="button"
              className="btn btn-outline-success btn-sm "
              onClick={() => {
                setOpenApprove(true);
                setApprove(true);
              }}
            >
              {pending_history.update_status == "ReviewSuccess"
                ? "Approve"
                : "Review"}
            </button>
            <button
              type="button"
              className="ml-2 btn btn-danger btn-sm "
              onClick={() => {
                setOpenApprove(true);
                setApprove(false);
              }}
            >
              Reject
            </button>
          </>
        ) : (
          <button
            type="button"
            className="ml-2 btn btn-outline-success btn-sm "
            onClick={() => setEdit(true)}
          >
            {canEdit ? "Edit" : "View"}
          </button>
        )}
         {!hasHistory && isAdmin && item.published ? (
          <button
            type="button"
            className="ml-2 btn btn-outline-success btn-sm "
            onClick={() =>
              forcePublishPlan(
                {
                  content_id: item.plan_id,
                  content_type: "PLAN",
                },
                refresh
              )
            }
          >
            Force Publish
          </button>
        ) : null}
      </td>

      <td className="accordion-menu" id={`AccordionTableMenu${index}`}>
        <i className="fas fa-ellipsis-v" />
      </td>
      <Popover
        placement="left"
        target={`AccordionTableMenu${index}`}
        trigger="legacy"
        isOpen={showPop}
        toggle={toggle}
      >
        <div className="accordion-menu-header">Options</div>
        {/* <div
          className="accordion-menu-item"
          onClick={() =>
            history.push(`/view-history?type=therapy&id=${data.therapy_id}`)
          }
        >
          View History
        </div> */}
        <div
          className="accordion-menu-item"
          onClick={() => {
            toggle();
            setEdit(true);
          }}
        >
          {isDone&&!canEdit ? "View" : "Edit"}
        </div>
        {/* <div
          className="accordion-menu-item"
          onClick={() =>
            history.push(`/view-history?type=package&id=${item.package_id}`)
          }
        >
          View History
        </div> */}
        {/* {!hasHistory && item.published && (
          <div
            className="accordion-menu-item"
            onClick={() => activateItem(!item.active)}
          >
            {!item.active ? "Activate" : "Deactivate"}
          </div>
        )} */}
        {hasHistory && (
          <div
            className="accordion-menu-item"
            onClick={() => {
              toggle();
              setView(true);
            }}
          >
            Compare
          </div>
        )}
      </Popover>
      {readyApprove && (
        <ApproveModal
          setOpen={() => setOpenApprove(false)}
          isOpen={isOpenApprove}
          isApprove={isApprove}
          title={
            isApprove
              ? pending_history.update_status == "ReviewSuccess"
                ? "Approve"
                : "Review"
              : "Reject"
          }
          refresh={refresh}
          onDone={onDone}
          isRequire={true}
          isShowDate={
            pending_history && pending_history.update_status == "ReviewSuccess"
          }
        />
      )}
      {hasHistory && (
        <JsonCompareModal
          setOpen={() => setView(false)}
          isOpen={isView}
          title={"Plan Compare"}
          actualDate={getFilteredObject(item)}
          newDate={getFilteredObject(pending_history.data)}
        />
      )}

      <PlanModal
        isOpen={isEdit}
        setOpen={() => setEdit(false)}
        refresh={() => {
          refresh((x) => x + 1);
        }}
        initialValues={item}
        categoryList={categoryList}
        interventionList={interventionList}
        screenerList={screenerList}
        isEditable={canEdit}
      />
    </tr>
  );
}
