import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import QuestionTopicAccordion from "../Components/QuestionTopicAccordion";
import CreateQuestionSection from "./CreateQuestionSection";
import UpdateQuestionSection from "./UpdateQuestionSection";
import { Popconfirm } from "antd";

export default function QuestionSection(props) {
  let {
    values = {},
    topicList = [],
    refreshQues = () => {},
    refresh = () => {},
    onClose = () => {},
    onDone = () => {},
    handleDiscord = () => {},
    onBack = () => {},
    isEditable,
  } = props;
  const { published } = values;
  const [question, setQuestion] = useState({});
  const subDomains = useSelector(({ DataSet }) => DataSet.subDomains);
  const [reqCount, setReqCount] = useState(0);
  const screener_history = question.pending_history
    ? question.pending_history
    : {};
  const hasUpdated = Boolean(screener_history && screener_history.updated_data);
  const [history, setHistory] = useState(false);

  useEffect(() => {
    refreshQues();
  }, [reqCount]);

  const ActionComponent =
    question.screener_question_id && !question.duplicate
      ? UpdateQuestionSection
      : CreateQuestionSection;

  const onEdit = (data) => {
    let view = document.getElementById("updateQuestion");
    if (view) view.scrollIntoView({ behavior: "smooth" });
    setQuestion(data);
  };
  return (
    <div className="row justify-content-center ">
      <div className="col-md-12">
        <div className="table-responsive border p-2">
          {topicList.length > 0 ? (
            topicList
              .slice()
              .filter((x) => x?.active === true)
              .sort((a, b) => a.sort_order - b.sort_order)
              .map((x, i) => (
                <QuestionTopicAccordion
                  item={x}
                  key={i}
                  index={i}
                  reqCount={reqCount}
                  canDelete={!published}
                  refresh={refresh}
                  refreshQues={refreshQues}
                  isEditable={isEditable}
                  onEdit={onEdit}
                  setHistory={setHistory}
                />
              ))
          ) : (
            <h5 className="text-center">No Topics Found...</h5>
          )}
        </div>
      </div>
      {isEditable && (
        <ActionComponent
          {...props}
          refresh={() => setReqCount((x) => x + 1)}
          question={question}
          reset={() => setQuestion({})}
          subDomains={subDomains}
          topicList={topicList.filter((x) => x?.active === true)}
        />
      )}
      <div className="d-flex align-items-end justify-content-center col-md-12 p-5">
        {isEditable && (hasUpdated || history) && (
          <Popconfirm
            title="Are you sure to discard and close?"
            onConfirm={handleDiscord}
            //  onCancel={cancel}
            okText="Yes"
            cancelText="No"
          >
            <button
              type="button"
              className="btn btn-danger btn-sm float-right btn-add-domain mr-2"
            >
              Discard All & Close
            </button>
          </Popconfirm>
        )}
        <button
          onClick={onClose}
          type="button"
          className="btn btn-danger btn-sm float-right btn-add-domain mr-2"
        >
          Close
        </button>{" "}
        <button
          onClick={onBack}
          type="button"
          className="btn btn-error btn-sm float-right btn-add-domain mr-2"
        >
          Back
        </button>
        <button
          onClick={onDone}
          type="button"
          className="btn btn-secondary btn-sm float-right btn-add-domain mr-2"
        >
          Next
        </button>
      </div>
    </div>
  );
}
