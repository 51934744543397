import { Component } from "react";

export default class CustomButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disable: false,
    };
    this.onClick = this.onClick.bind(this);
  }
  componentDidMount() {
    if (this.props.disabled) {
      this.setState({ disable: this.props.disabled });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.disabled && nextProps.disabled !== this.props.disabled) {
      this.setState({ disable: nextProps.disabled });
    }
  }
  onClick() {
    this.setState({ disable: true });
    setTimeout(() => {
      this.setState({ disable: false });
    }, 1000);
    if (this.props.onClick) {
      this.props.onClick();
    }
  }
  render() {
    const {
      type = "button",
      className = "btn btn-pay-now btn-sm",
      children,
      disabled,
    } = this.props;
    const { disable } = this.state;
    return (
      <button
        type={type}
        className={className}
        onClick={this.onClick}
        disabled={disabled || disable}
      >
        {children}
      </button>
    );
  }
}
