import { useMemo, useState } from "react";
import { SketchPicker } from "react-color";
import { PresentColor } from "../../constants";

export default function ColorPicker({
  value = "",
  onChange = () => {},
  onBlur = () => {},
  id = "",
  name = "",
  disabled,
}) {
  const [current, setCurrent] = useState(value);
  const [isOpen, setOpen] = useState(false);
  useMemo(() => {
    if (value !== current) setCurrent(value ? value : "");
  }, [value]);
  const toggle = () => {
    onBlur();
    !disabled && setOpen((x) => !x);
  };
  const _onChange = (value) => {
    if (disabled) return;
    onChange(value);
    setCurrent(value);
  };
  return (
    <div>
      <div className="form-control p-2 cursor-pointer" onClick={toggle}>
        <input
          type="text"
          disabled
          value={current ? "" : "Pick here"}
          style={{
            borderRadius: "2px",
            border: "none",
            background: current || "transparent",
          }}
          className="w-100 h-100"
          id={id}
          name={name}
        />
      </div>
      {isOpen && !disabled ? (
        <div
          style={{
            position: "absolute",
            zIndex: "2",
          }}
        >
          <div
            style={{
              position: "fixed",
              top: "0px",
              right: "0px",
              bottom: "0px",
              left: "0px",
            }}
            onClick={toggle}
          />
          <SketchPicker
            presetColors={PresentColor}
            color={current}
            onChange={({ hex }) => _onChange(hex)}
          />
        </div>
      ) : null}
    </div>
  );
}
