import { Fragment } from "react";
import MainLayout from "./MainLayout";
import { withRouter } from "react-router-dom";
// import CustomLoader from "../components/Loader";

let previousPath = "";

function Layout({ children, location: { pathname, search } }) {
  const currentPath = pathname + search;
  if (currentPath !== previousPath) {
    window.scrollTo(0, 0);
    try {
      let cont = document.getElementsByClassName("page-wrapper");
      if (cont && cont[0]) cont[0].scrollTo(0, 0);
    } catch (error) {
      console.log("Error while scroll");
    }
  }
  setTimeout(() => {
    previousPath = currentPath;
  }, 300);

  const CurrentLayout = () => {
    // if (isUserLoading) {
    //   return <CustomLoader />;
    // }

    if (
      pathname.includes("login") ||
      pathname.includes("signup") ||
      pathname.includes("404")
    ) {
      return children;
    }
    // return <Loading />;

    // return children;
    return <MainLayout>{children}</MainLayout>;
  };
  return <Fragment>{CurrentLayout()}</Fragment>;
}

export default withRouter(Layout);
