import MarkdownIt from "markdown-it";
const mdParser = new MarkdownIt(/* Markdown-it options */);

export default function MetaTableItem({
  index,
  item,
  titleKey = "meta_data_title",
  valueKey = "meta_data_value",
  typeKey = "meta_type_value",
  remove = () => {},
  onEdit = () => {},
  disabled,
  metaType="",
  canEdit=true,
}) {
  return (
    <tr className="">
      {metaType==="Subdomain" && <td className="text-none">{item[typeKey]}</td>}
      <td className="text-none">{item[titleKey]}</td>
      <td className="text-none">
        <div
          className=" custom-html-style"
          dangerouslySetInnerHTML={{
            __html: mdParser.render(item[valueKey]),
          }}
        ></div>
      </td>
      <td className="d-flex flex-row">
      {canEdit&&(
        <>
        <i
          className="fas fa-edit cursor-pointer"
          onClick={() => {
            !disabled && onEdit({ ...item, index });
          }}
        ></i>
        <i
          className="ml-2 fas fa-trash cursor-pointer"
          onClick={() => !disabled && remove(index)}
        ></i>
        </>
      )}
      </td>
    </tr>
  );
}
