import React, { useState } from "react";
import ActionModal from "./ActionModal";

export default function ActionListItem({
  item,
  refresh = () => {},
  interventionList = [],
  screenerList = [],
  removeActions = () => {},
  actionList = [],
}) {
  const [isOpen, setOpen] = useState(false);
  const getData = (type) => {
    if (type === "Intervention") {
      return interventionList.length > 0
        ? interventionList.find(
            (x) => x.intervention_id === item.intervention_id
          ).intervention_name
        : type;
    } else if (type === "Document") {
      return item.therapy_module_document_url
        ? item.therapy_module_document_url.name
        : type;
    } else if (type === "Video") {
      return item.therapy_module_video_url
        ? item.therapy_module_video_url.video_type
        : type;
    } else if (type === "Screener") {
      return screenerList.length > 0 &&
        screenerList.find((x) => x.screener_id === item.screener_id)
        ? screenerList.find((x) => x.screener_id === item.screener_id)
            .screener_name
        : type;
    } else {
      return type;
    }
  };
  return (
    <tr>
      <td className="text-center">{item.therapy_module_action_name || "-"}</td>
      <td className="text-center">{item.therapy_action_type || "-"}</td>
      <td className="text-center">{getData(item.therapy_action_type)}</td>
      <td className="text-center">{item.sort_order}</td>
      <td className="text-center">
        <i
          className="fas fa-edit cursor-pointer"
          onClick={() => setOpen(true)}
        />
        <i
          className="ml-2 fas fa-trash cursor-pointer"
          onClick={() =>
            removeActions(item.therapy_module_action_id, refresh())
          }
        ></i>
      </td>
      <ActionModal
        isOpen={isOpen}
        setOpen={setOpen}
        refresh={refresh}
        therapy_module_id={item.therapy_module_id}
        therapy_id={item.therapy_id}
        interventionList={interventionList}
        screenerList={screenerList}
        defaultValues={item}
        actionList={actionList}
      />
    </tr>
  );
}
