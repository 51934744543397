import React from "react";

export default function QuestionOptions({ item }) {
  const canShowOptionsFn = (value) =>
    value &&
    value != "TextField" &&
    value != "DocumentUpload" &&
    value != "ImageCapture" &&
    value != "Date" &&
    value != "Rating" &&
    value != "TextArea";
  return (
    <>
      {canShowOptionsFn(item.screener_question_type) &&
        item.options.options &&
        item.options.options.length > 0 &&
        item.options.options.map((a, i) => (
          //   <p className="card-subtitle mb-2 text-muted" key={i}>
          <div className="form-check form-check-inline" key={i}>
            <input
              className="form-check-input"
              type="radio"
              name={a.data}
              id={a.data}
              value={a.data}
              //   checked={false}
            />
            <label className="form-check-label" htmlFor={a.data}>
              {a.data}
            </label>
          </div>
          //   </p>
        ))}
    </>
  );
}
