import { useState } from "react";
// import { toast } from "react-toastify";
// import { Popover } from "reactstrap";
// import { ACTIVATE_ORGANIZATION_EMPLOYEE_GQL } from "../../../Apollo/ApplicationService/OrganizationResponse";
// import graphHandler from "../../../Apollo/graphHandler";
import OrganizationEmployeeModal from "../modal/OrganizationEmployeeModal";

export default function ListItem({
  item = {},
  i,
  refresh = () => {},
  access = {},
}) {
  const [isEdit, setEdit] = useState(false);
  // console.log("organization_employee_status",item.organization_employee_status)
  // const [showPop, setShowPop] = useState(false);
  // const toggle = () => setShowPop((x) => !x);

  // const activateOrganization = async (activate) => {
  //   toggle();
  //   let active_request = {
  //     activate,
  //     user_id: item.organization_employee.user_id,
  //   };
  //   try {
  //     await graphHandler().invoke(
  //       ACTIVATE_ORGANIZATION_EMPLOYEE_GQL,
  //       { active_request },
  //       0,
  //       false,
  //       true
  //     );
  //     toast.success(
  //       `${item.organization_employee.organization_employee_name} ${activate ? "Activated" : "Deactivated"} Successfully`
  //     );
  //     refresh();
  //   } catch (error) {
  //     console.log("activateUser error", error);
  //   }
  // };
  return (
    <tr className="option-table">
      <td>{item.organization_employee.organization_employee_name || "-"}</td>
      <td>{item.organization_employee.organization_employee_email || "-"}</td>
      <td>
        <span
          className={`${item.organization_employee.organization_employee_status === "Active"? "good" : "bad"} text-center btn btn-sm`}
        >
          {item.organization_employee.organization_employee_status}
        </span>
      </td>
      {access.canEdit && (
        <td>
          <button
            type="button"
            className="btn btn-outline-success btn-sm "
            onClick={() => setEdit(true)}
          >
            Edit
          </button>
        </td>
      )}
      {/* <td
        className="accordion-menu"
        id={`AccordionTableMenu${i}`}
        onClick={toggle}
      >
        <i className="fas fa-ellipsis-v" />
      </td>
      <Popover
        isOpen={showPop}
        toggle={toggle}
        placement="left"
        target={`AccordionTableMenu${i}`}
        trigger="legacy"
      >
        <div className="accordion-menu-header">Options</div>

        <div
          className="accordion-menu-item"
          onClick={() => activateOrganization(!item.organization_employee.organization_employee_status)}
        >
          {!item.organization_employee.organization_employee_status ? "Activate" : "Deactivate"}
        </div>
      </Popover> */}
      {access.canEdit && (
        <OrganizationEmployeeModal
          isOpen={isEdit}
          setOpen={() => setEdit(false)}
          refresh={refresh}
          values={item.organization_employee}
        />
      )}
    </tr>
  );
}
