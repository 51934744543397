import { useEffect, useState } from "react";
import { GET_USER_ADMIN_ROLES_GQL } from "../../Apollo/AdminServices/QueryRequest";
import graphHandler from "../../Apollo/graphHandler";

function useUserAdminRoles(id) {
  const [roles, setRoles] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (id) {
      getAllAdminRoles(id);
    } else {
      setRoles([]);
    }
  }, [id]);

  const getAllAdminRoles = async (user_id) => {
    setLoading(true);
    try {
      const { data } = await graphHandler().invoke(
        GET_USER_ADMIN_ROLES_GQL,
        { user_id },
        1,
        false,
        true
      );
      if (
        data &&
        data.getAllAdminRoles &&
        data.getAllAdminRoles.roles &&
        data.getAllAdminRoles.roles.length > 0
      ) {
        // console.log("getAllAdminRoles Success", data.getAllAdminRoles);
        setRoles([]);
        setRoles(data.getAllAdminRoles.roles);
        setLoading(false);
      } else {
        setRoles([]);
        setLoading(false);
      }
    } catch (error) {
      console.log("getAllAdminRoles Error", error);
      setRoles([]);
      setLoading(false);
    }
  };

  return { roles, roleLoading: loading, getAllAdminRoles };
}

export default useUserAdminRoles;
