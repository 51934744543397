import { toast } from "react-toastify";
import { isInputNumber, IsNullOrEmpty } from "../../../utils/Functions/filter";
import * as Yup from "yup";
import { useFormik } from "formik";
import Description from "../../../components/Input/Description";
import MetaInput from "../../../components/MetaInput";
import CustomButton from "../../../components/Button/CustomButton";
import graphHandler from "../../../Apollo/graphHandler";
import { CREATE_SCREENER_RESULT_RANGE_GQL } from "../../../Apollo/AdminServices/MutationRequest";
import ColorPicker from "../../../components/Input/ColorPicker";
import { resultRangeGameNameKey } from "../../../constants";
import { useEffect, useState } from "react";

export default function CreateResultSection({
  screener = {},
  disabled,
  subDomains = [],
  topicList = [],
  domainList = [],
  refresh = () => {},
  setLoading = () => {},
}) {
  const { screener_id,screener_format } = screener;
  const [isGame, setIsGame] = useState(false);
  const formik = useFormik({
    initialValues: {},
    validationSchema: isGame ? gameValidation : validation,
    onSubmit: (values) => {
      onSubmit(values);
    },
  });
  useEffect(() => {
    setIsGame(
      formik.values.screener_result_range_entity_type === "GameNameKey"
    );
    formik.validateForm();
  }, [formik.values.screener_result_range_entity_type]);

  const handleError = () => {
    if (Object.keys(formik.errors).length > 0) {
      toast.error(formik.errors[Object.keys(formik.errors)[0]]);
    }
  };
  const onSubmit = async (modal) => {
    formik.setSubmitting(true);
    const {
      screener_result_range_color,
      screener_result_range_entity_type,
      entity_ref_id,
      screener_result_range_meta,
      screener_result_range_name,
      screener_result_range_min,
      screener_result_range_max,
      description,
      mobile_description,
      game_name_key,
      screener_result_range_report_description,
      // mobile_report_description,
      screener_result_range_type_name,
    } = modal;
    let create_request = {
      screener_id,
      screener_result_range_color,
      screener_result_range_entity_type,
      // entity_ref_id,
      // game_name_key,
      screener_result_range_meta,
      screener_result_range_name,
      screener_result_range_min: Number(screener_result_range_min),
      screener_result_range_max: Number(screener_result_range_max),
    };
    if (screener_result_range_type_name) {
      create_request.screener_result_range_type_name =
        screener_result_range_type_name;
    }
    if (screener_result_range_entity_type === "GameNameKey") {
      create_request.game_name_key = game_name_key;
    }
    if (screener_result_range_entity_type !== "GameNameKey") {
      create_request.entity_ref_id = entity_ref_id;
    }
    let screener_result_range_descriptions = [];
    //let screener_result_range_report_descriptions = [];
    if (description) {
      screener_result_range_descriptions.push({
        client_type: "Web",
        description: description,
      });
    }
    if (mobile_description) {
      screener_result_range_descriptions.push({
        client_type: "Mobile",
        description: mobile_description,
      });
    }
    if (screener_result_range_descriptions.length > 0) {
      create_request.screener_result_range_descriptions =
        screener_result_range_descriptions;
    }
    // if (report_description) {
    //   screener_result_range_report_descriptions.push({
    //     client_type: "Web",
    //     description: report_description,
    //   });
    // }
    // if (mobile_report_description) {
    //   screener_result_range_report_descriptions.push({
    //     client_type: "Mobile",
    //     description: mobile_report_description,
    //   });
    // }
    if (screener_result_range_report_description) {
      create_request.screener_result_range_report_description =
        screener_result_range_report_description;
    }
    try {
      const { data } = await graphHandler().invoke(
        CREATE_SCREENER_RESULT_RANGE_GQL,
        { create_request },
        1,
        false,
        true
      );
      if (
        data &&
        data.createScreenerResultRange &&
        data.createScreenerResultRange.screener_result_range
      ) {
        // console.log(
        //   "createScreenerTopic Success",
        //   data.createScreenerResultRange.screener_result_range
        // );
        refresh();
        setLoading(false);
        toast.success("Created Successfully");
        formik.setSubmitting(false);
        resetModal();
      }
    } catch (error) {
      console.error("createScreenerResultRange Error", error);
      setLoading(false);
      formik.setSubmitting(false);
    }
  };

  const handleTypeChange = (e) => {
    const { name, value } = e.target;
    formik.setFieldValue(name, value);
    formik.setFieldValue(
      "entity_ref_id",
      value === "Screener" ? screener_id : ""
    );
  };
  const resetModal = () => {
    formik.resetForm();
    formik.setValues({});
    formik.setSubmitting(false);
  };
  return (
    <>
      <div className="col-md-12 modal-header text-center align-items-center">
        <h5 className="modal-title text-center">Create Interpretation</h5>
      </div>
      <div className="col-md-12">
        <div className="border">
          <div className="row p-3">
            <div className="col-md-4 form-group">
              <label
                htmlFor="screener_result_range_entity_type"
                className="col-form-label w-100 mb-2"
              >
                Type
              </label>
              <select
                className="custom-select"
                name="screener_result_range_entity_type"
                id="screener_result_range_entity_type"
                onChange={handleTypeChange}
                value={
                  formik.values.screener_result_range_entity_type
                    ? formik.values.screener_result_range_entity_type
                    : ""
                }
                onBlur={formik.handleBlur}
                disabled={disabled}
              >
                <option key="Select" hidden value="">
                  Select
                </option>
                <option value="Domain">Domain</option>
                <option value="SubDomain">Sub Domain</option>
                <option value="Topic">Topic</option>
                <option value="Screener">Screener</option>
                {screener_format ==="Game"&&<option value="GameNameKey">Game Name Key</option>}
              </select>
              <small className="text-danger col-md-12">
                {formik.touched.screener_result_range_entity_type &&
                formik.errors.screener_result_range_entity_type
                  ? formik.errors.screener_result_range_entity_type
                  : ""}
              </small>
            </div>
            {formik.values.screener_result_range_entity_type ==
            "GameNameKey" ? (
              <div className="col-md-4 form-group ">
                <label
                  htmlFor="game_name_key"
                  className="col-form-label w-100 mb-2"
                >
                  Game Name Key
                </label>
                <select
                  className="custom-select"
                  name="game_name_key"
                  id="game_name_key"
                  onChange={formik.handleChange}
                  value={formik.values.game_name_key}
                  onBlur={formik.handleBlur}
                  disabled={disabled}
                >
                  <option key="Select" hidden value="">
                    Select
                  </option>

                  {resultRangeGameNameKey.map((item, index) => (
                    <option key={"Game" + index} value={item.value}>
                      {item.label}
                    </option>
                  ))}
                </select>
                <small className="text-danger col-md-12">
                  {formik.touched.game_name_key && formik.errors.game_name_key
                    ? formik.errors.game_name_key
                    : ""}
                </small>
              </div>
            ) : formik.values.screener_result_range_entity_type !==
              "Screener" ? (
              <div className="col-md-4 form-group ">
                <label
                  htmlFor="entity_ref_id"
                  className="col-form-label w-100 mb-2"
                >
                  {formik.values.screener_result_range_entity_type ===
                  "SubDomain"
                    ? "Sub Domain"
                    : formik.values.screener_result_range_entity_type ||
                      "Reference"}
                </label>
                <select
                  className="custom-select"
                  name="entity_ref_id"
                  id="entity_ref_id"
                  onChange={formik.handleChange}
                  value={formik.values.entity_ref_id}
                  onBlur={formik.handleBlur}
                  disabled={disabled}
                >
                  <option key="Select" hidden value="">
                    Select
                  </option>

                  {!formik.values.screener_result_range_entity_type
                    ? null
                    : formik.values.screener_result_range_entity_type ===
                      "SubDomain"
                    ? subDomains.map((item, index) => (
                        <option
                          key={"SubDomain" + index}
                          value={item.sub_domain_id}
                        >
                          {item.sub_domain_name}
                        </option>
                      ))
                    : formik.values.screener_result_range_entity_type ===
                      "Domain"
                    ? domainList.map((item, index) => (
                        <option value={item.domain_id} key={"domain" + index}>
                          {item.domain_name}
                        </option>
                      ))
                    : topicList.map((item, index) => (
                        <option
                          key={"Topic" + index}
                          value={item.screener_topic_id}
                        >
                          {item.screener_topic_title}
                        </option>
                      ))}
                </select>
                <small className="text-danger col-md-12">
                  {formik.touched.entity_ref_id &&
                  formik.errors.entity_ref_id &&
                  formik.values.screener_result_range_entity_type
                    ? formik.errors.entity_ref_id +
                      (formik.values.screener_result_range_entity_type ===
                      "SubDomain"
                        ? "sub domain"
                        : formik.values.screener_result_range_entity_type.toLowerCase() ||
                          "Reference")
                    : ""}
                </small>
              </div>
            ) : null}
            <div className="col-md-4 form-group ">
              <label
                htmlFor="screener_result_range_color"
                className="col-form-label w-100 mb-2"
              >
                Color
              </label>
              <ColorPicker
                id="screener_result_range_color"
                name="screener_result_range_color"
                onBlur={() =>
                  formik.setFieldTouched("screener_result_range_color", true)
                }
                value={
                  formik.values.screener_result_range_color
                    ? formik.values.screener_result_range_color
                    : ""
                }
                onChange={(e) =>
                  formik.setFieldValue("screener_result_range_color", e)
                }
              />
              <small className="text-danger col-md-12">
                {formik.touched.screener_result_range_color &&
                formik.errors.screener_result_range_color
                  ? formik.errors.screener_result_range_color
                  : ""}
              </small>
            </div>
            <div className="w-100 d-none d-md-block"></div>
            <div className="col-md-4 form-group ">
              <label
                htmlFor="screener_result_range_name"
                className="col-form-label w-100 mb-2"
              >
                Name
              </label>
              <input
                type="text"
                className="form-control"
                name="screener_result_range_name"
                id="screener_result_range_name"
                onChange={formik.handleChange}
                placeholder=""
                value={
                  !IsNullOrEmpty(formik.values.screener_result_range_name)
                    ? formik.values.screener_result_range_name
                    : ""
                }
                onBlur={formik.handleBlur}
                disabled={disabled}
              />
              <small className="text-danger col-md-12">
                {formik.touched.screener_result_range_name &&
                formik.errors.screener_result_range_name
                  ? formik.errors.screener_result_range_name
                  : ""}
              </small>
            </div>
            <div className="col-md-4 form-group">
              <label
                htmlFor="screener_result_range_min"
                className="col-form-label w-100 mb-2"
              >
                Min
              </label>
              <input
                type="text"
                className="form-control"
                name="screener_result_range_min"
                id="screener_result_range_min"
                onChange={formik.handleChange}
                placeholder=""
                value={
                  !IsNullOrEmpty(formik.values.screener_result_range_min)
                    ? formik.values.screener_result_range_min
                    : ""
                }
                onBlur={formik.handleBlur}
                disabled={disabled}
                onKeyPress={isInputNumber}
              />
              <small className="text-danger col-md-12">
                {formik.touched.screener_result_range_min &&
                formik.errors.screener_result_range_min
                  ? formik.errors.screener_result_range_min
                  : ""}
              </small>
            </div>
            <div className="col-md-4 form-group ">
              <label
                htmlFor="screener_result_range_max"
                className="col-form-label w-100 mb-2"
              >
                Max
              </label>
              <input
                type="text"
                className="form-control"
                name="screener_result_range_max"
                id="screener_result_range_max"
                onChange={formik.handleChange}
                placeholder=""
                value={
                  !IsNullOrEmpty(formik.values.screener_result_range_max)
                    ? formik.values.screener_result_range_max
                    : ""
                }
                onBlur={formik.handleBlur}
                disabled={disabled}
                onKeyPress={isInputNumber}
              />
              <small className="text-danger col-md-12">
                {formik.touched.screener_result_range_max &&
                formik.errors.screener_result_range_max
                  ? formik.errors.screener_result_range_max
                  : ""}
              </small>
            </div>
            <Description
              formik={formik}
              value={formik.values.description}
              mobileValue={formik.values.mobile_description}
              disabled={disabled}
              col="col-md-6"
            />
            {/* <Description
              formik={formik}
              value={formik.values.report_description}
              mobileValue={formik.values.mobile_report_description}
              webName="report_description"
              mobileName="mobile_report_description"
              webTitle="Report Description"
              mobileTitle="Mobile Report Description"
              disabled={disabled}
              col="col-md-6"
            /> */}
            <div className="col-md-6 form-group ">
              <label
                htmlFor="screener_result_range_report_description"
                className="col-form-label w-100 mb-2"
              >
                Report Description
              </label>
              <textarea
                className="form-control"
                name="screener_result_range_report_description"
                id="screener_result_range_report_description"
                onChange={formik.handleChange}
                placeholder=""
                value={
                  !IsNullOrEmpty(
                    formik.values.screener_result_range_report_description
                  )
                    ? formik.values.screener_result_range_report_description
                    : ""
                }
                onBlur={formik.handleBlur}
                disabled={disabled}
              ></textarea>
              <small className="text-danger col-md-12">
                {formik.touched.screener_result_range_report_description &&
                formik.errors.screener_result_range_report_description
                  ? formik.errors.screener_result_range_report_description
                  : ""}
              </small>
            </div>
            <div className="col-md-6 form-group ">
              <label
                htmlFor="screener_result_range_type_name"
                className="col-form-label w-100 mb-2"
              >
                Type Name
              </label>
              <input
                type="text"
                className="form-control"
                name="screener_result_range_type_name"
                id="screener_result_range_type_name"
                onChange={formik.handleChange}
                placeholder=""
                value={
                  !IsNullOrEmpty(formik.values.screener_result_range_type_name)
                    ? formik.values.screener_result_range_type_name
                    : ""
                }
                onBlur={formik.handleBlur}
                disabled={disabled}
              />
              <small className="text-danger col-md-12">
                {formik.touched.screener_result_range_type_name &&
                formik.errors.screener_result_range_type_name
                  ? formik.errors.screener_result_range_type_name
                  : ""}
              </small>
            </div>
            <div className="col-md-12">
              <MetaInput
                formik={formik}
                parentArray="screener_result_range_meta"
                typeKey="type"
                titleKey="key"
                valueKey="value"
              />
            </div>

            <div
              className={"d-flex align-items-end justify-content-end col-md-12"}
            >
              {formik.values.screener_result_range_id && (
                <button
                  onClick={resetModal}
                  type="button"
                  className="btn btn-light btn-sm float-right btn-add-domain mr-2"
                >
                  Reset
                </button>
              )}
              <CustomButton
                type="button"
                className="btn btn-primary btn-outline-success btn-sm float-right btn-add-domain"
                disabled={!formik.isValid || formik.isSubmitting}
                onClick={
                  formik.isValid && Object.keys(formik.values).length !== 0
                    ? formik.handleSubmit
                    : handleError
                }
              >
                {formik.values.screener_result_range_id ? "Update" : "Create"}
              </CustomButton>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const validation = Yup.object().shape({
  screener_result_range_entity_type: Yup.string().required("Select a type"),
  entity_ref_id: Yup.string().required("Select a "),
  screener_result_range_name: Yup.string().trim().required("Enter name"),
  screener_result_range_min: Yup.string().trim().required("Enter min"),
  screener_result_range_max: Yup.string().required("Enter max"),
  screener_result_range_color: Yup.string().required("Select a color"),
});
const gameValidation = Yup.object().shape({
  screener_result_range_entity_type: Yup.string().required("Select a type"),
  game_name_key: Yup.string().required("Select a game name key"),
  screener_result_range_name: Yup.string().trim().required("Enter name"),
  screener_result_range_min: Yup.string().trim().required("Enter min"),
  screener_result_range_max: Yup.string().required("Enter max"),
  screener_result_range_color: Yup.string().required("Select a color"),
});
