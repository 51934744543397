import { useEffect, useState } from "react";
import graphHandler from "../../Apollo/graphHandler";
import { GET_EMPLOYEE_GQL } from "../../Apollo/ProfileService/profileQueryRequest";
function useEmployee(id, preload = false) {
  const [user, setUser] = useState({});
  const [profile, setProfile] = useState({});
  const [loading, setLoading] = useState(preload);

  useEffect(() => {
    id && getEmployee(id);
  }, [id]);

  const getEmployee = async (user_id) => {
    setLoading(true);
    try {
      const { data } = await graphHandler().invoke(
        GET_EMPLOYEE_GQL,
        { user_id },
        0,
        false,
        true
      );
      if (data && data.getEmployee) {
        // console.log("getEmployee Success", data.getEmployee);
        data.getEmployee.user && setUser(data.getEmployee.user);
        data.getEmployee.employee_profile &&
          setProfile(data.getEmployee.employee_profile);
        setLoading(false);
      } else {
        setUser({});
        setProfile({});
        setLoading(false);
      }
    } catch (error) {
      console.error("getEmployee", error);
      setUser({});
      setProfile({});
      setLoading(false);
    }
  };

  return { user, loading, profile, getEmployee };
}

export default useEmployee;
