import { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import MultiSelect from "../../../components/MultiSelect/MultiSelect";
import {
  CREATE_THERAPY_GQL,
} from "../../../Apollo/AdminServices/MutationRequest";
import graphHandler from "../../../Apollo/graphHandler";
import Description from "../../../components/Input/Description";
import CustomButton from "../../../components/Button/CustomButton";
import MetaInput from "../../../components/MetaInput";

export default function CreateTherapy({
  refresh = () => {},
  subDomainList = [],
  onClose = () => {},
  setTherapy = () => {},
  setLoading = () => {},
}) {
  const [therapyId, setTherapyId] = useState("");

  const formik = useFormik({
    initialValues: {
      sub_domain_ids: [],
      therapy_meta: [],
      screener_result_range_max: "100",
      screener_result_range_min: "50",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      onSubmit(values);
    },
  });

  const onSubmit = async (value) => {
    formik.setSubmitting(true);
    // console.log(value);
    setLoading(true);
    const {
      sub_domain_ids,
      therapy_name,
      therapy_code,
      therapy_description,
      screener_result_range_min,
      screener_result_range_max,
      mobile_description,
      time_per_module,
      total_duration,
      days_per_week,
      therapy_meta,
      alias_name
    } = value;
    if (Number(screener_result_range_min) > Number(screener_result_range_max))
      return toast.error("Result Max should be greater than Min");
    const create_request = {
      sub_domain_ids: [...sub_domain_ids.map((x) => x.value)],
      therapy_name,
      alias_name,
      screener_result_range_min: Number(screener_result_range_min),
      screener_result_range_max: Number(screener_result_range_max),
      total_duration: Number(total_duration),
      time_per_module: Number(time_per_module) * 60,
      days_per_week: Number(days_per_week),
    };
    if (therapy_code) create_request.therapy_code = therapy_code;

    let description = [];
    if (therapy_description) {
      description.push({
        client_type: "Web",
        description: therapy_description,
      });
    }
    if (mobile_description) {
      description.push({
        client_type: "Mobile",
        description: mobile_description,
      });
    }
    if (description.length > 0)
      create_request.therapy_description = description;

    if (therapy_meta && therapy_meta.length > 0) {
      create_request.therapy_meta = therapy_meta;
    }
    try {
      const { data } = await graphHandler().invoke(
        CREATE_THERAPY_GQL,
        { create_request },
        1,
        true
      );
      if (data && data.createTherapy && data.createTherapy.therapy) {
        setTherapy({
          ...data.createTherapy.therapy,
          pending_history: data.createTherapy.therapy_history,
        });
      }
      refresh();
      setLoading(false);
      formik.setSubmitting(false);
    } catch (error) {
      console.log("createTherapy error", error);
      formik.setSubmitting(false);
      setLoading(false);
    }
  };

  const handleError = () => {
    if (Object.keys(formik.errors).length > 0) {
      toast.error(formik.errors[Object.keys(formik.errors)[0]]);
    }
  };

  const handleMinMax = (e, type) => {
    const { name, value } = e.target;
    const re = /^[0-9\b]+$/;
    let temp = !value
      ? value
      : re.test(value)
      ? value
      : formik.values[name]
      ? formik.values[name]
      : "";
    // console.log(temp);
    formik.setFieldValue(name, temp ? temp.substring(0, 10) : temp);
  };

  return (
    <>
      <div className="container">
        <div className="intervention-form row">
          <div className="col-md-6">
            <div className="row">
              <div className="col-md-12 form-group">
                <label
                  htmlFor="sub_domain_ids"
                  className="col-form-label w-100 mb-2"
                >
                  Sub Domains
                </label>
                <MultiSelect
                  option={subDomainList}
                  onChange={(e) => formik.setFieldValue("sub_domain_ids", e)}
                  placeholder="Select Sub Domain"
                  isCreatable={false}
                  isMulti={true}
                  type="Sub Domain"
                  name="sub_domain_ids"
                  onBlur={formik.handleBlur}
                  disabled={therapyId !== ""}
                />
                <small className="text-danger">
                  {formik.touched.sub_domain_ids &&
                  formik.errors &&
                  formik.errors.sub_domain_ids
                    ? formik.errors.sub_domain_ids
                    : ""}
                </small>
              </div>
              <div className="col-md-12 form-group">
                <label
                  htmlFor="therapy_code"
                  className="col-form-label w-100 mb-2"
                >
                  Code
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="therapy_code"
                  name="therapy_code"
                  onChange={formik.handleChange}
                  // maxLength={30}
                  disabled={therapyId !== ""}
                  value={
                    formik.values.therapy_code ? formik.values.therapy_code : ""
                  }
                  onBlur={formik.handleBlur}
                />
                <small className="text-danger align-self-end">
                  {formik.touched.therapy_code &&
                  formik.errors &&
                  formik.errors.therapy_code
                    ? formik.errors.therapy_code
                    : ""}
                </small>
              </div>
              <div className="col-md-12 form-group">
                <label
                  htmlFor="total_duration"
                  className="col-form-label w-100 mb-2"
                >
                  Total Duration (in Weeks)
                </label>
                <input
                  type="text"
                  className="form-control "
                  id="total_duration"
                  name="total_duration"
                  value={
                    formik.values.total_duration
                      ? formik.values.total_duration
                      : ""
                  }
                  onChange={(e) => handleMinMax(e, "min")}
                  onBlur={formik.handleBlur}
                  disabled={therapyId !== ""}
                />
                <small className="text-danger align-self-end">
                  {formik.touched.total_duration &&
                  formik.errors &&
                  formik.errors.total_duration
                    ? formik.errors.total_duration
                    : ""}
                </small>
              </div>
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-6 form-group">
                    <label
                      htmlFor="time_per_module"
                      className="col-form-label w-100 mb-2"
                    >
                      Time Per Module (in Mins)
                    </label>
                    <input
                      type="text"
                      className="form-control "
                      name="time_per_module"
                      id="time_per_module"
                      onBlur={formik.handleBlur}
                      value={
                        formik.values.time_per_module
                          ? formik.values.time_per_module
                          : ""
                      }
                      onChange={(e) => handleMinMax(e, "max")}
                      disabled={therapyId !== ""}
                    />
                    <small className="text-danger align-self-end">
                      {formik.touched.time_per_module &&
                      formik.errors &&
                      formik.errors.time_per_module
                        ? formik.errors.time_per_module
                        : ""}
                    </small>
                  </div>

                  <div className="col-md-6 form-group">
                    <label
                      htmlFor="days_per_week"
                      className="col-form-label w-100 mb-2"
                    >
                      Days Per Week
                    </label>
                    <input
                      type="text"
                      className="form-control "
                      id="days_per_week"
                      name="days_per_week"
                      onBlur={formik.handleBlur}
                      value={
                        formik.values.days_per_week
                          ? formik.values.days_per_week
                          : ""
                      }
                      onChange={(e) => handleMinMax(e, "max")}
                      disabled={therapyId !== ""}
                    />
                    <small className="text-danger align-self-end">
                      {formik.touched.days_per_week &&
                      formik.errors &&
                      formik.errors.days_per_week
                        ? formik.errors.days_per_week
                        : ""}
                    </small>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-6 form-group">
                    <label
                      htmlFor="screener_result_range_min"
                      className="col-form-label w-100 mb-2"
                    >
                      Result Range Min
                    </label>
                    <input
                      type="text"
                      className="form-control "
                      id="screener_result_range_min"
                      name="screener_result_range_min"
                      onBlur={formik.handleBlur}
                      value={
                        formik.values.screener_result_range_min
                          ? formik.values.screener_result_range_min
                          : ""
                      }
                      // {...formik.getFieldProps("screener_result_range_min")}
                      onChange={(e) => handleMinMax(e, "min")}
                      disabled={therapyId !== ""}
                    />
                    <small className="text-danger align-self-end">
                      {formik.touched.screener_result_range_min &&
                      formik.errors &&
                      formik.errors.screener_result_range_min
                        ? formik.errors.screener_result_range_min
                        : ""}
                    </small>
                  </div>
                  <div className="col-md-6 form-group">
                    <label
                      htmlFor="screener_result_range_max"
                      className="col-form-label w-100 mb-2"
                    >
                      Result Range Max
                    </label>
                    <input
                      type="text"
                      className="form-control "
                      id="screener_result_range_max"
                      name="screener_result_range_max"
                      onBlur={formik.handleBlur}
                      value={
                        formik.values.screener_result_range_max
                          ? formik.values.screener_result_range_max
                          : ""
                      }
                      // {...formik.getFieldProps("screener_result_range_max")}
                      onChange={(e) => handleMinMax(e, "max")}
                      disabled={therapyId !== ""}
                    />
                    <small className="text-danger align-self-end">
                      {formik.touched.screener_result_range_max &&
                      formik.errors &&
                      formik.errors.screener_result_range_max
                        ? formik.errors.screener_result_range_max
                        : ""}
                    </small>
                  </div>
                </div>
              </div>

            </div>
          </div>
          <div className="col-md-6">
            <div className="row">
              <div className="col-md-12 form-group">
                <label
                  htmlFor="therapy_name"
                  className="col-form-label w-100 mb-2"
                >
                  Therapy Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="therapy_name"
                  name="therapy_name"
                  // maxLength={30}
                  value={
                    formik.values.therapy_name ? formik.values.therapy_name : ""
                  }
                  onChange={(e) =>
                    formik.setFieldValue("therapy_name", e.target.value.trimStart())
                  }
                  onBlur={(e) => {
                    formik.setFieldTouched("therapy_name", true);
                    formik.setFieldValue(
                      "therapy_name",
                      e.target.value.replace(/\s+/g, " ").trim()
                    );
                  }}
                  disabled={therapyId !== ""}
                />
                <small className="text-danger align-self-end">
                  {formik.touched.therapy_name &&
                  formik.errors &&
                  formik.errors.therapy_name
                    ? formik.errors.therapy_name
                    : ""}
                </small>
              </div>
              <div className="col-md-12 form-group">
              <label htmlFor="alias_name" className="col-form-label w-100 mb-2">
                Alias Name
              </label>
              <input
                type="text"
                className="form-control"
                id="alias_name"
                name="alias_name"
                value={
                  formik.values && formik.values.alias_name
                    ? formik.values.alias_name
                    : ""
                }
                onChange={(e)=>formik.setFieldValue("alias_name", e.target.value.trimStart())}
                // onBlur={formik.handleBlur}
                onBlur={(e)=>{
                  formik.setFieldTouched("alias_name", true)
                  formik.setFieldValue("alias_name", e.target.value.replace(/\s+/g,' ').trim())
                }}
              />
              <small className="text-danger">
                {formik.touched.alias_name &&
                formik.errors &&
                formik.errors.alias_name
                  ? formik.errors.alias_name
                  : ""}
              </small>
            </div>
              <Description
                formik={formik}
                webName="therapy_description"
                // disabled={therapy.therapy_id}
                disabled={therapyId !== ""}
                showEditor={true}
              />
            </div>
          </div>
          <div className="col-md-12">
            <MetaInput
              formik={formik}
              disabled={therapyId !== ""}
              parentArray="therapy_meta"
              typeKey="type"
              titleKey="key"
              valueKey="value"
            />
          </div>
          <div className="d-flex align-items-end justify-content-end col-md-12 mt-3">
            <button
              onClick={onClose}
              type="button"
              className="btn btn-danger btn-sm float-right btn-add-domain mr-2"
            >
              Close
            </button>
            <CustomButton
              type="button"
              className="btn btn-primary btn-outline-success btn-sm float-right btn-add-domain"
              disabled={
                Object.keys(formik.errors).length !== 0 ||
                !formik.isValid ||
                formik.isSubmitting
              }
              onClick={
                formik.isValid && Object.keys(formik.values).length !== 0
                  ? formik.handleSubmit
                  : handleError
              }
            >
              Create
            </CustomButton>
          </div>
        </div>
      </div>
    </>
  );
}

const validationSchema = Yup.object().shape({
  sub_domain_ids: Yup.array()
    .of(
      Yup.object().shape({
        label: Yup.string(),
        value: Yup.string(),
      })
    )
    .test({
      message: "Select a sub domain",
      test: (arr) => {
        return arr && arr.length > 0;
      },
    }),
  therapy_name: Yup.string().trim().required("Enter name"),
  alias_name: Yup.string().trim(),
  therapy_code: Yup.string().required("Enter code"),
  // .min(4, "Name should be min of 4 characters")
  // .max(30, "Name should be maximum of 30 characters")
  therapy_description: Yup.string().required("Enter description"),
  mobile_description: Yup.string().required("Enter mobile description"),
  screener_result_range_min: Yup.string().required("Enter min result mark"),
  screener_result_range_max: Yup.string().required("Enter max result mark"),
  total_duration: Yup.string().required("Enter total duration"),
  time_per_module: Yup.string().required("Enter time per module"),
  days_per_week: Yup.string().required("Enter days per week"),
  therapy_meta: Yup.array().of(
    Yup.object().shape({
      type: Yup.string().required("Select a data type"),
      key: Yup.string().trim().required("Enter a title"),
      value: Yup.string().trim().required("Enter a value"),
    })
  ),
});
