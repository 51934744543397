import React, { useState } from "react";
import PaginationComponent from "../../../components/PaginationComponent";
import moment from "moment";
export default function ActionHistory({ historyData = [] }) {
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const pageCount = Math.ceil(historyData.length / pageSize);

  const handleClick = (event, index) => {
    event.preventDefault();
    setCurrentPage(index);
  };

  return (
    <div className="assign-wrapper col-md-12 d-flex flex-column  ">
      <div className="form-group">
        <div className="table-responsive ">
          <table className="table   table-borderless">
            <thead>
              <tr>
                <th scope="col-3" className="w-25">
                  Action
                </th>
                <th scope="col" className="w-25">
                  Time
                </th>
              </tr>
            </thead>
            <tbody>
              {historyData && historyData.length > 0 ? (
                historyData
                  .slice()
                  .sort(
                    (a, b) =>
                      new Date(b.updated_at ? b.updated_at : b.created_at) -
                      new Date(a.updated_at ? a.updated_at : a.created_at)
                  )
                  .slice(currentPage * pageSize, (currentPage + 1) * pageSize)
                  .map((x, i) => (
                    <tr key={i}>
                      <td scope="row">{x.user_activity_type}</td>
                      <td>
                        {moment(x.created_at).format("MMMM Do YYYY, h:mm:ss a")}
                      </td>
                    </tr>
                  ))
              ) : (
                <tr>
                  <td colSpan="2" className="text-center">
                    <span className="font-weight-bold">No Data Found</span>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      {historyData.length > 0 && pageCount != 1 && (
        <PaginationComponent
          handleClick={handleClick}
          currentPage={currentPage}
          pageCount={pageCount}
        />
      )}
    </div>
  );
}
