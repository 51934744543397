import {
  LOADING_START,
  LOADING_STOP,
  FLUSH_AUTH,
  LOGIN_SUCCESS,
  LOGIN_FAILED,
  LOGOUT_SUCCESS,
  SET_USER_ROLES,
} from "./types";

const InitialState = {
  user: {},
  isLogin: false,
  loading: true,
  sessionToken: "",
  userType: "",
  roles: [],
};

const Reducer = (state = InitialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        isLogin: true,
        loading: false,
        user: payload.user,
        session: payload.session,
      };

    case LOGIN_FAILED:
      return {
        ...state,
        isLogin: false,
        loading: false,
        user: {},
        session: "",
      };
    case LOADING_START:
      return {
        ...state,
        loading: true,
      };
    case LOADING_STOP:
      return {
        ...state,
        loading: false,
      };
    case LOGOUT_SUCCESS:
      return {
        ...InitialState,
        loading: false,
      };
    case FLUSH_AUTH:
      return {
        ...InitialState,
        loading: false,
      };
    case SET_USER_ROLES:
      return {
        ...state,
        roles: payload,
      };

    default:
      return state;
  }
};

export default Reducer;
