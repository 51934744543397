import { useEffect } from "react";
import { UPDATE_SCREENER_QUESTIONAIRE_GQL } from "../../../Apollo/AdminServices/MutationRequest";
import graphHandler from "../../../Apollo/graphHandler";
import { IsNullOrEmpty } from "../../../utils/Functions/filter";

export default function SortByTopicAccordionItem({ item = {}, i, isEditable }) {
  useEffect(() => {
    // console.log(item.sort_order, isEditable, i);
    if (
      !IsNullOrEmpty(item.sort_order) &&
      isEditable &&
      i !== item.sort_order
    ) {
      let request = {
          screener_question_id: item.screener_question_id,
          sort_order: i,
        },
        update_data =
          item && item.pending_history && item.pending_history.updated_data
            ? item.pending_history.updated_data
            : null;
      if (update_data) {
        if (update_data.screener_question_title) {
          request.screener_question_title = update_data.screener_question_title;
        }
        if (update_data.result_weightage) {
          request.result_weightage = Number(update_data.result_weightage);
        }
        if (update_data.screener_topic_id) {
          request.screener_topic_id = update_data.screener_topic_id;
        }
        if (update_data.ignore_for_scoring) {
          request.ignore_for_scoring = update_data.ignore_for_scoring;
        }
        if (update_data.sub_domain_id) {
          request.sub_domain_id = update_data.sub_domain_id;
        }
        if (
          update_data.options &&
          update_data.options &&
          update_data.options.options_update &&
          update_data.options.options_update.update_items
        ) {
          request.options = {
            options_update: {
              update_items: update_data.options.options_update.update_items,
            },
          };
        }
      }
      update({ ...request });
    }
  }, [i, item]);

  const update = async (update_request) => {
    try {
      const { data } = await graphHandler().invoke(
        UPDATE_SCREENER_QUESTIONAIRE_GQL,
        { update_request },
        1,
        false,
        true
      );
      if (
        data &&
        data.updateScreenerQuestion &&
        data.updateScreenerQuestion.screener_question
      ) {
        // console.log(
        //   "Question Sort Success",
        //   data.updateScreenerQuestion.screener_question
        // );
      }
    } catch (error) {
      console.error("Question Sort Error", error);
    }
  };
  const OptionRender = (option, key) => {
    if (item.option_type === "Text")
      return (
        <li key={key} className={key === 0 ? "ml-4" : "ml-4"}>
          {option.data + " (" + option.value + ")"}
        </li>
      );
    else
      return (
        <div key={key} className="option-panel mb-2">
          {key + 1 + ". "}
          <img src={option.data} />
        </div>
      );
  };
  const canShowOptions = (value) =>
    value &&
    value != "TextField" &&
    value != "DocumentUpload" &&
    value != "ImageCapture" &&
    value != "Date" &&
    value != "Rating" &&
    value != "TextArea";
  return (
    <>
      <td>
        <h6 className="card-title position-relative">
          {`${i}). ${
            item.screener_question_title ? item.screener_question_title : ""
          }`}
        </h6>
      </td>
      <td>
        <ol type="A" className="d-flex flex-row flex-wrap">
          {canShowOptions(item.screener_question_type) &&
          item.options &&
          item.options.options
            ? item.options.options.map((q, j) => OptionRender(q, j))
            : item.screener_question_type}
        </ol>
      </td>
      <td> {item.result_weightage}</td>
      <td>
        {item.ignore_for_scoring ? "Yes" : "No"}
        <i className="fas fa-grip-vertical float-right" />
      </td>
    </>
  );
}
