import { useEffect, useState } from "react";
import { GET_TOPIC_HISTORIES_GQL } from "../../Apollo/AdminServices/QueryRequest";
import graphHandler from "../../Apollo/graphHandler";

function useTopicHistory(id = "") {
  const [ topicHistory, setTopicHistory ] = useState([]);
  const [ loading, setLoading ] = useState(false);

  useEffect(() => {
    if (id) {
      getTopicHistory(id);
    } else {
      setTopicHistory([]);
    }
  }, [ id ]);

  const getTopicHistory = async (screener_topic_id) => {
    if (!screener_topic_id) return;
    setLoading(true);
    try {
      const { data } = await graphHandler().invoke(
        GET_TOPIC_HISTORIES_GQL,
        { screener_topic_id },
        1
      );
      if (
        data &&
        data.getAllScreenerTopicHistories &&
        data.getAllScreenerTopicHistories.screener_topic_history_list &&
        data.getAllScreenerTopicHistories.screener_topic_history_list.length > 0
      ) {
        // console.log(
        //   "getAllScreenerTopicHistories Success",
        //   data.getAllScreenerTopicHistories.screener_topic_history_list
        // );
        setTopicHistory(
          data.getAllScreenerTopicHistories.screener_topic_history_list
        );
        setLoading(false);
      } else {
        setTopicHistory([]);
        setLoading(false);
      }
    } catch (error) {
      console.log("getAllScreenerTopicHistories Error", error);
      setTopicHistory([]);
      setLoading(false);
    }
  };
  return {
    topicHistory,
    loading,
    getTopicHistory,
  };
}
export default useTopicHistory;
