export default function MediaTableItem({
  index,
  item,
  remove = () => {},
  onEdit = () => {},
  disabled,
}) {
  // console.log("item", item);
  return (
    <tr className="">
      <td className="text-none">{index + 1}</td>
      <td className="text-none">
        {item?.mediaUrl?.type === "Video" ? (
          <video src={item?.mediaUrl?.url} className="upload__img1"></video>
        ) : item?.mediaUrl.type === "Image" ? (
          <img src={item?.mediaUrl?.url} className="upload__img1" />
        ) : (
          <i className="fas fa-file mr-5" style={{ fontSize: "60px" }} />
        )}
      </td>
      <td className="text-none">
        <p className="upload__name">{item.mediaUrl.name || "Uploaded file"}</p>
      </td>
      {/* <td className="text-none">{item.media_type}</td> */}
      <td className="text-none">
        {`${item.media_device}` === "false"
          ? "Web"
          : "Mobile"}
      </td>
      <td className="text-none">{item?.media_format}</td>
      <td className="d-flex flex-row">
        <i
          className="fas fa-edit cursor-pointer"
          onClick={() => {
            !disabled && onEdit({ ...item, index });
          }}
        ></i>
        <i
          className="ml-2 fas fa-trash cursor-pointer"
          onClick={() => !disabled && remove(index)}
        ></i>
      </td>
    </tr>
  );
}
