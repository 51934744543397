import { useState } from "react";
import GameModal from "../modal/GameModal";
import { Popconfirm } from "antd";
import useRemoveAPI from "../../../utils/CustomHooks/useRemoveAPI";
import { toast } from "react-toastify";

export default function GameItem({
  item,
  i,
  refresh = () => {},
  access = {},
  screenerList = [],
  interventionList = [],
}) {
  const [isEdit, setEdit] = useState(false);
  const [levelDta,setLevelDta] = useState([]);
  // console.log(levelDta,"levelDta");
  const { removeGameConfig } = useRemoveAPI();
  const onDelete = () => {
    if (levelDta.length > 0)
      return toast.error("Please delete all the leveldata");
    removeGameConfig({ game_config_id: item.game_config_id }, refresh);
  };
  const isScreener = item.game_type === "Screener";
  const getContent = () => {
    if (isScreener && item.screener_id) {
      return screenerList.find((x) => x.screener_id === item.screener_id)
        ? screenerList.find((x) => x.screener_id === item.screener_id)
            .screener_name
        : "-";
    } else if (!isScreener && item.intervention_id) {
      return interventionList.find(
        (x) => x.intervention_id === item.intervention_id
      )
        ? interventionList.find(
            (x) => x.intervention_id === item.intervention_id
          ).intervention_name
        : "-";
    } else return "-";
  };
  return (
    <tr className="option-table">
      <td>{getContent()}</td>
      <td>{item.game_name_key || "-"}</td>
      <td>{item.game_type || "-"}</td>
      <td>{item.game_view_id || "-"}</td>
      {/* <td>
        <span
          className={`${item.active ? "good" : "bad"} text-center btn btn-sm`}
        >
          {item.active ? "Active" : "Disabled"}
        </span>
      </td> */}
      {access.canEdit && (<>
        <td>
          <button
            type="button"
            className="btn btn-outline-success btn-sm "
            onClick={() => setEdit(true)}
          >
            Edit
          </button>
        </td>
      <td>
        <Popconfirm
          title="Are you sure to delete this Game Config?"
          onConfirm={onDelete}
          //  onCancel={cancel}
          okText="Yes"
          cancelText="No"
        >
          <i
            className="ml-2 fas fa-trash cursor-pointer"
            // onClick={onDelete}
            ></i>
        </Popconfirm>
      </td>
      </>
      )}
      <GameModal
        setLevelDta={setLevelDta}
        isOpen={isEdit}
        setOpen={setEdit}
        initialValues={item}
        refresh={() => {
          refresh((x) => x + 1);
        }}
        isEditable={access.canEdit}
        {...{
          screenerList,
          interventionList,
        }}
      />
    </tr>
  );
}
