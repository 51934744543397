export default function HistoryOptionTableItem({
  item,
  titleKey = "data",
  valueKey = "value",
  arrayIndex = "",
  remove = () => {},
  onEdit = () => {},
  disabled,
}) {
  return (
    <tr
      className={`${
        item.isRemoved
          ? "bad"
          : item.isAdded
          ? "good"
          : item.isUpdated
          ? "warn"
          : ""
      }`}
    >
      <td className="text-none">{item[titleKey]}</td>
      <td className="text-none">{item[valueKey]}</td>
      {!disabled && (
        <td className="d-flex flex-row">
          {!item.isRemoved && !disabled && (
            <>
              <i
                className="fas fa-edit cursor-pointer"
                onClick={() => {
                  !disabled && onEdit({ ...item }, arrayIndex);
                }}
              ></i>
              <i
                className="ml-2 fas fa-trash cursor-pointer"
                onClick={() => !disabled && remove(item.index, arrayIndex)}
              ></i>
            </>
          )}
        </td>
      )}
    </tr>
  );
}
