import { gql } from "@apollo/client";
import { serviceResponse, userResponse } from "./profileResponse";

export const GET_USER = gql`
    query GetUser($user_id: String!) {
        getUser(user_id: $user_id) {
            ${userResponse}
        }
    }
`;

export const GET_EMPLOYEE_GQL = gql`
  query getEmployee($user_id: String!) {
    getEmployee(user_id: $user_id) {
      status
      error
      message
      version
      service
      user {
        created_at
        created_by
        updated_at
        updated_by
        user_id
        user_type
        user_name
        name
        mobile_number
        mobile_number_verified
        email
        email_verified
        password
        address {
          line_1
          line_2
          region
          town_city
          postcode
          state_id
          country_id
        }
        profile_image_urls {
          image_type
          url
          is_mobile
        }
        device_id
        pn_token
        active
      }
      employee_profile {
        created_at
        created_by
        updated_at
        updated_by
        user_id
        employee_profile_status
        first_name
        last_name
        gender
        dob
        personal_email
        alternate_email
        education_id
        country_id
        city_id
        industry_id
        function_id
        function
        time_format
        date_format
        marital_status
        work_experience_years
        designation
      }
    }
  }
`;

export const GET_USER_LIST = gql`
  query getUserList(
    $name: String
    $mobile_number: String
    $email: String
    $active: Boolean
	$role_types:[RoleType!]
	$user_types: [UserType!]

  ) {
    getUserList(
      name: $name
      mobile_number: $mobile_number
      email: $email
      active: $active
	  role_types:$role_types
	  user_types: $user_types
    ) {
		${serviceResponse}
		users{
			${userResponse}
		}
    }
  }
`;

export const EMAIL_EXIST = gql`
    query IsEmailExists($email: String!) {
        isEmailExists(email: $email) {
            ${serviceResponse}
            exists
        }
    }
`;

export const MOBILE_EXIST = gql`
    query IsMobileExists($mobile_number: String!) {
        isMobileExists(mobile_number: $mobile_number) {
            ${serviceResponse}
            exists
        }
    }
`;

export const USERNAME_EXIST = gql`
    query IsUserNameExists($user_name: String!) {
        isUserNameExists(user_name: $user_name) {
            ${serviceResponse}
            exists
        }
    }
`;

export const GET_USER_ACTIVITY_LIST_GQL = gql`
  query getUserActivity(
    $affected_user_id: String
    $user_action: UserActivityType
    $user_id: String!
  ) {
    getUserActivity(
      affected_user_id: $affected_user_id
      user_action: $user_action
      user_id: $user_id
    ) {
      status
      error
      message
      version
      service
      user_activity_list {
        created_at
        created_by
        updated_at
        updated_by
        user_activity_id
        user_id
        user_activity_type
        #reason
        data {
          user_name
          name
        }
        roles {
          row_crc
          admin_role_id
          user_id
          sub_domain_id
          role_type
          active
        }
      }
    }
  }
`;

export const GET_USER_ACTIVITY_CONTENT_LIST_GQL = gql`
  query getUserContentActivity(
    $content_type: ContentType
    $content_version: String
    $update_status: String
    $update_type: String
    $user_id: String!
  ) {
    getUserContentActivity(
      content_type: $content_type
      content_version: $content_version
      update_status: $update_status
      update_type: $update_type
      user_id: $user_id
    ) {
      status
      error
      message
      version
      service
      content_history_list {
        created_at
        created_by
        updated_by
        row_crc
        content_version
        update_counter
        content_type
        update_status
        update_type
        child_content_histories {
          child_content_history_id
          child_content_type
        }
        publish_data {
          created_by
          updated_by
          row_crc
          sub_domain_ids
          content_version
          update_counter
          published
          active
        }
        publish_at
        reviewer_comment
        reviewed_by
        reviewed_at
        approver_comment
        approved_by
        approved_at
      }
    }
  }
`;
