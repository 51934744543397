import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  MediaDevice,
  ImageFormat,
  MediaType,
  VideoFormat,
  DocumentFormat,
} from "../../constants";
import CreateMediaFileUpload from "./CreateMediaFileUpload";
import * as Yup from "yup";
// import EditorModal from "../Modal/EditorModal";
// import EditorModal from "../Modal/EditorModal";
// import MetaTableItem from "./MetaTableItem";
import MediaTableItem from "./MediaTableItem";

const MediaFileUpload = (props) => {
  const [isView, setVisible] = useState(true);

  let {
    formik,
    heading,
    contentId = "",
    contentType = "",
    parentArray,
    mediaType,
  } = props;
  const formik1 = useFormik({
    initialValues: {},
    validationSchema: Yup.object().shape({
      // media_type: Yup.string().required("Select a media type"),
      media_device: Yup.boolean().required("Select a media device"),
      media_format: Yup.string().required("Select a media format"),
      mediaUrl: Yup.object().shape({
        url: Yup.string(),
      }).required("upload a file"),
    }),
    onSubmit: (values) => onSubmit(values),
  });

  useEffect(() => {
    setVisible(
      Boolean(
        parentArray &&
          formik.values[parentArray] &&
          formik.values[parentArray].length > 0
      )
    );
  }, [formik.values, parentArray]);

  const onSubmit = (values = {}) => {
    let final = { ...values };
    let media_data = [
      ...(formik.values[parentArray] ? formik.values[parentArray] : []).map(
        (x) => x
      ),
    ];
    if ( !values.media_device || !values.media_format)
      return console.error("Data Not found", values);

    // console.log(final);
    if (Number.isInteger(values.index) && media_data.length > values.index) {
      delete final.index;
      media_data[values.index] = { ...final };
      formik.setFieldValue(parentArray, [...media_data]);
      reset();
    } else {
      delete final.index;
      formik.setFieldValue(parentArray, [...media_data, { ...final }]);
      reset();
    }
  };

  const onEdit = (values = {}) => {
    setVisible(true);
    formik1.setValues({
      ...values,
    });
    const container = document.getElementById("mediaSection" + parentArray);
    if (container) container.scrollIntoView({ behavior: "smooth" });
  };

  const handleError = () => {
    if (Object.keys(formik1.errors).length > 0) {
      toast.error(formik1.errors[Object.keys(formik1.errors)[0]]);
    }
  };

  const handleFormats = () => {
    switch (mediaType) {
      case "Image":
        return ImageFormat;
      case "Video":
        return VideoFormat;
      case "Document":
        return DocumentFormat;
      default:
        return ImageFormat;
    }
  };

  const remove = (i) => {
    let media_data = [
      ...(formik.values[parentArray] ? formik.values[parentArray] : []).map(
        (x) => x
      ),
    ];
    media_data.splice(i, 1);
    formik.setFieldValue(parentArray, media_data);
  };

  const reset = () => {
    formik1.resetForm();
    formik1.setTouched({});
  };

  return (
    <div className="col-md-12 form-group" id={"mediaSection" + parentArray}>
      <div className="row mb-2">
        <label className="col-form-label w-100">
          {heading}{" "}
          {!isView && !props.disabled && (
            <button
              className="btn btn-outline-success ml-5 btn-sm"
              onClick={() => setVisible(true)}
            >
              Add Media
            </button>
          )}
        </label>
      </div>
      {formik.values[parentArray] && formik.values[parentArray].length > 0 ? (
        <div className="row ">
          <div
            className="table-responsive mb-3 table-borderless  questionnaire-table"
            style={{ maxHeight: "200px" }}
          >
            <table className="table  table-striped mb-0">
              <thead>
                <tr>
                  <th className="col-sm-1">#</th>
                  <th className="col-sm-2">File</th>
                  <th className="col-sm-3">Name</th>
                  <th className="col-sm-2">Device</th>
                  <th className="col-sm-2">Format</th>
                  <th className="col-sm-2">Action</th>
                </tr>
              </thead>
              <tbody>
                {formik.values[parentArray]
                  .slice()
                  .sort((a, b) => a.sort_order - b.sort_order)
                  .map((x, i) => (
                    <MediaTableItem
                      key={i}
                      index={i}
                      item={x}
                      {...props}
                      remove={remove}
                      onEdit={onEdit}
                    />
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      ) : null}
      {isView && !props.disabled && (
        <div className="row px-3 pt-3 position-relative rounded-4 shadow-common">
          <div className="col-md-12">
            <div className="row">
              {/* <div className="col-md-6">
                <div htmlFor="media_type" className="col-form-label w-100 mb-2">
                  <select
                    className="custom-select"
                    id="media_type"
                    name="media_type"
                    onChange={formik1.handleChange}
                    onBlur={formik1.handleBlur}
                    value={
                      formik1.values.media_type ? formik1.values.media_type : ""
                    }
                  >
                    <option key="Select" hidden>
                      Select a media
                    </option>
                    {MediaType.map((x, i) => (
                      <option value={x.value} key={i}>
                        {x.label}
                      </option>
                    ))}
                  </select>
                </div>
              </div> */}
              <div className="col-md-6">
                <div htmlFor="media_device" className="col-form-label w-100 mb-2">
                  <select
                    className="custom-select"
                    id="media_device"
                    name="media_device"
                    onChange={formik1.handleChange}
                    onBlur={formik1.handleBlur}
                    value={
                      formik1.values.media_device
                        ? formik1.values.media_device
                        : ""
                    }
                  >
                    <option key="Select" hidden>
                      Select a device
                    </option>
                    {MediaDevice.map((x, i) => (
                      <option value={x.value} key={i}>
                        {x.label}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              {mediaType? (
                <div className="col-md-6">
                  <div
                    htmlFor="media_format"
                    className="col-form-label w-100 mb-2"
                  >
                    <select
                      className="custom-select"
                      id="media_format"
                      name="media_format"
                      onChange={formik1.handleChange}
                      onBlur={formik1.handleBlur}
                      value={
                        formik1.values.media_format
                          ? formik1.values.media_format
                          : ""
                      }
                    >
                      <option key="Select" hidden>
                        Select a format
                      </option>
                      {handleFormats().map((x, i) => (
                        <option value={x.value} key={i}>
                          {x.label}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              ) : null}
              {formik1.values.media_format ? (
                <div className="col-md-6">
                  <div
                    htmlFor="media_upload"
                    className="col-form-label w-100 mb-2"
                  >
                    <CreateMediaFileUpload
                      id="mediaUrl"
                      name="mediaUrl"
                      formik={formik1}
                      mediaType={mediaType}
                      contentId={contentId}
                      // type={formik1.values.media_type}
                      contentType={contentType}
                      format={formik1.values.media_format}
                    />
                  </div>
                </div>
              ) : null}
              <div className="col-md-12 d-flex align-items-end justify-content-end mb-5">
                {Number.isInteger(formik1.values.index) ? (
                  <button className="btn btn-light btn-sm mr-3" onClick={reset}>
                    Reset
                  </button>
                ) : null}
                <button
                  className="btn btn-primary btn-outline-success btn-sm"
                  onClick={
                    formik1.isValid && Object.keys(formik1.values).length !== 0
                      ? formik1.handleSubmit
                      : handleError
                  }
                  disabled={Object.keys(formik1.errors).length !== 0 || !formik1?.values?.mediaUrl?.url}
                >
                  {!Number.isInteger(formik1.values.index) ? "Save" : "Update"}
                </button>
              </div>
            </div>
          </div>
          <button
            type="button"
            className="btn btn-link position-absolute"
            style={{ top: "0px", right: "0px" }}
            onClick={() => {
              setVisible(false);
              reset();
            }}
          >
            <i className="fas fa-times"></i>
          </button>
        </div>
      )}
    </div>
  );
};

export default MediaFileUpload;
