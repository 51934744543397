import Banner from "../components/Banner";
import Paragraph from "../components/Paragraph";
import MediaLeft from "../components/MediaLeft";
import Quote from "../components/Quote";
import MediaRight from "../components/MediaRight";
import { BlogComponents } from "../constants";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { Nav, NavItem, NavLink } from "reactstrap";
import BlogForm from "./BlogForm";
export default function ComponentView(props = {}) {
  let { dataSet = [], currentEditor, edit, setEdit, setCurrentEditor } = props;
  const renderComponent = (value) => {
    switch (value.blog_content_type) {
      case "BANNER":
        return <Banner value={value} {...props} />;
      case "PARAGRAPH":
        return <Paragraph value={value} {...props} />;
      case "LEFTMEDIA":
        return <MediaLeft value={value} {...props} />;
      case "RIGHTMEDIA":
        return <MediaRight value={value} {...props} />;
      case "QUOTE":
        return <Quote value={value} {...props} />;
      default:
        return null;
    }
  };

  return (
    <>
      <div className="col-md-12 p-0 m-0">
        <Nav tabs>
          <NavItem>
            <NavLink
              onClick={() => setCurrentEditor(0)}
              active={currentEditor === 0}
            >
              Component
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              disabled={!edit || !edit.id}
              onClick={() => setCurrentEditor(1)}
              active={currentEditor === 1}
            >
              Editor
            </NavLink>
          </NavItem>
        </Nav>
      </div>
      <div className="editor-view component-container border border-top-0">
        {currentEditor === 0 ? (
          <Droppable droppableId="component">
            {(provided) => (
              <div
                {...provided.droppableProps}
                className="col-md-12"
                ref={provided.innerRef}
              >
                {BlogComponents.map((item, i) => (
                  <Draggable
                    key={i}
                    draggableId={item.blog_content_type}
                    index={i}
                  >
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className="row card-bg"
                      >
                        {renderComponent(item)}
                      </div>
                    )}
                  </Draggable>
                ))}
              </div>
            )}
          </Droppable>
        ) : (
          <BlogForm {...props} />
        )}
      </div>
    </>
  );
}
