import { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import graphHandler from "../../../Apollo/graphHandler";
import {
  CREATE_SUB_DOMAIN_GQL,
  UPDATE_SUB_DOMAIN_GQL,
} from "../../../Apollo/AdminServices/MutationRequest";
import { ImageFormat, VisibilityStatus } from "../../../constants";
import Description from "../../../components/Input/Description";
import CustomButton from "../../../components/Button/CustomButton";
// import SingleMediaFileUpload from "../../../components/MediaFileUpload/SingleMediaFileUpload";
import SingleImageUpload from "../../../components/MediaFileUpload/SingleImageUpload";
import MetaInput from "../../../components/MetaInput";

export default function CreateSubDomain({
  domainList,
  refresh = () => {},
  setSubDomain = () => {},
  setLoading = () => {},
  onClose = () => {},
}) {
  // const [progress, setProgress] = useState(0);
  // const [size, setSize] = useState("0");
  // const [isUpload, setUpload] = useState(false);
  // const [image, setImage] = useState({});
  const [subDomainId, setSubDomainId] = useState("");

  const formik = useFormik({
    initialValues: {},
    validationSchema: validation,
    onSubmit: (values) => {
      onSubmit(values);
    },
  });

  const formik1 = useFormik({
    initialValues: {},
    onSubmit: (values) => {
      onEditSubmit(values);
    },
  });

  const onClosed = () => {
    onClose();
    formik.resetForm();
    formik.setValues({});
    formik1.resetForm();
    formik1.setValues({});
    setSubDomainId("");
  };
  const onSubmit = async (values) => {
    const {
      sub_domain_name,
      alias_name,
      domain_id,
      description,
      sub_domain_code,
      mobile_description,
      visibility_status,
      sort_order,
      sub_domain_meta,
    } = values;

    const create_request = {
      sub_domain_name,
      domain_id,
      sub_domain_code,
      visibility_status,
      sort_order: Number(sort_order),
    };
    if(sub_domain_meta){
      create_request.sub_domain_meta=sub_domain_meta;
    }
    if(alias_name){
      create_request.alias_name=alias_name;
    }
    let sub_domain_description = [];
    if (description) {
      sub_domain_description.push({ client_type: "Web", description });
    }
    if (mobile_description) {
      sub_domain_description.push({
        client_type: "Mobile",
        description: mobile_description,
      });
    }
    if (sub_domain_description.length > 0)
      create_request.sub_domain_description = sub_domain_description;
    try {
      const { data } = await graphHandler().invoke(
        CREATE_SUB_DOMAIN_GQL,
        { create_request },
        1,
        true
      );
      setLoading(false);
      refresh();
      // onClose();
      if (data) {
        setSubDomainId(data?.CreateSubDomain?.sub_domain?.sub_domain_id);
        setSubDomain(data?.CreateSubDomain?.sub_domain);
      }
      formik.setSubmitting(false);
    } catch (error) {
      // console.log("CreateSubDomain error", error);
      setLoading(false);
      formik.setSubmitting(false);
    }
  };

  const handleError = () => {
    if (Object.keys(formik.errors).length > 0) {
      toast.error(formik.errors[Object.keys(formik.errors)[0]]);
    }
  };

  const onEditSubmit = async (value) => {
    // console.log("submit", value);
    const { sub_domain_image_urls } = value;
    const update_request = {
      sub_domain_id: subDomainId,
    };

    // let sub_domain_image_urls = [];

    // console.log("media_datas", media_datas);
    // if (media_datas && media_datas.length > 0) {
    //   media_datas.map((x) => {
    //     sub_domain_image_urls.push({
    //       image_type: x.media_format,
    //       url: x.mediaUrl.url,
    //       is_mobile: x.media_device === "false" ? false : true,
    //     });
    //   });
    // }
    if (sub_domain_image_urls.length > 0) {
      update_request.sub_domain_image_urls = sub_domain_image_urls;
    }
    if (sub_domain_image_urls && sub_domain_image_urls.length > 0) {
      update_request.sub_domain_image_urls = sub_domain_image_urls.map((x) => {
        delete x.index;
        return { ...x };
      });
    }
    // console.log("update_request", update_request,createReq);
    try {
      // formik1.setSubmitting(false);
      // return;
      const { data } = await graphHandler().invoke(
        UPDATE_SUB_DOMAIN_GQL,
        { update_request },
        1,
        true
      );
      refresh();
      // onClose();
      // console.log("update data", data);
    } catch (error) {
      console.log("update Intervention error", error);
      formik1.setSubmitting(false);
    }
  };
  const handleEditError = () => {
    if (Object.keys(formik1.errors).length > 0) {
      toast.error(formik1.errors[Object.keys(formik1.errors)[0]]);
    }
  };
  return (
    <>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-6 ">
            <div className="row">
              <div className="col-md-12 form-group">
                <label
                  htmlFor="sub_domain_name"
                  className="col-form-label w-100 mb-2"
                >
                  Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="sub_domain_name"
                  name="sub_domain_name"
                  // maxLength="30"
                  value={
                    formik.values && formik.values.sub_domain_name
                      ? formik.values.sub_domain_name
                      : ""
                  }
                  disabled={subDomainId !== ""}
                  onChange={(e) =>
                    formik.setFieldValue(
                      "sub_domain_name",
                      e.target.value.trimStart()
                    )
                  }
                  onBlur={(e) => {
                    formik.setFieldTouched("sub_domain_name", true);
                    formik.setFieldValue(
                      "sub_domain_name",
                      e.target.value.replace(/\s+/g, " ").trim()
                    );
                  }}
                />
                <small className="text-danger">
                  {formik.touched.sub_domain_name &&
                  formik.errors &&
                  formik.errors.sub_domain_name
                    ? formik.errors.sub_domain_name
                    : ""}
                </small>
              </div>
              <div className="col-md-12 form-group">
                <label
                  htmlFor="alias_name"
                  className="col-form-label w-100 mb-2"
                >
                  Alias Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="alias_name"
                  name="alias_name"
                  value={
                    formik.values && formik.values.alias_name
                      ? formik.values.alias_name
                      : ""
                  }
                  disabled={subDomainId !== ""}
                  onChange={(e) =>
                    formik.setFieldValue(
                      "alias_name",
                      e.target.value.trimStart()
                    )
                  }
                  // onBlur={formik.handleBlur}
                  onBlur={(e) => {
                    formik.setFieldTouched("alias_name", true);
                    formik.setFieldValue(
                      "alias_name",
                      e.target.value.replace(/\s+/g, " ").trim()
                    );
                  }}
                />
                <small className="text-danger">
                  {formik.touched.alias_name &&
                  formik.errors &&
                  formik.errors.alias_name
                    ? formik.errors.alias_name
                    : ""}
                </small>
              </div>
              <div className="col-md-12 form-group">
                <label
                  htmlFor="sub_domain_code"
                  className="col-form-label w-100 mb-2"
                >
                  Code
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="sub_domain_code"
                  name="sub_domain_code"
                  onChange={formik.handleChange}
                  disabled={subDomainId !== ""}
                  value={
                    formik.values.sub_domain_code
                      ? formik.values.sub_domain_code
                      : ""
                  }
                  onBlur={formik.handleBlur}
                />
                <small className="text-danger align-self-end">
                  {formik.touched.sub_domain_code &&
                  formik.errors &&
                  formik.errors.sub_domain_code
                    ? formik.errors.sub_domain_code
                    : ""}
                </small>
              </div>
              <div className="col-md-12 form-group">
                <label
                  htmlFor="domain_id"
                  className="col-form-label w-100 mb-2"
                >
                  Domains
                </label>
                <select
                  id="domain_id"
                  name="domain_id"
                  className="form-control"
                  onChange={formik.handleChange}
                  disabled={subDomainId !== ""}
                  value={formik.values.domain_id ? formik.values.domain_id : ""}
                  onBlur={formik.handleBlur}
                >
                  <option key="Select" hidden>
                    Select
                  </option>
                  {domainList.map((x, i) => (
                    <option value={x.domain_id} key={i}>
                      {x.domain_name}
                    </option>
                  ))}
                </select>
                <small className="text-danger">
                  {formik.touched.domain_id &&
                  formik.errors &&
                  formik.errors.domain_id
                    ? formik.errors.domain_id
                    : ""}
                </small>
              </div>
              <div className="col-md-6 form-group">
                <label
                  htmlFor="visibility_status"
                  className="col-form-label w-100 mb-2"
                >
                  Visibility
                </label>
                <select
                  className="form-control"
                  name="visibility_status"
                  id="visibility_status"
                  onChange={formik.handleChange}
                  value={
                    formik.values.visibility_status
                      ? formik.values.visibility_status
                      : ""
                  }
                  disabled={subDomainId !== ""}
                  onBlur={formik.handleBlur}
                >
                  <option hidden value="">
                    Select
                  </option>
                  {VisibilityStatus.map((x, i) => (
                    <option value={x.value} key={i}>
                      {x.name}
                    </option>
                  ))}
                </select>
                <small className="text-danger">
                  {formik.touched.visibility_status &&
                  formik.errors &&
                  formik.errors.visibility_status
                    ? formik.errors.visibility_status
                    : ""}
                </small>
              </div>
              <div className="col-md-6 form-group">
                <label
                  htmlFor="sort_order"
                  className="col-form-label w-100 mb-2"
                >
                  Sort Order
                </label>
                <div className="d-flex flex-column p-0">
                  <input
                    type="number"
                    className="form-control h-100 w-100"
                    //   placeholder={questionaireList.length + 1}
                    placeholder=""
                    id="sort_order"
                    name="sort_order"
                    max={100}
                    disabled={subDomainId !== ""}
                    value={
                      formik.values && formik.values.sort_order
                        ? formik.values.sort_order
                        : ""
                    }
                    onBlur={formik.handleBlur}
                    onChange={(e) =>
                      formik.setFieldValue(
                        "sort_order",
                        e.target.value.replace(/[^0-9\.]/g, "")
                      )
                    }
                  />
                  <small className="text-danger">
                    {formik.touched.sort_order && formik.errors.sort_order
                      ? formik.errors.sort_order
                      : ""}
                  </small>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 ">
            <div className="row">
              <Description
                formik={formik}
                showEditor={true}
                disabled={subDomainId !== ""}
              />
            </div>
          </div>

              <div className="col-md-12">
          <MetaInput
            formik={formik}
            parentArray="sub_domain_meta"
            typeKey="type"
            titleKey="key"
            valueKey="value"
            constType="ReportDescription"
            metaType={"Subdomain"}
          />
        </div>
          <div className={"d-flex  justify-content-end col-md-12"}>
            <button
              onClick={onClosed}
              type="button"
              className="btn btn-danger btn-sm float-right btn-add-domain mr-2"
            >
              Close
            </button>
            <CustomButton
              type="button"
              className="btn btn-primary btn-outline-success float-right btn-add-domain"
              disabled={
                Object.keys(formik.errors).length !== 0 ||
                !formik.isValid ||
                formik.isSubmitting
              }
              onClick={
                formik.isValid && Object.keys(formik.values).length !== 0
                  ? formik.handleSubmit
                  : handleError
              }
            >
              Create
            </CustomButton>
          </div>
          {subDomainId !== "" ? (
            <>
              <div className="col-md-12">
                <SingleImageUpload
                  formik={formik1}
                  heading="Image File"
                  contentId={subDomainId}
                  contentType="SUBDOMAIN"
                  mediaType="Image"
                  parentArray="sub_domain_image_urls"
                />
              </div>
              <div className="d-flex align-items-end justify-content-end col-md-12 mt-3">
                <CustomButton
                  type="button"
                  className="btn btn-primary btn-outline-success float-right btn-add-domain"
                  disabled={
                    Object.keys(formik1.errors).length !== 0 ||
                    !formik1.isValid ||
                    formik1.isSubmitting
                  }
                  onClick={() => {
                    formik1.isValid && Object.keys(formik1.values).length !== 0
                      ? formik1.handleSubmit()
                      : handleEditError();
                  }}
                >
                  Update
                </CustomButton>
              </div>
            </>
          ) : null}
        </div>
      </div>
    </>
  );
}

const validation = Yup.object().shape({
  description: Yup.string().required("Enter a Description"),
  alias_name: Yup.string().trim(),
  mobile_description: Yup.string().required("Enter a Mobile Description"),
  sub_domain_name: Yup.string().trim().required("Enter a name"),
  sub_domain_code: Yup.string().required("Enter a code"),
  visibility_status: Yup.string().required("Select visibility"),
  // .min(4, "Name should be min of 4 characters")
  // .max(30, "Name should be maximum of 30 characters")
  domain_id: Yup.string().required("Select a domain"),
  sort_order: Yup.string().required("Enter sort order"),
});
const ImgValidation = Yup.object().shape({
  sub_domain_image_urls: Yup.array().of(
    Yup.object().shape({
      is_mobile: Yup.boolean().required("Select a Image device"),
      image_type: Yup.string().required("Select a Image format"),
      url: Yup.string(),
    })
  ),
});
// const initialContent = {
//   content_id: "",
//   content_type: "SUBDOMAIN",
//   description: "",
//   mobile_description: "",
// };
