import moment from "moment";
import React, { useEffect } from "react";
import useUserInfo from "../../../utils/CustomHooks/useUserInfo";

export default function ScreenerHistoryComments({ x = {} }) {
  const { userInfo, getUser } = useUserInfo("");
  useEffect(() => {
    if (x.created_by) getUser(x.created_by);
  }, [x]);
  if (!x.approved_by && !x.reviewed_by) return null;
  const getComments = (data, type = "Review") => {
    if (data.reviewed_by && type == "Review") return data.reviewer_comment;
    else if (data.approved_by && type == "Approve")
      return data.approver_comment;
    else return "-";
  };
  return (
    <>
      <tr>
        <td> {getComments(x, "Review")}</td>
        <td>
          {moment(x.updated_at ? x.updated_at : x.created_at).format(
            "DD/MM/YYYY - hh:mm:ss A"
          ) || "-"}
        </td>
        <td> {userInfo && userInfo.name ? userInfo.name : "-"} </td>
      </tr>
      {x.approved_by && (
        <tr>
          <td> {getComments(x, "Approve")}</td>
          <td>
            {moment(x.updated_at ? x.updated_at : x.created_at).format(
              "DD/MM/YYYY - hh:mm:ss A"
            ) || "-"}
          </td>
          <td> {userInfo && userInfo.name ? userInfo.name : "-"} </td>
        </tr>
      )}
    </>
  );
}
