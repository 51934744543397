import { useState } from "react";
import { GET_CITY_LIST } from "../../Apollo/AdminServices/QueryRequest";
import graphHandler from "../../Apollo/graphHandler";
function useCityList(){
    const [cityList, setCityList] = useState([]);
    const [loading, setLoading] = useState(false);
    
  const getCityList = async (req = {}) => {
    setLoading(true);
    try {
      let { data } = await graphHandler().invoke(
        GET_CITY_LIST,
        req,
        1,
        false,
        true
      );
      if (
        data &&
        data.getCityList &&
        data.getCityList.cities &&
        data.getCityList.cities.length > 0
      ) {
        // console.log(
        //   "getCityList Success",
        //   data.getCityList.cities
        // );
        setCityList([]);
        setCityList(data.getCityList.cities.slice());
        setLoading(false);
      } else {
        setCityList([]);
        setLoading(false);
      }
    } catch (error) {
      console.log("getCityList Error", error);
      setCityList([]);
      setLoading(false);
    }
  };
  return {
    cityList,
    loading,
    getCityList,
  };
  
}
export default useCityList;