import { Fragment, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CustomLoader from "../components/Loader";
import { rLoginUser } from "../redux/Auth/action";
import { LOADING_STOP } from "../redux/Auth/types";
import { rAddRoutes } from "../redux/Routes/action";

function AuthRouter({ children, history, location }) {
  const [wait, setWait] = useState(true);
  const loading = useSelector(({ Auth }) => Auth.loading);
  const isLogin = useSelector(({ Auth }) => Auth.isLogin);
  const routes = useSelector(({ Routes }) => Routes);
  const { publicRoutes, privateRoutes, all } = routes;
  const dispatch = useDispatch();
  //SET ROUTES
  useEffect(() => {
    // console.log(routes);
    if (all && all.length) {
      setWait(false);
    } else {
      dispatch(rAddRoutes());
    }
  }, [all]);

  //set Refresh Session CB
  useEffect(() => {
    const email = localStorage.getItem("email");
    const password = localStorage.getItem("password");
    if (!wait) {
      if (email && password) {
        dispatch(
          rLoginUser({
            login_method: "EMAIL",
            device_id: "134567823456789",
            email,
            password,
          })
        );
      } else {
        dispatch({ type: LOADING_STOP });
      }
    }
  }, [wait]);

  //CheckOnEachChange
  useEffect(() => {
    if (!wait && !loading) {
      redirectRoute();
    }
  }, [wait, location, history, loading, isLogin]);

  const redirectRoute = () => {
    const { pathname, state, search } = location;
    const isPublic = publicRoutes.find((x) => x.path === pathname)
      ? true
      : false;
    const isPrivate = privateRoutes.find((x) => x.path === pathname)
      ? true
      : false;
    const redirectUrl =
      state && state.redirectUrl ? state.redirectUrl : pathname;
    // console.log(wait, location, history, loading, isLogin, isPublic, isPrivate);
    if (!isLogin) {
      if (isPrivate || pathname === "/") {
        // console.log("redirectUrl", redirectUrl);
        history.push({
          pathname: "/login",
          state: { redirectUrl },
          search: search,
        });
      } else {
        history.push({
          pathname: pathname,
          search: search,
        });
      }
    } else if (isLogin && isPublic) {
      if (history.previous) {
        history.goBack();
      } else {
        history.push("/");
      }
    } else if (isPrivate) {
      // history.replace({
      //   pathname: redirectUrl,
      //   search: search,
      // });
    }
  };

  return loading || wait ? <CustomLoader /> : <Fragment>{children}</Fragment>;
}

export default withRouter(AuthRouter);
